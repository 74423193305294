/* eslint-disable */
import React from 'react';
import { ThemeProvider } from 'styled-components';
import CodeIcon from '@material-ui/icons/Code';
import HistoryIcon from '@material-ui/icons/History';
import CustomButton from '../components/CustomButton/CustomButton';
import TransperentButtonTheme from '../themes/Button/TransperentButtonTheme';
import StyledTrashIcon from '../components/Icons/TrashIcon';
import ButtonLoader from '../components/ButtonLoader/ButtonLoader';
import StyledTableCell from '../components/TableRowCell';
import StyledEditIcon from '../components/Icons/EditIcon';

const getBusinessColumns = (
  handleEdit,
  handleDelete,
  businessContainer,
  iconsTheme,
  handleCodeCopy,
  handleReactive,
) => [
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <StyledTableCell>{value}</StyledTableCell>;
        },
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <StyledTableCell>{value}</StyledTableCell>;
        },
      },
    },
    {
      name: 'phone',
      label: 'Phone',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <StyledTableCell>{value}</StyledTableCell>;
        },
      },
    },
    {
      name: 'website',
      label: 'Website',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <StyledTableCell>{value}</StyledTableCell>;
        },
      },
    },
    // {
    //   name: 'type',
    //   label: 'Type',
    //   options: {
    //     filter: true,
    //     sort: false,
    //     customBodyRender: (value) => {
    //       return <StyledTableCell>{value || <ButtonLoader />}</StyledTableCell>;
    //     },
    //   },
    // },
    // {
    //   name: 'status',
    //   label: 'Status',
    //   options: {
    //     filter: true,
    //     sort: false,
    //     customBodyRender: (value) => {
    //       return <StyledTableCell>{value}</StyledTableCell>;
    //     },
    //   },
    // },
    // {
    //   name: 'uiId',
    //   label: 'Code',
    //   options: {
    //     filter: true,
    //     sort: false,
    //     customBodyRenderLite: (dataIndex) => {
    //       return (
    //         <ThemeProvider theme={TransperentButtonTheme}>
    //           <CustomButton onClick={() => handleCodeCopy(dataIndex)} title="Copy">
    //             <CodeIcon
    //               style={{
    //                 width: '16px',
    //                 color: 'rgb(191, 191, 191)',
    //                 float: 'right',
    //                 margin: ' 0',
    //                 height: '17px',
    //                 cursor: 'pointer',
    //               }}
    //             />
    //           </CustomButton>
    //         </ThemeProvider>
    //       );
    //     },
    //   },
    // },
    {
      name: '',
      label: 'Action',
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          if (businessContainer.length) {
            return (
              <div style={{ display: 'flex' }}>
                {!businessContainer[dataIndex].isDeleted ? (
                  <div style={{ display: 'flex' }}>
                    <ThemeProvider theme={{ ...TransperentButtonTheme, color: iconsTheme.editIcon }}>
                      <CustomButton onClick={() => handleEdit(dataIndex, rowIndex)} title="Edit">
                        <StyledEditIcon />
                      </CustomButton>
                    </ThemeProvider>
                    <ThemeProvider
                      theme={{ ...TransperentButtonTheme, color: iconsTheme.trashIconTheme }}
                    >
                      <CustomButton onClick={() => handleDelete(dataIndex, rowIndex)} title="Delete">
                        <StyledTrashIcon />
                      </CustomButton>
                    </ThemeProvider>
                  </div>
                ) : (
                  <div style={{ display: 'flex' }}>
                    <ThemeProvider theme={{ ...TransperentButtonTheme, color: iconsTheme.editIcon }}>
                      <CustomButton onClick={() => handleEdit(dataIndex, rowIndex)} title="Edit">
                        <StyledEditIcon />
                      </CustomButton>
                    </ThemeProvider>
                    <ThemeProvider
                      theme={{ ...TransperentButtonTheme, color: iconsTheme.trashIconTheme }}
                    >
                      <CustomButton
                        onClick={() => handleReactive(dataIndex, rowIndex)}
                        title="Re-Active"
                      >
                        <HistoryIcon />
                      </CustomButton>
                    </ThemeProvider>
                  </div>
                )}
              </div>
            );
          }
          return '';
        },
      },
    },
  ];

export default getBusinessColumns;
