import React from 'react';
import { makeStyles, Radio } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.radioColor,
    label: {
      fontFamily: 'Montserrat !important',
    },
    '&$checked': {
      color: theme.radioColor,
    },
  },
  checked: {},
}));

const StyledRadioButton = (props) => {
  const classes = useStyles();
  return <Radio className={classes.root} {...props} />;
};

export default StyledRadioButton;
