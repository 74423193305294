import React, { useState, useContext, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import { Grid, TextField } from '@material-ui/core';
import CustomButton from '../CustomButton/CustomButton';
import AldonLogo from '../../assets/images/aldon_logo.svg';
import styles from './surveyCard.module.scss';
import { YesNo } from '../SurveyAnswers/YesNo';
import { StarRating } from '../SurveyAnswers/StarRating';
import { LongAnswer } from '../SurveyAnswers/LongAnswer';
import { MultipleChoice } from '../SurveyAnswers/MultipleChoice';
import baseUrl from '../../utils/services/config';
import Loader from '../Loader/Loader';
import { OnBoardingContext } from '../../Stores/OnBoarding';
import SocketStore from '../../Stores/SocketStore';
import { io } from '../../socket_context/initSocket';

const ContainerButtonLink = styled.div`
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  flex-direction: 'column';
`;

const FirstDiv = styled.div`
  position: absolute;
  left: 10px;
`;

const SurveyCard = (props) => {
  const { data, stateAnswers, setStateAnswers } = props;

  const answers = data.answers || '';
  const clientId = data.clientId || '';
  const surveyId = data.surveyId || '';
  const locationId = data.locationId || '';
  const campaignAnalyticsId = data.campaignAnalyticsId || '';
  const surveyTitle = data.meta[data.name].title || '';
  const { onBoardingDispatch, onBoardingData } = useContext(OnBoardingContext);
  const { localAnswers } = onBoardingData;

  const { screens } = Object.values(data.meta).find((element) => element.hasOwnProperty('screens'));
  const [isUpdating, setIsUpdating] = useState(false);

  const { questions } = screens[1];

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [previusQuestionCode, setPreviusQuestionCode] = useState([]);

  const currentQuestion = questions[currentQuestionIndex];

  const [currentAnswer, setCurrentAnswer] = useState();
  const [questionsToSkip, setQuestionsToSkip] = useState();
  const [reviewUrl, setReviewUrl] = useState([]);
  const [reviewMessage, setReviewMessage] = useState('');
  const [hiddenButtons, setHiddenButtons] = useState(false);
  const [flowControl, setFlowControl] = useState([]);
  const [flowControlMessage, setFlowControlMessage] = useState('');
  const [isEndSurvey, setIsEndSurvey] = useState(false);
  const [otherValue, setOtherValue] = useState('');


  const valueComplete = Math.round((currentQuestionIndex / questions.length) * 100 - 1)
  const [formCompleted, setFormCompleted] = useState(false)

  const StyledButton = styled(CustomButton)`
    background-color: #208c91 !important;
    color: white !important;
  `;

  const StyledLink = {
    color: 'white',
    padding: 10,
    borderRadius: 10,
    paddingLeft: 30,
    paddingRight: 30,
    width: '70%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  };

  useEffect(() => {
    if (currentQuestion.type === 'multipleChoice') {
      //   setCurrentAnswer([getSavedAnswer(currentQuestion.code)]);
      // I had to comment the code because it generates duplicate responses.
    } else {
      // setCurrentAnswer(getSavedAnswer(currentQuestion.code));
    }
    setQuestionsToSkip(currentQuestion.questionsToSkip);
  }, [currentQuestion]);

  const handleAnswerChange = (newAnswer) => {
    setCurrentAnswer(newAnswer);
  };

  const getSavedAnswer = (code) => {
    let hasAnswer;
    if (answers && answers.length > 0) {
      hasAnswer = answers.filter((question) => question.questionCode === code);
      if (hasAnswer.length === 0) {
        hasAnswer = localAnswers.filter((question) => question.questionCode === code);
      }
    } else {
      hasAnswer = localAnswers.filter((question) => question.questionCode === code);
    }
    return hasAnswer.length > 0 ? hasAnswer[0].answerValue : null;
  };

  const getLocalAnswer = (code) => {
    const hasAnswer = localAnswers.filter((question) => question.questionCode === code);
    return hasAnswer.length > 0 ? hasAnswer[0].answerValue : null;
  };
  const nextQuestionIndex = () => {
    setIsEndSurvey(false)
    setPreviusQuestionCode([...previusQuestionCode, currentQuestion.code]);
    if (flowControl.length > 0) {
      const targetQuestionItem = flowControl.find(
        (item) =>
          item.expectedValue?.toString() === currentAnswer?.toString() ||
          item.expectedValue?.toString() === '*',
      );
      if (targetQuestionItem) {
        if (targetQuestionItem?.text) {
          setFlowControlMessage(targetQuestionItem?.text);
          setIsEndSurvey(targetQuestionItem.targetQuestion === "endSurvey")
        } else {
          setFlowControlMessage('');
        }
        const targetQuestionCode = targetQuestionItem.targetQuestion;
        const indexQuestion = questions.findIndex(
          (item) => item.code?.toString() === targetQuestionCode?.toString(),
        );

        if (indexQuestion !== -1) {
          setCurrentQuestionIndex(indexQuestion);
        } else {
          setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
      } else {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
    } else {
      // If there is no flow control, proceed to the next question.
      // eslint-disable-next-line no-lonely-if
      if (currentQuestion.type === 'yesNo') {
        if (currentAnswer === 'yes' && currentQuestion.questionsToSkip) {
          setCurrentQuestionIndex(currentQuestionIndex + currentQuestion.questionsToSkip + 1);
        } else {
          setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
      } else if (currentQuestion.questionsToSkip) {
        setCurrentQuestionIndex(currentQuestionIndex + currentQuestion.questionsToSkip + 1);
      } else {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
    }
  };

  const handleNext = async () => {
    if (flowControlMessage.length > 0) {
      setFlowControlMessage('');
      return;
    }
    setIsUpdating(true);

    nextQuestionIndex();

    let multipleChoiceAnswer;

    let currentAnswerCopy;
    if (Array.isArray(currentAnswer)) {
      currentAnswerCopy = [...currentAnswer];
    } else {
      currentAnswerCopy = currentAnswer;
    }

    if (Array.isArray(currentAnswerCopy) && currentQuestion.allowInput !== undefined) {
      const otherIndex = currentAnswerCopy.findIndex((item) => item === currentQuestion.allowInput);

      if (otherIndex !== -1) {
        currentAnswerCopy[otherIndex] = `${currentQuestion.allowInput}-${otherValue}`;
      }
    }
    if (Array.isArray(currentAnswerCopy)) multipleChoiceAnswer = currentAnswerCopy.join();

    const postBody = {
      surveyId,
      clientId,
      questionCode: currentQuestion.code,
      answerValue: Array.isArray(currentAnswer) ? multipleChoiceAnswer : currentAnswerCopy,
      analyticsId: campaignAnalyticsId,
    };
    const result = await baseUrl.post(`/campaign/send-survey-answer`, { ...postBody });
    if (result.status === 200) {
      const updateObject = { questionCode: currentQuestion.code, answerValue: currentAnswer };
      onBoardingDispatch({ type: 'SAVE_LOCAL_ANSWERS', payload: updateObject });
      setCurrentAnswer(null);
      setTimeout(() => {
        setIsUpdating(false);
      }, [500]);
    }
    setFlowControl([]);
  };

  const handlePrevious = () => {
    if (flowControlMessage.length > 0) {
      setFlowControlMessage('')
      setIsEndSurvey(false)
    }
    setIsUpdating(true);

    if (previusQuestionCode.length > 0) {
      const prevCode = previusQuestionCode[previusQuestionCode.length - 1];

      const indexQuestion = questions.findIndex((item) => item.code === prevCode);

      if (indexQuestion !== -1) {
        setCurrentQuestionIndex(indexQuestion);
        setPreviusQuestionCode([...previusQuestionCode.slice(0, -1)]);

      }
      setTimeout(() => {
        setIsUpdating(false);
      }, [500]);
    } else {
      setCurrentAnswer(getLocalAnswer(questions[currentQuestionIndex - 1].code));
      if (currentQuestion.goBackTo) {
        setCurrentQuestionIndex(currentQuestion.goBackTo - 1);
      } else {
        setCurrentQuestionIndex(currentQuestionIndex - 1);
      }
      setTimeout(() => {
        setIsUpdating(false);
      }, [500]);
    }
  };

  const validatedFlowControl = (question) => {
    if (!question?.flowControl) return;
    if (question?.flowControl) {
      setFlowControl(question?.flowControl);
    } else {
      setFlowControl([]);
    }
  };
  const QuestionComponent = ({ currentQuestion }) => {
    validatedFlowControl(currentQuestion);
    // eslint-disable-next-line default-case
    switch (currentQuestion.type) {
      case 'yesNo':
        return (
          <YesNo
            question={currentQuestion}
            currentAnswer={currentAnswer}
            setCurrentAnswer={handleAnswerChange}
          />
        );
      case 'starRating':
        return <StarRating currentAnswer={currentAnswer} setCurrentAnswer={setCurrentAnswer} />;
      case 'longAnswer':
        return <LongAnswer currentAnswer={currentAnswer} setCurrentAnswer={setCurrentAnswer} />;
      case 'multipleChoice':
        return (
          <MultipleChoice
            question={currentQuestion}
            currentAnswer={currentAnswer}
            setCurrentAnswer={setCurrentAnswer}
          />
        );
      case 'singleChoice':
        return (
          <MultipleChoice
            singleChoice
            question={currentQuestion}
            currentAnswer={currentAnswer}
            setCurrentAnswer={setCurrentAnswer}
          />
        );
    }
  };

  const fetchSurveyScore = () => {
    baseUrl
      .get(`/campaign/get-survey-score/${surveyId}/${campaignAnalyticsId}`)
      .then((response) => {
        if (response.data.links.length) {
          setHiddenButtons(true);
          setReviewUrl(response.data.links);
          setReviewMessage(response.data.message);
        } else {
          window.location.reload();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleDone = () => {
    baseUrl
      .post(`/conversation/complete-survey`, {
        surveyId,
        campaignAnalyticsId,
        client: clientId,
        location: locationId,
        surveyTitle,
      })
      .then((response) => {
        if (response.status === 200) {
          fetchSurveyScore();
          setFormCompleted(true)
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const keywordsReplacementsSurvey = (value) => {
    const regex = /@(\w+)/g;
    const newQuestion = value.replace(regex, (match, word) => {
      const answers = localAnswers.find((question) => question?.questionCode === word);
      if (answers) {
        let text = '';
        answers.answerValue.forEach((res, index) => {
          if (res) {
            if (index > 0) {
              text += ', ';
            }
            text += res;
          }
        });
        return text;
      }
      return word;
    });
    return newQuestion;
  };

  return (
    <SocketStore>
      <div className={styles.survey}>
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '1rem' }}>
          <ReactSVG src={AldonLogo} />
        </div>

        <div className={styles.title}>{surveyTitle}</div>

        {isUpdating ? <Loader /> : null}
        <div
          key={currentQuestion.id}
          className={`${styles.slide} ${isUpdating ? styles.fadeIn : ''}`}
        >
          {reviewUrl.length ? (
            <div className={styles.title}>
              {reviewMessage}
              {' '}
              <ContainerButtonLink>
                {reviewUrl.map((item) => (
                  <a
                    className={styles.title}
                    style={{
                      backgroundColor: item.color ?? '#35EDA7',
                      ...StyledLink,
                    }}
                    href={item.url}
                  >
                    <FirstDiv>{item.site.charAt(0).toUpperCase()}</FirstDiv>
                    <div>{item.site}</div>
                  </a>
                ))}
              </ContainerButtonLink>
            </div>
          ) : (
            <>
              {flowControlMessage.length > 0 ? (
                <div className={styles.questionTitle}>{flowControlMessage}</div>
              ) : (
                <>
                  <div className={styles.questionTitle}>
                    {keywordsReplacementsSurvey(currentQuestion.text)}
                  </div>
                  <QuestionComponent currentQuestion={currentQuestion} />
                  {currentQuestion?.allowInput &&
                    currentAnswer &&
                    currentAnswer.includes(currentQuestion?.allowInput) && (
                      <TextField
                        fullWidth="100%"
                        value={otherValue}
                        placeholder="description of others"
                        onChange={(e) => setOtherValue(e.target.value)}
                      />
                    )}
                </>
              )}
            </>
          )}
        </div>
        {!hiddenButtons && (
          <div className={styles.progressButtons}>
            <StyledButton onClick={handlePrevious} disabled={currentQuestionIndex === 0}>
              Previous
            </StyledButton>
            {currentQuestionIndex === questions.length - 1 || isEndSurvey ? (
              <StyledButton onClick={() => handleDone()}>Done</StyledButton>
            ) : (
              <StyledButton
                onClick={() => handleNext('next')}
                disabled={currentQuestionIndex === questions.length - 1}
              >
                Next
              </StyledButton>
            )}
          </div>
        )}
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          style={{ marginTop: '2rem' }}
          className={styles.progressIndicatorContainer}
        >
          <div className={styles.percentageText}>
            {
              formCompleted ? '100% Complete' :
                `${valueComplete > 0 ? valueComplete : '0'}% Complete`}
          </div>
          <div className={styles.progressTrack}>
            <div
              className={styles.progressIndicator}
              style={{
                width:
                  formCompleted ? '100%' :
                    `${valueComplete > 0 ? valueComplete : '0'}%`,
              }}
            />
          </div>
        </Grid>
      </div>
    </SocketStore>
  );
};

export default SurveyCard;
