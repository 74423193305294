import React from 'react';
import { ListItemIcon, MenuList, MenuItem, Paper, makeStyles, Divider } from '@material-ui/core';
import styled from 'styled-components';
import { Popover } from '@material-ui/core';
import ViewReviews from '@material-ui/icons/RecentActors';
import PostReviews from '@material-ui/icons/RateReview';
import CheckForReviews from '@material-ui/icons/Update';

const StyledListItemText = styled.div`
  font-family: 'Montserrat' !important;
  font-weight: 600;
  margin: 0.2rem 0;
`;

function ConnectionsMorePopover(props) {
  const { open, handleClose, onViewReview, onViewPostReview, anchorEl, onCheckForReviews } = props;

  const useStyles = makeStyles((theme) => ({
    paper: {
      overflow: 'hidden',
      background: theme.background,
    },
  }));

  const classes = useStyles();

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      classes={{
        paper: classes.paper,
      }}
    >
      <Paper sx={{ width: 320, maxWidth: '100%' }}>
        <MenuList dense onClick={handleClose}>
          <MenuItem onClick={onViewReview}>
            <ListItemIcon>
              <ViewReviews />
            </ListItemIcon>
            <StyledListItemText>View Reviews Link</StyledListItemText>
          </MenuItem>
          <MenuItem onClick={onViewPostReview}>
            <ListItemIcon>
              <PostReviews />
            </ListItemIcon>
            <StyledListItemText>View Post Review Link</StyledListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={onCheckForReviews}>
            <ListItemIcon>
              <CheckForReviews />
            </ListItemIcon>
            <StyledListItemText>Check For Reviews</StyledListItemText>
          </MenuItem>
        </MenuList>
      </Paper>
    </Popover>
  );
}

export default React.memo(ConnectionsMorePopover);
