import sailsIOClient from 'sails.io.js';
import socketIOClient from 'socket.io-client';
import { socketEvents } from './events';

export let io;

export const initSockets = ({ socketDispatch, dispatch }) => {
  if (socketIOClient.sails) {
    io = socketIOClient;
  } else {
    io = sailsIOClient(socketIOClient);
  }

  io.sails.transports = ['websocket'];
  io.sails.headers = {
    Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`,
  };

  io.sails.url = process.env.REACT_APP_IMAGE_ENDPOINT;

  try {
    if (io) {
      //console.log('provider', io);

      socketEvents({ socketDispatch, dispatch });
    }
  } catch (e) {
    console.log(e);
  }
};
