import React, { useEffect, useState } from 'react';
import { Radio, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#00000054',
    '&$checked': {
      color: '#208c91',
    },
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
  },
  checked: {},
}));

export const YesNo = (props) => {
  const { currentAnswer, setCurrentAnswer } = props;
  const [answer, setAnswer] = useState(currentAnswer);
  const classes = useStyles();

  // useEffect(() => {
  //   setAnswer(currentAnswer);
  // }, [currentAnswer]);

  const handleChange = (event) => {
    //console.log('handle change was called ' + event.target.value);
    //setAnswer(event.target.value);
    setCurrentAnswer(event.target.value);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', rowGap: '.5rem' }}>
      <label>
        <Radio
          value="yes"
          checked={currentAnswer === 'yes'}
          onChange={handleChange}
          classes={classes}
        />
        Yes
      </label>
      <label>
        <Radio
          value="no"
          classes={classes}
          checked={currentAnswer === 'no'}
          onChange={handleChange}
        />
        No
      </label>
    </div>
  );
};
