import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import styled from 'styled-components';

const { TextField } = require('@material-ui/core');

const useStyles = makeStyles((theme) => ({
  root: {
    '& label': {
      color: theme.inputLabelColor,
      fontFamily: 'Montserrat !important',
      fontSize: '1rem !important',
      fontWeight: '500 !important',
    },
    '& label.Mui-focused ': {
      color: '#0000008a !important',
    },
    '& .Mui-focused.Mui-error label': {
      color: 'green', //color: '#f44336',
      fontFamily: 'Montserrat !important',
    },
    '&> div:before': {
      borderBottomColor: theme.inputBorderBottomBefore,
    },
    '& >div:after': {
      borderBottomColor: theme.inputBorderBottomAfter,
    },
    '& >div:hover:not(.Mui-disabled):before': {
      borderBottomColor: theme.inputOnFocusBefore,
    },
    '& > div > input': {
      color: `${theme.inputTextColor} !important`,
      fontFamily: 'Montserrat !important',
      fontWeight: '500 !important',
      fontSize: '14px !important',
    },
    '& > div > input::placeholder': {
      color: `${theme.inputTextColor} !important`,
      fontFamily: 'Montserrat !important',
      fontSize: '14px !important',
    },
    '  & > div >  textarea': {
      color: `${theme.inputTextColor} !important`,
      fontFamily: 'Montserrat !important',
      fontWeight: '500 !important',
      fontSize: '12px !important',
      overflowY: 'auto !important',
      maxHeight: (props) => props.styles?.maxHeight || '500px',
      height: (props) => props.styles?.height ?? '150px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
    '& .MuiFormHelperText-root': {
      fontFamily: 'Montserrat !important',
      color: '#8b8b8b !important',
    },
  },
  helperText: {
    fontFamily: 'Montserrat !important',
    fontSize: '12px !important',
    fontWeight: '500 !important',
  },
}));

const StyledTextFieldContainer = styled(TextField)`
  width: -webkit-fill-available !important;
  font-size: 14px;
  input[type='number'] {
    -moz-appearance: textfield !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & > div {
    &:before {
      border: ${(props) => props.border || props.theme.border} !important;
      border-bottom: ${(props) => props.border || props.theme.borderBottom} !important;
    }
    & .MuiFormHelperText-root {
      // Target the helper text
      font-family: 'Montserrat' !important; // Set fontFamily to Montserrat
    }
  }
  & > div > textarea {
    padding: ${(props) => props.theme.padding} !important;
    text-align: ${(props) => props.theme.textAlign} !important;
    font-size: ${(props) => props.fontSize || props.theme.fontSize || '12px'} !important;
  }
`;

const StyledTextField = forwardRef((props, ref) => {
  const classes = useStyles();
  return (
    <StyledTextFieldContainer
      ref={ref}
      className={classes.root}
      {...props}
      value={props.value || ''}
      onChange={props.onChange}
      error={props.error}
      InputLabelProps={{
        shrink: true,
      }}
      FormHelperTextProps={{
        className: classes.helperText, // Apply Montserrat font to helper text
      }}
    />
  );
});

export default StyledTextField;
