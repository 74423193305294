import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import useAlert from '../../../../../hooks/useAlert';
import CusotmAlert from '../../../../CustomAlert/CustomAlert';
import ColorPickerInput from './ColorPickerInput';
import SettingsSwitch from '../../../../SettingsSwitch/SettingsSwitch';
import StyledTextField from '../../../../Common/StyledTextField';
import styled from 'styled-components';

const SectionHeader = styled.div`
  font-weight: 500;
  font-size: 14px;
  margin: 0.7rem 0 0.5rem 0;
`;

export const SettingsSidebar = ({
  bodyRequest,
  setBodyRequest,
  selectedColor,
  setSelectedColor,
}) => {
  const messageOptions = [
    {
      id: 1,
      name: 'Review and Response',
      value: 'reviewAndResponse',
    },
    {
      id: 2,
      name: 'Review Only',
      value: 'reviewOnly',
    },
  ];

  const ContactAlert = useAlert();

  const toggleAutoHash = async () => {
    setBodyRequest({
      ...bodyRequest,
      autoHashTags: !bodyRequest.autoHashTags,
    });
  };

  const toggleDarkTheme = () => {
    setBodyRequest((prevState) => ({
      ...prevState,
      colors: {
        ...prevState.colors,
        [selectedColor]: {
          ...prevState.colors[selectedColor],
          isDarkText: !prevState.colors[selectedColor].isDarkText,
        },
      },
    }));
  };

  const togglePreview = () => {
    setBodyRequest({
      ...bodyRequest,
      isPreview: !bodyRequest.isPreview,
    });
  };

  const changeHashTags = (value) => {
    setBodyRequest({
      ...bodyRequest,
      hashtags: value,
    });
  };

  //console.log(bodyRequest);

  return (
    <Box style={{ padding: '0 1rem', display: 'flex', rowGap: '1rem', flexDirection: 'column' }}>
      <CusotmAlert
        message={ContactAlert.message}
        handleClose={ContactAlert.handleClose}
        open={ContactAlert.show}
        messageType={ContactAlert.messageType}
      />
      {/* <SettingsSwitch
        title="Enable Post Preview"
        onChange={() => togglePreview()}
        checked={bodyRequest.isPreview}
        description={'Toggle to enable post preview'}
      /> */}
      <SettingsSwitch
        title="Auto Post Tags"
        onChange={() => toggleAutoHash()}
        checked={bodyRequest.autoHashTags}
        description={'Auto generate industry specific tags for the post'}
      />
      {!bodyRequest.autoHashTags ? (
        <StyledTextField
          value={bodyRequest.hashtags}
          placeholder="#tags separated by a space"
          onChange={(e) => changeHashTags(e.target.value)}
          label="Post tags"
          disabled={bodyRequest.autoHashTags}
        />
      ) : null}
      <Box>
        <SectionHeader>{'Overlay Colors'}</SectionHeader>
        <Box display="flex" style={{ columnGap: '.5rem' }}>
          <ColorPickerInput
            colorToChange={'overlayColor1'}
            bodyRequest={bodyRequest}
            setBodyRequest={setBodyRequest}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
          />
          <ColorPickerInput
            colorToChange={'overlayColor2'}
            bodyRequest={bodyRequest}
            setBodyRequest={setBodyRequest}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
          />
          <ColorPickerInput
            colorToChange={'overlayColor3'}
            bodyRequest={bodyRequest}
            setBodyRequest={setBodyRequest}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
          />
          <ColorPickerInput
            colorToChange={'overlayColor4'}
            bodyRequest={bodyRequest}
            setBodyRequest={setBodyRequest}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
          />
          <ColorPickerInput
            colorToChange={'overlayColor5'}
            bodyRequest={bodyRequest}
            setBodyRequest={setBodyRequest}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
          />
        </Box>
      </Box>
      <SettingsSwitch
        title="Dark Theme"
        onChange={() => toggleDarkTheme()}
        checked={bodyRequest.colors[selectedColor].isDarkText}
        description={'If enabled, text will be readable on a dark background'}
      />
    </Box>
  );
};
export default SettingsSidebar;
