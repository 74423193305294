import React, { useContext, useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { Button, Grid, ThemeProvider as MUIThemeProvider } from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import ArrowBackIcon from 'mdi-react/ArrowBackIcon';
import PageTitle from '../../../components/PageTitle/PageTitle';
import Dropdown from '../../../components/Dropdown/Dropdown';
import NoBorderDropDownTheme from '../../../themes/Dropdown/NoBorderDropDownTheme';
import CustomButton from '../../../components/CustomButton/CustomButton';
import GroupFilter from '../../../components/Filters/GroupFilter';
import StyledTuneIcon from '../../../components/Icons/TuneIcon';
import CustomTable2 from '../../../components/CustomTable/CustomTable2';
import getMainGroupColumns, { getMainGroupColumnsSurvey } from '../../../Columns/mainGroup';
import { GlobalContext } from '../../../Stores/GlobalStateStore';
import { ConvertIntoLocationDropDown, getUniqueArray } from '../../../utils/helper/helper';
import useTable2Theme from '../../../hooks/useTable2Theme';
import useButtonTheme from '../../../hooks/useButtonTheme';
import CusotmTable from '../../../components/CustomTable/CusotmTable';
import PrimaryButton from '../../../components/Common/PrimaryButton';

function GroupListView(props) {
  const {
    handleNext,
    handleSendCampaignFromGroup,
    title,
    disableLocations,
    isPreviewSurvery,
    listViewData,
    handlepressSave,
    handlepressCancel,
    changeStep,
  } = props;
  const [columnsWidth, setColumnsWidth] = useState(12);
  const [toggleFilterView, setToggleFilterView] = useState(false);
  const { state, dispatch } = useContext(GlobalContext);
  const { groupContainer, locations } = state;
  const { customTable2Theme } = useTable2Theme();
  const { buttonTheme } = useButtonTheme();

  const StyledOutlineButton = styled(Button)`
    text-transform: capitalize !important;
    letter-spacing: unset !important;
    font-family: 'Montserrat' !important;
    font-weight: 600 !important;
  `;
  useEffect(() => {
    // finalGroupData
    dispatch({
      type: 'SET_FINAL_GROUP_CONTAINER',
      payload: groupContainer.listViewData.concat(groupContainer.existingClientContainer),
    });
  }, []);

  // Effect to handle view Toggling

  useEffect(() => {
    if (toggleFilterView) {
      setColumnsWidth(9);
    } else {
      setColumnsWidth(12);
    }
  }, [toggleFilterView]);

  const handleSelectLocation = (e) => {
    dispatch({ type: 'SET_GROUP_LOCATION', payload: e.target.value });
  };
  const groupViewContent = toggleFilterView ? <GroupFilter /> : null;
  const [rowsSelected, setRowsSelected] = useState([]);

  const groupContainerListViewData = isPreviewSurvery
    ? listViewData
    : getUniqueArray(groupContainer.listViewData.concat(groupContainer.existingClientContainer));

  const groupContainerColumns = isPreviewSurvery
    ? getMainGroupColumnsSurvey()
    : getMainGroupColumns();

  const options = {
    filterType: 'checkbox',
    responsive: 'standard',
    selectableRows: 'multiple',
    elevation: 0,
    viewColumns: false,
    toolbar: false,
    rowsSelected: rowsSelected || [],
    onChangeRowsPerPage: () => {},
    customToolbarSelect: () => {},
    onRowsSelect: (currentRowsSelected, allRowsSelected) => {
      setRowsSelected(allRowsSelected.map((row) => row.dataIndex));
    },

    rowsPerPageOptions: [5, 10, 15, 100],
  };

  const filterSelectedList = () => {
    const data = rowsSelected.map((row) => {
      return listViewData[row].data;
    });
    handlepressSave(data);
  };

  useEffect(() => {
    if (listViewData.length) {
      const selectAllRows = [];
      listViewData.forEach((data, index) => {
        selectAllRows.push(index);
      });
      setRowsSelected(selectAllRows);
    }
  }, []);

  return (
    <Grid container style={{ height: '100%' }}>
      <Grid item xs={12} sm={12} md={columnsWidth} lg={columnsWidth} style={{ height: '100%' }}>
        <Grid container direction="column" style={{ height: '100%' }} wrap="nowrap">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ flex: '1 1 auto', maxHeight: '70px' }}
          >
            <Grid container alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} style={{ margin: '1em' }}>
                <Grid container alignItems="center">
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    {isPreviewSurvery ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          margin: 10,
                        }}
                      >
                        <ArrowBackIcon
                          onClick={() => {
                            changeStep(0);
                          }}
                          style={{
                            cursor: 'pointer',
                            marginRight: 10,
                          }}
                        />

                        <PageTitle title={title} />
                      </div>
                    ) : (
                      <PageTitle title={title} />
                    )}
                  </Grid>
                  {!disableLocations && (
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <Grid container alignItems="center" justify="flex-end">
                        <Grid item xs={5} sm={5} md={5} lg={5}>
                          <ThemeProvider theme={NoBorderDropDownTheme}>
                            <Dropdown
                              listOptions={ConvertIntoLocationDropDown(locations)}
                              label="Select Location"
                              handleChange={handleSelectLocation}
                              value={groupContainer.selectedGroupLocation}
                            />
                          </ThemeProvider>
                        </Grid>
                        <Grid item xs={5} sm={5} md={5} lg={5}>
                          <ThemeProvider
                            theme={{
                              margin: '0',
                              background: buttonTheme.buttonBgColor,
                              color: buttonTheme.buttonColor2,
                            }}
                          >
                            <CustomButton onClick={() => handleNext(0)}>
                              Upload Contacts
                            </CustomButton>
                          </ThemeProvider>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                          <ThemeProvider
                            theme={{
                              margin: '0',
                              background: buttonTheme.buttonBgColor,
                              color: buttonTheme.buttonColor2,
                            }}
                          >
                            <CustomButton onClick={() => setToggleFilterView((prev) => !prev)}>
                              <StyledTuneIcon />
                            </CustomButton>
                          </ThemeProvider>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{ margin: '1em', overflow: 'auto' }}>
            {isPreviewSurvery ? (
              <MUIThemeProvider theme={customTable2Theme}>
                <CusotmTable
                  data={groupContainerListViewData}
                  columns={groupContainerColumns}
                  options={options}
                  renderElementsPerPage={6}
                />
              </MUIThemeProvider>
            ) : (
              <MUIThemeProvider theme={customTable2Theme}>
                <CustomTable2
                  data={groupContainerListViewData}
                  columns={groupContainerColumns}
                  selectedContactsList={
                    isPreviewSurvery
                      ? groupContainerListViewData
                      : groupContainer.listViewSelectedContact
                  }
                  selectActionType="SET_GROUP_LIST_VIEW_SELECTED_CONTACTS"
                />
              </MUIThemeProvider>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ flex: '1 0 auto', maxHeight: '60px', padding: '.5em 0' }}
          >
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item xs={12} sm={12} md={3} lg={3}>
                {isPreviewSurvery ? (
                  <Grid
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      columnGap: '.5rem',
                    }}
                  >
                    <PrimaryButton text onClick={handlepressCancel}>
                      Cancel
                    </PrimaryButton>

                    <PrimaryButton onClick={filterSelectedList}>Save</PrimaryButton>
                  </Grid>
                ) : (
                  <ThemeProvider
                    theme={{
                      margin: '0',
                      background: buttonTheme.buttonBgColor,
                      color: buttonTheme.buttonColor2,
                    }}
                  >
                    <CustomButton
                      disabled={
                        !(
                          groupContainer.listViewSelectedContact.length > 0 &&
                          groupContainer.isSaved
                        )
                      }
                      onClick={handleSendCampaignFromGroup}
                    >
                      Select Campaign
                    </CustomButton>
                  </ThemeProvider>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {groupViewContent}
    </Grid>
  );
}

GroupListView.defaultProps = {
  title: 'Send Campaigns',
  disableLocations: false,
  isPreviewSurvery: false,
  listViewData: [],
  handlepressSave: () => {},
  handlepressCancel: () => {},
  changeStep: () => {},
};

GroupListView.propTypes = {
  handleNext: Proptypes.func.isRequired,
  handleSendCampaignFromGroup: Proptypes.func.isRequired,
  handlepressSave: Proptypes.func,
  handlepressCancel: Proptypes.func,
  changeStep: Proptypes.func,
  title: Proptypes.string,
  disableLocations: Proptypes.bool,
  isPreviewSurvery: Proptypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  listViewData: Proptypes.arrayOf(Proptypes.object),
};

export default React.memo(GroupListView);
