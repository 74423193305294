import { Grid, Typography, makeStyles } from '@material-ui/core';
import RoomIcon from 'mdi-react/LocationOutlineIcon';
import PhoneIcon from 'mdi-react/PhoneOutlineIcon';
import LanguageIcon from 'mdi-react/WebIcon';
import MessageIcon from 'mdi-react/ForumOutlineIcon';
import React, { useEffect, useState } from 'react';
import { convertIntoPhoneFormat, isEmptyObject } from '../../utils/helper/helper';
import HoursDisplay from './HoursDisplay';
import { Skeleton } from '@material-ui/lab';

function BusinessInformation(props) {
  const { address, phone, website, meta, linkColor, mms, googleDetails } = props;

  const [showPhone, setShowPhone] = useState(false);
  const [showText, setShowText] = useState(false);
  const [ll, setLL] = useState({
    lat: null,
    lng: null,
  });

  const useStyles = makeStyles(() => ({
    link: {
      color: linkColor,
      fontSize: '15px',
      fontWeight: 'normal',
    },
    buttonLink: {
      fontSize: '15px',
      padding: 0,
      fontWeight: 'normal',
      border: 'none',
      background: 'none',
      fontFamily: 'inherit',
      color: linkColor,
      cursor: 'pointer',
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    if (meta && meta !== undefined) {
      setLL({
        lat: meta.latLan && meta.latLan !== undefined ? meta.latLan.latitude : 40.6371226,
        lng: meta.latLan && meta.latLan !== undefined ? meta.latLan.longitude : -111.902314,
      });
    }
  }, [meta]);

  return (
    <Grid
      container
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1rem',
        alignItems: 'flex-start',
      }}
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography
          style={{
            color: '#000',
            fontFamily: 'inherit',
            fontWeight: '600',
          }}
        >
          Business information
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{
          alignItems: 'center',
          display: 'flex',
          columnGap: '.5rem',
        }}
      >
        <RoomIcon />
        <span style={{ fontSize: '15px', fontWeight: 'normal' }}>
          {!address ? (
            <Skeleton width={'250px'} />
          ) : (
            (googleDetails && googleDetails.formatted_address) || address
          )}
        </span>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{
          alignItems: 'center',
          display: 'flex',
          columnGap: '.5rem',
        }}
      >
        <PhoneIcon />
        {showPhone ? (
          <a
            className={classes.link}
            href={`tel:${convertIntoPhoneFormat(
              (googleDetails && googleDetails.international_phone_number.replace(/[ -]+/g, '')) ||
                phone,
            )}`}
          >
            {convertIntoPhoneFormat(
              (googleDetails && googleDetails.international_phone_number.replace(/[ -]+/g, '')) ||
                phone,
            )}
          </a>
        ) : (
          <button className={classes.buttonLink} onClick={() => setShowPhone(true)}>
            Call us
          </button>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{
          alignItems: 'center',
          display: 'flex',
          columnGap: '.5rem',
        }}
      >
        <MessageIcon />
        {showText ? (
          <a className={classes.link} href={`sms:${'+1' + mms}`}>
            {convertIntoPhoneFormat('+1' + mms)}
          </a>
        ) : (
          <button className={classes.buttonLink} onClick={() => setShowText(true)}>
            Text us
          </button>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{
          alignItems: 'center',
          display: 'flex',
          columnGap: '.5rem',
        }}
      >
        <LanguageIcon />
        <a className={classes.link} href={website} target="_blank">
          {!website ? <Skeleton width={'250px'} /> : website}
        </a>
      </Grid>
      {googleDetails && googleDetails.current_opening_hours ? (
        <HoursDisplay schedule={googleDetails.current_opening_hours.weekday_text}></HoursDisplay>
      ) : null}
    </Grid>
  );
}

export default BusinessInformation;
