import React, { useState, useEffect } from 'react';
import StarIcon from 'mdi-react/StarIcon';

export const StarRating = (props) => {
  const { currentAnswer, setCurrentAnswer } = props;
  // const [rating, setRating] = useState(null);

  // const setFinalRating = (stars) => {
  //   setRating(stars);
  //   setCurrentAnswer(stars);
  // };

  // useEffect(() => {
  //   if (currentAnswer !== null) {
  //     setRating(currentAnswer);
  //     setCurrentAnswer(currentAnswer);
  //   } else {
  //     setRating(null);
  //   }
  // }, []);

  return (
    <div style={{ marginLeft: '-5px' }}>
      {[1, 2, 3, 4, 5].map((star, i) => (
        <span key={i} onClick={() => setCurrentAnswer(star)}>
          {currentAnswer >= star ? (
            <StarIcon size={30} color={'#ffbe21'} />
          ) : (
            <StarIcon size={30} color={'#e2e2e2'} />
          )}
        </span>
      ))}
    </div>
  );
};
