import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { convertIntoPhoneFormat, getInitials } from '../../utils/helper/helper';
import {
  Button,
  Box,
  Chip,
  IconButton,
  ThemeProvider as MUIThemeProvider,
} from '@material-ui/core';
import { format } from 'date-fns';
import CloseButton from 'mdi-react/CloseIcon';
import AddIcon from 'mdi-react/AddCircleOutlineIcon';
import usePopover from '../../hooks/usePopover';
import usePopOverTheme from '../../hooks/usePopOverTheme';
import TagSearchPopover from './TagSearchPopover';
import baseUrl from '../../utils/services/config';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import { errorChecker, getUserName } from '../../utils/helper/helper';
import { Skeleton } from '@material-ui/lab';
import UserChatActionPanel from '../UserChatActionPanel/UserChatActionPanel';
import AttachmentPopover from '../../components/Popover/AttachmentPopover/AttchmentPopover';
import CampaignListPopOver from '../../components/Popover/CampaignListPopOver/CampaignListPopOver';
import CampaignStore from '../../Stores/CamapignStore';
import { io } from '../../socket_context/initSocket';
import useAlert from '../../hooks/useAlert';
import CusotmAlert from '../CustomAlert/CustomAlert';
import Note from 'mdi-react/NoteOutlineIcon';
import NewNote from 'mdi-react/NoteAddOutlineIcon';
import Message from 'mdi-react/MessageOutlineIcon';
import AddNotePopover from '../Popover/AddNotePopover/AddNotePopover';
import useModal from '../../hooks/useModal';

const StyledSidebar = styled.div`
  background-color: #eaeaea;
  flex-direction: column;
  display: flex;
  border-left: 1px solid #eaeaea;
  transition: all 0.3s;
  width: 400px;
  top: 60px;
  right: 0;
  bottom: 0;
  position: fixed;
`;
const StyledUserBox = styled.div`
  background-color: #f5f5f5;
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StyledDetailsBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: 20px 0;
`;

const StyledUserName = styled.span`
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 270px;
`;

const StyledUserAvatar = styled.div`
  font-size: 24px;
  background-color: #30feaf;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  display: flex;
  font-weight: 700;
  margin-right: 10px;
`;

const StyledPhoneNumber = styled.span`
  color: #898989;
  font-size: 14px;
  margin-bottom: 20px;
`;

const SidebarTagContainer = styled.div`
  background-color: #f5f5f5;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 1.5rem;
  padding: 0.5rem;
  align-items: center;
`;

const EditAndClose = styled.div`
  position: absolute !important;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
`;
const StyledChip = styled(Chip)`
  margin: 0.1rem;
  font-family: inherit !important;
`;
const NoteContent = styled.div`
  padding: 1rem;
  border-bottom: 1px solid #d8d8d8;
  font-size: 13px;
  display: flex;
  align-items: center;
`;
const Username = styled.span`
  color: gray;
  margin-bottom: 5px;
`;

const NoteDate = styled.span`
  font-weight: 700;
  color: #898989;
  font-size: 12px;
`;

const SidebarTitle = styled.span`
  font-size: 12px;
  color: gray;
  font-weight: 600;
  margin-bottom: 10px;
`;
const SidebarContainer = styled.div`
  background-color: #f5f5f5;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  margin-bottom: 1rem;
  max-height: 188px;
  overflow-y: auto;
`;
const StyledButton = styled(Button)`
  background: transparent;
  // border: 1px solid #ababab !important;
  text-transform: capitalize !important;
  //width: 150px;
  letter-spacing: none;
  & svg {
    margin-right: 8px;
  }
  font-family: 'Montserrat' !important;
  margin-top: 1rem !important;
`;

export const MessageContactSidebar = (props) => {
  const { contact, handleToggleEditSidebar, isMessageContactSidebarExpanded, currentConversation } =
    props;
  const { dispatch, state } = useContext(GlobalContext);
  // const { socketState, socketDispatch } = useContext(SocketContext);
  // const { conversationContainer, isConversationsLoading } =
  // socketState;

  const { selectedLoaction, selectedBusiness } = state;
  const conversationAlert = useAlert();

  const [sendMessage, setSendMessage] = useState('');
  const [campaginLoader, setCampaignLoader] = useState(false);
  const [otherCardFileName, setOtherCardFileName] = useState('');
  const [selectedCampagin, setSelectedCampagin] = useState(null);
  const [recentMessages, setRecentMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const tagSearchPopover = usePopover();
  const { popOverTheme } = usePopOverTheme();
  const campaignListPopOver = usePopover();

  const [showTagSkeleton, setShowTagSkeleton] = useState(false);

  const [tagData, setTagData] = useState([]);
  const attachmentPopover = usePopover();
  const addNotePopover = useModal();

  // const handleSaveTag = async (tagToAdd) => {
  //   const newList = Array.from(tagData);
  //   newList.push(tagToAdd);
  //   setTagData(newList);
  //   tagSearchPopover.handleClose();

  //   const postBody = {
  //     tags: newList,
  //   };
  //   try {
  //     const results = await baseUrl.patch(`/client/${conversation.client}`, { ...postBody });
  //     if (results.status === 200) {
  //     }
  //   } catch (err) {
  //     dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
  //   }
  // };

  // const handleDeleteTag = async (tagToDelete) => {
  //   const newTags = tagData.filter((tag) => tag.label !== tagToDelete.label);
  //   setTagData(newTags);
  //   // try {
  //   const postBody = {
  //     tags: newTags,
  //   };
  //   try {
  //     const results = await baseUrl.patch(`/client/${conversation.client}`, { ...postBody });
  //     if (results.status === 200) {
  //     }
  //   } catch (err) {
  //     dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
  //   }
  // };

  // const handleAddGlobalTag = async (tagToAdd) => {
  //   handleSaveTag({ label: tagToAdd });

  //   //save new tag to global business tag list
  //   const globalList = businessInfo.tags !== null ? Array.from(businessInfo.tags) : [];
  //   globalList.push({ label: tagToAdd });

  //   try {
  //     const postBody = {
  //       businessId: localStorage.getItem('SELECTED_BUSINESS'),
  //       tags: JSON.stringify(globalList),
  //     };
  //     const result = await baseUrl.post(`business/saveGlobalTags`, { ...postBody });
  //     if (result.status === 200) {
  //     }
  //   } catch (err) {
  //     dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
  //   }
  // };

  // const conversationTags = tagData
  //   ? tagData.map((tag, index) => (
  //       <li key={index}>
  //         <StyledChip
  //           size="small"
  //           label={<span>{tag.label}</span>}
  //           onDelete={() => handleDeleteTag(tag)}
  //         />
  //       </li>
  //     ))
  //   : null;

  // const getTags = () => {
  //   const finalTags =
  //     tagData !== undefined ? (
  //       <>
  //         <SidebarTitle>Tags</SidebarTitle>
  //         <SidebarTagContainer>
  //           {showTagSkeleton ? (
  //             <Skeleton variant={'rect'} height={16} width={150} />
  //           ) : (
  //             conversationTags
  //           )}
  //           <AddButton onClick={tagSearchPopover.handleClick}>
  //             <AddIcon size="24px" color="#58e298" />
  //           </AddButton>
  //           <MuiThemeProvider theme={popOverTheme}>
  //             <TagSearchPopover
  //               handleClose={tagSearchPopover.handleClose}
  //               open={tagSearchPopover.open}
  //               id={tagSearchPopover.id}
  //               anchorEl={tagSearchPopover.anchorEl}
  //               handleAddGlobalTag={handleAddGlobalTag}
  //               handleSaveTag={handleSaveTag}
  //               currentTagList={tagData}
  //             />
  //           </MuiThemeProvider>
  //         </SidebarTagContainer>
  //       </>
  //     ) : null;
  //   return finalTags;
  // };

  // const getNotes = () => {
  //   const allNotes = conversation.messages.filter((item) => item.type === 'note');

  //   const finalNotes =
  //     allNotes.length > 0 ? (
  //       <>
  //         <SidebarTitle>Notes</SidebarTitle>
  //         <SidebarContainer>
  //           {allNotes.map((note, index) => (
  //             <NoteContent key={index}>
  //               <Note size={'18px'} />
  //               <Box width={'88%'} marginLeft={'10px'} display="flex" flexDirection="column">
  //                 <Box marginBottom="5px">
  //                   <Username>{note.meta.user}</Username>
  //                   <NoteDate align="center"> • {format(note.updatedAt, 'LLL d, yyyy')}</NoteDate>
  //                 </Box>
  //                 {note.meta.message}
  //               </Box>
  //             </NoteContent>
  //           ))}
  //         </SidebarContainer>
  //       </>
  //     ) : null;
  //   return finalNotes;
  // };
  useEffect(() => {
    getConversations();
  }, []);

  const handleSendMessageOnChange = (e) => {
    setSendMessage(e.target.value);
  };

  const handleSelectedCampaign = (info) => {
    setSendMessage(info.message);
    setSelectedCampagin(info);
    campaignListPopOver.handleClose();
  };

  const getConversations = () => {
    setIsLoading(true);
    io.socket.get(
      `/api/conversations-by-client?id=${contact.id}`,
      function serverResponded(body, JWR) {
        if (JWR.statusCode === 200) {
          if (body.length) {
            setRecentMessages(body[0].messages);
          }
          setIsLoading(false);
        } else {
        }
      },
    );
  };

  const handleAddNote = () => {
    addNotePopover.handleShowModal();
  };

  const handleSaveNote = async (noteContent) => {
    const { handleSetMessage, handleSetMessageType, handleShow } = conversationAlert;

    try {
      const postBody = {
        client: contact.id,
        note: noteContent,
        userName: `${contact.firstName} ${contact.lastName}`,
        location: selectedLoaction,
      };
      io.socket.post(`/api/conversation/add-note`, postBody, function serverResponded(body, JWR) {
        if (JWR.statusCode === 200) {
          handleSetMessageType('success');
          handleSetMessage('Note added successfully');
          handleShow(false);
        } else {
        }
      });
    } catch (err) {
      //dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const handleBusinessCardChange = async () => {
    const { handleSetMessage, handleSetMessageType, handleShow } = conversationAlert;

    try {
      const userId = parseInt(localStorage.getItem('USER_ID'), 10);
      const postBody = {
        source: 'vcard',
        client: contact.id,
        location: selectedLoaction,
        business: selectedBusiness,
        user: userId,
        message: '',
        phone: contact.phone || null,
        meta: {
          vacardUrl: `${process.env.REACT_APP_IMAGE_ENDPOINT}/public/vcard/${userId}.vcf`,
        },
        attributed: userId,
      };
      attachmentPopover.handleClose();
      const vcardResult = await baseUrl.post(`/conversation/vcard`, { ...postBody });
      if (vcardResult.status === 200) {
        handleSetMessageType('success');
        handleSetMessage('Message sent successfully');
        handleShow(false);
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const handleMessageSend = async () => {
    const { handleSetMessage, handleSetMessageType, handleShow } = conversationAlert;

    try {
      if (selectedCampagin) {
        setCampaignLoader(true);
        const postBody = {
          client: contact.id,
          location: selectedLoaction,
          user: parseInt(localStorage.getItem('USER_ID'), 10),
          campaign: selectedCampagin.id,
        };

        const result = await baseUrl.post(`/reviewsrequest`, { ...postBody });
        if (result.status === 200) {
          setSelectedCampagin(null);
          setSendMessage('');
          setCampaignLoader(false);
        }
      } else {
        setCampaignLoader(true);
        const postBody = {
          client: contact.id,
          type: 'attributed',
          phone: contact.phone,
          attributed: parseInt(localStorage.getItem('USER_ID'), 10),
          location: selectedLoaction,
          user: parseInt(localStorage.getItem('USER_ID'), 10),
          message: sendMessage,
        };

        io.socket.post(
          `/api/conversation/text-message`,
          postBody,
          function serverResponded(body, JWR) {
            if (JWR.statusCode === 200) {
              setSelectedCampagin(null);
              setSendMessage('');
              setCampaignLoader(false);
              handleSetMessageType('success');
              handleSetMessage('Message sent successfully');
              handleShow(false);
            } else {
            }
          },
        );
      }
    } catch (err) {
      setCampaignLoader(false);
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  // Attachments of other types expect business image handler method
  const handleOtherAttachmentChange = async (e) => {
    console.log('attaching a file...');
    setOtherCardFileName(e.target.files[0].name);
    const picture = new FormData();
    picture.append('picture', e.target.files[0]);
    try {
      const result = await baseUrl.post(
        `/uploads/conversation?type=conversations&meta=attachment`,
        picture,
      );
      if (result.status === 200) {
        const postBody = {
          uploadedFile: result.data[0].id,
          source: 'attachment',
          client: contact.id,
          location: selectedLoaction,
        };
        attachmentPopover.handleClose();
        const results = await baseUrl.post(`conversation/attach-file`, { ...postBody });
        if (results.status === 200) {
          setOtherCardFileName('');

          setTimeout(() => {}, 100);
        }
      }
    } catch (err) {
      setOtherCardFileName('');
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const getNotes = () => {
    const allNotes = recentMessages && recentMessages.filter((item) => item.type === 'note');
    const sortedData = allNotes.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

    const finalNotes =
      sortedData && sortedData.length > 0 ? (
        <>
          <SidebarTitle>Notes</SidebarTitle>
          <SidebarContainer>
            {sortedData.map((note, index) => (
              <NoteContent key={index}>
                <Note size={'18px'} />
                <Box width={'88%'} marginLeft={'10px'} display="flex" flexDirection="column">
                  <Box marginBottom="5px">
                    <Username>{note.meta.user}</Username>
                    <NoteDate align="center"> • {format(note.updatedAt, 'LLL d, yyyy')}</NoteDate>
                  </Box>
                  {note.meta.message}
                </Box>
              </NoteContent>
            ))}
          </SidebarContainer>
        </>
      ) : null;
    return finalNotes;
  };

  const getRecentMessages = () => {
    const allNotes = recentMessages && recentMessages.filter((item) => item.type !== 'note');
    const sortedData = allNotes.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

    const finalNotes = isLoading ? (
      <>
        <SidebarTitle>Recent Messages</SidebarTitle>
        <Box style={{ borderRadius: 10, overflow: 'hidden' }}>
          <Skeleton variant="rect" height={180} />
        </Box>
      </>
    ) : sortedData && sortedData.length > 0 ? (
      <>
        <SidebarTitle>Recent Messages</SidebarTitle>
        <SidebarContainer>
          {sortedData.map((message, index) => (
            <NoteContent key={index}>
              <Message size={'18px'} />
              <Box width={'88%'} marginLeft={'10px'} display="flex" flexDirection="column">
                <Box marginBottom="5px">
                  {message.attributed ? (
                    <>
                      <Username>{getUserName(message.attributed)}</Username> •{' '}
                    </>
                  ) : null}
                  <NoteDate align="center">{format(message.updatedAt, 'LLL d, yyyy')}</NoteDate>
                </Box>
                {message.text}
              </Box>
            </NoteContent>
          ))}
        </SidebarContainer>
      </>
    ) : null;
    return finalNotes;
  };

  const sidebarContent =
    contact !== undefined ? (
      <>
        <StyledSidebar isMessageContactSidebarExpanded={isMessageContactSidebarExpanded.toString()}>
          <StyledUserBox>
            {isMessageContactSidebarExpanded.toString() === 'true' ? (
              <EditAndClose>
                <Button>
                  <CloseButton onClick={() => handleToggleEditSidebar()} />
                </Button>
              </EditAndClose>
            ) : null}
            <Box display="flex" alignItems="flex-start" flexDirection="column">
              <Box flexDirection="row" display="flex" alignItems="center">
                <StyledUserAvatar>{getInitials(contact.name)}</StyledUserAvatar>
                <Box display="flex" flexDirection="column">
                  <StyledUserName>{contact?.name}</StyledUserName>
                  <StyledPhoneNumber>{contact.phone}</StyledPhoneNumber>
                </Box>
              </Box>
              {/* <StyledButton variant="outlined" size="small" onClick={() => handleAddNote()}>
                <NewNote size="18px" /> Add a note
              </StyledButton> */}
            </Box>
          </StyledUserBox>
          <StyledDetailsBox>
            <Box display="flex" padding="0 20px" flexDirection="column">
              {getNotes()}
              {getRecentMessages()}
            </Box>
            <UserChatActionPanel
              campaginLoader={false}
              handleShowSelectedAttachment={() => {}}
              handleShowAttachmentPopup={attachmentPopover.handleClick}
              conversationType={'open'}
              capamignListId={campaignListPopOver.id}
              handleShowCampaignList={campaignListPopOver.handleClick}
              handleMessageSend={handleMessageSend}
              handleSendMessageOnChange={handleSendMessageOnChange}
              sendMessage={sendMessage}
              currentConversation={currentConversation}
            />
          </StyledDetailsBox>
          <MUIThemeProvider theme={popOverTheme}>
            <AttachmentPopover
              anchorEl={attachmentPopover.anchorEl}
              handleClose={attachmentPopover.handleClose}
              open={attachmentPopover.open}
              id={attachmentPopover.id}
              handleBusinessCardChange={handleBusinessCardChange}
              handleOtherAttachmentChange={handleOtherAttachmentChange}
              otherCardFileName={otherCardFileName}
            />
            <CampaignStore>
              <CampaignListPopOver
                anchorEl={campaignListPopOver.anchorEl}
                handleClose={campaignListPopOver.handleClose}
                open={campaignListPopOver.open}
                id={campaignListPopOver.id}
                handleSelectedCampaign={handleSelectedCampaign}
                campaginLoader={campaginLoader}
                contactId={contact.id}
              />
            </CampaignStore>
          </MUIThemeProvider>
        </StyledSidebar>
        <CusotmAlert
          message={conversationAlert.message}
          handleClose={conversationAlert.handleClose}
          open={conversationAlert.show}
          messageType={conversationAlert.messageType}
        />
        <AddNotePopover
          handleClose={addNotePopover.handleHideShowModal}
          open={addNotePopover.showModal}
          handleAddNote={handleSaveNote}
        />
      </>
    ) : null;

  return <>{sidebarContent}</>;
};
