import React from 'react';
import Proptypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import { styled } from '@material-ui/styles';
import { DialogContent, DialogTitle, Grid, DialogActions } from '@material-ui/core';
import { ThemeProvider } from 'styled-components';
import CustomButton from '../../CustomButton/CustomButton';
import TransperentButtonTheme from '../../../themes/Button/TransperentButtonTheme';
import ButtonLoader from '../../ButtonLoader/ButtonLoader';
import GenericPopup from '../GenricPopup/index';
import useButtonTheme from '../../../hooks/useButtonTheme';

const StyledDialogTitle = styled(DialogTitle)`
  letter-spacing: 1.08px;
  color: #6c6c6c;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 400 !important;
  & > h2 {
    letter-spacing: 1.08px;
    color: #6c6c6c;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 400 !important;
  }
`;

const StyledActions = styled(DialogActions)`
  padding: 0 0.5em !important;
`;

const StyledContentDialog = styled(DialogContent)`
  padding: 0 0.5em !important;
`;

const cancelBtn = {
  padding: '6px 8px',
};

function ReActivePopup(props) {
  const {
    show,
    handleReActiveClose,
    showReactivePopUp,
    reactiveLoader,
    title,
    description,
    buttonTitle,
  } = props;
  const { buttonTheme } = useButtonTheme();
  const showLoader = reactiveLoader ? <ButtonLoader /> : null;
  return (
    <GenericPopup open={show} maxWidth="xs" onClose={handleReActiveClose}>
      <StyledDialogTitle id="scroll-dialog-title">
        <Grid container justify="flex-end">
          <Grid item>
            <ThemeProvider theme={TransperentButtonTheme}>
              <CustomButton onClick={handleReActiveClose} title="Close">
                <ClearIcon />
              </CustomButton>
            </ThemeProvider>
          </Grid>
        </Grid>
      </StyledDialogTitle>
      <StyledContentDialog>
        <h2 style={{ textAlign: 'center', color: '#6C6C6C', margin: '0' }}>{title}</h2>
      </StyledContentDialog>
      <StyledContentDialog>
        <p style={{ textAlign: 'center', color: '#6C6C6C', margin: '0' }}>{description}</p>
      </StyledContentDialog>
      <StyledActions>
        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={3} lg={4}>
            <ThemeProvider
              theme={{
                ...cancelBtn,
                background: buttonTheme.buttonBgColor,
                color: buttonTheme.fontColor3,
              }}
            >
              <CustomButton onClick={showReactivePopUp} color="primary" disabled={reactiveLoader}>
                {buttonTitle}
                {showLoader}
              </CustomButton>
            </ThemeProvider>
          </Grid>
        </Grid>
      </StyledActions>
    </GenericPopup>
  );
}

ReActivePopup.propTypes = {
  show: Proptypes.bool.isRequired,
  handleReActiveClose: Proptypes.func.isRequired,
  showReactivePopUp: Proptypes.func.isRequired,
  reactiveLoader: Proptypes.bool.isRequired,
  title: Proptypes.string,
  description: Proptypes.string,
  buttonTitle: Proptypes.string,
};

ReActivePopup.defaultProps = {
  title: 'Are you sure?',
  description: 'Do you really want to revert these records?',
  buttonTitle: 'ReActive',
};

export default ReActivePopup;
