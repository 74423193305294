import React, { useContext } from 'react';
import Proptypes from 'prop-types';
import { Grid, makeStyles, Popover } from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import NotificationList from '../../List/NotificationList';
import PageTitle from '../../PageTitle/PageTitle';
import { GlobalContext } from '../../../Stores/GlobalStateStore';
import ButtonLoader from '../../ButtonLoader/ButtonLoader';
import NoDataFound from '../../NoDataFound/NoDataFound';

const StyledContainer = styled(Grid)`
  padding: 0 !important;
  min-width: 450px !important;
  max-width: 450px !important;
`;

const titleStyle = {
  fontSize: '28px',
  padding: '5px',
};

const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: 'hidden',
    background: theme.background,
  },
}));

function NotificationPopover(props) {
  const classes = useStyles();
  const { id, open, anchorEl, handleClose } = props;
  const { state } = useContext(GlobalContext);
  const { notificationContainer, notificationLoader } = state;
  const notificationContent = notificationContainer.map((item, index) => (
    <NotificationList
      key={index}
      {...item}
      notificationContainer={notificationContainer}
      index={index}
    />
  ));

  const loaderContent = notificationLoader ? (
    <Grid container alignItems="center" justify="center">
      <Grid item>
        <ButtonLoader />
      </Grid>
    </Grid>
  ) : (
    notificationContent
  );

  const notificationContentContainer = notificationContainer.length ? (
    loaderContent
  ) : (
    <NoDataFound>No Notifications found</NoDataFound>
  );

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      classes={{
        paper: classes.paper,
      }}
    >
      <StyledContainer container direction="column">
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ padding: '0.5em' }}>
          <ThemeProvider theme={titleStyle}>
            <PageTitle title="Notifications" />
          </ThemeProvider>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ overflow: 'auto', flex: '1 1 auto', height: '320px', padding: '0' }}
        >
          <Grid container alignItems="center" justify="center">
            {notificationContentContainer}
          </Grid>
        </Grid>
      </StyledContainer>
    </Popover>
  );
}

NotificationPopover.propTypes = {
  open: Proptypes.bool.isRequired,
  handleClose: Proptypes.func.isRequired,
};

export default NotificationPopover;
