import React, { useContext } from 'react';
import Proptypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { Grid } from '@material-ui/core';
import { OnBoardingContext } from '../../Stores/OnBoarding';
import UploadLayout from '../UploadLayout';
import GalleryPopup from '../Popup/Gallarypopup';
import useModal from '../../hooks/useModal';

const StyledUploaderContainer = styled(Grid)`
  height: 100% !important;
  // max-height: 125px !important;
`;

function EditableImage(props) {
  const { activeStep, radioValue, isEditable, requestType, uploadFileId, urlId, type } = props;
  const { onBoardingDispatch, onBoardingData } = useContext(OnBoardingContext);
  const { defaultPortals } = onBoardingData;

  const gallaryModal = useModal();

  const handleImagesContainer = ({ fileId, url }) => {
    const defaultPortalCopy = { ...defaultPortals };
    // if (urlId === 'url') {
    //   updateImageInfoOfPortals(defaultPortalCopy, radioValue, id, url);
    // }
    defaultPortalCopy[radioValue].screens[0][uploadFileId] = fileId;
    defaultPortalCopy[radioValue].screens[0][urlId] = url;
    onBoardingDispatch({ type: 'SET_DEFAULT_PORTAL', payload: { ...defaultPortalCopy } });
  };

  const textContainer1 = (
    <ThemeProvider theme={{ width: '85%' }}>
      <UploadLayout
        handleClick={isEditable ? gallaryModal.handleShowModal : () => {}}
        url={
          defaultPortals[radioValue].screens[activeStep][urlId]
            ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${defaultPortals[radioValue].screens[activeStep][urlId]}`
            : ''
        }
        type={type}
        imgHeight="250px"
      />
    </ThemeProvider>
  );

  return (
    <StyledUploaderContainer item xs={12} sm={12} lg={12} md={12}>
      {textContainer1}

      <GalleryPopup
        show={gallaryModal.showModal}
        radioValue={radioValue}
        handleClose={gallaryModal.handleHideShowModal}
        key="gallary-popup-modal"
        requestType={requestType}
        handleImagesContainer={handleImagesContainer}
        type={type}
      />
    </StyledUploaderContainer>
  );
}

EditableImage.propTypes = {
  activeStep: Proptypes.number.isRequired,
  radioValue: Proptypes.string.isRequired,
  isEditable: Proptypes.bool,
  requestType: Proptypes.string.isRequired,
  uploadFileId: Proptypes.string,
  urlId: Proptypes.string,
  type: Proptypes.string.isRequired,
};

EditableImage.defaultProps = {
  isEditable: true,
  uploadFileId: 'uploadedFile',
  urlId: 'url',
};

export default EditableImage;
