import React, { useState } from 'react';
import { Box, MuiThemeProvider } from '@material-ui/core';
import useTabsTheme from '../../../../../hooks/useTabsTheme';
import AntTab from '../../../../StyledTabs/StyledTabs';
import AntTabs from '../../../../StyledTabsContainer/StyledTabsContainer';
import Message from '../AddCampaign/Message/Message';
import Survey from '../AddCampaign/Survey';
import Request from '../AddCampaign/Request';

const wrapperStyles = { position: 'relative', padding: '0 1rem 1rem 1rem' };
const containerStyles = {
  margin: '1rem .5rem 1.5rem .5rem',
};

const RequestSettings = ({ campaignType, changeStep, campaignId }) => {
  const { tabsTheme } = useTabsTheme();
  const [tabValue, setTabValue] = useState(1);
  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  const campaignTypeTabs = () => {
    switch (campaignType) {
      case 'message':
        return (
          <Box style={containerStyles}>
            <MuiThemeProvider theme={tabsTheme}>
              <AntTabs value={tabValue} onChange={handleTabChange} aria-label="ant">
                <AntTab label="Message" />
              </AntTabs>
            </MuiThemeProvider>
          </Box>
        );

      default:
        return null;
    }
  };
  const handleClose = () => {
    changeStep(0);
  };

  return (
    <>
      <Box style={wrapperStyles}>
        {campaignTypeTabs()}
        {campaignType === 'message' && (
          <Message handleClose={handleClose} campaignId={campaignId} />
        )}
        {campaignType === 'survey' && (
          <Survey tabValue={tabValue} handleClose={handleClose} campaignId={campaignId} />
        )}
        {campaignType === 'review' && <Request campaignId={campaignId} handleClose={handleClose} />}
      </Box>
    </>
  );
};

export default RequestSettings;
