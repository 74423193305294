/* eslint-disable */
//TODO: Refactor out into a helper or logic file
import baseUrl from '../utils/services/config';
import { sortByRelaventMessageDate } from '../utils/helper/helper';
//import { calculateConversationCounts } from './messageCount.logic';

const addConversationOrMessage = (prevConversations, message) => {
  //console.log(prevConversations.find((conv) => conv.client === message.client));
  const newMessage = {
    id: message.id,
    createdAt: message.createdAt,
    updatedAt: message.updatedAt,
    meta: message.meta,
    source: message.source,
    text: message.message,
    isClosed: message.isClosed,
    isSeen: message.isSeen,
    location: message.location,
    attachment: message.attachment,
    type: message.type,
    attributed: message.attributed,
  };
  if (!prevConversations.find((conv) => conv.client === message.client)) {
    const newConversation = {
      id: message.id,
      name: message.name,
      phone: message.phone,
      unsubscribeMobile: message.unsubscribeMobile,
      unsubscribeEmail: message.unsubscribeEmail,
      client: message.client,
      location: message.location,
      updatedAt: message.updatedAt,
      isSeen: message.isSeen,
      messages: [newMessage],
    };

    return [...prevConversations, newConversation].sort(
      (a, b) =>
        b.messages[b.messages.length - 1].updatedAt - a.messages[a.messages.length - 1].updatedAt,
    );
  }
  return prevConversations
    .map((conversation) => {
      if (conversation.client === message.client) {
        if (!conversation.messages.find((i) => i.id === newMessage.id)) {
          conversation.messages = [...conversation.messages, newMessage];
        }
        // const conversationCopy = Array.from(prevConversations);
        // conversationCopy[0].isSeen = false;
      }

      return conversation;
    })
    .sort(
      (a, b) =>
        b.messages[b.messages.length - 1].updatedAt - a.messages[a.messages.length - 1].updatedAt,
    );
};

const fetchAllClients = async () => {
  const selectedLoaction = localStorage.getItem('SELECTED_LOCATION');
  if (selectedLoaction) {
    try {
      const result = await baseUrl(`/location/${selectedLoaction}/client`);
      if (result.status === 200) {
        //console.log(result);
        return result.data;
        //dispatch({ type: 'UPDATE_ALL_CLIENTS', payload: result.data });
      }
    } catch (err) {
      //dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      // dispatch({ type: 'SHOW_ERROR', payload: 'could not get location details' });
    }
  }
};

const socketReducer = (state, action) => {
  //console.log('CONVERSATIONS SET');
  switch (action.type) {
    case 'SET_CONVERSATIONS':
      const betterPayload = action.payload
        ? action.payload.sort(sortByRelaventMessageDate)
        : action.payload;
      return {
        ...state,
        conversationContainer: betterPayload,
      };
    case 'SET_PREVIOUS_CONVERSATIONS':
      return {
        ...state,
        previousConversations: action.payload,
      };

    case 'UPDATE_CONVERSATIONS_LIST':
      return {
        ...state,
        conversationContainer: [...state.conversationContainer, ...action.payload].sort(
          sortByRelaventMessageDate,
        ),
      };

    case 'SET_UNFILTERED_CONVERSATIONS':
      return {
        ...state,
        allConversations: action.payload,
      };

    case 'UPDATE_CONVERSATIONS':
      const conversationContainer = addConversationOrMessage(
        state.conversationContainer,
        action.payload,
      );

      const conversationCopy = Array.from(conversationContainer);

      //Mark the conversation as new as long as it's not a note or inbox moving or attribution
      let source = conversationCopy[0].messages[conversationCopy[0].messages.length - 1].source;
      let type = conversationCopy[0].messages[conversationCopy[0].messages.length - 1].type;
      if (source !== 'system' && source !== 'note' && type !== 'attributed') {
        conversationCopy[0].isSeen = false;
      }

      return {
        ...state,
        //conversationCounts: calculateConversationCounts(state.allConversations),
        conversationContainer: conversationCopy,
        //allClients: fetchAllClients(),
      };

    case 'UPDATE_AFTER_DELETE':
      //const updatedContainer = refreshConversations();
      return {
        ...state,
        shouldRefresh: action.payload,
      };

    case 'SET_SELECTED_CONVERSATION':
      // console.log('SETTING SELECTED CONV.');
      return {
        ...state,
        selectedConversation: action.payload,
        //conversationCounts: calculateConversationCounts(state.allConversations),
      };

    case 'UPDATE_DOCUMENT_TITLE':
      const unreadConversations = state.conversationContainer.filter((obj) => {
        if (obj.isSeen === false) {
          return true;
        }
        return false;
      }).length;

      let faviconPath;

      if (unreadConversations > 0) {
        faviconPath = '/favicon_notify.png';
      } else {
        faviconPath = '/favicon.png';
      }

      const finalTitle = unreadConversations === 0 ? 'Reveo' : `(${unreadConversations}) Reveo`;

      return {
        ...state,
        documentTitle: finalTitle,
        faviconHref: faviconPath,
        //conversationCounts: calculateConversationCounts(state.allConversations),
      };

    case 'UPDATE_ALL_CLIENTS': {
      return {
        ...state,
        allClients: action.payload,
      };
    }
    case 'UPDATE_CONVERSATION_COUNTS': {
      return {
        ...state,
        conversationCounts: action.payload,
      };
    }
    case 'IS_CONVERSATIONS_LOADING': {
      return {
        ...state,
        isConversationsLoading: action.payload,
      };
    }
    case 'CAMPAIGN_SEND_COMPLETE': {
      return {
        ...state,
        campaignSendComplete: action.payload,
      };
    }
    case 'CAMPAIGN_SEND_UPDATE': {
      return {
        ...state,
        campaignSendUpdate: action.payload,
      };
    }
    case 'FORCE_LOGOUT': {
      return {
        ...state,
        forceLogout: action.payload,
      };
    }
    default:
      return state;
  }
};

export default socketReducer;
