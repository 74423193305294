import React, { useCallback, useEffect, useState } from 'react';
// import { makeStyles, Card, CardContent, Typography, Grid } from '@material-ui/core';
// import styled, { ThemeProvider } from 'styled-components';
// import StarIcon from '@material-ui/icons/Star';
// import CustomButton from '../../components/CustomButton/CustomButton';
// import CustomRating from '../../components/CusotmRating/CusotmRating';
import baseUrl from '../../utils/services/config';
import {
  getParaByName,
  imageURLCheck,
  capitalizeFirstLetter,
  getDateFromTimeStamp,
  getRatingFromNameValue,
} from '../../utils/helper/helper';
// //import ProgressBar from './ProgressBarComponent';

// const dummyProgressBar = {
//   backgroundColor: '#58E298',
//   fontColor: '#FFFFFF',
// };

// const StyledSummaryCard = styled(Grid)`
//   height: 140px;
//   padding: 0.5em;

//   // @media screen and (min-width: 1920px) {
//   //   & {
//   //     height: 200px;
//   //   }
//   // }
// `;

// const SubText = styled.p`
//   margin: 0 !important;
//   font-size: 16px;
//   color: #939393;
// `;

// function AllInOneReviews() {

//   const [settings, setSettings] = useState({
//     settings: {
//       settings: {
//         shape: 'square',
//         backgroundColor: '#FFFFFF',
//         button: {
//           fontSize: '12',
//           bgColor: '#4d6fd5',
//           color: '#ffffff',
//         },
//         paragraph: {
//           fontSize: '12',
//           color: '#d2bcbc',
//         },
//         header: {
//           Headerfontsize: '24',
//           HeaderfontColour: '#f5e5e5',
//         },
//         ratingfontColour: '#ffa338',
//         ratingfontsize: '12',
//         progress: {
//           backgroundColor: '#58E298',
//           fontColor: '#fff',
//         },
//       },
//     },
//   });

//   const fetchMainsettings = useCallback(async () => {
//     const result = await baseUrl.get(`/micro-site-hybrid-widgets?id=${getParaByName('id')}`);
//     if (result.status === 200) {
//       setHybridWidgetsData(result.data.list);
//       setSettings(result.data.settings);
//     }
//   }, []);

//   useEffect(() => {
//     fetchMainsettings();
//   }, [fetchMainsettings]);

//   const settingData = settings.settings.settings;
//   const starBtnTheme = {
//     width: '55%',
//     margin: '1em auto 0.1em',

//     fontSize: `${settingData.button.fontSize}px !important`,
//     color: `${settingData.button.color}`,
//     background: `${settingData.button.bgColor}`,
//   };

//   const useStyles = makeStyles(() => ({
//     root: {
//       paddingTop: '1vw',
//     },
//     square: {
//       height: 'auto',
//       borderRadius: '9px',
//       minHeight: '190px',
//       // maxHeight: '250px',
//       padding: '4px',
//       position: 'relative',
//       backgroundColor: settingData.backgroundColor ? `${settingData.backgroundColor}` : '#FFFFFF',
//     },
//     oval: {
//       backgroundColor: settingData.backgroundColor ? `${settingData.backgroundColor}` : '#FFFFFF',
//       fontSize: '12px',
//       width: '600px',
//       height: '450px',
//       borderRadius: '50%',
//       boxShadow: '0px 3px 6px #00000029',
//       padding: '2em',
//       position: 'relative',
//     },
//     rectangle: {
//       backgroundColor: settingData.backgroundColor ? `${settingData.backgroundColor}` : '#FFFFFF',
//       fontSize: '12px',
//       width: '96%',
//       //   height: '100%',
//       boxShadow: '0px 3px 6px #00000029',
//       padding: '1em',
//       position: 'relative',
//     },
//     Circle: {
//       backgroundColor: settingData.backgroundColor ? `${settingData.backgroundColor}` : '#FFFFFF',
//       fontSize: '12px',
//       width: '600px',
//       height: '600px',
//       borderRadius: '50%',
//       boxShadow: '0px 3px 6px #00000029',
//       padding: '4em',
//     },
//     CardContent: {
//       padding: '0 !important',
//       textAlign: 'center',
//     },
//     secondCardContent: {
//       height: '50%',
//       background: 'rgb(50, 167, 83)',
//       padding: '0 !important',
//       textAlign: 'center',
//       position: 'relative',
//     },
//     cardAction: {
//       fontSize: '12px',
//       textAlign: 'left',
//       padding: '7px 10px !important',
//     },
//     textContect: {
//       padding: '7px !important',
//       textAlign: 'left',
//       fontSize: '12px',
//       fontWeight: 'bold',
//       display: 'flex',
//       marginTop: '30px',
//     },
//     reviewHeader: {
//       padding: '12px !important',
//       textAlign: 'left',
//       fontSize: `${settingData.header ? settingData.header.Headerfontsize : '18px'}px`,
//       fontWeight: 'bold',
//       display: 'flex',
//       color: settingData.header.HeaderfontColour,
//       fontFamily: 'Montserrat !important',
//     },
//     connect: {
//       padding: '9px !important',
//       fontSize: '10px',
//       textAlign: 'center',
//       // fontWeight: 'bold',
//       color: '#32b6ec',
//     },
//     paragraph: {
//       fontSize: `${settingData.paragraph.fontSize}px`,
//       color: `${settingData.paragraph.color}`,
//       fontFamily: 'Montserrat',
//       position: 'relative',
//     },
//     cardActionHeader: {
//       color: `${settingData.header ? settingData.header.reviewHeaderfontColour : '#37423E'}`,
//       margin: '0',
//       fontSize: `${settingData.header ? settingData.header.reviewHeadertext : '18px'}px`,
//       fontFamily: 'Montserrat, bold',
//       fontWeight: 'bold',
//     },
//     tableConatiner: {
//       padding: '8px !important',
//     },
//     imgConatiner: {
//       height: '25px',
//       width: '25px',
//     },
//     setting: {
//       right: '7px',
//       bottom: '7px',
//       position: 'absolute',
//       width: '13px',
//     },
//   }));

//   const classes = useStyles();

//   const btnTheme = {
//     width: '10%',
//     fontSize: `${settingData.button.fontSize}px !important`,
//     color: `${settingData.button.color}`,
//     margin: '0.5em 0  ',
//     fontWeight: 'bold',
//     background: `${settingData.button.bgColor}`,
//   };

//   const ReviewSrc = styled.p`
//     font-size: ${settingData.paragraph.fontSize}px;
//     color: ${settingData.paragraph.color};
//     margin: 1.3em 1.2em !important;
//   `;

//   const Header = styled.p`
//     margin: 0 !important;
//     color: black;
//     font-size: 30px;
//   `;
//   //  // font-size: ${headerFontSize};
//   //  color: ${reviewSummary[0].settings.settings.averageRating.color};
//   const boxDesign = () => {
//     if (settingData.shape === 'circle') {
//       return classes.Circle;
//     }
//     if (settingData.shape === 'oval') {
//       return classes.oval;
//     }
//     if (settingData.shape === 'rectangle') {
//       return classes.rectangle;
//     }
//     return classes.square;
//   };

//   const topReviewSourceContent = hybridWidgetsData.topReviewSource.map((item) => (
//     <tr>
//       <td>
//         <img src={imageURLCheck(item.logo)} alt="" className={classes.imgConatiner} />
//       </td>
//       <td style={{ width: '100%' }}>
//         {/* <ProgressBar
//           value={item.totalReviewCount}
//           progressBarStyle={settingData.progress ? settingData.progress : dummyProgressBar}
//         /> */}
//       </td>
//       <td>
//         <span
//           style={{
//             color: `${settingData.ratingfontColour}`,
//           }}
//         >
//           {item.averageRating}
//         </span>
//       </td>
//       <td>
//         <span>
//           <StarIcon style={{ color: '#FFA338' }} />
//         </span>
//       </td>
//     </tr>
//   ));

//   const reviewsContent = hybridWidgetsData.reviews.map((item) => (
//     <>
//       <CardContent className={classes.textContect}>
//         <ThemeProvider theme={btnTheme}>
//           <CustomButton>
//             <span>{getRatingFromNameValue(item.rating)}</span>

//             <StarIcon
//               style={{
//                 fontSize: `${settingData.button.fontSize}px`,
//                 color: `${settingData.button.color}`,
//               }}
//             />
//           </CustomButton>
//         </ThemeProvider>
//         <ReviewSrc>
//           {`          On
//             ${capitalizeFirstLetter(item.source)},
//             ${getDateFromTimeStamp(new Date(item.reviewOn).getTime())}
//   `}
//         </ReviewSrc>
//       </CardContent>
//       <CardContent className={classes.cardAction}>
//         <Typography className={classes.cardActionHeader}>{item.reviewBy}</Typography>
//         <Typography className={classes.paragraph}>{item.reviewText}</Typography>
//       </CardContent>
//     </>
//   ));

//   return (
//     <>
//       <div>Is this visible</div>
//       <Card className={boxDesign()}>
//         <StyledSummaryCard container justify="space-evenly">
//           <Grid item lg={5} md={5} sm={6} style={{ alignSelf: 'center' }}>
//             {/* <Grid item alignItems="center"> */}
//             <div style={{ textAlign: 'center' }}>
//               <Header
//                 style={{
//                   color: settingData.header.HeaderfontColour,
//                   fontSize: `${settingData.header.Headerfontsize} px`,
//                 }}
//               >
//                 {hybridWidgetsData.reviewSummary[0].averageRating}
//               </Header>
//               <CustomRating ratingValue={5} />
//               <SubText
//                 style={{
//                   color: `${settingData.ratingfontColour}`,
//                 }}
//               >
//                 {hybridWidgetsData.reviewSummary[0].totalReviewCount}
//               </SubText>
//               <ThemeProvider theme={starBtnTheme}>
//                 <CustomButton
//                   onClick={() => window.open(hybridWidgetsData.reviewSummary[0].postReviewLink)}
//                 >
//                   Add Your Review
//                 </CustomButton>
//               </ThemeProvider>
//             </div>
//           </Grid>
//           <Grid item lg={7} md={7} sm={6}>
//             <CardContent className={classes.reviewHeader}>Top Review Sources</CardContent>
//             <CardContent className={classes.tableConatiner}>
//               <table>
//                 <tbody>{topReviewSourceContent}</tbody>
//               </table>
//             </CardContent>
//           </Grid>
//         </StyledSummaryCard>
//         <Grid container style={{ height: '100%', overflow: 'auto', padding: '3em' }}>
//           <Grid item xs={12} sm={12} md={12} lg={12}>
//             {reviewsContent}
//           </Grid>
//         </Grid>
//       </Card>
//     </>
//   );
// }

// export default AllInOneReviews;
//********** DO NOT EDIT BELOW HERE *************//
function AllInOneWidgetContainer() {
  console.log('YOU MADE IT');
  const [hybridWidgetsData, setHybridWidgetsData] = useState({
    reviews: [],
    topReviewSource: [],
    reviewSummary: [
      {
        averageRating: '0',
        reviewSrc: 'main',
        starRating: {
          FIVE: 0,
          FOUR: 0,
          THREE: 0,
          TWO: 0,
          ONE: 0,
        },
        totalReviewCount: 0,
        postReviewLink: '',
      },
    ],
  });

  const [settings, setSettings] = useState({
    settings: {
      settings: {
        shape: 'square',
        backgroundColor: '#FFFFFF',
        button: {
          fontSize: '12',
          bgColor: '#4d6fd5',
          color: '#ffffff',
        },
        paragraph: {
          fontSize: '12',
          color: '#d2bcbc',
        },
        header: {
          Headerfontsize: '24',
          HeaderfontColour: '#f5e5e5',
        },
        ratingfontColour: '#ffa338',
        ratingfontsize: '12',
        progress: {
          backgroundColor: '#58E298',
          fontColor: '#fff',
        },
      },
    },
  });

  useEffect(() => {
    const uuid = document.getElementById('reveo-reviews-widget')?.getAttribute('data-uuid');
    console.log(uuid);
    const message = document.createElement('p');
    message.textContent = "It's working!";
    document.body.appendChild(message);
  }, []);

  const fetchMainsettings = useCallback(async () => {
    const result = await baseUrl.get(`/micro-site-hybrid-widgets?id=${getParaByName('id')}`);
    if (result.status === 200) {
      setHybridWidgetsData(result.data.list);
      setSettings(result.data.settings);
    }
  }, []);

  useEffect(() => {
    fetchMainsettings();
  }, [fetchMainsettings]);

  // const reviews = hybridWidgetsData && hybridWidgetsData.reviews.map((review) => review.reviewText)

  return (
    <div id="reveo-reviews-widget" data-uuid="your-uuid-here">
      This is the new widget
    </div>
  );
}

export default AllInOneWidgetContainer;
