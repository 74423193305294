import React from 'react';
import Proptypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { Card, CardContent, Typography } from '@material-ui/core';
import CustomButton from '../CustomButton/CustomButton';
import Logo from '../../assets/images/reveo_logo@1x.png';
import UploadLayout from '../UploadLayout';

const CardStyle = styled(Card)`
  text-align: center;
  min-width: 100%;
  height: 100%;
  position: relative;
  // @media only screen and (max-width: 600px) {
  //   box-shadow: none;
  // }
`;
const CardContentStyle = styled(CardContent)`
  text-align: center;
`;

const BottomContent = styled(CardContent)`
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 12px 0 !important;
`;

const QuestionTextStyle = styled(Typography)`
  color: #9e9a9a !important;
  font-family: Montserrat, bold !important;
  font-size: 1.2rem !important;
  // @media only screen and (min-device-width: 481px) and (max-device-width: 1000px) {
  //   font-size: 1rem !important;
  // }
`;

const buttonTheme = {
  background: 'black',
  margin: '0px',
  color: '#fff',
  fontSize: '18px !important',
  fontWeight: 'bold',
};

function SingleAction(props) {
  const { singleActionData, onYesClick, notificationReviewData, url } = props;
  const { title, positiveButtonText } = singleActionData;
  return (
    <CardStyle>
      <CardContentStyle>
        {url ? (
          <ThemeProvider theme={{ width: '85%', margin: '0 auto', border: 'none' }}>
            <UploadLayout
              handleClick={() => {}}
              url={`${process.env.REACT_APP_IMAGE_ENDPOINT}${url}`}
              imgHeight="350px"
              disabled
            />
          </ThemeProvider>
        ) : (
          <p />
        )}
      </CardContentStyle>
      <CardContentStyle>
        <QuestionTextStyle>{title}</QuestionTextStyle>
      </CardContentStyle>

      <CardContentStyle>
        <ThemeProvider theme={buttonTheme}>
          <CustomButton
            onClick={() => {
              window.open(notificationReviewData.metaData.externalLink, '_blank');
              onYesClick();
            }}
            // disabled={disabledBtn}
          >
            {positiveButtonText}
          </CustomButton>
        </ThemeProvider>
      </CardContentStyle>

      <BottomContent>
        <div style={{ display: 'inline-grid' }}>
          <span style={{ fontSize: '6px', marginLeft: '14px' }}>Powered by</span>
          <img
            src={Logo}
            // style={{ width: powerImageWidth ? `${powerImageWidth}em` : '2.3em' }}
            style={{ width: '2.3em' }}
            alt="logo"
          />
        </div>
      </BottomContent>
    </CardStyle>
  );
}

SingleAction.propTypes = {
  onYesClick: Proptypes.func.isRequired,
  singleActionData: Proptypes.objectOf(Proptypes.string).isRequired,
  notificationReviewData: Proptypes.objectOf(Proptypes.string).isRequired,
  url: Proptypes.string.isRequired,
};

SingleAction.defaultProps = {};

export default SingleAction;
