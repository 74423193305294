import { Grid } from '@material-ui/core';
import styled from 'styled-components';

const StyledImpText = styled(Grid)`
  letter-spacing: 0.25px;
  color: ${(props) => props.theme.color};
  font-family: Montserrat;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-bottom: 0.25rem !important;
`;

export default StyledImpText;
