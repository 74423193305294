import { useCallback, useState, useContext } from 'react';
import { getActivePostsites, errorChecker } from '../utils/helper/helper';
import baseUrl from '../utils/services/config';
import { GlobalContext } from '../Stores/GlobalStateStore';
import { OnBoardingContext } from '../Stores/OnBoarding';

const useFetchPostSites = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { selectedLoaction, selectedBusiness, businesses } = state;
  const { onBoardingDispatch } = useContext(OnBoardingContext);

  const fetchPostSites = useCallback(async () => {
    if (selectedLoaction) {
      try {
        const result = await baseUrl('/postsites?isDeleted=false');
        if (result.status === 200) {
          const activeBusiness = businesses.find((each) => each.id === selectedBusiness);
          const activeBusinessIndustry = activeBusiness.industryName;
          const filteredResults = result.data.filter((each) => {
            if (each.industry && each.industry.length) {
              const industryNames = each.industry.map((ind) => ind.name);
              if (activeBusinessIndustry && !industryNames.includes('Others')) {
                return industryNames.includes(activeBusinessIndustry);
              }
            }
            return true;
          });

          const result1 = await baseUrl(`/location/${selectedLoaction}/postSitesMetaData`);
          const selectedSites = getActivePostsites(filteredResults, result1.data);
          onBoardingDispatch({
            type: 'SET_POST_SITES',
            payload: Array.from(selectedSites),
          });
        }
      } catch (err) {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  }, [baseUrl, selectedLoaction, selectedBusiness, businesses, dispatch, onBoardingDispatch]);

  return { fetchPostSites };
};

export default useFetchPostSites;
