import { Box } from '@material-ui/core';
import React from 'react';
import GenricPopup from '../../Popup/GenricPopup';
import Message from '../SendInvitePopover/components/AddCampaign/Message/Message';

const CreateMessagePopover = ({ messageCreationPopup, selectedId = null }) => {
  const handleClose = () => {
    messageCreationPopup.handleHideShowModal();
  };
  return (
    <GenricPopup
      open={messageCreationPopup.showModal}
      handleClose={messageCreationPopup.handleHideShowModal}
      maxWidth="md"
      fullWidth
    >
      <Box style={{ height: 'auto', margin: '1rem' }}>
        <Message handleClose={handleClose} campaignId={selectedId} />
      </Box>
    </GenricPopup>
  );
};

export default CreateMessagePopover;
