import React from 'react';
import Proptypes from 'prop-types';
import styled from 'styled-components';

const StyledCompanyLogo = styled.img`
  height: ${(props) => props.theme.height};
  max-height: ${(props) => props.theme.height};
  width: ${(props) => props.theme.width};
  // padding: ${(props) => (props.theme.padding ? props.theme.padding : '0 0 0 0')};
  border-radius: ${(props) => props.theme.borderRadius};
  // @media screen and (min-width: 1920px) {
  //   & {
  //     height: ${(props) => props.theme.largeScreenHeight};
  //     max-height: ${(props) => props.theme.largeScreenHeight};
  //     width: ${(props) => props.theme.largeScreenWidth};
  //   }
  // }
`;

function CompanyLogo(props) {
  const { logo, alt } = props;
  return <StyledCompanyLogo src={logo} alt={alt} />;
}

// CompanyLogo.propTypes = {
//   logo: Proptypes.arrayOf(Proptypes.any).isRequired,
//   alt: Proptypes.string.isRequired,
// };

export default CompanyLogo;
