import React, { useContext } from 'react';
import Proptypes from 'prop-types';
import styled from 'styled-components';
import { List } from '@material-ui/core';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import { getNotificationCount } from '../../utils/helper/helper';
import TopNavLink from '../CustomNavLink/TopNavLink';

const StyledCusotmNavBar = styled(List)`
  width: 100% !important;
  display: flex;
  flex-direction: row;
`;

function CustomHeaderNavBar(props) {
  const { navList } = props;
  const { state } = useContext(GlobalContext);
  const { notificationContainer } = state;
  const NavList = navList.map((navItem) => (
    <TopNavLink
      {...navItem}
      key={`${navItem.title}`}
      count={getNotificationCount(notificationContainer)[`${navItem.title.toLowerCase()}`]}
    />
  ));

  return <StyledCusotmNavBar>{NavList}</StyledCusotmNavBar>;
}

CustomHeaderNavBar.propTypes = {
  navList: Proptypes.arrayOf(Proptypes.object).isRequired,
};

export default React.memo(CustomHeaderNavBar);
