import styled from 'styled-components';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';

const StyledSmartPhoneIcon = styled(PhoneIphoneIcon)`
  color: ${(props) => props.theme.color} !important;
  font-size: ${(props) => props.theme.fontSize} !important;
  opacity: ${(props) => props.theme.opacity} !important;
`;

export default StyledSmartPhoneIcon;
