import React, { useState } from 'react';
import SettingItem from '../../pages/WidgetsContainer/SettingItem';
import { Button, makeStyles } from '@material-ui/core';
import BackIcon from 'mdi-react/ArrowBackIcon';
import NextIcon from 'mdi-react/ChevronRightIcon';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';
import './SettingsPanel.scss';

const PrimaryButton = styled(Button)`
  background-color: #30feaf !important;
  margin-left: 10px !important;
  text-transform: capitalize !important;
  letter-spacing: unset !important;
  font-weight: 600 !important;
  padding: 8px 20px !important;
`;

const StyledInput = styled.div`
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
`;

function SettingsPanel({ settings, updateSetting, widgetCode }) {
  const [activeItem, setActiveItem] = useState(null);
  const [copied, setCopied] = useState(false);

  const useStyles = makeStyles(() => ({
    widgetCode: {
      fontSize: '14px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: '25ch',
    },
  }));

  const classes = useStyles();

  function handleItemClick(item) {
    setActiveItem(item);
  }

  function handleBackClick() {
    setActiveItem(null);
  }

  return (
    <div className="panel-container">
      <div className={`menu ${activeItem ? 'slide-out' : ''}`}>
        <div className="menu-item" onClick={() => handleItemClick('item1')}>
          Widget Appearance
          <NextIcon />
        </div>
        <div className="menu-item" onClick={() => handleItemClick('item2')}>
          Header <NextIcon />
        </div>{' '}
        <div className="menu-item" onClick={() => handleItemClick('item4')}>
          Review Button <NextIcon />
        </div>
        <div className="menu-item" onClick={() => handleItemClick('item3')}>
          Review <NextIcon />
        </div>
        <div className="section_header">Widget Code</div>
        <div>
          <CopyToClipboard text={widgetCode} onCopy={() => setCopied(true)}>
            <StyledInput>
              <div className={classes.widgetCode}>{widgetCode}</div>
              <PrimaryButton style={{ width: '110px' }}>
                {(() => {
                  const label = copied ? 'Copied' : 'Copy';
                  if (copied) {
                    setTimeout(() => setCopied(false), 2000);
                  }
                  return label;
                })()}
              </PrimaryButton>
            </StyledInput>
          </CopyToClipboard>
        </div>
      </div>
      <div className={`panel-content ${activeItem ? 'slide-in' : ''}`}>
        <div className="back-button" onClick={handleBackClick}>
          <BackIcon />
          Back
        </div>
        <div className="panel-settings">
          {/* settings for the active menu item */}
          {activeItem === 'item1' && (
            <>
              <div className="section_header">Global Settings</div>

              <SettingItem
                title="Brand Color"
                settingsTitle="colors.brandColor"
                value={settings.colors.brandColor}
                updateSetting={updateSetting}
                type="color"
              />

              <SettingItem
                title="Light Appearance"
                settingsTitle="isLight"
                value={settings.isLight}
                updateSetting={updateSetting}
                type="toggle"
              />
              <SettingItem
                title="Background Color"
                settingsTitle="colors.backgroundColor"
                value={settings.colors.backgroundColor}
                updateSetting={updateSetting}
                type="color"
              />
              <SettingItem
                title="Widget Style"
                settingsTitle="widgetStyle"
                value={settings.widgetStyle}
                updateSetting={updateSetting}
                menuItems={['badge', 'carousel', 'grid', 'list', 'masonry', 'summary']}
                type="menu"
              />
              {settings.widgetStyle === 'badge' ? (
                <SettingItem
                  title="Placement"
                  settingsTitle="widgetPlacement"
                  value={settings.widgetPlacement}
                  updateSetting={updateSetting}
                  menuItems={['left', 'right']}
                  type="menu"
                />
              ) : null}
              {settings.widgetStyle === 'carousel' && (
                <SettingItem
                  title="Auto Scroll"
                  settingsTitle="carousel.autoPlay"
                  value={settings.carousel.autoPlay}
                  updateSetting={updateSetting}
                  type={'text'}
                  adornment="seconds"
                />
              )}
              <div className="section_header">Container Settings</div>
              <SettingItem
                title="Background Color"
                settingsTitle="colors.containerBgColor"
                value={settings.colors.containerBgColor}
                updateSetting={updateSetting}
                type="color"
              />
              <SettingItem
                title="Background Opacity"
                settingsTitle="colors.containerBgOpacity"
                value={settings.colors.containerBgOpacity}
                updateSetting={updateSetting}
                type="slider"
              />
              <SettingItem
                title="Rounded Corners"
                settingsTitle="radius"
                value={settings.radius}
                updateSetting={updateSetting}
                type={'text'}
              />
              <SettingItem
                title="Gap"
                settingsTitle="gap"
                value={settings.gap}
                updateSetting={updateSetting}
                type={'text'}
              />
            </>
          )}
          {activeItem === 'item2' && (
            <>
              <div className="section_header">Title Settings</div>

              <SettingItem
                title="Show Widget Title"
                settingsTitle="showWidgetTitle"
                value={settings.showWidgetTitle}
                updateSetting={updateSetting}
                type="toggle"
              />
              {settings.showWidgetTitle && (
                <>
                  <SettingItem
                    title="Rounded Corners"
                    settingsTitle="widgetTitleText"
                    value={settings.widgetTitleText}
                    updateSetting={updateSetting}
                    type="text"
                    label={false}
                  />
                  <SettingItem
                    title="Title Text Color"
                    settingsTitle="colors.widgetTitleColor"
                    value={settings.colors.widgetTitleColor}
                    updateSetting={updateSetting}
                    type="color"
                  />
                </>
              )}
              <div className="section_header">Header Settings</div>

              <SettingItem
                title="Show Header"
                settingsTitle="showHeader"
                value={settings.showHeader}
                updateSetting={updateSetting}
                type="toggle"
              />
              {settings.showHeader && (
                <SettingItem
                  title="Show Tabs"
                  settingsTitle="showTabs"
                  value={settings.showTabs}
                  updateSetting={updateSetting}
                  type="toggle"
                />
              )}
            </>
          )}
          {activeItem === 'item3' && (
            <>
              <SettingItem
                title="Style"
                settingsTitle="reviewStyle"
                value={settings.reviewStyle}
                updateSetting={updateSetting}
                menuItems={['default', 'bubble', 'center']}
                type="menu"
              />
              <SettingItem
                title="Show Full Review"
                settingsTitle="isTextExpanded"
                value={settings.isTextExpanded}
                updateSetting={updateSetting}
                type="toggle"
              />
            </>
          )}
          {activeItem === 'item4' && (
            <>
              <SettingItem
                title="Show Button"
                settingsTitle="reviewButton.showWriteReviewButton"
                value={settings.reviewButton.showWriteReviewButton}
                updateSetting={updateSetting}
                type="toggle"
              />
              {settings.reviewButton.showWriteReviewButton ? (
                <>
                  <SettingItem
                    title="Rounded Button"
                    settingsTitle="reviewButton.isRounded"
                    value={settings.reviewButton.isRounded}
                    updateSetting={updateSetting}
                    type="toggle"
                  />
                  <SettingItem
                    title="Solid Button"
                    settingsTitle="reviewButton.isSolid"
                    value={settings.reviewButton.isSolid}
                    updateSetting={updateSetting}
                    type="toggle"
                  />
                  <SettingItem
                    title="Button Color"
                    settingsTitle="reviewButton.buttonColor"
                    value={settings.reviewButton.buttonColor}
                    updateSetting={updateSetting}
                    type="color"
                  />
                  <SettingItem
                    title="Button Text Color"
                    settingsTitle="reviewButton.textColor"
                    value={settings.reviewButton.textColor}
                    updateSetting={updateSetting}
                    type="color"
                  />
                </>
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default SettingsPanel;
