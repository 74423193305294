import React from 'react';
import { makeStyles, Grid, LinearProgress, withStyles, Box } from '@material-ui/core';
import styled from 'styled-components';
import StarIcon from '@material-ui/icons/Star';
import CustomRating from '../../components/CusotmRating/CusotmRating';
import { getParaByName } from '../../utils/helper/helper';
import SingleReview from './SingleReview';
import ChevronRight from 'mdi-react/ChevronRightIcon';
import ChevronLeft from 'mdi-react/ChevronLeftIcon';

const StyledLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#ffd643',
  },
}))(LinearProgress);

const SubText = styled.p`
  margin: 0 !important;
  font-size: 16px;
  color: #939393;
`;

function HybridWidget(props) {
  const { reviewsData, linkColor } = props;
  const currentPage = getParaByName('page') ?? 1;
  const reviewsPerPage = 10;
  const totalReviewsCount = reviewsData?.reviewSummary.totalReviewCount;
  const listLinks = reviewsData?.reviewSummary.listReviewLink[0];
  const totalPages = Math.ceil(totalReviewsCount / reviewsPerPage);

  const startIndex = (!currentPage ? 0 : currentPage - 1) * reviewsPerPage;
  const endIndex = startIndex + reviewsPerPage;
  const starRatingChartData = reviewsData?.reviewSummary?.starRating;

  const useStyles = makeStyles(() => ({
    root: {
      paddingTop: '1vw',
    },
    pagination: {
      marginTop: '1rem',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',

      '& svg': {
        width: '30px',
      },

      '& a': {
        fontWeight: '600',
        fontSize: '18px',
        border: '1px solid transparent',
        padding: '.3rem .6rem',
        borderRadius: '.2rem',
        transition: 'all ease-in-out',

        '&:hover': {
          borderColor: '#d0d0d0',
          transition: 'all ease-in-out',
        },
      },
    },
    navChevron: {
      border: '1px solid transparent',
      padding: '.3rem .3rem',
      borderRadius: '.2rem',
      color: '#c3c3c3',
      transition: 'all ease-in-out',
      cursor: 'pointer',

      '&:hover': {
        borderColor: '#d0d0d0',
        transition: 'all ease-in-out',
      },
    },
    inactive: {
      color: '#c3c3c3',
    },
    currentPage: {
      color: '#000',
    },
    reviewCount: {
      fontSize: '13px',
      minWidth: '10px',
    },
    ratingStar: {
      fill: '#ffd643',
      height: '18px',
      minWidth: '25px',
      marginRight: '5px',
    },
    disabled: {
      cursor: 'not-allowed',
      color: '#c3c3c3',
      border: '1px solid transparent',
      padding: '.3rem .3rem',
    },
  }));

  const classes = useStyles();

  const goToNextPage = () => {
    window.location.href = createUpdatedHref(parseInt(currentPage) + 1);
  };

  const goToPreviousPage = () => {
    window.location.href = createUpdatedHref(parseInt(currentPage) - 1);
  };

  const getReviewLink = (review) => {
    if (review.source.toLowerCase() === 'google') {
      return `https://www.google.com/maps/reviews/?authuser=0&hl=en&reviewid=${review.reviewId}`;
    }
    const link = listLinks.find((link) => link.name === review.source);
    return link ? link.listReviewsLink : null; // Return null or handle if link is not found
  };

  const createUpdatedHref = (page) => {
    const currentUrl = window.location.href;
    const pageParam = `page=${page}`;

    if (currentUrl.includes('page=')) {
      // Replace the existing page parameter with the new one
      return currentUrl.replace(/page=\d+/, pageParam);
    } else if (currentUrl.includes('?')) {
      // URL already has query parameters, append page parameter
      return `${currentUrl}&${pageParam}`;
    } else {
      // URL without query parameters, add page parameter
      return `${currentUrl}?${pageParam}`;
    }
  };

  const allReviewsChart = Object.keys(starRatingChartData)
    .reverse()
    .map((key, index) => {
      let stars = 5;
      stars = stars - index;
      let percentage =
        (starRatingChartData[key] / reviewsData.reviewSummary.totalReviewCount) * 100;

      return (
        <Box key={index} display="flex" alignItems="center" marginBottom={1}>
          <Box minWidth={20} marginLeft={'10px'} display="flex" alignItems="center">
            <span className={classes.reviewCount}>{stars}</span>
            <StarIcon className={classes.ratingStar} />
          </Box>
          <Box width="100%">
            <StyledLinearProgress value={percentage} variant="determinate" />
          </Box>
          <Box minWidth={20} marginLeft={'10px'}>
            <span className={classes.reviewCount}>{starRatingChartData[key]}</span>
          </Box>
        </Box>
      );
    });

  const reviewsContent = reviewsData.reviews.slice(startIndex, endIndex).map((item, index) => {
    return (
      <SingleReview
        item={item}
        index={index}
        key={index}
        linkColor={linkColor}
        reviewLink={getReviewLink(item)}
      />
    );
  });

  return (
    <Grid item style={{ alignItems: 'center', margin: '2rem 0' }}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={3}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <div
              style={{
                color: '#000',
                fontSize: '48px',
                fontWeight: '500',
              }}
            >
              {reviewsData.reviewSummary.averageRating}
            </div>
            <CustomRating ratingValue={reviewsData.reviewSummary.averageRating} />
            <SubText
              style={{
                color: '#a5a5a5',
                fontSize: '13px',
              }}
            >
              {reviewsData.reviewSummary.totalReviewCount} reviews
            </SubText>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={8} lg={8} xl={9}>
          {allReviewsChart}
        </Grid>
      </Grid>
      <Grid container>{reviewsContent}</Grid>
      <Grid item className={classes.pagination}>
        <ChevronLeft
          onClick={currentPage === null || parseInt(currentPage) === 1 ? null : goToPreviousPage}
          className={`${
            currentPage === null || parseInt(currentPage) === 1
              ? classes.disabled
              : classes.navChevron
          }`}
        />
        {Array.from({ length: totalPages }, (_, index) => (
          <a
            key={index}
            className={
              (currentPage === null && index === 0) || parseInt(currentPage) === index + 1
                ? classes.currentPage
                : classes.inactive
            }
            href={createUpdatedHref(index + 1)}
          >
            {index + 1}
          </a>
        ))}
        <ChevronRight
          onClick={parseInt(currentPage) === totalPages ? null : goToNextPage}
          className={`${
            parseInt(currentPage) === totalPages ? classes.disabled : classes.navChevron
          }`}
        />
      </Grid>
    </Grid>
  );
}

export default HybridWidget;
