import { getLocationName } from '../utils/helper/helper';

const CustomersReducer = (state, action) => {
  switch (action.type) {
    case 'SET_CUSTOMERS_CONTAINER':
      return {
        ...state,
        customersContainer: Array.from(action.payload),
        loader: false,
      };

    case 'UPDATE_CUSTOMERS_CONTAINER_LOCATIONS': {
      const stateCopy = { ...state };
      stateCopy.customersContainer.filter((element, index) => {
        if (element.id === action.payload.id) {
          stateCopy.customersContainer[index].locations = getLocationName(action.payload.locations);
        }
        return null;
      });
      return {
        ...state,
        ...stateCopy,
      };
    }

    case 'UPDATE_CUSTOMER_CONTAINER':
      return {
        ...state,
        customersContainer: Array.from(action.payload),
        loader: false,
      };

    case 'INIT_CUSTOMERS_CONTAINER': {
      return {
        ...state,
        customersContainer: [],
      };
    }

    case 'SET_CUSTOMERS_FEEDBACK_CONTAINER':
      return {
        ...state,
        customersContainer: Array.from(action.payload),
        loader: false,
      };

    case 'SHOW_LOADER':
      return {
        ...state,
        loader: true,
      };
    case 'HIDE_LOADER':
      return {
        ...state,
        loader: false,
      };
    default:
      return state;
  }
};

export default CustomersReducer;
