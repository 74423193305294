import React, { useCallback, useContext, useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { ThemeProvider } from 'styled-components';
import { useForm } from 'react-hook-form';

import CusotmAlert from '../../components/CustomAlert/CustomAlert';
import Loader from '../../components/Loader/Loader';
import CompanyLogo from '../../components/CompanyLogo/CompanyLogo';
import Logo from '../../assets/images/reveo_logo@2x.png';
import { OnBoardingContext } from '../../Stores/OnBoarding';
import OnBoardingHeaderLogoTheme from '../../themes/Logo/OnBoardingHeaderLogoTheme';
import {
  ConvertIntoLocationDropDown,
  errorChecker,
  getMetaImage,
  getMultiSelectIds,
  getPortalData,
  getSelectedPortalInfo,
} from '../../utils/helper/helper';
import baseUrl from '../../utils/services/config';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import { autoReview } from '../../utils/api/portalJson';
import ReviewRequestCreation from '../../views/ReviewRequestCretaion';
import SurveyCreationContent from '../../views/SurveyCreation';
import NotificationCreationContent from '../../views/NotificationCreation';
import StyledHelpIcon from '../../components/Icons/HelpIcon';
import useIconTheme from '../../hooks/useIconTheme';

function PopupContainer(props) {
  const { show, handleNext, selectedCampaign } = props;
  const [renderView, setRenderView] = useState(true);
  const [showAlert, setAlert] = useState(false);
  const [locationsContainer, setLocationsContainer] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const [loader, setLoader] = useState(false);
  const [campaignViewToggler, setCampaignViewToggler] = useState('mms');
  const [reminderMessage, setReminderMessage] = useState('');
  const [businessLogo, setBusinessLogo] = useState({});
  const { onBoardingData, onBoardingDispatch } = useContext(OnBoardingContext);
  const { dispatch, state } = useContext(GlobalContext);
  const { iconsTheme } = useIconTheme();
  const { selectedBusiness, selectedLoaction } = state;
  const { userList, defaultPortals, campaignType } = onBoardingData;

  const { register, handleSubmit, control, errors, watch, setValue, setError, clearError } =
    useForm({
      defaultValues: {
        name: '',
        campaignType: '',
        reviewPortal: '',
        user: [],
        location: [],
        first_image: '',
        message: '',
      },
    });

  const watchUsersField = watch('users');
  const watchLocationField = watch('location');
  const watchAutoReviewReminder = watch('autoReminder');
  const watchAllowTextFeedback = watch('allowTextFeedback');

  const fetchBusinessLogo = useCallback(async () => {
    if (selectedLoaction) {
      try {
        const result = await baseUrl(`location/${selectedLoaction}/uploadedFile`);
        if (result.status === 200) {
          setBusinessLogo(getMetaImage(result.data, 'business_logo'));
        }
      } catch (err) {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  }, [selectedLoaction]);

  useEffect(() => {
    fetchBusinessLogo();
  }, [fetchBusinessLogo]);

  const fetchLocation = useCallback(() => {
    if (selectedBusiness) {
      baseUrl
        .get(`/business/${selectedBusiness}/location?isDeleted=false`)
        .then((res) => {
          setLocationsContainer(Array.from(ConvertIntoLocationDropDown(res.data)));
        })
        .catch((err) => {
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    }
  }, [selectedBusiness, dispatch]);

  useEffect(() => {
    fetchLocation();
  }, [fetchLocation]);

  useEffect(() => {
    if (selectedCampaign) {
      setRenderView(false);
      baseUrl.get(`campaign/${selectedCampaign}`).then((res) => {
        onBoardingDispatch({
          type: 'SET_DEFAULT_PORTAL',
          payload: getPortalData(res.data.reviewPortal),
        });
        onBoardingDispatch({
          type: 'SET_CAMPAIGN_TYPE',
          payload: res.data.campaignType,
        });
        setRenderView(true);
        setReminderMessage(
          res.data.settings && res.data.settings.reminderText
            ? res.data.settings.reminderText
            : autoReview.title,
        );
        setValue('name', res.data.name);
        setValue('location', getMultiSelectIds(res.data.location));
        setValue('users', getMultiSelectIds(res.data.user));
        setValue('canResendAfter', res.data.canResendAfter ? res.data.canResendAfter : '');
        setValue('emailSubject', res.data.emailSubject);
        setValue('emailTitle', res.data.emailTitle);
        setValue(
          'externalLink',
          res.data.settings && res.data.settings.externalLink
            ? res.data.settings.externalLink
            : false,
        );
        setValue(
          'allowTextFeedback',
          res.data.settings && res.data.settings.allowTextFeedback
            ? res.data.settings.allowTextFeedback
            : false,
        );
        setValue(
          'autoReminder',
          res.data.settings && res.data.settings.autoReminder
            ? res.data.settings.autoReminder
            : false,
        );
        setValue(
          'attributionPeriod',
          res.data.settings && res.data.settings.attributionPeriod
            ? res.data.settings.attributionPeriod
            : '',
        );
        setValue(
          'reminderFrequency',
          res.data.settings && res.data.settings.reminderFrequency
            ? res.data.settings.reminderFrequency
            : '',
        );
        setValue(
          'reminders',
          res.data.settings && res.data.settings.reminders ? res.data.settings.reminders : '',
        );
      });
    } else {
      clearError();
    }
  }, [show, clearError, onBoardingDispatch, selectedCampaign, setValue]);

  const handleAlertClose = () => setAlert(false);

  const handleNextStep = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleBackStep = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const handleCampaignTypeToggler = (type) => {
    setActiveStep(0);
    setCampaignViewToggler(type);
  };

  const radioValueState =
    Object.keys(defaultPortals)[0] && Object.keys(defaultPortals)[0] !== undefined
      ? Object.keys(defaultPortals)[0]
      : null;

  const checkIfImagesPresent = () => {
    const finalArray = [];
    if (defaultPortals[radioValueState].screens[0].uploadedFile) {
      finalArray.push(defaultPortals[radioValueState].screens[0].uploadedFile);
    }
    if (defaultPortals[radioValueState].screens[0].emailUploadFile) {
      finalArray.push(defaultPortals[radioValueState].screens[0].emailUploadFile);
    }
    return finalArray;
  };

  const onSubmit = async (data) => {
    try {
      if (data.users.length > 0 && data.location.length > 0) {
        setActiveStep(0);
        setDisableSubmitBtn(true);
        setLoader(true);
        try {
          setDisableSubmitBtn(true);
          setLoader(true);
          const result = await baseUrl.post(`/reviewportal`, {
            ...getSelectedPortalInfo(defaultPortals, radioValueState, selectedBusiness),
          });
          if (result.status === 200) {
            const postBody = {
              user: [
                ...new Set([parseInt(localStorage.getItem('USER_ID'), 10), 1].concat(data.users)),
              ],
              location: data.location,
              name: data.name,
              surveyId: defaultPortals[radioValueState].id,
              campaignType,
              reviewPortal: [result.data.id],
              uploadedFile: checkIfImagesPresent(),
              message: defaultPortals[radioValueState].screens[0].title,
              emailSubject: data.emailSubject,
              canResendAfter: data.canResendAfter ? parseInt(data.canResendAfter, 10) : 0,
              subType: radioValueState,
              settings: {
                attributionPeriod: data.attributionPeriod
                  ? parseInt(data.attributionPeriod, 10)
                  : 0,
                autoReminder: data.autoReminder,
                allowTextFeedback: data.allowTextFeedback,
                reminders: data.autoReminder ? data.reminders : 0,
                reminderFrequency: data.autoReminder ? data.reminderFrequency : 0,
                emailTitle: defaultPortals[radioValueState].screens[0].emailTitle,
                reminderText: data.autoReminder ? reminderMessage : '',
                externalLink: data.externalLink,
                subType: radioValueState,
              },
            };
            if (result.status === 200) {
              const resultCampaign = await baseUrl.post(`/campaign`, {
                ...postBody,
              });
              if (resultCampaign.status === 200) {
                setAlert(true);
                setTimeout(() => {
                  handleNext();
                  setLoader(false);
                  setDisableSubmitBtn(false);
                }, 1000);
              }
            }
          }
        } catch (err) {
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
          setDisableSubmitBtn(false);
          setLoader(false);
        }
      } else {
        setLoader(false);
        setDisableSubmitBtn(false);
        if (data.users.length <= 0) {
          setError('users', {
            type: 'required',
            message: '',
          });
        }
        if (data.location.length <= 0) {
          setError('location', {
            type: 'required',
            message: '',
          });
        }
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      setDisableSubmitBtn(false);
      setLoader(false);
    }
  };
  useEffect(() => {
    if (
      watchAutoReviewReminder &&
      defaultPortals[radioValueState].screens[defaultPortals[radioValueState].screens.length - 1]
        .screen !== 'auto-review-reminder'
    ) {
      setCampaignViewToggler('mms');
      defaultPortals[radioValueState].screens.push(
        Object.assign(autoReview(businessLogo), {
          title: reminderMessage || autoReview().title,
          uploadedFile: defaultPortals[radioValueState].screens[0].uploadedFile,
          url: defaultPortals[radioValueState].screens[0].url,
        }),
      );
      setActiveStep(defaultPortals[radioValueState].screens.length - 1);
    } else if (
      watchAutoReviewReminder === false &&
      defaultPortals[radioValueState].screens[defaultPortals[radioValueState].screens.length - 1]
        .screen === 'auto-review-reminder'
    ) {
      setCampaignViewToggler('mms');
      defaultPortals[radioValueState].screens.pop();
      setActiveStep(defaultPortals[radioValueState].screens.length - 1);
    }
  }, [watchAutoReviewReminder]);

  useEffect(() => {
    if (
      watchAutoReviewReminder &&
      defaultPortals[radioValueState].screens[defaultPortals[radioValueState].screens.length - 1]
        .screen === 'auto-review-reminder'
    ) {
      setReminderMessage(
        defaultPortals[radioValueState].screens[defaultPortals[radioValueState].screens.length - 1]
          .title,
      );
    }
  }, [watchAutoReviewReminder, defaultPortals]);

  const handleRemovePostSite = (info, postSiteIndex, type = 'positive-feedback') => {
    const defaultPortalsCopy = { ...defaultPortals };
    if (type === 'positive-feedback') {
      defaultPortalsCopy[radioValueState].screens[activeStep].postSites.splice(postSiteIndex, 1);
      onBoardingDispatch({
        type: 'SET_DEFAULT_PORTAL',
        payload: defaultPortalsCopy,
      });
    } else if (type === 'social-site') {
      defaultPortalsCopy[radioValueState].screens.splice(activeStep, 1);
      if (defaultPortalsCopy[radioValueState].screens.length === 3) {
        defaultPortalsCopy[radioValueState].screens.splice(activeStep, 0, {
          type: 'setup',
          screen: 'setup',
          title: 'Want to leave your review?',
          positiveButtonText: 'Use this Site',
          negativeButtonText: 'click, setup my sites',
          uploadedFile: null,
          backgroundColor: '#1877F2',
          color: '#fff',
          url: '',
        });
      }
      onBoardingDispatch({
        type: 'SET_DEFAULT_PORTAL',
        payload: defaultPortalsCopy,
      });
    }
  };

  const loaderContainer = loader ? <Loader /> : null;

  const getPopupContent = (type) => {
    switch (type) {
      case 'review request':
        return (
          <ReviewRequestCreation
            activeStep={activeStep}
            campaignViewToggler={campaignViewToggler}
            control={control}
            defaultPortals={defaultPortals}
            disableSubmitBtn={disableSubmitBtn}
            errors={errors}
            handleBackStep={handleBackStep}
            handleCampaignTypeToggler={handleCampaignTypeToggler}
            handleNextStep={handleNextStep}
            handleSubmit={handleSubmit}
            locationList={locationsContainer}
            userList={userList}
            onSubmit={onSubmit}
            radioValue={radioValueState}
            register={register}
            watchAllowTextFeedback={watchAllowTextFeedback}
            watchAutoReviewReminder={watchAutoReviewReminder}
            watchLocationField={watchLocationField}
            watchUsersField={watchUsersField}
            requestType="review request"
            setValue={setValue}
            handleRemovePostSite={handleRemovePostSite}
          />
        );
      case 'survey':
        return (
          <SurveyCreationContent
            activeStep={activeStep}
            campaignViewToggler={campaignViewToggler}
            control={control}
            defaultPortals={defaultPortals}
            disableSubmitBtn={disableSubmitBtn}
            errors={errors}
            handleBackStep={handleBackStep}
            handleCampaignTypeToggler={handleCampaignTypeToggler}
            handleNextStep={handleNextStep}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            radioValue={radioValueState}
            register={register}
            locationList={locationsContainer}
            userList={userList}
            watchAutoReviewReminder={watchAutoReviewReminder}
            watchLocationField={watchLocationField}
            watchUsersField={watchUsersField}
            requestType="survey"
            setValue={setValue}
          />
        );
      case 'notification':
        return (
          <NotificationCreationContent
            activeStep={activeStep}
            campaignViewToggler={campaignViewToggler}
            control={control}
            defaultPortals={defaultPortals}
            disableSubmitBtn={disableSubmitBtn}
            errors={errors}
            handleBackStep={handleBackStep}
            handleCampaignTypeToggler={handleCampaignTypeToggler}
            handleNextStep={handleNextStep}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            radioValue={radioValueState}
            register={register}
            locationList={locationsContainer}
            userList={userList}
            watchAutoReviewReminder={watchAutoReviewReminder}
            watchLocationField={watchLocationField}
            watchUsersField={watchUsersField}
            setValue={setValue}
            requestType="notification"
          />
        );
      default:
        return null;
    }
  };

  return renderView ? (
    <Grid container direction="column" style={{ height: '100%' }} wrap="nowrap">
      {loaderContainer}
      <CusotmAlert
        open={showAlert}
        message="Campaign saved successfully"
        handleClose={handleAlertClose}
      />

      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        sm={12}
        style={{ flex: '1 0 auto', maxHeight: '40px', minHeight: '40px', padding: '.5em 1em' }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <ThemeProvider theme={{ color: iconsTheme.helpIcon }}>
              <StyledHelpIcon />
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Grid container justifyContent="flex-end">
              <ThemeProvider theme={OnBoardingHeaderLogoTheme}>
                <CompanyLogo alt="Reveo" logo={Logo} />
              </ThemeProvider>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={12} sm={12} style={{ height: 'calc(100% - 40px)' }}>
        {getPopupContent(campaignType)}
      </Grid>
    </Grid>
  ) : (
    <Loader />
  );
}

PopupContainer.propTypes = {
  show: Proptypes.bool,
  handleNext: Proptypes.func,
  selectedCampaign: Proptypes.number,
};

export default PopupContainer;
