import { Grid } from '@material-ui/core';
import styled from 'styled-components';

const StyledBeginText = styled(Grid)`
  letter-spacing: none;
  color: ${(props) => props.theme.color} !important;
  font-family: Montserrat;
  font-size: ${(props) => (props.theme.fontSize ? props.theme.fontSize : '24px ')}!important;
  margin: ${(props) => props.theme.margin} !important;
  padding: ${(props) => props.theme.padding} !important;
  font-weight: ${(props) => props.theme.fontWeight} !important;
`;

export default StyledBeginText;
