/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Card, CardContent, Typography, Avatar } from '@material-ui/core';
import Proptypes from 'prop-types';
import Logo from '../../assets/images/Reveo_Logo_Gray.svg';
import UploadLayout from '../UploadLayout';
import { getIcon, imageURLCheck } from '../../utils/helper/helper';

const CardStyle = styled(Card)`
  text-align: center;
  min-width: 100%;
  height: 100%;
  position: relative;
`;

const StyledAvatar = styled(Avatar)`
  width: 100px !important;
  height: 100px !important;
  margin: 0 auto !important;
`;

const StyledImage = styled.img`
  margin: 2rem 0 1rem 0;
  width: 220px;
  height: auto;
`;
const CardContentStyle = styled(CardContent)`
  text-align: center;
  padding-top: 2px;
  padding-bottom: 10px;
`;

const BottomContent = styled(CardContent)`
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 12px 0 !important;
`;

const StyledSiteButtons = styled.div`
  display: flex;
  row-gap: 0.5rem;
  flex-direction: column;
  align-items: center;
`;

const ReviewSiteButtons = styled.div`
  display: flex;
  column-gap: 1rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 1rem;
`;

const StyledSiteButton = styled.button`
  width: 90px;
  height: 90px;
  border-radius: 8px;
  border: none;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: #cacaca 0 0 20px;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  font-family: 'Montserrat';
  font-size: 0.7rem;
  font-weight: 500;
  color: #000000;
`;

const StyledTextUsButton = styled.button`
  width: 80%;
  background-color: #dfdfdf;
  border-radius: 50px;
  padding: 0.8rem;
  display: flex;
  justify-content: center;
  border: none;
  margin-top: 2rem;
  font-family: 'Montserrat';
  font-size: 1rem;
  font-weight: 500;
  color: '#000000';
`;

const QuestionTextStyle = styled(Typography)`
  color: #9e9a9a !important;
  font-family: 'Montserrat', bold !important;
  font-size: 1.2rem !important;
  margin: 2rem 0 1rem 0 !important;
`;

function PositiveFeedback(props) {
  const {
    disabledBtn,
    powerImageWidth,
    openDynamickLink,
    postitiveFeedbackCard,
    onMessageTextFeedback,
    allowTextFeedback,
    reviewResponseData,
    url,
    userImage,
  } = props;

  const { title, postSites } = postitiveFeedbackCard;

  const shareReviewBtn = postSites || [];

  const getUpdatedItem = (item) => {
    let obj = {};
    if (reviewResponseData.postSites.length) {
      reviewResponseData.postSites.forEach((i1) => {
        if (i1.postSites && i1.postSites.name.toLowerCase() === item.name.toLowerCase()) {
          obj = {
            name: i1.postSites.name,
            postReviewLink: i1.postReviewLink,
            listReviewLink: i1.listReviewsLink,
            isConnected: i1.isConnected || i1.isDeleted || i1.isSynced,
          };
        }
      });
    }
    return obj;
  };

  const dynamicBtn = shareReviewBtn.map((item) => {
    const obj = getUpdatedItem(item);

    return obj.isConnected ? (
      <div
        key={item.name}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <StyledSiteButton onClick={() => openDynamickLink(obj)} disabled={disabledBtn}>
          <img src={getIcon(item.name).icon} width={60} height={60} />
        </StyledSiteButton>
        <div
          style={{
            textTransform: 'capitalize',
            fontSize: '10px',
            marginTop: '.5rem',
            color: '#000000',
            fontWeight: 400,
            letterSpacing: 'normal',
          }}
        >
          {item.name}
        </div>
      </div>
    ) : (
      ''
    );
  });

  const dynamicBtnView = shareReviewBtn.length > 0 ? dynamicBtn : '';

  const addHref = () => {
    const link = document.getElementById('myAnchor');
    link.setAttribute('href', reviewResponseData.metaData.textFeedbackLink);
    onMessageTextFeedback();
  };

  const businessLogo = reviewResponseData.locationInfo?.uploadedFile?.filter(
    (image) => image.meta === 'business_logo',
  );
  const businessName = reviewResponseData.locationInfo?.business[0]?.nickName ?? '';

  return (
    <CardStyle>
      {businessLogo ? (
        <StyledImage alt={`${businessName} logo`} src={imageURLCheck(businessLogo[0].url)} />
      ) : null}
      {userImage !== undefined ? (
        <StyledAvatar
          alt={'user image'}
          src={`${process.env.REACT_APP_IMAGE_ENDPOINT}${userImage}`}
        />
      ) : null}
      {url ? (
        <CardContentStyle>
          <ThemeProvider theme={{ width: '85%', margin: '0 auto', border: 'none' }}>
            <UploadLayout
              handleClick={() => {}}
              url={`${process.env.REACT_APP_IMAGE_ENDPOINT}${url}`}
              imgHeight="350px"
              disabled
            />
          </ThemeProvider>
        </CardContentStyle>
      ) : null}
      <CardContentStyle>
        <QuestionTextStyle>{title}</QuestionTextStyle>
      </CardContentStyle>
      <StyledSiteButtons>
        <ReviewSiteButtons>{dynamicBtnView}</ReviewSiteButtons>
        {allowTextFeedback ? (
          <>
            <a
              href="#"
              id="myAnchor"
              onClick={() => addHref()}
              style={{
                display: 'flex',
                justifyContent: 'center',
                textDecoration: 'none',
                width: '100%',
                color: '#000',
              }}
            >
              <StyledTextUsButton>Text us feedback</StyledTextUsButton>
            </a>
          </>
        ) : (
          ''
        )}
      </StyledSiteButtons>
      <BottomContent>
        <div style={{ display: 'inline-grid' }}>
          <span style={{ fontSize: '8px', marginLeft: '14px' }}>Powered by</span>
          <img
            src={Logo}
            style={{ width: powerImageWidth ? `${powerImageWidth}em` : '7rem' }}
            alt="logo"
          />
        </div>
      </BottomContent>
    </CardStyle>
  );
}

PositiveFeedback.propTypes = {
  disabledBtn: Proptypes.bool,
  openDynamickLink: Proptypes.func.isRequired,
  url: Proptypes.string.isRequired,
  onMessageTextFeedback: Proptypes.func.isRequired,
  allowTextFeedback: Proptypes.bool.isRequired,
  reviewResponseData: Proptypes.object.isRequired,
};

PositiveFeedback.defaultProps = {
  disabledBtn: false,
};

export default PositiveFeedback;
