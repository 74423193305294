/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { Chip, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import styled from 'styled-components';
import StyledTextField from '../../../../../Common/StyledTextField';
import InsertLink from '@material-ui/icons/InsertLink';

const StyledCardTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #6c6c6c;
  margin-bottom: 15px;
`;

const StyledChip = withStyles({
  root: {
    backgroundColor: '#f5f5f5 !important',
    borderColor: '#f5f5f5 !important',
    margin: '3px !important',
    fontFamily: 'Montserrat, sans-serif !important',
    fontSize: '10px !important',
    height: '28px !important',
  },
  label: {
    fontSize: '12px !important', // Adjust the font size as needed
    padding: '0 8px', // Adjust the padding inside the label as needed
  },
})(Chip);

const StyledContainerTextField = styled.div`
  padding: 1rem 1.5rem;
`;

const StyledContainerText = styled.div`
  border-radius: 15px;
  background-color: #fff;
  font-size: 12px !important;
`;

const StyledContainerIcons = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid #d3d3d3;
`;

const StyledContainerIconsRemoveAttach = styled.div`
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -1px;
  right: -1px;
  padding: 5px;
  height: 5px;
  width: 5px;
  border-radius: 99px;
`;

const StyledContainerIconsRemove = styled.span`
  font-size: 10px;
  margin: 0px;
  padding: 0px;
`;

const StyledContainerIconsAttach = styled.div`
  background-color: #35eda7;
  border-radius: 15px;
  padding: 5px;
  position: relative;
`;

const TextAreaCampaignSettigs = ({
  title = '',
  value = '',
  setValue,
  placeholder = '',
  multiline = false,
  maxRows = 6,
  withLink = false,
  userLink,
  setUserLink,
  handleAttachFileIcon,
  withChip = true,
  styles,
}) => {
  const chip = ['@customerName', '@business', '@nickname', '@website', '@phone', '@link'];

  const handleClick = (payload) => {
    setValue(`${value} ${payload}`);
  };

  const getChip = (item) => (
    <StyledChip key={item} label={item} onClick={() => handleClick(item)} />
  );

  const chipContainer = <div style={{ flexWrap: 'wrap' }}>{chip.map((item) => getChip(item))}</div>;

  return (
    <div style={{ margin: '0 0 1rem 0', flex: 1 }}>
      {title ? <StyledCardTitle>{title}</StyledCardTitle> : null}

      <StyledContainerText>
        <StyledContainerTextField>
          <StyledTextField
            value={value}
            onChange={(e) => setValue(e.target.value)}
            multiline={multiline}
            placeholder={placeholder}
            styles={styles}
            maxRows={maxRows}
            fontSize="24px"
            border="none"
          />
        </StyledContainerTextField>

        {withLink && (
          <StyledContainerIcons>
            <>
              {userLink?.titleLink?.length > 0 ? (
                <StyledContainerIconsAttach>
                  <StyledContainerIconsRemoveAttach
                    onClick={() => setUserLink({ titleLink: '', link: '' })}
                  >
                    <StyledContainerIconsRemove>X</StyledContainerIconsRemove>
                  </StyledContainerIconsRemoveAttach>
                  <InsertLink fontSize="small" onClick={handleAttachFileIcon} />
                </StyledContainerIconsAttach>
              ) : (
                <IconButton onClick={handleAttachFileIcon}>
                  <InsertLink fontSize="small" />
                </IconButton>
              )}
            </>
            {withChip && chipContainer}
          </StyledContainerIcons>
        )}
      </StyledContainerText>
    </div>
  );
};

TextAreaCampaignSettigs.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  multiline: PropTypes.bool,
  maxRows: PropTypes.number,
  withLink: PropTypes.bool,
  withChip: PropTypes.bool,
  placeholder: PropTypes.string,
  userLink: PropTypes.shape({
    titleLink: PropTypes.string,
    link: PropTypes.string,
  }),
  setUserLink: PropTypes.func,
  handleAttachFileIcon: PropTypes.func,
};

export default TextAreaCampaignSettigs;
