import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';

const PrimaryButton = styled(Button)`
  text-transform: capitalize !important;
  letter-spacing: unset !important;
  font-weight: 600 !important;
  font-family: inherit !important;
  padding: 0.7rem 1.5rem;
  border: 1px solid transparent;
  cursor: pointer !important;
`;

const StyledOutlineButton = styled(Button)`
  text-transform: capitalize !important;
  letter-spacing: unset !important;
  font-weight: 600 !important;
  font-family: inherit !important;
  padding: 0.7rem 1.5rem;
`;

function ReveoButton(props) {
  const { title, variant, onClick, color, icon } = props;

  return variant === 'outlined' ? (
    <StyledOutlineButton
      variant="outlined"
      onClick={onClick}
      style={{
        borderColor: color === 'black' ? '#000' : '#303030',
        columnGap: '10px',
        display: 'flex',
        paddingRight: icon ? '1.5rem' : '1.5rem',
        paddingLeft: icon ? '1rem' : '',
      }}
    >
      {icon ? icon : null}
      {title}
    </StyledOutlineButton>
  ) : (
    <PrimaryButton
      onClick={onClick}
      title={title}
      style={{
        backgroundColor: color === 'black' ? '#000' : '#30feaf',
        color: color === 'black' ? '#fff' : '#000',
        display: 'flex',
        columnGap: '10px',
        paddingLeft: icon ? '1rem' : '',
        width: '100%',
      }}
    >
      {icon ? icon : null}
      {title}
    </PrimaryButton>
  );
}

export default ReveoButton;
