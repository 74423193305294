import React, { useContext } from 'react';
import Proptypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import mobilePreviewImage from '../../assets/images/mobile_preview.png';

const StyledMobilePreview = styled.div`
position: relative;
width: ${(props) => (props.width ? props.width : '300px')};
// background: url(${mobilePreviewImage});
// width: 100%;
    height: ${(props) => (props.height ? props.height : '600px')};
    background-position: top;
  // position: relative;
  // height: 500px;
  // margin: auto;
  // border: ${(props) => `10px ${props.theme.color} solid `};
  // border-top-width: 5px;
  // border-bottom-width: 5px;
  // border-radius: 36px;

  // &:before {
  //   content: '';
  //   display: block;
  //   width: 152px;
  //   height: 20px;
  //   position: absolute;
  //   top: 5px;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  //   background: ${(props) => props.theme.color};
  //   border-radius: 10px;
  }
`;

function MobilePreview(props) {
  const { children, width, height } = props;
  const { state } = useContext(GlobalContext);
  const { themeContainer } = state;

  return (
    <ThemeProvider theme={{ color: themeContainer.mobileColor }}>
      <StyledMobilePreview style={{ width: width, height: height }}>
        <div style={{ overflow: 'auto !important', position: 'relative', height: '100%' }}>
          {children}
          <img
            src={mobilePreviewImage}
            alt="mobile preview"
            style={{
              width: 'auto',
              height: '100%',
              position: 'absolute',
              pointerEvents: 'none',
              top: 0,
            }}
          />
        </div>
      </StyledMobilePreview>
    </ThemeProvider>
  );
}

MobilePreview.propTypes = {
  children: Proptypes.node.isRequired,
};

export default MobilePreview;
