import React from 'react';
import Proptypes from 'prop-types';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';

const StyledDescriptionTextContainer = styled(Grid)`
  font-size: ${(props) => (props.theme.fontSize ? props.theme.fontSize : '12px')} !important;
  padding: ${(props) => (props.theme.padding ? props.theme.padding : '0')} !important;
  color: ${(props) => (props.theme.color ? props.theme.color : '#6c6c6c')} !important;
  font-family: 'Montserrat' !important;
  margin: ${(props) => (props.theme.margin ? props.theme.margin : '0 0 1em')} !important;
  letter-spacing: none;
`;

function StyledDescriptionText(props) {
  const { children } = props;

  return <StyledDescriptionTextContainer>{children}</StyledDescriptionTextContainer>;
}

StyledDescriptionText.propTypes = {
  children: Proptypes.node.isRequired,
};

export default StyledDescriptionText;
