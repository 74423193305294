import { useState } from 'react';

const useAlert = () => {
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [show, setShow] = useState(false);
  const [messageAction, setMessageAction] = useState('');

  const handleSetMessage = (messageText) => {
    setMessage(messageText);
  };

  const handleSetMessageType = (messageTypeText) => {
    setMessageType(messageTypeText);
  };

  const handleSetMessageAction = (messageAction) => {
    setMessageAction(messageAction);
  };

  const handleShow = () => setShow(true);

  const handleClose = () => setShow(false);

  return {
    message,
    messageType,
    show,
    handleSetMessage,
    handleSetMessageType,
    handleShow,
    handleClose,
    handleSetMessageAction,
    messageAction,
  };
};
export default useAlert;
