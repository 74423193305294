import React from 'react';
import Proptypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { Grid } from '@material-ui/core';
import LaptopPreview from '../LaptopPreview/LaptopView';
import CustomButton from '../CustomButton/CustomButton';
import EditableText from '../Editable/EditableText';
import EditableImage from '../Editable/EditableImage';

const StyledGridContainer = styled(Grid)`
  padding: 0 0 0 90px !important;
  height: 94% !important;
  position: absolute !important;
  top: 55px !important;
  width: 57% !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const StyledMessage = styled(Grid)`
  font-family: Montserrat !important;
  font-size: 16px !important;
  color: #656565 !important;
  margin: 0.5em 0 !important;
`;

const StyledLink = styled(Grid)`
  font-family: Montserrat !important;
  font-size: 14px !important;
  text-decoration: underline !important;
  color: #1877f2 !important;
  margin: 0.5em 0 !important;
`;

const buttonTheme = {
  background: '#000000',
  color: '#FFFFFF',
  borderTopRight: '5px',
  borderBottomRight: '5px',
  borderTopLeft: '5px',
  borderBottomLeft: '5px',
  padding: '5px',
  minWidth: '120px',
};

const emailTitleTheme = {
  fontSize: '36px',
  margin: '0.5em 0',
  color: '#656565',
};

function LaptopViewContainer(props) {
  const { slides } = props;

  const emailTitleContainer = (
    <EditableText {...props} id="emailTitle" welcomeTheme={emailTitleTheme} />
  );
  return (
    <>
      <Grid item>
        {/* <StyledMobileViewContainer container justify="space-around"> */}
        <LaptopPreview>
          <StyledGridContainer container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <EditableImage
                {...props}
                uploadFileId="emailUploadFile"
                urlId="emailImgUrl"
                activeStep={0}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container>
                {emailTitleContainer}
                <StyledMessage item xs={12} sm={12} md={12} lg={12}>
                  {slides[0].title}
                </StyledMessage>
                <StyledLink item xs={12} sm={12} md={12} lg={12}>
                  https://my.reveo.com/sys/activate?token=cb704a17d6a083e74da3c66b6ead8
                </StyledLink>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <ThemeProvider theme={buttonTheme}>
                    <CustomButton>Leave a review</CustomButton>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </Grid>
          </StyledGridContainer>
        </LaptopPreview>
        {/* </StyledMobileViewContainer> */}
      </Grid>
    </>
  );
}

LaptopViewContainer.propTypes = {
  slides: Proptypes.arrayOf(Proptypes.object).isRequired,
};

export default React.memo(LaptopViewContainer);
