import React from 'react';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import { makeStyles, Popover, Paper, MenuItem, MenuList } from '@material-ui/core';
import styled from 'styled-components';
import RadioboxMarkedIcon from 'mdi-react/RadioboxMarkedIcon';
import CheckboxOutlineIcon from 'mdi-react/CheckboxOutlineIcon';
import TextIcon from 'mdi-react/TextIcon';
import StarOutlineIcon from 'mdi-react/StarOutlineIcon';
import ThumbsUpDownIcon from 'mdi-react/ThumbsUpDownIcon';
import NumericIcon from 'mdi-react/NumericIcon';
import usePopover from '../../../../../hooks/usePopover';

const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: 'hidden',
    background: theme.background,
  },
}));

const StyledPaper = styled(Paper)`
  min-width: 200px !important;
  max-width: 200px !important;
  background-color: #3f3f3f;
`;

const StyledItemsBox = styled.div`
  background-color: #f5f5f5;
`;
const StyledListItemText = styled.div`
  font-family: 'Montserrat' !important;
  font-weight: 600 !important;
  padding: 0.3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddTypeRequestModal = ({ handleAddRequest }) => {
  const addTypeRequestPopover = usePopover();
  const classes = useStyles();

  return (
    <div>
      <PlusCircleOutlineIcon
        onClick={addTypeRequestPopover.handleClick}
        size={30}
        style={{ marginTop: 10 }}
        color="#808080"
      />

      <Popover
        open={addTypeRequestPopover.open}
        anchorEl={addTypeRequestPopover.anchorEl}
        onClose={addTypeRequestPopover.handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: classes.paper,
        }}
        style={{ marginTop: '20px' }}
      >
        <StyledPaper>
          <StyledItemsBox>
            <MenuList dense onClick={addTypeRequestPopover.handleClose}>
              <MenuItem onClick={() => handleAddRequest('singleChoice')}>
                <StyledListItemText>
                  <RadioboxMarkedIcon style={{ marginRight: 10 }} size={30} color="#808080" />
                  Single Choice
                </StyledListItemText>
              </MenuItem>
              <MenuItem onClick={() => handleAddRequest('multipleChoice')}>
                <StyledListItemText>
                  <CheckboxOutlineIcon style={{ marginRight: 10 }} size={30} color="#808080" />
                  Multiple Choice
                </StyledListItemText>
              </MenuItem>
              <MenuItem onClick={() => handleAddRequest('starRating')}>
                <StyledListItemText>
                  <StarOutlineIcon size={30} style={{ marginRight: 10 }} color="#808080" />
                  Star Rating
                </StyledListItemText>
              </MenuItem>
              <MenuItem onClick={() => handleAddRequest('shortAnswer')}>
                <StyledListItemText>
                  <TextIcon size={30} style={{ marginRight: 10 }} color="#808080" />
                  Short Answer
                </StyledListItemText>
              </MenuItem>
              <MenuItem onClick={() => handleAddRequest('linearScale')}>
                <StyledListItemText>
                  <NumericIcon size={30} style={{ marginRight: 10 }} color="#808080" />
                  Linear Scale
                </StyledListItemText>
              </MenuItem>
              <MenuItem onClick={() => handleAddRequest('thumbs')}>
                <StyledListItemText>
                  <ThumbsUpDownIcon size={30} style={{ marginRight: 10 }} color="#808080" />
                  Thumbs up/down
                </StyledListItemText>
              </MenuItem>
            </MenuList>
          </StyledItemsBox>
        </StyledPaper>
      </Popover>
    </div>
  );
};

export default AddTypeRequestModal;
