import React from 'react';
import { ThemeProvider } from 'styled-components';
import CustomButton from '../components/CustomButton/CustomButton';
import TransperentButtonTheme from '../themes/Button/TransperentButtonTheme';
import StyledTableCell from '../components/TableRowCell';
import StyledDataUsageIcon from '../components/Icons/DataUsageIcon';
import StyledTrashIcon from '../components/Icons/TrashIcon';

const getGoalsColumns = (handleGoalSetting, iconsTheme, handleDelete) => [
  {
    name: 'name',
    label: 'NAME',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => {
        return <StyledTableCell>{value}</StyledTableCell>;
      },
    },
  },
  {
    name: 'jan',
    label: 'JAN',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => {
        return <StyledTableCell>{value && value !== undefined ? value.total : ''}</StyledTableCell>;
      },
    },
  },
  {
    name: 'feb',
    label: 'FEB',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => {
        return <StyledTableCell>{value && value !== undefined ? value.total : ''}</StyledTableCell>;
      },
    },
  },
  {
    name: 'mar',
    label: 'MAR',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => {
        return <StyledTableCell>{value && value !== undefined ? value.total : ''}</StyledTableCell>;
      },
    },
  },
  {
    name: 'apr',
    label: 'APR',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => {
        return <StyledTableCell>{value && value !== undefined ? value.total : ''}</StyledTableCell>;
      },
    },
  },
  {
    name: 'may',
    label: 'MAY',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => {
        return <StyledTableCell>{value && value !== undefined ? value.total : ''}</StyledTableCell>;
      },
    },
  },
  {
    name: 'jun',
    label: 'JUN',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => {
        return <StyledTableCell>{value && value !== undefined ? value.total : ''}</StyledTableCell>;
      },
    },
  },
  {
    name: 'jul',
    label: 'JUL',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => {
        return <StyledTableCell>{value && value !== undefined ? value.total : ''}</StyledTableCell>;
      },
    },
  },
  {
    name: 'aug',
    label: 'AUG',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => {
        return <StyledTableCell>{value && value !== undefined ? value.total : ''}</StyledTableCell>;
      },
    },
  },
  {
    name: 'sep',
    label: 'SEP',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => {
        return <StyledTableCell>{value && value !== undefined ? value.total : ''}</StyledTableCell>;
      },
    },
  },
  {
    name: 'oct',
    label: 'OCT',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => {
        return <StyledTableCell>{value && value !== undefined ? value.total : ''}</StyledTableCell>;
      },
    },
  },
  {
    name: 'nov',
    label: 'NOV',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => {
        return <StyledTableCell>{value && value !== undefined ? value.total : ''}</StyledTableCell>;
      },
    },
  },
  {
    name: 'dec',
    label: 'DEC',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => {
        return <StyledTableCell>{value && value !== undefined ? value.total : ''}</StyledTableCell>;
      },
    },
  },
  {
    name: '',
    label: 'Action',
    options: {
      filter: false,
      sort: false,
      searchable: false,
      customBodyRenderLite: (dataIndex, rowIndex) => {
        return (
          <div style={{ display: 'flex' }}>
            <ThemeProvider theme={{ ...TransperentButtonTheme }}>
              <CustomButton
                onClick={() => handleGoalSetting(dataIndex, rowIndex)}
                title="Goal Setting"
              >
                <ThemeProvider theme={{ color: iconsTheme.dataUsageIcon }}>
                  <StyledDataUsageIcon />
                </ThemeProvider>
              </CustomButton>
            </ThemeProvider>
            <ThemeProvider theme={{ ...TransperentButtonTheme, color: iconsTheme.trashIconTheme }}>
              <CustomButton onClick={() => handleDelete(dataIndex, rowIndex)} title="Delete">
                <StyledTrashIcon />
              </CustomButton>
            </ThemeProvider>
          </div>
        );
      },
    },
  },
];

export default getGoalsColumns;
