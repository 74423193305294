import GroupAddIcon from '@material-ui/icons/GroupAdd';
import styled from 'styled-components';

const StyledGroupAddIcon = styled(GroupAddIcon)`
  color: ${(props) => props.theme.color} !important;
  font-size: ${(props) => props.theme.fontSize} !important;
  opacity: ${(props) => props.theme.opacity} !important;
`;

export default StyledGroupAddIcon;
