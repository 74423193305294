import React, { createContext, useReducer } from 'react';
import Proptypes from 'prop-types';
import onBoardingState from '../state/OnBoardingState';
import OnBoardingReducer from '../Reducers/OnBoardingReducer';

export const OnBoardingContext = createContext(onBoardingState);

const OnBoardingStore = ({ children }) => {
  const [onBoardingData, onBoardingDispatch] = useReducer(OnBoardingReducer, onBoardingState);
  return (
    <OnBoardingContext.Provider value={{ onBoardingData, onBoardingDispatch }}>
      {children}
    </OnBoardingContext.Provider>
  );
};

OnBoardingStore.propTypes = {
  children: Proptypes.node.isRequired,
};

export default OnBoardingStore;
