import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import StyledTextField from './StyledTextField';

function StyledNumberField(props) {
  const {
    name,
    format,
    customInput,
    label,
    onChange,
    error,
    helperText,
    defaultValue,
    value,
    mask,
    onBlur,
    disabled,
    InputProps,
  } = props;

  const handleChange = (values) => {
    let { value: inputValue } = values;
    inputValue = inputValue.replace(/^\+?1/, '');
    onChange({
      ...values,
      value: inputValue,
    });
  };

  const handlePaste = (event) => {
    const pastedValue = event.clipboardData.getData('text/plain');
    const modifiedValue = pastedValue.replace(/^\+?1/, '');
    handleChange({
      value: modifiedValue,
    });
  };

  return (
    <NumberFormat
      name={name}
      format={format}
      customInput={customInput}
      label={label}
      onValueChange={handleChange}
      onPaste={handlePaste}
      error={error}
      helperText={helperText}
      defaultValue={defaultValue}
      value={value}
      mask={mask}
      onBlur={onBlur}
      disabled={disabled}
      InputProps={InputProps}
    />
  );
}

StyledNumberField.propTypes = {
  name: PropTypes.string.isRequired,
  format: PropTypes.string,
  customInput: PropTypes.object, // Adjusted prop name here
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string.isRequired,
  mask: PropTypes.string,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  InputProps: PropTypes.object, // Adjusted prop type here
};

StyledNumberField.defaultProps = {
  format: '+1 ### ### ####',
  customInput: StyledTextField,
  error: false,
  helperText: '',
  defaultValue: '',
  mask: ' ',
  disabled: false,
};

export default StyledNumberField;
