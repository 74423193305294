import React, { useContext, useEffect, useState, useCallback } from 'react';
import Proptypes from 'prop-types';
import { Grid, Popover, makeStyles, MenuItem, Box, Divider } from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import BorderSearchBoxTheme from '../../themes/SearchBox/BorderSearchBoxTheme';
import CusotomSearchBox from '../../components/CustomSearchBox/CustomSearchBox';
import useSearch from '../../hooks/useSearch';
import baseUrl from '../../utils/services/config';
import { errorChecker } from '../../utils/helper/helper';

const StyledMenuItem = styled(MenuItem)`
  font-weight: 600 !important;
  font-family: 'Montserrat' !important;
`;

function TagSearchPopover(props) {
  const { handleClose, open, id, anchorEl, currentTagList, handleSaveTag, handleAddGlobalTag } =
    props;
  const { state, dispatch } = useContext(GlobalContext);
  const { selectedBusiness, selectedLoaction } = state;
  const [allTags, setAllTags] = useState();

  const fetchGlobalTags = useCallback(async () => {
    try {
      const results = await baseUrl.get(`/business/${selectedBusiness}`);
      if (results.status === 200) {
        const globalTags =
          results.data.tags !== null
            ? results.data.tags.filter(function (o1) {
                return !currentTagList.some(function (o2) {
                  return o1.label.toLowerCase() === o2.label.toLowerCase(); // assumes unique id
                });
              })
            : [];
        setAllTags(globalTags);
        //console.log(results.data);
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  }, [currentTagList]);

  useEffect(() => {
    // const result =
    //   businessInfo.tags !== null
    //     ? businessInfo.tags.filter(function (o1) {
    //         return !currentTagList.some(function (o2) {
    //           return o1.label.toLowerCase() === o2.label.toLowerCase(); // assumes unique id
    //         });
    //       })
    //     : [];
    // setAllTags(result);
    fetchGlobalTags();
  }, [currentTagList]);

  const { filteredData, handleSearch, searchValue, clearSearch, setSearchValue } = useSearch({
    searchParameters: ['label'],
    data: allTags || [],
  });

  const useStyles = makeStyles((theme) => ({
    popover: {
      padding: '1em',
      minWidth: '320px',
    },
    paper: {
      padding: theme.spacing(1),
      minWidth: '320px',
      background: theme.background,
    },
  }));

  const classes = useStyles();

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      className={classes.popover}
      classes={{
        paper: classes.paper,
      }}
      style={{ marginTop: '40px', maxWidth: '350px' }}
    >
      <Grid container alignItems="center" justifyContent="center">
        <ThemeProvider theme={BorderSearchBoxTheme}>
          <CusotomSearchBox
            placeholder="Search"
            handleChange={handleSearch}
            clearSearch={clearSearch}
            searchValue={searchValue}
          />
        </ThemeProvider>
        <Box width="100%" display="flex" flexDirection="column">
          {filteredData.map((tag, index) => (
            <StyledMenuItem dense key={index} onClick={() => handleSaveTag(tag)}>
              {tag.label}
            </StyledMenuItem>
          ))}
          {filteredData.length === 0 && searchValue.length > 0 ? (
            <>
              <Divider style={{ marginBottom: '10px' }} />
              <StyledMenuItem
                dense
                onClick={() => [setSearchValue(''), handleAddGlobalTag(searchValue)]}
              >
                {'Add New tag: ' + searchValue}
              </StyledMenuItem>
            </>
          ) : null}
        </Box>
      </Grid>
    </Popover>
  );
}

TagSearchPopover.propTypes = {
  handleClose: Proptypes.func.isRequired,
  open: Proptypes.bool.isRequired,
};

export default React.memo(TagSearchPopover);
