import React, { useCallback, useContext, useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ThemeProvider as MUIThemeProvider,
} from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import baseUrl from '../../utils/services/config';
import {
  errorChecker,
  convertIntoCampaignCards,
  ConvertIntoLocationDropDown,
} from '../../utils/helper/helper';
import CustomButton from '../CustomButton/CustomButton';
import CampaignList from '../List/CampaignList/CampaignList';
import { CampaignContext } from '../../Stores/CamapignStore';
import NoDataFound from '../NoDataFound/NoDataFound';
import ButtonLoader from '../ButtonLoader/ButtonLoader';
import ContactInfoList from '../List/ContactInfoList/ContactInfoList';
import Dropdown from '../Dropdown/Dropdown';
import FieldGroupLable from '../FieldGroupLable/FieldGroupLable';
import StyledFormControl from '../StyledFormControl';
import useInputFieldTheme from '../../hooks/useInputFieldTheme';
import useButtonTheme from '../../hooks/useButtonTheme';

const StyledDialogTitle = styled(DialogTitle)`
  letter-spacing: 1.08px;
  color: #6c6c6c;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 400 !important;
  & > h2 {
    letter-spacing: 1.08px;
    color: #6c6c6c;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 400 !important;
  }
`;

const StyledContentDialog = styled(DialogContent)`
  padding: 0 0.5em !important;
`;

const StyledActions = styled(DialogActions)`
  padding: 0 !important;
`;

function CampaignSelectionView(props) {
  const { handleSelectedCampaign, campaginLoader, name, phone, email, id, totalmembers } = props;
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const { state, dispatch } = useContext(GlobalContext);
  const { locations, campaignLocation } = state;
  const { campaignDispatch, campaignStates } = useContext(CampaignContext);
  const { sendInviteCampaigns, loader } = campaignStates;
  const { inputTheme } = useInputFieldTheme();
  const { buttonTheme } = useButtonTheme();

  const fetchUserList = useCallback(async () => {
    if (campaignLocation && !totalmembers) {
      campaignDispatch({
        type: 'INIT_SEND_CAMPAIGN_DATA',
      });
      campaignDispatch({
        type: 'SHOW_LOADER',
      });

      baseUrl
        .get(
          `/campaing-list?location=${campaignLocation}&user=${localStorage.getItem(
            'USER_ID',
          )}&client=${id}`,
        )
        .then((res) => {
          campaignDispatch({
            type: 'SET_SEND_INVITE_CAMPAIGN',
            payload: Array.from(convertIntoCampaignCards(res.data)),
          });
        })
        .catch((err) => {
          campaignDispatch({
            type: 'HIDE_LOADER',
          });
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    } else if (campaignLocation && totalmembers) {
      campaignDispatch({
        type: 'INIT_SEND_CAMPAIGN_DATA',
      });
      campaignDispatch({
        type: 'SHOW_LOADER',
      });

      baseUrl
        .get(
          `/group/campaing/list?location=${campaignLocation}&user=${localStorage.getItem(
            'USER_ID',
          )}`,
        )
        .then((res) => {
          campaignDispatch({
            type: 'SET_SEND_INVITE_CAMPAIGN',
            payload: Array.from(convertIntoCampaignCards(res.data)),
          });
        })
        .catch((err) => {
          campaignDispatch({
            type: 'HIDE_LOADER',
          });
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    } else {
      campaignDispatch({
        type: 'HIDE_LOADER',
      });
    }
  }, [campaignLocation, totalmembers, dispatch, campaignDispatch, id]);

  useEffect(() => {
    setSelectedCampaign(null);
    fetchUserList();
  }, [campaignLocation, fetchUserList]);

  const handleCampaignSelection = (index) => setSelectedCampaign(index);

  const handleSelectCampaign = () => {
    if (selectedCampaign !== undefined && selectedCampaign >= 0) {
      handleSelectedCampaign({
        id: sendInviteCampaigns[selectedCampaign].id,
        message: sendInviteCampaigns[selectedCampaign].message,
        type: sendInviteCampaigns[selectedCampaign].campaignType,
      });
    }
  };

  const handleSelectLocation = (e) => {
    dispatch({ type: 'SET_CAMPAIGN_LOCATION', payload: e.target.value });
  };

  const campaignDataNotFound =
    sendInviteCampaigns.length > 0 ? (
      sendInviteCampaigns.map((campaign, index) => (
        <CampaignList
          {...campaign}
          selectedCampaign={selectedCampaign}
          index={index}
          handleSelection={handleCampaignSelection}
        />
      ))
    ) : (
      <NoDataFound>No Campaign Found !</NoDataFound>
    );

  const campaignListContainer = loader ? (
    <Grid container alignItems="center" justify="center">
      <CircularProgress />
    </Grid>
  ) : (
    campaignDataNotFound
  );

  const showLoader = campaginLoader ? <ButtonLoader /> : null;
  const btnDisabled = selectedCampaign === null || campaginLoader;
  return (
    <>
      <StyledDialogTitle>
        <List>
          <Grid container alignItems="center" justify="space-between">
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ContactInfoList
                name={name}
                phone={phone || email}
                selectedUser={null}
                handleSelection={() => {}}
                phoneShowAsSecondary
                membersCount={totalmembers}
                MemberCountShowAsSecondary={totalmembers !== null && totalmembers >= 0}
              />
            </Grid>
          </Grid>
        </List>
        <List>
          <Grid container justify="center">
            <Grid container xs={11} sm={11} md={11} lg={11}>
              <FieldGroupLable item>Send campaign from</FieldGroupLable>
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={11}>
              <MUIThemeProvider theme={inputTheme}>
                <StyledFormControl>
                  <ThemeProvider theme={{ maxWidth: '100%' }}>
                    <Dropdown
                      value={campaignLocation}
                      listOptions={Array.from(ConvertIntoLocationDropDown(locations))}
                      //label="Send Campaign from"
                      handleChange={handleSelectLocation}
                    />
                  </ThemeProvider>
                </StyledFormControl>
              </MUIThemeProvider>
            </Grid>
          </Grid>
        </List>
      </StyledDialogTitle>
      <StyledContentDialog>
        <List>{campaignListContainer}</List>
      </StyledContentDialog>
      <StyledActions>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justify="center">
            <Grid item xs={12} sm={12} md={8} lg={8} style={{ display: 'flex' }}>
              <ThemeProvider
                theme={{ background: buttonTheme.buttonBgColor, color: buttonTheme.fontColor3 }}
              >
                <CustomButton
                  type="submit"
                  color="primary"
                  onClick={handleSelectCampaign}
                  disabled={btnDisabled}
                >
                  Send
                  {showLoader}
                </CustomButton>
              </ThemeProvider>
            </Grid>
          </Grid>
        </Grid>
      </StyledActions>
    </>
  );
}

CampaignSelectionView.propTypes = {
  campaginLoader: Proptypes.bool.isRequired,
  handleSelectedCampaign: Proptypes.func.isRequired,
  name: Proptypes.string.isRequired,
  phone: Proptypes.string.isRequired,
  email: Proptypes.string.isRequired,
  id: Proptypes.number.isRequired,
  totalmembers: Proptypes.number,
};

CampaignSelectionView.defaultProps = {
  totalmembers: null,
};

export default CampaignSelectionView;
