import React, { useContext } from 'react';
import Proptypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { Grid, Hidden } from '@material-ui/core';
import { GlobalContext } from '../Stores/GlobalStateStore';

const StyledTopNavBar = styled.div`
  background: #000;
  color: #000;
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
  height: 60px;
  width: 100%;
  padding: 0 1em;
`;

function TopNavBar(props) {
  const { children } = props;
  const { state } = useContext(GlobalContext);
  const { themeContainer } = state;
  return (
    <Hidden>
      <ThemeProvider theme={{ bgColor: themeContainer.sideBarBgColor }}>
        <StyledTopNavBar item md={12} lg={12}>
          {children}
        </StyledTopNavBar>
      </ThemeProvider>
    </Hidden>
  );
}

TopNavBar.propTypes = {
  children: Proptypes.objectOf(Proptypes.any).isRequired,
};

TopNavBar.defaultProps = {};

export default React.memo(TopNavBar);
