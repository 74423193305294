import React, { useEffect, useReducer, createContext, useContext } from 'react';
import Proptypes from 'prop-types';
import SocketState from '../state/SocketState';
import { initSockets } from '../socket_context/initSocket';
import socketReducer from '../Reducers/SocketReducer';
import GlobalReducer from '../Reducers/GlobalReducer';
import globalState from '../state/GlobalState';
import { GlobalContext } from './GlobalStateStore';

export const SocketContext = createContext(SocketState);

const SocketStore = ({ children }) => {
  const { dispatch } = useContext(GlobalContext);
  const [socketState, socketDispatch] = useReducer(socketReducer, SocketState);

  useEffect(() => {
    initSockets({ socketDispatch, dispatch });
  }, [initSockets]);

  return (
    <SocketContext.Provider value={{ socketState, socketDispatch }}>
      {children}
    </SocketContext.Provider>
  );
};

SocketStore.propTypes = {
  children: Proptypes.node.isRequired,
};

export default SocketStore;
