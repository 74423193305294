import React from 'react';
import Proptypes from 'prop-types';
import TimeAgo from 'timeago-react';

function ShowTimeAgo(props) {
  const { timeStampValue } = props;
  return <TimeAgo datetime={new Date(timeStampValue)} />;
}

ShowTimeAgo.propTypes = {
  timeStampValue: Proptypes.number.isRequired,
};

export default ShowTimeAgo;
