import React, { useEffect, useContext, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useState } from 'react';
import baseUrl from '../../utils/services/config';
import { Chip, Paper, IconButton, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { SurveyContext } from '../../Stores/SurveyStore';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import styled from 'styled-components';
import PlusCircleIcon from 'mdi-react/PlusCircleIcon';
import PrimaryButton from './PrimaryButton';

const useStyles = makeStyles((theme) => ({
  root: {
    '& label': {
      color: theme.inputLabelColor,
      fontFamily: 'Montserrat !important',
      fontSize: '1rem !important',
      fontWeight: '500 !important',
    },
    '& label.Mui-focused ': {
      color: '#0000008a !important',
    },
    '& .Mui-focused.Mui-error label': {
      color: 'green', //color: '#f44336',
      fontFamily: 'Montserrat !important',
    },
    '&> div:before': {
      borderBottomColor: theme.inputBorderBottomBefore,
    },
    '& >div:after': {
      borderBottomColor: theme.inputBorderBottomAfter,
    },
    '& >div:hover:not(.Mui-disabled):before': {
      borderBottomColor: theme.inputOnFocusBefore,
    },
    '& > div > input': {
      color: `${theme.inputTextColor} !important`,
      fontFamily: 'Montserrat !important',
      fontWeight: '500 !important',
      fontSize: '14px !important',
    },
    '& > div > input::placeholder': {
      color: `${theme.inputTextColor} !important`,
      fontFamily: 'Montserrat !important',
      fontSize: '14px !important',
    },
    '  & > div >  textarea': {
      color: `${theme.inputTextColor} !important`,
      fontFamily: 'Montserrat !important',
      fontWeight: '500 !important',
      fontSize: '12px !important',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
    '& .MuiFormHelperText-root': {
      fontFamily: 'Montserrat !important',
      color: '#8b8b8b !important',
    },
  },
  helperText: {
    fontFamily: 'Montserrat !important',
    fontSize: '12px !important',
    fontWeight: '500 !important',
  },
  popper: {
    // Add your styles for font and text size here
    fontFamily: 'Montserrat',
    fontSize: '14px',
  },
  chipSuggestion: {
    fontFamily: 'Montserrat',
  },
}));

const StyledTextField = styled(TextField)`
  & div {
    border: none !important;
  }
`;

const StyledAddIcon = styled(PlusCircleIcon)`
  color: #0000008a !important;
  width: 18px;
  height: 18px;
`;
const StyledAddText = styled.span`
  color: #0000008a !important;
  font-weight: 500;
`;

// Import statements...
const Asynchronous = (props) => {
  const { createNewContact, searchTerms, setSearchTerms, setSelectedValue, selectedValue } = props;
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const { surveyDispatch } = useContext(SurveyContext);
  const { state, dispatch } = useContext(GlobalContext);

  const classes = useStyles();

  const handleInputChange = (event) => {
    setSelectedValue(null);
    dispatch({ type: 'SET_INVITE_DATA', payload: [] });

    const { value } = event.target;

    setSearchTerms((prevSearchTerms) => ({
      ...prevSearchTerms,
      page: 0,
      count: 0,
      text: value,
    }));

    setLoading(true);

    // Use the updated text value directly
    const { perPage, page, count } = searchTerms;
    const urlParams = `location=${
      state.selectedLoaction
    }&text=${value}&count=${count}&page=${page}&offset=${perPage || 20}`;

    baseUrl
      .get(`/client/contact?${urlParams}`)
      .then((res) => {
        const formattedOptions = res.data.list.map((contact) => contact);
        setOptions(formattedOptions);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleOptionSelect = (value) => {
    surveyDispatch({
      type: 'SET_SELECTED_CONTACTS',
      payload: value,
    });
  };

  return (
    <>
      {selectedValue && (
        <Autocomplete
          id="has-option-selected"
          open={open}
          size="small"
          onOpen={() => {
            setOpen(true);
            setSearchTerms({});
          }}
          onClose={() => {
            setOpen(false);
            setSearchTerms({});
          }}
          value={selectedValue ? [selectedValue] : null}
          multiple
          PaperComponent={(props) => <Paper {...props} className={classes.popper} />}
          onInputChange={handleInputChange}
          getOptionLabel={(option) =>
            `${option.firstName} ${option.lastName} ${
              option.phoneNumber?.length ? '(' + option.phoneNumber + ')' : ''
            } ${option.emailAddress?.length ? '(' + option.emailAddress + ')' : ''}`
          }
          onChange={(event, value) => {
            if (value) {
              handleOptionSelect(value);
            }
          }}
          options={options}
          loading={loading}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const { firstName, lastName } = option;
              const label = `${firstName} ${lastName}`;
              return (
                <Chip
                  key={index}
                  size="small"
                  label={label}
                  style={{ fontFamily: 'Montserrat' }}
                  {...getTagProps({ index })}
                />
              );
            })
          }
          renderInput={(params) => (
            <StyledTextField
              {...params}
              label="Recipient(s)"
              variant="standard"
              className={classes.root}
              autoFocus
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {searchTerms?.text?.length > 0 && open && (
                      <div
                        style={{
                          display: 'flex',
                          cursor: 'pointer',
                          whiteSpace: 'nowrap',
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          columnGap: '.2rem',
                        }}
                        onClick={() => createNewContact()}
                      >
                        <StyledAddIcon /> <StyledAddText>Add New Contact</StyledAddText>
                      </div>
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}

      {!selectedValue && (
        <Autocomplete
          id="ready-for-values"
          open={open}
          size="small"
          onOpen={() => {
            setOpen(true);
            setSearchTerms({});
          }}
          onClose={() => {
            setOpen(false);
            setSearchTerms({});
          }}
          multiple
          PaperComponent={(props) => <Paper {...props} className={classes.popper} />}
          onInputChange={handleInputChange}
          getOptionLabel={(option) =>
            `${option.firstName} ${option.lastName} ${
              option.phoneNumber?.length ? '(' + option.phoneNumber + ')' : ''
            } ${option.emailAddress?.length ? '(' + option.emailAddress + ')' : ''}`
          }
          onChange={(event, value) => {
            if (value) {
              handleOptionSelect(value);
            }
          }}
          options={options}
          loading={loading}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const { firstName, lastName } = option;
              const label = `${firstName} ${lastName}`;
              return (
                <Chip
                  key={index}
                  size="small"
                  label={label}
                  style={{ fontFamily: 'Montserrat' }}
                  {...getTagProps({ index })}
                />
              );
            })
          }
          renderInput={(params) => (
            <StyledTextField
              {...params}
              label="Recipient(s)"
              variant="standard"
              className={classes.root}
              autoFocus
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {searchTerms?.text?.length > 0 && open && (
                      <div
                        style={{
                          display: 'flex',
                          cursor: 'pointer',
                          whiteSpace: 'nowrap',
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          columnGap: '.2rem',
                        }}
                        onClick={() => createNewContact()}
                      >
                        <StyledAddIcon /> <StyledAddText>Add New Contact</StyledAddText>
                      </div>
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    </>
  );
};

export default Asynchronous;
