import React, { useState } from 'react';
import Proptypes from 'prop-types';
import styled from 'styled-components';
import GetAppIcon from '@material-ui/icons/GetApp';
import FileUploader from '../../components/FileUploader/FileUploader';
import theme from '../../setup';
import CSVfile from '../../assets/files/smpleCSV.csv';

const StyledHintText = styled.div`
  letter-spacing: 1.08px;
  color: #6c6c6c;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 400 !important;
  display: flex;
  align-items: center;
`;

const StyledDownloadIcon = styled(GetAppIcon)`
  color: ${theme.btn_background_color} !important;
`;

function ImportCustomers(props) {
  const { handleGetImportCustomersFile } = props;
  const [value, setValue] = useState(null);
  const handleGetFile = (file) => {
    setValue(file[0].name);
    handleGetImportCustomersFile(file);
  };
  return (
    <div>
      <FileUploader
        handleGetFile={handleGetFile}
        value={value}
        title="Upload or select CSV file"
        accept=".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"
      />
      <StyledHintText>
        <span>Download Sample CSV file </span>
        <span>
          <a href={CSVfile} download title="Sample CSV">
            <StyledDownloadIcon />
          </a>
        </span>
      </StyledHintText>
    </div>
  );
}

ImportCustomers.propTypes = {
  handleGetImportCustomersFile: Proptypes.func.isRequired,
};

export default ImportCustomers;
