/* eslint-disable */
import { useCallback, useContext, useState } from 'react';
import { conversationsFilter } from '../utils/helper/helper';
import { GlobalContext } from '../Stores/GlobalStateStore';
import { SocketContext } from '../Stores/SocketStore';
import { io } from '../socket_context/initSocket';
import { errorChecker } from '../utils/helper/helper';

export const useFetchConversations = (conversationType, filter) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { socketDispatch } = useContext(SocketContext);
  const [isLoading, setIsLoading] = useState(true);
  const { selectedLoaction } = state;

  const fetchConversations = useCallback(
    (clientId, pageNum = 0, conversationState = 'open') => {
      //TODO: In the future when things are a little cleaner you might need to have better logic to tell if io is initialized
      if (conversationType && selectedLoaction && io) {
        setIsLoading(true);
        io.socket.get(
          `/api/list-communication?pageNum=${pageNum}&type=${conversationType}&id=${selectedLoaction}&filter=${filter}&state=${conversationState}`,
          async function serverResponded(body, JWR) {
            if (JWR.statusCode === 200) {
              const conversations = body;

              if (pageNum > 0) {
                if (body.length > 0) {
                  socketDispatch({
                    type: 'UPDATE_CONVERSATIONS_LIST',
                    payload: conversations,
                  });
                } else {
                  return;
                }
              } else {
                socketDispatch({
                  type: 'SET_CONVERSATIONS',
                  payload: conversations,
                });
              }
              setIsLoading(false);
              socketDispatch({ type: 'SET_SELECTED_CONVERSATION', payload: 0 });
              socketDispatch({ type: 'UPDATE_DOCUMENT_TITLE', payload: 0 });
              if (clientId) {
                body.forEach((item, index) => {
                  if (item.client === clientId) {
                    socketDispatch({ type: 'SET_SELECTED_CONVERSATION', payload: index });
                  }
                });
              }
            } else {
              dispatch({ type: 'SHOW_ERROR', payload: errorChecker(null) });
            }
          },
        );
      }
    },
    [conversationType, selectedLoaction, dispatch, filter],
  );

  return { fetchConversations, isLoading };
};
