import React, { useCallback, useContext, useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { Grid, FormHelperText, ThemeProvider as MUIThemeProvider } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import HelpIcon from '@material-ui/icons/Help';
import { Controller, useForm } from 'react-hook-form';
import MessageLogo from '../../../assets/images/Management@2x.png';
import CompanyLogo from '../../../components/CompanyLogo/CompanyLogo';
import CustomButton from '../../../components/CustomButton/CustomButton';
import Logo from '../../../assets/images/reveo_logo@2x.png';
import Dropdown from '../../../components/Dropdown/Dropdown';
import { OnBoardingContext } from '../../../Stores/OnBoarding';
import { errorChecker, handlePhoneError, validateURL } from '../../../utils/helper/helper';
import StyledTextField from '../../../components/GeneralInputField/GeneralInputField';
import defaultDropDownTheme from '../../../themes/Dropdown/DefaultDropdownTheme';
import OnBoardingButtonTheme from '../../../themes/Button/OnBoardingButtonTheme';
import OnBoardingHeaderLogoTheme from '../../../themes/Logo/OnBoardingHeaderLogoTheme';
import baseUrl from '../../../utils/services/config';
import { GlobalContext } from '../../../Stores/GlobalStateStore';
import StyledFormControl from '../../../components/StyledFormControl';
import useIconTheme from '../../../hooks/useIconTheme';
import StyledBeginText from '../../../components/BeginText/BeginText';
import useInputFieldTheme from '../../../hooks/useInputFieldTheme';
import useTextColors from '../../../hooks/useTextColors';
import StyledImpText from '../../../components/ImpText/ImpText';
import StyledDescriptionText from '../../../components/DescriptionText';
import StyledIosSwitch from '../../../components/StyledIosSwitch';
import useAntSwitchTheme from '../../../hooks/useAntSwitchTheme';

const StyledGridContaienr = styled(Grid)`
  width: 100% !important;
  min-height: 480px !important;
  height: 100% !important;
`;

const StyledContentContainer = styled(Grid)`
  height: 100% !important;
`;

const StyledImpTextContainer = styled(Grid)`
  margin: 1em 0 0 0 !important;
`;

const StyledFooter = styled(Grid)`
  align-self: flex-end;
`;

const StyledFowArrow = styled(ArrowForwardIcon)`
  color: #fff !important;
  font-size: 3em !important;
`;

const StyledHelpIcon = styled(HelpIcon)`
  color: #c1c1c1 !important;
  font-size: 2em !important;
`;

const StyledForm = styled.form`
  height: calc(100% - 45px) !important;
`;

const rocketLogoTheme = {
  width: '164px',
  height: '187px',
  largeScreenWidth: '264px',
  largeScreenHeight: '287px',
  padding: '0 1em',
};

function Step4(props) {
  const { handleNext } = props;
  const [localBusinessListing, setLocalBusinessListing] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const { onBoardingData } = useContext(OnBoardingContext);
  const { dispatch, state } = useContext(GlobalContext);
  const { businessInfo } = state;
  const { industries, seoCategories } = onBoardingData;
  const { register, control, errors, handleSubmit } = useForm();
  const { iconsTheme } = useIconTheme();
  const { inputTheme } = useInputFieldTheme();
  const { textColors } = useTextColors();
  const { antSwitchTheme } = useAntSwitchTheme();
  const userId = localStorage.getItem('USER_ID');
  const fetchBusiness = useCallback(async () => {
    if (userId) {
      try {
        const result = await baseUrl.get(`/user/${userId}/business`);
        if (result.status === 200) {
          dispatch({ type: 'SET_BUSINESS_CONTAINER', payload: Array.from(result.data) });
        }
      } catch (err) {
        setDisableBtn(false);
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  }, [userId, dispatch]);

  useEffect(() => {
    if (businessInfo && Object.keys(businessInfo).length) {
      handleNext();
      setDisableBtn(false);
    }
  }, [businessInfo, handleNext]);
  const onSubmit = async (data) => {
    setDisableBtn(true);
    try {
      let businessDetails = { ...onBoardingData.businessDetails };
      businessDetails = {
        ...businessDetails,
        contact: {
          ...businessDetails.contact,
          businessContact:
            businessDetails.contact.businessContact &&
            businessDetails.contact.businessContact !== undefined
              ? `+1${businessDetails.contact.businessContact}`
              : '',
          phoneNumber: `+1${businessDetails.contact.phoneNumber}`,
          webSite: validateURL(businessDetails.contact.webSite),
        },
      };
      const postBody = {
        ...businessDetails,
        seoCategory: [data.seoCategory],
        industry: [data.industry],
        noEmployees: parseInt(data.noEmployees, 10),
        noLocations: parseInt(data.noLocations, 10),
        user: [parseInt(localStorage.getItem('USER_ID'), 10), 1],
        createdBy: parseInt(localStorage.getItem('USER_ID'), 10),
        settings: { localBusinessListing },
      };
      const result = await baseUrl.post('/business', { ...postBody });
      if (result.status === 200) {
        fetchBusiness();
      }
    } catch (err) {
      setDisableBtn(false);
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const handleLocalBusinessListing = (e) => setLocalBusinessListing(e.target.value);

  return (
    <StyledGridContaienr>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <ThemeProvider theme={{ color: iconsTheme.helpIcon }}>
            <StyledHelpIcon />
          </ThemeProvider>
        </Grid>
        <Grid item>
          <Grid container justify="flex-end">
            <ThemeProvider theme={OnBoardingHeaderLogoTheme}>
              <CompanyLogo alt="Reveo" logo={Logo} />
            </ThemeProvider>
          </Grid>
        </Grid>
      </Grid>
      <MUIThemeProvider theme={inputTheme}>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <StyledContentContainer container justify="center" alignItems="center">
            <StyledFooter item xs={3} sm={3} lg={3} md={3}>
              <ThemeProvider theme={rocketLogoTheme}>
                <CompanyLogo alt="Reveo" logo={MessageLogo} />
              </ThemeProvider>
            </StyledFooter>
            <Grid item xs={6} sm={6} lg={6} md={6}>
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <Grid container justify="center">
                  <Grid item xs={12} md={12} lg={12} sm={12}>
                    <Grid container justify="center">
                      <ThemeProvider theme={{ color: textColors.headingTextColor }}>
                        <StyledBeginText item xs={12} md={12} lg={12} sm={12}>
                          Tell us a little about your business
                        </StyledBeginText>
                      </ThemeProvider>
                      <Grid item xs={12} md={12} lg={12} sm={12}>
                        <ThemeProvider theme={defaultDropDownTheme}>
                          <StyledFormControl error={!!errors.industry}>
                            <Controller
                              as={
                                <Dropdown
                                  listOptions={Array.from(industries)}
                                  label="Business Industry *"
                                />
                              }
                              name="industry"
                              control={control}
                              rules={{ required: true }}
                              defaultValue=""
                            />
                            {errors.industry ? (
                              <FormHelperText>SEO Category is a required field</FormHelperText>
                            ) : null}
                          </StyledFormControl>
                        </ThemeProvider>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12} sm={12}>
                        <ThemeProvider theme={defaultDropDownTheme}>
                          <StyledFormControl error={!!errors.seoCategory}>
                            <Controller
                              as={
                                <Dropdown
                                  listOptions={Array.from(seoCategories)}
                                  label="SEO Category *"
                                />
                              }
                              name="seoCategory"
                              control={control}
                              rules={{ required: true }}
                              defaultValue=""
                            />
                            {errors.seoCategory ? (
                              <FormHelperText>SEO Category is a required field</FormHelperText>
                            ) : null}
                          </StyledFormControl>
                        </ThemeProvider>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12} sm={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6} lg={6} sm={12}>
                            <StyledTextField
                              name="noEmployees"
                              type="number"
                              label="Number of employees"
                              inputRef={register({ min: 0 })}
                              error={errors.noEmployees}
                              helperText={
                                errors.noEmployees
                                  ? handlePhoneError(errors.noEmployees, 'Number of Employees', '0')
                                  : ''
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={6} sm={12}>
                            <StyledTextField
                              name="noLocations"
                              type="number"
                              label="Number of locations"
                              inputRef={register({ min: 0 })}
                              error={errors.noEmployees}
                              helperText={
                                errors.noEmployees
                                  ? handlePhoneError(errors.noLocations, 'Number of locations', '0')
                                  : ''
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12} sm={12}>
                        <StyledImpTextContainer container spacing={2} alignItems="center">
                          <ThemeProvider theme={{ color: textColors.impTextColor }}>
                            <StyledImpText item xs={12} md={10} lg={10} sm={12}>
                              Enable Accurate Local Business Listings
                            </StyledImpText>
                          </ThemeProvider>
                          <Grid item xs={12} md={2} lg={2} sm={12}>
                            <MUIThemeProvider theme={antSwitchTheme}>
                              <StyledIosSwitch
                                id="localBusinessListing"
                                value={localBusinessListing}
                                onChange={handleLocalBusinessListing}
                              />
                            </MUIThemeProvider>
                          </Grid>
                        </StyledImpTextContainer>
                        <Grid container spacing={2}>
                          <ThemeProvider
                            theme={{ color: textColors.descriptionText2, padding: '1em 1.5em' }}
                          >
                            <StyledDescriptionText item xs={12} md={11} lg={11} sm={12}>
                              Allow Reveo to automatically manage 50+ online listings including
                              Google, Bing, Yahoo, and Facebook, to increase your online search
                              engine ranking.
                            </StyledDescriptionText>
                          </ThemeProvider>
                          <Grid item xs={12} md={1} lg={1} sm={12} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <StyledFooter item xs={2} sm={2} lg={2} md={2}>
              <Grid container justify="flex-end">
                <Grid item>
                  <ThemeProvider theme={OnBoardingButtonTheme}>
                    <CustomButton type="submit" disabled={disableBtn}>
                      <StyledFowArrow />
                    </CustomButton>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </StyledFooter>
          </StyledContentContainer>
        </StyledForm>
      </MUIThemeProvider>
    </StyledGridContaienr>
  );
}

Step4.propTypes = {
  handleNext: Proptypes.func.isRequired,
};

export default Step4;
