import React, { useState, useRef, useEffect } from 'react';
import { makeStyles, Grid, Avatar } from '@material-ui/core';
import {
  capitalizeFirstLetter,
  getRatingFromNameValue,
  getTimeAgoString,
  decrease_brightness,
} from '../../utils/helper/helper';
import CustomRating from '../../components/CusotmRating/CusotmRating';

function SingleReview(props) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { item, index, linkColor, reviewLink } = props;
  const review = item;
  const hasProfilePic = true; // Set this to true or false based on your logic

  const textDivRef = useRef(null);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const [numberOfLines, setNumberOfLines] = useState(0);

  useEffect(() => {
    const textDiv = textDivRef.current;
    if (textDiv) {
      const computedStyle = getComputedStyle(textDiv);
      const lineHeight = parseFloat(computedStyle.lineHeight);
      const divHeight = textDiv.clientHeight;

      const calculatedNumberOfLines = Math.round(divHeight / lineHeight);
      setNumberOfLines(calculatedNumberOfLines);
    }
  }, []);

  const useStyles = makeStyles(() => ({
    reviewBy: {
      fontSize: '14px',
      fontFamily: 'Montserrat',
      fontWeight: '600',
      maxWidth: '30ch',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',

      '@media screen and (max-width:600px)': { maxWidth: '12ch' },
      '@media screen and (max-width:430px)': { maxWidth: '6ch' },
    },
    textContect: {
      textAlign: 'left',
      fontSize: '12px',
      display: 'flex',
      marginTop: '30px',
    },
    avatar: {
      alignSelf: 'center',
      marginRight: '10px',
      '@media screen and (max-width:430px)': { display: 'none' },
    },
    reviewDate: {
      color: '#9c9c9c',
      fontWeight: 'normal',
    },
    collapsed: {
      maxHeight: '96px',
    },

    paragraph: {
      fontSize: '15px',
      fontFamily: 'Montserrat !important',
      position: 'relative',
      fontWeight: 'normal',
    },

    reviewSource: {
      color: linkColor,
      fontWeight: 'normal',
      marginLeft: '.3rem',
      cursor: 'pointer',
      '&:hover': {
        color: decrease_brightness(linkColor || '#1976d2', 40),
      },
    },

    Review__Outer: {
      overflow: 'hidden',
      position: 'relative',
    },
    ReadMore__Control: {
      cursor: 'pointer',
      fontSize: '14px',
      textDecoration: 'none',
      color: linkColor,
      marginTop: '.5rem',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    Review__Inner: {
      marginTop: '.5rem',
      width: '100%',
      lineHeight: '1.4',
      fontSize: '13px',
      color: '#000',
      fontFamily: 'Montserrat',
    },
    Review__Title: {
      fontWeight: '600',
      fontSize: '15px',
    },
    review_reply: {
      padding: '1rem',
      backgroundColor: '#eeeeee',
      borderRadius: '10px',
      fontSize: '15px',
      marginTop: '.5rem',
      fontWeight: 'normal',
    },
    reply_header: {
      fontWeight: '500',
      marginBottom: '.25rem',
    },
  }));

  const classes = useStyles();

  return (
    <div key={index} style={{ width: '100%' }}>
      <div className={classes.textContect}>
        {hasProfilePic ? <Avatar className={classes.avatar} src={item.profilePic} /> : null}
        <Grid style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <Grid
            item
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'baseline',
            }}
          >
            <Grid item style={{ display: 'flex', alignItems: 'baseline' }}>
              <div className={classes.reviewBy}>{review.reviewBy}</div>
              <a href={reviewLink} target="_blank" className={classes.reviewSource}>
                {`on
              ${capitalizeFirstLetter(review.source)}`}
              </a>
            </Grid>
            <span className={classes.reviewDate}>
              {getTimeAgoString(new Date(review.reviewOn))}
            </span>
          </Grid>
          <Grid style={{ marginTop: '.2rem' }}>
            <CustomRating ratingValue={getRatingFromNameValue(review.rating)} />
          </Grid>
        </Grid>
      </div>
      <div className={`${classes.Review__Outer} ${isCollapsed ? classes.collapsed : null}`}>
        <div className={classes.Review__Inner}>
          <div ref={textDivRef} className={classes.paragraph}>
            {review.reviewText}
          </div>
        </div>
      </div>
      {numberOfLines > 4 ? (
        <div className={classes.ReadMore__Control} onClick={toggleCollapse}>
          {isCollapsed ? 'read more' : 'hide'}
        </div>
      ) : null}
      {review.reviewReply && !review.reviewReply.comment ? null : (
        <div className={classes.review_reply}>
          <div className={classes.reply_header}>Response from the owner:</div>
          {review.reviewReply.comment}
        </div>
      )}
    </div>
  );
}

export default SingleReview;
