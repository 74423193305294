import globalState from '../state/GlobalState';
import { getUniqueArray } from '../utils/helper/helper';

const GlobalReducer = (state, action) => {
  switch (action.type) {
    case 'INIT_GLOBAL_STATE':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_LOCATION':
      return {
        ...state,
        selectedLoaction: action.payload,
      };
    case 'SET_BUSINESS':
      return {
        ...state,
        selectedBusiness: action.payload,
      };
    case 'SET_LOCATIONS_CONTAINER':
      return {
        ...state,
        locations: action.payload,
      };
    case 'SET_BUSINESS_CONTAINER':
      return {
        ...state,
        businesses: action.payload,
      };
    case 'SET_TOKEN':
      return {
        ...state,
        token: action.payload,
      };
    case 'SET_ROW_ID':
      return {
        ...state,
        selectedRow: action.payload,
      };
    case 'SET_EDIT_LOCATION':
      return {
        ...state,
        selectedEditLocation: action.payload,
      };
    case 'SET_PROFILE_PIC':
      return {
        ...state,
        profilePic: action.payload,
      };
    case 'SHOW_ERROR':
      return {
        ...state,
        showError: true,
        errorMessage: action.payload,
      };
    case 'HIDE_ERROR':
      return {
        ...state,
        showError: false,
        errorMessage: '',
      };
    case 'SET_ALERT_MESSAGE_TYPE': {
      return {
        ...state,
        alertMessageType: action.payload,
      };
    }
    case 'IS_EXPIERED':
      return {
        ...state,
        isExpired: action.payload,
      };

    case 'SET_USER_INFO':
      return {
        ...state,
        userInfo: { ...action.payload },
      };

    case 'SET_GOOGLE_ACCOUNT_ID':
      return {
        ...state,
        googleAccountId: action.payload,
      };
    case 'SET_FACEBOOK_ACCOUNT_ID':
      return {
        ...state,
        facebookAccountId: action.payload,
      };
    case 'SET_BUSINESS_INFO':
      return {
        ...state,
        businessInfo: action.payload,
      };
    case 'SET_LOCATION_DETAILS':
      return {
        ...state,
        locationInfo: action.payload,
      };

    case 'SET_CAMPAIGN_LOCATION':
      return {
        ...state,
        campaignLocation: action.payload,
      };

    case 'SET_NOTIFICATION_CONTAINER':
      return {
        ...state,
        notificationContainer: [...action.payload],
      };
    case 'UPDATE_NOTIFICATION_CONTAINER':
      return {
        ...state,
        notificationContainer: [
          ...state.notificationContainer,
          state.notificationContainer.find(
            (i) =>
              i.type === 'conversations' &&
              i.id === action.payload.id &&
              i.client === action.payload.client,
          )
            ? []
            : action.payload,
        ],
      };

    case 'FILTER_NOTIFICATION_CONTAINER':
      return {
        ...state,
        notificationContainer: state.notificationContainer.filter((notification) => {
          return notification.type !== action.payload.type;
        }),
      };

    case 'SET_NOTIFICATION_LOADER':
      return {
        ...state,
        notificationLoader: action.payload,
      };

    case 'SHOW_GROUP_LOADER':
      return {
        ...state,
        groupContainer: {
          ...state.groupContainer,
          loader: true,
        },
      };

    case 'HIDE_GROUP_LOADER':
      return {
        ...state,
        groupContainer: {
          ...state.groupContainer,
          loader: false,
        },
      };

    case 'INIT_GROUP_CONTAINER':
      return {
        ...state,
        groupContainer: globalState.groupContainer,
      };

    case 'SET_GROUP_CONTAINER':
      return {
        ...state,
        groupContainer: {
          ...state.groupContainer,
          loader: false,
          data: action.payload,
        },
      };

    case 'SET_SELECTED_CONTACTS':
      return {
        ...state,
        groupContainer: {
          ...state.groupContainer,
          selectedContact: action.payload,
        },
      };

    case 'SET_LIST_VIEW_SELECTED_CONTACTS':
      return {
        ...state,
        groupContainer: {
          ...state.groupContainer,
          selectedListViewContact: action.payload,
        },
      };

    case 'SET_GROUP_LIST_VIEW_CONTAINER':
      return {
        ...state,
        groupContainer: {
          ...state.groupContainer,
          loader: false,
          listViewData: getUniqueArray(state.groupContainer.listViewData.concat(action.payload)),
        },
      };

    case 'SET_GROUP_LIST_VIEW_SELECTED_CONTACTS':
      return {
        ...state,
        groupContainer: {
          ...state.groupContainer,
          listViewSelectedContact: action.payload,
        },
      };

    case 'SET_GROUP_LOCATION':
      return {
        ...state,
        groupContainer: {
          ...state.groupContainer,
          selectedGroupLocation: action.payload,
        },
      };

    case 'SET_GROUP_NAME_CONTAINER':
      return {
        ...state,
        groupContainer: {
          ...state.groupContainer,
          groupNameContainer: action.payload,
        },
      };

    case 'SET_SELECTED_GROUP_NAME':
      return {
        ...state,
        groupContainer: {
          ...state.groupContainer,
          selectedGroupName: action.payload,
        },
      };

    case 'SET_EXISTING_GROUP_CONTAINER':
      return {
        ...state,
        groupContainer: {
          ...state.groupContainer,
          existingClientContainer: action.payload,
        },
      };

    case 'SET_FINAL_GROUP_CONTAINER':
      return {
        ...state,
        groupContainer: {
          ...state.groupContainer,
          finalGroupData: action.payload,
        },
      };

    case 'SET_SELECTED_GROUP_ID':
      return {
        ...state,
        selectedGroupId: action.payload,
      };

    // Action for send invite list container

    case 'SET_SEND_INVITE_LIST_CONTAINER':
      return {
        ...state,
        sendInviteLoader: false,
        sendInviteList: { ...action.payload },
      };

    case 'SHOW_SEND_INVITE_LOADER':
      return {
        ...state,
        sendInviteLoader: true,
      };

    case 'HIDE_SEND_INVITE_LOADER':
      return {
        ...state,
        sendInviteLoader: false,
      };

    // Action for selected group info
    case 'SET_SELECTED_GROUP_INFO':
      return {
        ...state,
        selectedGroupInfo: { ...action.payload },
      };

    // Action for selected contact ids

    case 'SET_SELECTED_CONTACT_ID':
      return {
        ...state,
        selectedContactForCampaign: Array.from(action.payload),
      };

    // Action for new group saved or not action

    case 'SET_IS_GROUP_SAVED':
      return {
        ...state,
        groupContainer: {
          ...state.groupContainer,
          isSaved: action.payload,
        },
      };

    // Role handling
    case 'SET_ROLES':
      return {
        ...state,
        roles: { ...action.payload },
      };

    // Routes handling:
    case 'SET_ROUTES':
      return {
        ...state,
        routes: { ...action.payload },
      };

    // Theme handling:
    case 'SET_THEME':
      return {
        ...state,
        themeContainer: { ...action.payload },
      };

    // Thank you popup BR toggle
    case 'SET_BORDER_RADIUS_THANK_YOU_POPUP':
      return {
        ...state,
        thankyouPopUpBR: action.payload,
      };

    // Thank you popup BR toggle
    case 'SET_INVITE_DATA':
      return {
        ...state,
        inviteData: action.payload,
      };

    default:
      return state;
  }
};

export default GlobalReducer;
