import React from 'react';
import RadioboxMarkedIcon from 'mdi-react/RadioboxMarkedIcon';
import CheckboxOutlineIcon from 'mdi-react/CheckboxOutlineIcon';
import TextIcon from 'mdi-react/TextIcon';
import StarOutlineIcon from 'mdi-react/StarOutlineIcon';
import EmoticonHappyIcon from 'mdi-react/EmoticonHappyIcon';
import ThumbsUpDownIcon from 'mdi-react/ThumbsUpDownIcon';
import NumericIcon from 'mdi-react/NumericIcon';
import styled from 'styled-components';

const StyledTitle = styled.a`
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  margin-top: 10px;
`;
const StyledDescription = styled.a`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  color: #808080;
  margin-top: 10px;
  width: 401px;
`;

const EmptyScreen = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          padding: 10,
          borderStyle: 'dashed',
          borderRadius: 99,
          borderColor: '#808080',
          marginTop: 10,
        }}
      >
        <RadioboxMarkedIcon style={{ marginLeft: 10 }} size={30} color="#808080" />
        <CheckboxOutlineIcon style={{ marginLeft: 10 }} size={30} color="#808080" />
        <TextIcon size={30} style={{ marginLeft: 10 }} color="#808080" />
        <StarOutlineIcon size={30} style={{ marginLeft: 10 }} color="#808080" />
        <EmoticonHappyIcon size={30} style={{ marginLeft: 10 }} color="#808080" />
        <ThumbsUpDownIcon size={30} style={{ marginLeft: 10 }} color="#808080" />
        <NumericIcon size={30} style={{ marginLeft: 10, marginRight: 10 }} color="#808080" />
      </div>

      <StyledTitle>Add your first question</StyledTitle>
      <StyledDescription>
        Get started by creating questions for your survey, with custom flows for answers, and
        triggers based on answer type, the possibilities are endless!
      </StyledDescription>
    </div>
  );
};

export default EmptyScreen;
