import { Grid } from '@material-ui/core';
import React from 'react';
import HybridWidget from '../../layout/Business Review/HybridWidget';

function HybridWidgetContainer() {
  return (
    <Grid container style={{ height: '100%', overflow: 'auto' }}>
      <HybridWidget />
    </Grid>
  );
}
export default HybridWidgetContainer;
