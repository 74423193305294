import styled from 'styled-components';

const NoDataFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: ${(props) => (props.theme.position ? props.theme.position : 'relative')};
  height: ${(props) => (props.theme.height ? props.theme.height : '100%')};
  width: ${(props) => (props.theme.width ? props.theme.width : '100%')};
  color: ${(props) => (props.theme.color ? props.theme.color : '#999')};
  background: transparent;
  font-size: ${(props) => (props.theme.fontSize ? props.theme.fontSize : '24px')};
  font-weight: ${(props) => (props.theme.fontWeight ? props.theme.fontWeight : '400')};
  font-familty: ${(props) => (props.theme.fontFamily ? props.theme.fontFamily : 'Montserrat')};
  text-align: center;
`;

export default NoDataFound;
