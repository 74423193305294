import React, { useState, useEffect, useCallback } from 'react';
import TabPanel from '../../components/TabPanel/TabPanel';
import { Grid, FormHelperText, Box } from '@material-ui/core';
import StyledTextField from '../../components/GeneralInputField/GeneralInputField';
import styled, { ThemeProvider } from 'styled-components';
import defaultDropDownTheme from '../../themes/Dropdown/DefaultDropdownTheme';
import StyledFormControl from '../../components/StyledFormControl';
import Dropdown from '../../components/Dropdown/Dropdown';
import baseUrl from '../../utils/services/config';
import {
  convertIntoGoogleLocationDropDown,
  convertedIntoFacebookPagesDropDown,
} from '../../utils/helper/helper';
import Facebook from '@material-ui/icons/Facebook';
import Twitter from '@material-ui/icons/Twitter';
import Instagram from '@material-ui/icons/Instagram';
import LinkedIn from '@material-ui/icons/LinkedIn';
import YouTube from '@material-ui/icons/YouTube';
import { Refresh } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import useTextColors from '../../hooks/useTextColors';

export const SocialConnections = (props) => {
  const {
    tabValue,
    formik,
    googleAccountId,
    facebookAccountId,
    selectedBusiness,
    dispatch,
    errorChecker,
    industries,
    seoCategories,
  } = props;
  const { textColors } = useTextColors();
  const [googleLocationsContainer, setGoogleLocationsContainer] = useState([]);
  const [facebookPagesContainer, setFacebookPagesContainer] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const StyledFormHelperText = styled(FormHelperText)`
    font-family: 'Montserrat' !important;
    color: #f44336 !important;
  `;
  useEffect(() => {
    fetchGoogleLocations();
    fetchFacebookPages();
  }, []);

  const fetchGoogleLocations = useCallback(async () => {
    if (googleAccountId) {
      setIsLoading(true);
      try {
        const result = await baseUrl.get(
          `/location/get-google-locations?accountId=${googleAccountId}&businessId=${selectedBusiness}`,
        );
        if (result.status === 200) {
          setGoogleLocationsContainer(Array.from(convertIntoGoogleLocationDropDown(result.data)));
          setIsLoading(false);
        }
      } catch (err) {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  }, [googleAccountId, dispatch]);

  const fetchFacebookPages = useCallback(async () => {
    if (facebookAccountId) {
      try {
        const result = await baseUrl.get(`/facebookPages?userId=${facebookAccountId}`);
        if (result.status === 200) {
          setFacebookPagesContainer(Array.from(convertedIntoFacebookPagesDropDown(result.data)));
        }
      } catch (err) {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  }, [facebookAccountId, dispatch]);

  return (
    <TabPanel value={tabValue} index={2}>
      {/* {isLoading ? (
        <CircularProgress />
      ) : ( */}
      <Grid container spacing={2}>
        <ThemeProvider theme={defaultDropDownTheme}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box display="flex" alignItems={'flex-end'}>
              {isLoading ? (
                <Box marginTop={'15px'} width={'100%'}>
                  <Skeleton variant="rect" width="100%" height={50} />
                </Box>
              ) : (
                <StyledFormControl>
                  <Dropdown
                    name="googleLocation"
                    listOptions={Array.from(googleLocationsContainer)}
                    label="Select Google Location"
                    value={formik.values.googleLocation}
                    onChange={formik.handleChange}
                    error={formik.touched.googleLocation && Boolean(formik.errors.googleLocation)}
                    helperText={formik.touched.googleLocation && formik.errors.googleLocation}
                  />
                </StyledFormControl>
              )}
              <Refresh
                style={{ cursor: 'pointer', color: '#989c9b' }}
                onClick={() => fetchGoogleLocations()}
                fontSize="small"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <StyledFormControl>
              <Dropdown
                name="facebookPages"
                listOptions={Array.from(facebookPagesContainer)}
                label="Facebook Page"
                value={formik.values.facebookPages}
                onChange={formik.handleChange}
              />
            </StyledFormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <StyledFormControl>
              <Dropdown
                name="industry"
                listOptions={Array.from(industries)}
                label="Google Business Category *"
                value={formik.values.industry}
                onChange={formik.handleChange}
                error={formik.touched.industry && Boolean(formik.errors.industry)}
                helperText={formik.touched.industry && formik.errors.industry}
              />
              {formik.errors.industry ? (
                <StyledFormHelperText>{formik.errors.industry}</StyledFormHelperText>
              ) : null}
            </StyledFormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <StyledFormControl>
              <Dropdown
                name="seoCategory"
                listOptions={Array.from(seoCategories)}
                label="Select SEO Category *"
                value={formik.values.seoCategory}
                onChange={formik.handleChange}
                error={formik.touched.seoCategory && Boolean(formik.errors.seoCategory)}
                helperText={formik.touched.seoCategory && formik.errors.seoCategory}
              />
              {formik.errors.seoCategory ? (
                <StyledFormHelperText>{formik.errors.seoCategory}</StyledFormHelperText>
              ) : null}
            </StyledFormControl>
          </Grid>
        </ThemeProvider>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <StyledTextField
            name="contact.facebook"
            type="text"
            value={formik.values.contact.facebook}
            onChange={formik.handleChange}
            error={formik.touched.contact?.facebook && Boolean(formik.errors.contact?.facebook)}
            helperText={formik.touched.contact?.facebook && formik.errors.contact?.facebook}
            InputProps={{
              startAdornment: <Facebook fontSize="small" />,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <StyledTextField
            name="contact.twitter"
            type="text"
            value={formik.values.contact.twitter}
            onChange={formik.handleChange}
            error={formik.touched.contact?.twitter && Boolean(formik.errors.contact?.twitter)}
            helperText={formik.touched.contact?.twitter && formik.errors.contact?.twitter}
            InputProps={{
              startAdornment: <Twitter fontSize="small" />,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <StyledTextField
            name="contact.instagram"
            type="text"
            value={formik.values.contact.instagram}
            onChange={formik.handleChange}
            error={formik.touched.contact?.instagram && Boolean(formik.errors.contact?.instagram)}
            helperText={formik.touched.contact?.instagram && formik.errors.contact?.instagram}
            InputProps={{
              startAdornment: <Instagram fontSize="small" />,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <StyledTextField
            name="contact.linkedIn"
            type="text"
            value={formik.values.contact.linkedIn}
            onChange={formik.handleChange}
            error={formik.touched.contact?.linkedIn && Boolean(formik.errors.contact?.linkedIn)}
            helperText={formik.touched.contact?.linkedIn && formik.errors.contact?.linkedIn}
            InputProps={{
              startAdornment: <LinkedIn fontSize="small" />,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <StyledTextField
            name="contact.youTube"
            type="text"
            value={formik.values.contact.youTube}
            onChange={formik.handleChange}
            error={formik.touched.contact?.youTube && Boolean(formik.errors.contact?.youTube)}
            helperText={formik.touched.contact?.youTube && formik.errors.contact?.youTube}
            InputProps={{
              startAdornment: <YouTube fontSize="small" />,
            }}
          />
        </Grid>
      </Grid>
      {/* )} */}
    </TabPanel>
  );
};
