/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Card, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { getParaByName, imageURLCheck, isEmptyObject } from '../../utils/helper/helper';
import baseUrl from '../../utils/services/config';
import BusinessInformation from './BusinessInformation';
import AboutCard from './AboutCard';
import logo from '../../assets/images/Reveo_Logo_Gray.svg';
import SmallReviewSummary from './SmallReviewSummary';
import HybridWidget from './HybridWidget';
import LocationDetails from './LocationDetails';
import FollowUs from './FollowUs';
import ReveoButton from '../../components/Buttons/ReveoButton';
import MicrositeFooter from './MicrositeFooter';
import DirectionsIcon from 'mdi-react/DirectionsForkIcon';
import ReviewMenu from './ReviewMenu';
import InformationIcon from 'mdi-react/StarCircleIcon';
import { OpenOrClosed } from './OpenOrClosed';
import { Skeleton } from '@material-ui/lab';

const StyledCard = styled(Card)`
  border-radius: 10px;
  margin-top: 1rem;
  padding: 1.5rem;
`;

function BusinessView() {
  const [businessInfo, setBusinessInfo] = useState({});
  const [googleDetails, setGoogleDetails] = useState({});
  const [businessDetails, setBusinessDetails] = useState({});
  const [linkColor, setLinkColor] = useState();
  const [reviews, setReviews] = useState({
    reviews: [],
    topReviewSource: [],
    reviewSummary: [
      {
        averageRating: '0',
        reviewSrc: 'main',
        starRating: {
          FIVE: 0,
          FOUR: 0,
          THREE: 0,
          TWO: 0,
          ONE: 0,
        },
        totalReviewCount: 0,
        postReviewLink: '',
      },
    ],
  });

  const businessId = getParaByName('id');

  const fetchBusinessInfo = useCallback(async () => {
    const result = await baseUrl.get(`/business-reviews?id=${businessId}`);
    if (result.status === 200) {
      setBusinessInfo(result.data);
      setGoogleDetails(result.data.googleDetails);
      setBusinessDetails(result.data.businessDetails);

      if (result.data.microsite === null) {
        setLinkColor('#1976d2');
      } else {
        setLinkColor(result.data.microsite.linkColor);
      }

      if (
        result.data &&
        result.data.microsite !== null &&
        result.data.microsite.allowWebchat === true
      ) {
        const script = document.createElement('script');
        script.id = 'reveo-chat-bot';
        script.dataset.uuid = result.data.uuid;
        script.src = 'https://reveo.com/js/reveo-chatbot.js';
        script.defer = true;
        document.body.appendChild(script);
      }
    }
  }, []);

  const getDirections = () => {
    window.open(
      `https://www.google.com/maps/dir//${encodeURIComponent(
        (googleDetails && googleDetails.formatted_address) || businessInfo.address,
      )}`,
      '_blank',
    );
  };

  const fetchReviews = useCallback(async () => {
    const result = await baseUrl.get(`/micro-site-hybrid-widgets?id=${businessId}`);
    if (result.status === 200) {
      setReviews(result.data.list);
    }
  }, []);
  const useStyles = makeStyles(() => ({
    headerStyle: {
      height: '330px',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '@media screen and (max-width:500px)': { height: '150px' },
    },
    buttonsDiv: {
      margin: '1.5rem 0 3rem 0',
      columnGap: '.5rem',
      display: 'flex',

      '@media screen and (max-width:500px)': { flexDirection: 'column', rowGap: '1rem' },
    },
    noReviews: {
      color: '#d2d2d2',
      marginTop: '2rem',
      height: '250px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      '& svg': {
        height: '48px',
        marginBottom: '1rem',
        color: '#e7e6e6',
      },
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    fetchBusinessInfo();
    fetchReviews();
  }, [fetchBusinessInfo, fetchReviews]);

  const backgroundStyle = imageURLCheck(businessInfo.businessImage)
    ? { backgroundImage: `url(${imageURLCheck(businessInfo.businessImage)})` }
    : { backgroundColor: '#f3f3f3' };

  return (
    <Grid
      container
      style={{
        justifyContent: 'center',
        overflow: 'scroll',
        height: '100vh',
      }}
    >
      <Grid
        container
        style={{ backgroundColor: 'black', padding: '1rem 2rem', justifyContent: 'center' }}
      >
        <Grid
          item
          style={{
            maxWidth: '1200px',
            justifyContent: 'flex-start',
            display: 'flex',
            width: '100%',
          }}
        >
          <img src={logo} alt={logo} style={{ height: '25px' }} />
        </Grid>
      </Grid>
      <Grid
        id="header"
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.headerStyle}
        style={{
          ...backgroundStyle,
        }}
      >
        {isEmptyObject(businessInfo) ? (
          <img src={logo} alt={logo} style={{ height: '60px' }} />
        ) : null}
      </Grid>
      <Grid
        container
        style={{
          background: '#fff',
          maxWidth: '1200px',
          margin: '2rem 0 3rem 0',
        }}
      >
        <Grid item xs={12} sm={12} md={7} lg={7} style={{ padding: '20px' }}>
          <Grid item>
            <div>
              <Typography
                variant="h4"
                style={{
                  color: '#000',
                  fontWeight: '600',
                  fontFamily: 'Montserrat',
                }}
              >
                {isEmptyObject(businessInfo) ? <Skeleton width={'250px'} /> : businessInfo.name}
              </Typography>
            </div>
            {/* )} */}
            {reviews && reviews.reviews.length ? (
              <SmallReviewSummary reviewSummary={reviews.reviewSummary} />
            ) : null}

            {isEmptyObject(businessInfo) ? (
              <Skeleton width={'200px'} height={'24px'} style={{ marginTop: '1rem' }} />
            ) : (
              <OpenOrClosed
                businessInfo={businessInfo}
                googleDetails={googleDetails}
                businessDetails={businessDetails}
              />
            )}

            <div className={classes.buttonsDiv}>
              {reviews && reviews.reviews.length ? (
                <ReviewMenu reviews={reviews.reviewSummary.postReviewLink} />
              ) : null}
              <ReveoButton
                title="Get directions"
                variant="outlined"
                onClick={getDirections}
                icon={<DirectionsIcon style={{ height: '16px', marginRight: '.2rem' }} />}
              />
            </div>
          </Grid>

          <AboutCard {...businessInfo} />
          <Grid container justifyContent="center">
            {reviews && reviews.reviews.length ? (
              <HybridWidget reviewsData={reviews} linkColor={linkColor && linkColor} />
            ) : reviews.reviews.length === 0 ? (
              <div className={classes.noReviews}>
                <InformationIcon style={{ width: '48px' }} />
                This location does not have any reviews yet
              </div>
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} style={{ padding: '20px' }}>
          <img
            style={{ height: '100px', width: 'auto', marginBottom: '1rem' }}
            src={imageURLCheck(businessInfo?.businessLogo)}
          />
          <div style={{ position: 'sticky', top: '20px' }}>
            <StyledCard variant="outlined">
              <BusinessInformation
                {...businessInfo}
                googleDetails={googleDetails && googleDetails}
                linkColor={linkColor && linkColor}
              />
            </StyledCard>
            {businessInfo &&
            businessInfo.socialLinks &&
            businessInfo.socialLinks.twitter === '' &&
            businessInfo.socialLinks.instagram === '' &&
            businessInfo.socialLinks.linkdin === '' &&
            businessInfo.socialLinks.facebook === '' &&
            businessInfo.socialLinks.youtube === '' ? null : (
              <StyledCard variant="outlined">
                <FollowUs {...businessInfo} />
              </StyledCard>
            )}
            <LocationDetails
              {...businessInfo}
              googleDetails={googleDetails}
              getDirections={() => getDirections()}
            />
          </div>
        </Grid>
      </Grid>
      <MicrositeFooter socialLinks={businessInfo} />
    </Grid>
  );
}

export default BusinessView;
