import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { makeStyles, Grid, Button, Box } from '@material-ui/core';
import { errorChecker } from '../../utils/helper/helper';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import styled from 'styled-components';
import baseUrl from '../../utils/services/config';
import Loader from '../../components/Loader/Loader';
import ReviewsWidget from './ReviewsWidget';
import { NoData } from '../../components/NoData/NoData';
import AddCircleOutlineIcon from 'mdi-react/AddCircleOutlineIcon';

const PrimaryButton = styled(Button)`
  background-color: #30feaf !important;
  text-transform: capitalize !important;
  letter-spacing: unset !important;
  font-weight: 600 !important;
  padding: 6px 20px !important;
`;
function Widgets() {
  const { state, dispatch } = useContext(GlobalContext);
  const { selectedBusiness, selectedLoaction, locationInfo } = state;
  const [renderView, setRenderView] = useState(false);
  const [widgets, setWidgets] = useState([]);

  const initialSettings = {
    widgetName: 'My First Widget',
    isLight: true,
    showTabs: true,
    showHeader: true,
    widgetStyle: 'carousel',
    widgetPlacement: 'left',
    showWidgetTitle: true,
    widgetTitleText: 'What our customers say',
    radius: '10',
    gap: '30',
    isTextExpanded: false,
    reviewStyle: 'default',
    location: selectedLoaction,

    colors: {
      brandColor: '#30feaf',
      backgroundColor: '#f1f1f1',
      containerBgColor: '#000000',
      containerBgOpacity: '18', //'ff' is fully opaque,
      widgetTitleColor: '#000000',
    },
    reviewButton: {
      isRounded: false,
      isSolid: true,
      textColor: '#000000',
      buttonColor: '#30feaf',
      showWriteReviewButton: true,
    },
    carousel: {
      autoPlay: 0,
    },
  };

  const createWidget = useCallback(async () => {
    try {
      const postBody = {
        businessId: localStorage.getItem('SELECTED_BUSINESS'),
        widgetSettings: JSON.stringify(initialSettings),
      };
      const result = await baseUrl.post(`/create-widget`, { ...postBody });
      if (result.status === 200) {
        setRenderView(false);
        fetchWidgets();
        setRenderView(true);
      }
    } catch (err) {
      //dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  }, []);

  const fetchWidgets = useCallback(async () => {
    if (selectedBusiness) {
      setRenderView(false);
      try {
        const results = await baseUrl.get(`/reviewWidgets?id=${selectedBusiness}`);
        if (results.status === 200) {
          setWidgets(results.data.settings);
          //console.log(results.data.settings);
          setRenderView(true);
        }
      } catch (err) {
        //dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  }, [selectedBusiness]);

  useEffect(() => {
    fetchWidgets();
  }, [fetchWidgets]);

  const useStyles = makeStyles(() => ({
    root: {
      paddingTop: '2rem',
    },
  }));

  const availableWidgets = widgets.filter(
    (widget) =>
      widget.widget.toLowerCase() !== 'review summary' &&
      widget.widget.toLowerCase() !== 'reviews only' &&
      widget.widget.toLowerCase() !== 'review sources' &&
      widget.widget.toLowerCase() !== 'hybrid widget' &&
      !widget.isDeleted &&
      widget.settings.location === selectedLoaction,
  );

  const classes = useStyles();

  return renderView ? (
    <Grid container className={classes.root}>
      {availableWidgets && availableWidgets.length > 0 ? (
        <>
          <Box position={'absolute'} right={'2rem'} top={'5.5rem'}>
            <PrimaryButton onClick={() => createWidget()}>New Widget</PrimaryButton>
          </Box>
          {availableWidgets.map((widget, index) => (
            <ReviewsWidget
              key={widget.id}
              id={widget.id}
              uuid={locationInfo.uuid}
              widgetSettings={widget.settings}
              isDeleted={widget.isDeleted}
              fetchWidgets={() => fetchWidgets()}
            />
          ))}
        </>
      ) : (
        <Box flex="1">
          <NoData
            icon={<AddCircleOutlineIcon />}
            title={"You don't have a widget yet"}
            link="Create new widget"
            fullScreen
            handleClick={() => createWidget()}
            style={{ cursor: 'pointer' }}
          />
        </Box>
      )}
    </Grid>
  ) : (
    <Loader />
  );
}

Widgets.propTypes = {};

export default Widgets;
