import { FormControl } from '@material-ui/core';
import { styled, withStyles } from '@material-ui/styles';

const MuiStyledFormControl = withStyles((theme) => ({
  root: {
    width: '100%',
    '& > div:before': {
      borderBottomColor: theme.inputBorderBottomBefore,
    },
    '& > div:after': {
      borderBottomColor: theme.inputBorderBottomAfter,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottomColor: theme.inputOnFocusBefore,
    },
  },
}))(FormControl);

const StyledFormControl = styled(MuiStyledFormControl)`
  width: 100% !important;
`;

export default StyledFormControl;
