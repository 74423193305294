import React, { useState, useContext, useRef } from 'react';
import {
  Box,
  ThemeProvider as MUIThemeProvider,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import StyledBorderTextField from '../../../../../../../BorderdInputField/BorderedInputField';
import CampaignStore from '../../../../../../../../Stores/CamapignStore';
import CampaignListPopOver from '../../../../../../CampaignListPopOver/CampaignListPopOver';
import usePopover from '../../../../../../../../hooks/usePopover';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import LightningBoltIcon from 'mdi-react/LightningBoltIcon';
import AutoFixIcon from 'mdi-react/AutoFixIcon';
import usePopOverTheme from '../../../../../../../../hooks/usePopOverTheme';
import baseUrl from '../../../../../../../../utils/services/config';
import CusotmAlert from '../../../../../../../CustomAlert/CustomAlert';
import useAlert from '../../../../../../../../hooks/useAlert';
// import { SocketContext } from '../../../../../../../../Stores/SocketStore';
import { GlobalContext } from '../../../../../../../../Stores/GlobalStateStore';
import { errorChecker } from '../../../../../../../../utils/helper/helper';
import { convertIntoPhoneFormat } from '../../../../../../../../utils/helper/helper';
import DynamicPopover from '../../../../../../DynamicPopover/DynamicPopover';
import AttachFile from '@material-ui/icons/AttachFile';
import AttachmentPopover from '../../../../../../AttachmentPopover/AttchmentPopover';
import AttachElementsGallery from '../../../../../AttachElementsGallery';

const StyledBorderLessTextArea = styled(StyledBorderTextField)`
  & div textarea {
    border: none !important;
    overflow-y: auto !important;
    line-height: 1.5;
    resize: none;
    max-height: 250px !important;
    font-size: 14px;
    font-family: 'Montserrat';
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: #30feaf !important;
`;

const textBoxTheme = {
  border: 'none',
  margin: '.5rem',
  borderRadius: '8px',
  fontFamily: 'Montserrat',
};

const StyledFilterIcon = styled(FilterNoneIcon)`
  color: ${(props) => props.theme.color} !important;
`;
const StyledAttachFileIcon = styled(AttachFile)`
  color: ${(props) => props.theme.color} !important;
`;

const CustomMessage = ({
  surveyData,
  customMessage,
  setCustomMessage,
  setSurveySelected,
  isSuggestionsVisible,
  shouldSaveCampaign,
  setShouldSaveCampaign,
  customCampaign,
  setCustomCampaign,
  campaigns,
}) => {
  const [campaginLoader, setCampaignLoader] = useState(false);
  const [otherCardFileName, setOtherCardFileName] = useState('');
  const [selectedCampagin, setSelectedCampagin] = useState(null);
  const [isAiLoading, setIsAiLoading] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showEditCampaign, setShowEditCampaign] = useState(false);
  const [userLink, setUserLink] = useState({
    titleLink: '',
    link: '',
  });
  const [isLoading, setisLoading] = useState(false);
  const [hasAttachments, setHasAttachments] = useState(false);

  const attachmentPopover = usePopover();
  const campaignListPopOver = usePopover();
  const dynamicPopover = usePopover();

  const UserChatActionAlert = useAlert();
  const attachmentsRef = useRef(null);

  const isSendDisabled = Object.entries(surveyData.selectedContacts).length === 0 && !customMessage;
  const userId = parseInt(localStorage.getItem('USER_ID'), 10);

  // const { socketState, socketDispatch } = useContext(SocketContext);
  const { dispatch, state } = useContext(GlobalContext);
  const { selectedLoaction, selectedBusiness, businessInfo, locationInfo } = state;

  const dynamicTags = [
    {
      label: 'Business Name',
      actual: businessInfo.name,
      value: '@business',
    },
    {
      label: 'Location Name',
      actual: locationInfo.name,
      value: '@location',
    },
    {
      label: 'Website',
      actual: '',
      value: '@website',
    },
    {
      label: 'Phone',
      actual: convertIntoPhoneFormat(businessInfo?.contact.phoneNumber),
      value: '@phone',
    },
  ];

  const attachElements =
    customCampaign?.uploadedFile.filter(
      (item) =>
        item?.meta === 'attachment' || item?.templateType === 'attachment' || item?.meta === 'sms',
    ) || [];

  // const attachElements =
  //   customCampaign?.uploadedFile.filter(
  //     (item) => item?.meta === 'attachment' || item?.templateType === 'attachment',
  //   ) || [];
  //console.log(customCampaign, customMessage);
  //const { fetchConversations, isLoading } = useFetchConversations(conversationType);
  const contactId = '';
  const handleCampaignRefInit = () => {};

  const handleSelectedCampaign = (info) => {
    const uploadedFiles = campaigns.filter((item) => item.id === info.id);

    if (uploadedFiles[0]?.uploadedFile?.length > 0) {
      setHasAttachments(true);

      setCustomCampaign({
        ...info,
        uploadedFile: uploadedFiles[0].uploadedFile,
      });
    } else {
      setHasAttachments(false);
      setCustomCampaign({
        ...info,
        uploadedFile: [],
      });
    }
    setCustomMessage(info.message);
    setSelectedCampagin(info);
    setSurveySelected(info);
    campaignListPopOver.handleClose();
  };

  const { popOverTheme } = usePopOverTheme();

  const handleMessageOnChange = (e) => {
    setCustomMessage(e.target.value);
  };

  const handleGetAiGeneratedResponse = () => {
    if (isAiLoading) return;
    const { handleSetMessage, handleSetMessageType, handleShow } = UserChatActionAlert;

    setIsAiLoading(true);
    baseUrl
      .post(
        `/conversation/openai-response?conversation=${encodeURIComponent(
          JSON.stringify(customMessage),
        )}`,
      )
      .then((res) => {
        setIsAiLoading(false);
        if (res.status === 200 && res.data?.message) {
          setCustomMessage(res.data.message);
        } else {
          setIsAiLoading(false);

          handleSetMessageType('error');
          handleSetMessage(
            'Something went wrong. Please check console errors for more information',
          );
          handleShow();
          console.error('Open AI error: ', res);
        }
      })
      .catch((err) => {
        setIsAiLoading(false);
        console.log(err);
      });
  };

  const handleBusinessCardChange = async () => {
    try {
      const postBody = {
        source: 'vcard',
        client: surveyData.selectedContacts[0].id,
        location: selectedLoaction,
        business: selectedBusiness,
        user: userId,
        message: '',
        phone: surveyData.selectedContacts[0].phoneNumber,
        meta: {
          vacardUrl: `${process.env.REACT_APP_IMAGE_ENDPOINT}/public/vcard/${userId}.vcf`,
        },
        attributed: userId,
      };

      console.log(postBody);
      attachmentPopover.handleClose();
      const vcardResult = await baseUrl.post(`/conversation/vcard`, { ...postBody });
      if (vcardResult.status === 200) {
        //fetchConversations(postBody.client);
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  // Attachments of other types expect business image handler method
  const handleOtherAttachmentChange = async (e) => {
    setOtherCardFileName(e.target.files[0].name);
    const picture = new FormData();
    picture.append('picture', e.target.files[0]);
    try {
      const result = await baseUrl.post(
        `/uploads/conversation?type=conversations&meta=attachment`,
        picture,
      );
      if (result.status === 200) {
        const postBody = {
          uploadedFile: result.data[0].id,
          source: 'attachment',
          client: surveyData.selectedContacts[0].id,
          location: selectedLoaction,
        };
        attachmentPopover.handleClose();
        const results = await baseUrl.post(`conversation/attach-file`, { ...postBody });
        if (results.status === 200) {
          //fetchConversations(result.data.client);
          setOtherCardFileName('');

          setTimeout(() => {}, 100);
        }
      }
    } catch (err) {
      setOtherCardFileName('');
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const handleAddTag = (e, value) => {
    setCustomMessage((prevSendMessage) => `${prevSendMessage} ${value}`.trim());
    setAnchorEl(null);
    dynamicPopover.handleClose();
  };

  const addAttachments = () => {
    setHasAttachments(true);

    if (attachmentsRef.current) {
      attachmentsRef.current.click();
    }
  };

  return (
    <div style={{ display: 'flex', rowGap: '.5rem', flexDirection: 'column' }}>
      <Box
        flex="1"
        padding=".5rem"
        sx={{ borderRadius: 10, backgroundColor: '#fff', position: 'relative' }}
      >
        {!customMessage && !isSuggestionsVisible && (
          <div
            onClick={campaignListPopOver.handleClick}
            style={{
              position: 'absolute',
              zIndex: 1,
              top: '1.25rem',
              left: '171px',
              fontSize: '14px',
              color: '#2bdf9a',
              cursor: 'pointer',
            }}
          >
            or use a template
          </div>
        )}
        <ThemeProvider theme={textBoxTheme}>
          <StyledBorderLessTextArea
            name="sendText"
            placeholder={'Enter your message'}
            onChange={(e) => handleMessageOnChange(e)}
            inputProps={{ maxLength: 2000 }}
            value={customMessage}
            multiline
          ></StyledBorderLessTextArea>
        </ThemeProvider>
        <Box margin={'.5rem 1rem'}>
          {hasAttachments || (customCampaign && customCampaign?.uploadedFile?.length) ? (
            <AttachElementsGallery
              campaign={customCampaign}
              setCampaign={setCustomCampaign}
              attachElements={attachElements}
              showEditCampaign={showEditCampaign}
              setShowEditCampaign={setShowEditCampaign}
              userLink={userLink}
              setUserLink={setUserLink}
              setIsLoading={setisLoading}
              hasAttachments={hasAttachments}
              customStyles={{ width: 50, height: 50, blankWidth: 50, blankHeight: 45 }}
            />
          ) : null}
        </Box>
        <Box
          flexDirection="row"
          flex="1"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginTop="20px"
        >
          <Box display="flex">
            <IconButton
              onClick={() => {
                addAttachments();
              }}
              title="Attach files"
              disabled={isSendDisabled || customCampaign?.uploadedFile?.length > 0}
            >
              <StyledAttachFileIcon fontSize="small" />
            </IconButton>
            <IconButton
              innerRef={handleCampaignRefInit}
              onClick={campaignListPopOver.handleClick}
              title="Select Campaigns"
              disabled={isSendDisabled}
            >
              <StyledFilterIcon fontSize="small" />
            </IconButton>
            <IconButton
              onClick={handleGetAiGeneratedResponse}
              title="Get AI Generated Message"
              disabled={isSendDisabled}
            >
              {!isAiLoading ? (
                <AutoFixIcon fontSize="small" />
              ) : (
                <StyledCircularProgress size={20} />
              )}
            </IconButton>
            <IconButton onClick={dynamicPopover.handleClick} disabled={isSendDisabled}>
              <LightningBoltIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        <MUIThemeProvider theme={popOverTheme}>
          <DynamicPopover
            anchorEl={dynamicPopover.anchorEl}
            handleClose={dynamicPopover.handleClose}
            open={dynamicPopover.open}
            id={dynamicPopover.id}
            dynamicTags={dynamicTags}
            handleAddTag={handleAddTag}
          />
          <AttachmentPopover
            anchorEl={attachmentPopover.anchorEl}
            handleClose={attachmentPopover.handleClose}
            open={attachmentPopover.open}
            id={attachmentPopover.id}
            handleBusinessCardChange={handleBusinessCardChange}
            handleOtherAttachmentChange={handleOtherAttachmentChange}
            otherCardFileName={otherCardFileName}
          />
          <CampaignStore>
            <CampaignListPopOver
              anchorEl={campaignListPopOver.anchorEl}
              handleClose={campaignListPopOver.handleClose}
              open={campaignListPopOver.open}
              id={campaignListPopOver.id}
              handleSelectedCampaign={handleSelectedCampaign}
              campaginLoader={campaginLoader}
              contactId={userId}
            />
          </CampaignStore>
        </MUIThemeProvider>
        <CusotmAlert
          message={UserChatActionAlert.message}
          handleClose={UserChatActionAlert.handleClose}
          open={UserChatActionAlert.show}
          messageType={UserChatActionAlert.messageType}
        />
      </Box>
    </div>
  );
};

export default CustomMessage;
