import React, { useCallback, useEffect, useState, useContext } from 'react';
import Axios from 'axios';
import baseUrl from '../../utils/services/config';
import Loader from '../../components/Loader/Loader';
import CusotmTable from '../../components/CustomTable/CusotmTable';
import {
  createDynamicTableHeader,
  convertIntoPermissionTable,
  convertIntoPermissionPostData,
  errorChecker,
} from '../../utils/helper/helper';
import { PermissionContext } from '../../Stores/PermissionStore';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import useCheckBoxTheme from '../../hooks/useCheckBoxTheme';

function Permissions() {
  const [permissionContainer, setPermissionContainer] = useState([]);
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const { dispatch } = useContext(GlobalContext);
  const { PermissionData, PermissionDispatch } = useContext(PermissionContext);
  const { rolePermission, defaultPermissions, tableData } = PermissionData;
  const { checkBoxTheme } = useCheckBoxTheme();
  const fetchPermissions = useCallback(() => {
    if (!permissionContainer.length) {
      baseUrl
        .get('permission?limit=1000')
        .then((res) => {
          setPermissionContainer(Array.from(res.data));
        })
        .catch((err) => {
          setLoading(false);
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    }
  }, [dispatch, permissionContainer]);

  const fetchRoles = useCallback(() => {
    baseUrl
      .get('role')
      .then((res) => {
        PermissionDispatch({
          type: 'SET_ROLE_PERMISSION',
          payload: Array.from(res.data),
        });
      })
      .catch((err) => {
        setLoading(false);
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      });
  }, [PermissionDispatch, dispatch]);

  const fetchRolesPermissions = useCallback(async () => {
    if (rolePermission) {
      try {
        const promises = rolePermission.map((element) =>
          baseUrl.get(`role/${element.id}/permissions?limit=1000`),
        );
        const results = await Axios.all(promises);
        const container = [];
        results.forEach((element, index) => {
          container.push({ [rolePermission[index].id]: element.data });
        });
        if (container.length === rolePermission.length) {
          PermissionDispatch({
            type: 'SET_DEFAULT_PERMISSIONS',
            payload: Array.from(container),
          });
        }
      } catch (err) {
        setLoading(false);
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  }, [rolePermission, PermissionDispatch, dispatch]);

  useEffect(() => {
    fetchPermissions();
    fetchRoles();
  }, []);

  const handleCheck = (checked, rowIndex, columnIndex) => {
    const dataCopy = Array.from(tableData);
    dataCopy[rowIndex][Object.keys(dataCopy[rowIndex])[columnIndex]] = checked;
    PermissionDispatch({
      type: 'SET_TABLE_DATA',
      payload: Array.from(dataCopy),
    });
    PermissionDispatch({
      type: 'SET_POST_DATA',
      payload: Array.from(
        convertIntoPermissionPostData(permissionContainer, tableData, rolePermission),
      ),
    });
  };

  useEffect(() => {
    if (rolePermission) {
      fetchRolesPermissions();
    }
  }, [rolePermission, fetchRolesPermissions]);

  useEffect(() => {
    if (permissionContainer.length > 0 && rolePermission.length > 0) {
      setColumns(
        Array.from(
          createDynamicTableHeader(rolePermission, handleCheck, permissionContainer, checkBoxTheme),
        ),
      );
    }
  }, [permissionContainer, rolePermission]);

  useEffect(() => {
    if (columns.length > 0 && defaultPermissions.length > 0) {
      setLoading(false);
      const tableDataContainer = Array.from(
        convertIntoPermissionTable(
          columns,
          permissionContainer,
          rolePermission,
          defaultPermissions,
        ),
      );
      PermissionDispatch({
        type: 'SET_TABLE_DATA',
        payload: Array.from(tableDataContainer),
      });
      PermissionDispatch({
        type: 'SET_TABLE_DATA',
        payload: Array.from(tableDataContainer),
      });

      PermissionDispatch({
        type: 'SET_POST_DATA',
        payload: Array.from(
          convertIntoPermissionPostData(permissionContainer, tableDataContainer, rolePermission),
        ),
      });
    }
  }, [columns, permissionContainer, rolePermission, defaultPermissions, PermissionDispatch]);

  const options = {
    textLabels: {
      body: {
        noMatch: loading ? <Loader /> : 'No data found !',
      },
    },
    selectToolbarPlacement: 'none',
    searchOpen: false,
    filter: true,
    selectableRows: 'none',
    filterType: 'dropdown',
    responsive: 'vertical',
    rowsPerPage: 10,
    selectableRowsHeader: true,
  };

  const perrmisionView = loading ? (
    <Loader />
  ) : (
    <CusotmTable
      data={tableData}
      columns={createDynamicTableHeader(
        rolePermission,
        handleCheck,
        permissionContainer,
        checkBoxTheme,
      )}
      options={options}
    />
  );

  return perrmisionView;
}

export default React.memo(Permissions);
