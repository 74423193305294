import React from 'react';
import PropTypes from 'prop-types';
import Builder from '../Builder/Builder';
import Preview from '../Preview/Preview';

const TemplateBuilder = ({
  emailBody,
  handleEditorChangeBody,
  emailFooter,
  handleEditorChangeFooter,
  previewEmailBody,
  previewEmailFooter,
  uploadedFile,
  setUploadedFile,
}) => {
  return (
    <div
      style={{
        height: '60vh',
        gridTemplateColumns: '1fr 1fr',
        display: 'grid',
        columnGap: '1rem',
        margin: '0 .5rem',
      }}
    >
      <div style={{ overflowY: 'auto' }}>
        <Builder
          emailBody={emailBody}
          emailFooter={emailFooter}
          handleEditorChangeBody={handleEditorChangeBody}
          handleEditorChangeFooter={handleEditorChangeFooter}
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
        />
      </div>
      <div style={{ overflowY: 'auto', height: '100%' }}>
        <Preview
          previewEmailBody={previewEmailBody}
          previewEmailFooter={previewEmailFooter}
          uploadedFile={uploadedFile}
        />
      </div>
    </div>
  );
};

TemplateBuilder.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  emailBody: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  previewEmailFooter: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  previewEmailBody: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  emailFooter: PropTypes.any,
  handleEditorChangeBody: PropTypes.func,
  handleEditorChangeFooter: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  uploadedFile: PropTypes.object,
  setUploadedFile: PropTypes.func,
};

TemplateBuilder.defaultProps = {
  emailBody: null,
  previewEmailBody: null,
  emailFooter: null,
  previewEmailFooter: null,
  handleEditorChangeBody: () => {},
  handleEditorChangeFooter: () => {},
  uploadedFile: null,
  setUploadedFile: () => {},
};

export default React.memo(TemplateBuilder);
