import { TextField } from '@material-ui/core';
import styled from 'styled-components';

const StyledBorderTextField = styled(TextField)`
  width: -webkit-fill-available;

  margin: ${(props) => (props.theme.margin ? props.theme.margin : '1em 0 0.5em')} !important; ;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  & div {
    width: -webkit-fill-available;
    background: #fff;
    padding: 0 !important;
  }

  & div input {
    border:  ${(props) => (props.theme.border ? props.theme.border : 'none')} !important; 
    border-bottom:  ${(props) => (props.theme.borderBottom ? props.theme.borderBottom : 'none')} !important;
    padding: ${(props) => (props.theme.padding ? props.theme.padding : '0.65em 0.5em')} !important;
    background: ${(props) => props.theme.background} !important;
    color: ${(props) => props.theme.color} !important;
    font-size: ${(props) => props.theme.fontSize} !important;
    font-family: 'Montserrat';
  }

  & div input:disabled {
    cursor: not-allowed !important;
  }

  & div textarea {
    border: 1px solid #dedede !important;
    padding: 0.25em 0.5em !important;
  }

  &.MuiFormControl-fullWidth {
    width: -webkit-fill-available !important;
  }

  & div:after {
    border-bottom: none !important;
  }

  & div:before {
    border-bottom: none !important;
  }
`;

export default StyledBorderTextField;
