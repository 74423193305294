import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Grid, MobileStepper, Button } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Proptypes from 'prop-types';
import SmsView from '../MobileViewScreen/SmsView';
import FeedbackView from '../MobileViewScreen/FeedbackView';
import ThankyouView from '../MobileViewScreen/ThankyouView';
import RecomendView from '../MobileViewScreen/RecomendView';
import NegativeFeedbackView from '../MobileViewScreen/NegativeFeedbackView';
import SocialSite from '../MobileViewScreen/SocialSite';
import PositiveFeedback from '../MobileViewScreen/PositiveFeedback';
import TextSurvey from '../MobileViewScreen/TextSurvey';
import RatingSurvey from '../MobileViewScreen/RatingSurvey';
import SentimentSurvey from '../MobileViewScreen/SentimentSurvey';
import SingleActionView from '../MobileViewScreen/SingleActionView';
import OptInOutView from '../MobileViewScreen/OptInOutView';
import ConfirmationView from '../MobileViewScreen/ConfirmationView';
import NoReplyView from '../MobileViewScreen/NoReplyView';
import AutoReviewReminder from '../MobileViewScreen/AutoReviewReminder';
import QuestionView from '../MobileViewScreen/QuestionView';

const StyledMobileViewContainer = styled(Grid)`
  height: 100%;
  flex-direction: column;
`;

const StyledFowArrow = styled(ArrowForwardIcon)`
  color: ${(props) => props.theme.iconColor} !important;
  font-size: ${(props) => props.theme.iconfontSize} !important;
`;

const StyledBackArrow = styled(KeyboardBackspaceIcon)`
  color: ${(props) => props.theme.iconColor} !important;
  font-size: ${(props) => props.theme.iconfontSize} !important;
`;

const StyledMobileSteeper = styled(MobileStepper)`
  background: transparent !important;
  & > .MuiMobileStepper-dots > .MuiMobileStepper-dotActive {
    background-color: #c1c1c1 !important;
  }
  & > .MuiMobileStepper-dots > .MuiMobileStepper-dot {
    background-color: #dfdfdf;
  }
`;

function MobileViewContainer(props) {
  const { slides, handleNextStep, handleBackStep, activeStep } = props;

  const isSurvey = slides[activeStep].screen === 'question' ? true : false;

  const getNameByVal = (value) => {
    switch (value) {
      case 'sms':
        return <SmsView {...slides[activeStep]} {...props} />;

      case 'feedback':
        return <FeedbackView {...slides[activeStep]} {...props} />;

      case 'thankyou':
        return <ThankyouView {...slides[activeStep]} {...props} />;

      case 'recommend':
        return <RecomendView {...slides[activeStep]} {...props} />;

      case 'negativefeedback':
        return <NegativeFeedbackView {...slides[activeStep]} {...props} />;

      case 'social-site':
        return <SocialSite {...slides[activeStep]} {...props} />;
      case 'setup':
        return <SocialSite {...slides[activeStep]} {...props} />;

      case 'postitiveFeedback':
        return <PositiveFeedback {...slides[activeStep]} {...props} />;

      case 'text-survey':
        return <TextSurvey {...slides[activeStep]} {...props} />;

      case 'rating-survey':
        return <RatingSurvey {...slides[activeStep]} {...props} />;

      case 'sentiment-survey':
        return <SentimentSurvey {...slides[activeStep]} {...props} />;

      case 'single-action':
        return <SingleActionView {...slides[activeStep]} {...props} />;

      case 'opt-in-or-out':
        return <OptInOutView {...slides[activeStep]} {...props} />;

      case 'confirmation':
        return <ConfirmationView {...slides[activeStep]} {...props} />;

      case 'no-reply':
        return <NoReplyView {...slides[activeStep]} {...props} />;

      case 'auto-review-reminder':
        return <AutoReviewReminder {...slides[activeStep]} {...props} />;

      case 'question':
        return <QuestionView {...slides[activeStep]} {...props} />;

      default:
    }
    return null;
  };

  return (
    <>
      <Grid item>
        <StyledMobileViewContainer container justify="space-around" alignItems="center">
          {getNameByVal(slides[activeStep].screen)}
        </StyledMobileViewContainer>
        <StyledMobileSteeper
          steps={!isSurvey ? slides.length : slides[activeStep].questions.length}
          position="static"
          variant="dots"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNextStep}
              disabled={activeStep === slides.length - 1}
            >
              <ThemeProvider theme={{ iconfontSize: '1.5em', iconColor: '#c1c1c1' }}>
                <StyledFowArrow />
              </ThemeProvider>
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBackStep} disabled={activeStep === 0}>
              <ThemeProvider theme={{ iconfontSize: '1.5em', iconColor: '#c1c1c1' }}>
                <StyledBackArrow />
              </ThemeProvider>
            </Button>
          }
        />
      </Grid>
    </>
  );
}

MobileViewContainer.propTypes = {
  slides: Proptypes.arrayOf(Proptypes.string).isRequired,
  handleNextStep: Proptypes.func.isRequired,
  handleBackStep: Proptypes.func.isRequired,
  activeStep: Proptypes.number.isRequired,
};

export default React.memo(MobileViewContainer);
