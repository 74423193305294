const surveyState = {
  title: '',
  selectedContacts: [],
  redirectTo: '',
  statusMessage: '',
  surveyByBusiness: [],
  surveyQuestionsAverage: [],
  surveyBusinessResponses: [],
  surverLocationDetails: {},
};

export default surveyState;
