import React from 'react';
import Proptypes from 'prop-types';
import { FormHelperText, Grid } from '@material-ui/core';
import { ThemeProvider } from 'styled-components';
import FieldGroupLable from '../../FieldGroupLable/FieldGroupLable';
import CustomButton from '../../CustomButton/CustomButton';
import EmoticonSad from '../../Icons/EmoticonSad';
import EmoticonNutral from '../../Icons/EmoticonNutral';
import Emoticon from '../../Icons/Emoticon';

const sentimentButtonTheme = {
  background: '#fff',
  borderRadis: '0',
  margin: '0em 0',
};

function SentimentFilter(props) {
  const { setSentiment, sentiment, sentimentError, setSentimentError } = props;

  const errorContent = sentimentError ? (
    <FormHelperText style={{ color: '#f44235' }}>Please select one of the option</FormHelperText>
  ) : null;

  const handleClick = (value) => {
    setSentiment(value);
    setSentimentError(false);
  };

  return (
    <>
      <FieldGroupLable item xs={12} sm={12} md={12} lg={12}>
        sentiment
      </FieldGroupLable>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container justify="space-around">
          <Grid item>
            <ThemeProvider theme={{ ...sentimentButtonTheme }}>
              <CustomButton onClick={() => handleClick(0)}>
                <EmoticonSad fill={sentiment === 0 ? '#f44235' : '#888888'} />
              </CustomButton>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <ThemeProvider theme={{ ...sentimentButtonTheme }}>
              <CustomButton onClick={() => handleClick(1)}>
                <EmoticonNutral fill={sentiment === 1 ? '#feb359' : '#888888'} />
              </CustomButton>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <ThemeProvider theme={{ ...sentimentButtonTheme }}>
              <CustomButton onClick={() => handleClick(2)}>
                <Emoticon fill={sentiment === 2 ? '#3fb51c' : '#888888'} />
              </CustomButton>
            </ThemeProvider>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container alignItems="center" justify="center">
          {errorContent}
        </Grid>
      </Grid>
    </>
  );
}

SentimentFilter.propTypes = {
  setSentiment: Proptypes.func.isRequired,
  sentiment: Proptypes.number.isRequired,
  sentimentError: Proptypes.bool.isRequired,
  setSentimentError: Proptypes.func.isRequired,
};

export default SentimentFilter;
