import { GlobalContext } from '../Stores/GlobalStateStore';
import { useContext } from 'react';

const useUpdateInviteData = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { inviteData } = state;

  const updateInviteData = (inputData) => {
    if (Object.keys(inputData).length === 0) {
      let newData = {};
      const clearInviteData = newData;
      dispatch({ type: 'SET_INVITE_DATA', payload: clearInviteData });
      return;
    }
    let newData = {};
    if (isFirstName(inputData)) {
      newData = { firstName: inputData }; // Assign inputData as the value of firstName property
    } else if (isFullName(inputData)) {
      const [firstName, lastName] = inputData.split(' ');
      newData = { firstName, lastName };
    } else if (isEmail(inputData)) {
      newData = { email: inputData };
    } else if (isPhoneNumber(inputData)) {
      newData = { phoneNumber: inputData };
    }
    const updatedInviteData = { ...inviteData, ...newData };
    dispatch({ type: 'SET_INVITE_DATA', payload: updatedInviteData });
  };

  const isFirstName = (input) => {
    return input.trim().split(' ').length === 1 && !/@/.test(input) && !/\d/.test(input);
  };

  const isFullName = (input) => {
    return input.trim().split(' ').length === 2;
  };

  const isEmail = (input) => {
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
    return emailRegex.test(input.trim());
  };

  const isPhoneNumber = (input) => {
    const phoneNumberRegex = /^\d{10}$/;
    return phoneNumberRegex.test(input.trim().replace(/\D/g, ''));
  };
  return updateInviteData;
};

export default useUpdateInviteData;
