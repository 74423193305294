import React, { useContext, useEffect } from 'react';
import Proptypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import {
  Grid,
  FormHelperText,
  ThemeProvider as MUIThemeProvider,
  InputAdornment,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import HelpIcon from '@material-ui/icons/Help';
import { useForm, Controller } from 'react-hook-form';
import MessageLogo from '../../../assets/images/review_generation4@2x.png';
import CompanyLogo from '../../../components/CompanyLogo/CompanyLogo';
import CustomButton from '../../../components/CustomButton/CustomButton';
import Logo from '../../../assets/images/reveo_logo@2x.png';
import Dropdown from '../../../components/Dropdown/Dropdown';
import { OnBoardingContext } from '../../../Stores/OnBoarding';
import Timezone from '../../../utils/api/Timezone';
import NumberField from '../../../components/NumberField/NumberField';
import {
  handleLocationViewErrors,
  handlePhoneError,
  handleEmailError,
} from '../../../utils/helper/helper';
import { emailRegEx } from '../../../utils/helper/regEx';
import StyledTextField from '../../../components/GeneralInputField/GeneralInputField';
import defaultDropDownTheme from '../../../themes/Dropdown/DefaultDropdownTheme';
import OnBoardingButtonTheme from '../../../themes/Button/OnBoardingButtonTheme';
import OnBoardingHeaderLogoTheme from '../../../themes/Logo/OnBoardingHeaderLogoTheme';
import StyledFormControl from '../../../components/StyledFormControl';
import useIconTheme from '../../../hooks/useIconTheme';
import StyledBeginText from '../../../components/BeginText/BeginText';
import useTextColors from '../../../hooks/useTextColors';
import useInputFieldTheme from '../../../hooks/useInputFieldTheme';

const StyledGridContaienr = styled(Grid)`
  width: 100% !important;
  min-height: 480px !important;
  height: 100% !important;
`;

const StyledContentContainer = styled(Grid)`
  height: calc(100% - 45px) !important;
`;

const StyledTitle = styled(Grid)`
  letter-spacing: 0.72px;
  color: #6c6c6c;
  font-size: 36px;
  font-weight: 300 !important;
  margin: 1em 0 !important;
`;

const StyledFooter = styled(Grid)`
  align-self: flex-end;
`;

const StyledFowArrow = styled(ArrowForwardIcon)`
  color: #fff !important;
  font-size: 3em !important;
`;

const StyledHelpIcon = styled(HelpIcon)`
  color: #c1c1c1 !important;
  font-size: 2em !important;
`;

const rocketLogoTheme = {
  width: '236px',
  height: '114px',
  largeScreenWidth: '336px',
  largeScreenHeight: '174px',
  padding: '0 1em',
};

function Step3(props) {
  const { handleNext } = props;
  const { iconsTheme } = useIconTheme();
  const { onBoardingData, onBoardingDispatch } = useContext(OnBoardingContext);
  const { userName } = onBoardingData;
  const { register, control, errors, handleSubmit, watch, setValue } = useForm();
  const { textColors } = useTextColors();
  const { inputTheme } = useInputFieldTheme();
  const onSubmit = (data) => {
    onBoardingDispatch({ type: 'SET_BUSNIENSS_DETALS', payload: { ...data } });
    handleNext();
  };

  useEffect(() => {
    register({ name: 'contact.businessContact' }, { minLength: 10 });
    register({ name: 'contact.phoneNumber' }, { required: true, minLength: 10 });
    register({ name: 'contact.zip' }, { required: true, minLength: 5 });
  }, [register]);

  const watchAllFields = watch({ nest: true });

  const handleTrimAndSetValue = (name, e) => {
    setValue(name, e.target.value.trim());
  };

  const handlePhoneNumber = (values) => {
    setValue('contact.phoneNumber', values.value);
  };

  const greetings = `Howdy ${userName}!`;
  return (
    <StyledGridContaienr>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        sm={12}
        style={{ flex: '1 0 auto', maxHeight: '40px', minHeight: '40px', padding: '.5em 1em' }}
      >
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <ThemeProvider theme={{ color: iconsTheme.helpIcon }}>
              <StyledHelpIcon />
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Grid container justify="flex-end">
              <ThemeProvider theme={OnBoardingHeaderLogoTheme}>
                <CompanyLogo alt="Reveo" logo={Logo} />
              </ThemeProvider>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={12} sm={12} style={{ height: 'calc(100% - 40px)' }}>
        <MUIThemeProvider theme={inputTheme}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sm={12}
              style={{
                margin: '0 0 0 1em',
                flex: '1 1 auto',
                overflow: 'auto',
                overflowX: 'hidden',
                height: 'calc(100% - 118px)',
              }}
            >
              <StyledContentContainer container alignItems="center">
                <Grid item xs={12} sm={12} lg={12} md={12}>
                  <Grid container direction="column" alignItems="center">
                    <Grid item xs={12} sm={12} lg={12} md={12}>
                      <Grid container justify="center">
                        <Grid item xs={12} md={12} lg={12} sm={12}>
                          <Grid container justify="center">
                            <StyledTitle item>{greetings}</StyledTitle>
                          </Grid>
                        </Grid>
                        <Grid item xsTimezone={12} md={8} lg={8} sm={12}>
                          <Grid container justify="center" alignItems="center">
                            <ThemeProvider theme={{ color: textColors.headingTextColor }}>
                              <StyledBeginText item xs={12} md={12} lg={12} sm={12}>
                                Where are you located?
                              </StyledBeginText>
                            </ThemeProvider>
                            <Grid item xs={12} md={12} lg={12} sm={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={8} lg={8} sm={12}>
                                  <StyledTextField
                                    name="name"
                                    label="Business Name *"
                                    onBlur={(e) => setValue('name', e.target.value.trim())}
                                    inputRef={register({
                                      required: true,
                                      validate: (value) => value.trim() !== '',
                                    })}
                                    error={!!errors.name}
                                    helperText={
                                      errors.name ? 'Business name is a required field' : ''
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} md={4} lg={4} sm={12}>
                                  <StyledTextField
                                    name="nickName"
                                    label="Nickname *"
                                    onBlur={(e) => setValue('nickName', e.target.value.trim())}
                                    inputRef={register({
                                      required: true,
                                      validate: (value) => value.trim() !== '',
                                    })}
                                    error={!!errors.nickName}
                                    helperText={
                                      errors.nickName ? 'Nickname is a required field' : ''
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} sm={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={8} lg={8} sm={12}>
                                  <NumberField
                                    name="contact.businessContact"
                                    format="+1 ### ### ####"
                                    label="Business Contact Number"
                                    onChange={(v) => setValue('contact.businessContact', v.value)}
                                    // error={
                                    //   handleLocationViewErrors(errors, 'contact')
                                    //     ? errors.contact.businessContact
                                    //     : false
                                    // }
                                    // helperText={
                                    //   handleLocationViewErrors(errors, 'contact') &&
                                    //   errors.contact.businessContact
                                    //     ? handlePhoneError(
                                    //         errors.contact.businessContact,
                                    //         'Contact Mobile',
                                    //       )
                                    //     : ''
                                    // }
                                    defaultValue={
                                      watchAllFields.contact &&
                                      watchAllFields.contact.businessContact
                                        ? watchAllFields.contact.businessContact
                                        : ''
                                    }
                                    value={
                                      watchAllFields.contact &&
                                      watchAllFields.contact.businessContact
                                        ? watchAllFields.contact.businessContact
                                        : ''
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} md={4} lg={4} sm={12}>
                                  <NumberField
                                    name="contact.phoneNumber"
                                    format="+1 ### ### ####"
                                    label="Contact Mobile *"
                                    onChange={(values) => handlePhoneNumber(values)}
                                    error={
                                      handleLocationViewErrors(errors, 'contact')
                                        ? errors.contact.phoneNumber
                                        : false
                                    }
                                    helperText={
                                      handleLocationViewErrors(errors, 'contact') &&
                                      errors.contact.phoneNumber
                                        ? handlePhoneError(
                                            errors.contact.phoneNumber,
                                            'Contact Mobile',
                                          )
                                        : ''
                                    }
                                    defaultValue={
                                      watchAllFields.contact && watchAllFields.contact.phoneNumber
                                        ? watchAllFields.contact.phoneNumber
                                        : ''
                                    }
                                    value={
                                      watchAllFields.contact && watchAllFields.contact.phoneNumber
                                        ? watchAllFields.contact.phoneNumber
                                        : ''
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} sm={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={8} lg={8} sm={12}>
                                  <StyledTextField
                                    name="contact.addressLine1"
                                    label="Address 1 *"
                                    onBlur={(e) => handleTrimAndSetValue('contact.addressLine1', e)}
                                    inputRef={register({
                                      required: true,
                                      validate: (value) => value.trim() !== '',
                                    })}
                                    error={
                                      handleLocationViewErrors(errors, 'contact')
                                        ? errors.contact.addressLine1
                                        : false
                                    }
                                    helperText={
                                      handleLocationViewErrors(errors, 'contact') &&
                                      errors.contact.addressLine1
                                        ? 'Address is a required field'
                                        : ''
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} md={4} lg={4} sm={12}>
                                  <StyledTextField
                                    name="email"
                                    label="Contact Email *"
                                    onBlur={(e) => setValue('email', e.target.value.trim())}
                                    inputRef={register({ required: true, pattern: emailRegEx })}
                                    error={errors.email}
                                    helperText={errors.email ? handleEmailError(errors.email) : ''}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            {/* <Grid item xs={12} md={12} lg={12} sm={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6} lg={6} sm={12}>
                                  <StyledTextField
                                    name="googleEmail"
                                    label="Google Email *"
                                    onBlur={(e) => setValue('googleEmail', e.target.value.trim())}
                                    inputRef={register({ required: true, pattern: emailRegEx })}
                                    error={errors.googleEmail}
                                    helperText={
                                      errors.googleEmail ? handleEmailError(errors.googleEmail) : ''
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6} sm={12}>
                                  <StyledTextField
                                    name="facebookEmail"
                                    label="Facebook Email *"
                                    onBlur={(e) => setValue('facebookEmail', e.target.value.trim())}
                                    inputRef={register({ required: true, pattern: emailRegEx })}
                                    error={errors.facebookEmail}
                                    helperText={
                                      errors.facebookEmail
                                        ? handleEmailError(errors.facebookEmail)
                                        : ''
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Grid> */}
                            {/* <Grid item xs={12} md={12} lg={12} sm={12}>
                              <StyledTextField
                                name="twitterEmail"
                                label="Twitter Email *"
                                onBlur={(e) => setValue('twitterEmail', e.target.value.trim())}
                                inputRef={register({ required: true, pattern: emailRegEx })}
                                error={errors.twitterEmail}
                                helperText={
                                  errors.twitterEmail ? handleEmailError(errors.twitterEmail) : ''
                                }
                              />
                            </Grid> */}
                            <Grid item xs={12} md={12} lg={12} sm={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={8} lg={8} sm={12}>
                                  <StyledTextField
                                    name="contact.addressLine2"
                                    onBlur={(e) => handleTrimAndSetValue('contact.addressLine2', e)}
                                    label="Address 2 "
                                    inputRef={register}
                                    error={
                                      handleLocationViewErrors(errors, 'contact')
                                        ? errors.contact.addressLine2
                                        : false
                                    }
                                    helperText={
                                      handleLocationViewErrors(errors, 'contact') &&
                                      errors.contact.addressLine2
                                        ? 'Address is a required field'
                                        : ''
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} md={4} lg={4} sm={12}>
                                  <StyledTextField
                                    name="contact.webSite"
                                    onBlur={(e) => handleTrimAndSetValue('contact.webSite', e)}
                                    label="Web Address *"
                                    inputRef={register({
                                      required: true,
                                      validate: (value) => value.trim() !== '',
                                    })}
                                    error={
                                      handleLocationViewErrors(errors, 'contact')
                                        ? errors.contact.webSite
                                        : false
                                    }
                                    helperText={
                                      handleLocationViewErrors(errors, 'contact') &&
                                      errors.contact.webSite
                                        ? 'Web Address is a required field'
                                        : ''
                                    }
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">https://</InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} sm={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={8} lg={8} sm={12}>
                                  <Grid container spacing={1}>
                                    <Grid item xs={12} md={4} lg={4} sm={12}>
                                      <StyledTextField
                                        name="contact.city"
                                        onBlur={(e) => handleTrimAndSetValue('contact.city', e)}
                                        label="City *"
                                        inputProps={{ 'aria-label': 'description' }}
                                        inputRef={register({
                                          required: true,
                                          validate: (value) => value.trim() !== '',
                                        })}
                                        error={
                                          handleLocationViewErrors(errors, 'contact')
                                            ? errors.contact.city
                                            : false
                                        }
                                        helperText={
                                          handleLocationViewErrors(errors, 'contact') &&
                                          errors.contact.city
                                            ? 'City is a required field'
                                            : ''
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4} sm={12}>
                                      <StyledTextField
                                        name="contact.state"
                                        onBlur={(e) => handleTrimAndSetValue('contact.state', e)}
                                        label="State *"
                                        inputProps={{ 'aria-label': 'description' }}
                                        inputRef={register({
                                          required: true,
                                          validate: (value) => value.trim() !== '',
                                        })}
                                        error={
                                          handleLocationViewErrors(errors, 'contact')
                                            ? errors.contact.state
                                            : false
                                        }
                                        helperText={
                                          handleLocationViewErrors(errors, 'contact') &&
                                          errors.contact.state
                                            ? 'State is a required field'
                                            : ''
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4} sm={12}>
                                      <NumberField
                                        name="contact.zip"
                                        format="#####"
                                        label="Zip *"
                                        onChange={(values) => setValue('contact.zip', values.value)}
                                        error={
                                          handleLocationViewErrors(errors, 'contact')
                                            ? errors.contact.zip
                                            : false
                                        }
                                        helperText={
                                          handleLocationViewErrors(errors, 'contact') &&
                                          errors.contact.zip
                                            ? handlePhoneError(errors.contact.zip, 'Zip', 5)
                                            : ''
                                        }
                                        defaultValue={
                                          watchAllFields.contact && watchAllFields.contact.zip
                                            ? watchAllFields.contact.zip
                                            : ''
                                        }
                                        value={
                                          watchAllFields.contact && watchAllFields.contact.zip
                                            ? watchAllFields.contact.zip
                                            : ''
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4} sm={12}>
                                  <ThemeProvider theme={defaultDropDownTheme}>
                                    <StyledFormControl
                                      error={
                                        handleLocationViewErrors(errors, 'contact')
                                          ? errors.contact.timeZone
                                          : false
                                      }
                                    >
                                      <Controller
                                        as={
                                          <Dropdown
                                            listOptions={Array.from(Timezone)}
                                            label="Time Zone *"
                                          />
                                        }
                                        name="contact.timeZone"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: true }}
                                      />
                                      {handleLocationViewErrors(errors, 'contact') &&
                                      errors.contact.timeZone ? (
                                        <FormHelperText>
                                          Time Zone is a required field
                                        </FormHelperText>
                                      ) : null}
                                    </StyledFormControl>
                                  </ThemeProvider>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </StyledContentContainer>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              md={12}
              style={{ flex: '1 0 auto', maxHeight: '120px', minHeight: '120px' }}
            >
              <Grid container justify="space-between">
                <StyledFooter item>
                  <ThemeProvider theme={rocketLogoTheme}>
                    <CompanyLogo alt="Reveo" logo={MessageLogo} />
                  </ThemeProvider>
                </StyledFooter>
                <StyledFooter item>
                  <Grid container justify="flex-end">
                    <Grid item>
                      <ThemeProvider theme={OnBoardingButtonTheme}>
                        <CustomButton type="submit">
                          <StyledFowArrow />
                        </CustomButton>
                      </ThemeProvider>
                    </Grid>
                  </Grid>
                </StyledFooter>
              </Grid>
            </Grid>
          </form>
        </MUIThemeProvider>
      </Grid>
    </StyledGridContaienr>
  );
}

Step3.propTypes = {
  handleNext: Proptypes.func.isRequired,
};

export default Step3;
