import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { Facebook, Twitter, Instagram, LinkedIn, YouTube } from '@material-ui/icons';

const SocialMediaIcons = (props) => {
  const { socialLinks } = props;

  const useStyles = makeStyles(() => ({
    iconStyle: {
      size: '1.5rem',
      color: '#838383',
      transition: 'all 0.3s',
      '&:hover': {
        color: '#30feaf',
        transition: 'all 0.3s',
      },
    },
  }));

  const classes = useStyles();

  if (!socialLinks) {
    return null;
  }

  return (
    <div>
      {Object.keys(socialLinks)?.map((key, index) => {
        let IconComponent;

        switch (key) {
          case 'facebook':
            IconComponent = Facebook;
            break;
          case 'twitter':
            IconComponent = Twitter;
            break;
          case 'instagram':
            IconComponent = Instagram;
            break;
          case 'linkedin':
            IconComponent = LinkedIn;
            break;
          case 'youtube':
            IconComponent = YouTube;
            break;
          default:
            IconComponent = null;
        }

        return (
          IconComponent && (
            <IconButton key={index} href={socialLinks[key].value} target="_blank">
              <IconComponent className={classes.iconStyle} />
            </IconButton>
          )
        );
      })}
    </div>
  );
};

export default SocialMediaIcons;
