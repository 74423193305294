const socketState = {
  conversationContainer: [],
  previousConversations: [],
  shouldRefresh: false,
  selectedConversation: 0,
  documentTitle: 'Reveo',
  faviconHref: '',
  conversationCounts: {},
  allConversations: [],
  allClients: [],
  isConversationsLoading: false,
  campaignSendComplete: {},
  campaignSendUpdate: {},
  forceLogout: false,
};

export default socketState;
