import React from 'react';
import { Grid } from '@material-ui/core';
import MobilePreview from '../MobilePreview/MobilePreview';

function FeedbackView() {
  return (
    <MobilePreview>
      <Grid container>SendFeedback</Grid>
    </MobilePreview>
  );
}

FeedbackView.propTypes = {};

export default FeedbackView;
