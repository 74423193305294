import { useContext, useMemo, useState } from 'react';
import { GlobalContext } from '../Stores/GlobalStateStore';

const useDropDownTheme = () => {
  const { state } = useContext(GlobalContext);
  const { themeContainer } = state;
  const [theme, setTheme] = useState({});
  useMemo(() => {
    setTheme({
      dropDownBackGroundColor: themeContainer.dropDownBackGroundColor,
      dropdownColor: themeContainer.dropdownColor,
    });
  }, [themeContainer]);
  return {
    dropDownTheme: theme,
  };
};
export default useDropDownTheme;
