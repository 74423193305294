import React, { useCallback, useContext } from 'react';
import Proptypes from 'prop-types';
import { Grid, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CusotmAvatar from '../../CustomAvatar/CustomAvatar';
import StyledNavLink from '../../StyledNavLink/StyledNavLink';
import TimeLapsed from '../../TimeLapsed/TimeLapsed';
import baseUrl from '../../../utils/services/config';
import { errorChecker } from '../../../utils/helper/helper';
import { GlobalContext } from '../../../Stores/GlobalStateStore';
import useTextColors from '../../../hooks/useTextColors';
import useButtonTheme from '../../../hooks/useButtonTheme';

const StyledListItemContainer = styled.div`
  cursor: pointer !important;
  border-bottom: ${(props) => props.theme.borderBottom}!important;
  background: ${(props) => props.theme.background} !important;
  width: 100% !important;
`;

const StyledListItem = styled(ListItem)`
  cursor: pointer !important;
`;

const StyledName = styled(ListItemText)`
  & > span {
    font-weight: 500 !important;
    color: ${(props) => props.theme.color} !important;
  }

  & > p {
    color: ${(props) => props.theme.color} !important;
  }
`;

const StyledDotIcon = styled(FiberManualRecordIcon)`
  color: ${(props) => props.theme.color} !important;
  font-size: 14px !important;
`;

const avatarTheme = {
  width: '50px',
  height: '50px',
  lgWidth: '60px',
  lgHeight: '60px',
  margin: '0',
};

function NotificationList(props) {
  const {
    title,
    description,
    linkTitle,
    url,
    icon,
    createdAt,
    notificationContainer,
    notifications,
    index,
  } = props;

  const { state, dispatch } = useContext(GlobalContext);
  const { selectedLoaction } = state;
  const { textColors } = useTextColors();
  const { buttonTheme } = useButtonTheme();
  const { themeContainer } = state;

  const fetchNotifications = useCallback(async () => {
    try {
      if (selectedLoaction) {
        dispatch({ type: 'SET_NOTIFICATION_LOADER', payload: true });
        const result = await baseUrl(
          `/getnotifications/${localStorage.getItem('USER_ID')}/${selectedLoaction}`,
        );
        if (result.status === 200) {
          dispatch({ type: 'SET_NOTIFICATION_CONTAINER', payload: result.data });
          dispatch({ type: 'SET_NOTIFICATION_LOADER', payload: false });
        }
      }
    } catch (err) {
      dispatch({ type: 'SET_NOTIFICATION_LOADER', payload: false });
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  }, [selectedLoaction, dispatch]);

  const handleSeenNotification = async () => {
    try {
      const postBody = {
        user: parseInt(localStorage.getItem('USER_ID'), 10),
        notifications: notificationContainer[index].id,
        isSeen: true,
      };
      const result = await baseUrl.get(`/set-notification-seen/${notificationContainer[index].id}`);
      if (result.status === 200) {
        fetchNotifications();
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  return (
    <ThemeProvider
      theme={{
        background: 'transparent',
        borderBottom: notificationContainer.length - 1 === index ? '' : '1px solid #e8e7e7',
      }}
    >
      <StyledListItemContainer>
        <StyledListItem>
          <Grid container alignItems="center">
            <Grid item xs={1} md={1} lg={1} sm={1}>
              <ThemeProvider theme={{ color: themeContainer.conversationDotColor }}>
                <StyledDotIcon />
              </ThemeProvider>
            </Grid>
            <Grid item xs={2} md={2} lg={2} sm={2}>
              <ListItemAvatar>
                <ThemeProvider theme={avatarTheme}>
                  <CusotmAvatar
                    src={`${process.env.REACT_APP_IMAGE_ENDPOINT}${icon}`}
                    alt={title}
                  />
                </ThemeProvider>
              </ListItemAvatar>
            </Grid>
            <Grid item xs={7} md={7} lg={7} sm={7}>
              <ThemeProvider theme={{ color: textColors.fontColor3 }}>
                <StyledName primary={title} secondary={description} />
              </ThemeProvider>
              <Grid container justify="space-between">
                <Grid item>
                  <ThemeProvider theme={{ color: buttonTheme.buttonBgColor }}>
                    <StyledNavLink
                      onClick={() => handleSeenNotification(index)}
                      to={notifications.url}
                    >
                      {notifications.title}
                    </StyledNavLink>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} md={2} lg={2} sm={2} alignItems>
              <ThemeProvider theme={{ color: themeContainer.timeLapsedColor1 }}>
                <TimeLapsed time={createdAt} />
              </ThemeProvider>
            </Grid>
          </Grid>
        </StyledListItem>
      </StyledListItemContainer>
    </ThemeProvider>
  );
}

NotificationList.propTypes = {
  url: Proptypes.string,
  title: Proptypes.string,
  description: Proptypes.string,
  linkTitle: Proptypes.string,
  icon: Proptypes.string,
  createdAt: Proptypes.number,
  notificationContainer: Proptypes.array,
  index: Proptypes.number.isRequired,
};

NotificationList.defaultProps = {
  url: '',
  title: '',
  description: '',
  linkTitle: '',
  icon: '',
  createdAt: Date.now(),
};

export default NotificationList;
