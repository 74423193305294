const { withStyles } = require('@material-ui/styles');
const { Switch } = require('@material-ui/core');

const StyledIosSwitch = withStyles((theme) => ({
  root: {
    width: 50,
    height: 30,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 3,
    color: theme.switchCheckColor,
    '&$checked': {
      transform: 'translateX(20px)',
      color: theme.switchCheckColor,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.backgroundColor,
        borderColor: theme.backgroundColor,
      },
    },
  },
  thumb: {
    width: 24,
    height: 24,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid #DEDEDE`,
    borderRadius: 36 / 2,
    opacity: 1,
    backgroundColor: theme.switchTrackColor,
  },
  checked: {},
}))(Switch);

export default StyledIosSwitch;
