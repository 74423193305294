import { makeStyles } from '@material-ui/styles';
import Image from '../../assets/images/loginLeftSideImage.jpg';

export default makeStyles(() => ({
  formTitle: {
    fontSize: '8px',
    fontStyle: 'normal',
    lineHeight: 1.21,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#212121',
    marginTop: '25px',
  },
  loginTitle: {
    textAlign: 'center',
    marginBottom: '1em',
  },

  signUpFormGroup: {
    marginBottom: '20px',
  },
  submitbtn: {
    marginTop: '2em',
  },
  loginButton: {
    textTransform: 'capitalize',
  },
  formFields: {
    fontSize: 12,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.17,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#757575',
  },
  logotypeContainer: {
    width: '60%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${Image})`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `center`,
    backgroundSize: `cover`,
  },
  container: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  form: {
    width: '50%',
  },
  input: {
    fontSize: '14px',
    marginBottom: '10px',
  },
}));
