import React, { useCallback, useEffect, useState } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import { Grid, Typography } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import { makeStyles } from '@material-ui/core/styles';
import styled, { ThemeProvider } from 'styled-components';
import baseUrl from '../../utils/services/config';
import { convertIntoNumber, getParaByName } from '../../utils/helper/helper';
import ShowTimeAgo from '../../components/ConvertIntoTime/ShowTimeAgo';
import CustomButton from '../../components/CustomButton/CustomButton';

function ReviewCard() {
  const [reviews, setReviews] = useState([
    {
      rating: '',
      source: '',
      reviewOn: '',
      reviewText: '',
      reviewBy: '',
    },
  ]);
  const [settings, setSetttings] = useState({
    settings: {
      settings: {
        shape: 'rectangle',
        backgroundColor: '#ffffff',
        button: {
          fontSize: '12',
          bgColor: '#58e298',
          color: '#ffffff',
        },
        paragraph: {
          fontSize: '12',
          color: '#939393',
        },
        header: {
          Headerfontsize: '16',
          HeaderfontColour: '#37423e',
        },
      },
    },
  });

  const fetchReviews = useCallback(async () => {
    const result = await baseUrl(`micro-site-reviews?id=${getParaByName('id')}`);
    if (result.status === 200) {
      setReviews(result.data.list);
      if (Object.keys(result.data.settings).length) {
        setSetttings(result.data.settings);
      }
    }
  }, []);

  useEffect(() => {
    fetchReviews();
  }, [fetchReviews]);

  const useStyles = makeStyles(() => ({
    root: {
      paddingTop: '1vw',
    },
    square: {
      width: '374px',
      height: 'auto',
      borderRadius: '9px',
      minHeight: '150px',
      // maxHeight: '250px',
      padding: '1em',
      position: 'relative',
      backgroundColor: settings.settings.settings.backgroundColor
        ? `${settings.settings.settings.backgroundColor}`
        : '#FFFFFF',
    },
    oval: {
      backgroundColor: settings.settings.settings.backgroundColor
        ? `${settings.settings.settings.backgroundColor}`
        : '#FFFFFF',
      fontSize: '12px',
      width: '500px',
      height: '250px',
      borderRadius: '50%',
      boxShadow: '0px 3px 6px #00000029',
      padding: '2em',
      position: 'relative',
    },
    rectangle: {
      backgroundColor: settings.settings.settings.backgroundColor
        ? `${settings.settings.settings.backgroundColor}`
        : '#FFFFFF',
      fontSize: '12px',
      width: '374px',
      borderRadius: '2%',
      boxShadow: '0px 3px 6px #00000029',
      padding: '1em',
      position: 'relative',
    },
    Circle: {
      position: 'relative',
      backgroundColor: settings.settings.settings.backgroundColor
        ? `${settings.settings.settings.backgroundColor}`
        : '#FFFFFF',
      fontSize: '12px',
      borderRadius: '50%',
      boxShadow: '0px 3px 6px #00000029',
      padding: '4em',
      width: '300px',
      maxWidth: '400px',
      maxHeight: '400px',
      height: '300px',
    },
    CardContent: {
      padding: '0 !important',
      textAlign: 'center',
    },
    secondCardContent: {
      height: '50%',
      background: 'rgb(50, 167, 83)',
      padding: '0 !important',
      textAlign: 'center',
      position: 'relative',
    },
    cardAction: {
      fontSize: '12px',
      textAlign: 'left',
      padding: '7px 10px !important',
    },
    textContect: {
      padding: '7px !important',
      textAlign: 'left',
      fontSize: '12px',
      fontWeight: 'bold',
      display: 'flex',
    },
    reviewHeader: {
      padding: '12px !important',
      textAlign: 'left',
      fontSize: '22px',
      fontWeight: 'bold',
      display: 'flex',
      color: '#37423E',
      fontFamily: 'Montserrat !important',
    },
    connect: {
      padding: '9px !important',
      fontSize: '10px',
      textAlign: 'center',
      // fontWeight: 'bold',
      color: '#32b6ec',
    },
    paragraph: {
      fontSize: `${settings.settings.settings.paragraph.fontSize}px`,
      color: `${settings.settings.settings.paragraph.color}`,
      fontFamily: 'Montserrat',
      position: 'relative',
    },
    cardActionHeader: {
      color: `${
        settings.settings.settings.header
          ? settings.settings.settings.header.HeaderfontColour
          : '#37423E'
      }`,
      margin: '0',
      fontSize: `${
        settings.settings.settings.header
          ? settings.settings.settings.header.Headerfontsize
          : '18px'
      }px`,
      fontFamily: 'Montserrat, bold',
      fontWeight: 'bold',
    },
    tableConatiner: {
      padding: '8px !important',
    },
    imgConatiner: {
      height: '25px',
      width: '25px',
    },
    setting: {
      right: '7px',
      bottom: '7px',
      position: 'absolute',
      width: '13px',
    },
  }));

  const classes = useStyles();

  const btnTheme = {
    width: '21%',
    fontSize: `${settings.settings.settings.button.fontSize}px !important`,
    color: `${settings.settings.settings.button.color}`,
    margin: '0.5em 0  ',
    fontWeight: 'bold',
    background: `${settings.settings.settings.button.bgColor}`,
  };

  const ReviewSrc = styled.p`
    font-size: ${settings.settings.settings.paragraph.fontSize}px;
    color: ${settings.settings.settings.paragraph.color};
    margin: 1.3em 1.2em !important;
  `;

  const boxDesign = () => {
    if (settings.settings.settings.shape === 'circle') {
      return classes.Circle;
    }
    if (settings.settings.settings.shape === 'oval') {
      return classes.oval;
    }
    if (settings.settings.settings.shape === 'rectangle') {
      return classes.rectangle;
    }
    return classes.square;
  };

  const reviewsContent = reviews.map((data) => (
    <Card className={boxDesign()} style={{ margin: '1em 0' }}>
      <Grid
        conatiner
        justify="center"
        style={{ display: 'flex', height: '100%' }}
        direction="column"
      >
        <CardContent className={classes.textContect}>
          <ThemeProvider theme={btnTheme}>
            <CustomButton>
              <span>{convertIntoNumber(data.rating)}</span>

              <StarIcon
                style={{
                  fontSize: `${settings.settings.settings.button.fontSize}px`,
                  color: `${settings.settings.settings.button.color}`,
                }}
              />
            </CustomButton>
          </ThemeProvider>
          <ReviewSrc>
            {data.source}
            ,
            <ShowTimeAgo timeStampValue={data.reviewOn} />
          </ReviewSrc>
        </CardContent>
        <CardContent className={classes.cardAction}>
          <Typography className={classes.cardActionHeader}>{data.reviewBy}</Typography>
          <Typography className={classes.paragraph}>{data.reviewText}</Typography>
        </CardContent>
      </Grid>
    </Card>
  ));
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} style={{ height: '100%', overflow: 'auto' }}>
      {reviewsContent}
    </Grid>
  );
}

export default ReviewCard;
