import React from 'react';
import Proptypes from 'prop-types';
import { Grid, makeStyles, Popover, Button } from '@material-ui/core';
import UploaderButtons from '../../UploaderButtons/UploaderButtons';
import CustomButton from '../../CustomButton/CustomButton';

const useStyles = makeStyles((theme) => ({
  popover: {
    padding: '.5em',
    minWidth: '400px',
  },
  paper: {
    padding: theme.spacing(1),
    minWidth: '400px',
    background: theme.background,
  },
}));
const buttonTheme = {
  width: '100%',
  margin: '0',
};

function AttachmentPopover(props) {
  const {
    id,
    open,
    anchorEl,
    handleClose,
    businessCardFileName,
    otherCardFileName,
    handleBusinessCardChange,
    handleOtherAttachmentChange,
  } = props;

  const classes = useStyles();
  const getRenderContent = (businessCard, otherCard) => {
    if (businessCard) {
      return (
        <Grid item xs={8} sm={8} md={8} lg={8}>
          <UploaderButtons
            id="businessCard"
            handleChange={handleBusinessCardChange}
            title="Business Card"
            accept="image/*"
          />
        </Grid>
      );
    }
    if (otherCard) {
      return (
        <Grid item xs={8} sm={8} md={8} lg={8}>
          <UploaderButtons
            id="other-attchment"
            handleChange={handleOtherAttachmentChange}
            title="Other Attachment"
            accept="image/*"
            onClick={() => console.log('attachement should be added')}
          />
        </Grid>
      );
    }
    if (!businessCard && !otherCard) {
      return (
        <>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <CustomButton theme={buttonTheme} type="submit" onClick={handleBusinessCardChange}>
              Business Card
            </CustomButton>
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <UploaderButtons
              id="other-attchment"
              handleChange={handleOtherAttachmentChange}
              title="Other Attachment"
              accept="
              application/pdf,
              application/vnd.ms-powerpoint,
              application/vnd.openxmlformats-officedocument.wordprocessingml.document,
              application/vnd.openxmlformats-officedocument.presentationml.presentation"
              onClick={() => console.log('attachement should be added')}
            />
          </Grid>
        </>
      );
    }
    return null;
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      className={classes.popover}
      classes={{
        paper: classes.paper,
      }}
    >
      <Grid container alignItems="center" justifyContent="center">
        {getRenderContent(businessCardFileName, otherCardFileName)}
      </Grid>
    </Popover>
  );
}

// AttachmentPopover.propTypes = {
//   id: Proptypes.string.isRequired,
//   open: Proptypes.bool.isRequired,
//   anchorEl: Proptypes.node.isRequired,
//   handleClose: Proptypes.func.isRequired,
//   handleBusinessCardChange: Proptypes.func.isRequired,
//   handleOtherAttachmentChange: Proptypes.func.isRequired,
//   otherCardFileName: Proptypes.string.isRequired,
//   businessCardFileName: Proptypes.string.isRequired,
// };

export default AttachmentPopover;
