import React from 'react';
import Proptypes from 'prop-types';
import styled from 'styled-components';
import PublishIcon from '@material-ui/icons/Publish';

const StyledContainer = styled.div`
  align-self: center;
`;

const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    font-size: 3em !important;
    color: #bfbfbf !important;
  }
`;

const StyledTitle = styled.div`
  color: #bfbfbf !important;
  padding: 0.2em 0;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
`;

const StyledHeading = styled.h2`
  color: #bfbfbf !important;
  padding: 0.2em 0;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  margin: 0;
`;

function UploaderInput(props) {
  const { title, width, height, heading, icon } = props;
  const message = null;
  // width && height ? <StyledTitle>{`Ratio: ${width} X ${height}`}</StyledTitle> : null;
  const headingContent = heading ? <StyledHeading>{heading}</StyledHeading> : null;

  return (
    <StyledContainer>
      <StyledIcon>{icon}</StyledIcon>
      {headingContent}
      <StyledTitle>{title}</StyledTitle>
      {message}
    </StyledContainer>
  );
}

UploaderInput.propTypes = {
  title: Proptypes.string,
  width: Proptypes.number,
  height: Proptypes.number,
  heading: Proptypes.string,
  icon: Proptypes.node,
};

UploaderInput.defaultProps = {
  title: 'Upload or select',
  heading: '',
  icon: <PublishIcon />,
};

export default UploaderInput;
