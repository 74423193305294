import React, { useContext } from 'react';
import Proptypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import { styled } from '@material-ui/styles';
import { DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { ThemeProvider } from 'styled-components';
import CustomButton from '../../CustomButton/CustomButton';
import TransperentButtonTheme from '../../../themes/Button/TransperentButtonTheme';
import GenericPopup from '../GenricPopup/index';
import { GlobalContext } from '../../../Stores/GlobalStateStore';

const StyledDialogTitle = styled(DialogTitle)`
  letter-spacing: 1.08px;
  color: #6c6c6c;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 400 !important;
  & > h2 {
    letter-spacing: 1.08px;
    color: #6c6c6c;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 400 !important;
  }
`;

const StyledContentDialog = styled(DialogContent)`
  padding: 0 0.5em !important;
`;

function PrivacyLinkPopup(props) {
  const { show, handleClose } = props;
  const { state } = useContext(GlobalContext);
  const { themeContainer } = state;
  return (
    <GenericPopup open={show} fullWidth maxWidth="xl" onClose={handleClose}>
      <StyledDialogTitle id="scroll-dialog-title">
        <Grid container justify="flex-end">
          <Grid item>
            <ThemeProvider theme={TransperentButtonTheme}>
              <CustomButton onClick={handleClose} title="Close">
                <ClearIcon />
              </CustomButton>
            </ThemeProvider>
          </Grid>
        </Grid>
      </StyledDialogTitle>
      <StyledContentDialog>
        <h2 style={{ textAlign: 'center', color: themeContainer.fontColor2 }}>Privacy policy</h2>
        <p style={{ color: themeContainer.fontColor2 }}>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
          been the industrys standard dummy text ever since the 1500s, when an unknown printer took
          a galley of type and scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting, remaining essentially
          unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
          Lorem Ipsum passages, and more recently with desktop publishing software like Aldus
          PageMaker including versions of Lorem Ipsum.
        </p>
        <p style={{ color: themeContainer.fontColor2 }}>
          Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece
          of classical Latin literature from 45 BC, making it over 2000 years old. Richard
          McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the
          more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the
          cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum
          comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum (The Extremes of
          Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of
          ethics, very popular during the Renaissance. The first line of Lorem Ipsum, Lorem ipsum
          dolor sit amet.., comes from a line in section 1.10.32.
        </p>
        <p style={{ color: themeContainer.fontColor2 }}>
          It is a long established fact that a reader will be distracted by the readable content of
          a page when looking at its layout. The point of using Lorem Ipsum is that it has a
          more-or-less normal distribution of letters, as opposed to using Content here, content
          here, making it look like readable English. Many desktop publishing packages and web page
          editors now use Lorem Ipsum as their default model text, and a search for lorem ipsum will
          uncover many web sites still in their infancy. Various versions have evolved over the
          years, sometimes by accident, sometimes on purpose (injected humour and the like).
        </p>
        <p style={{ color: themeContainer.fontColor2 }}>
          There are many variations of passages of Lorem Ipsum available, but the majority have
          suffered alteration in some form, by injected humour, or randomised words which dont look
          even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be
          sure there isnt anything embarrassing hidden in the middle of text. All the Lorem Ipsum
          generators on the Internet tend to repeat predefined chunks as necessary, making this the
          first true generator on the Internet. It uses a dictionary of over 200 Latin words,
          combined with a handful of model sentence structures, to generate Lorem Ipsum which looks
          reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected
          humour, or non-characteristic words etc.
        </p>
        <p style={{ color: themeContainer.fontColor2 }}>
          Mauris vehicula felis id mi suscipit malesuada. Morbi a porttitor mi. Nulla et ante et
          libero vehicula vestibulum. Aenean fringilla lacinia dui, in ultricies orci scelerisque
          eget. Nam non venenatis arcu, et gravida nibh. Nunc id justo pharetra, rhoncus lorem a,
          dictum quam. Etiam id commodo libero, ut scelerisque lorem. Nulla placerat ac dolor in
          lacinia. Quisque ut quam tempus, fermentum ex in, feugiat ante. Donec lobortis, massa et
          elementum vulputate, orci sem dictum magna, vestibulum tempor tellus ligula eu nisi.
          Curabitur imperdiet ut mi sit amet mollis. Vivamus dapibus sagittis erat, id auctor erat
          aliquam eu. Donec commodo nibh nec orci sagittis ullamcorper at egestas sapien.
        </p>
        <p style={{ color: themeContainer.fontColor2 }}>
          Vestibulum ullamcorper arcu sit amet purus eleifend, sit amet mollis diam consectetur.
          Fusce eu dolor eleifend ipsum malesuada pretium ut at risus. Integer cursus nibh eros, at
          volutpat mauris feugiat nec. Fusce facilisis non est a bibendum. Pellentesque at hendrerit
          nisl. In a nisi fringilla, maximus mi posuere, placerat nulla. Praesent vulputate laoreet
          nibh sed fermentum. Phasellus et dolor congue, gravida turpis sed, dignissim lorem. Mauris
          venenatis lorem a dapibus aliquam. Ut faucibus felis eget urna pharetra, id ultricies
          turpis laoreet. Etiam magna eros, pulvinar id consectetur at, accumsan eget nisl. Quisque
          mattis ut dolor a sollicitudin.
        </p>
      </StyledContentDialog>
    </GenericPopup>
  );
}

PrivacyLinkPopup.propTypes = {
  show: Proptypes.bool.isRequired,
  handleClose: Proptypes.func,
};

export default PrivacyLinkPopup;
