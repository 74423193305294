import React, { useEffect, useState, useContext } from 'react';
import Proptypes from 'prop-types';
import { useHistory } from 'react-router';
import { ThemeProvider } from 'styled-components';
import CusotmTable from '../../components/CustomTable/CusotmTable';
import { ConvertIntoPostSitesTableFormat, errorChecker } from '../../utils/helper/helper';
import getPostSiteColumns from '../../Columns/PostSitesColumns';
import CusotomSearchBox from '../../components/CustomSearchBox/CustomSearchBox';
import Loader from '../../components/Loader/Loader';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import baseUrl from '../../utils/services/config';
import CusotmAlert from '../../components/CustomAlert/CustomAlert';
import useAlert from '../../hooks/useAlert';
import useSearch from '../../hooks/useSearch';
import CancellationPopup from '../../components/Popup/CancellationPopup/CancellationPopup';
import useServerPagination from '../../hooks/useServerPagination';
import useIconTheme from '../../hooks/useIconTheme';
import GenricPopup from '../../components/Popup/GenricPopup';
import useModal from '../../hooks/useModal';
import PostSites from './PostSites';

function PostSitesList(props) {
  const { postSiteStatusFilter } = props;
  const { state, dispatch } = useContext(GlobalContext);
  const { roles, themeContainer } = state;
  const { isSuperAdmin } = roles;
  const [postSitesList, setPostSitesList] = useState([]);
  const {
    paginationInfo,
    tableData,
    loader,
    handleRowPerPageChange,
    handlePageChange,
    handleTableSearch,
    searchLoader,
  } = useServerPagination({
    apiEndpoint: `/sites/list`,
    originalData: postSitesList,
    depends: localStorage.getItem('USER_ID'),
    secondParameter: 'deleted',
    secondParameterDepends: postSiteStatusFilter,
  });
  const { rowsPerPage, page, count } = paginationInfo;
  const { iconsTheme } = useIconTheme();

  const postSiteAlert = useAlert();
  const [deleteLoader, setDeleteloader] = useState(false);
  const { filteredData, clearSearch, handleSearch, searchValue } = useSearch({
    searchParameters: ['name'],
    data: postSitesList || [],
  });
  const [showModal, setshowModal] = useState(false);
  const [postSiteId, setPostSiteId] = useState();
  const editPostSite = useModal();

  const handleEdit = (dataIndex) => {
    // history.push(`/app/setup/post-sites/${postSitesList[dataIndex].id}`);
    console.log('trying to edit postsite ' + postSitesList[dataIndex].id);
    setPostSiteId(postSitesList[dataIndex].id);
    editPostSite.handleShowModal();
  };

  useEffect(() => {
    setPostSitesList(Array.from(ConvertIntoPostSitesTableFormat(tableData)));
  }, [tableData]);

  useEffect(() => {
    setPostSitesList([]);
  }, [rowsPerPage, postSiteStatusFilter]);

  const handleDelete = (dataIndex) => {
    localStorage.setItem('delete_row_id', dataIndex);
    setshowModal(true);
  };

  const handleClose = () => {
    setshowModal(false);
    localStorage.removeItem('delete_row_id');
  };

  const closeModal = () => {
    editPostSite.handleHideShowModal();
  };

  const showDeletionPopUp = async () => {
    const dataIndex = localStorage.getItem('delete_row_id');
    setDeleteloader(true);
    const { handleSetMessage, handleSetMessageType, handleShow } = postSiteAlert;
    const postSiteId = postSitesList[dataIndex].id;
    const postSitesListCopy = Array.from(postSitesList);
    postSitesListCopy.splice(dataIndex, 1);
    const postBody = {
      isDeleted: true,
    };
    try {
      const results = await baseUrl.patch(`/postsites/${postSiteId}`, { ...postBody });
      if (results.status === 200) {
        handleSetMessageType('success');
        handleSetMessage('Post-site deleted successfully');
        handleShow(true);
        handleClose();
        setPostSitesList(postSitesListCopy);
      }
    } catch (err) {
      setDeleteloader(false);
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    download: false,
    print: false,
    selectableRowsHeader: false,
    selectableRows: 'none',
    elevation: 0,
    viewColumns: false,
    filter: true,
    searchOpen: true,
    onSearchClose: false,
    selectToolbarPlacement: 'none',
    toolbar: {},
    count,
    page,
    textLabels: {
      body: {
        noMatch: loader ? <Loader /> : 'No data found !',
      },
    },

    onChangeRowsPerPage: (numberOfRows) => {
      handleRowPerPageChange(numberOfRows);
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handlePageChange(tableState.page);
          break;
        case 'search':
          handleTableSearch(tableState.searchText);
          break;
        default:
      }
    },
    customSearchRender: () => (
      <ThemeProvider theme={{ color: themeContainer.tableSearchBoxColor }}>
        <CusotomSearchBox
          loader={loader}
          placeholder="Search…"
          handleChange={handleSearch}
          clearSearch={clearSearch}
          searchValue={searchValue}
        />
      </ThemeProvider>
    ),
    rowsPerPage,
    rowsPerPageOptions: [5, 10, 15, 100],
  };

  const showLoader = searchLoader ? <Loader /> : null;

  return (
    <>
      {showLoader}
      <CusotmAlert
        message={postSiteAlert.message}
        handleClose={postSiteAlert.handleClose}
        open={postSiteAlert.show}
        messageType={postSiteAlert.messageType}
      />
      <CusotmTable
        data={filteredData}
        columns={getPostSiteColumns(handleEdit, isSuperAdmin, handleDelete, iconsTheme)}
        options={options}
      />
      <CancellationPopup
        show={showModal}
        showDeletionPopUp={showDeletionPopUp}
        handleClose={handleClose}
        deleteLoader={deleteLoader}
      />
      <GenricPopup
        open={editPostSite.showModal}
        maxWidth={'md'}
        handleClose={editPostSite.handleHideShowModal}
      >
        <PostSites postSiteId={postSiteId} handleClose={closeModal} />
      </GenricPopup>
    </>
  );
}

// PostSitesList.propTypes = {
//   postSiteStatusFilter: Proptypes.bool.isRequired,
// };

export default PostSitesList;
