import React, { createContext, useReducer } from 'react';
import Proptypes from 'prop-types';
import GlobalReducer from '../Reducers/GlobalReducer';
import globalState from '../state/GlobalState';

export const GlobalContext = createContext(globalState);

const GlobalStateStore = ({ children }) => {
  const [state, dispatch] = useReducer(GlobalReducer, globalState);
  return <GlobalContext.Provider value={{ state, dispatch }}>{children}</GlobalContext.Provider>;
};

GlobalStateStore.propTypes = {
  children: Proptypes.node.isRequired,
};

export default GlobalStateStore;
