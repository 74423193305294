import React, { useState, useContext, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import baseUrl from '../../utils/services/config';
import { convertIntoContactTable, errorChecker } from '../../utils/helper/helper';
import Loader from '../../components/Loader/Loader';
import CusotomSearchBox from '../../components/CustomSearchBox/CustomSearchBox';
import CustomersList from './CustomersList';
import CancellationPopup from '../../components/Popup/CancellationPopup/CancellationPopup';
import GenricPopup from '../../components/Popup/GenricPopup';
import AddClient from './AddClient';
import useIconTheme from '../../hooks/useIconTheme';
import useModal from '../../hooks/useModal';
import useAlert from '../../hooks/useAlert';
import { MessageContactSidebar } from '../../components/MessageContactSidebar/MessageContactSidebar';
import getContactColumns from '../../Columns/contactColumns';
import useServerSidePagination from '../../hooks/useServerPagination';
import { CustomersContext } from '../../Stores/CustomersStore';
import CusotmAlert from '../../components/CustomAlert/CustomAlert';
import useDebounce from '../../hooks/useDebounce';

function CustomerView() {
  const { state, dispatch } = useContext(GlobalContext);
  const [cusotmersState] = useContext(CustomersContext);
  const { customersContainer } = cusotmersState;
  const { selectedLoaction } = state;
  const customerAlert = useAlert();
  const [showModal, setshowModal] = useState(false);
  const [deleteLoader, setDeleteloader] = useState(false);
  const { iconsTheme } = useIconTheme();
  const [currentContact, setCurrentContact] = useState();
  const [isMessageContactSidebarExpanded, setIsMessageContactSidebarExpanded] = useState(
    localStorage.getItem('isMessageContactSidebarExpanded') === 'true',
  );

  const [customers, setCustomers] = useState([]);

  const [searchTerms, setSearchTerms] = useState({
    page: 0,
    count: 0,
    perPage: 20,
  });
  const [stringSearch, setStringSearch] = useState('');

  const debouncedSearchTerm = useDebounce(stringSearch, 500);

  const MINIMUN_RANGE_OF_STRING_TO_SEARCH = 3;

  const { searchLoader } = useServerSidePagination({
    apiEndpoint: `/client/contact`,
    depends: selectedLoaction,
    extraParameter: 'location',
    extraParameterDepends: selectedLoaction,
    originalData: customersContainer,
  });

  const [isLoading, setIsLoading] = useState(false);

  const editContact = useModal();

  const handleEdit = (dataIndex) => {
    setCurrentContact(customers[dataIndex].id);
    editContact.handleShowModal();
  };

  const handleDelete = (dataIndex) => {
    localStorage.setItem('delete_row_id', dataIndex);
    setshowModal(true);
  };

  const handleClose = () => {
    setshowModal(false);
    localStorage.removeItem('delete_row_id');
  };

  const handleMessageContact = (dataIndex) => {
    setCurrentContact(customers[dataIndex]);
    setIsMessageContactSidebarExpanded(true);
  };

  const showDeletionPopUp = async () => {
    const dataIndex = localStorage.getItem('delete_row_id');
    setDeleteloader(true);
    const { handleSetMessage, handleSetMessageType, handleShow } = customerAlert;
    if (Array.isArray(customers) && customers[dataIndex]) {
      const customerId = customers[dataIndex].id;
      if (customerId) {
        const customersCopy = [...customers];
        customersCopy.splice(dataIndex, 1);
        const postBody = {
          isDeleted: true,
        };
        try {
          const results = await baseUrl.patch(`/client/${customerId}`, postBody);
          if (results.status === 200) {
            handleSetMessageType('success');
            handleSetMessage('Customer deleted successfully');
            handleShow(true);
            setDeleteloader(false);
            handleClose();
            setCustomers(customersCopy);
          }
        } catch (err) {
          setDeleteloader(false);
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        }
      }
    }
  };

  const showLoader = searchLoader ? <Loader /> : null;

  const contactsMessage = {
    messages: [
      {
        source: 'text message',
      },
    ],
  };

  const cleanSearchTerms = () => {
    setSearchTerms((prevCounter) => ({
      ...prevCounter,
      page: 0,
      count: 0,
    }));
  };
  const clearSearchParameters = () => {
    setCustomers([]);
    cleanSearchTerms();
    setStringSearch('');
  };

  const handleChangeSearchBox = (e) => {
    setIsLoading(true);
    cleanSearchTerms();
    setStringSearch(e.target.value);
  };

  const getCustomers = (clean = false) => {
    setIsLoading(true);
    const location = state.selectedLoaction;
    const searchText = debouncedSearchTerm.length ? `&text=${debouncedSearchTerm}` : '';

    const urlParams = `location=${location}&count=${searchTerms.count}&page=${searchTerms.page}&offset=${searchTerms.perPage}${searchText}`;
    baseUrl
      .get(`/client/contact?${urlParams}`)
      .then((res) => {
        setTimeout(() => {
          setIsLoading(false);
          if (res.data.list.length) {
            setSearchTerms({
              ...searchTerms,
              page: res.data.pageMeta.currentPage + 1,
              count: res.data.pageMeta.total,
            });
            if (clean) {
              setCustomers([...Array.from(convertIntoContactTable(res.data.list))]);
            } else {
              setCustomers([
                ...customers,
                ...Array.from(convertIntoContactTable([...res.data.list])),
              ]);
            }
          }
        }, 500);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (debouncedSearchTerm.length >= MINIMUN_RANGE_OF_STRING_TO_SEARCH) {
      getCustomers(true);
    } else if (debouncedSearchTerm.length === 0) {
      getCustomers();
    }
  }, [debouncedSearchTerm]);
  useEffect(() => {
    if (state.selectedLoaction) {
      setCustomers([]);
      getCustomers();
    }
  }, [state.selectedLoaction]);

  return (
    <>
      {showLoader}
      <CusotmAlert
        message={customerAlert.message}
        handleClose={customerAlert.handleClose}
        open={customerAlert.show}
        messageType={customerAlert.messageType}
      />
      <div style={{ marginRight: isMessageContactSidebarExpanded ? '400px' : 0 }}>
        <ThemeProvider theme={{ color: state.themeContainer.tableSearchBoxColor }}>
          <CusotomSearchBox
            loader={isLoading}
            placeholder="Search Contacts..."
            handleChange={handleChangeSearchBox}
            clearSearch={clearSearchParameters}
            searchValue={stringSearch}
          />
        </ThemeProvider>
        <CustomersList
          data={customers}
          columns={getContactColumns(
            handleEdit,
            handleDelete,
            customers,
            iconsTheme,
            handleMessageContact,
          )}
          getCustomers={getCustomers}
          searchTerms={searchTerms}
          isLoading={isLoading}
        />
      </div>
      {isMessageContactSidebarExpanded && (
        <MessageContactSidebar
          contact={currentContact}
          handleToggleEditSidebar={() => setIsMessageContactSidebarExpanded(false)}
          handleShowEditPopover={handleEdit}
          handleMessageContact={() => handleMessageContact()}
          isMessageContactSidebarExpanded={isMessageContactSidebarExpanded}
          currentConversation={contactsMessage}
        />
      )}
      <CancellationPopup
        show={showModal}
        showDeletionPopUp={showDeletionPopUp}
        handleClose={handleClose}
        deleteLoader={deleteLoader}
      />
      <GenricPopup
        open={editContact.showModal}
        maxWidth="sm"
        handleClose={editContact.handleHideShowModal}
      >
        <AddClient currentContact={currentContact} handleClose={editContact.handleHideShowModal} />
      </GenricPopup>
    </>
  );
}

export default CustomerView;
