import React, { useState, useEffect, useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import getBusinessColumns from '../../Columns/BusinessColumns';
import CusotomSearchBox from '../../components/CustomSearchBox/CustomSearchBox';
import { ConvertIntoBusinessTableFormat, errorChecker } from '../../utils/helper/helper';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import CusotmAlert from '../../components/CustomAlert/CustomAlert';
import baseUrl from '../../utils/services/config';
import useAlert from '../../hooks/useAlert';
import CancellationPopup from '../../components/Popup/CancellationPopup/CancellationPopup';
import useIconTheme from '../../hooks/useIconTheme';
import useFetchBusiness from '../../hooks/useFetchBusiness';
import ReActivePopup from '../../components/Popup/ReActivePopup/ReActivePopup';
import useModal from '../../hooks/useModal';
import BusinessForm from './BusinessForm';
import GenricPopup from '../../components/Popup/GenricPopup';
import CustomersList from '../Customers/CustomersList';
import useDebounce from '../../hooks/useDebounce';

function BusinessContainer(props) {
  const { businessStatusFilter } = props;
  const { state, dispatch } = useContext(GlobalContext);
  const { selectedBusiness, themeContainer } = state;
  const businessAlert = useAlert();
  const [showModal, setshowModal] = useState(false);
  const [currentBusiness, setCurrentBusiness] = useState();
  const [deleteLoader, setDeleteloader] = useState(false);
  const [showReactiveModal, setShowReactiveModal] = useState(false);
  const [reactiveLoader, setReactiveLoader] = useState(false);
  const { iconsTheme } = useIconTheme();
  const { fetchBusiness } = useFetchBusiness();
  const [isLoading, setIsLoading] = useState(false);
  const [business, setBusiness] = useState([]);

  const [searchTerms, setSearchTerms] = useState({
    page: 0,
    count: 0,
    perPage: 30,
  });
  const [stringSearch, setStringSearch] = useState('');

  const debouncedSearchTerm = useDebounce(stringSearch, 500);
  const editBusiness = useModal();

  useEffect(() => {
    localStorage.removeItem('ROW_ID');
  }, [selectedBusiness]);

  const handleEdit = (dataIndex) => {
    setCurrentBusiness(business[dataIndex].id);
    editBusiness.handleShowModal();
  };

  const handleDelete = (dataIndex) => {
    localStorage.setItem('delete_row_id', dataIndex);
    setshowModal(true);
  };

  const handleClose = () => {
    setshowModal(false);
    localStorage.removeItem('delete_row_id');
  };

  const handleReactive = (dataIndex) => {
    localStorage.setItem('reActive_row_id', dataIndex);
    setShowReactiveModal(true);
  };

  const handleReActiveClose = () => {
    setShowReactiveModal(false);
    localStorage.removeItem('reActive_row_id');
  };

  const showDeletionPopUp = async () => {
    const dataIndex = localStorage.getItem('delete_row_id');
    setDeleteloader(true);
    const { handleSetMessage, handleSetMessageType, handleShow } = businessAlert;
    const businessId = business[dataIndex].id;
    const businessContainerCopy = Array.from(business);
    businessContainerCopy.splice(dataIndex, 1);
    const postBody = {
      isDeleted: true,
    };
    try {
      const results = await baseUrl.patch(`/business/${businessId}`, { ...postBody });
      if (results.status === 200) {
        handleSetMessageType('success');
        handleSetMessage('Business deleted successfully');
        handleShow(true);
        handleClose();
        fetchBusiness();
        setBusiness(businessContainerCopy);
        setDeleteloader(false);
      }
    } catch (err) {
      setDeleteloader(false);
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const showReactivePopUp = async () => {
    const dataIndex = localStorage.getItem('reActive_row_id');
    setReactiveLoader(true);
    const { handleSetMessage, handleSetMessageType, handleShow } = businessAlert;
    const businessId = business[dataIndex].id;
    const postBody = {
      isDeleted: false,
    };
    try {
      const results = await baseUrl.patch(`/business/${businessId}`, { ...postBody });
      if (results.status === 200) {
        handleSetMessageType('success');
        handleSetMessage('Business Re Activeted successfully');
        handleShow(true);
        handleReActiveClose();
        fetchBusiness();
      }
    } catch (err) {
      setReactiveLoader(false);
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };
  const handleCodeCopy = (dataIndex) => {
    navigator.clipboard.writeText(business[dataIndex].uuid);
  };

  const getBusiness = () => {
    const user = localStorage.getItem('USER_ID');
    const order = 'DESC';
    const deleted = businessStatusFilter;
    const { count, page, perPage } = searchTerms;
    const text = debouncedSearchTerm;
    // eslint-disable-next-line max-len
    const urlParams = `user=${user}&deleted=${deleted}&text=${text}&order=${order}&count=${count}&page=${page}&offset=${perPage}`;
    baseUrl
      .get(`/business/list?${urlParams}`)
      .then((res) => {
        setTimeout(() => {
          setIsLoading(false);
          if (res.data.list.length) {
            setSearchTerms((prevCounter) => ({
              ...prevCounter,
              page: res.data.pageMeta.currentPage + 1,
              count: res.data.pageMeta.total,
            }));
            setBusiness([
              ...business,
              ...Array.from(ConvertIntoBusinessTableFormat([...res.data.list])),
            ]);
          }
        }, 500);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        setIsLoading(false);
      });
  };

  const cleanSearchTerms = () => {
    setSearchTerms((prevCounter) => ({
      ...prevCounter,
      page: 0,
      count: 0,
    }));
  };
  const clearSearchParameters = () => {
    setIsLoading(true);
    setBusiness([]);
    cleanSearchTerms();
    setStringSearch('');
  };
  const cleanStateBusiness = () => {
    setBusiness([]);
  };
  const handleChangeSearchBox = (e) => {
    setIsLoading(true);
    setBusiness([]);
    cleanSearchTerms();
    setStringSearch(e.target.value);
  };
  useEffect(() => {
    cleanSearchTerms();
    cleanStateBusiness();
    getBusiness();
  }, [state.selectedLoaction]);

  useEffect(() => {
    cleanStateBusiness();
    setIsLoading(true);
    setSearchTerms((prevSearchTerms) => ({
      ...prevSearchTerms,
      count: 0,
      page: 0,
    }));

    setBusiness([]);
    getBusiness();
  }, [businessStatusFilter]);

  useEffect(() => {
    getBusiness();
  }, [debouncedSearchTerm]);

  return (
    <>
      <CusotmAlert
        message={businessAlert.message}
        handleClose={businessAlert.handleClose}
        open={businessAlert.show}
        messageType={businessAlert.messageType}
      />

      <ThemeProvider theme={{ color: themeContainer.tableSearchBoxColor }}>
        <CusotomSearchBox
          loader={isLoading}
          placeholder="Search…"
          handleChange={handleChangeSearchBox}
          clearSearch={() => clearSearchParameters()}
          searchValue={stringSearch}
        />
      </ThemeProvider>

      <CustomersList
        data={business}
        columns={getBusinessColumns(
          handleEdit,
          handleDelete,
          business,
          iconsTheme,
          handleCodeCopy,
          handleReactive,
        )}
        getCustomers={getBusiness}
        searchTerms={searchTerms}
        isLoading={isLoading}
      />
      <CancellationPopup
        show={showModal}
        showDeletionPopUp={showDeletionPopUp}
        handleClose={handleClose}
        deleteLoader={deleteLoader}
      />
      <ReActivePopup
        show={showReactiveModal}
        showReactivePopUp={showReactivePopUp}
        handleReActiveClose={handleReActiveClose}
        reactiveLoader={reactiveLoader}
      />
      <GenricPopup
        open={editBusiness.showModal}
        fullWidth
        maxWidth="lg"
        handleClose={editBusiness.handleHideShowModal}
      >
        <BusinessForm businessId={currentBusiness} handleClose={editBusiness.handleHideShowModal} />
      </GenricPopup>
    </>
  );
}

export default BusinessContainer;
