const reminderFrequency = [
  {
    label: 'None',
    value: '0',
  },
  {
    label: 'Every 6 Hours',
    value: '6',
  },
  {
    label: 'Every 12 Hours',
    value: '12',
  },
  {
    label: 'Every 18 Hours',
    value: '18',
  },
  {
    label: 'Every 24 Hours',
    value: '24',
  },
];

export default reminderFrequency;
