export const LocationStatusList = [
  { value: false, label: 'Active Locations' },
  { value: true, label: 'In-Active Locations' },
];
export const ReviewSitesList = [
  { value: false, label: 'Active Connections' },
  { value: true, label: 'In-Active  Connections' },
];

// const connectionList = [
//   { value: false, label: 'All' },
//   { value: 'standard', label: 'Standard' },
//   { value: 'premium', label: 'Premium' },
// ];

export const businessStatusList = [
  { value: 0, label: 'Active Business' },
  { value: 1, label: 'In-Active Business' },
];

export const postSiteStatusList = [
  { value: 0, label: 'Active Post-site' },
  { value: 1, label: 'In-Active Post-site' },
];

export const seoCategoryStatusList = [
  { value: 0, label: 'Active Seo-category' },
  { value: 1, label: 'In-Active Seo-category' },
];

export const industryStatusList = [
  { value: 0, label: 'Active Industry' },
  { value: 1, label: 'In-Active Industry' },
];

export const CampaignStatusList = [
  { value: 'all', label: 'All' },
  { value: 'review request', label: 'Review' },
  { value: 'survey', label: 'Survey' },
  { value: 'notification', label: 'Notification' },
  { value: 'message', label: 'Message' },
  { value: 'active', label: 'Active Templates' },
  { value: 'in-active', label: 'InActive Templates' },
  { value: 'deleted', label: 'Deleted Templates' },
];

export const employeeTypeList = [
  { value: false, label: 'Active Employee' },
  { value: true, label: 'InActive Employee' },
];

export const DashboardList = [
  { value: 0, label: 'Today' },
  { value: 7, label: '7 days' },
  { value: 30, label: '1 month' },
  { value: 90, label: '3 month' },
  { value: 180, label: '6 month' },
  { value: 365, label: 'Year' },
];
