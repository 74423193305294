import React from 'react';
import { makeStyles } from '@material-ui/styles';
import styled from 'styled-components';
import { Select } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 500,
  },
  '& label': {
    color: 'green',
  },
  '& .MuiInputLabel label.Mui-focused ': {
    color: '#0000008a !important',
  },
  '& .MuiMenuItem-root': {
    fontFamily: 'Montserrat !important', // Set font family for MenuItem
    fontSize: '16px !important', // Set font size for MenuItem
    color: 'blue !important', // Set text color for MenuItem
  },
}));

const StyledSelectBox = styled(Select)`
  width: -webkit-fill-available !important;
  font-size: 14px;
  input[type='number'] {
    -moz-appearance: textfield !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;

    margin: 0;
  }
  & > div {
    &:before {
      border: ${(props) => props.theme.border} !important;
      border-bottom: ${(props) => props.theme.borderBottom} !important;
    }
  }
  & > div > select {
    padding: ${(props) => props.theme.padding} !important;
    text-align: ${(props) => props.theme.textAlign} !important;
    font-size: 14px !important;
  }
`;

const StyledSelect = (props) => {
  const classes = useStyles();
  return <StyledSelectBox className={classes.root} {...props} />;
};

export default StyledSelect;
