import React from 'react';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import useTextColors from '../../hooks/useTextColors';
import CustomSwitch from '../CustomSwitch/CustomSwitch';

const SwitchHeader = styled.div`
  font-weight: 500;
  font-size: 14px;
  margin: 0.7rem 0 0.2rem 0;
`;

const SwitchDescription = styled.div`
  font-size: 11px;
`;

export const SettingsSwitch = (props) => {
  const { title, description, onChange, checked } = props;

  const { textColors } = useTextColors();

  return (
    <Box display="flex" justifyContent={'space-between'}>
      <Box>
        <SwitchHeader>{title}</SwitchHeader>
        <SwitchDescription style={{ color: textColors.descriptionTextColor }}>
          {description}
        </SwitchDescription>
      </Box>
      <Box>
        <CustomSwitch checked={checked} onChange={onChange} />
      </Box>
    </Box>
  );
};
export default SettingsSwitch;
