import React, { useContext, useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { Button, Grid, Box, DataGrid, ThemeProvider as MUIThemeProvider } from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import ArrowBackIcon from 'mdi-react/ArrowBackIcon';
import PageTitle from '../../../../PageTitle/PageTitle';
import Dropdown from '../../../../Dropdown/Dropdown';
import NoBorderDropDownTheme from '../../../../../themes/Dropdown/NoBorderDropDownTheme';
import GroupFilter from '../../../../Filters/GroupFilter';
import CustomTable2 from '../../../../CustomTable/CustomTable2';
import getMainGroupColumns from '../../../../../Columns/mainGroup';
import { getMainGroupColumnsSurvey } from '../../../../../Columns/mainGroup';
import { ConvertIntoLocationDropDown, getUniqueArray } from '../../../../../utils/helper/helper';
import useTable2Theme from '../../../../../hooks/useTable2Theme';
import useButtonTheme from '../../../../../hooks/useButtonTheme';
import CusotmTable from '../../../../CustomTable/CusotmTable';
import { GlobalContext } from '../../../../../Stores/GlobalStateStore';
import PrimaryButton from '../../../../Common/PrimaryButton';
import CustomButton from '../../../../CustomButton/CustomButton';

const wrapperStyles = { position: 'relative', padding: '1rem', overflow: 'hidden' };
const containerStyles = { margin: '.5rem .5rem 1rem .5rem', height: '55vh' };
const footerStyles = { display: 'flex', justifyContent: 'flex-end', gap: '1rem' };

function PreviewList(props) {
  const {
    handleNext,
    title,
    disableLocations,
    isPreviewSurvey,
    listViewData,
    handlepressSave,
    handlepressCancel,
    changeStep,
  } = props;
  const [columnsWidth, setColumnsWidth] = useState(12);
  const [toggleFilterView, setToggleFilterView] = useState(false);
  const { state, dispatch } = useContext(GlobalContext);
  const { groupContainer, locations } = state;
  const { customTable2Theme } = useTable2Theme();
  const { buttonTheme } = useButtonTheme();

  const StyledOutlineButton = styled(Button)`
    text-transform: capitalize !important;
    letter-spacing: unset !important;
    font-family: 'Montserrat' !important;
    font-weight: 600 !important;
  `;
  useEffect(() => {
    // finalGroupData
    dispatch({
      type: 'SET_FINAL_GROUP_CONTAINER',
      payload: groupContainer.listViewData.concat(groupContainer.existingClientContainer),
    });
  }, []);

  // Effect to handle view Toggling

  useEffect(() => {
    if (toggleFilterView) {
      setColumnsWidth(9);
    } else {
      setColumnsWidth(12);
    }
  }, [toggleFilterView]);

  const handleSelectLocation = (e) => {
    dispatch({ type: 'SET_GROUP_LOCATION', payload: e.target.value });
  };
  const groupViewContent = toggleFilterView ? <GroupFilter /> : null;
  const [rowsSelected, setRowsSelected] = useState([]);

  const groupContainerListViewData = isPreviewSurvey
    ? listViewData
    : getUniqueArray(groupContainer.listViewData.concat(groupContainer.existingClientContainer));

  const groupContainerColumns = isPreviewSurvey
    ? getMainGroupColumnsSurvey()
    : getMainGroupColumns();

  const options = {
    filterType: 'checkbox',
    responsive: 'standard',
    selectableRows: 'multiple',
    elevation: 0,
    viewColumns: false,
    toolbar: false,
    tableBodyHeight: '100%',
    count: 0,
    fixedSelectColumn: true,
    fixedHeader: true,
    rowsSelected: rowsSelected,
    onChangeRowsPerPage: () => {},
    customToolbarSelect: () => {},
    onRowsSelect: (currentRowsSelected, allRowsSelected) => {
      setRowsSelected(allRowsSelected.map((row) => row.dataIndex));
    },

    //rowsPerPageOptions: [5, 10, 15],
  };

  const filterSelectedList = () => {
    const data = rowsSelected.map((row) => {
      return listViewData[row].data;
    });

    handlepressSave(data);
  };

  useEffect(() => {
    if (listViewData.length) {
      const selectAllRows = [];
      listViewData.forEach((data, index) => {
        selectAllRows.push(index);
      });
      setRowsSelected(selectAllRows);
    }
  }, []);

  return (
    <Box style={wrapperStyles}>
      <Box style={containerStyles}>
        <Box margin=".5rem 0 1rem 0">
          <PageTitle title={title} />
        </Box>
        <Box style={{ overflowY: 'auto', maxHeight: 'calc(55vh - 40px)' }}>
          <MUIThemeProvider theme={customTable2Theme}>
            <CusotmTable
              data={groupContainerListViewData}
              columns={groupContainerColumns}
              options={options}
              renderElementsPerPage={4}
            />
          </MUIThemeProvider>
        </Box>
      </Box>
      <Box style={footerStyles}>
        <PrimaryButton text onClick={filterSelectedList}>
          Cancel
        </PrimaryButton>
        <PrimaryButton onClick={filterSelectedList}>Save</PrimaryButton>
      </Box>
      {/* {groupViewContent} */}
    </Box>
  );
}

PreviewList.defaultProps = {
  title: 'Send Campaigns',
  disableLocations: false,
  isPreviewSurvery: false,
  listViewData: [],
  handlepressSave: () => {},
  handlepressCancel: () => {},
  changeStep: () => {},
};

PreviewList.propTypes = {
  handleNext: Proptypes.func.isRequired,
  handlepressSave: Proptypes.func,
  handlepressCancel: Proptypes.func,
  changeStep: Proptypes.func,
  title: Proptypes.string,
  disableLocations: Proptypes.bool,
  isPreviewSurvery: Proptypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  listViewData: Proptypes.arrayOf(Proptypes.object),
};

export default React.memo(PreviewList);
