const DashboardReducer = (state, action) => {
  switch (action.type) {
    case 'INIT_CONTAINER': {
      return {
        ...state,
        recentReviewContainer: [],
        ReviewSummaryContainer: [],
        userGoalContainer: {},
        allUserGoalContainer: [],
        leaderBoard: {},
      };
    }

    case 'SET_RECENT_REVIEW':
      return {
        ...state,
        recentReviewContainer: Array.from(action.payload),
      };
    case 'SET_REVIEW_SUMMARY':
      return {
        ...state,
        ReviewSummaryContainer: Array.from(action.payload),
      };
    case 'SET_USER_GOALS':
      return {
        ...state,
        userGoalContainer: action.payload,
      };

    case 'SET_USERS':
      // eslint-disable-next-line no-case-declarations
      const stateCopy = { ...state };

      stateCopy.allUserGoalContainer.filter((element, index) => {
        if (element.user === action.payload.id) {
          stateCopy.allUserGoalContainer[index].userdata = action.payload;
        }
        return null;
      });
      return {
        ...state,
        ...stateCopy,
        userloader: false,
      };

    case 'SET_ALL_USER_GOALS':
      return {
        ...state,
        allUserGoalContainer: action.payload,
      };

    case 'SET_LEADER_BOARD':
      return {
        ...state,
        leaderBoard: { ...action.payload },
      };

    case 'SET_AVG_RATING':
      return {
        ...state,
        avgRating: Array.from(action.payload),
      };

    case 'SHOW_LOADER':
      return {
        ...state,
        loader: true,
      };

    case 'HIDE_LOADER':
      return {
        ...state,
        loader: false,
      };
    case 'SHOW_REVIEWSUMMARY_LOADER':
      return {
        ...state,
        reviewLoader: true,
      };
    case 'HIDE_REVIEWSUMMARY_LOADER':
      return {
        ...state,
        reviewLoader: false,
      };

    default:
      return state;
  }
};

export default DashboardReducer;
