import React from 'react';
import Proptypes from 'prop-types';
import styled from 'styled-components';
import { Dialog, DialogContent, ThemeProvider as MUIThemeProvider } from '@material-ui/core';
import usePopupTheme from '../../hooks/usePopupTheme';

const StyledDialog = styled(Dialog)`
  overflow-x: hidden !important;
  min-height: 100vh !important;
`;

const StyledContentDialog = styled(DialogContent)`
  padding: ${(props) => (props.theme.padding ? props.theme.padding : '0 0.5em ')}!important;
  display: flex;
  align-item: center;
  justify-content: center;
  overflow-x: hidden !important;
  overflow-y: ${(props) => props.theme.overflowY} !important;
`;

function OnBoardingPopUp(props) {
  const { show, handleClose, children, fullScreen } = props;
  const { popUpTheme } = usePopupTheme();

  return (
    <MUIThemeProvider theme={popUpTheme}>
      <StyledDialog
        open={show}
        fullWidth
        fullScreen={fullScreen}
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <StyledContentDialog>{children}</StyledContentDialog>
      </StyledDialog>
    </MUIThemeProvider>
  );
}

OnBoardingPopUp.propTypes = {
  show: Proptypes.bool.isRequired,
  handleClose: Proptypes.func.isRequired,
  children: Proptypes.node.isRequired,
  fullScreen: Proptypes.bool,
};

OnBoardingPopUp.defaultProps = {
  fullScreen: false,
};

export default OnBoardingPopUp;
