import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Typography, Grid, FormHelperText, InputAdornment, IconButton } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Dropdown from '../../components/Dropdown/Dropdown';
import useStyles from './style';
import GeneralInputField from '../../components/GeneralInputField/GeneralInputField';
import CustomButton from '../../components/CustomButton/CustomButton';
import baseUrlLogin from '../../utils/services/auth.config';
import { passwordRegex, emailRegEx } from '../../utils/helper/regEx';
import {
  handleResetPasswordError,
  handlePhoneError,
  handleEmailError,
} from '../../utils/helper/helper';
import useShowPassword from '../../state/ShowPassword';
import CusotmAlert from '../../components/CustomAlert/CustomAlert';
import ButtonLoader from '../../components/ButtonLoader/ButtonLoader';
import PhoneNumberField from '../../components/NumberField/NumberField';
import StyledFormControl from '../../components/StyledFormControl';
import useButtonTheme from '../../hooks/useButtonTheme';

const StyledGridContainer = styled(Grid)`
  text-align: center;
`;

const dropDownTheme = {
  maxWidth: '100%',
  background: '#fff',
  margin: '.5em 0',
};

const StyledFormContainer = styled.div`
  width: 60%;
  min-height: 100vh;
  height: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  flex-direction: column;

  // @media screen and (min-width: 1920px) {
  //   & {
  //     justify-content: center;
  //   }
  // }
`;

const buttonTheme1 = {
  boxShadow: '5px 2px 14px -3px',
};

const inputFieldTheme = {
  inputMargin: '.25em 0',
};

function Registration() {
  const { handleSubmit, register, errors, control, setValue, setError } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      companyName: '',
      phoneNumber: '',
      emailAddress: '',
      industry: '',
    },
  });
  const classes = useStyles();
  const [industries, setIndustries] = useState([]);
  const [showAlert, setAlert] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const { handlePasswordViewToggle, passwordInfo } = useShowPassword({
    password: false,
    confirmPassword: false,
  });
  const { buttonTheme } = useButtonTheme();

  useEffect(() => {
    baseUrlLogin.get('/industry?isDeleted=false').then((res) => {
      if (res.status === 200) {
        const industryCopy = [];
        res.data.map((item) => industryCopy.push({ label: item.name, value: item.id }));
        setIndustries(industryCopy);
      }
    });
  }, []);

  useEffect(() => {
    register({ name: 'phoneNumber' }, { required: true, minLength: 10 });
  }, [register]);

  const onSubmit = (data) => {
    const body = {
      firstName: data.firstName,
      lastName: data.lastName,
      companyName: data.companyName,
      emailAddress: data.emailAddress,
      password: data.password,
      confirmPassword: data.confirmPassword,
      industry: data.industry,
      phoneNumber: `+1${data.phoneNumber}`,
    };

    if (data.password !== data.confirmPassword) {
      setError('password', {
        type: 'manual',
        types: '',
        message: 'Password and confirm password must be same',
      });
      setError('confirmPassword', {
        type: 'manual',
        types: '',
        message: 'Password and confirm password must be same',
      });
    } else {
      setLoader(true);
      const userData = { ...body };
      const industryValue = [parseInt(userData.industry, 10)];
      userData.industry = industryValue;

      baseUrlLogin
        .post('/account/signup', { ...userData })
        .then(() => {
          setLoader(false);
          setAlert(true);
          setTimeout(() => {
            setAlert(false);
            history.push('/');
          }, 2000);
        })
        .catch(() => {
          setLoader(false);
          setServerError(true);
          setAlert(true);
        });
    }
  };

  const handleCloseAlert = () => {
    setAlert(false);
    setTimeout(() => {
      setServerError(false);
    }, 3000);
  };

  const showLoader = loader ? <ButtonLoader /> : null;

  return (
    <>
      <ThemeProvider theme={inputFieldTheme}>
        <div className={classes.container}>
          <CusotmAlert
            open={showAlert}
            message={serverError ? 'User already exists' : 'Activation link sent successfully'}
            handleClose={handleCloseAlert}
            messageType={serverError ? 'error' : 'success'}
          />
          <div className={classes.logotypeContainer}> </div>
          <StyledFormContainer className={classes.formSection}>
            <div className={classes.formTitle}>
              <Typography variant="h6" className={classes.loginTitle}>
                Tell us about yourself to get a free demo.
              </Typography>
            </div>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
              <div className={classes.signUpFormGroup}>
                <GeneralInputField
                  name="firstName"
                  label="First name"
                  inputProps={{ 'aria-label': 'description' }}
                  inputRef={register({
                    required: true,
                    validate: (value) => value.trim() !== '',
                  })}
                  error={errors.firstName}
                  helperText={errors.firstName ? 'First name is a required field' : ''}
                />
              </div>

              <div className={classes.signUpFormGroup}>
                <GeneralInputField
                  name="lastName"
                  label="Last name"
                  inputProps={{ 'aria-label': 'description' }}
                  inputRef={register({ required: true, validate: (value) => value.trim() !== '' })}
                  error={errors.lastName}
                  helperText={errors.lastName ? 'Last name is a required field' : ''}
                />
              </div>
              <div className={classes.signUpFormGroup}>
                <GeneralInputField
                  name="companyName"
                  label="Company name"
                  inputProps={{ 'aria-label': 'description' }}
                  inputRef={register({ required: true, validate: (value) => value.trim() !== '' })}
                  error={errors.companyName}
                  helperText={errors.companyName ? 'Company name is a required field' : ''}
                />
              </div>
              <div className={classes.signUpFormGroup}>
                <PhoneNumberField
                  name="phoneNumber"
                  format="+1 ### ### ####"
                  label="Phone Number"
                  cusotmInputField={GeneralInputField}
                  onChange={(values) => setValue('phoneNumber', values.value)}
                  error={errors.phoneNumber}
                  helperText={
                    errors.phoneNumber ? handlePhoneError(errors.phoneNumber, 'Phone Number') : ''
                  }
                />
              </div>
              <div className={classes.signUpFormGroup}>
                <GeneralInputField
                  name="emailAddress"
                  type="email"
                  label="Email"
                  inputProps={{ 'aria-label': 'description' }}
                  inputRef={register({
                    required: true,
                    pattern: emailRegEx,
                    validate: (value) => value.trim() !== '',
                  })}
                  error={errors.emailAddress}
                  helperText={handleEmailError(errors.emailAddress)}
                />
              </div>
              <div className={classes.signUpFormGroup}>
                <GeneralInputField
                  name="password"
                  type={passwordInfo.password ? 'text' : 'password'}
                  error={!!errors.password}
                  label="Password"
                  inputRef={register({
                    required: true,
                    pattern: passwordRegex,
                    validate: (value) => value.trim() !== '',
                  })}
                  helperText={
                    errors.password ? handleResetPasswordError(errors.password, 'Password') : null
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handlePasswordViewToggle('password')}
                        >
                          {passwordInfo.password ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className={classes.signUpFormGroup}>
                <GeneralInputField
                  name="confirmPassword"
                  type={passwordInfo.confirmPassword ? 'text' : 'password'}
                  error={!!errors.confirmPassword}
                  label="Confirm password"
                  inputRef={register({
                    required: true,
                    pattern: passwordRegex,
                    validate: (value) => value.trim() !== '',
                  })}
                  helperText={
                    errors.confirmPassword
                      ? handleResetPasswordError(errors.confirmPassword, 'Password')
                      : null
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handlePasswordViewToggle('confirmPassword')}
                        >
                          {passwordInfo.confirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className={classes.signUpFormGroup}>
                <ThemeProvider theme={dropDownTheme}>
                  <StyledFormControl error={!!errors.industry}>
                    <Controller
                      as={<Dropdown listOptions={Array.from(industries)} label="Select Industry" />}
                      name="industry"
                      control={control}
                      rules={{ required: true }}
                      defaultValue=""
                    />
                    {errors.industry ? (
                      <FormHelperText>Industry is a required field</FormHelperText>
                    ) : null}
                  </StyledFormControl>
                </ThemeProvider>
              </div>
              <Grid container justify="center" alignItems="center">
                <StyledGridContainer item xs={12} sm={12} lg={12} md={12}>
                  <ThemeProvider
                    theme={{
                      ...buttonTheme1,
                      background: buttonTheme.buttonBgColor,
                      color: buttonTheme.buttonColor1,
                    }}
                  >
                    <CustomButton type="submit">
                      Submit
                      {showLoader}
                    </CustomButton>
                  </ThemeProvider>
                </StyledGridContainer>
                <StyledGridContainer item xs={12} sm={12} lg={12} md={12}>
                  <Link to="/login">Back to login </Link>
                </StyledGridContainer>
              </Grid>
            </form>
          </StyledFormContainer>
        </div>
      </ThemeProvider>
    </>
  );
}
export default Registration;
