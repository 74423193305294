import React from 'react';
import { Button } from '@material-ui/core';
import styled from 'styled-components';

const StyledPrimaryButton = styled(Button)`
  background-color: #30feaf !important;
  text-transform: capitalize !important;
  letter-spacing: unset !important;
  font-weight: 500 !important;
  padding: 6px 18px !important;
  border-radius: 10px !important;
  cursor: pointer !important;
  font-family: 'Montserrat' !important;
  width: ${(props) => (props.fullWidth ? '100%' : '')} !important;

  &:disabled {
    background-color: #eaeaea !important;
  }
`;

const StyledOutlineButton = styled(Button)`
  text-transform: capitalize !important;
  letter-spacing: unset !important;
  font-weight: 500 !important;
  padding: 6px 18px !important;
  border-radius: 10px !important;
  font-family: 'Montserrat' !important;
`;

const StyledTextButton = styled(Button)`
  text-transform: capitalize !important;
  letter-spacing: unset !important;
  font-weight: 500 !important;
  padding: 6px 18px !important;
  cursor: pointer !important;
  font-family: 'Montserrat' !important;
`;

function PrimaryButton(props) {
  const { outlined, text, onClick, label, type, children, disabled, id, icon, fullWidth } = props;

  if (outlined) {
    return (
      <StyledOutlineButton
        variant="outlined"
        onClick={onClick}
        title={label}
        disabled={disabled}
        type={type}
        id={id}
        startIcon={icon}
        fullWidth={fullWidth}
      >
        {children}
      </StyledOutlineButton>
    );
  } else if (text) {
    return (
      <StyledTextButton
        id={id}
        onClick={onClick}
        title={label}
        disabled={disabled}
        type={type}
        startIcon={icon}
        fullWidth={fullWidth}
      >
        {children}
      </StyledTextButton>
    );
  } else {
    return (
      <StyledPrimaryButton
        id={id}
        onClick={onClick}
        title={label}
        disabled={disabled}
        type={type}
        startIcon={icon}
        fullWidth={fullWidth}
      >
        {children}
      </StyledPrimaryButton>
    );
  }
}

export default PrimaryButton;
