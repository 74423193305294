import React from 'react';
import styled from 'styled-components';

const StyledMainView = styled.div`
  background: ${(props) => props.theme.bgColor};
  overflow: hidden auto;
  height: calc(100vh - 60px);
  width: 100vw;

  @media screen and (max-width: 960px) {
    min-width: 100% !important;
    max-width: 100% !important;
  }
`;

function MainView(props) {
  const { children } = props;
  return (
    <StyledMainView item xs={12} sm={12} md={10} lg={10}>
      {children}
    </StyledMainView>
  );
}

MainView.defaultProps = {};

export default React.memo(MainView);
