import React, { useContext, useState, useEffect } from 'react';
import Proptypes from 'prop-types';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  ThemeProvider as MUIThemeProvider,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import styled, { ThemeProvider } from 'styled-components';
import ClearIcon from '@material-ui/icons/Clear';
import baseUrl from '../../utils/services/config';
import { errorChecker } from '../../utils/helper/helper';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import ButtonLoader from '../ButtonLoader/ButtonLoader';
import CustomButton from '../CustomButton/CustomButton';
import CusotmAlert from '../CustomAlert/CustomAlert';
import Loader from '../Loader/Loader';
import StyledTextField from '../GeneralInputField/GeneralInputField';
import useInputFieldTheme from '../../hooks/useInputFieldTheme';
import GenericPopup from '../Popup/GenricPopup';
import useButtonTheme from '../../hooks/useButtonTheme';

const StyledContentDialog = styled(DialogContent)`
  padding: 0 0.5em !important;
`;

const StyledActions = styled(DialogActions)`
  padding: 0 !important;
`;

const buttonTheme1 = {
  background: 'transparent !important',
  margin: '0 ',
};

function SeoCategoryPopup(props) {
  const { open, handleClose, selectedSEO } = props;
  const { register, handleSubmit, errors, setValue } = useForm();
  const [showAlert, setAlert] = useState(false);
  const [loader, setLoader] = useState(false);
  const [renderView, setRenderView] = useState(true);
  const title = selectedSEO ? 'Edit Seocategory' : 'Add Seocategory';
  const { dispatch } = useContext(GlobalContext);
  const { inputTheme } = useInputFieldTheme();
  const { buttonTheme } = useButtonTheme();

  useEffect(() => {
    if (selectedSEO) {
      setRenderView(false);
      baseUrl.get(`/seoCategory/${selectedSEO}`).then((res) => {
        if (res.status === 200) {
          setRenderView(true);
          setValue('name', res.data.name);
          setValue('synupId', res.data.synupId);
          setValue('synupDatabaseId', res.data.synupDatabaseId);
        }
      });
    }
  }, [selectedSEO, setValue]);

  const onSubmit = (data) => {
    const body = {
      isDeleted: 0,
      name: data.name,
      synupId: data.name,
      synupDatabaseId: Math.floor(Math.random() * 90000) + 10000,
      synupIsPrimary: 1,
      createdBy: parseInt(localStorage.getItem('USER_ID'), 10),
    };

    setLoader(true);
    if (selectedSEO) {
      baseUrl
        .patch(`seoCategory/${selectedSEO}`, { ...body })
        .then(() => {
          setLoader(false);
          setAlert(true);
          setTimeout(() => {
            setAlert(false);
            handleClose();
          }, 1000);
        })
        .catch((err) => {
          setLoader(false);
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    } else {
      baseUrl
        .post(`/seoCategory`, { ...body })
        .then(() => {
          setLoader(false);
          setAlert(true);
          setTimeout(() => {
            setAlert(false);
            handleClose();
          }, 1000);
        })
        .catch((err) => {
          setLoader(false);
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    }
  };

  const handleAlertClose = () => setAlert(false);

  const showLoader = loader ? <ButtonLoader /> : null;

  return renderView ? (
    <>
      <CusotmAlert
        open={showAlert}
        message={
          selectedSEO ? 'Seocategory updated successfully' : 'Seocategory created successfully'
        }
        handleClose={handleAlertClose}
      />
      <GenericPopup open={open} onClose={handleClose} fullWidth aria-labelledby="form-dialog-title">
        <DialogTitle>
          <Grid container justify="space-between">
            <Grid item>{title}</Grid>
            <Grid item>
              <ThemeProvider theme={buttonTheme1}>
                <CustomButton onClick={handleClose} title="Close">
                  <ClearIcon />
                </CustomButton>
              </ThemeProvider>
            </Grid>
          </Grid>
        </DialogTitle>
        <MUIThemeProvider theme={inputTheme}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <StyledContentDialog>
              <Grid container justify="center">
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <StyledTextField
                    name="name"
                    onBlur={(e) => setValue('name', e.target.value.trim())}
                    label="Please enter a seocategory *"
                    inputRef={register({
                      required: true,
                      validate: (value) => value.trim() !== '',
                    })}
                    error={errors.name}
                    helperText={errors.name ? 'Seocategory is a required field' : null}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={12} md={8} lg={8}>
                  <StyledTextField
                    name="synupId"
                    type="number"
                    label="Please enter a syncup id *"
                    inputRef={register({
                      // required: true,
                      validate: (value) => value.trim() !== '',
                    })}
                    error={errors.name}
                    helperText={errors.name ? 'Seocategory is a required field' : null}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <StyledTextField
                    name="synupDatabaseId"
                    type="number"
                    label="Please enter a syncup database id *"
                    inputRef={register({
                      // required: true,
                      validate: (value) => value.trim() !== '',
                    })}
                    error={errors.name}
                    helperText={errors.name ? 'Seocategory is a required field' : null}
                  />
                </Grid> */}
              </Grid>
            </StyledContentDialog>
            <StyledActions>
              <Grid container justify="center">
                <Grid item xs={12} sm={12} md={3} lg={4}>
                  <ThemeProvider
                    theme={{
                      color: buttonTheme.buttonColor1,
                      background: buttonTheme.buttonBgColor,
                    }}
                  >
                    <CustomButton type="submit" color="primary" disabled={loader}>
                      {selectedSEO ? 'Update' : 'Add'}
                      {showLoader}
                    </CustomButton>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </StyledActions>
          </form>
        </MUIThemeProvider>
      </GenericPopup>
    </>
  ) : (
    <Loader />
  );
}

SeoCategoryPopup.propTypes = {
  open: Proptypes.bool.isRequired,
  handleClose: Proptypes.func.isRequired,
  selectedSEO: Proptypes.number,
};

export default React.memo(SeoCategoryPopup);
