import React, { useState, useContext } from 'react';
import Proptypes from 'prop-types';
import { ThemeProvider as MUIThemeProvider, Box, Button, FormControl } from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import { useForm } from 'react-hook-form';
import ButtonLoader from '../ButtonLoader/ButtonLoader';
import baseUrl from '../../utils/services/config';
import { errorChecker } from '../../utils/helper/helper';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import CusotmAlert from '../CustomAlert/CustomAlert';
import StyledTextField from '../GeneralInputField/GeneralInputField';
import GenericPopup from '../Popup/GenricPopup/index';
import useInputFieldTheme from '../../hooks/useInputFieldTheme';
import useButtonTheme from '../../hooks/useButtonTheme';
import { FormHeader } from '../Forms/FormHeader';
import renderHTML from 'react-render-html';
import CusotmAvatar from '../CustomAvatar/CustomAvatar';
import {
  getPlaceholderText,
  getPostReviewLink,
  getListReviewLink,
} from './reviewLinkPopup.logic.js';

const avatarTheme = {
  width: '75px',
  height: '75px',
  objectFit: 'cover',
  borderRadius: '10px !important',
  border: 'none !important',
  marginBottom: '.5rem !important',
};

const StyledLink = styled.a`
  font-size: 0.8rem;
`;

const PrimaryButton = styled(Button)`
  background-color: #30feaf !important;
  margin-left: 10px !important;
  text-transform: capitalize !important;
  letter-spacing: unset !important;
  font-weight: 600 !important;
`;
const StyledHeader = styled(FormHeader)`
  margin-bottom: 0 !important;
  margin-top: 0.5rem !important;
`;
const StyledOutlineButton = styled(Button)`
  text-transform: capitalize !important;
  letter-spacing: unset !important;
  font-weight: 600 !important;
`;

const IntroText = styled.div`
  color: #717171;
  font-size: 0.8rem;
`;

const MainText = styled.div`
  font-size: 0.8rem;
  margin-top: 1rem;
`;

function ReviewLinkPopup(props) {
  const {
    open,
    handleClose,
    metaId,
    postReviewLink,
    listReviewsLink,
    postSiteId,
    metaInfo,
    siteId,
  } = props;
  const [loader, setLoader] = useState(false);
  const [showAlert, setAlert] = useState(false);
  const [isEnabled, setIsEnabled] = useState(true);
  const { state, dispatch } = useContext(GlobalContext);
  const { selectedLoaction } = state;
  const { register, handleSubmit, errors } = useForm();
  const { inputTheme } = useInputFieldTheme();
  const { buttonTheme } = useButtonTheme();

  const onSubmit = (data) => {
    const postBody = {
      postReviewLink: getPostReviewLink(siteName, data.listReviewsLink, data.siteId),
      listReviewsLink: getListReviewLink(siteName, data.listReviewsLink),
      location: selectedLoaction,
      postSites: postSiteId,
      isDeleted: true,
      siteId: data.siteId,
    };
    setLoader(true);
    if (metaId) {
      baseUrl
        .patch(`/postSitesMetaData/${metaId}`, { ...postBody })
        .then((res) => {
          if (res.status === 200) {
            setLoader(false);
            setAlert(true);
            setTimeout(() => {
              setAlert(false);
              handleClose();
            }, 1000);
          }
        })
        .catch((err) => {
          setLoader(false);
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    } else {
      baseUrl
        .post(`/postSitesMetaData`, { ...postBody })
        .then((res) => {
          if (res.status === 200) {
            setLoader(false);
            setAlert(true);
            setTimeout(() => {
              setAlert(false);
              handleClose();
            }, 1000);
          }
        })
        .catch((err) => {
          setLoader(false);
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    }
  };

  const handleAlertClose = () => setAlert(false);

  const showLoader = loader ? <ButtonLoader /> : null;

  const reviewSiteInfo = metaInfo && metaInfo.filter((postsite) => postsite.id === postSiteId);
  let siteName;
  let specialInstructions;
  let postSiteInfo;
  let placeholderText;
  let logo;

  if (reviewSiteInfo && reviewSiteInfo.length > 0) {
    siteName = reviewSiteInfo[0].name;
    specialInstructions = reviewSiteInfo[0].specialInstructions;
    placeholderText = getPlaceholderText(siteName);
    logo = reviewSiteInfo[0].uploadedFile[0]?.url;
  }

  const introText =
    siteName === 'Yelp'
      ? `When you connect ${siteName}, you can see submitted reviews in Reveo. Yelp does not allow asking for reviews from an external website.`
      : `When you connect ${siteName}, you can see submitted reviews in Reveo and include the
  site in your review invitations.`;

  return (
    <>
      <CusotmAlert
        open={showAlert}
        message={metaId ? 'Listing updated successfully' : 'Listing created successfully'}
        handleClose={handleAlertClose}
      />
      <GenericPopup open={open} onClose={handleClose} fullWidth>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box padding="2rem">
            <FormControl component="fieldset">
              <Box display="flex" flexDirection="column" alignItems="center">
                <ThemeProvider theme={avatarTheme}>
                  <CusotmAvatar
                    alt={siteName}
                    src={`${process.env.REACT_APP_IMAGE_ENDPOINT}${logo}`}
                  />
                </ThemeProvider>
                <StyledHeader>{siteName}</StyledHeader>
              </Box>

              <IntroText>{introText}</IntroText>
              <MainText>
                {listReviewsLink
                  ? "Here's the business profile link you provided when you set up this review site."
                  : specialInstructions
                  ? renderHTML(specialInstructions)
                  : ''}
              </MainText>

              <MUIThemeProvider theme={inputTheme}>
                <StyledTextField
                  name="listReviewsLink"
                  label="Please enter a business reviews link *"
                  placeholder={placeholderText}
                  inputRef={register({
                    required: true,
                    validate: (value) => value.trim() !== '',
                  })}
                  defaultValue={listReviewsLink}
                  error={errors.listReviewsLink}
                  helperText={
                    errors.listReviewsLink ? 'List Reviews Link is a required field' : null
                  }
                />
              </MUIThemeProvider>
              {postSiteInfo && (
                <StyledLink target="_blank" href={postSiteInfo.postSiteLink}>
                  {postSiteInfo.postSiteLink}
                </StyledLink>
              )}
              {siteName === 'Houzz' ? (
                <StyledTextField
                  name="siteId"
                  label="Please enter your Houzz business id*"
                  placeholder={siteId}
                  inputRef={register({
                    required: true,
                    validate: (value) => value.trim() !== '',
                  })}
                  defaultValue={siteId}
                  error={errors.siteId}
                  helperText={errors.siteId ? 'Houzz business id is a required field' : null}
                />
              ) : null}
            </FormControl>
            <Box justifyContent={'flex-end'} display="flex" marginTop="1rem">
              <StyledOutlineButton title="cancel" variant="outlined" onClick={() => handleClose()}>
                Cancel
              </StyledOutlineButton>
              <PrimaryButton title="save" type="submit" variant="contained" onClick={() => {}}>
                Save
                {showLoader}
              </PrimaryButton>
            </Box>
          </Box>
        </form>
      </GenericPopup>
    </>
  );
}

ReviewLinkPopup.propTypes = {
  open: Proptypes.bool.isRequired,
  handleClose: Proptypes.func.isRequired,
  metaId: Proptypes.number,
  postReviewLink: Proptypes.string,
  listReviewsLink: Proptypes.string,
  postSiteId: Proptypes.number,
};

ReviewLinkPopup.defaultProps = {
  metaId: null,
  postReviewLink: '',
  listReviewsLink: '',
};

export default React.memo(ReviewLinkPopup);
