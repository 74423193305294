const Timezone = [
  {
    value: '1',
    label: 'Eastern',
  },
  {
    value: '2',
    label: 'Central',
  },
  {
    value: '3',
    label: 'Mountain',
  },
  {
    value: '4',
    label: 'Mountain without DST',
  },
  {
    value: '5',
    label: 'Pacific',
  },
  {
    value: '6',
    label: 'Alaska',
  },
  {
    value: '7',
    label: 'Hawaii',
  },
  {
    value: '8',
    label: 'Hawaii without DST',
  },
];

export default Timezone;
