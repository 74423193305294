import { Box } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import Proptypes from 'prop-types';

const DetailDescription = styled.div`
  min-height: 18px;
  color: #7a7a7a;
  font-size: 14px;
`;
const DetailDescriptionData = styled.div`
  min-height: 18px;
  color: #000;
  font-size: 14px;
`;
const ContainerRowDescriptionData = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding-top: 5px;
  padding-bottom: 5px;
`;
const ContainerDescriptionData = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 50%;
`;
const RespondentInformation = ({ name, email, locationName, cellPhone, createdDate }) => {
  const isNoAnswer = (value) => {
    if (value && value.length) return value;
    return 'NO ANSWER';
  };
  return (
    <Box>
      <ContainerRowDescriptionData>
        <ContainerDescriptionData>
          <DetailDescription>Name</DetailDescription>
          <DetailDescriptionData>{isNoAnswer(name)}</DetailDescriptionData>
        </ContainerDescriptionData>

        <ContainerDescriptionData>
          <DetailDescription>Email</DetailDescription>
          <DetailDescriptionData>{isNoAnswer(email)}</DetailDescriptionData>
        </ContainerDescriptionData>
      </ContainerRowDescriptionData>

      <ContainerRowDescriptionData>
        <ContainerDescriptionData>
          <DetailDescription>Location name </DetailDescription>
          <DetailDescriptionData>{isNoAnswer(locationName)}</DetailDescriptionData>
        </ContainerDescriptionData>

        <ContainerDescriptionData>
          <DetailDescription>Cell Phone</DetailDescription>
          <DetailDescriptionData>{isNoAnswer(cellPhone)}</DetailDescriptionData>
        </ContainerDescriptionData>
      </ContainerRowDescriptionData>

      <ContainerRowDescriptionData>
        <ContainerDescriptionData>
          <DetailDescription>Creation date</DetailDescription>
          <DetailDescriptionData>{createdDate}</DetailDescriptionData>
        </ContainerDescriptionData>

        <ContainerDescriptionData />
      </ContainerRowDescriptionData>
    </Box>
  );
};

RespondentInformation.propTypes = {
  name: Proptypes.string,
  email: Proptypes.string,
  locationName: Proptypes.string,
  cellPhone: Proptypes.string,
};

RespondentInformation.defaultProps = {
  name: '',
  email: '',
  locationName: '',
  cellPhone: '',
};
export default RespondentInformation;
