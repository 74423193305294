import theme from '../../setup';

const DefaultAvatar = {
  width: '40px',
  height: '40px',
  lgWidth: '40px',
  lgHeight: '40px',
  margin: '0',
  bgColor: theme.btn_background_color,
  textTransform: 'capitalize !important',
  fontFamily: 'Montserrat !important',
};

export default DefaultAvatar;
