import React from 'react';
import Proptypes from 'prop-types';
import { FormHelperText, Grid } from '@material-ui/core';
import { ThemeProvider } from 'styled-components';
import FieldGroupLable from '../../FieldGroupLable/FieldGroupLable';
import CustomButton from '../../CustomButton/CustomButton';

const sentimentButtonTheme = {
  background: '#fff',
  borderRadis: '0',
  margin: '0em 0',
};

function YesNoFilter(props) {
  const { setRecommedned, recommended, recommendedError, setRecommednedError, title } = props;
  const background1 = recommended === 1 ? '#3fb51c' : '#fff';
  const background2 = recommended === 0 ? '#3fb51c' : '#fff';
  const color1 = recommended === 1 ? '#fff' : '';
  const color2 = recommended === 0 ? '#fff' : '';

  const errorContent = recommendedError ? (
    <FormHelperText style={{ color: '#f44235' }}>Please select one of the option</FormHelperText>
  ) : null;

  const handleClick = (value) => {
    setRecommedned(value);
    setRecommednedError(false);
  };

  return (
    <>
      <FieldGroupLable item xs={12} sm={12} md={12} lg={12}>
        {title}
      </FieldGroupLable>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container justify="space-around">
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <ThemeProvider
              theme={{ ...sentimentButtonTheme, background: background1, color: color1 }}
            >
              <CustomButton onClick={() => handleClick(1)}>Yes</CustomButton>
            </ThemeProvider>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <ThemeProvider
              theme={{ ...sentimentButtonTheme, background: background2, color: color2 }}
            >
              <CustomButton onClick={() => handleClick(0)}>No</CustomButton>
            </ThemeProvider>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container alignItems="center" justify="center">
          {errorContent}
        </Grid>
      </Grid>
    </>
  );
}

YesNoFilter.propTypes = {
  setRecommedned: Proptypes.func.isRequired,
  recommended: Proptypes.number.isRequired,
  recommendedError: Proptypes.bool.isRequired,
  setRecommednedError: Proptypes.func.isRequired,
  title: Proptypes.string,
};

YesNoFilter.defaultProps = {
  title: 'Sentiment',
};

export default YesNoFilter;
