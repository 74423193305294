import { Box, Grid, MuiThemeProvider } from '@material-ui/core';
import React, { useState } from 'react';
import useTabsTheme from '../../../hooks/useTabsTheme';
import PageTitle from '../../PageTitle/PageTitle';
import GenricPopup from '../../Popup/GenricPopup';
import AntTab from '../../StyledTabs/StyledTabs';
import AntTabs from '../../StyledTabsContainer/StyledTabsContainer';
import SurveyPopover from '../SendInvitePopover/components/AddCampaign/Survey';

const CreateSurveyPopover = ({ surveyCreationPopup, selectedId = null }) => {
  const [tabValue, setTabValue] = useState(0);
  const { tabsTheme } = useTabsTheme();

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  const handleClose = () => {
    surveyCreationPopup.handleHideShowModal();
  };
  return (
    <GenricPopup
      open={surveyCreationPopup.showModal}
      handleClose={surveyCreationPopup.handleHideShowModal}
      maxWidth="md"
      fullWidth
    >
      <Box style={{ height: 'auto', margin: '1rem' }}>
        {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 10 }}>
          <PageTitle title="Survey settings" />
        </div> */}
        <SurveyPopover
          tabValue={tabValue}
          handleClose={handleClose}
          campaignId={selectedId}
          isOnlySave={false}
        />
      </Box>
    </GenricPopup>
  );
};

export default CreateSurveyPopover;
