import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#208c91',
    '&$checked': {
      color: '#208c91',
    },
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
  },
  checked: {},
}));

export const MultipleChoice = ({ question, currentAnswer, setCurrentAnswer, singleChoice }) => {
  const classes = useStyles();

  const handleChange = (e) => {
    const { value } = e.target;
    let newCheckedValues;

    if (singleChoice) {
      setCurrentAnswer([value]);
    } else {
      if (!currentAnswer) {
        newCheckedValues = [];
      } else {
        newCheckedValues = [...currentAnswer];
      }

      if (e.target.checked) {
        if (!newCheckedValues.includes(value)) {
          newCheckedValues.push(value);
        }
      } else {
        newCheckedValues = newCheckedValues.filter((curValue) => curValue !== value);
      }

      setCurrentAnswer(newCheckedValues);
    }
  };

  const isAlreadyChecked = (value) => {
    if (Array.isArray(currentAnswer)) {
      return currentAnswer.includes(value);
    }
    return false;
  };

  const choices = question.choices.map((choice, i) => {
    return (
      <FormControlLabel
        key={i}
        control={
          <Checkbox
            color="default"
            onChange={handleChange}
            className={classes.root}
            value={choice}
            checked={isAlreadyChecked(choice)}
          />
        }
        label={
          <div
            style={{
              fontFamily: 'Montserrat',
              lineHeight: '1.1',
              fontSize: '15px',
              fontWeight: 500,
              marginTop: choice.length > 30 ? '10px' : null,
            }}
          >
            {choice}
          </div>
        }
      />
    );
  });

  return <div style={{ display: 'flex', flexDirection: 'column', rowGap: '.3rem' }}>{choices}</div>;
};
