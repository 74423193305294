import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ThankYouCard from '../components/ReviewRequestPages/ThankYouCard';
// import baseUrlLogin from '../utils/services/auth.config';
import Loader from '../components/Loader/Loader';
import FirstCard from '../components/SurveyReview/FirstCard';
import RatingCard from '../components/SurveyReview/RatingCard';
import SentimentCard from '../components/SurveyReview/SentimentCard';
import useFetchSurvey from '../hooks/useFetchSurvey';
import { replaceBusinessLogoInSurveys } from '../utils/helper/helper';

const StyledPNFGrid = styled(Grid)`
  height: 100%;
  font-size: 36px;
  color: #37423e;
  font-weight: 200;
  letter-spacing: 1.08px;
  background: #fff;
  overflow: scroll;
`;

function SurveyReview() {
  const [thankYouCard, setThankYouCard] = useState({});
  const [surveyData, setSurveyTypeJson] = useState({});
  const [textSurvey, setTextSurvey] = useState({});
  const [ratingSurvey, setRatingSurvey] = useState({});
  const [sentimentData, setSentimentData] = useState({});
  const [firstCard, setFirstCard] = useState(false);
  const [showRatingCard, setshowRatingCard] = useState(false);
  const [sentimentCard, setSentimentCard] = useState(false);
  const [showThankyou, setshowThankyou] = useState(false);
  const { getSurveyType, surveyTypes } = useFetchSurvey()

  const [ReviewFeedback, setrReviewFeedback] = useState({
    reviewFeedback: '',
    rating: 0,
  });
  // const [code, setCode] = useState();
  const [renderView, setRenderView] = useState(false);
  // const previousCount = localStorage.getItem(`linkClicks_${code}`)
  //   ? parseInt(localStorage.getItem(`linkClicks_${code}`), 10)
  //   : 0;

  useEffect(() => {
    getSurveyType()
  }, [])

  useEffect(() => {
    // setCode(getParameterByName('code'));
    setRenderView(true);
    const type = 'yesNo';
    const data = replaceBusinessLogoInSurveys(surveyTypes);
    setSurveyTypeJson(data);
    // eslint-disable-next-line array-callback-return
    data[type].screens.map((item) => {
      if (item.screen === 'text-survey') setTextSurvey(item);
      if (item.screen === 'thankyou') setThankYouCard(item);
      if (item.screen === 'sentiment-survey') setSentimentData(item);
      if (item.screen === 'rating-survey') setRatingSurvey(item);
    });
    if (type === 'yesNo') {
      setFirstCard(true);
    } else if (type === 'sentiment') {
      setSentimentCard(true);
    } else {
      setshowRatingCard(true);
    }
  }, [surveyTypes]);

  const onClientFeedback = (e) => {
    setrReviewFeedback({
      ...ReviewFeedback,
      reviewFeedback: e,
    });
  };

  const onClientRating = (event, newValue) => {
    setrReviewFeedback({
      ...ReviewFeedback,
      rating: newValue,
    });
  };

  const onYesNoClick = () => {
    setshowThankyou(true);
  };

  return renderView ? (
    <>
      <StyledPNFGrid container justify="center">
        <Grid xs={12} md={4} lg={3} sm={5}>
          {firstCard ? <FirstCard textSurvey={textSurvey} onYesNoClick={onYesNoClick} /> : ''}

          {showRatingCard ? (
            <RatingCard
              onClientFeedback={onClientFeedback}
              onYesClickonClientRating={onClientRating}
              ratingSurvey={ratingSurvey}
            />
          ) : (
            ''
          )}
          {sentimentCard ? (
            <SentimentCard
              onClientFeedback={onClientFeedback}
              sentimentData={sentimentData}
              onClientRating={onClientRating}
              surveyTypeJson={surveyData}
            />
          ) : (
            ''
          )}
          {showThankyou ? <ThankYouCard thankyouCard={thankYouCard} /> : ''}
        </Grid>
      </StyledPNFGrid>
    </>
  ) : (
    <Loader />
  );
}

SurveyReview.propTypes = {};
export default SurveyReview;
