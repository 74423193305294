import React, { useCallback, useContext, useState } from 'react';
import Proptypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import NewConversationPopup from '../../components/Popup/NewConversationPopup/NewConversationPopup';
import CampaignSelectionView from '../../components/CampaignSelectionView';
import useModal from '../../hooks/useModal';
import StatusMessage from '../../components/StatusMessage';
import StyledCheckCircle from '../../components/Icons/CheckedCircle';
import baseUrl from '../../utils/services/config';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import { errorChecker, getFeedbackPathFromType } from '../../utils/helper/helper';
import useIconTheme from '../../hooks/useIconTheme';

function SendInviteContainer(props) {
  const { newConversationPopup, handleGroupViewToggle } = props;
  const [clientInfo, setClientInfo] = useState({});
  const [campaignLoader, setCampaignLoader] = useState(false);
  const [redirectTo, setRedirectTo] = useState('');
  const thankYouScreen = useModal();
  const { state, dispatch } = useContext(GlobalContext);
  const { iconsTheme } = useIconTheme();
  const {
    campaignLocation,
    selectedLoaction,
    selectedGroupInfo,
    selectedContactForCampaign,
  } = state;

  const fetchNotifications = useCallback(async () => {
    try {
      if (selectedLoaction) {
        dispatch({ type: 'SET_NOTIFICATION_LOADER', payload: true });
        const result = await baseUrl(
          `/getnotifications/${localStorage.getItem('USER_ID')}/${selectedLoaction}`,
        );
        if (result.status === 200) {
          dispatch({ type: 'SET_NOTIFICATION_CONTAINER', payload: result.data });
          dispatch({ type: 'SET_NOTIFICATION_LOADER', payload: false });
        }
      }
    } catch (err) {
      dispatch({ type: 'SET_NOTIFICATION_LOADER', payload: false });
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  }, [selectedLoaction, dispatch]);

  const handleNewCampaignCreation = (clientDetails) => {
    setClientInfo(clientDetails);
    // console.log('In New Campgin container',clientDetails.locations[0].location)
    // dispatch({ type: 'SET_CAMPAIGN_LOCATION', payload: clientDetails.locations[0].location });
  };

  const handleSendCampaign = async (campaignInfo) => {
    if (campaignInfo.id) {
      try {
        setCampaignLoader(true);
        let postBody;
        if (selectedContactForCampaign.length) {
          postBody = {
            client: selectedContactForCampaign,
            location: campaignLocation,
            user: parseInt(localStorage.getItem('USER_ID'), 10),
            campaign: campaignInfo.id,
          };
        } else {
          postBody = {
            client: clientInfo.id,
            location: campaignLocation,
            user: parseInt(localStorage.getItem('USER_ID'), 10),
            campaign: campaignInfo.id,
          };
        }
        const result = await baseUrl.post(`/reviewsrequest`, { ...postBody });
        if (result.status === 200) {
          setRedirectTo(getFeedbackPathFromType(campaignInfo.type));
          dispatch({ type: 'SET_BORDER_RADIUS_THANK_YOU_POPUP', payload: '20px' });
          thankYouScreen.handleShowModal();
          setCampaignLoader(false);
          fetchNotifications();
        }
      } catch (err) {
        setCampaignLoader(false);
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  };

  const handleCloseSendInviteModal = () => {
    dispatch({ type: 'SET_SELECTED_GROUP_ID', payload: null });
    newConversationPopup.handleHideShowModal();
    dispatch({
      type: 'SET_SELECTED_GROUP_INFO',
      payload: {},
    });
    dispatch({ type: 'SET_BORDER_RADIUS_THANK_YOU_POPUP', payload: '10px' });
    newConversationPopup.handleHideShowModal();
  };

  const showCampaignSelectionView =
    (clientInfo && Object.keys(clientInfo).length) ||
    (selectedGroupInfo &&
      Object.keys(selectedGroupInfo).length &&
      selectedContactForCampaign.length);

  const sendInviteViewContainer = showCampaignSelectionView ? (
    <CampaignSelectionView
      {...clientInfo}
      {...selectedGroupInfo}
      handleSelectedCampaign={handleSendCampaign}
      campaginLoader={campaignLoader}
    />
  ) : (
    <NewConversationPopup
      setClientInfo={setClientInfo}
      open={newConversationPopup.showModal}
      handleNewChatCreation={handleNewCampaignCreation}
      newConversationLoader={false}
      handleClose={newConversationPopup.handleHideShowModal}
      handleShowGenericModal={newConversationPopup.handleShowModal}
      handleGroupViewToggle={handleGroupViewToggle}
    />
  );

  const contentContainer = thankYouScreen.showModal ? (
    <StatusMessage
      Icon={
        <ThemeProvider theme={{ color: iconsTheme.checkCircleIcon }}>
          <StyledCheckCircle />
        </ThemeProvider>
      }
      {...selectedGroupInfo}
      message={
        selectedContactForCampaign.length
          ? `${selectedContactForCampaign.length} Message Sent`
          : 'Message Sent'
      }
      actionBtnText="Close"
      redirectLinkText="Go to Feedback"
      redirectTo={redirectTo}
      handleClose={handleCloseSendInviteModal}
    />
  ) : (
    sendInviteViewContainer
  );

  return contentContainer;
}

SendInviteContainer.propTypes = {
  newConversationPopup: Proptypes.objectOf(Proptypes.any).isRequired,
  handleGroupViewToggle: Proptypes.func.isRequired,
};

export default SendInviteContainer;
