const reminders = [
  {
    label: 'None',
    value: '0',
  },
  {
    label: '1 Reminder',
    value: '1',
  },
  {
    label: '2 Reminders',
    value: '2',
  },
  {
    label: '3 Reminders',
    value: '3',
  },
  {
    label: '4 Reminders',
    value: '4',
  },
];

export default reminders;
