import React, { useState } from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';
import TabPanel from '../../components/TabPanel/TabPanel';
import styled, { ThemeProvider } from 'styled-components';
import StyledDescriptionText from '../../components/DescriptionText';
import BusinessCardPreview from '../../components/BusinessCardPreview/BusinessCardPreview';
import { ChromePicker } from 'react-color';
import CustomSwitch from '../../components/CustomSwitch/CustomSwitch';
import MobilePreview from '../../components/MobilePreview/MobilePreview';

const WidgetSetting = styled.div`
  font-weight: 600;
  min-width: 50%;
  font-size: 14px;
`;

const SubtitleText = styled.div`
  font-size: 0.8rem;
  line-height: 1.3;
  color: #818181;
  margin-top: 0.3rem;
`;

export const BusinessCardTab = (props) => {
  const { tabValue, formik, locationImages, businessInfo } = props;

  const headerColor = formik.values.businessCard.headerColor
    ? formik.values.businessCard.headerColor
    : '#0f0';
  const [displayHeaderColorPicker, setDisplayHeaderColorPicker] = useState(false);

  const backgroundColor = formik.values.businessCard.backgroundColor || '#fff';
  const [displayBackgroundColorPicker, setDisplayBackgroundColorPicker] = useState(false);

  const iconColor = formik.values.businessCard.iconColor || '#000';
  const [displayIconColorPicker, setDisplayIconColorPicker] = useState(false);

  const textColor = formik.values.businessCard.textColor || '#000';
  const [displayTextColorPicker, setDisplayTextColorPicker] = useState(false);

  const isUserImageAvatar = formik.values.businessCard.isUserImageAvatar;
  const isHeaderImage = formik.values.businessCard.isHeaderImage;

  const businessImage = formik.values.business_Reviews;

  const useStyles = makeStyles(() => ({
    textColor: {
      width: '36px',
      height: '14px',
      borderRadius: '2px',
      background: textColor,
    },
    backgroundColor: {
      width: '36px',
      height: '14px',
      borderRadius: '2px',
      background: backgroundColor,
    },
    iconColor: {
      width: '36px',
      height: '14px',
      borderRadius: '2px',
      background: iconColor,
    },
    headerColor: {
      width: '36px',
      height: '14px',
      borderRadius: '2px',
      background: headerColor,
    },
    swatch: {
      padding: '5px',
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
      marginTop: '.5rem',
    },
    popover: {
      position: 'absolute',
      zIndex: '2',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
  }));

  const classes = useStyles();

  return (
    <TabPanel value={tabValue} index={4}>
      <Grid container spacing={4} style={{ marginTop: '1rem' }}>
        <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
          <Box
            display="flex"
            justifyContent="flex-start"
            flex="1"
            flexDirection="column"
            marginBottom="30px"
          >
            <WidgetSetting>Business Card Settings</WidgetSetting>
            <Grid container style={{ marginTop: '1rem' }}>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                <Box display="flex" alignItems="flex-start" flexDirection="column">
                  <ThemeProvider theme={{ color: '#888888', margin: '0' }}>
                    <StyledDescriptionText>Header Color </StyledDescriptionText>
                  </ThemeProvider>
                  <div
                    className={classes.swatch}
                    onClick={() => setDisplayHeaderColorPicker(!displayHeaderColorPicker)}
                  >
                    <div className={classes.headerColor} />
                  </div>
                </Box>
                {displayHeaderColorPicker ? (
                  <div className={classes.popover}>
                    <div
                      className={classes.cover}
                      onClick={() => setDisplayHeaderColorPicker(!displayHeaderColorPicker)}
                    />
                    <ChromePicker
                      color={headerColor}
                      disableAlpha
                      validateOnChange={false}
                      onChange={(color) =>
                        formik.setFieldValue('businessCard.headerColor', color.hex)
                      }
                      onChangeComplete={(color) =>
                        formik.setFieldValue('businessCard.headerColor', color.hex)
                      }
                    />
                  </div>
                ) : null}
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                <Box display="flex" alignItems="flex-start" flexDirection="column">
                  <ThemeProvider theme={{ color: '#888888', margin: '0' }}>
                    <StyledDescriptionText>Background Color </StyledDescriptionText>
                  </ThemeProvider>
                  <div
                    className={classes.swatch}
                    onClick={() => setDisplayBackgroundColorPicker(!displayBackgroundColorPicker)}
                  >
                    <div className={classes.backgroundColor} />
                  </div>
                </Box>
                {displayBackgroundColorPicker ? (
                  <div className={classes.popover}>
                    <div
                      className={classes.cover}
                      onClick={() => setDisplayBackgroundColorPicker(!displayBackgroundColorPicker)}
                    />
                    <ChromePicker
                      color={backgroundColor}
                      disableAlpha
                      onChange={(color) =>
                        formik.setFieldValue('businessCard.backgroundColor', color.hex)
                      }
                      onChangeComplete={(color) =>
                        formik.setFieldValue('businessCard.backgroundColor', color.hex)
                      }
                    />
                  </div>
                ) : null}
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '2rem' }}>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                <Box display="flex" alignItems="flex-start" flexDirection="column">
                  <ThemeProvider theme={{ color: '#888888', margin: '0' }}>
                    <StyledDescriptionText>Icon Color </StyledDescriptionText>
                  </ThemeProvider>
                  <div
                    className={classes.swatch}
                    onClick={() => setDisplayIconColorPicker(!displayIconColorPicker)}
                  >
                    <div className={classes.iconColor} />
                  </div>
                </Box>
                {displayIconColorPicker ? (
                  <div className={classes.popover}>
                    <div
                      className={classes.cover}
                      onClick={() => setDisplayIconColorPicker(!displayIconColorPicker)}
                    />
                    <ChromePicker
                      color={iconColor}
                      disableAlpha
                      onChange={(color) =>
                        formik.setFieldValue('businessCard.iconColor', color.hex)
                      }
                      onChangeComplete={(color) =>
                        formik.setFieldValue('businessCard.iconColor', color.hex)
                      }
                    />
                  </div>
                ) : null}
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                <Box display="flex" alignItems="flex-start" flexDirection="column">
                  <ThemeProvider theme={{ color: '#888888', margin: '0' }}>
                    <StyledDescriptionText>Text Color</StyledDescriptionText>
                  </ThemeProvider>
                  <div
                    className={classes.swatch}
                    onClick={() => setDisplayTextColorPicker(!displayTextColorPicker)}
                  >
                    <div className={classes.textColor} />
                  </div>
                </Box>
                {displayTextColorPicker ? (
                  <div className={classes.popover}>
                    <div
                      className={classes.cover}
                      onClick={() => setDisplayTextColorPicker(!displayTextColorPicker)}
                    />
                    <ChromePicker
                      color={textColor}
                      disableAlpha
                      onChange={(color) =>
                        formik.setFieldValue('businessCard.textColor', color.hex)
                      }
                      onChangeComplete={(color) =>
                        formik.setFieldValue('businessCard.textColor', color.hex)
                      }
                    />
                  </div>
                ) : null}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  flex="1"
                  marginTop=".8rem"
                >
                  <Box display="flex" flexDirection="column" marginTop="1rem">
                    <WidgetSetting>User image as avatar</WidgetSetting>
                    <SubtitleText>
                      Switching this off will use the location logo as the image (change the
                      location logo on the images tab)
                    </SubtitleText>
                  </Box>
                  <CustomSwitch
                    checked={isUserImageAvatar ? true : false}
                    onChange={() =>
                      formik.setFieldValue('businessCard.isUserImageAvatar', !isUserImageAvatar)
                    }
                  />
                </Box>
              </Grid>
              {businessImage ? (
                <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    flex="1"
                    marginTop=".8rem"
                  >
                    <Box display="flex" flexDirection="column" marginTop="1rem">
                      <WidgetSetting>Use microsite image as header</WidgetSetting>
                      <SubtitleText>
                        Switching this off will use the header color as the background for the
                        header (change the microsite header image on the images tab)
                      </SubtitleText>
                    </Box>
                    <CustomSwitch
                      checked={isHeaderImage ? true : false}
                      onChange={() =>
                        formik.setFieldValue('businessCard.isHeaderImage', !isHeaderImage)
                      }
                    />
                  </Box>
                </Grid>
              ) : null}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <Box display="flex" justifyContent={'flex-end'} zIndex={2}>
            <MobilePreview width={'196px'} height={'400px'}>
              <BusinessCardPreview
                headerColor={headerColor}
                backgroundColor={backgroundColor}
                iconColor={iconColor}
                textColor={textColor}
                businessInfo={businessInfo}
                locationImages={locationImages}
                isUserImageAvatar={isUserImageAvatar}
                isHeaderImage={isHeaderImage}
                headerImage={businessImage}
              />
            </MobilePreview>
          </Box>
        </Grid>
      </Grid>
    </TabPanel>
  );
};
