import React, { useState, useContext, useCallback } from 'react';
import {
  makeStyles,
  Grid,
  Button,
  Link,
  FormControlLabel,
  LinearProgress,
} from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import CustomTable from '../../components/CustomTable/CusotmTable';
import baseUrl from '../../utils/services/config';
import Loader from '../../components/Loader/Loader';
import CusotomSearchBox from '../../components/CustomSearchBox/CustomSearchBox';
import CustomSwitch from '../../components/CustomSwitch/CustomSwitch';
import Dropdown from '../../components/Dropdown/Dropdown';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import StyledTableCell from '../../components/TableRowCell';
import FileUploader from '../../components/FileUploader/FileUploader';

const PrimaryButton = styled(Button)`
  background-color: #30feaf !important;
  text-transform: capitalize !important;
  letter-spacing: unset !important;
  font-weight: 600 !important;
  padding: 6px 20px !important;
`;

const StyledTableCellContainer = styled.div`
  color: ${(props) => props.color} !important;
  font-weight: 600 !important;
`;

const StyledDropdown = styled(Dropdown)`
  transform: translateY(-20px);
`;

const StyledUploaderContainer = styled(Grid)`
  padding: 0.5em 0 0 3em !important;
`;

const StyledScrapeContainer = styled(Grid)`
  padding-top: 10px !important;
`;

const StyledSearchButtonContainer = styled(Grid)`
  padding-top: 15px !important;
`;

const StyledLinearProgressContainer = styled(Grid)`
  padding-top: 30px !important;
`;

function SearchScrape() {
  const { state } = useContext(GlobalContext);
  const { themeContainer } = state;
  const [isLoading, setIsLoading] = useState(false);
  const [stringSearch, setStringSearch] = useState('');
  const [maxResults, setMaxResults] = useState(20);
  const [scrapeMethod, setScrapeMethod] = useState('axios');
  const [reScrape, setReScrape] = useState('no');
  const [dataView, setDataView] = useState('front');
  const [doScrape, setDoScrape] = useState(false);
  const [results, setResults] = useState([]);
  const [storedResults, setStoredResults] = useState([]);
  const [uploadedValue, setUploadedValue] = useState(null);
  const [progress, setProgress] = useState(0);

  const useStyles = makeStyles(() => ({
    root: {
      paddingTop: '2rem',
    },
  }));

  const classes = useStyles();

  const handleChangeSearchBox = (e) => {
    setStringSearch(e.target.value);
  };

  const handleClearSearch = () => {
    setStringSearch('');
  };

  const getResults = (searchString) => {
    if (searchString !== undefined && searchString !== '') {
      baseUrl
        .get(
          `/search-text-google-maps/${searchString}?doScrape=${doScrape}&maxResults=${maxResults}&scrapeMethod=${scrapeMethod}&reScrape=${reScrape}`,
        )
        .then((res) => {
          setTimeout(() => {
            setIsLoading(false);
            if (res.data.length) {
              setResults(res.data);
            }
          }, 500);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  const getStoredResults = useCallback(() => {
    setIsLoading(true);
    baseUrl
      .get(`/scraped-business`)
      .then((res) => {
        setTimeout(() => {
          setIsLoading(false);
          if (res.data.length) {
            setStoredResults(res.data);
          }
        }, 500);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, results);

  const handleSearchClick = () => {
    if (stringSearch === '') {
      alert('Empty search value');
      return;
    }
    setIsLoading(true);
    getResults(stringSearch);
    setDataView('front');
  };

  const getScrapeResult = async (url, placeId) => {
    if (url !== undefined && url !== '' && placeId !== undefined && placeId !== '') {
      try {
        const res = await baseUrl.get(
          `/scrape-website?url=${encodeURIComponent(
            url,
          )}&scrapeMethod=${scrapeMethod}&placeId=${placeId}`,
        );
        return res.data.scrapeResult || 'Scrape failed';
      } catch (err) {
        console.error('ScrapeError:', err);
        return 'Scrape failed';
      }
    }
  };

  const doFeScrape = (scrapeResult) => {
    const isScraped = scrapeResult && scrapeResult !== '';
    if (reScrape === 'all' || !isScraped) {
      return true;
    }
    if (reScrape === 'no') {
      if (isScraped) {
        return false;
      }
    } else if (!['no matches', 'scrape failed'].includes(scrapeResult.toLowerCase())) {
      return false;
    }
    return true;
  };

  const handleScrapeFeClick = async () => {
    if (results.length <= 0) {
      alert('Empty results');
      return;
    }
    setProgress(0.1);
    const newResults = [...results];
    const partialProgress = 100 / results.length;
    for (let i = 0; i < results.length; i++) {
      const element = results[i];
      if (element.website && doFeScrape(element.scrapeResult)) {
        try {
          const scrapeResult = await getScrapeResult(element.website, element.placeId);
          newResults[i] = { ...newResults[i], scrapeResult };
        } catch (error) {
          console.error(`Error fetching data for ${element.website}: ${error.message}`);
          // Handle the error as needed
        }
      }
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        return oldProgress + partialProgress;
      });
    }
    setProgress(0);
    setResults(newResults);
  };

  const columnDefaultOptions = {
    filter: true,
    sort: true,
    customBodyRender: (value) => {
      return <StyledTableCell>{value}</StyledTableCell>;
    },
  };

  const columns = [
    {
      name: 'placeId',
      label: 'placeId',
      options: {
        display: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: columnDefaultOptions,
    },
    {
      name: 'address',
      label: 'Address',
      options: columnDefaultOptions,
    },
    {
      name: 'phone',
      label: 'Phone',
      options: columnDefaultOptions,
    },
    {
      name: 'rating',
      label: 'Rating',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <StyledTableCell>{value}</StyledTableCell>;
        },
      },
    },
    {
      name: 'userRatingTotals',
      label: 'Total',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <StyledTableCell>{value}</StyledTableCell>;
        },
      },
    },
    {
      name: 'scrapeResult',
      label: 'Competitor',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          let colorString = 'green';
          if (value === 'Scrape failed') {
            colorString = 'red';
          } else if (value === 'No matches') {
            colorString = 'blue';
          }
          return <StyledTableCellContainer color={colorString}>{value}</StyledTableCellContainer>;
        },
      },
    },
    {
      name: 'googleLink',
      label: 'Google',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            value && (
              <StyledTableCell>
                <Link href={value} underline="hover" target="_blank">
                  Link
                </Link>
              </StyledTableCell>
            )
          );
        },
      },
    },
    {
      name: 'website',
      label: 'Website',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            value && (
              <StyledTableCell>
                <Link href={value} underline="hover" target="_blank">
                  Website
                </Link>
              </StyledTableCell>
            )
          );
        },
      },
    },
  ];

  const resultOptions = [
    { label: '20', value: '20' },
    { label: '40', value: '40' },
    { label: '60', value: '60' },
  ];

  const scrapeMethodsOptions = [
    { label: 'Axios', value: 'axios' },
    { label: 'ScrapeIt', value: 'scrapeit' },
    { label: 'Puppeteer', value: 'puppeteer' },
  ];

  const reScrapeOptions = [
    { label: 'Dont re-scrape', value: 'no' },
    { label: 'Only failed or no-match', value: 'failed' },
    { label: 'All', value: 'all' },
  ];

  const viewOptions = [
    { label: 'Actual', value: 'front' },
    { label: 'Stored', value: 'stored' },
  ];

  const options = {
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader /> : 'No data found !',
      },
    },
    selectToolbarPlacement: 'none',
    searchOpen: false,
    filter: true,
    selectableRows: 'none',
    filterType: 'dropdown',
    responsive: 'vertical',
    rowsPerPage: 10,
    selectableRowsHeader: true,
    downloadOptions: {
      filename: 'csv_export.csv',
      separator: ';',
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
  };

  const handleUploadCsv = (file) => {
    if (file !== undefined) {
      const googledata = new FormData();
      googledata.append('googledata', file);
      baseUrl
        .post(
          `/search-file-google-maps?doScrape=${doScrape}&scrapeMethod=${scrapeMethod}&reScrape=${reScrape}`,
          googledata,
        )
        .then((res) => {
          setTimeout(() => {
            setIsLoading(false);
            if (res.data.length) {
              setResults(res.data);
            }
          }, 500);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
    setDataView('front');
  };

  const handleGetFile = (file) => {
    setIsLoading(true);
    setUploadedValue(file[0].name);
    handleUploadCsv(file[0]);
  };

  return !isLoading ? (
    <Grid container className={classes.root}>
      <Grid item xs={4} md={4}>
        <ThemeProvider theme={{ color: themeContainer.tableSearchBoxColor }}>
          <CusotomSearchBox
            loader={isLoading}
            placeholder="Search…"
            handleChange={handleChangeSearchBox}
            clearSearch={handleClearSearch}
            searchValue={stringSearch}
          />
        </ThemeProvider>
      </Grid>
      <Grid item xs={2} md={2}>
        <StyledDropdown
          listOptions={resultOptions}
          label="Max Results"
          value={maxResults}
          handleChange={(e) => {
            setMaxResults(e.target.value);
          }}
          title={false}
        />
      </Grid>
      <StyledScrapeContainer item xs={2} md={2}>
        <FormControlLabel
          control={
            <CustomSwitch
              checked={doScrape}
              onChange={(e) => {
                setDoScrape(e.target.checked);
              }}
            />
          }
          label="Scrape BE"
        />
      </StyledScrapeContainer>
      <StyledSearchButtonContainer item xs={1} md={1}>
        <PrimaryButton onClick={handleSearchClick} title="Search">
          Search
        </PrimaryButton>
      </StyledSearchButtonContainer>
      <StyledUploaderContainer item xs={3} md={3}>
        <ThemeProvider theme={{ width: '80%', height: '30px' }}>
          <FileUploader
            handleGetFile={handleGetFile}
            value={uploadedValue}
            icon={null}
            title="Upload CSV file"
            uploaderText="Upload CSV file"
            accept=".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"
          />
        </ThemeProvider>
      </StyledUploaderContainer>
      <Grid item xs={2} md={2}>
        <StyledDropdown
          listOptions={scrapeMethodsOptions}
          label="Scrape methods"
          value={scrapeMethod}
          handleChange={(e) => {
            setScrapeMethod(e.target.value);
          }}
          title={false}
        />
      </Grid>
      <Grid item xs={2} md={2}>
        <StyledDropdown
          listOptions={reScrapeOptions}
          label="Do Scrape again"
          value={reScrape}
          handleChange={(e) => {
            setReScrape(e.target.value);
          }}
          title={false}
        />
      </Grid>
      <StyledSearchButtonContainer item xs={2} md={2}>
        <PrimaryButton onClick={handleScrapeFeClick} title="Scrape FE">
          Scrape FE
        </PrimaryButton>
      </StyledSearchButtonContainer>
      {progress > 0 ? (
        <StyledLinearProgressContainer item xs={6} md={6}>
          <LinearProgress variant="determinate" value={progress} color="primary" />
        </StyledLinearProgressContainer>
      ) : (
        <Grid item xs={6} md={6}>
          <StyledDropdown
            listOptions={viewOptions}
            label="Work area"
            value={dataView}
            handleChange={(e) => {
              setDataView(e.target.value);
              if (e.target.value === 'stored') {
                getStoredResults();
              }
            }}
            title={false}
          />
        </Grid>
      )}
      <Grid item xs={12} md={12}>
        {progress > 0 ? (
          <LinearProgress variant="determinate" value={progress} color="primary" />
        ) : (
          <CustomTable
            data={dataView === 'front' ? results : storedResults}
            columns={columns}
            options={options}
          />
        )}
      </Grid>
    </Grid>
  ) : (
    <Loader />
  );
}

SearchScrape.propTypes = {};

export default SearchScrape;
