import React, { useContext, useEffect } from 'react';
import Proptypes from 'prop-types';
import {
  createStyles,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
  ThemeProvider as MUIThemeProvider,
} from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import OnBoardingPopUp from '../../OnBorad/OnBoardingPopUp';
import useModal from '../../../hooks/useModal';
import GroupUpload from '../GroupUpload';
import GroupListView from '../GroupListView';
import GroupUploadedContacts from '../GroupUploadedContacts';
import { GlobalContext } from '../../../Stores/GlobalStateStore';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 'calc(100vh - 120px)',
      '& > div:nth-of-type(1)': {
        display: 'none',
      },
      [theme.breakpoints.up('xl')]: {
        height: '834px',
      },
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
);

function getSteps() {
  return ['Step1', 'Step2', 'Step3'];
}

function GroupContainer(props) {
  const { isNewGroup, isEditGroup, initIsNewGroup, handleSendCampaignFromGroup } = props;
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const groupViewModal = useModal();
  const { dispatch, state } = useContext(GlobalContext);
  const { groupContainer, themeContainer } = state;
  // Effect to fetchGroupName
  useEffect(() => {}, []);

  // Set the edit action flow
  useEffect(() => {
    if (isEditGroup) {
      dispatch({
        type: 'SET_SELECTED_GROUP_NAME',
        payload: [],
      });
      dispatch({
        type: 'SET_IS_GROUP_SAVED',
        payload: true,
      });
      setActiveStep(1);
      groupViewModal.handleShowModal();
    }
  }, [isEditGroup, dispatch]);

  // set the new group action flow
  useEffect(() => {
    if (isNewGroup) {
      groupViewModal.handleShowModal();
    }
  }, [isNewGroup]);

  useEffect(() => {
    if (!groupViewModal.showModal) {
      dispatch({ type: 'SET_SELECTED_GROUP_ID', payload: null });
      initIsNewGroup();
    }
  }, [groupViewModal.showModal, dispatch]);

  const handleSubmit = () => {
    setActiveStep(0);
  };

  const handleNext = (step) => {
    if (step >= 0) {
      setActiveStep(step);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleClose = () => {
    setActiveStep(0);
    dispatch({ type: 'INIT_GROUP_CONTAINER' });
    groupViewModal.handleHideShowModal();
  };

  const handleSendCampaign = () => {
    dispatch({
      type: 'SET_SELECTED_CONTACT_ID',
      payload: groupContainer.listViewSelectedContact,
    });
    dispatch({ type: 'SET_CAMPAIGN_LOCATION', payload: groupContainer.selectedGroupLocation });
    handleSendCampaignFromGroup();
    handleClose();
  };

  const popUpTheme = createTheme({
    background: themeContainer.popoverBgColor,
  });

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <GroupUpload handleNext={handleNext} activeStep={activeStep} />;
      case 1:
        return (
          <GroupListView
            handleNext={handleNext}
            handleSendCampaignFromGroup={handleSendCampaign}
            activeStep={activeStep}
          />
        );
      case 2:
        return <GroupUploadedContacts handleNext={handleNext} activeStep={activeStep} />;

      default:
        return 'Unknown step';
    }
  }

  return (
    <ThemeProvider theme={{ overflowY: 'hidden' }}>
      <MUIThemeProvider theme={popUpTheme}>
        <OnBoardingPopUp
          show={groupViewModal.showModal}
          handleClose={handleClose}
          handleNext={activeStep === steps.length - 1 ? handleSubmit : handleNext}
          activeStep={activeStep}
        >
          <div className={classes.root}>
            <Stepper alternativeLabel activeStep={activeStep}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {getStepContent(activeStep)}
          </div>
        </OnBoardingPopUp>
      </MUIThemeProvider>
    </ThemeProvider>
  );
}

GroupContainer.propTypes = {
  isNewGroup: Proptypes.bool.isRequired,
  isEditGroup: Proptypes.bool.isRequired,
  initIsNewGroup: Proptypes.func.isRequired,
  handleSendCampaignFromGroup: Proptypes.func.isRequired,
};

export default React.memo(GroupContainer);
