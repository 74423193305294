import styled from 'styled-components';
import React from 'react';
import { Box } from '@material-ui/core';

const StyledStatBox = styled(Box)`
  display: flex;
  flex-direction: column;
  //border-left: 1px solid #bebebe;
  align-items: center;
`;
const StyledSubtitle = styled.div`
  text-transform: uppercase;
  color: #6d6d6d;
  font-size: 11px;
`;
const StyledCount = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const getCountStuff = (subtitle, count) => {
  switch (subtitle) {
    case 'Successful':
      return <div style={{ color: '#32a753' }}>{count}</div>;
    case 'Blocked':
      return <div style={{ color: '#cc5353' }}>{count}</div>;
    case 'Frequency':
      if (count === 0) {
        return <div>None</div>;
      } else {
        return <div>{count}</div>;
      }
    default:
      return <div>{count}</div>;
  }
};

const StatBox = (props) => {
  const { count, subtitle } = props;
  return (
    <StyledStatBox>
      <StyledCount>{getCountStuff(subtitle, count)}</StyledCount>
      <StyledSubtitle>{subtitle}</StyledSubtitle>
    </StyledStatBox>
  );
};
export default StatBox;
