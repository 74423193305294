import { useState, useEffect } from 'react';
import { convertIntoSearchPhoneFormat } from '../utils/helper/helper';

function useSearch(props) {
  const { data, searchParameters } = props;
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const handleSearch = (e) => setSearchValue(e.target.value);

  const clearSearch = () => setSearchValue('');

  useEffect(() => {
    if (data) {
      setFilteredData(data);
    }
  }, [data]);

  useEffect(() => {
    if (searchValue) {
      const filteredDataContainer = [];
      data.forEach((item) => {
        searchParameters.forEach((searchItem) => {
          if (item !== null || item !== undefined) {
            if (searchItem.toLowerCase() === 'phone') {
              if (
                convertIntoSearchPhoneFormat(item[searchItem])
                  .toLowerCase()
                  .startsWith(searchValue.toLowerCase())
              ) {
                filteredDataContainer.push(item);
              }
            } else if (
              item[searchItem].toLowerCase().includes(searchValue.toLowerCase())
            ) {
              filteredDataContainer.push(item);
            }
          }
        });
      });
      setFilteredData(filteredDataContainer);
    } else {
      setFilteredData(data);
    }
  }, [searchValue]);

  return { filteredData, handleSearch, searchValue, setSearchValue, clearSearch };
}

export default useSearch;
