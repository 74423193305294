import React from 'react';
import Proptypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import useTableTheme from '../../hooks/useTableTheme';
import { checkObjectArrayIsEmpty } from '../../utils/helper/helper';

function CusotmTable(props) {
  const { data, columns, options } = props;
  const { tableTheme } = useTableTheme();
  const countContainer = data.length;
  const optionsContainer =
    countContainer >= 10 || options.count >= 10
      ? Object.assign(options, { pagination: false })
      : Object.assign(options, { pagination: false });

  if (options?.downloadOptions && checkObjectArrayIsEmpty(tableTheme)) {
    tableTheme.overrides.MUIDataTableToolbar.actions.display = 'block';
  }

  return (
    <MuiThemeProvider theme={tableTheme}>
      <MUIDataTable theme={tableTheme} data={data} columns={columns} options={optionsContainer} />
    </MuiThemeProvider>
  );
}

// CusotmTable.propTypes = {
//   // eslint-disable-next-line react/forbid-prop-types
//   data: Proptypes.arrayOf(Proptypes.object),
//   // eslint-disable-next-line react/forbid-prop-types
//   columns: Proptypes.arrayOf(Proptypes.object).isRequired,
//   options: Proptypes.objectOf(Proptypes.string).isRequired,
//   count: Proptypes.number,
// };

// CusotmTable.defaultProps = {
//   data: [],
//   count: 0,
// };

export default CusotmTable;
