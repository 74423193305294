import React, { useContext } from 'react';
import Proptypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { ListItem, ListItemText, Grid, Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import StyledBadge from '../Badge/StyledBadge';
import { SocketContext } from '../../Stores/SocketStore';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import styles from './TopNavLink.module.css';

const StyledCustomNavLink = styled(ListItem)`
  // padding-right: 0 !important;
  padding-bottom: 10px;
  padding-left: 0 !important;
  padding-right: 0 !important;
`;

const StyledRouteNavLink = styled(NavLink)`
  padding: 0 0.2em !important;
  padding-bottom: 5px;
  font-weight: normal;
  cursor: pointer;
  user-select: none;
  display: flex;
  color: #959595;
  background: ${(props) => props.theme.bgColor};
  text-decoration: none;
  height: 39px;
  font-weight: 600;
  transform: translateY(9px);
  font-size: 16px;

  &.active {
    color: #30feaf !important;
    border-bottom: #30feaf 4px solid !important;
  }

  & li > div {
    padding: 0 5px;
    margin: 0 0.5em;
    transform: translateY(-5px);
    padding-bottom: 8px;
  }
  &:hover {
    li > div {
      color: #30feaf;
    }
  }

  // @media screen and (min-width: 1920px) {
  //   & {
  //     padding: 0.5em 0 !important;
  //     height: 40px;
  //   }
  // }
`;

const StyledListItemText = styled(ListItemText)`
  & span {
    font-size: 16px;
    font-weight: 700;
  }
  //padding: 0 0.5em;

  // @media screen and (min-width: 1920px) {
  //   & span {
  //     font-size: 18px;
  //   }
  // }
`;

const StyledIconContainer = styled(Grid)`
  padding: 0 0.2em;
  align-self: center;

  & svg {
    font-size: 16px;
  }

  // @media screen and (min-width: 1920px) {
  //   & svg {
  //     font-size: 22px;
  //   }
  // }
`;

const StyledBadgeContainer = styled.div`
  transform: translate(6px, -6px);
`;

function TopNavLink(props) {
  const { title, pathname, count } = props;
  const { state, dispatch } = useContext(GlobalContext);
  const { themeContainer } = state;

  const badgeContainer = count ? (
    <StyledBadgeContainer>
      <ThemeProvider
        theme={{ color: themeContainer.badgeColor, bgColor: themeContainer.badgebgColor }}
      >
        <StyledBadge badgeContent={''} overlap="rectangular" />
      </ThemeProvider>
    </StyledBadgeContainer>
  ) : null;

  return (
    <ThemeProvider
      theme={{
        activeColor: themeContainer.sideNavLinkActiveColor,
        borderLeft: themeContainer.sideNavLinkBorderLeft,
        color: themeContainer.sideNavLinkColor,
        bgColor: themeContainer.sideNavLinkBgColor,
        activeBorderLeft: themeContainer.sideNavLinkActiveBorderLeft,
      }}
    >
      <StyledRouteNavLink
        className={styles.navLink}
        to={pathname}
        onClick={() => {
          dispatch({
            type: 'FILTER_NOTIFICATION_CONTAINER',
            payload: { type: title.toLowerCase() },
          });
        }}
      >
        <ListItem className={styles.customNavLink}>
          <Box display="flex" flexDirection="row">
            <span className={styles.listItemtext}>{title.toLowerCase()}</span>
            {badgeContainer}
          </Box>
        </ListItem>
      </StyledRouteNavLink>
    </ThemeProvider>
  );
}

TopNavLink.propTypes = {
  title: Proptypes.string.isRequired,
  pathname: Proptypes.string.isRequired,
  count: Proptypes.number,
};

export default React.memo(TopNavLink);
