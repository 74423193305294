import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../Stores/GlobalStateStore';
import { errorChecker, getPaginationUrl1 } from '../utils/helper/helper';
import baseUrl from '../utils/services/config';
import useDebounce from './useDebounce';

const paginationObj = {
  rowsPerPage: 10,
  page: 0,
  count: 0,
  searchValue: '',
  sortDirection: '',
};

const useServerSidePagination = ({
  apiEndpoint,
  depends,
  extraParameter,
  extraParameterDepends,
  // extraParameterPagination,
  // extraParameterPaginationDepends,
  originalData,
  secondParameter,
  secondParameterDepends,
  thirdParameter,
  thirdParameterDepends,
  fourthParameter,
  fourthParameterDepends,
  fifthParameter,
  fifthParameterDepends,
  sixthParameter,
  sixthParameterDepends,
  seventhParameter,
  seventhParameterDepends,
  sortBy,
}) => {
  const [tableData, setTableData] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({ ...paginationObj });
  // Flag to decide if data has to append to the array or not
  const [appendFlag, setAppendFlag] = useState(false);
  const [loader, setLoader] = useState(false);
  const [restore, setRestore] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const [secondParameterCount, setSecondParameterCount] = useState(0);
  const { dispatch } = useContext(GlobalContext);
  const debouncedSearchTerm = useDebounce(paginationInfo.searchValue, 500);

  // const {} = useFetch();
  const handlePaginationInfo = (page, rowsPerPage, count, searchValue) => {
    const paginationInfoCopy = { ...paginationInfo };
    paginationInfoCopy.count = count;
    paginationInfoCopy.page = page;
    paginationInfoCopy.rowsPerPage = rowsPerPage;
    paginationInfoCopy.searchValue = searchValue;
    setPaginationInfo({ ...paginationInfoCopy });
  };

  const clearSearch = () => setPaginationInfo({ ...paginationObj });

  // Function to append or mutate the table data
  const conditionalTableDataInit = (isAppend, list) => {
    // console.log(tableData, isAppend, list);
    if (isAppend) {
      setAppendFlag(false);
      setRestore(false);
      setTableData(tableData.concat(list));
    } else {
      setRestore(false);
      setTableData(list);
    }
  };
  const fetchData = async () => {
    if (depends) {
      try {
        setLoader(true);
        setRestore(false);
        const result = await baseUrl.get(
          getPaginationUrl1(
            apiEndpoint,
            paginationInfo.searchValue,
            paginationInfo.page,
            paginationInfo.rowsPerPage,
            extraParameter,
            extraParameterDepends,
            // extraParameterPagination,
            // extraParameterPaginationDepends,
            secondParameter,
            secondParameterDepends,
            sortBy,
            thirdParameter,
            thirdParameterDepends,
            fourthParameter,
            fourthParameterDepends,
            fifthParameter,
            fifthParameterDepends,
            sixthParameter,
            sixthParameterDepends,
            seventhParameter,
            seventhParameterDepends,
          ),
        );
        if (result.status === 200) {
          const { list, pageMeta } = result.data;
          conditionalTableDataInit(appendFlag, list);
          handlePaginationInfo(pageMeta.currentPage, pageMeta.perPage, pageMeta.total, '');
          setLoader(false);
          setSearchLoader(false);
        }
      } catch (err) {
        setLoader(false);
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  };

  useEffect(() => {
    if (depends) {
      fetchData();
    }
  }, [depends]);

  // Method to handle page change
  const handlePageChange = (value) => {
    if (originalData.length < paginationInfo.count) {
      handlePaginationInfo(value, paginationInfo.rowsPerPage, paginationInfo.count, '');
      setAppendFlag(true);
    }
  };

  // Effect to call API after append flag set to true

  useEffect(() => {
    if (appendFlag) {
      fetchData();
    }
  }, [appendFlag]);

  // // Method to handle rows per page change
  const handleRowPerPageChange = (value) => {
    handlePaginationInfo(0, value, paginationInfo.count, '');
    setRestore(true);
  };

  // // Effect to restore the table data

  useEffect(() => {
    if (restore) {
      fetchData();
    }
  }, [restore]);

  // Method to search the table
  const handleTableSearch = (value) => {
    handlePaginationInfo(0, paginationInfo.rowsPerPage, paginationInfo.count, value);
  };

  useEffect(() => {
    if (debouncedSearchTerm || debouncedSearchTerm === null) {
      setSearchLoader(true);
      setRestore(true);
    }
  }, [debouncedSearchTerm]);

  // Effect to call API for active and inactive list

  useEffect(() => {
    if (secondParameterCount > 0) {
      handlePaginationInfo(0, paginationInfo.rowsPerPage, paginationInfo.count, '');
      setSearchLoader(true);
      setRestore(true);
    }
    setSecondParameterCount(secondParameterCount + 1);
  }, [secondParameterDepends, secondParameter]);

  // Method to init the table state

  const handleInitTable = () => {
    handlePaginationInfo(0, paginationInfo.rowsPerPage, paginationInfo.count, '');
    setRestore(true);
  };

  return {
    paginationInfo,
    tableData,
    handlePageChange,
    loader,
    handleRowPerPageChange,
    handleTableSearch,
    handleInitTable,
    searchLoader,
    clearSearch,
  };
};

export default useServerSidePagination;
