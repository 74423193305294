import React, { useRef, useEffect } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import BubbleReview from './BubbleReview';
import DefaultReview from './DefaultReview';

const ReviewsGrid = (props) => {
  const { reviews, settings, postSites } = props;

  const useStyles = makeStyles(() => ({
    Grid__Container: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
      gap: settings.gap + 'px',
      display: 'grid',
    },
    List__Container: {
      gap: settings.gap + 'px',
      display: 'grid',
    },

    Review__Container: {
      backgroundColor: `${settings.colors.containerBgColor}${settings.colors.containerBgOpacity}`,
      position: 'relative',
      padding: '24px',
      transition: 'background-color 0.1s ease 0s',
      borderRadius: `${settings.radius}px`,
      display: 'flex',
      flexDirection: 'column',
    },

    Review__Bubble: {
      marginBottom: `${
        settings.widgetStyle === 'grid' ? '' : 'margin-bottom:' + settings.gap + 'px'
      }`,
    },
    Review__Container_Bubble: {
      backgroundColor: `${settings.colors.containerBgColor}${settings.colors.containerBgOpacity}`,
      position: 'relative',
      padding: '24px',
      transition: 'background-color 0.1s ease 0s',
      borderRadius: `${settings.radius}px`,
      display: 'flex',
      flexDirection: 'column',
    },
  }));

  const classes = useStyles();

  const getReviewStyle = (review) => {
    if (settings.reviewStyle === 'bubble') {
      return (
        <div className={classes.Review__Bubble}>
          <BubbleReview review={review} settings={settings} postSites={postSites} />
        </div>
      );
    } else {
      return <DefaultReview review={review} settings={settings} postSites={postSites} />;
    }
  };

  const reviewBlocks = reviews && reviews.map((review, index) => getReviewStyle(review));

  return (
    <div
      className={
        settings.widgetStyle === 'grid' ? classes.Grid__Container : classes.List__Container
      }
    >
      {reviewBlocks}
    </div>
  );
};

export default ReviewsGrid;
