import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Radio, FormControl, Box, Button, TextareaAutosize } from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import GenericPopup from '../../Popup/GenricPopup/index';
import { FormHeader } from '../../Forms/FormHeader';

const PrimaryButton = styled(Button)`
  background-color: #30feaf !important;
  margin-left: 10px !important;
  text-transform: capitalize !important;
  letter-spacing: unset !important;
  font-family: 'Montserrat' !important;
  font-weight: 600 !important;
`;

const StyledOutlineButton = styled(Button)`
  text-transform: capitalize !important;
  letter-spacing: unset !important;
  font-family: 'Montserrat' !important;
  font-weight: 600 !important;
`;

const StyledTextarea = styled(TextareaAutosize)`
  border: 2px solid #d3d3d3;
  border-radius: 6px;
  padding: 0.5rem;
  font-size: 16px;
  font-family: 'Montserrat' !important;
  resize: none;
`;

function AddNotePopover(props) {
  const { open, handleClose, handleAddNote } = props;
  const [noteContent, setNoteContent] = useState();

  return (
    <GenericPopup open={open} maxWidth="xs" fullWidth handleClose={handleClose}>
      <Box padding={'2rem'}>
        <FormControl component="fieldset" fullWidth>
          <FormHeader>Add Note</FormHeader>
          <StyledTextarea
            aria-label="minimum height"
            minRows={3}
            placeholder="Add internal note..."
            onChange={(e) => setNoteContent(e.target.value)}
            autoFocus
          />
        </FormControl>
        <Box justifyContent={'flex-end'} display="flex" marginTop={'30px'}>
          <StyledOutlineButton title="cancel" variant="outlined" onClick={() => handleClose()}>
            Cancel
          </StyledOutlineButton>
          <PrimaryButton
            title="assign"
            variant="contained"
            onClick={() => [handleAddNote(noteContent), handleClose()]}
          >
            Add Note
          </PrimaryButton>
        </Box>
      </Box>
    </GenericPopup>
  );
}

export default React.memo(AddNotePopover);
