const portalJson = (businessLogo) => {
  return {
    guided: {
      screens: [
        {
          screen: 'sms',
          title:
            'Hi @customerName, it was a pleasure to serve you at @business. Please leave us a review by clicking the link below. Thanks! @link',
          emailTitle: 'Thank you for your business',
          uploadedFile: null,
          emailImgUrl: '',
          emailUploadFile: null,
        },
        {
          type: 'setup',
          screen: 'setup',
          title: 'Want to leave your review?',
          positiveButtonText: 'Use this Site',
          negativeButtonText: 'click, setup my sites',
          uploadedFile: null,
          backgroundColor: '#1877F2',
          color: '#fff',
          url: '',
        },
        // {
        //   screen: 'postitiveFeedback',
        //   postSites: [],
        //   title: 'Please choose where you would like to leave feedback.',
        //   negativeButtonText: 'Text Us Feedback',
        //   url: businessLogo,
        //   uploadedFile: null,
        // },
        {
          screen: 'negativefeedback',
          title:
            'Our goal is to provide the best experience possible to our customers.  We are sorry that we have fallen short of your expectations.  Please help us do better by letting us know more about your experience with us.',
          positiveButtonText: 'Send Feedback',
          url: businessLogo,
          uploadedFile: null,
        },
        {
          screen: 'thankyou',
          title: 'Thank you for your time, we look forward to serving you in the future',
          url: businessLogo,
          uploadedFile: null,
        },
      ],
    },
    semiFiltered: {
      screens: [
        {
          screen: 'sms',
          title:
            'Hi @customerName, it was a pleasure to serve you at @business. Please leave us a review by clicking the link below. Thanks! @link',
          emailTitle: 'Thank you for your business',
        },
        {
          screen: 'recommend',
          title: 'Would you recommend us to others?',
          positiveButtonText: 'Yes!',
          negativeButtonText: 'No, Thank You',
          url: businessLogo,
          uploadedFile: null,
        },
        {
          screen: 'postitiveFeedback',
          postSites: [],
          title: 'Please choose where you would like to leave feedback.',
          negativeButtonText: 'Text Us Feedback',
          url: businessLogo,
          uploadedFile: null,
        },
        {
          screen: 'negativefeedback',
          title:
            'Our goal is to provide the best experience possible to our customers.  We are sorry that we have fallen short of your expectations.  Please help us do better by letting us know more about your experience with us.',
          positiveButtonText: 'Send Feedback',
          url: businessLogo,
          uploadedFile: null,
        },
        {
          screen: 'thankyou',
          title: 'Thank you for your time, we look forward to serving you in the future',
          url: businessLogo,
          uploadedFile: null,
        },
      ],
    },
    simple: {
      screens: [
        {
          screen: 'sms',
          title:
            'Hi @customerName, it was a pleasure to serve you at @business. Please leave us a review by clicking the link below. Thanks! @link',
          emailTitle: 'Thank you for your business',
          url: '',
          uploadedFile: null,
        },
        {
          screen: 'postitiveFeedback',
          postSites: [],
          title: 'Please choose where you would like to leave feedback.',
          negativeButtonText: 'Text Us Feedback',
          url: businessLogo,
          uploadedFile: null,
        },
        {
          screen: 'thankyou',
          title: 'Thank you for your time, we look forward to serving you in the future',
          url: businessLogo,
          uploadedFile: null,
        },
      ],
    },
  };
};

export default portalJson;

export const autoReview = (businessLogo) => {
  return {
    screen: 'auto-review-reminder',
    title:
      'Thank you so much for your business!  We just wanted to remind you to please take a minute to leave a review about your experience for others to see by clicking the link in the previous text message above.  Thanks!',
    uploadedFile: null,
    url: businessLogo,
  };
};
