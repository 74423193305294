/* eslint-disable max-len */
import { useCallback, useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../Stores/GlobalStateStore';
import { SurveyContext } from '../Stores/SurveyStore';
import { errorChecker } from '../utils/helper/helper';
import baseUrl from '../utils/services/config';

const useFetchSurvey = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { surveyData, surveyDispatch } = useContext(SurveyContext);

  const { selectedLoaction, selectedBusiness } = state;
  const [surveys, setSurveys] = useState([]);
  const [surveyTypes, setSurveyTypes] = useState({});
  const [summary, setSummary] = useState([]);
  const [distribution, setDistribution] = useState({});
  const [overrallPerformance, setoverrallPerformance] = useState([]);
  const [summaryDetail, setSummaryDetail] = useState([]);
  const [questionsPercentage, setquestionsPercentage] = useState([]);
  const [questionScore, setQuestionsScore] = useState([]);
  const fetchSurvey = useCallback(async () => {
    if (selectedLoaction) {
      try {
        const result = await baseUrl.get(
          `/group/campaing/list?location=${selectedLoaction}&user=${localStorage.getItem(
            'USER_ID',
          )}`,
        );
        if (result.status === 200) {
          setSurveys(Array.from(result.data));
        }
      } catch (err) {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  }, [selectedLoaction]);

  // eslint-disable-next-line no-unused-vars
  const getSurveyByBusiness = async () => {
    try {
      const result = await baseUrl.get(
        `/survey?where={"business":${state.selectedBusiness},"isDeleted":false}`,
      );
      if (result) {
        if (result.data) {
          surveyDispatch({ type: 'SET_SURVEYS_BUSINESS', payload: result.data });
        }
      }
    } catch (err) {
      surveyDispatch({ type: 'SET_SURVEYS_BUSINESS', payload: [] });
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };
  const getAnswersFromASurvey = async ({
    surveyId,
    analyticsId,
    clientId,
    dateStart,
    dateEnd,
    locationId,
  }) => {
    try {
      const result = await baseUrl.get(
        `/survey/location-details?surveyId=${surveyId}&analyticsId=${analyticsId}&locationId=${selectedLoaction}&dateStart=${dateStart}&dateEnd=${dateEnd}&clientId=${clientId}${locationId ? `&locationId=${locationId}` : ``
        }`,
      );
      if (result) {
        if (result.data) {
          const { questions } = surveyData.surveyByBusiness.find(
            (item) => item.id === surveyId,
          ).questions;
          const questionsAndResponse = result.data.questions
            .map((item) => {
              const question = questions.find((q) => q.code === item.questionCode);
              if (question) {
                return {
                  questionCode: item.questionCode,
                  answerValue: item.answerValue,
                  question: question.text.replace("@propertyName", result.data.locationName),
                  type: question.type,
                };
              }
              return null;
            })
            .filter((item) => item !== null);
          const databody = {
            firstName: result.data.firstName,
            lastName: result.data.lastName,
            emailAddress: result.data.emailAddress,
            phoneNumber: result.data.phoneNumber,
            locationName: result.data.locationName,
            surveyName: result.data.surveyName,
            surveyId: result.data.surveyId,
            questions: questionsAndResponse,
            createdDate: result.data.createdDate,
          };

          surveyDispatch({ type: 'SET_SURVEYS_LOCATION_DETAILS', payload: databody });
        }
      }
    } catch (err) {
      surveyDispatch({ type: 'SET_SURVEYS_LOCATION_DETAILS', payload: {} });
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };
  const getSurveyQuestionsAverage = async ({
    surveyId,
    businessId,
    dateStart,
    dateEnd,
    locationId = null,
  }) => {
    try {
      const endpoint1 = `survey/rating-score-by-question?surveyId=${surveyId}&businessId=${businessId}&dateStart=${dateStart}&dateEnd=${dateEnd}${locationId ? `&locationId=${locationId}` : ''
        }`;
      const endpoint2 = `survey/count-yn-question?surveyId=${surveyId}&businessId=${businessId}&dateStart=${dateStart}&dateEnd=${dateEnd}${locationId ? `&locationId=${locationId}` : ''
        }`;

      const [result1, result2] = await Promise.all([
        baseUrl.get(endpoint1),
        baseUrl.get(endpoint2),
      ]);

      if (result1 && result1.data.length && result2 && result2.data.length) {
        const combinedResult = [
          ...result1.data.filter((data) => data.average !== 0),
          ...result2.data.filter((data) => data.total_count !== 0),
        ];

        surveyDispatch({ type: 'SET_SURVEY_QUESTIONS_AVERAGE', payload: combinedResult });
      } else {
        surveyDispatch({ type: 'SET_SURVEY_QUESTIONS_AVERAGE', payload: [] });
      }
    } catch (err) {
      surveyDispatch({ type: 'SET_SURVEY_QUESTIONS_AVERAGE', payload: [] });
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };
  const getSurveyBusinessList = async ({
    surveyId,
    businessId,
    dateStart,
    dateEnd,
    locationId = null,
  }) => {
    try {
      const result = await baseUrl.get(
        `survey/business-list?surveyId=${surveyId}&businessId=${businessId}&dateStart=${dateStart}&dateEnd=${dateEnd}${locationId ? `&locationId=${locationId}` : ``
        }`,
      );
      if (result) {
        if (result.data.length) {
          surveyDispatch({ type: 'SET_SURVEY_BUSINESS_RESPONSES', payload: result.data });
        } else {
          surveyDispatch({ type: 'SET_SURVEY_BUSINESS_RESPONSES', payload: [] });
        }
      }
    } catch (err) {
      surveyDispatch({ type: 'SET_SURVEY_BUSINESS_RESPONSES', payload: [] });
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };
  const getSummary = async ({ surveyId, businessId, dateStart, dateEnd }) => {
    try {
      const result = await baseUrl.get(
        `/survey/location-summary?surveyId=${surveyId}&businessId=${businessId}&dateStart=${dateStart}&dateEnd=${dateEnd}`,
      );
      if (result.status === 200) {
        if (result.data.length) {
          setSummary(Array.from(result.data));
        } else {
          setSummary([]);
        }
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };
  const getDistribution = async ({ surveyId, businessId, dateStart, dateEnd }) => {
    try {
      const result = await baseUrl.get(
        `/survey/distribution?surveyId=${surveyId}&businessId=${businessId}&dateStart=${dateStart}&dateEnd=${dateEnd}`,
      );

      if (result.status === 200) {
        if (result.data) {
          setDistribution(result.data);
        } else {
          setDistribution({});
        }
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  // eslint-disable-next-line no-unused-vars
  const getSummaryDetailByLocacion = async ({ surveyId, businessId, dateStart, dateEnd }) => {
    try {
      const result = await baseUrl.get(
        `/survey/location-summary?surveyId=${surveyId}&businessId=${businessId}&dateStart=${dateStart}&dateEnd=${dateEnd}`,
      );
      if (result.status === 200) {
        setSummaryDetail(Array.from(result.data));
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
    return [];
  };
  const getQuestionsPercentage = async ({
    surveyId,
    businessId,
    dateStart,
    dateEnd,
    locationId,
    questionCode,
  }) => {
    try {
      const result = await baseUrl.get(
        `/survey/questions-percentage?questionCode=${questionCode}&surveyId=${surveyId}&businessId=${businessId}&dateStart=${dateStart}&dateEnd=${dateEnd}${locationId ? `&locationId=${locationId}` : ``
        }`,
      );
      if (result.status === 200) {
        setquestionsPercentage(Array.from(result.data));
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
    return [];
  };

  const getQuestionsScore = async ({
    surveyId,
    businessId,
    dateStart,
    dateEnd,
    locationId,
    questionCode,
  }) => {
    try {
      const result = await baseUrl.get(
        `/survey/rating-score-by-question?questionCode=${questionCode}&surveyId=${surveyId}&businessId=${businessId}&dateStart=${dateStart}&dateEnd=${dateEnd}${locationId ? `&locationId=${locationId}` : ``
        }`,
      );
      if (result.status === 200) {
        setQuestionsScore(Array.from(result.data));
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
    return [];
  };

  const getOverallSummary = async ({
    surveyId,
    businessId,
    dateStart,
    dateEnd,
    grouping,
    locationId = null,
  }) => {
    try {
      const result = await baseUrl.get(
        // eslint-disable-next-line max-len
        `/survey/overrall-performance?surveyId=${surveyId}${locationId ? `&locationId=${locationId}` : ''
        } &start=${dateStart}&end=${dateEnd}&businessId=${businessId}&grouping=${grouping}`,
      );
      if (result.status === 200) {
        setoverrallPerformance(Array.from(result.data));
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
    return [];
  };

  const getSurveyType = async () => {
    try {
      const result = await baseUrl.get(`/survey/get-surveys?businessId=${selectedBusiness}`);
      if (result.status === 200) {
        if (result.data) {
          setSurveyTypes(result.data);
        } else {
          setSurveyTypes({});
        }
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  useEffect(() => {
    fetchSurvey();
    getSurveyByBusiness();
  }, [selectedLoaction, selectedBusiness]);
  return {
    surveys,
    getSummary,
    summary,
    getSummaryDetailByLocacion,
    summaryDetail,
    getOverallSummary,
    overrallPerformance,
    getSurveyByBusiness,
    getDistribution,
    distribution,
    getSurveyQuestionsAverage,
    getSurveyBusinessList,
    getAnswersFromASurvey,
    getQuestionsPercentage,
    getQuestionsScore,
    questionsPercentage,
    questionScore,
    surveyTypes,
    getSurveyType,
  };
};

export default useFetchSurvey;
