import React, { useContext, useState, useEffect } from 'react';
import Proptypes from 'prop-types';
import { Grid, makeStyles, Popover } from '@material-ui/core';
import { ThemeProvider } from 'styled-components';
import { useHistory } from 'react-router';
import { OnBoardingContext } from '../../../Stores/OnBoarding';
import ContactInfoList from '../../List/ContactInfoList/ContactInfoList';
import CustomButton from '../../CustomButton/CustomButton';
import { filterByReference, getSocialSiteLastIndex } from '../../../utils/helper/helper';
import useButtonTheme from '../../../hooks/useButtonTheme';
import NoDataFound from '../../NoDataFound/NoDataFound';

const useStyles = makeStyles((theme) => ({
  popover: {
    padding: '.5em',
    minWidth: '200px',
    maxWidth: '250px',
  },
  paper: {
    padding: theme.spacing(1),
    minWidth: '200px',
    maxWidth: '250px',
    background: theme.background,
  },
}));

function AddNetworkPopover(props) {
  const { id, open, anchorEl, handleClose, radioValue, activeStep, isAddPostSite } = props;
  const { onBoardingDispatch, onBoardingData } = useContext(OnBoardingContext);
  const [selectedPostSite, setSelectedPostsite] = useState(null);
  const [postSitesContainer, setPostSitesContainer] = useState([]);
  const { buttonTheme } = useButtonTheme();
  const { postSites, defaultPortals } = onBoardingData;
  const classes = useStyles();
  const history = useHistory();
  const handleSelection = (index) => {
    setSelectedPostsite(index);
  };

  useEffect(() => {
    if (postSites && postSites !== undefined && postSites.length) {
      let postSitesContainerCopy = [];
      const socialSites = [];

      defaultPortals[radioValue].screens.forEach((item) => {
        if (item.screen === 'social-site') {
          socialSites.push(item.postSites);
        }
      });
      const { screen } = defaultPortals[radioValue].screens[activeStep];

      postSites.forEach((postSite) => {
        if (!postSite.name.toLowerCase().includes('twitter'))
          postSitesContainerCopy.push({
            ...postSite,
            isSelected: false,
          });
      });
      if (screen === 'postitiveFeedback') {
        postSitesContainerCopy = filterByReference(
          postSitesContainerCopy,
          defaultPortals[radioValue].screens[activeStep].postSites,
        );
      } else if (screen === 'social-site') {
        postSitesContainerCopy = filterByReference(postSitesContainerCopy, socialSites);
      }
      setPostSitesContainer(postSitesContainerCopy);
    } else if (open && postSites && postSites !== undefined && !postSites.length) {
      setTimeout(() => {
        localStorage.setItem('portalInfo', JSON.stringify(defaultPortals));
        localStorage.setItem('subType', radioValue);
        localStorage.setItem('redirectToReviewRequest', true);
        history.push('/app/setup/connections');
      }, 1000);
    }
  }, [postSites, open]);

  const handleSelectedNetwork = () => {
    // Modified functionality for post sites selection
    const defaultPortalCopy = { ...defaultPortals };
    if (isAddPostSite) {
      defaultPortalCopy[radioValue].screens[activeStep].postSites.push(
        postSitesContainer[selectedPostSite],
      );
    } else {
      const site = {
        type: postSitesContainer[selectedPostSite].name,
        screen: 'social-site',
        title: `Use ${postSitesContainer[selectedPostSite].name} to leave your review?`,
        positiveButtonText: `Use ${postSitesContainer[selectedPostSite].name}`,
        negativeButtonText: 'No, Use A Different Site',
        uploadedFile: null,
        backgroundColor: postSitesContainer[selectedPostSite].backgroundColor,
        color: '#fff',
        url: postSitesContainer[selectedPostSite].url,
        postSites: postSitesContainer[selectedPostSite],
      };

      defaultPortalCopy[radioValue].screens.splice(
        getSocialSiteLastIndex(defaultPortalCopy[radioValue].screens),
        0,
        site,
      );
      const setupScreenIndex = defaultPortalCopy[radioValue].screens.findIndex(
        (item) => item.screen === 'setup',
      );
      if (setupScreenIndex !== -1) {
        defaultPortalCopy[radioValue].screens.splice(setupScreenIndex, 1);
      }
    }
    const postSitesContainerCopy = Array.from(postSitesContainer);
    postSitesContainerCopy[selectedPostSite].isSelected = true;
    setPostSitesContainer(postSitesContainerCopy);
    onBoardingDispatch({
      type: 'SET_DEFAULT_PORTAL',
      payload: defaultPortalCopy,
    });

    setSelectedPostsite(null);
    handleClose();
  };

  const networkListContainer =
    postSitesContainer && postSitesContainer.length
      ? postSitesContainer.map((postSite, index) => {
          return !postSite.isSelected ? (
            <ContactInfoList
              name={postSite.name}
              showPhone={false}
              src={`${process.env.REACT_APP_IMAGE_ENDPOINT}${postSite.url}`}
              index={index}
              handleSelection={handleSelection}
              selectedUser={selectedPostSite}
            />
          ) : (
            ''
          );
        })
      : '';

  const noDataFoundContent =
    [...postSitesContainer.filter((item) => !item.isSelected)].length === 0 ? (
      <NoDataFound>No data found!</NoDataFound>
    ) : (
      ''
    );

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      className={classes.popover}
      classes={{
        paper: classes.paper,
      }}
    >
      <Grid container>
        <Grid item style={{ maxHeight: '150px', overflow: 'auto', width: '100%' }}>
          {networkListContainer}
          {noDataFoundContent}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <ThemeProvider
            theme={{ background: buttonTheme.buttonBgColor, color: buttonTheme.fontColor3 }}
          >
            <CustomButton
              type="submit"
              color="primary"
              onClick={handleSelectedNetwork}
              disabled={selectedPostSite === null}
            >
              Select
            </CustomButton>
          </ThemeProvider>
        </Grid>
      </Grid>
    </Popover>
  );
}

AddNetworkPopover.propTypes = {
  id: Proptypes.string.isRequired,
  open: Proptypes.bool.isRequired,
  anchorEl: Proptypes.node.isRequired,
  handleClose: Proptypes.func.isRequired,
  isAddPostSite: Proptypes.bool,
  radioValue: Proptypes.string.isRequired,
  activeStep: Proptypes.number.isRequired,
};

AddNetworkPopover.defaultProps = {
  isAddPostSite: true,
};

export default AddNetworkPopover;
