import { Box } from '@material-ui/core';
import React from 'react';
import GenricPopup from '../../Popup/GenricPopup';
import Request from '../SendInvitePopover/components/AddCampaign/Request';

const CreateReviewRequestPopover = ({ campaignCreationPopup, selectedId = null }) => {
  const handleClose = () => {
    campaignCreationPopup.handleHideShowModal();
  };

  return (
    <GenricPopup
      open={campaignCreationPopup.showModal}
      handleClose={campaignCreationPopup.handleHideShowModal}
      maxWidth="md"
      fullWidth
    >
      <Box style={{ height: 'auto', margin: '0 1rem 1rem 1rem' }}>
        <Request handleClose={handleClose} campaignId={selectedId} />
      </Box>
    </GenricPopup>
  );
};

export default CreateReviewRequestPopover;
