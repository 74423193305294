const { default: styled } = require('styled-components');
const { CircularProgress } = require('@material-ui/core');

const ButtonLoader = styled(CircularProgress)`
  width: ${(props) => (props.theme.width ? props.theme.width : '15px')} !important;
  height: ${(props) => (props.theme.height ? props.theme.height : '15px')} !important;
  margin: ${(props) => (props.theme.margin ? props.theme.margin : '0 0.5em')} !important;
  color: ${(props) => props.theme.color} !important;
`;

export default ButtonLoader;
