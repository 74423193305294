import React, { useContext } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Grid, makeStyles, Avatar } from '@material-ui/core';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import PhoneIcon from '@material-ui/icons/Phone';
import WebsiteIcon from '@material-ui/icons/Language';
import EmailIcon from '@material-ui/icons/Email';
import MessageIcon from '@material-ui/icons/Message';
import NavigationIcon from '@material-ui/icons/LocationOn';
import logo from '../../assets/images/Reveo_Logo_Gray.svg';
import CompanyLogo from '../CompanyLogo/CompanyLogo';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import { imageURLCheck } from '../../utils/helper/helper';

const ContactIcons = styled(Grid)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
  column-gap: 5px;
  row-gap: 20px;
  width: 150px;
  margin: 1.5rem 0;
`;
const ContactTag = styled.div`
  font-size: 0.3rem;
  text-decoration: none;
  color: ${(props) => props.theme.color};
  underline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledButton = styled.button`
  font-size: 0.5rem;
  border: none;
  font-weight: 600;
  padding: 0.4rem 1rem;
  border-radius: 3rem;
`;

const iconSize = '1.2rem';

const BusinessCardPreview = (props) => {
  const {
    headerColor,
    backgroundColor,
    iconColor,
    textColor,
    businessInfo,
    locationImages,
    isUserImageAvatar,
    isHeaderImage,
    headerImage,
  } = props;
  const currentUser = localStorage.getItem('USER_ID');
  const currentLocation = localStorage.getItem('SELECTED_LOCATION');
  const { state } = useContext(GlobalContext);

  const businessLogo = locationImages && locationImages.url;

  const useStyles = makeStyles(() => ({
    avatar: {
      width: '45px',
      height: '45px',
      borderRadius: '40px',
      marginTop: '-35px',
      border: `4px solid ${backgroundColor}`,
      backgroundColor: 'lightgrey',
    },
    userName: {
      textAlign: 'center',
      fontSize: '.8rem',
      marginTop: '.5rem',
      fontWeight: '400',
      color: `${textColor}`,
    },
    locationName: {
      fontSize: '.5rem',
      color: convertToRgba(textColor),
    },
  }));

  const classes = useStyles();

  const contact =
    businessInfo && businessInfo.user?.filter((user) => user.id === parseInt(currentUser));
  const locationInfo =
    businessInfo &&
    businessInfo.location?.filter((location) => location.id === parseInt(currentLocation));
  const socialLink = locationInfo && locationInfo[0]?.contact;

  function convertToRgba(color) {
    if (color.slice(0, 3) === 'rgb') {
      return color.replace(')', ', 0.30)');
    } else {
      const hex = color.replace('#', '');
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
      return `rgba(${r}, ${g}, ${b}, 0.30)`;
    }
  }

  const twitterContent =
    socialLink.twitter !== undefined ? (
      <ThemeProvider theme={{ color: `${convertToRgba(textColor)}` }}>
        <ContactTag>
          <a href={socialLink.twitter}>
            <TwitterIcon style={{ color: iconColor, fontSize: iconSize }} />
          </a>
          Twitter
        </ContactTag>{' '}
      </ThemeProvider>
    ) : (
      ''
    );

  const instagramContent =
    socialLink.instagram !== undefined ? (
      <ThemeProvider theme={{ color: `${convertToRgba(textColor)}` }}>
        <ContactTag>
          <a href={socialLink.instagram}>
            <InstagramIcon style={{ color: iconColor, fontSize: iconSize }} />
          </a>{' '}
          Instagram
        </ContactTag>{' '}
      </ThemeProvider>
    ) : (
      ''
    );
  const facebookContent =
    socialLink.facebook !== undefined ? (
      <ThemeProvider theme={{ color: `${convertToRgba(textColor)}` }}>
        <ContactTag>
          <a href={socialLink.facebook}>
            <FacebookIcon style={{ color: iconColor, fontSize: iconSize }} />
          </a>{' '}
          Facebook
        </ContactTag>{' '}
      </ThemeProvider>
    ) : (
      ''
    );

  const youtubeContent =
    socialLink.youTube !== undefined ? (
      <ThemeProvider theme={{ color: `${convertToRgba(textColor)}` }}>
        <ContactTag>
          <a href={socialLink.youTube}>
            <YouTubeIcon style={{ color: iconColor, fontSize: iconSize }} />
          </a>{' '}
          YouTube
        </ContactTag>
      </ThemeProvider>
    ) : (
      ''
    );
  const linkdinContent =
    socialLink.linkedIn !== undefined ? (
      <ThemeProvider theme={{ color: `${convertToRgba(textColor)}` }}>
        <ContactTag>
          <a href={socialLink.linkedIn}>
            <LinkedInIcon style={{ color: iconColor, fontSize: iconSize }} />
          </a>{' '}
          LinkedIn
        </ContactTag>{' '}
      </ThemeProvider>
    ) : (
      ''
    );
  const callMeContent =
    socialLink?.phoneNo !== undefined ? (
      <ThemeProvider theme={{ color: `${convertToRgba(textColor)}` }}>
        <ContactTag>
          <a href={`tel:${socialLink.phoneNo}`}>
            <PhoneIcon style={{ color: iconColor, fontSize: iconSize }} />
          </a>{' '}
          Call
        </ContactTag>{' '}
      </ThemeProvider>
    ) : (
      ''
    );
  const websiteContent =
    socialLink.webSite !== undefined ? (
      <ThemeProvider theme={{ color: `${convertToRgba(textColor)}` }}>
        <ContactTag>
          <a href={socialLink.webSite}>
            <WebsiteIcon style={{ color: iconColor, fontSize: iconSize }} />
          </a>{' '}
          Website
        </ContactTag>{' '}
      </ThemeProvider>
    ) : (
      ''
    );
  const emailContent =
    socialLink?.emailAddress !== undefined ? (
      <ThemeProvider theme={{ color: `${convertToRgba(textColor)}` }}>
        <ContactTag>
          <a href={`mailto:${socialLink.emailAddress}`}>
            <EmailIcon style={{ color: iconColor, fontSize: iconSize }} />
          </a>{' '}
          E-mail
        </ContactTag>{' '}
      </ThemeProvider>
    ) : (
      ''
    );

  const messageContent =
    socialLink?.phoneNo !== undefined ? (
      <ThemeProvider theme={{ color: `${convertToRgba(textColor)}` }}>
        <ContactTag>
          <a href={`sms:${socialLink.phoneNo}`}>
            <MessageIcon style={{ color: iconColor, fontSize: iconSize }} />
          </a>{' '}
          Message
        </ContactTag>{' '}
      </ThemeProvider>
    ) : (
      ''
    );

  const navigationContent =
    socialLink.addressLine1 !== undefined ? (
      <ThemeProvider theme={{ color: `${convertToRgba(textColor)}` }}>
        <ContactTag>
          <a href={`https://google.com/maps/place/${socialLink.addressLine1}`}>
            <NavigationIcon style={{ color: iconColor, fontSize: iconSize }} />
          </a>{' '}
          Directions
        </ContactTag>
      </ThemeProvider>
    ) : (
      ''
    );

  const imageType = () => {
    return isUserImageAvatar ? (
      <img className={classes.avatar} src={imageURLCheck(state.profilePic)} />
    ) : (
      <img className={classes.avatar} src={imageURLCheck(businessLogo)} />
    );
  };

  const headerColorOrImage = () => {
    return isHeaderImage ? (
      <div
        style={{
          background: `url(${imageURLCheck(headerImage)})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '45px',
        }}
      ></div>
    ) : (
      <div style={{ backgroundColor: headerColor, height: '45px' }}></div>
    );
  };

  return (
    <div style={{ backgroundColor: backgroundColor, height: '100%', zIndex: 1 }}>
      <div style={{ backgroundColor: '#000', height: '32px' }}></div>
      {headerColorOrImage()}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {imageType()}
        <div className={classes.userName}>{`${contact[0]?.contact.fullName}`}</div>
        <div className={classes.locationName}>{locationInfo[0]?.name}</div>
        <ContactIcons>
          {callMeContent}
          {messageContent}
          {emailContent}
          {navigationContent}
          {facebookContent}
          {twitterContent}
          {linkdinContent}
          {youtubeContent}
          {instagramContent}
          {websiteContent}
        </ContactIcons>
        <StyledButton style={{ color: backgroundColor, backgroundColor: iconColor }}>
          Add me to your contacts
        </StyledButton>
        <Grid
          item
          style={{
            position: 'absolute',
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '1rem',
            fontSize: '.2rem',
            alignItems: 'center',
            color: '#6a6a6a',
          }}
        >
          Powered By
          <ThemeProvider theme={{ width: '39px', height: 'auto', marginTop: '.2rem' }}>
            <CompanyLogo logo={logo} alt={logo} />
          </ThemeProvider>
        </Grid>{' '}
      </div>
    </div>
  );
};

export default BusinessCardPreview;
