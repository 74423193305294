import React from 'react';
import Proptypes from 'prop-types';
import { styled } from '@material-ui/styles';
import { DialogContent, DialogActions, Box } from '@material-ui/core';
import ButtonLoader from '../../ButtonLoader/ButtonLoader';
import GenericPopup from '../GenricPopup/index';
import useButtonTheme from '../../../hooks/useButtonTheme';
import PrimaryButton from '../../Common/PrimaryButton';
import PageTitle from '../../PageTitle/PageTitle';

function CancellationPopup(props) {
  const { show, handleClose, showDeletionPopUp, deleteLoader, title, description, buttonTitle } =
    props;
  const showLoader = deleteLoader ? <ButtonLoader /> : null;
  return (
    <GenericPopup open={show} maxWidth="xs" onClose={handleClose}>
      <Box padding="1rem" display="flex" flexDirection="column" gap=".5rem">
        <Box margin=".5rem .5rem 1rem .5rem">
          <PageTitle title={title} />
          <p style={{ fontSize: '14px' }}>{description}</p>
        </Box>
        <Box marginLeft="auto" display="flex" style={{ columnGap: '.5rem' }}>
          <PrimaryButton text onClick={handleClose}>
            Cancel
          </PrimaryButton>
          <PrimaryButton onClick={showDeletionPopUp} color="primary" disabled={deleteLoader}>
            {buttonTitle}
            {showLoader}
          </PrimaryButton>
        </Box>
      </Box>
    </GenericPopup>
  );
}

CancellationPopup.propTypes = {
  show: Proptypes.bool.isRequired,
  handleClose: Proptypes.func.isRequired,
  showDeletionPopUp: Proptypes.func.isRequired,
  deleteLoader: Proptypes.bool,
  title: Proptypes.string,
  description: Proptypes.string,
  buttonTitle: Proptypes.string,
};

CancellationPopup.defaultProps = {
  title: 'Are you sure?',
  description: 'Do you really want to delete these records?',
  buttonTitle: 'Delete',
};

export default CancellationPopup;
