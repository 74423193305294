import React from 'react';
import styled from 'styled-components';

const StyledFormLabel = styled.div`
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 30px;
`;

export const FormHeader = (props) => {
  const { children } = props;
  return <StyledFormLabel>{children}</StyledFormLabel>;
};
