import { Box, MuiThemeProvider, Grid } from '@material-ui/core';
import React, { useContext, useState, useEffect } from 'react';
import GalleryPopup from '../../../Popup/Gallarypopup';
import useModal from '../../../../hooks/useModal';
import { errorChecker, convertIntoCampaignCards } from '../../../../utils/helper/helper';
import { GlobalContext } from '../../../../Stores/GlobalStateStore';
import { CampaignContext } from '../../../../Stores/CamapignStore';
import baseUrl from '../../../../utils/services/config';
import PrimaryButton from '../../../Common/PrimaryButton';
import AntTab from '../../../StyledTabs/StyledTabs';
import AntTabs from '../../../StyledTabsContainer/StyledTabsContainer';
import useTabsTheme from '../../../../hooks/useTabsTheme';
import TemplateTitleEdit from '../../SendInvitePopover/TemplateTitleEdit';
import ContainerLoader from '../../SendInvitePopover/ContainerLoader';
import { SettingsSidebar } from './components/SettingsSidebar';
import FacebookPost from './components/PostSites/FacebookPost';

const containerStyles = {
  margin: '1rem 1rem 1rem .5rem',
  height: '50vh',
  rowGap: '1rem',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
};

const footerStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0 1rem 1rem 1rem',
};

const SocialMediaPostCreator = ({ handleClose, campaignId }) => {
  const [tabValue, setTabValue] = useState(0);

  const [bodyRequest, setBodyRequest] = useState({
    backgroundImage: {},
    autoHashTags: true,
    hashtags: '',
    customBgImage: false,
    colors: {
      overlayColor1: {
        bgColor: { r: 0, g: 0, b: 0, a: 0.8 },
        isDarkText: false,
      },
      overlayColor2: {
        bgColor: { r: 117, g: 117, b: 117, a: 0.8 },
        isDarkText: false,
      },
      overlayColor3: {
        bgColor: { r: 48, g: 254, b: 175, a: 0.8 },
        isDarkText: true,
      },
      overlayColor4: {
        bgColor: { r: 0, g: 0, b: 0, a: 0.8 },
        isDarkText: false,
      },
      overlayColor5: {
        bgColor: { r: 255, g: 255, b: 255, a: 0.8 },
        isDarkText: true,
      },
    },
    isPreview: false,
    name: '',
  });

  const [selectedColor, setSelectedColor] = useState('overlayColor1');

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  // const ContactAlert = useAlert();
  const { tabsTheme } = useTabsTheme();

  const [isLoading, setisLoading] = useState(false);

  const [buttonIsDisabled, setbuttonIsDisabled] = useState(false);

  const gallaryModal = useModal();
  const { state, dispatch } = useContext(GlobalContext);
  const { campaignStates, campaignDispatch } = useContext(CampaignContext);
  const [campaignViewToggler] = useState('socialimage');

  const { campaignToEdit } = campaignStates;
  const { selectedLoaction, locationInfo } = state;

  const handleCancel = () => {
    handleClose();
  };

  useEffect(() => {
    if (bodyRequest.name.length < 1) {
      setbuttonIsDisabled(true);
    } else {
      setbuttonIsDisabled(false);
    }
  }, [bodyRequest.name]);

  const handleSaveTemplate = async (isClone) => {
    setisLoading(true);

    try {
      const postBody = {
        // eslint-disable-next-line radix
        user: parseInt(localStorage.getItem('USER_ID')),
        // eslint-disable-next-line radix
        location: selectedLoaction,
        name: isClone ? bodyRequest.name + ' [copy]' : bodyRequest.name,
        campaignType: 'social post',
        subType: 'facebook',
        settings: bodyRequest,
        message: bodyRequest.name,
        uploadedFile: [bodyRequest.backgroundImage.fileId],
      };

      const result = await baseUrl.post('/campaign', { ...postBody });
      if (result.status === 200) {
        setisLoading(false);

        handleClose();

        campaignDispatch({
          type: 'UPDATE_CAMPAIGN_CONTAINER',
          payload: Array.from(convertIntoCampaignCards([result.data])),
        });
      }
      return;
    } catch (err) {
      setisLoading(false);

      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const handleUpdateTemplate = async () => {
    setisLoading(true);

    try {
      const postBody = {
        name: bodyRequest.name,
        settings: bodyRequest,
        message: bodyRequest.name,
        uploadedFile: [bodyRequest.backgroundImage.fileId],
      };

      const result = await baseUrl.patch(`/campaign/${campaignId}`, { ...postBody });
      if (result.status === 200) {
        setisLoading(false);
        handleClose();
        const updatedCampaign = {
          ...result.data,
          currentLocation: selectedLoaction,
        };

        campaignDispatch({
          type: 'UPDATE_CAMPAIGN_ITEM',
          payload: Array.from(convertIntoCampaignCards([updatedCampaign])),
        });
      }
      return;
    } catch (err) {
      setisLoading(false);

      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const getCampaignById = async () => {
    try {
      const result = await baseUrl.get(`/campaign/${campaignId || campaignToEdit}`, {});
      if (result?.data) {
        setBodyRequest((prevState) => ({
          ...prevState,
          name: result.data.name,
          backgroundImage: result.data.settings.backgroundImage,
          customBgImage: result.data.settings.customBgImage,
          colors: {
            overlayColor1: {
              bgColor: {
                r:
                  result.data.settings.colors?.overlayColor1?.bgColor.r ??
                  prevState.colors.overlayColor1.bgColor.r,
                g:
                  result.data.settings.colors?.overlayColor1?.bgColor.g ??
                  prevState.colors.overlayColor1.bgColor.g,
                b:
                  result.data.settings.colors?.overlayColor1?.bgColor.b ??
                  prevState.colors.overlayColor1.bgColor.b,
                a:
                  result.data.settings.colors?.overlayColor1?.bgColor.a ??
                  prevState.colors.overlayColor1.bgColor.a,
              },
              isDarkText:
                result.data.settings.colors?.overlayColor1?.isDarkText ??
                prevState.colors.overlayColor1.isDarkText,
            },
            overlayColor2: {
              bgColor: {
                r:
                  result.data.settings.colors?.overlayColor2?.bgColor.r ??
                  prevState.colors.overlayColor2.bgColor.r,
                g:
                  result.data.settings.colors?.overlayColor2?.bgColor.g ??
                  prevState.colors.overlayColor2.bgColor.g,
                b:
                  result.data.settings.colors?.overlayColor2?.bgColor.b ??
                  prevState.colors.overlayColor2.bgColor.b,
                a:
                  result.data.settings.colors?.overlayColor2?.bgColor.a ??
                  prevState.colors.overlayColor2.bgColor.a,
              },
              isDarkText:
                result.data.settings.colors?.overlayColor2?.isDarkText ??
                prevState.colors.overlayColor2.isDarkText,
            },
            overlayColor3: {
              bgColor: {
                r:
                  result.data.settings.colors?.overlayColor3?.bgColor.r ??
                  prevState.colors.overlayColor3.bgColor.r,
                g:
                  result.data.settings.colors?.overlayColor3?.bgColor.g ??
                  prevState.colors.overlayColor3.bgColor.g,
                b:
                  result.data.settings.colors?.overlayColor3?.bgColor.b ??
                  prevState.colors.overlayColor3.bgColor.b,
                a:
                  result.data.settings.colors?.overlayColor3?.bgColor.a ??
                  prevState.colors.overlayColor3.bgColor.a,
              },
              isDarkText:
                result.data.settings.colors?.overlayColor3?.isDarkText ??
                prevState.colors.overlayColor3.isDarkText,
            },
            overlayColor4: {
              bgColor: {
                r:
                  result.data.settings.colors?.overlayColor4?.bgColor.r ??
                  prevState.colors.overlayColor4.bgColor.r,
                g:
                  result.data.settings.colors?.overlayColor4?.bgColor.g ??
                  prevState.colors.overlayColor4.bgColor.g,
                b:
                  result.data.settings.colors?.overlayColor4?.bgColor.b ??
                  prevState.colors.overlayColor4.bgColor.b,
                a:
                  result.data.settings.colors?.overlayColor4?.bgColor.a ??
                  prevState.colors.overlayColor4.bgColor.a,
              },
              isDarkText:
                result.data.settings.colors?.overlayColor4?.isDarkText ??
                prevState.colors.overlayColor4.isDarkText,
            },
            overlayColor5: {
              bgColor: {
                r:
                  result.data.settings.colors?.overlayColor5?.bgColor.r ??
                  prevState.colors.overlayColor5.bgColor.r,
                g:
                  result.data.settings.colors?.overlayColor5?.bgColor.g ??
                  prevState.colors.overlayColor5.bgColor.g,
                b:
                  result.data.settings.colors?.overlayColor5?.bgColor.b ??
                  prevState.colors.overlayColor5.bgColor.b,
                a:
                  result.data.settings.colors?.overlayColor5?.bgColor.a ??
                  prevState.colors.overlayColor5.bgColor.a,
              },
              isDarkText:
                result.data.settings.colors?.overlayColor5?.isDarkText ??
                prevState.colors.overlayColor5.isDarkText,
            },
          },
          autoHashTags: result.data.settings.autoHashTags,
          hashtags: result.data.settings.hashtags,
          isPreview: result.data.settings.isPreview,
        }));
        setisLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (campaignId || campaignToEdit) {
      setisLoading(true);
      getCampaignById();
    }
  }, [campaignId, campaignToEdit]);

  const handleTemplateTitleChange = (value) => {
    setBodyRequest({
      ...bodyRequest,
      name: value,
    });
  };

  // useEffect(() => {
  //   console.log(bodyRequest);
  // }, [bodyRequest]);

  const handleShowGallery = () => {
    gallaryModal.handleShowModal();
  };

  return (
    <>
      {isLoading && <ContainerLoader hideContent />}
      <Box style={containerStyles}>
        <Box margin={'0 0 0 1rem'}>
          <TemplateTitleEdit
            campaign={bodyRequest}
            handleChange={handleTemplateTitleChange}
            type={'social post'}
            errors={campaignId ? false : true}
          />
        </Box>
        <Grid container spacing={3} style={{ height: '100%' }}>
          <Grid item sm={4} md={4} lg={4} xl={4}>
            <SettingsSidebar
              bodyRequest={bodyRequest}
              setBodyRequest={setBodyRequest}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
            />
          </Grid>
          <Grid
            item
            sm={8}
            md={8}
            lg={8}
            xl={8}
            style={{
              rowGap: '1rem',
              display: 'flex',
              flexDirection: 'column',
              paddingRight: '1rem',
            }}
          >
            <Box>
              <MuiThemeProvider theme={tabsTheme}>
                <AntTabs value={tabValue} onChange={handleTabChange} aria-label="ant">
                  <AntTab label="Facebook" />
                </AntTabs>
              </MuiThemeProvider>
            </Box>
            <Box height={'calc(43vh - 3rem)'} overflow={'auto'}>
              <FacebookPost
                locationInfo={locationInfo}
                bodyRequest={bodyRequest}
                setBodyRequest={setBodyRequest}
                showGallery={() => handleShowGallery()}
                selectedColor={selectedColor}
              />
            </Box>
          </Grid>
        </Grid>
        <GalleryPopup
          show={gallaryModal.showModal}
          handleClose={gallaryModal.handleHideShowModal}
          key="gallary-popup-modal"
          handleImagesContainer={(payload) => {
            setBodyRequest({
              ...bodyRequest,
              backgroundImage: payload,
            });
          }}
          type={campaignViewToggler}
          customStyle={{ borderRadius: '15px' }}
        />
      </Box>
      <Box style={footerStyles}>
        {campaignId && (
          <PrimaryButton outlined onClick={() => {}} disabled={buttonIsDisabled}>
            Clone
          </PrimaryButton>
        )}
        <div style={{ columnGap: '.5rem', display: 'flex', marginLeft: 'auto' }}>
          <PrimaryButton text onClick={handleCancel}>
            Cancel
          </PrimaryButton>
          {campaignId && (
            <PrimaryButton onClick={() => handleUpdateTemplate()} disabled={buttonIsDisabled}>
              Update
            </PrimaryButton>
          )}
          {!campaignId && (
            <PrimaryButton onClick={() => handleSaveTemplate()} disabled={buttonIsDisabled}>
              Save
            </PrimaryButton>
          )}
        </div>
      </Box>
    </>
  );
};

export default SocialMediaPostCreator;
