const onBoardingState = {
  showPopUp: false,
  userName: '',
  industries: [],
  seoCategories: [],
  userList: [],
  locationList: [],
  portalList: [],
  businessDetails: {},
  locationDetails: {},
  locationCardDetails: {},
  campaignDetails: {},
  selectedPortal: '',
  defaultPortals: {},
  radioValue: null,
  postSites: [],
  campaignType: 'mms',
  allowTextFeedback: true,
  showInvitePopup: false,
  isWizardMode: false,
  localAnswers: [],
};

export default onBoardingState;
