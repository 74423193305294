import React from 'react';
import Proptypes from 'prop-types';
import { Backdrop, CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

const StyledBackdrop = styled(Backdrop)`
  height: 100%;
  font-size: 36px;
  color: #37423e;
  font-weight: 200;
  letter-spacing: 1.08px;
`;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function Loader(props) {
  const classes = useStyles();
  const { title, Icon } = props;
  return (
    <StyledBackdrop open className={classes.backdrop}>
      {Icon}
      <div>{title}</div>
    </StyledBackdrop>
  );
}

Loader.propTypes = {
  title: Proptypes.string,
  Icon: Proptypes.node,
};

Loader.defaultProps = {
  title: '',
  Icon: <CircularProgress />,
};

export default Loader;
