import { ThemeProvider } from 'styled-components';
import React, { useEffect, useState } from 'react';
import useButtonTheme from '../../../../../hooks/useButtonTheme';
import { linkRegEx } from '../../../../../utils/helper/regEx';

import PageTitle from '../../../../PageTitle/PageTitle';
import GenericPopup from '../../../../Popup/GenricPopup/index';
import PrimaryButton from '../../../../Common/PrimaryButton';
import StyledTextField from '../../../../Common/StyledTextField';
import { Box } from '@material-ui/core';

const EditCampaign = ({
  handleClose,
  open,
  handleSubmit,
  isAddLink = false,
  isEditName = false,
  linkData = null,
}) => {
  const [valueTitle, setValueTitle] = useState('');
  const [value, setvalue] = useState('');
  const { buttonTheme } = useButtonTheme();
  const [disabledButton, setDisabledButton] = useState(true);

  const title = isAddLink ? 'Add a link' : 'Rename Template';
  const placeholder = isAddLink ? 'Link' : 'New template name';
  const handlePress = () => {
    if (isEditName) {
      handleSubmit(value);
    } else if (isAddLink) {
      handleSubmit({
        title: valueTitle,
        link: value,
      });
    }
    handleClose(false);
  };

  useEffect(() => {
    const isValidLength = isEditName && value.length > 3;
    const isValidUrl = isAddLink && linkRegEx.test(value) && valueTitle.length;

    setDisabledButton(!(isValidLength || isValidUrl));
  }, [value, isEditName, isAddLink]);

  useEffect(() => {
    if (linkData?.title && linkData?.link) {
      setValueTitle(linkData.title);
      setvalue(linkData.link);
    }
  }, [linkData?.title, linkData?.link]);

  return (
    <GenericPopup open={open} maxWidth="xs" fullWidth handleClose={handleClose}>
      <div style={{ margin: '1rem' }}>
        <div
          style={{ margin: '.5rem', rowGap: '1.5rem', display: 'flex', flexDirection: 'column' }}
        >
          <PageTitle title={title} />
          {isAddLink && (
            <StyledTextField
              value={valueTitle}
              onChange={(e) => setValueTitle(e.target.value)}
              label="Link title"
            />
          )}
          <StyledTextField
            value={value}
            placeholder={isAddLink ? 'http://' : ''}
            onChange={(e) => setvalue(e.target.value)}
            label={'Link Address'}
          />
        </div>
        <Box display="flex" justifyContent={'flex-end'} marginTop="1rem" gridColumnGap={'.5rem'}>
          <PrimaryButton text onClick={handleClose}>
            Cancel
          </PrimaryButton>
          <PrimaryButton disabled={disabledButton} onClick={handlePress}>
            Save
          </PrimaryButton>
        </Box>
      </div>
    </GenericPopup>
  );
};

export default EditCampaign;
