import React, { useRef, useEffect } from 'react';
import Flickity from 'react-flickity-component';
import { makeStyles } from '@material-ui/core';
import BubbleReview from './BubbleReview';
import DefaultReview from './DefaultReview';
import './flickity.css';

const ReviewsCarousel = (props) => {
  const { reviews, settings, postSites } = props;

  const flickityRef = useRef(null);

  let flickityOptions = {
    initialIndex: 2,
    pageDots: false,
    autoPlay: settings.carousel.autoPlay,
  };

  useEffect(() => {
    if (flickityRef.current) {
      flickityRef.current.reloadCells();
    }
  }, [flickityOptions]);

  let textColor = settings && settings.isLight ? '#000000' : '#ffffff';

  const useStyles = makeStyles((theme) => ({
    Review__Container: {
      backgroundColor: `${settings.colors.containerBgColor}${settings.colors.containerBgOpacity}`,
      position: 'relative',
      padding: '24px',
      transition: 'background-color 0.1s ease 0s',
      borderRadius: `${settings.radius}px`,
      display: 'flex',
      flexDirection: 'column',
    },

    Review__Bubble: {
      marginBottom: `${
        settings.widgetStyle === 'grid' ? '' : 'margin-bottom:' + settings.gap + 'px'
      }`,
    },
    Review__Container_Bubble: {
      backgroundColor: `${settings.colors.containerBgColor}${settings.colors.containerBgOpacity}`,
      position: 'relative',
      padding: '24px',
      transition: 'background-color 0.1s ease 0s',
      borderRadius: `${settings.radius}px`,
      display: 'flex',
      flexDirection: 'column',
    },

    flickityContainer: {
      '& .flickity-slide': {
        margin: `0 calc(${settings.gap + 'px'} / 2)`,
        width: '100%',

        [theme.breakpoints.up('1201')]: {
          width: '50% !important',
        },
        [theme.breakpoints.up('1601')]: {
          width: '30% !important',
        },
        [theme.breakpoints.up('2301')]: {
          width: '20% !important',
        },
      },
      '& .flickity-prev-next-button': {
        backgroundColor: `${settings.colors.brandColor}`,
      },
      '& .flickity-button-icon': {
        fill: 'white !important',
      },
      '& .flickity-button:disabled': {
        display: 'none',
      },
      '& .flickity-enabled': {
        height: 'auto',
      },
    },
  }));

  const classes = useStyles();

  const getReviewStyle = (review) => {
    if (settings.reviewStyle === 'bubble') {
      return <BubbleReview review={review} settings={settings} postSites={postSites} />;
    } else {
      return <DefaultReview review={review} settings={settings} postSites={postSites} />;
    }
  };

  const slides =
    reviews &&
    reviews.map((review, index) => (
      <div key={index} className={`${'flickity-slide'} ${classes.Review__Bubble}`}>
        {getReviewStyle(review)}
      </div>
    ));

  return (
    <div style={{ width: '100%' }}>
      <Flickity
        className={`${classes.flickityContainer} 'carousel'`} // default ''
        elementType={'div'} // default 'div'
        options={flickityOptions} // takes flickity options {}
        disableImagesLoaded={false} // default false
        reloadOnUpdate // default false
        //static // default false
        //groupCells={'30%'}
        adaptiveHeight={true}
      >
        {slides}
      </Flickity>
    </div>
  );
};

export default ReviewsCarousel;
