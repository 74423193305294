import React from 'react';
import Proptypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { Grid } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CompanyLogo from '../../../components/CompanyLogo/CompanyLogo';
import Logo from '../../../assets/images/reveo_logo@2x.png';
import rocketLogo from '../../../assets/images/reviews generation@2x.png';
import CustomButton from '../../../components/CustomButton/CustomButton';
import OnBoardingButtonTheme from '../../../themes/Button/OnBoardingButtonTheme';
import useTextColors from '../../../hooks/useTextColors';
import StyledBeginText from '../../../components/BeginText/BeginText';

const StyledGridContaienr = styled(Grid)`
  width: 100% !important;
  height: 100% !important;
`;

const StyledTitle = styled(Grid)`
  letter-spacing: 0.72px;
  color: #6c6c6c;
  font-size: 36px;
  font-weight: 300 !important;
  margin: 0.5em 0 !important;
`;

const StyledFooter = styled(Grid)`
  align-self: flex-end;
`;

const StyledFowArrow = styled(ArrowForwardIcon)`
  color: #fff !important;
  font-size: 3em !important;
`;

const companyLogoTheme = {
  width: '324px',
  height: '90px',
  largeScreenWidth: '473px',
  largeScreenHeight: '127px',
};

const rocketLogoTheme = {
  width: '215px',
  height: '215px',
  largeScreenWidth: '371px',
  largeScreenHeight: '371px',
  padding: '0 3em',
};

function Step1(props) {
  const { handleNext } = props;
  const { textColors } = useTextColors();
  return (
    <StyledGridContaienr container justify="center" alignItems="center">
      <StyledFooter item xs={4} sm={4} lg={4} md={4}>
        <ThemeProvider theme={rocketLogoTheme}>
          <CompanyLogo alt="Reveo" logo={rocketLogo} />
        </ThemeProvider>
      </StyledFooter>
      <Grid item xs={4} sm={4} lg={4} md={4}>
        <Grid container direction="column" alignItems="center">
          <StyledTitle item alignContent="center">
            Welcome to
          </StyledTitle>
          <Grid item>
            <ThemeProvider theme={companyLogoTheme}>
              <CompanyLogo alt="Reveo" logo={Logo} />
            </ThemeProvider>
          </Grid>
        </Grid>
      </Grid>
      <StyledFooter item xs={4} sm={4} lg={4} md={4}>
        <Grid container justify="space-evenly" alignItems="center">
          <Grid item>
            <ThemeProvider theme={{ color: textColors.headingTextColor }}>
              <StyledBeginText>Let’s get started!</StyledBeginText>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <ThemeProvider theme={OnBoardingButtonTheme}>
              <CustomButton onClick={handleNext}>
                <StyledFowArrow />
              </CustomButton>
            </ThemeProvider>
          </Grid>
        </Grid>
      </StyledFooter>
    </StyledGridContaienr>
  );
}

Step1.propTypes = {
  handleNext: Proptypes.func.isRequired,
};

export default Step1;
