import React, { useRef, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { makeStyles, CircularProgress } from '@material-ui/core';
import {
  increase_brightness,
  generateSourcesList,
  makeStars,
  getSource,
} from '../ReviewsWidget.logic';
import { Skeleton } from '@material-ui/lab';

const Badge = (props) => {
  const { settings, reviews, postSites, onClick } = props;

  const StyledContainer = styled.div`
    background-color: ${(props) => props.bgColor};
    border-radius: ${(props) => props.borderRadius};
    display: flex;
    flex-direction: column;
    position: relative;
    width: fit-content;
    position: absolute;
    cursor: pointer;
    bottom: 1rem;
  `;

  let textColor = settings && settings.isLight ? '#000000' : '#ffffff';
  let backgroundColor = settings && settings.isLight ? '#ffffff' : '#000000';
  let secondaryTextColor = settings && settings.isLight ? textColor + '91' : textColor + '91';
  let writeReviewButtonBorderRadius = settings && settings.reviewButton.isRounded ? '200px' : '5px';

  const tabBarRef = useRef(null);
  const allReviewsLinkRef = useRef(null);

  const [sources, setSources] = useState({});

  const useStyles = makeStyles(() => ({
    widget_header: {
      backgroundColor: `${settings.colors.containerBgColor}${settings.colors.containerBgOpacity}`,
      borderRadius: `${settings.radius}px`,
      marginBottom: settings.gap + 'px',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    tab_bar: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '.5rem',
    },
    overall_rating: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: ' 0 1.5rem 1rem 1.5rem',
      flexDirection: 'column',
      fontSize: '18px',
      lineHeight: 1,
      color: textColor,
    },
    other_rating_stuff: {
      display: 'flex',
      alignItems: 'center',
      gap: '.5rem',
      flexDirection: 'column',
      marginTop: '.3rem',
    },
    large_average_rating: {
      fontWeight: 600,
      fontSize: '24px',
      color: textColor,
      marginRight: '10px',
    },
    main_stars: {
      display: 'flex',
      gap: '.15rem',
    },
    average_plus_stars: {
      display: 'flex',
      alignItems: 'center',
    },
    source_total_reviews: {
      fontSize: '12px',
      color: secondaryTextColor,
      marginTop: '-.2rem',
      fontWeight: 400,
      textDecoration: 'underline',
    },
    source_rating: {
      fontSize: '24px',
      height: '40px',
      marginBottom: '.5rem',
      lineHeight: 2,
      color: textColor,
    },
    source_link: {
      cursor: 'pointer',
      fontSize: '.95rem',
      display: 'flex',
      alignItems: 'center',
      gap: '.5rem',
      height: '3rem',
      whiteSpace: 'nowrap',
      marginLeft: '-8px',
      color: textColor,
      '&.selected': {
        borderBottom: `3px solid ${settings.colors.brandColor}`,
      },
    },
    Review__ReviewSource: {
      display: 'flex',
      marginTop: '1rem',
      gap: '.5rem',
      alignItems: 'center',
      justifyContent: `${settings.reviewStyle === 'center' ? 'center;' : null}`,
    },
    Review__ReviewSourceImage: {
      width: '24px',
      borderRadius: '50%',
      border: '2px solid white',
      backgroundColor: 'white',
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    const sourcesResult = generateSourcesList(reviews, postSites);
    setSources(sourcesResult);
  }, [reviews, postSites]);

  const finalSources = Object.keys(sources).map((sourceKey, index) => {
    const source = sources[sourceKey];
    const length = Object.keys(sources).length;
    if (source.postReviewLink) {
      return (
        <div key={index} className={classes.source_link} datasource={getSource(sourceKey).title}>
          <img
            src={getSource(sourceKey).icon}
            alt="Review Source Image"
            datasource={getSource(sourceKey).title}
            className={classes.Review__ReviewSourceImage}
            style={{ zIndex: length - index }}
          />
        </div>
      );
    }
  });

  const leftRightCenter = () => {
    if (settings.widgetPlacement === 'left') {
      return { left: '1rem' };
    } else if (settings.widgetPlacement === 'center') {
      return { right: '50%', transform: 'translate(50%, 0)' };
    } else {
      return { right: '1rem' };
    }
  };
  return (
    <>
      <StyledContainer
        bgColor={settings.colors.containerBgColor + settings.colors.containerBgOpacity}
        borderRadius={settings.radius + 'px'}
        gap={settings.gap + 'px'}
        onClick={onClick}
        style={leftRightCenter()}
      >
        <div id="widget_header">
          {settings.showTabs ? (
            <div className={classes.tab_bar} ref={tabBarRef}>
              {finalSources}
            </div>
          ) : null}
          <div className={classes.overall_rating}>
            Overall Rating
            <div className={classes.large_rating}>
              <div className={classes.other_rating_stuff}>
                <div className={classes.average_plus_stars}>
                  <div className={classes.large_average_rating}>
                    {sources && sources.allAverageRating}
                  </div>
                  <div
                    className={classes.main_stars}
                    dangerouslySetInnerHTML={{
                      __html: makeStars(
                        sources && sources.allAverageRating,
                        20,
                        settings,
                        textColor,
                        backgroundColor,
                      ),
                    }}
                  ></div>
                </div>
                <div className={classes.source_total_reviews}>{`Read our ${
                  sources && sources.allCount
                } reviews`}</div>
              </div>
            </div>
          </div>
        </div>
      </StyledContainer>
    </>
  );
};

export default Badge;
