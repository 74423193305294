import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, Modifier } from 'draft-js';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import styled, { ThemeProvider } from 'styled-components';
import useModal from '../../../../../hooks/useModal';
import GenricPopup from '../../../../Popup/GenricPopup';
import StyledTextField from '../../../../GeneralInputField/GeneralInputField';
import useButtonTheme from '../../../../../hooks/useButtonTheme';
import CustomButton from '../../../../CustomButton/CustomButton';
import { MenuItem, IconButton, Menu } from '@material-ui/core';
import Settings from '@material-ui/icons/Settings';
import StyledMenuItem from '../../../../Common/StyledMenuItem';

const Container = styled.div`
  margin: 10px;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h1`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
`;

const InsertTagButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  padding: 5px;
  margin: 0 4px;
  min-width: 25px;
`;

const ContentContainer = styled.div`
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
`;

const StyledContainerTextField = styled.div`
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 0px;
`;

const StyledContainerText = styled.div`
  margin-top: 15px;
  border-radius: 15px;
  background-color: #fff;
`;

const DraftEmailTemplate = ({ editorState, setEditorState, title = '', prefixes = [] }) => {
  const [show, setShow] = useState(true);
  const linkNameModal = useModal();
  const { buttonTheme } = useButtonTheme();

  const [linkText, setLinkText] = useState('');
  const [prefixSelected, setPrefixSelected] = useState(null);

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const isLink = (string) => {
    return string.toLowerCase().includes('link');
  };
  const handleAddPrefix = (prefix) => {
    if (isLink(prefix)) {
      setPrefixSelected(prefix);
      linkNameModal.handleShowModal();
      handleMenuClose();
      return;
    }
    const currentContentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const block = currentContentState.getBlockForKey(selection.getStartKey());
    const textWithPrefix = `${block.getText()} ${prefix}`;

    const newContentState = Modifier.replaceText(
      currentContentState,
      selection.merge({
        focusOffset: textWithPrefix.length,
        anchorOffset: 0,
      }),
      textWithPrefix,
    );

    const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');
    setEditorState(newEditorState);
  };

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };
  const handlePressAddTextLink = () => {
    const payload = `${prefixSelected}[${linkText}]`;
    const currentContentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const block = currentContentState.getBlockForKey(selection.getStartKey());
    const textWithPrefix = `${block.getText()} ${payload}`;

    const newContentState = Modifier.replaceText(
      currentContentState,
      selection.merge({
        focusOffset: textWithPrefix.length,
        anchorOffset: 0,
      }),
      textWithPrefix,
    );

    const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');
    setEditorState(newEditorState);
    setPrefixSelected(null);
    setLinkText('');
    linkNameModal.handleHideShowModal();
  };

  return (
    <>
      <GenricPopup
        open={linkNameModal.showModal}
        anchorEl={linkNameModal.anchorEl}
        handleClose={linkNameModal.handleHideShowModal}
      >
        <div style={{ padding: 10, backgroundColor: 'white' }}>
          <StyledContainerText>
            <StyledContainerTextField>
              <ThemeProvider theme={{}}>
                <StyledTextField
                  value={linkText}
                  onChange={(e) => setLinkText(e.target.value)}
                  placeholder="Text link"
                />
              </ThemeProvider>
            </StyledContainerTextField>
          </StyledContainerText>

          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <div>
              <ThemeProvider
                theme={{
                  margin: '0',
                  background: '#FFF',
                  color: buttonTheme.buttonColor2,
                  padding: '10px',
                }}
              >
                <CustomButton onClick={linkNameModal.handleHideShowModal}>Cancel</CustomButton>
              </ThemeProvider>
            </div>
            <div>
              <ThemeProvider
                theme={{
                  margin: '0',
                  background: buttonTheme.buttonBgColor,
                  color: buttonTheme.buttonColor2,
                  padding: '10px',
                }}
              >
                <CustomButton disabled={!linkText.length} onClick={handlePressAddTextLink}>
                  Save
                </CustomButton>
              </ThemeProvider>
            </div>
          </div>
        </div>
      </GenricPopup>
      <Container>
        <Title>{title}</Title>
        {show ? (
          <ArrowDropUpIcon onClick={() => setShow(!show)} />
        ) : (
          <ArrowDropDownIcon onClick={() => setShow(!show)} />
        )}
      </Container>
      {show && (
        <ContentContainer>
          <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            toolbar={{
              options: [
                'inline',
                'blockType',
                'fontSize',
                'fontFamily',
                'colorPicker',
                'list',
                'textAlign',
                'link',
                'emoji',
              ],
              inline: {
                inDropdown: true,
                options: ['bold', 'italic', 'underline'],
              },
              textAlign: {
                inDropdown: true,
                options: ['left', 'center', 'right', 'justify'],
              },
              fontSize: {
                options: [10, 12, 14, 16, 18, 24, 30],
                defaultFontSize: 12,
              },
            }}
            toolbarCustomButtons={[
              <InsertTagButton>
                <Settings fontSize="small" onClick={handleMenuOpen} />
              </InsertTagButton>,
            ]}
            defaultEditorState={editorState} // Try setting defaultEditorState instead
            editorStyle={{
              backgroundColor: '#FFFFFF',
              minHeight: '100px',
            }}
          />
          <Menu
            id="customized-menu"
            anchorEl={menuAnchorEl}
            keepMounted
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
          >
            {prefixes.map((item) => (
              <StyledMenuItem
                key={item.tag}
                value={item.tag}
                onClick={() => handleAddPrefix(item.tag)}
              >
                {item.visualName}
              </StyledMenuItem>
            ))}
          </Menu>
        </ContentContainer>
      )}
    </>
  );
};

DraftEmailTemplate.defaultProps = {
  editorState: null,
  setEditorState: () => {},
  title: '',
  prefixes: [],
};

export default React.memo(DraftEmailTemplate);
