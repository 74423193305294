const AccountReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ACCOUNT_CONTAINER':
      return {
        ...state,
        accountsContainer: Array.from(action.payload),
      };
    case 'UPDATE_ACCOUNT_CONTAINER': {
      const stateCopy = { ...state };
      stateCopy.accountsContainer.filter((element, index) => {
        if (element.id === action.payload.id) {
          stateCopy.accountsContainer[index].roles = action.payload.role;
          stateCopy.accountsContainer[index].locations = action.payload.location;
          stateCopy.accountsContainer[index].name.url = action.payload.url;
        }
        return null;
      });
      return {
        ...stateCopy,
        loader: false,
      };
    }
    case 'UPDATE_ALL_FIELDS_ACCOUNT_CONTAINER':
      return {
        ...state,
        accountsContainer: Array.from(action.payload),
      };
    case 'INIT_ACCOUNT_CONTAINER': {
      return {
        ...state,
        accountsContainer: [],
      };
    }

    case 'SHOW_LOADER':
      return {
        ...state,
        loader: true,
      };

    case 'HIDE_LOADER':
      return {
        ...state,
        loader: false,
      };
    default:
      return state;
  }
};

export default AccountReducer;
