import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { ThemeProvider } from 'styled-components';
import loginLeftSideImage from '../assets/images/loginLeftSideImage.jpg';
import Logo from '../assets/images/reveo_logo@1x.png';
import GeneralInputField from '../components/GeneralInputField/GeneralInputField';
import { getParameterByName, handleResetPasswordError } from '../utils/helper/helper';
import { passwordRegex } from '../utils/helper/regEx';
import baseUrlLogin from '../utils/services/auth.config';
import useShowPassword from '../state/ShowPassword';
import CusotmAlert from '../components/CustomAlert/CustomAlert';
import CustomButton from '../components/CustomButton/CustomButton';
import ButtonLoader from '../components/ButtonLoader/ButtonLoader';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    height: '100%',
    width: '100%',
  },
  loginLeftSection: {
    backgroundImage: `url(${loginLeftSideImage})`,
    height: '100%',
    backgroundSize: 'cover',
  },
  loginRightSection: {
    backgroundColor: '#F0F7F7',
    height: '100vh',
    position: 'relative',
  },
  loginForm: {
    top: '50%',
    position: 'absolute',
    transform: 'translateY(-50%)',
    textAlign: 'center',
  },
  logo: {
    width: '250px',
  },
  items: {
    padding: '10px',
    margin: '0 auto',
  },
  form: {
    width: '100%',
  },
  inputField: {
    marginBottom: '20px',
  },
}));

const buttonTheme = {
  boxShadow: '5px 2px 14px -3px',
};

function ResetPassword() {
  const classes = useStyles();
  const history = useHistory();
  const [code, setCode] = useState();
  const [showAlert, setAlert] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [loader, setLoader] = useState(false);
  const { register, handleSubmit, errors, setError } = useForm({
    password: '',
    confirmPassword: '',
  });
  const { handlePasswordViewToggle, passwordInfo } = useShowPassword({
    password: false,
    confirmPassword: false,
  });

  useEffect(() => {
    setCode(getParameterByName('code'));
  }, []);

  const onSubmit = (data) => {
    if (data.password !== data.confirmPassword) {
      setError('password', {
        type: 'manual',
        types: '',
        message: 'Password and confirm password must be same',
      });
      setError('confirmPassword', {
        type: 'manual',
        types: '',
        message: 'Password and confirm password must be same',
      });
    } else {
      setLoader(true);
      baseUrlLogin
        .post(`/account/reset?code=${code}`, { ...data })
        .then((res) => {
          console.log('screw you!!!!!!!!!');
          if (res.status === 200) {
            setLoader(false);
            setAlert(true);
            setTimeout(() => {
              setAlert(false);
              history.push('/');
            }, 2000);
          }
        })
        .catch(() => {
          setLoader(false);
          setServerError(true);
          setAlert(true);
        });
    }
  };

  const handleCloseAlert = () => {
    setAlert(false);
  };

  const showLoader = loader ? <ButtonLoader /> : null;

  return (
    <div className={classes.root}>
      <CusotmAlert
        open={showAlert}
        message={serverError ? 'Invalid reset password link' : 'Password reset successfully'}
        handleClose={handleCloseAlert}
        messageType={serverError ? 'error' : 'success'}
      />
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6}>
          <div className={classes.loginLeftSection} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.loginRightSection}>
            <Grid
              className={classes.loginForm}
              item
              xs={12}
              sm={12}
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item className={classes.items}>
                <img src={Logo} className={classes.logo} alt="logo" />
              </Grid>
              <Grid item className={classes.items}>
                <Typography variant="subtitle1" gutterBottom>
                  Please enter password and confirm password.
                </Typography>
              </Grid>
              <Grid container>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className={classes.form}>
                  <Grid item className={classes.items} xs={6} lg={6} md={6}>
                    <div className={classes.inputField}>
                      <GeneralInputField
                        name="password"
                        type={passwordInfo.password ? 'text' : 'password'}
                        error={!!errors.password}
                        disabled={loader}
                        label="Password"
                        inputRef={register({ required: true, pattern: passwordRegex })}
                        helperText={
                          errors.password
                            ? handleResetPasswordError(errors.password, 'Password')
                            : null
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handlePasswordViewToggle('password')}
                              >
                                {passwordInfo.password ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div className={classes.inputField}>
                      <GeneralInputField
                        name="confirmPassword"
                        type={passwordInfo.confirmPassword ? 'text' : 'password'}
                        error={!!errors.confirmPassword}
                        disabled={loader}
                        label="Confirm Password"
                        inputRef={register({ required: true, pattern: passwordRegex })}
                        helperText={
                          errors.confirmPassword
                            ? handleResetPasswordError(errors.confirmPassword, 'Confirm Password')
                            : null
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handlePasswordViewToggle('confirmPassword')}
                              >
                                {passwordInfo.confirmPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid container justify="center" alignItems="center">
                    <Grid item xs={12} sm={12} lg={6} md={6}>
                      <ThemeProvider theme={buttonTheme}>
                        <CustomButton type="submit" disabled={loader}>
                          Reset
                          {showLoader}
                        </CustomButton>
                      </ThemeProvider>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default ResetPassword;
