import React, { useState } from 'react';
import { Menu, MenuItem, Box, makeStyles } from '@material-ui/core';
import ReveoButton from '../../components/Buttons/ReveoButton';
import StarIcon from 'mdi-react/StarOutlineIcon';
import { getIcon } from '../../utils/helper/helper';

function ReviewMenu(props) {
  const { reviews } = props;
  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const useStyles = makeStyles(() => ({
    menuItemContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    menuItemTitle: {
      fontWeight: 600,
      fontSize: '12px',
    },
    caretIcon: {
      color: '#9d9d9d',
      width: '32px',
      opacity: 0,
      transition: 'opacity .2s',
    },

    menuItem: {
      display: 'flex',
      columnGap: '.7rem',
      padding: '.8rem .8rem .8rem 1.5rem',
      cursor: 'pointer',
      fontFamily: 'inherit',
      '&:hover': {
        backgroundColor: '#ededed',
        transition: 'background-color 0.3s ease',
      },
    },

    leaveWhereHeader: {
      margin: '1.5rem 1.5rem 1rem 1.5rem',
      color: '#303030',
      fontWeight: 600,
      fontSize: '16px',
    },
    menuSubtitle: {
      fontSize: '10px',
      color: '#303030',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontWeight: 400,
      maxWidth: '35ch',
    },
    sourceImage: {
      width: '30px',
      height: '30px',
      borderRadius: '50%',
    },
  }));

  const classes = useStyles();

  const removeFront = (link) => {
    return link.replace(/^(https?:\/\/)/, '');
  };

  const handleMenuItemClick = (link) => {
    handleMenuClose();
    window.open(link, '_blank');
  };

  const menuItems =
    reviews && reviews[0]
      ? reviews[0].map((item, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => handleMenuItemClick(item.postReviewLink)}
              className={classes.menuItem}
            >
              <img className={classes.sourceImage} src={getIcon(item.name).icon} />
              <div className={classes.menuItemContainer}>
                <div className={classes.menuItemTitle}>{item.name}</div>
                <div className={classes.menuSubtitle}> {removeFront(item.postReviewLink)}</div>
              </div>
            </MenuItem>
          );
        })
      : null;

  return (
    <div>
      <ReveoButton
        title="Write a review"
        color="black"
        icon={<StarIcon style={{ height: '16px', marginRight: '.2rem' }} />}
        onClick={handleMenuOpen} // Open the menu on button click
      />
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose} // Close the menu when clicking outside
        style={{ maxWidth: '350px' }}
      >
        <div className={classes.leaveWhereHeader}>Where would you like to leave a review?</div>
        {menuItems}
      </Menu>
    </div>
  );
}

export default ReviewMenu;
