// .jsx

import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Card, CardContent, Typography } from '@material-ui/core';
import Proptypes from 'prop-types';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import CustomButton from '../CustomButton/CustomButton';
import Logo from '../../assets/images/reveo_logo@1x.png';
import UploadLayout from '../UploadLayout';

const CardContentStyle = styled(CardContent)`
  text-align: center;
`;

const ButtonCardContentStyle = styled(CardContent)`
  text-align: center;
  padding-top: 0 !important;
`;

const ReviewCommentText = styled.textarea`
  width: 85%;
  height: 90px;
  outline: none;
  border: 2px solid #a7a3a3 !important;
  border-radius: 6px;
  padding: 10px;
  font-size: 14px;
  font-family: Montserrat, regular;
`;

const CardStyle = styled(Card)`
  text-align: center;
  min-width: 100%;
  height: 100%;
  position: relative;
  // @media only screen and (max-width: 600px) {
  //   box-shadow: none;
  // }
`;

const StyledCustomRating = styled(Rating)`
  font-size: 28px !important;
  & svg {
    padding: 10px;
  }

  @media screen and (max-width: 330px) and {
    & {
      font-size: 24px !important;
    }
    & svg {
      padding: 8px;
    }
  }

  @media only screen and (min-device-width: 1000px) and (max-device-width: 1050px) {
    font-size: 24px !important;
    & svg {
      padding: 8px;
    }
  }
`;

const StarBorderIconStyle = styled(StarBorderIcon)`
  color: #ffb400 !important;
`;

const BottomContent = styled(CardContent)`
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 12px 0 !important;
`;

const buttonTheme = {
  background: '#000',
  margin: '0px',
  color: '#fff',
  fontSize: '18px !important',
  fontWeight: 'bold',
  width: '80%',
};
// const cancelButtonTheme = {
//   background: '#fff',
//   border: 'none',
//   fontSize: '18px !important',
//   fontWeight: 'bold',
//   color: '#00BFFF',
//   margin: '0px',
// };

const QuestionTextStyle = styled(Typography)`
  color: #9e9a9a !important;
  font-family: Montserrat, bold !important;
  font-size: 1.2rem !important;
  // @media only screen and (min-device-width: 481px) and (max-device-width: 1000px) {
  //   font-size: 1rem !important;
  // }
`;

function NegativeFeedback(props) {
  const {
    negativeFeedbackCard,
    onClientFeedback,
    saveExperienceReview,
    disabledBtn,
    powerImageWidth,
    onClientRating,
    url,
  } = props;
  const { title, positiveButtonText } = negativeFeedbackCard;
  return (
    <CardStyle>
      <CardContentStyle>
        {url ? (
          <ThemeProvider theme={{ width: '85%', margin: '0 auto', border: 'none' }}>
            <UploadLayout
              handleClick={() => {}}
              url={`${process.env.REACT_APP_IMAGE_ENDPOINT}${url}`}
              imgHeight="350px"
              disabled
            />
          </ThemeProvider>
        ) : (
          <p />
        )}
      </CardContentStyle>
      <CardContentStyle>
        <QuestionTextStyle>{title}</QuestionTextStyle>
      </CardContentStyle>

      <CardContentStyle>
        <StyledCustomRating
          name="customized-empty"
          emptyIcon={<StarBorderIconStyle fontSize="inherit" />}
          onChange={(event, newValue) => {
            onClientRating(event, newValue);
          }}
        />

        <ReviewCommentText onChange={(e) => onClientFeedback(e.target.value)} />
      </CardContentStyle>
      <ButtonCardContentStyle>
        <ThemeProvider theme={buttonTheme}>
          <CustomButton onClick={saveExperienceReview} disabled={disabledBtn}>
            {positiveButtonText || 'Send Feedback'}
          </CustomButton>
        </ThemeProvider>
      </ButtonCardContentStyle>
      <CardContentStyle>
        {/* <ThemeProvider theme={cancelButtonTheme}>
          <CustomButton onClick={negativeBtnAction} disabled={disabledBtn}>
            No, Thanks
          </CustomButton>
        </ThemeProvider> */}
      </CardContentStyle>
      <BottomContent>
        <div style={{ display: 'inline-grid' }}>
          <span style={{ fontSize: '6px', marginLeft: '14px' }}>Powered by</span>
          <img
            src={Logo}
            style={{ width: powerImageWidth ? `${powerImageWidth}em` : '2.3em' }}
            alt="logo"
          />
        </div>
      </BottomContent>
    </CardStyle>
  );
}

NegativeFeedback.propTypes = {
  negativeFeedbackCard: Proptypes.string,
  saveExperienceReview: Proptypes.func.isRequired,
  onClientFeedback: Proptypes.func.isRequired,
  disabledBtn: Proptypes.bool,
  powerImageWidth: Proptypes.number.isRequired,
  onClientRating: Proptypes.func.isRequired,
  url: Proptypes.string.isRequired,
};

NegativeFeedback.defaultProps = {
  disabledBtn: false,
  negativeFeedbackCard: {},
};

export default NegativeFeedback;
