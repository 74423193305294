import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './scss/index.scss';
import { HashRouter, Route } from 'react-router-dom';
import JavascriptTimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import ru from 'javascript-time-ago/locale/ru';
import ScriptLoader from './layout/ScriptLoader';

JavascriptTimeAgo.addLocale(en);
JavascriptTimeAgo.addLocale(ru);

ReactDOM.render(
  // <React.StrictMode>
  <HashRouter
    basename=""
    getUserConfirmation={(message, callback) => {
      const allowTransition = window.confirm(message);
      callback(allowTransition);
    }}
    hashType="slash"
  >
    <Route path="/" component={ScriptLoader} />
  </HashRouter>,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
