import React, { createContext, useReducer } from 'react';
import Proptypes from 'prop-types';
import CustomersState from '../state/CustomersState';
import CustomersReducer from '../Reducers/CustomersReducer';

export const CustomersContext = createContext(CustomersState);

const CustomersStore = ({ children }) => {
  const [state, dispatch] = useReducer(CustomersReducer, CustomersState);
  return (
    <CustomersContext.Provider value={[state, dispatch]}>{children}</CustomersContext.Provider>
  );
};

CustomersStore.propTypes = {
  children: Proptypes.node.isRequired,
};

export default CustomersStore;
