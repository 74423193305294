import React, { useState, useEffect, useContext, useCallback } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import {
  Grid,
  FormHelperText,
  InputAdornment,
  Box,
  IconButton,
  ThemeProvider as MUIThemeProvider,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import baseUrl from '../../utils/services/config';
import CusotmAlert from '../../components/CustomAlert/CustomAlert';
import {
  ConvertIntoTableFormat,
  ConvertIntoLocationDropDown,
  handleEmailError,
  handlePhoneError,
  getMultiSelectIds,
  errorChecker,
  checkIFUSFormat,
} from '../../utils/helper/helper';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import NumberField from '../../components/NumberField/NumberField';
import CustomButton from '../../components/CustomButton/CustomButton';
import ButtonLoader from '../../components/ButtonLoader/ButtonLoader';
import Loader from '../../components/Loader/Loader';
import { emailRegEx } from '../../utils/helper/regEx';
import StyledTextField from '../../components/GeneralInputField/GeneralInputField';
import defaultDropDownTheme from '../../themes/Dropdown/DefaultDropdownTheme';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import StyledChip from '../../components/Chip/BasicChip';
import useInputFieldTheme from '../../hooks/useInputFieldTheme';
import StyledFormControl from '../../components/StyledFormControl';
import useIconTheme from '../../hooks/useIconTheme';
import TransperentButtonTheme from '../../themes/Button/TransperentButtonTheme';
import { FormHeader } from '../../components/Forms/FormHeader';

const StyledDashboard = styled(Grid)`
  padding: 1rem 2rem 2rem 2rem;
  min-height: 100%;
`;

const StyledCloseButton = styled(IconButton)`
  right: 10px;
  top: 10px;
`;

function AddClient(props) {
  const { currentContact, handleClose, handleSave } = props;
  const [showAlert, setAlert] = useState(false);
  const [loader, setLoader] = useState(false);
  const [viewLoader, setViewLoader] = useState(false);
  const [tel, setTel] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [renderView, setRenderView] = useState(false);
  const [disabledBtn, setDisableBtn] = useState(false);
  const [emailLoader, setEmailLoader] = useState(false);
  const [phoneLoader, setPhoneLoader] = useState(false);
  const [unsubscribeMobile, setUnsubscribeMobile] = useState(false);
  const [unsubscribeEmail, setUnsubscribeEmail] = useState(false);
  const { state, dispatch } = useContext(GlobalContext);
  const { selectedBusiness } = state;
  const [locations, setLocations] = useState(Array.from(ConvertIntoTableFormat([])));
  const { register, handleSubmit, errors, setValue, control, watch, setError, clearError } =
    useForm({});
  const { inputTheme } = useInputFieldTheme();
  const { iconsTheme } = useIconTheme();
  const customerId = currentContact === undefined ? 'new' : currentContact;

  const fetchCustomers = useCallback(() => {
    if (customerId !== 'new') {
      setViewLoader(true);
      baseUrl
        .get(`/client/${customerId}`)
        .then((res) => {
          if (res.status === 200) {
            setViewLoader(false);
            setRenderView(true);
            setValue('firstName', res.data.firstName);
            setValue('lastName', res.data.lastName);
            setValue('phoneNumber', checkIFUSFormat(res.data.phoneNumber).substring(2));
            setValue('emailAddress', res.data.emailAddress);
            setValue('location', getMultiSelectIds(res.data.location));
            setUnsubscribeMobile(res.data.unsubscribeMobile);
            setUnsubscribeEmail(res.data.unsubscribeEmail);
          }
        })
        .catch((err) => {
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    } else {
      setRenderView(true);
    }
  }, [customerId, setValue, dispatch]);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  useEffect(() => {
    if (selectedBusiness) {
      baseUrl
        .get(`/business/${selectedBusiness}/location?isDeleted=false`)
        .then((res) => {
          if (res.status === 200) {
            setLocations(Array.from(ConvertIntoLocationDropDown(res.data)));
          }
        })
        .catch((err) => {
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    }
  }, [selectedBusiness, dispatch]);

  const watchAllFields = watch({ nest: true });

  const onSubmit = (data) => {
    if (watchAllFields.location && watchAllFields.location.length && !phoneError && !emailError) {
      setLoader(true);
      setDisableBtn(true);
      const postBody = {
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber ? `+1${data.phoneNumber}` : '',
        emailAddress: data.emailAddress,
        location: data.location,
        user: parseInt(localStorage.getItem('USER_ID'), 10),
        createdBy: parseInt(localStorage.getItem('USER_ID'), 10),
      };
      if (customerId !== 'new') {
        baseUrl
          .patch(`/client/${customerId}`, { ...postBody })
          .then((res) => {
            if (res.status === 200) {
              setLoader(false);
              setAlert(true);
              setTimeout(() => {
                setDisableBtn(false);
                handleClose();
              }, 1000);
            }
          })
          .catch((err) => {
            setLoader(false);
            setDisableBtn(false);
            dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
          });
      } else {
        baseUrl
          .post(`/client`, { ...postBody })
          .then((res) => {
            if (res.status === 200) {
              setLoader(false);
              setAlert(true);
              setTimeout(() => {
                setDisableBtn(false);
                handleClose();
                if (handleSave) {
                  handleSave(res);
                }
              }, 1000);
            }
          })
          .catch((err) => {
            setLoader(false);
            setDisableBtn(false);
            dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
          });
      }
    } else {
      if (data.location.length <= 0) {
        setError('location', {
          type: 'required',
          message: '',
        });
      }
      if (phoneError) {
        setError('phoneNumber', {
          type: 'exists',
          message: 'Phone Number already taken',
        });
      }
      if (emailError) {
        setError('emailAddress', {
          type: 'exists',
          message: 'Email address already taken',
        });
      }
    }
  };

  const handleCloseAlert = () => {
    setAlert(false);
  };

  const validateEmail = (e) => {
    setValue('emailAddress', e.target.value.trim());
    if (emailRegEx.test(watchAllFields.emailAddress)) {
      setDisableBtn(true);
      setEmailLoader(true);
      baseUrl(
        `/client-exist?business=${selectedBusiness}&field=emailAddress&value=${watchAllFields.emailAddress}`,
      )
        .then((res) => {
          if (res.status === 200) {
            if (res.data.length > 0 && res.data[0].client !== parseInt(customerId, 10)) {
              setDisableBtn(false);
              setEmailLoader(false);
              setEmailError(true);
              setError('emailAddress', {
                type: 'exists',
                message: 'Email address already taken',
              });
            } else {
              setEmailError(false);
              setEmailLoader(false);
              clearError('emailAddress');
              setDisableBtn(false);
            }
          }
        })
        .catch((err) => {
          setDisableBtn(false);
          setEmailLoader(false);
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    } else {
      clearError('emailAddress');
      setDisableBtn(false);
    }
  };

  const validatePhone = () => {
    if (watchAllFields.phoneNumber && watchAllFields.phoneNumber.length === 10) {
      const phoneNo = encodeURIComponent(`+1${watchAllFields.phoneNumber}`);
      setDisableBtn(true);
      setPhoneLoader(true);
      baseUrl(`/client-exist?business=${selectedBusiness}&field=phoneNumber&value=${phoneNo}`)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.length > 0 && res.data[0].client !== parseInt(customerId, 10)) {
              setDisableBtn(false);
              setPhoneError(true);
              setPhoneLoader(false);
              setError('phoneNumber', {
                type: 'exists',
                message: 'Phone Number already taken',
              });
            } else {
              setDisableBtn(false);
              setPhoneLoader(false);
              clearError('phoneNumber');
              setPhoneError(false);
            }
          }
        })
        .catch((err) => {
          setDisableBtn(false);
          setPhoneLoader(false);
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    } else {
      clearError('phoneNumber');
      setDisableBtn(false);
    }
  };

  const isPhoneRequired = useCallback(() => {
    if (watchAllFields.emailAddress) {
      return false;
    }
    return true;
  }, [watchAllFields.emailAddress]);

  useEffect(() => {
    register({ name: 'phoneNumber' }, { required: isPhoneRequired(), minLength: 10 });
    setValue('phoneNumber', tel);
  }, [register, isPhoneRequired, setValue, tel]);

  const showLoader = loader ? <ButtonLoader /> : null;

  const viewLoaderConatiner = viewLoader ? <Loader /> : null;

  const emailLoaderContent = emailLoader ? (
    <InputAdornment position="start">
      <ButtonLoader />
    </InputAdornment>
  ) : null;

  const endAdornmentContainer =
    unsubscribeEmail || unsubscribeMobile ? (
      <InputAdornment position="start">
        <ThemeProvider theme={{ color: iconsTheme.badgeColor, bgColor: iconsTheme.badgebgColor }}>
          <StyledChip label="Unsubscribed" />
        </ThemeProvider>
      </InputAdornment>
    ) : null;

  const phoneLoaderContent = phoneLoader ? (
    <InputAdornment position="start">
      <ButtonLoader />
    </InputAdornment>
  ) : (
    endAdornmentContainer
  );

  return renderView ? (
    <StyledDashboard container xs={12} sm={12} md={12} lg={12} direction="column">
      {viewLoaderConatiner}
      <CusotmAlert
        open={showAlert}
        message={
          customerId !== 'new' ? 'Contact updated successfully' : 'Contact added successfully'
        }
        handleClose={handleCloseAlert}
      />
      <MUIThemeProvider theme={inputTheme}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            position="sticky"
            top="0"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            zIndex={1}
            padding="1rem 1rem 0"
            margin="0 -1rem"
          >
            <FormHeader>{customerId === 'new' ? 'New Contact' : 'Edit Contact'}</FormHeader>
            <Box marginTop="-7rem" marginRight="-3rem" display="flex" height="3rem">
              <Box width="200px" marginTop="1.5rem" marginRight="-3rem">
                <CustomButton
                  type="submit"
                  width="300px"
                  disabled={disabledBtn || phoneLoader || emailLoader}
                >
                  Save
                  {showLoader}
                </CustomButton>
              </Box>
              <ThemeProvider theme={TransperentButtonTheme}>
                <StyledCloseButton onClick={() => handleClose()}>
                  <ThemeProvider theme={{ color: iconsTheme.clearIcon }}>
                    <CloseIcon />
                  </ThemeProvider>
                </StyledCloseButton>
              </ThemeProvider>
            </Box>
          </Box>
          <Grid container sm={12} xs={12} md={12} lg={12} spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <StyledTextField
                name="firstName"
                onBlur={(e) => setValue('firstName', e.target.value.trim())}
                label="First Name *"
                inputRef={register({ required: true })}
                error={!!errors.firstName}
                helperText={errors.firstName ? 'First Name is a required field' : ''}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <StyledTextField
                name="lastName"
                onBlur={(e) => setValue('lastName', e.target.value.trim())}
                label="Last Name"
                inputRef={register}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <NumberField
                name="phoneNumber"
                format="+1 ### ### ####"
                label="Contact Mobile *"
                onChange={(values) => {
                  setValue('phoneNumber', values.value);
                  clearError('phoneNumber');
                  setTel(values.value);
                }}
                error={!!errors.phoneNumber}
                helperText={
                  errors.phoneNumber ? handlePhoneError(errors.phoneNumber, 'Contact Mobile') : ''
                }
                defaultValue=""
                value={watchAllFields.phoneNumber}
                onBlur={validatePhone}
                InputProps={{
                  endAdornment: phoneLoaderContent,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <StyledTextField
                name="emailAddress"
                label="Email Address *"
                onChange={(e) => {
                  setValue('emailAddress', e.target.value);
                  clearError('emailAddress');
                }}
                inputRef={register({
                  required: !watchAllFields.phoneNumber,
                  pattern: emailRegEx,
                })}
                onBlur={validateEmail}
                error={!!errors.emailAddress}
                helperText={errors.emailAddress ? handleEmailError(errors.emailAddress) : ''}
                InputProps={{
                  endAdornment: emailLoaderContent,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <ThemeProvider theme={defaultDropDownTheme}>
                <StyledFormControl error={!!errors.location}>
                  <Controller
                    as={
                      <MultiSelect
                        listOptions={Array.from(locations)}
                        label="Select Location *"
                        style={{ maxWidth: '550px' }}
                      />
                    }
                    value={watchAllFields.location ? watchAllFields.location : []}
                    name="location"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={[]}
                  />
                  {errors.location ? (
                    <FormHelperText>Location is a required field</FormHelperText>
                  ) : null}
                </StyledFormControl>
              </ThemeProvider>
              {/* <ThemeProvider theme={defaultDropDownTheme}>
                <StyledFormControl error={!!errors.location}>
                  <Controller
                    as={<Dropdown listOptions={Array.from(locations)} label="Select Location *" />}
                    name="location"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                  />
                  {errors.location ? (
                    <FormHelperText>Location is a required field</FormHelperText>
                  ) : null}
                </StyledFormControl>
              </ThemeProvider> */}
            </Grid>
          </Grid>
        </form>
      </MUIThemeProvider>
    </StyledDashboard>
  ) : (
    <Loader />
  );
}

export default AddClient;
