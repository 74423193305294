import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import StarIcon from '@material-ui/icons/Star';
import baseUrl from '../../utils/services/config';
import { getParaByName, imageURLCheck } from '../../utils/helper/helper';
import ProgressBar from './ProgressBarComponent';

function TopReviewSouce() {
  const [topReviewSources, setTopReviewSources] = useState([]);
  const [settings, setSettings] = useState({
    settings: {
      settings: {
        shape: 'square',
        backgroundColor: '#fff',
        button: {
          fontSize: '12',
          bgColor: '#031a2b',
          color: '#ffffff',
          star: {
            color: '#878787',
          },
        },
        averageRating: {
          color: '#878787',
          fontSize: '54',
        },
      },
    },
  });

  const fetchTopReviewSources = useCallback(async () => {
    const result = await baseUrl.get(`micro-site-reviews-summary?id=${getParaByName('id')}`);
    if (result.status === 200) {
      setTopReviewSources(result.data.list);
      if (Object.keys(result.data.settings).length) {
        setSettings(result.data.settings);
      }
    }
  }, []);

  useEffect(() => {
    fetchTopReviewSources();
  }, [fetchTopReviewSources]);

  const useStyles = makeStyles(() => ({
    root: {
      paddingTop: '1vw',
    },
    square: {
      width: '374px',
      height: 'auto',
      borderRadius: '9px',
      // minHeight: '190px',
      // maxHeight: '250px',
      padding: '1em',
      position: 'relative',
      backgroundColor: `${settings.settings.settings.backgroundColor}`,
    },

    oval: {
      backgroundColor: `${settings.settings.settings.backgroundColor}`,
      fontSize: '12px',
      width: '500px',
      height: '250px',
      borderRadius: '50%',
      boxShadow: '0px 3px 6px #00000029',
    },

    Circle: {
      backgroundColor: `${settings.settings.settings.backgroundColor}`,
      fontSize: '12px',
      width: '400px',
      height: '400px',
      borderRadius: '50%',
      boxShadow: '0px 3px 6px #00000029',
    },
    rectangle: {
      position: 'relative',
      backgroundColor: `${settings.settings.settings.backgroundColor}`,
      fontSize: '12px',
      width: '96%',
      borderRadius: '2%',
      boxShadow: '0px 3px 6px #00000029',
      padding: '1em',
    },
    CardContent: {
      padding: '0 !important',
      textAlign: 'center',
    },
    secondCardContent: {
      height: '50%',
      background: 'rgb(50, 167, 83)',
      padding: '0 !important',
      textAlign: 'center',
      position: 'relative',
    },
    cardAction: {
      fontSize: '12px',
      textAlign: 'left',
      padding: '7px 10px !important',
    },
    textContect: {
      padding: '7px !important',
      textAlign: 'left',
      fontSize: '12px',
      fontWeight: 'bold',
      display: 'flex',
    },
    reviewHeader: {
      padding:
        settings.settings.settings.shape === 'oval' ? '.2em 1em !important' : '12px !important',
      textAlign: 'left',
      fontSize: settings.settings.settings.header
        ? `${settings.settings.settings.header.Headerfontsize}px`
        : '16px',
      fontWeight: 'bold',
      display: 'flex',
      color: settings.settings.settings.header
        ? `${settings.settings.settings.header.HeaderfontColour}`
        : '#37423E',
      fontFamily: 'Montserrat !important',
    },
    connect: {
      padding: '9px !important',
      fontSize: '10px',
      textAlign: 'center',
      // fontWeight: 'bold',
      color: '#32b6ec',
    },

    tableConatiner: {
      padding: '1em !important',
      width: '80%',
    },
    imgConatiner: {
      height: '25px',
      width: '25px',
    },
    setting: {
      right: '7px',
      bottom: '7px',
      position: 'absolute',
      width: '13px',
    },
  }));

  const classes = useStyles();

  const boxDesign = () => {
    if (settings.settings.settings.shape === 'circle') {
      return classes.Circle;
    }
    if (settings.settings.settings.shape === 'oval') {
      return classes.oval;
    }
    if (settings.settings.settings.shape === 'rectangle') {
      return classes.rectangle;
    }
    return classes.square;
  };

  const contentPlacement =
    settings.settings.settings.shape === 'oval' || settings.settings.settings.shape === 'circle'
      ? 'center'
      : 'flex-start';

  const topSourceContent = topReviewSources.map((item) => (
    <tr>
      <td>
        <img src={imageURLCheck(item.logo)} alt="" className={classes.imgConatiner} />
      </td>
      <td style={{ width: '100%' }}>
        <ProgressBar
          value={item.totalReviewCount}
          progressBarStyle={settings.settings.settings.progressBar}
        />
      </td>
      <td>
        <span style={{ color: `${settings.settings.settings.textColor}` }}>4.9</span>
      </td>
      <td>
        <span>
          <StarIcon style={{ color: '#FFA338' }} />
        </span>
      </td>
    </tr>
  ));

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Card className={boxDesign()}>
        <Grid
          container
          justifyContent={contentPlacement}
          alignItems={contentPlacement}
          direction="column"
          style={{ width: '100%', height: '100%' }}
        >
          <CardContent className={classes.reviewHeader}>Top Review Sources</CardContent>
          <CardContent className={classes.tableConatiner}>
            <table>
              <tbody>{topSourceContent}</tbody>
            </table>
          </CardContent>
        </Grid>
      </Card>
    </Grid>
  );
}

export default TopReviewSouce;
