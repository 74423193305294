import React, { useState, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import ViewHeadlineIcon from 'mdi-react/ViewHeadlineIcon';
import CircleOutlineIcon from 'mdi-react/CircleOutlineIcon';
import LightningBoltIcon from 'mdi-react/LightningBoltIcon';
import ArrowDecisionIcon from 'mdi-react/ArrowDecisionIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { TextField } from '@material-ui/core';

const SingleChoiceSortable = ({ choices = [], setChoices, onSort, ItemType = 'SINGLE_CHOICE' }) => {
  const [sortedChoices, setSortedChoices] = useState(choices);
  const [focusedIndex, setFocusedIndex] = useState(null);

  useEffect(() => {
    setSortedChoices(choices);
  }, [choices]);

  const handleSort = (dragIndex, hoverIndex) => {
    const newSortedChoices = [...sortedChoices];
    const [draggedChoice] = newSortedChoices.splice(dragIndex, 1);
    newSortedChoices.splice(hoverIndex, 0, draggedChoice);
    setSortedChoices(newSortedChoices);
    onSort(newSortedChoices);
  };

  const Choice = ({ choice, index }) => {
    const [text, setText] = useState('');
    const isFocused = index === focusedIndex;

    const [, drag] = useDrag({
      type: ItemType,
      item: { index },
    });

    const [, drop] = useDrop({
      accept: ItemType,
      hover: (draggedItem) => {
        if (draggedItem.index !== index) {
          handleSort(draggedItem.index, index);
          draggedItem.index = index;
        }
      },
    });

    const DeleteQuestion = () => {
      setChoices((prevArray) => {
        return prevArray.filter((obj) => obj.id !== choice.id);
      });
    };

    useEffect(() => {
      if (choice.label.length) {
        setText(choice.label);
      }
    }, [choice.label]);

    return (
      <div
        ref={(node) => drag(drop(node))}
        style={{
          display: 'flex',
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 15,
          paddingBottom: 15,
          backgroundColor: isFocused ? '#E6E6E6' : '#FFFFFF',
        }}
      >
        <div
          style={{
            width: '10%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <ViewHeadlineIcon size={30} style={{ marginRight: 10 }} />
          <CircleOutlineIcon size={30} style={{ marginRight: 10 }} />
        </div>
        <TextField
          key={choice.key}
          placeholder=""
          value={text}
          onInput={(e) => {
            setText(e.target.value);
          }}
          onFocus={() => setFocusedIndex(index)}
          onBlur={() => {
            setFocusedIndex(null);
            const updatedChoices = [...sortedChoices];
            if (updatedChoices[index]) {
              updatedChoices[index].label = text;
              setChoices(updatedChoices);
            }
          }}
          style={{ width: '70%' }}
        />
        <div style={{ width: '20%' }}>
          <LightningBoltIcon size={20} style={{ marginRight: 10 }} />
          <ArrowDecisionIcon size={20} style={{ marginRight: 10 }} />
          <DeleteIcon onClick={DeleteQuestion} size={20} />
        </div>
      </div>
    );
  };

  return (
    <>
      {sortedChoices.map((choice, index) => (
        <Choice key={choice.id} choice={choice} index={index} />
      ))}
    </>
  );
};

export default SingleChoiceSortable;
