import React, { useContext, useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { GlobalContext } from '../../../Stores/GlobalStateStore';
import NewConversationList from '../../List/NewConversationList/NewConveresationList';
import AddClientPopupForm from '../../Forms/AddClientPopupForm/AddClientPopupForm';
import useFetch from '../../../hooks/useFetch';
import { convertIntoContactList, convertIntoPhoneFormat } from '../../../utils/helper/helper';

function NewConversationPopup(props) {
  const {
    open,
    handleNewChatCreation,
    newConversationLoader,
    handleGroupViewToggle,
    setClientInfo,
  } = props;
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(null);
  const { state } = useContext(GlobalContext);
  const { sendInviteList } = state;
  const [isEdit, setIsEdit] = useState(false);
  const { fetchRecentSendInviteList } = useFetch();

  useEffect(() => {
    if (open) {
      setSelectedUser(null);

      fetchRecentSendInviteList();
    }
  }, [open, fetchRecentSendInviteList]);

  const handleUserSelection = (index) => {
    setSelectedGroupIndex(null);
    setSelectedUser(index);
  };

  const handleEditModeToggle = (clientData) => {
    if (clientData) {
      const clientFinalObj = {
        id: clientData.id,
        locations: clientData.locations,
        name: `${clientData.firstName} ${clientData.lastName}`,
        phone: clientData.phoneNumber ? convertIntoPhoneFormat(clientData.phoneNumber) : '',
        email: clientData.emailAddress,
        unsubscribeEmail: clientData.unsubscribeEmail ? clientData.unsubscribeEmail : false,
        unsubscribeMobile: clientData.unsubscribeMobile ? clientData.unsubscribeMobile : false,
      };
      setClientInfo(clientFinalObj);
    }
    setIsEdit((prevState) => !prevState);
  };

  const handleSelectedUser = () => {
    handleNewChatCreation(convertIntoContactList(sendInviteList.clients)[selectedUser]);
  };

  const handleGroupSelection = (index) => {
    setSelectedUser(null);
    setSelectedGroupIndex(index);
  };

  useEffect(() => {
    if (!isEdit) {
      setSelectedUser(null);
      fetchRecentSendInviteList();
    }
  }, [isEdit]);

  const toggleViewContainer = isEdit ? (
    <AddClientPopupForm handleEditModeToggle={handleEditModeToggle} open={open} />
  ) : (
    <NewConversationList
      handleEditModeToggle={handleEditModeToggle}
      handleUserSelection={handleUserSelection}
      selectedUser={selectedUser}
      handleSelectedUser={handleSelectedUser}
      newConversationLoader={newConversationLoader}
      handleGroupViewToggle={handleGroupViewToggle}
      selectedGroupIndex={selectedGroupIndex}
      handleGroupSelection={handleGroupSelection}
      open={open}
    />
  );

  return (
    // <Grid item xs={12} sm={12} md={12} lg={12}>
    open ? toggleViewContainer : ''
    // </Grid>
  );
}

NewConversationPopup.propTypes = {
  handleGroupViewToggle: Proptypes.func,
  open: Proptypes.bool.isRequired,
  newConversationLoader: Proptypes.bool.isRequired,
  handleNewChatCreation: Proptypes.func.isRequired,
};

export default React.memo(NewConversationPopup);
