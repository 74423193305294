import React, { useContext, useState } from 'react';
import Proptypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { Grid, ThemeProvider as MUIThemeProvider } from '@material-ui/core';
import StyledEditIcon from '../Icons/EditIcon';
import CustomButton from '../CustomButton/CustomButton';
import TransperentButtonTheme from '../../themes/Button/TransperentButtonTheme';
// import StyledCloseIcon from '../Icons/CloseIcon';
import StyledTextField from '../GeneralInputField/GeneralInputField';
import { OnBoardingContext } from '../../Stores/OnBoarding';
import useInputFieldTheme from '../../hooks/useInputFieldTheme';

const StyledTextContainer = styled(Grid)`
  position: relative !important;
  &:hover {
    & > div {
      border: 1px dotted #ccc;
    }
    & > span {
      display: block;
    }
  }
`;

const StyledEditIconContainer = styled.span`
  position: absolute;
  width: 100% !important;
  display: ${(props) => props.theme.displayStyle};
  right: 0;
  top: ${(props) => props.theme.top};
  text-align: right;
`;

const buttonAdditionalStyle = {
  minWidth: '20px',
  maxWidth: '20px',
  padding: '0',
  border: 'none',
};

const editIconStyle = {
  fontSize: '16px',
  color: '#000',
};

const displayNone = {
  displayStyle: 'none',
};

const displayBlock = {
  displayStyle: 'block',
};

const inputFieldStyle = {
  padding: '0',
  fontSize: '12px',
  inputMargin: '0',
  textAlign: 'center',
  color: 'inherit',
  border: 'none',
};

function EditableButton(props) {
  const { activeStep, radioValue, type, isEditable, handleClick, editIconTheme, textColor } = props;
  const { onBoardingDispatch, onBoardingData } = useContext(OnBoardingContext);
  const { defaultPortals } = onBoardingData;
  const [isEdit, setIsEdit] = useState(false);
  const handleEdit = () => setIsEdit(true);
  const handleChange = (e) => {
    const defaultPortalCopy = { ...defaultPortals };
    defaultPortalCopy[radioValue].screens[activeStep][type] = e.target.value;
    onBoardingDispatch({ type: 'SET_DEFAULT_PORTAL', payload: { ...defaultPortalCopy } });
  };
  const { inputTheme } = useInputFieldTheme();

  const handleBlur = () => {
    setIsEdit(false);
  };

  const iconContainer =
    isEdit || !isEditable ? null : (
      <ThemeProvider theme={editIconStyle}>
        <StyledEditIcon />
      </ThemeProvider>
    );
  const textContainer =
    isEdit && isEditable ? (
      <CustomButton>
        <MUIThemeProvider theme={{ ...inputTheme, inputTextColor: textColor }}>
          <ThemeProvider theme={inputFieldStyle}>
            <StyledTextField
              value={defaultPortals[radioValue].screens[activeStep][type]}
              onChange={handleChange}
              onBlur={handleBlur}
              autoFocus={isEdit}
              multiline
              rowsMax={4}
            />
          </ThemeProvider>
        </MUIThemeProvider>
      </CustomButton>
    ) : (
      <ThemeProvider
        theme={{
          padding: '6px 8px',
        }}
      >
        <CustomButton onClick={handleClick}>
          {defaultPortals[radioValue].screens[activeStep][type]}
        </CustomButton>
      </ThemeProvider>
    );

  return (
    <StyledTextContainer item xs={12} sm={12} lg={12} md={12}>
      <StyledEditIconContainer
        theme={
          isEdit ? { ...displayBlock, ...editIconTheme } : { ...displayNone, ...editIconTheme }
        }
      >
        <ThemeProvider
          theme={{
            ...TransperentButtonTheme,
            ...buttonAdditionalStyle,
          }}
        >
          <CustomButton onClick={handleEdit}>{iconContainer}</CustomButton>
        </ThemeProvider>
      </StyledEditIconContainer>
      {textContainer}
    </StyledTextContainer>
  );
}

EditableButton.propTypes = {
  activeStep: Proptypes.number.isRequired,
  radioValue: Proptypes.string.isRequired,
  type: Proptypes.string.isRequired,
  isEditable: Proptypes.bool,
  handleClick: Proptypes.func,
  editIconTheme: Proptypes.objectOf(Proptypes.string),
  textColor: Proptypes.string,
};

EditableButton.defaultProps = {
  isEditable: true,
  handleClick: () => {},
  editIconTheme: {},
  textColor: '#000',
};

export default EditableButton;
