import React, { useEffect, useState, useContext } from 'react';
import Proptypes from 'prop-types';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import { convertIntoIndustryTable, errorChecker } from '../../utils/helper/helper';
import getIndustryColumns from '../../Columns/IndustryColumns';
import CusotmTable from '../../components/CustomTable/CusotmTable';
import Loader from '../../components/Loader/Loader';
import CusotomSearchBox from '../../components/CustomSearchBox/CustomSearchBox';
import IndustryPopup from '../../components/IndustryPopup/IndustryPopup';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import baseUrl from '../../utils/services/config';
import CusotmAlert from '../../components/CustomAlert/CustomAlert';
import useAlert from '../../hooks/useAlert';
import useSearch from '../../hooks/useSearch';
import CancellationPopup from '../../components/Popup/CancellationPopup/CancellationPopup';
import useServerSidePagination from '../../hooks/useServerPagination';
import useIconTheme from '../../hooks/useIconTheme';

function Industry(props) {
  const [industryData, setIndustryData] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const { state, dispatch } = useContext(GlobalContext);
  const { roles, themeContainer } = state;
  const { isSuperAdmin } = roles;
  const { handleClose, open, handleOpen, industryFilter } = props;
  const {
    paginationInfo,
    tableData,
    loader,
    handleRowPerPageChange,
    handlePageChange,
    handleTableSearch,
    handleInitTable,
    searchLoader,
  } = useServerSidePagination({
    apiEndpoint: `/industry/list`,
    originalData: industryData,
    depends: localStorage.getItem('USER_ID'),
    secondParameter: 'deleted',
    secondParameterDepends: industryFilter,
  });
  const { rowsPerPage, page, count } = paginationInfo;
  const industryAlert = useAlert();
  const [showModal, setshowModal] = useState(false);
  const { filteredData, clearSearch, handleSearch, searchValue } = useSearch({
    searchParameters: ['name'],
    data: industryData || [],
  });
  const [deleteLoader, setDeleteloader] = useState(false);
  const { iconsTheme } = useIconTheme();

  useEffect(() => {
    setIndustryData(Array.from(convertIntoIndustryTable(tableData)));
  }, [tableData]);

  useEffect(() => {
    setIndustryData([]);
  }, [rowsPerPage, industryFilter]);

  const handleEdit = (dataIndex) => {
    setSelectedIndustry(industryData[dataIndex].id);
    handleOpen();
  };

  const handleInitState = () => {
    setSelectedIndustry(null);
    setIndustryData([]);
    handleInitTable();
    handleClose();
  };

  const handleDelete = (dataIndex) => {
    localStorage.setItem('delete_row_id', dataIndex);
    setshowModal(true);
  };

  const cancelHandleClose = () => {
    setshowModal(false);
    localStorage.removeItem('delete_row_id');
  };

  const showDeletionPopUp = async () => {
    const dataIndex = localStorage.getItem('delete_row_id');
    setDeleteloader(true);
    const { handleSetMessage, handleSetMessageType, handleShow } = industryAlert;
    const industryId = industryData[dataIndex].id;
    const industryDataCopy = Array.from(industryData);
    industryDataCopy.splice(dataIndex, 1);
    const postBody = {
      isDeleted: true,
    };
    try {
      const results = await baseUrl.patch(`/industry/${industryId}`, { ...postBody });
      if (results.status === 200) {
        handleSetMessageType('success');
        handleSetMessage('Industry deleted successfully');
        handleShow(true);
        cancelHandleClose();
        setIndustryData(industryDataCopy);
      }
    } catch (err) {
      setDeleteloader(false);
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const popUpTheme = createTheme({
    background: themeContainer.popoverBgColor,
  });

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    download: false,
    print: false,
    selectableRowsHeader: false,
    selectableRows: 'none',
    elevation: 0,
    viewColumns: false,
    filter: true,
    searchOpen: true,
    onSearchClose: false,
    selectToolbarPlacement: 'none',
    toolbar: {},
    count,
    page,
    textLabels: {
      body: {
        noMatch: loader ? <Loader /> : 'No data found !',
      },
    },

    onChangeRowsPerPage: (numberOfRows) => {
      handleRowPerPageChange(numberOfRows);
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handlePageChange(tableState.page);
          break;
        case 'search':
          handleTableSearch(tableState.searchText);
          break;
        default:
      }
    },
    customSearchRender: () => (
      <ThemeProvider theme={{ color: themeContainer.tableSearchBoxColor }}>
        <CusotomSearchBox
          loader={loader}
          placeholder="Search…"
          handleChange={handleSearch}
          clearSearch={clearSearch}
          searchValue={searchValue}
        />
      </ThemeProvider>
    ),
    rowsPerPage,
    rowsPerPageOptions: [5, 10, 15, 100],
  };

  const showLoader = searchLoader ? <Loader /> : null;

  return (
    <>
      {showLoader}
      <CusotmAlert
        message={industryAlert.message}
        handleClose={industryAlert.handleClose}
        open={industryAlert.show}
        messageType={industryAlert.messageType}
      />
      <CusotmTable
        data={filteredData}
        columns={getIndustryColumns(handleEdit, isSuperAdmin, handleDelete, iconsTheme)}
        options={options}
        count={count}
      />
      <MUIThemeProvider theme={popUpTheme}>
        <IndustryPopup
          open={open}
          handleClose={handleInitState}
          selectedIndustry={selectedIndustry}
        />

        <CancellationPopup
          show={showModal}
          showDeletionPopUp={showDeletionPopUp}
          handleClose={cancelHandleClose}
          deleteLoader={deleteLoader}
        />
      </MUIThemeProvider>
    </>
  );
}

Industry.propTypes = {
  open: Proptypes.bool.isRequired,
  handleClose: Proptypes.func.isRequired,
  handleOpen: Proptypes.func.isRequired,
  //industryFilter: Proptypes.bool.isRequired,
};

export default Industry;
