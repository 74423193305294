const { default: styled } = require('styled-components');
const { NavLink } = require('react-router-dom');

const StyledNavLink = styled(NavLink)`
  color: ${(props) => props.theme.color} !important;
  margin-bottom: 20px;
  font-family: 'Montserrat';
`;

export default StyledNavLink;
