import React from 'react';
import Proptypes from 'prop-types';
import ChatGpt from '../Icons/ChatGpt';
import { Button, CircularProgress, IconButton } from '@material-ui/core';
import AutoFix from 'mdi-react/AutoFixIcon';
import styled from 'styled-components';
import PrimaryButton from '../Common/PrimaryButton';

const StyledButton = styled(Button)`
  background: ${(props) => (props.theme.background ? props.theme.background : '#fff')} !important;
  color: ${(props) => (props.theme.color ? props.theme.color : '#000')} !important;
  border-radius: ${(props) =>
    props.theme.borderRadis ? props.theme.borderRadis : '20px'} !important;
  margin: ${(props) => (props.theme.margin ? props.theme.margin : '1.5em auto 0.5em')} !important;
  width: ${(props) => (props.theme.width ? props.theme.width : '80%')};
  height: ${(props) => (props.theme.height ? props.theme.height : 'auto')};
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${(props) => (props.theme.color ? props.theme.color : '#000')} !important;
`;

function ButtonOpenAi(props) {
  const { handleOnClick, title, width, height, isLoading, disabled } = props;

  return (
    <IconButton onClick={handleOnClick} title={title} disabled={disabled}>
      {!isLoading ? (
        <AutoFix width={width} height={height} />
      ) : (
        <StyledCircularProgress size={20} />
      )}
    </IconButton>
  );
}

ButtonOpenAi.propTypes = {
  handleOnClick: Proptypes.func,
  title: Proptypes.string,
  width: Proptypes.string,
  height: Proptypes.string,
  color: Proptypes.string,
  isLoading: Proptypes.bool,
};

export default ButtonOpenAi;
