import { createTheme } from '@material-ui/core/styles';
import { useContext, useMemo, useState } from 'react';
import { GlobalContext } from '../Stores/GlobalStateStore';

const useTable2Theme = () => {
  const { state } = useContext(GlobalContext);
  const { themeContainer } = state;
  const [theme, setTheme] = useState({});

  useMemo(() => {
    setTheme(
      createTheme({
        editableTableBorderBottom: themeContainer.editableTableBorderBottom,
        tableCellBackground: themeContainer.tableCellBackground,
        tableEditBorderRight: themeContainer.tableEditBorderRight,
        tableCellColor: themeContainer.tableCellColor,
        contactInfoColor: themeContainer.contactInfoColor,
        tablePaginationColor: themeContainer.tablePaginationColor,
        tablePaginationDisabledBtnColor: themeContainer.tablePaginationDisabledBtnColor,
      }),
    );
  }, [themeContainer]);

  return {
    customTable2Theme: theme,
  };
};
export default useTable2Theme;
