import React from 'react';
import TabPanel from '../../components/TabPanel/TabPanel';
import { Grid, Box, FormHelperText } from '@material-ui/core';
import styled from 'styled-components';
import StyledTextField from '../../components/GeneralInputField/GeneralInputField';
import { ThemeProvider } from 'styled-components';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import defaultDropDownTheme from '../../themes/Dropdown/DefaultDropdownTheme';
import StyledFormControl from '../../components/StyledFormControl';
import useTextColors from '../../hooks/useTextColors';
import StyledImpText from '../../components/ImpText/ImpText';
import StyledDescriptionText from '../../components/DescriptionText';
import CustomSwitch from '../../components/CustomSwitch/CustomSwitch';
import Uploader from '../../components/Uploader/Uploader';
import FieldGroupLable from '../../components/FieldGroupLable/FieldGroupLable';

const fullSizeUploader = {
  maxWidth: '100%',
  height: '224px',
};

const logoUploader = {
  maxWidth: '100%',
  height: '224px',
};

export const GeneralTab = (props) => {
  const {
    tabValue,
    selectedBusiness,
    usersContainer,
    formik,
    imagesContainer,
    setImagesContainer,
  } = props;
  const { textColors } = useTextColors();

  const handleImagesContainer = (name, imgId, imgData) => {
    let imagesContainerCopy = { ...imagesContainer };
    imagesContainerCopy = Object.assign(imagesContainer, { ...{ [name]: imgId } });
    setImagesContainer(imagesContainerCopy);
  };

  const userSelection = selectedBusiness ? (
    <Grid item md={12} lg={12} xs={12} sm={12}>
      <ThemeProvider theme={defaultDropDownTheme}>
        <StyledFormControl label="Location Users" error={formik.errors.users}>
          <MultiSelect
            name={'users'}
            listOptions={Array.from(usersContainer)}
            label="Select Users *"
            value={formik.values.users}
            onChange={formik.handleChange}
          />
          {formik.errors.users ? <FormHelperText>{formik.errors.users}</FormHelperText> : null}
        </StyledFormControl>
      </ThemeProvider>
    </Grid>
  ) : null;

  return (
    <TabPanel value={tabValue} index={0}>
      <Grid container marginTop={'1rem'} spacing={3}>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <StyledTextField
            name="name"
            label="Location Name *"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <StyledTextField
            name="nickName"
            label="Nickname *"
            value={formik.values.nickName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.nickName && Boolean(formik.errors.nickName)}
            helperText={formik.touched.nickName && formik.errors.nickName}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
          <ThemeProvider theme={logoUploader}>
            <Uploader
              name="business_logo"
              setValue={(name, url) => url && formik.setFieldValue(name, url)}
              value={formik.values.business_logo}
              handleImagesContainer={handleImagesContainer}
              imgWidth={300}
              imgHeight={150}
              apiUrl="/uploads/images"
              imageType="location"
              customStyle={{ padding: '2rem', width: '60%' }}
              description={'Location logo (300 x 300)'}
            />
          </ThemeProvider>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box container marginTop={'1rem'} display="flex" justifyContent={'space-between'}>
            <Box>
              <ThemeProvider theme={{ color: textColors.impTextColor }}>
                <StyledImpText item>Accept reviews</StyledImpText>
              </ThemeProvider>
              <ThemeProvider theme={{ color: textColors.descriptionTextColor }}>
                <StyledDescriptionText item>
                  If enabled, this location will accept reviews
                </StyledDescriptionText>
              </ThemeProvider>
            </Box>
            <Box>
              <CustomSwitch
                checked={formik.values.acceptReviews}
                onChange={() => formik.setFieldValue('acceptReviews', !formik.values.acceptReviews)}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <StyledTextField
            name="noEmployees"
            type="number"
            label="Number of Employees"
            value={formik.values.noEmployees}
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>
      {userSelection}
      <Grid item xs={12}>
        <StyledTextField
          name="aboutUs"
          label="About us"
          inputProps={{ 'aria-label': 'description', maxLength: 1000 }}
          multiline
          minRows={2}
          maxRows={3}
          value={formik.values.aboutUs}
          onChange={formik.handleChange}
        />
      </Grid>
    </TabPanel>
  );
};
