import React, { createContext, useReducer } from 'react';
import Proptypes from 'prop-types';
import reportsState from '../state/ReportsState';
import ReportsReducer from '../Reducers/ReportsReducer';

export const OnReportsContext = createContext(reportsState);

const ReportsStore = ({ children }) => {
  const [state, reportsDispatch] = useReducer(ReportsReducer, reportsState);
  return (
    <OnReportsContext.Provider value={{ state, reportsDispatch }}>
      {children}
    </OnReportsContext.Provider>
  );
};

ReportsStore.propTypes = {
  children: Proptypes.node.isRequired,
};

export default ReportsStore;
