import React, { useContext } from 'react';
import Proptypes from 'prop-types';
import { Grid, ThemeProvider as MUIThemeProvider } from '@material-ui/core';
import { ThemeProvider } from 'styled-components';
import PageTitle from '../../../components/PageTitle/PageTitle';
import CustomButton from '../../../components/CustomButton/CustomButton';
import CustomTable2 from '../../../components/CustomTable/CustomTable2';
import getMainGroupColumns from '../../../Columns/mainGroup';
import { GlobalContext } from '../../../Stores/GlobalStateStore';
import {
  getSelectedContacts,
  getUniqueArray,
  selectAllContacts,
} from '../../../utils/helper/helper';
import useTable2Theme from '../../../hooks/useTable2Theme';
import useButtonTheme from '../../../hooks/useButtonTheme';

function GroupUploadedContacts(props) {
  const { handleNext } = props;
  const { state, dispatch } = useContext(GlobalContext);
  const { groupContainer } = state;
  const { customTable2Theme } = useTable2Theme();
  const { buttonTheme } = useButtonTheme();

  const handleAddContactToGroup = () => {
    dispatch({
      type: 'SET_GROUP_LIST_VIEW_CONTAINER',
      payload: getSelectedContacts(groupContainer.data, groupContainer.selectedContact),
    });
    getSelectedContacts(groupContainer.data, groupContainer.selectedContact);
    dispatch({
      type: 'SET_GROUP_LIST_VIEW_SELECTED_CONTACTS',
      payload: selectAllContacts(
        getUniqueArray(
          getSelectedContacts(groupContainer.data, groupContainer.selectedContact).concat(
            groupContainer.listViewData,
          ),
        ),
      ),
    });

    handleNext(1);
  };

  return (
    <Grid container style={{ height: '100%' }}>
      <Grid item xs={12} sm={12} md={12} lg={12} style={{ height: '100%' }}>
        <Grid container direction="column" style={{ height: '100%' }} wrap="nowrap">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ flex: '1 1 auto', maxHeight: '70px' }}
          >
            <Grid container alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} style={{ margin: '1em' }}>
                <Grid container alignItems="center">
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <PageTitle title="Uploaded Contacts" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{ margin: '1em', overflow: 'auto' }}>
            <MUIThemeProvider theme={customTable2Theme}>
              <CustomTable2
                columns={getMainGroupColumns()}
                data={groupContainer.data}
                isEdit
                selectActionType="SET_SELECTED_CONTACTS"
                selectedContactsList={groupContainer.selectedContact}
              />
            </MUIThemeProvider>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ flex: '1 0 auto', maxHeight: '60px', padding: '.5em 0' }}
          >
            <Grid container justify="flex-end" alignItems="center">
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <ThemeProvider
                  theme={{
                    margin: '0',
                    background: buttonTheme.buttonBgColor,
                    color: buttonTheme.buttonColor2,
                  }}
                >
                  <CustomButton
                    disabled={!groupContainer.selectedContact.length}
                    onClick={handleAddContactToGroup}
                  >
                    Add to Group
                  </CustomButton>
                </ThemeProvider>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

GroupUploadedContacts.propTypes = {
  handleNext: Proptypes.func.isRequired,
};

export default GroupUploadedContacts;
