import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Popover, Paper, MenuItem, MenuList, Box } from '@material-ui/core';
import Proptypes from 'prop-types';
import styled from 'styled-components';
import GenricPopup from '../../Popup/GenricPopup';
import useModal from '../../../hooks/useModal';
import GenericCampaignStart from './components/CampaignSettings/GenericCampaignStart';
import { SurveyContext } from '../../../Stores/SurveyStore';
import RequestSettings from './components/RequestSettings/RequestSettings';
import AddClient from './components/CampaignSettings/AddClient';
import PreviewList from './components/CampaignSettings/PreviewList';
import { GlobalContext } from '../../../Stores/GlobalStateStore';

const StyledPaper = styled(Paper)`
  min-width: 200px !important;
  max-width: 200px !important;
  background-color: #3f3f3f;
`;

const StyledItemsBox = styled.div`
  background-color: #f5f5f5;
`;

const StyledListItemText = styled.div`
  font-family: 'Montserrat' !important;
  font-weight: 600 !important;
  padding: 0.3rem 0;
`;

const StyledBoxLateralMenu = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const StyledStepContent = styled.div`
  height: auto;
  flex: 1;
`;
const useStyles = makeStyles((theme) => ({
  paper: {
    background: theme.background,
  },
}));

function SendInvitePopover(props) {
  const { id, open, anchorEl, handleClose } = props;

  const { surveyData, surveyDispatch, selectedContacts } = useContext(SurveyContext);
  const { dispatch } = useContext(GlobalContext);

  // CHANGE THIS TO CHANGE THE ACTIVE STEP *** SET TO 0 TO RESET ***
  const [activeStep, setActiveStep] = useState(0);

  // const [isVisibleStatusMessage, setIsVisibleStatusMessage] = useState(false);

  // CHANGE THIS TO CHANGE THE REQUEST TYPE *** CLEAR TO RESET ***
  const [campaignType, setCampaignType] = useState();

  const [campaignId, setCampaignId] = useState(null);
  const [isCSV, setIsCSV] = useState(false);

  const classes = useStyles();
  const surveyPopup = useModal();
  const messagePopup = useModal();
  const statusMessagePopup = useModal();

  const findLocationById = (locationId) => {
    const { location } = JSON.parse(localStorage.getItem('USER_INFO'));
    if (locationId && locationId.length) {
      const locationArray = location.filter((item) => item.id === parseInt(locationId, 10));
      const nickName = locationArray[0].nickName ? locationArray[0].nickName : '';
      return nickName;
    }
    return '';
  };

  const clearContacts = () => {
    surveyDispatch({
      type: 'SET_SELECTED_CONTACTS',
      payload: [],
    });
    dispatch({ type: 'SET_INVITE_DATA', payload: [] });
  };

  const handleSurvey = () => {
    clearContacts();
    surveyPopup.handleShowModal();
    setCampaignType('survey');
  };
  const handleMessage = () => {
    clearContacts();
    setIsCSV(false);
    messagePopup.handleShowModal();
    setCampaignType('message');
  };
  const handleReview = () => {
    clearContacts();
    messagePopup.handleShowModal();
    setCampaignType('review');
  };

  const handleReviewRequest = () => {
    // eslint-disable-next-line react/destructuring-assignment
    props.handleSendInvite();
  };

  const handleNext = (step) => {
    if (step >= 0) {
      setActiveStep(step);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handlePrevious = (step) => {
    if (step >= 0) {
      setActiveStep(step);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };
  const changeStep = (value) => {
    setActiveStep(value);
  };

  const handleCloseCampaignSettings = () => {
    setActiveStep(0);

    surveyDispatch({
      type: 'SET_SELECTED_CONTACTS',
      payload: [],
    });
    surveyPopup.handleHideShowModal();
    messagePopup.handleHideShowModal();
    // setIsVisibleStatusMessage(false);
  };

  const handleCloseMessage = () => {
    setActiveStep(0);

    surveyDispatch({
      type: 'SET_SELECTED_CONTACTS',
      payload: [],
    });
    messagePopup.handleHideShowModal();
  };

  const handlepressSavePreview = (data) => {
    if (data.length) {
      surveyDispatch({
        type: 'SET_SELECTED_CONTACTS',
        payload: data,
      });
      handlePrevious();
    }
  };

  const handleSaveAddClient = (response) => {
    surveyDispatch({
      type: 'SET_SELECTED_CONTACTS',
      payload: [response.data],
    });
    changeStep(1);
  };
  const getStepContent = (step) => {
    switch (step) {
      // case 0:
      //   return (
      //     <SelectOrUploadContacts
      //       changeStep={changeStep}
      //       handleNext={handleNext}
      //       campaignType={campaignType}
      //     />
      //   );
      case 0:
        return (
          <GenericCampaignStart
            handlePrevious={handlePrevious}
            handleClose={handleCloseCampaignSettings}
            handleNext={handleNext}
            campaignType={campaignType}
            changeStep={changeStep}
            setCampaignId={setCampaignId}
            campaignId={campaignId}
            isCSV={isCSV}
            setIsCSV={setIsCSV}
          />
        );
      case 1:
        return (
          <PreviewList
            handleNext={handleNext}
            activeStep={activeStep}
            title="Preview List"
            disableLocations
            isPreviewSurvey
            changeStep={changeStep}
            handlepressCancel={handlePrevious}
            handlepressSave={handlepressSavePreview}
            listViewData={surveyData.selectedContacts.map((data) => {
              return {
                data,
                name: `${data.firstName} ${data.lastName}`,
                email: data.emailAddress,
                phone: data.phoneNumber,
                location: findLocationById(data.location),
              };
            })}
          />
        );
      case 2:
        return (
          <AddClient
            currentContact={undefined}
            handleSave={handleSaveAddClient}
            handleClose={() => changeStep(0)}
          />
        );
      case 3:
        return (
          <RequestSettings
            campaignType={campaignType}
            changeStep={changeStep}
            campaignId={campaignId}
          />
        );

      default:
        return 'Unknown step';
    }
  };

  const handleCloseStatusMessage = () => {
    // setIsVisibleStatusMessage(false);
    surveyDispatch({
      type: 'SET_STATUS_MESSAGE',
      payload: {
        redirectTo: '',
        statusMessage: '',
      },
    });
  };

  useEffect(() => {
    if (surveyData.statusMessage.length) {
      //setIsVisibleStatusMessage(true);
    }
  }, [surveyData.statusMessage]);
  return (
    <Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{
          paper: classes.paper,
        }}
        style={{ marginTop: '50px' }}
      >
        <StyledPaper>
          <StyledItemsBox>
            <MenuList dense onClick={handleClose}>
              <MenuItem onClick={handleSurvey}>
                <StyledListItemText>Survey</StyledListItemText>
              </MenuItem>
              <MenuItem>
                <StyledListItemText onClick={handleMessage}>Message</StyledListItemText>
              </MenuItem>
              <MenuItem>
                <StyledListItemText onClick={handleReview}>Review</StyledListItemText>
              </MenuItem>
            </MenuList>
          </StyledItemsBox>
        </StyledPaper>
      </Popover>

      {/* <GenricPopup
        open={isVisibleStatusMessage}
        handleClose={handleCloseCampaignSettings}
        fullWidth
      >
        <StatusMessage
          Icon={
            <ThemeProvider theme={{ color: iconsTheme.checkCircleIcon }}>
              <StyledCheckCircle />
            </ThemeProvider>
          }
          message={surveyData.statusMessage}
          actionBtnText="Close"
          redirectLinkText="Go to Feedback"
          redirectTo={surveyData.redirectTo}
          handleClose={handleCloseCampaignSettings}
        />
      </GenricPopup> */}

      <GenricPopup
        open={surveyPopup.showModal}
        // open={true}
        maxWidth="sm"
        handleClose={handleCloseCampaignSettings}
        fullWidth
        invitePopup
      >
        <StyledBoxLateralMenu>
          <StyledStepContent>{getStepContent(activeStep)}</StyledStepContent>
        </StyledBoxLateralMenu>
      </GenricPopup>

      <GenricPopup
        open={messagePopup.showModal}
        //open={true}
        maxWidth="sm"
        handleClose={handleCloseMessage}
        fullWidth
      >
        <StyledBoxLateralMenu>
          <StyledStepContent>{getStepContent(activeStep)}</StyledStepContent>
        </StyledBoxLateralMenu>
      </GenricPopup>
    </Box>
  );
}
SendInvitePopover.defaultProps = {
  id: '',
  anchorEl: null,
};
SendInvitePopover.propTypes = {
  id: Proptypes.any,
  open: Proptypes.bool.isRequired,
  handleClose: Proptypes.func.isRequired,
  //anchorEl: Proptypes.element,
};

export default SendInvitePopover;
