import React from 'react';

const { withStyles } = require('@material-ui/styles');
const { Tab } = require('@material-ui/core');

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 20,
    fontWeight: 500,
    marginRight: 20,
    padding: 0,
    fontFamily: 'Montserrat',
    color: theme.tabColor,
    '&:hover': {
      color: theme.tabColor,
      opacity: 1,
    },
    '&$selected': {
      color: theme.selectedTabColor,
      fontWeight: 500,
    },
    '&:focus': {
      color: theme.selectedTabColor,
    },
    '&$disabled': {
      pointerEvents: 'none',
      color: theme.disabledTabColor,
    },
  },
  selected: {},
  disabled: {},
  // eslint-disable-next-line react/jsx-props-no-spreading
}))((props) => <Tab disableRipple {...props} />);

export default AntTab;
