const getMainGroupColumns = () => [
  {
    name: 'firstName',
    label: 'First Name',
  },
  {
    name: 'lastName',
    label: 'Last Name',
  },
  {
    name: 'email',
    label: 'Email',
  },
  {
    name: 'phone',
    label: 'Phone',
  },
  {
    name: 'tags',
    label: 'Tags',
  },
  {
    name: 'status',
    label: 'Status',
  },
];
export const getMainGroupColumnsSurvey = () => [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'email',
    label: 'Email',
  },
  {
    name: 'phone',
    label: 'Phone',
  },
  // {
  //   name: 'location',
  //   label: 'Location',
  // },
];

export default getMainGroupColumns;
