import { Box, Grid } from '@material-ui/core';
import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';

import CustomButton from '../../components/CustomButton/CustomButton';
import PageTitle from '../../components/PageTitle/PageTitle';
import SurveyBuilderPopover from '../../components/Popover/SurveyBuilderPopover/SurveyBuilderPopover';
import useButtonTheme from '../../hooks/useButtonTheme';
import useModal from '../../hooks/useModal';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import MainViewActionButtonTheme from '../../themes/Button/MainViewActionButtonTheme';

const SurveyBuilder = () => {
  const { buttonTheme } = useButtonTheme();
  const { state } = useContext(GlobalContext);
  const { themeContainer } = state;

  const surveyModal = useModal();

  return (
    <div>
      <Box display="flex" justifyContent="space-between">
        <PageTitle title="Survey Builder" />

        <Grid item xs={12} md={6} lg={6} sm={12}>
          <Grid container alignItems="flex-end" justifyContent="flex-end">
            <ThemeProvider
              theme={{
                ...MainViewActionButtonTheme,
                background: buttonTheme.buttonBgColor,
                color: themeContainer.fontColor11,
              }}
            >
              <CustomButton onClick={surveyModal.handleShowModal}>Create survey</CustomButton>
            </ThemeProvider>
          </Grid>
        </Grid>

        <SurveyBuilderPopover
          handleClose={surveyModal.handleHideShowModal}
          show={surveyModal.showModal}
        />
      </Box>
    </div>
  );
};

export default SurveyBuilder;
