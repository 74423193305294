const { withStyles } = require('@material-ui/styles');
const { Tabs } = require('@material-ui/core');

const AntTabs = withStyles((theme) => ({
  root: {
    minHeight: '40px',
    height: '40px',
    '& .MuiTabScrollButton-root': {
      color: theme.tabScrollBtn,
    },
  },
  indicator: {
    backgroundColor: theme.tabIndicator,
  },
}))(Tabs);

export default AntTabs;
