const surveyReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SURVEY_TITLE':
      return {
        ...state,
        title: action.payload,
      };
    case 'SET_SELECTED_CONTACTS':
      return {
        ...state,
        selectedContacts: action.payload,
      };
    case 'SET_STATUS_MESSAGE':
      return {
        ...state,
        redirectTo: action.payload.redirectTo,
        statusMessage: action.payload.statusMessage,
      };
    case 'SET_SURVEYS_BUSINESS':
      return {
        ...state,
        surveyByBusiness: action.payload,
      };
    case 'SET_SURVEY_QUESTIONS_AVERAGE':
      return {
        ...state,
        surveyQuestionsAverage: action.payload,
      };
    case 'SET_SURVEY_BUSINESS_RESPONSES':
      return {
        ...state,
        surveyBusinessResponses: action.payload,
      };
    case 'SET_SURVEYS_LOCATION_DETAILS':
      return {
        ...state,
        surverLocationDetails: action.payload,
      };

    default:
      return state;
  }
};

export default surveyReducer;
