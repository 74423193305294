import { lightTheme } from '../setup';
import { getLocalStorageItem } from '../utils/helper/helper';

const globalState = {
  selectedLoaction: getLocalStorageItem('SELECTED_LOCATION', ''),
  selectedBusiness: getLocalStorageItem('SELECTED_BUSINESS', ''),
  businesses: [],
  locations: [],
  token:
    localStorage.getItem('AUTH_TOKEN') && localStorage.getItem('AUTH_TOKEN') !== undefined
      ? localStorage.getItem('AUTH_TOKEN')
      : null,
  profilePic: null,
  showError: false,
  alertMessageType: 'error',
  errorMessage: '',
  isExpired: false,
  googleAccountId: '',
  facebookAccountId: '',
  businessInfo: {},
  locationInfo: {},
  userInfo:
    localStorage.getItem('USER_INFO') && localStorage.getItem('USER_INFO') !== undefined
      ? JSON.parse(localStorage.getItem('USER_INFO'))
      : {},
  userRole:
    localStorage.getItem('User') && localStorage.getItem('User') !== undefined
      ? JSON.parse(localStorage.getItem('User')).role[0]
      : {},
  campaignLocation: getLocalStorageItem('SELECTED_LOCATION'),
  notificationContainer: [],
  notificationLoader: false,
  groupContainer: {
    loader: false,
    data: [],
    selectedContact: [],
    listViewData: [],
    listViewSelectedContact: [],
    selectedGroupLocation: '',
    groupNameContainer: [],
    selectedGroupName: [],
    existingClientContainer: [],
    finalGroupData: [],
    isSaved: false,
  },
  selectedGroupId: null,
  // Send invite list container
  sendInviteList: {
    groups: [],
    clients: [],
  },
  inviteData: [],
  selectedGroupInfo: {},
  sendInviteLoader: false,
  selectedContactForCampaign: [],

  // Role handling
  roles: {
    isSuperAdmin: false,
    isBusinessAdmin: false,
    isEmployee: false,
    isAdmin: false,
    isManager: false,
  },

  // routing state
  routes: {
    routesContainer: [],
    setupTabContainer: [],
    accountTabContainer: [],
    routeRedirectContainer: [],
    navListContainer: [],
  },
  // Theme
  themeContainer: { ...lightTheme, themeType: 'light' },
  // Thank you Popup Theme
  thankyouPopUpBR: '10px !important',
};

export default globalState;
