const { useState } = require('react');

function useModal() {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);

  const handleHideShowModal = () => setShowModal(false);

  return {
    showModal,
    handleShowModal,
    handleHideShowModal,
  };
}
export default useModal;
