import React, { useEffect, useContext, useState } from 'react';
import { Grid, Box, ThemeProvider as MUIThemeProvider } from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
// import Dropdown from '../../components/Dropdown/Dropdown';
import Loader from '../../components/Loader/Loader';
// import instagram from '../../assets/images/Instagram_logo.svg';
// import linkDin from '../../assets/images/linkDin.svg';
import Twitter from '../../assets/images/tiwtter.png';
import facebook from '../../assets/images/rsz_facebook.png';
import CommanDropDown from './FirstDropDown';
import CustomButton from '../../components/CustomButton/CustomButton';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import baseUrl from '../../utils/services/config';
import ButtonLoader from '../../components/ButtonLoader/ButtonLoader';
import CusotmAlert from '../../components/CustomAlert/CustomAlert';
import NoDataFound from '../../components/NoDataFound/NoDataFound';
import MainViewActionButtonTheme from '../../themes/Button/MainViewActionButtonTheme';
import AntSwitch from '../../components/StyledAntSwitch/StyledAntSwitch';
import FieldGroupLable from '../../components/FieldGroupLable/FieldGroupLable';
import useButtonTheme from '../../hooks/useButtonTheme';
import useAntSwitchTheme from '../../hooks/useAntSwitchTheme';

const HeaderLabel = styled(Grid)`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0 !important;
  position: relative;
`;

const defaultValues = {
  twitter: {
    enable: false,
  },
  facebook: {
    enable: false,
  },
  linkDin: {
    enable: false,
  },
  instagram: {
    enable: false,
  },
  widget: {
    enable: false,
  },
};
// const reviewAttributionPeriodList = [
//   { value: '10 days', label: '10 days ' },
//   { value: '15 days', label: '15 days ' },
//   { value: '20 days', label: '20 days ' },
//   { value: '30 days', label: '30 days ' },
// ];

// const reviewRequestLimit = [
//   { value: '60 days', label: '60 days' },
//   { value: '90 days', label: '90 days' },
//   { value: '180 days', label: '180 days' },
//   { value: '365 days', label: '365 days' },
// ];

const starList = [
  { value: 1, label: '1 Stars' },
  { value: 2, label: '2 Stars' },
  { value: 3, label: '3 Stars ' },
  { value: 4, label: '4 Stars' },
  { value: 5, label: '5 Stars' },
];

const firstList = [
  { label: 'Minimum Rating', value: '>' },
  // { label: 'Recommended', value: 'recommended' },
];
const lastList = [
  { label: 'With content', value: 'feedback' },
  { label: 'All Reviews', value: 'all' },
];

const widgetList = [{ label: 'Minimum Rating', value: '>' }];

const widgetList2 = [
  { label: 'All Reviews', value: 'all' },
  { label: 'With content', value: 'feedback' },
];

function SettingContainer() {
  const { state } = useContext(GlobalContext);
  const { selectedBusiness } = state;
  const [loader, setLoader] = useState(false);
  const [renderView, setRenderView] = useState(false);
  const [dataIsSet, setDataIsSet] = useState(false);
  const [showAlert, setAlert] = useState([
    {
      show: false,
      messageType: '',
      message: '',
      postionVertical: 'top',
      positionHorizontal: 'center',
    },
  ]);
  const { register, control, handleSubmit, setValue, watch } = useForm({
    defaultValues,
  });
  const { buttonTheme } = useButtonTheme();
  const { antSwitchTheme } = useAntSwitchTheme();

  useEffect(() => {
    if (selectedBusiness) {
      setRenderView(false);
      baseUrl
        .get(`/business/${selectedBusiness}`)
        .then((res) => {
          setRenderView(true);
          setDataIsSet(true);
          if (res.data.settings) {
            setValue('settings', res.data.settings);
            setValue('facebook', res.data.settings.facebook);
            setValue('twitter', res.data.settings.twitter);
            setValue('linkDin', res.data.settings.linkedIn);
            setValue('instagram', res.data.settings.instagram);
            setValue('widget', res.data.settings.widget);
          }
        })
        .catch(() => {
          setRenderView(true);
        });
    } else {
      setRenderView(true);
      setAlert({
        show: true,
        message: 'Please Create a business',
        messageType: 'error',
        postionVertical: 'bottom',
        positionHorizontal: 'center',
      });
      setDataIsSet(false);
    }
  }, [selectedBusiness, setValue]);

  const onSubmit = (data) => {
    const body = {
      facebook: {
        ...data.facebook,
        updatedAt: new Date().getTime(),
      },
      twitter: {
        ...data.twitter,
        updatedAt: new Date().getTime(),
      },
      widget: data.widget,
      // instagram: data.instagram,
      // linkedIn: data.linkDin,
      // reviewAttributionPeriod: data.settings.reviewAttributionPeriod,
      // reviewRequestLimit: data.settings.reviewRequestLimit,
    };
    setLoader(true);
    baseUrl
      .patch(`/business/${selectedBusiness}`, { settings: body })
      .then((res) => {
        if (res.status === 200) {
          setAlert({
            show: true,
            message: 'Setting Updated In Business Successfully',
            messageType: 'success',
          });
          setLoader(false);
        }
      })
      .catch(() => {
        setAlert({
          show: true,
          message: 'Error',
          messageType: 'error',
        });
        setLoader(false);
      });
  };

  const handleCloseAlert = () => {
    setAlert({
      show: false,
    });
  };
  const showLoader = loader ? <ButtonLoader /> : null;

  const watchTwitter = watch('twitter.enable');
  const watchFacebook = watch('facebook.enable');
  const watchWidgets = watch('widget.enable');
  watch('facebook');
  watch('twitter');
  watch('widget');
  const disabledTwitterFields = !(watchTwitter && watchTwitter !== undefined);
  const disabledFacebookFields = !(watchFacebook && watchFacebook !== undefined);
  const disabledWidgetFields = !(watchWidgets && watchWidgets !== undefined);
  const settingForm = dataIsSet ? (
    <Grid item xs={12} sm={10} md={8} lg={6}>
      {/* <SectionHedaer>General</SectionHedaer> */}

      {/* <Grid item xs={12} sm={6} md={6}>
        <ThemeProvider theme={defaultDropDownTheme}>
          <StyledFormControl
            error={
              handleLocationViewErrors(errors, 'settings')
                ? errors.settings.reviewAttributionPeriod
                : false
            }
          >
            <Controller
              as={
                <Dropdown
                  listOptions={reviewAttributionPeriodList}
                  label="Select review Attribution Period"
                />
              }
              name="settings.reviewAttributionPeriod"
              control={control}
              rules={{ required: true }}
              defaultValue=""
            />
            {handleLocationViewErrors(errors, 'settings') &&
            errors.settings.reviewAttributionPeriod ? (
              <ErrorTxt> review attribution period required field</ErrorTxt>
            ) : (
              ''
            )}
          </StyledFormControl>
        </ThemeProvider>
      </Grid> */}

      {/* <Grid item xs={12} sm={6} md={6}>
        <ThemeProvider theme={defaultDropDownTheme}>
          <StyledFormControl
            error={
              handleLocationViewErrors(errors, 'settings')
                ? errors.settings.reviewRequestLimit
                : false
            }
          >
            <Controller
              as={<Dropdown listOptions={reviewRequestLimit} label="Select review Request Limit" />}
              name="settings.reviewRequestLimit"
              control={control}
              rules={{ required: true }}
              defaultValue=""
            />
            {handleLocationViewErrors(errors, 'settings') && errors.settings.reviewRequestLimit ? (
              <ErrorTxt> review request limit required field</ErrorTxt>
            ) : (
              ''
            )}
          </StyledFormControl>
        </ThemeProvider>
      </Grid> */}

      {/* <Grid item xs={12} sm={6} md={6}>
        <ThemeProvider theme={defaultDropDownTheme}>
          <StyledFormControl>
            <Controller
              as={<Dropdown listOptions={[]} label="Select MMS Number Pool" />}
              name="settings.mmsNumberPool"
              control={control}
              // rules={{ required: true }}
              defaultValue=""
            /> */}
      {/* {handleLocationViewErrors(errors, 'settings') && errors.settings.mmsNumberPool ? (
              <ErrorTxt> MMS Number Pool required field</ErrorTxt>
            ) : (
              ''
            )} */}
      {/* </StyledFormControl>
        </ThemeProvider>
      </Grid> */}
      <Box marginTop={'2.5rem'}>
        <Grid container item xs={12} sm={12} md={12} spacing={3}>
          {/* <FieldGroupLable>Auto review posting</FieldGroupLable> */}
          <HeaderLabel item xs={12} sm={12} md={12}>
            <div>
              <img
                src={facebook}
                alt=""
                style={{ width: '5em', position: 'absolute', bottom: '30%' }}
              />
            </div>
            <div>
              <MUIThemeProvider theme={antSwitchTheme}>
                <Controller
                  as={<AntSwitch />}
                  name="facebook.enable"
                  control={control}
                  inputRef={register}
                />
              </MUIThemeProvider>
            </div>
          </HeaderLabel>
          <Grid item xs={12} sm={4} md={4} style={{ paddingTop: '0' }}>
            <CommanDropDown
              name="facebook.reviewPosting"
              control={control}
              list={firstList}
              disabled={disabledFacebookFields}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} style={{ paddingTop: '0' }}>
            <CommanDropDown
              name="facebook.rating"
              control={control}
              list={starList}
              disabled={disabledFacebookFields}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} style={{ paddingTop: '0' }}>
            <CommanDropDown
              name="facebook.content"
              control={control}
              list={lastList}
              disabled={disabledFacebookFields}
            />
          </Grid>

          <HeaderLabel item xs={12} sm={12} md={12}>
            <div>
              <img src={Twitter} alt="" style={{ width: '5em', position: 'absolute' }} />
            </div>
            <div>
              <MUIThemeProvider theme={antSwitchTheme}>
                <Controller
                  as={<AntSwitch />}
                  name="twitter.enable"
                  control={control}
                  inputRef={register}
                />
              </MUIThemeProvider>
            </div>
          </HeaderLabel>

          <Grid item xs={12} sm={4} md={4}>
            <CommanDropDown
              name="twitter.reviewPosting"
              control={control}
              list={firstList}
              disabled={disabledTwitterFields}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <CommanDropDown
              name="twitter.rating"
              control={control}
              list={starList}
              disabled={disabledTwitterFields}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <CommanDropDown
              name="twitter.content"
              control={control}
              list={lastList}
              disabled={disabledTwitterFields}
            />
          </Grid>

          <HeaderLabel item xs={12} sm={12} md={12}>
            <Grid container alignItems="center" justify="space-between">
              <Grid item>
                <FieldGroupLable item>Widget Reviews Listing</FieldGroupLable>
              </Grid>
              <Grid item>
                <MUIThemeProvider theme={antSwitchTheme}>
                  <Controller
                    as={<AntSwitch />}
                    name="widget.enable"
                    control={control}
                    inputRef={register}
                  />
                </MUIThemeProvider>
              </Grid>
            </Grid>
          </HeaderLabel>
          <Grid item xs={12} sm={4} md={4}>
            <CommanDropDown
              name="widget.reviewPosting"
              control={control}
              list={widgetList}
              disabled={disabledWidgetFields}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <CommanDropDown
              name="widget.rating"
              control={control}
              list={starList}
              disabled={disabledWidgetFields}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <CommanDropDown
              name="widget.content"
              control={control}
              list={widgetList2}
              disabled={disabledWidgetFields}
            />
          </Grid>
          {/* <HeaderLabel item xs={12} sm={12} md={12}>
          <div>
            {' '}
            <img
              src={linkDin}
              alt=""
              style={{ width: '5em', position: 'absolute', bottom: '17%' }}
            />
          </div>
          <div>
            <Controller
              as={<AntSwitch />}
              name="linkDin.enable"
              control={control}
              inputRef={register}
            />
          </div>
        </HeaderLabel> */}

          {/* <Grid item xs={12} sm={4} md={4}>
          <CommanDropDown name="linkDin.reviewPosting" control={control} list={firstList} />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <CommanDropDown name="linkDin.rating" control={control} list={starList} />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <CommanDropDown name="linkDin.content" control={control} list={lastList} />
        </Grid> */}
          {/* <HeaderLabel item xs={12} sm={12} md={12}>
          <div>
            <img
              src={instagram}
              alt=""
              style={{ width: '5em', position: 'absolute', bottom: '17%' }}
            />
          </div>
          <div>
            <Controller
              as={<AntSwitch />}
              name="instagram.enable"
              control={control}
              inputRef={register}
            />
          </div>
        </HeaderLabel> */}
          {/* <Grid item xs={12} sm={4} md={4}>
          <CommanDropDown name="instagram.reviewPosting" control={control} list={firstList} />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <CommanDropDown name="instagram.rating" control={control} list={starList} />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <CommanDropDown name="instagram.content" control={control} list={lastList} />
        </Grid> */}
        </Grid>
      </Box>
    </Grid>
  ) : (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <NoDataFound>No data found !</NoDataFound>
    </Grid>
  );
  return renderView ? (
    <>
      <CusotmAlert
        open={showAlert.show}
        message={showAlert.message}
        messageType={showAlert.messageType}
        handleClose={handleCloseAlert}
        postionVertical={showAlert.postionVertical}
        positionHorizontal={showAlert.positionHorizontal}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container xs={12} sm={12} md={12} direction="row" justify="space-around">
          {settingForm}
          {dataIsSet ? (
            <Grid item xs={12} sm={2} md={4} lg={6}>
              <Grid
                container
                item
                xs={12}
                md={12}
                spacing={3}
                justify="flex-end"
                alignItems="flex-end"
              >
                <Grid item xs={12} sm={12} md={3}>
                  <ThemeProvider
                    theme={{
                      ...MainViewActionButtonTheme,
                      background: buttonTheme.buttonBgColor,
                      color: buttonTheme.buttonColor1,
                    }}
                  >
                    <CustomButton type="submit" disabled={loader}>
                      Save
                      {showLoader}
                    </CustomButton>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      </form>
    </>
  ) : (
    <Loader />
  );
}

SettingContainer.propTypes = {};

export default SettingContainer;
