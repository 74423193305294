import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { getSource, formatDate, getReviewLink, wordToStars } from '../ReviewsWidget.logic.js';

const BubbleReview = (props) => {
  const { review, settings, postSites } = props;

  const [isCollapsed, setIsCollapsed] = useState(true);

  // general colors and opacities
  let textColor = settings && settings.isLight ? '#000000' : '#ffffff';
  let secondaryTextColor = settings && settings.isLight ? textColor + '91' : textColor + '91';
  let backgroundColor = settings && settings.isLight ? '#ffffff' : '#000000';
  let userImageColor = secondaryTextColor;

  const useStyles = makeStyles(() => ({
    Review__Container: {
      backgroundColor: `${settings.colors.containerBgColor}${settings.colors.containerBgOpacity}`,
      position: 'relative',
      padding: '24px',
      transition: 'background-color 0.1s ease 0s',
      borderRadius: settings.radius,
      display: 'flex',
      flexDirection: 'column',
    },

    Review__Bubble: {
      marginBottom: `${settings.widgetStyle === 'grid' ? '' : settings.gap + 'px'}`,
    },
    Review__Container_Bubble: {
      backgroundColor: `${settings.colors.containerBgColor}${settings.colors.containerBgOpacity}`,
      position: 'relative',
      padding: '24px',
      transition: 'background-color 0.1s ease 0s',
      borderRadius: `${settings.radius}px`,
      display: 'flex',
      flexDirection: 'column',
    },
    Blurb__Triangle: {
      transform: 'translate(44px)',
      width: '30px',
    },
    'Blurb__Triangle svg': {
      width: '22px',
      height: '15px',
    },
    Bubble__Author__Info: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textAlign: 'left',
      marginLeft: '1rem',
    },

    centered: {
      textAlign: 'center !important',
      flexDirection: 'column !important',
      margin: 0,
    },

    Review__Rating_Container: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: `${settings.reviewStyle === 'center' ? 'column;' : null}`,
      textAlign: `${settings.reviewStyle === 'center' ? 'center;' : null}`,
      gap: `${settings.reviewStyle === 'center' ? '.5rem;' : null}`,
    },

    Review__RatingBlock: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: `${settings.reviewStyle === 'center' ? 'column;' : null}`,
    },
    Rating__Stars: {
      display: 'flex',
      gap: '.15rem',
    },
    Review__AuthorRating: {
      marginLeft: `${settings.reviewStyle === 'center' ? '0' : '.8rem'}`,
    },
    Review__ReviewDate: {
      overflow: 'hidden',
      minWidth: '0px',
      fontWeight: '400',
      fontSize: '12px',
      whiteSpace: 'nowrap',
      marginTop: '.25rem',
      textOverflow: 'ellipsis',
      color: `${secondaryTextColor}`,
      marginLeft: '.2rem',
    },
    Review__AuthorName: {
      fontSize: '14px',
      fontWeight: '700',
      maxWidth: '21ch',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: `${textColor}`,
      marginBottom: `${settings.reviewStyle === 'center' ? '.5rem,' : '.2rem'}`,
    },
    Review__UserImage: {
      width: '44px',
      height: '44px',
      borderRadius: '50%',
      '&svg': {
        width: '44px',
        height: '44px',
      },
    },
    Review__ReviewSource: {
      display: 'flex',
      marginTop: '1rem',
      gap: '.5rem',
      alignItems: 'center',
      justifyContent: `${settings.reviewStyle === 'center' ? 'center' : null}`,
    },
    Review__ReviewSourceImage: {
      width: '24px',
      BorderRadius: '50%',
    },
    postedOn__Image: {
      width: '35px',
      borderRadius: '50%',
    },
    Review__Outer: {
      overflow: 'hidden',
      position: 'relative',
    },
    Review__Inner: {
      marginTop: '.5rem',
      width: '100%',
      lineHeight: '1.4',
      color: 'rgb(17, 17, 17)',
      fontSize: '14px',
      textAlign: 'left',
      color: textColor,
      textAlign: `${settings.reviewStyle === 'center' ? 'center' : null}`,
    },
    ReadMore__Control: {
      display: 'inline-block',
      cursor: 'pointer',
      fontSize: '14px',
      color: `${secondaryTextColor}`,
      textDecoration: 'none',
      textAlign: `${settings.reviewStyle === 'center' ? 'center' : null}`,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    collapsed: {
      maxHeight: '51px',
      minHeight: '51px',
    },
    a: {
      textDecoration: 'none',
    },
    PostedOn__Title: {
      overflow: 'hidden',
      minWidth: '0px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '1.17',
      color: secondaryTextColor,
      textDecoration: 'none',
      display: 'flex',
      flexDirection: 'column',
    },
    PostedOn__Link: {
      color: textColor,
      overflow: 'hidden',
      minWidth: '0px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: '14px',
      lineHeight: '1.38',
      textDecoration: 'none',
    },
  }));

  const classes = useStyles();

  const userImage = () => {
    if (review.profilePic) {
      return (
        <img
          src={review.profilePic}
          alt="user image"
          referrerPolicy="no-referrer"
          className={classes.Review__UserImage}
        ></img>
      );
    } else {
      return (
        <svg width="44px" height="44px" viewBox="0 0 44 44">
          <path
            fill={userImageColor}
            d="M21.988,0c12.142,-0.007 22.005,9.846 22.012,21.988c0.007,12.142 -9.846,22.005 -21.988,22.012c-12.142,0.007 -22.005,-9.846 -22.012,-21.988c-0.007,-12.142 9.846,-22.005 21.988,-22.012Zm15.913,35.062c2.926,-3.558 4.682,-8.112 4.68,-13.073c-0.006,-11.359 -9.234,-20.576 -20.592,-20.57c-11.359,0.006 -20.576,9.234 -20.57,20.592c0.003,4.963 1.765,9.516 4.697,13.071c0.375,-4.704 5.152,-8.514 15.718,-8.673c10.343,-0.199 15.837,3.702 16.067,8.653Zm-16.067,-28.629c4.222,-0.076 7.723,3.956 7.815,8.999c0.091,5.044 -3.262,9.2 -7.484,9.277c-4.221,0.076 -7.722,-3.956 -7.814,-9c-0.091,-5.043 3.262,-9.2 7.483,-9.276Z"
          />
        </svg>
      );
    }
  };

  return (
    <>
      <div className={classes.Review__Container_Bubble}>
        <div
          className={classes.Rating__Stars}
          dangerouslySetInnerHTML={{
            __html: wordToStars(review.rating, settings, textColor, backgroundColor),
          }}
        ></div>
        <div
          className={`${classes.Review__Outer} ${
            isCollapsed && !settings.isTextExpanded ? classes.collapsed : null
          }`}
        >
          <div className={classes.Review__Inner}>
            <div>{review.reviewText}</div>
          </div>
        </div>
        {!settings.isTextExpanded && (
          <div
            className={classes.ReadMore__Control}
            style={{ marginTop: !isCollapsed ? '5px' : '' }}
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? 'Read More' : 'Hide'}
          </div>
        )}
        <a
          href={getReviewLink(review.source, postSites)}
          target="_blank"
          className={classes.Review__ReviewSource}
        >
          <img
            src={getSource(review.source).icon}
            alt="review source image"
            className={classes.postedOn__Image}
          ></img>
          <div className={classes.PostedOn__Title}>
            Posted on
            <span className={classes.PostedOn__Link}>{getSource(review.source).title}</span>
          </div>
        </a>
      </div>
      <div className={classes.Blurb__Triangle}>
        <svg width="100%" height="100%" viewBox="0 0 22 13" version="1.1">
          <path
            fill={settings.colors.containerBgColor + settings.colors.containerBgOpacity}
            d="M0,0L22,0L3.074,12.044C2.753,12.248 2.38,12.357 2,12.357C0.903,12.357 0,11.454 0,10.357L0,0Z"
          ></path>
        </svg>
      </div>
      <div className={classes.Bubble__Author__Info}>
        <div className={classes.Review__UserImage}>{userImage()}</div>
        <div className={classes.Review__AuthorRating}>
          <div className={classes.Review__AuthorName}>{review.reviewBy}</div>
          <div className={classes.Review__ReviewDate}>{formatDate(review.reviewOn)}</div>
        </div>
      </div>
    </>
  );
};

export default BubbleReview;
