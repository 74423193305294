import React from 'react';
import PostImage from '../PostImage';
import { Skeleton } from '@material-ui/lab';
import { Box, makeStyles, Paper, Avatar } from '@material-ui/core';
import styled from 'styled-components';
import Like from './like.svg';
import Comment from './comment.svg';
import Share from './share.svg';

const ReviewContent = styled.div`
  padding: 1rem;
  font-size: 13px;
`;

const Hashtags = styled.div`
  padding: 0 1.5rem 1rem 1rem;
  color: #0064d1;
  font-size: 13px;
`;

const ReviewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 0 1rem;
  font-size: 13px;
`;

const CommentBox = styled.div`
  background-color: #eeeeee;
  border-radius: 30px;
  color: #939393;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  flex: 1;
  font-size: 13px;
`;

const StyledAvatar = styled(Avatar)`
  width: 35px !important;
  height: 35px !important;
`;

export const FacebookPost = (props) => {
  const { locationInfo, bodyRequest, setBodyRequest, showGallery, postPreview, selectedColor } =
    props;

  const businessLogo =
    locationInfo && locationInfo?.uploadedFile?.filter((image) => image.meta === 'business_logo');

  const businessName = locationInfo?.business[0].nickName ?? '';

  const isPreview = bodyRequest.isPreview;

  const useStyles = makeStyles(() => ({
    reveoLogo: {
      position: 'absolute',
      width: '100px',
      bottom: '20px',
      right: '20px',
      opacity: 0.5,
    },
    googleLogo: {
      height: '60px',
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      top: '35px',
    },
    companyInfo: {
      display: 'flex',
      columnGap: '.5rem',
      alignItems: 'center',
    },
    postTimeCompany: {
      display: 'flex',
      flexDirection: 'column',
    },
    companyLogo: {
      width: '32px',
      height: '32px',
      borderRadius: '20px',
      backgroundColor: '#adadad',
    },
    businessName: {
      fontWeight: 700,
    },
    postTime: {
      color: '#adadad',
      fontSize: '.7rem',
    },
    actionButton: {
      fontWeight: 500,
      fontSize: '14px',
      color: '#8a8a8a',
      marginLeft: '.4rem',
    },
    styledHr: {
      border: 'none',
      backgroundColor: '#e3e3e3',
      margin: '0 1rem',
      height: '1px',
    },
  }));

  const paperStyles = !postPreview
    ? {
        transform: 'scale(.8)',
        transformOrigin: 'top left',
        backgroundColor: 'white',
      }
    : { backgroundColor: 'white' };

  const classes = useStyles();
  return isPreview ? (
    <Paper elevation={3} style={paperStyles}>
      <ReviewHeader>
        <div className={classes.companyInfo}>
          <img
            src={
              businessLogo.length
                ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${businessLogo[0].url}`
                : ''
            }
            className={classes.companyLogo}
            alt="business-logo"
          />
          <div className={classes.postTimeCompany}>
            <div className={classes.businessName}>{businessName}</div>
            <div className={classes.postTime}>Just Now</div>
          </div>
        </div>
      </ReviewHeader>
      <ReviewContent>
        {bodyRequest.review ? (
          bodyRequest.review.comment
        ) : (
          <>
            <Skeleton animation={false} />
            <Skeleton animation={false} width={'50%'} />
          </>
        )}
      </ReviewContent>
      <Hashtags>
        {bodyRequest.autoHashTags && bodyRequest.hashtags === null
          ? `#SampleHashtag #SampleHashtag #SampleHashtag`
          : bodyRequest.hashtags}
      </Hashtags>
      {bodyRequest.previewImage ? (
        <img
          src={bodyRequest.previewImage}
          style={{ width: '100%', minWidth: '100%', minHeight: '280px' }}
        />
      ) : (
        <PostImage bodyRequest={bodyRequest} setBodyRequest={setBodyRequest} postPreview />
      )}
      <Box
        display="flex"
        justifyContent={'center'}
        padding={'.7rem 0'}
        style={{ columnGap: '5rem' }}
      >
        <Box display="flex" alignItems={'center'}>
          <img width={18} src={Like} />
          <span className={classes.actionButton}>Like</span>
        </Box>
        <Box display="flex" alignItems={'center'}>
          <img width={18} src={Comment} />
          <span className={classes.actionButton}>Comment</span>
        </Box>
        <Box display="flex" alignItems={'center'}>
          <img width={18} src={Share} />
          <span className={classes.actionButton}>Share</span>
        </Box>
      </Box>
      <hr className={classes.styledHr} />
      <Box padding={'1rem'} display="flex" style={{ columnGap: '.5rem' }}>
        <StyledAvatar />
        <CommentBox>Comment as John Doe</CommentBox>
      </Box>
    </Paper>
  ) : (
    <PostImage
      bodyRequest={bodyRequest}
      setBodyRequest={setBodyRequest}
      showGallery={showGallery}
      selectedColor={selectedColor}
      style={{ cursor: 'pointer' }}
    />
  );
};
export default FacebookPost;
