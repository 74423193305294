import { useState, useContext } from 'react';
import baseUrl from '../utils/services/config';
import { convertIntoCampaignCards } from '../utils/helper/helper';
import { errorChecker } from '../utils/helper/helper';
import { CampaignContext } from '../Stores/CamapignStore';
import { GlobalContext } from '../Stores/GlobalStateStore';

const useCreateMessage = () => {
  const [isCreateMessageLoading, setIsCreateMessageLoading] = useState(false);
  const { campaignDispatch } = useContext(CampaignContext);
  const { dispatch } = useContext(GlobalContext);

  const createMessage = async (campaign, shouldSaveCampaign, handleClose) => {
    setIsCreateMessageLoading(true);

    let uploadedFile;
    if (campaign.uploadedFile && Array.isArray(campaign.uploadedFile)) {
      uploadedFile = campaign.uploadedFile.map((item) => item.id);
    }

    try {
      const postBody = {
        // eslint-disable-next-line radix
        user: parseInt(localStorage.getItem('USER_ID')),
        // eslint-disable-next-line radix
        location: parseInt(localStorage.getItem('SELECTED_LOCATION')),
        name: campaign.name,
        emailTitle: '',
        reviewPortal: [],
        campaignType: 'message',
        isActive: true,
        isDeleted: false,
        message: campaign.message,
        canResendAfter: 30,
        subType: 'simple',
        settings: {},
      };

      if (uploadedFile && uploadedFile.length) {
        postBody.uploadedFile = uploadedFile;
      }

      const result = await baseUrl.post('/campaign', { ...postBody });
      if (result.status === 200) {
        // if ((shouldSaveCampaign)) {
        //   campaignDispatch({
        //     type: 'UPDATE_CAMPAIGN_CONTAINER',
        //     payload: Array.from(convertIntoCampaignCards([postBody])),
        //   });
        // }
        handleClose();
        return result.data;
      }
      return;
    } catch (err) {
      setIsCreateMessageLoading(false);
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  return { createMessage, isCreateMessageLoading };
};

export default useCreateMessage;
