import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Checkbox, makeStyles, Box } from '@material-ui/core';
import RadioButtonUncheckedIcon from 'mdi-react/RadioButtonUncheckedIcon';
import RadioButtonCheckedIcon from 'mdi-react/RadioButtonCheckedIcon';
import { GlobalContext } from '../../../../../../../../Stores/GlobalStateStore';
import { errorChecker } from '../../../../../../../../utils/helper/helper';
import baseUrl from '../../../../../../../../utils/services/config';
import SingleChoice from '../../../../../../../SingleChoice/SingleChoice';
import { StarRating } from '../../../../../../../StarRating/StarRating';
import { NoData } from '../../../../../../../NoData/NoData';
import SurveyIcon from 'mdi-react/FormatListChecksIcon';

const StyledTextQuestion = styled.span`
  font-size: 14px;
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
`;

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#208c91',
    '&$checked': {
      color: '#208c91',
    },
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
  },
  checked: {},
}));

const tabContainerStyles = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: '1.5rem',
  height: 'calc(55vh - 5rem)',
};

const fakeSurvey = [
  {
    id: 1,
    createdAt: 1667534185336,
    updatedAt: 1667534185336,
    isDeleted: false,
    name: 'Move-In Survey',
    displayName: 'Move-In Survey',
    questions: {
      name: 'New Move-in Survey',
      questions: [
        {
          text: 'Did the office staff ask for your current contact information (cell phone, email, etc.)?',
          type: 'yesNo',
          code: 'movein1',
          category: 'none',
        },
        {
          text: 'Were your keys available when you arrived to move-in?',
          type: 'yesNo',
          code: 'movein2',
          category: 'none',
        },
        {
          text: 'Was a copy of your lease provided prior to/at move in?',
          type: 'yesNo',
          code: 'movein3',
          category: 'none',
        },
        {
          text: 'Was your home clean when you moved in?',
          type: 'yesNo',
          code: 'movein4',
          category: 'preparation',
          hasChildren: 2,
        },
        {
          text: 'Have you contacted the office about the cleaning issues you discovered?',
          type: 'yesNo',
          code: 'movein5',
          category: 'none',
        },
        {
          text: 'Was it cleaned for you at a later date?',
          type: 'yesNo',
          code: 'movein6',
          category: 'recovery',
        },
        {
          text: 'Were your appliances and fixtures working properly?',
          type: 'yesNo',
          code: 'movein7',
          category: 'preparation',
          hasChildren: 2,
        },
        {
          text: 'Have you contacted the office about any appliance or fixture issues you discovered?',
          type: 'yesNo',
          code: 'movein8',
          category: 'none',
        },
        {
          text: 'Do any problems still exist?',
          type: 'yesNo',
          code: 'movein9',
          category: 'recovery',
        },
        {
          text: 'Office staff’s performance during the move-in process',
          type: 'starRating',
          code: 'movein10',
          category: 'performance',
        },
        {
          text: 'Overall condition, appearance of your apartment home',
          type: 'starRating',
          code: 'movein11',
          category: 'preparation',
        },
        {
          text: 'Overall move in process',
          type: 'starRating',
          code: 'movein12',
          category: 'preparation',
        },
        {
          text: 'Why did you choose to rent there?',
          type: 'longAnswer',
          code: 'movein13',
          category: 'none',
        },
        {
          text: 'What sources of information did you use to find out about where you rented?',
          type: 'multipleChoice',
          code: 'movein14',
          category: 'none',
          choices: ['phone', 'internet'],
        },
        {
          text: 'Would you like a member of your Management team to follow up with you regarding any issues you might be experiencing?',
          type: 'yesNo',
          code: 'movein15',
          category: 'none',
        },
        {
          text: 'If the apartment community staff needs to contact you, what is your preferred method (s) of contact?',
          type: 'multipleChoice',
          code: 'movein16',
          choices: ['Email', 'Phone', 'Text Message'],
          category: 'none',
        },
        {
          text: 'Is it okay for management to include your name and comments for use as marketing testimonials (for example, if the community wants to include the testimonial on a website, etc.)?',
          type: 'yesNo',
          code: 'movein17',
          category: 'engagement',
        },
      ],
    },
    screens: {
      screens: [
        {
          screen: 'sms',
          title:
            'Hi @customerName, welcome to @business. Please help us know how we are doing by filling out this brief survey. Thanks! @link',
          url: '',
          uploadedFile: null,
          emailTitle: 'Thank you for your business',
        },
        {
          screen: 'question',
          questions: [],
        },
        {
          screen: 'thankyou',
          title: 'Thank you for your time, we look forward to serving you in the future',
          url: '@businessLogo',
          uploadedFile: null,
        },
      ],
    },
    business: {
      id: 48,
      createdAt: 1665253579534,
      updatedAt: 1667534561795,
      isDeleted: false,
      name: 'Aldon Management',
      uuid: '8d0a18f2-4a11-43b8-8acf-b36edee99642',
      nickName: 'Aldon Management',
      aboutUs: '',
      email: 'lgutierrez@aldonishome.com',
      contact: {
        businessContact: '+13016565998',
        zip: '20814',
        phoneNumber: '+12403802561',
        addressLine1: '8180 Wisconsin Ave',
        addressLine2: '',
        webSite: 'https://aldonishome.com',
        city: 'Bethesda',
        state: 'MD',
        timeZone: '1',
      },
      noEmployees: 100,
      noLocations: 10,
      mmsNumber: '+13852472073',
      createdBy: 1,
      settings: {
        importMap: {
          firstName: 'FirstName',
          lastName: 'LastName',
          phone: 'Phone',
          emailAddress: 'Email',
          externalId: 'ResidentHouseHoldID',
          locationName: 'PropertyName',
          locationId: {
            PropertyKey: {
              1: 88,
              2: 89,
              8: 82,
              9: 83,
              10: 84,
              12: 86,
              14: 90,
              15: 85,
            },
          },
        },
        facebook: {
          enable: false,
          reviewPosting: '>',
          rating: 4,
          content: 'feedback',
          updatedAt: 1667534561760,
        },
        twitter: {
          enable: false,
          reviewPosting: '>',
          rating: 4,
          content: 'feedback',
          updatedAt: 1667534561760,
        },
        widget: {
          enable: false,
          reviewPosting: '>',
          rating: 4,
          content: 'feedback',
        },
      },
      isChatbotEnabled: {
        type: 'Buffer',
        data: [0],
      },
      webChats: [
        {
          website: 'aldonishome.com',
          isEnabled: true,
          isLiveChatEnabled: true,
          brandColor: '#50E3C2',
          greeting: 'Hi there, have a question for me, or not?',
          offlineAutoResponder: 'Thanks for texting us. We will text you back soon.',
          liveChatAutoResponder: 'Thanks for texting us. We will be with you shortly, hopefully.',
          textColor: '#FFFFFF',
          pagePosition: 'right',
          avatarImage: 'https://reveo.com/images/operators.png',
        },
      ],
      tags: null,
    },
  },
  {
    id: 2,
    createdAt: 1667534185336,
    updatedAt: 1667534185336,
    isDeleted: false,
    name: 'Move-Out Survey',
    displayName: 'Move-Out Survey',
    questions: {
      name: 'Move-Out Survey',
      questions: [
        {
          text: 'Why did you decide to move?',
          type: 'multipleChoice',
          code: 'moveOut1',
          category: 'none',
        },
        {
          text: 'Appearance, condition of your apartment home',
          type: 'starRating',
          code: 'moveOut2',
          category: 'none',
        },
        {
          text: 'Appearance, condition of the community, overall',
          type: 'starRating',
          code: 'moveOut3',
          category: 'none',
        },
        {
          text: 'Office staff',
          type: 'starRating',
          code: 'moveOut4',
          category: 'preparation',
          hasChildren: 2,
        },
        {
          text: 'Maintenance staff',
          type: 'starRating',
          code: 'moveOut5',
          category: 'none',
        },
        {
          text: 'Level of service you have received',
          type: 'starRating',
          code: 'moveOut6',
          category: 'none',
        },
        {
          text: '“Sense of community” felt among residents and the staff',
          type: 'starRating',
          code: 'moveOut7',
          category: 'none',
        },
        {
          text: 'Community sponsored social activities, events',
          type: 'starRating',
          code: 'moveOut8',
          category: 'none',
        },
        {
          text: 'Community social media (Facebook page, etc.)',
          type: 'starRating',
          code: 'moveOut9',
          category: 'none',
        },
        {
          text: 'Neighbors',
          type: 'starRating',
          code: 'moveOut10',
          category: 'none',
        },
        {
          text: 'Value for the rent you pay',
          type: 'starRating',
          code: 'moveOut11',
          category: 'none',
        },
        {
          text: 'Your overall experience as a resident at this community',
          type: 'starRating',
          code: 'moveOut12',
          category: 'none',
        },
        {
          text: 'Would you recommend your community to a friend?',
          type: 'starRating',
          code: 'moveOut13',
          category: 'none',
        },
        {
          text: 'Are you moving to another community in the area?',
          type: 'yesNo',
          code: 'moveOut14',
          category: 'none',
        },
        {
          text: 'What are your preferred contact method(s)?',
          type: 'multipleChoice',
          code: 'moveOut15',
          category: 'none',
          choices: ['Email', 'Phone', 'Text Message'],
        },
        {
          text: 'Is it okay for management to include your name and comments for use as marketing testimonials (for example, if the community wants to include the testimonial on a website, etc.)?',
          type: 'yesNo',
          code: 'moveOut16',
          category: 'Overall Engagement',
        },
      ],
    },
    screens: {
      screens: [
        {
          screen: 'sms',
          title:
            'Hi @customerName, welcome to @business. Please help us know how we are doing by filling out this brief survey. Thanks! @link',
          url: '',
          uploadedFile: null,
          emailTitle: 'Thank you for your business',
        },
        {
          screen: 'question',
          questions: [],
        },
        {
          screen: 'thankyou',
          title: 'Thank you for your time, we look forward to serving you in the future',
          url: '@businessLogo',
          uploadedFile: null,
        },
      ],
    },
    business: {
      id: 48,
      createdAt: 1665253579534,
      updatedAt: 1667534561795,
      isDeleted: false,
      name: 'Aldon Management',
      uuid: '8d0a18f2-4a11-43b8-8acf-b36edee99642',
      nickName: 'Aldon Management',
      aboutUs: '',
      email: 'lgutierrez@aldonishome.com',
      contact: {
        businessContact: '+13016565998',
        zip: '20814',
        phoneNumber: '+12403802561',
        addressLine1: '8180 Wisconsin Ave',
        addressLine2: '',
        webSite: 'https://aldonishome.com',
        city: 'Bethesda',
        state: 'MD',
        timeZone: '1',
      },
      noEmployees: 100,
      noLocations: 10,
      mmsNumber: '+13852472073',
      createdBy: 1,
      settings: {
        importMap: {
          firstName: 'FirstName',
          lastName: 'LastName',
          phone: 'Phone',
          emailAddress: 'Email',
          externalId: 'ResidentHouseHoldID',
          locationName: 'PropertyName',
          locationId: {
            PropertyKey: {
              1: 88,
              2: 89,
              8: 82,
              9: 83,
              10: 84,
              12: 86,
              14: 90,
              15: 85,
            },
          },
        },
        facebook: {
          enable: false,
          reviewPosting: '>',
          rating: 4,
          content: 'feedback',
          updatedAt: 1667534561760,
        },
        twitter: {
          enable: false,
          reviewPosting: '>',
          rating: 4,
          content: 'feedback',
          updatedAt: 1667534561760,
        },
        widget: {
          enable: false,
          reviewPosting: '>',
          rating: 4,
          content: 'feedback',
        },
      },
      isChatbotEnabled: {
        type: 'Buffer',
        data: [0],
      },
      webChats: [
        {
          website: 'aldonishome.com',
          isEnabled: true,
          isLiveChatEnabled: true,
          brandColor: '#50E3C2',
          greeting: 'Hi there, have a question for me, or not?',
          offlineAutoResponder: 'Thanks for texting us. We will text you back soon.',
          liveChatAutoResponder: 'Thanks for texting us. We will be with you shortly, hopefully.',
          textColor: '#FFFFFF',
          pagePosition: 'right',
          avatarImage: 'https://reveo.com/images/operators.png',
        },
      ],
      tags: null,
    },
  },
];

const TabQuestion = ({ campaignId, campaign, setcampaign }) => {
  const [surveys, setSurveys] = useState([]);
  const { state, dispatch } = useContext(GlobalContext);
  const { selectedBusiness } = state;
  const [surveySelected, setSurveySelected] = useState({});

  const classes = useStyles();

  const getSurveys = async () => {
    setSurveys(fakeSurvey);
    try {
      const result = await baseUrl.get(
        `/survey?where={"business":${selectedBusiness},"isDeleted":false}`,
      );
      if (result) {
        if (result.data) {
          setSurveys(result.data);
        }
      }
    } catch (err) {
      // console.log('no questions available');
      //dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const CheckQuestion = ({ text, checked }) => {
    return (
      <div>
        <Checkbox
          color="default"
          onChange={() => console.log('')}
          className={classes.root}
          value=""
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<RadioButtonCheckedIcon />}
          checked={checked}
        />
        <StyledTextQuestion>{text}</StyledTextQuestion>
      </div>
    );
  };

  const QuestionType = ({ question }) => {
    return (
      <div style={{ margin: 10, paddingTop: 10 }}>
        <StyledTextQuestion>{question.text}</StyledTextQuestion>
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          {question.type === 'starRating' ? (
            <StarRating starRating={5} />
          ) : question.type === 'yesNo' ? (
            <>
              <CheckQuestion text="Yes" />
              <CheckQuestion text="No" />
            </>
          ) : (
            question.type === 'multipleChoice' && (
              <>
                {question?.choices?.map((item, index) => (
                  <CheckQuestion key={index} text={item} />
                ))}
              </>
            )
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (selectedBusiness) {
      getSurveys();
    }
  }, []);

  useEffect(() => {
    if (surveySelected.id) {
      const payload = surveys.find((item) => item.id === surveySelected.id);
      setcampaign({
        ...campaign,
        survey: payload,
      });
    }
  }, [surveySelected.id]);

  useEffect(() => {
    if (campaignId && campaign.survey) {
      const payload = {
        id: campaign.survey.id,
        value: campaign.survey.id,
        label: campaign.survey.name,
        questions: campaign.survey.questions.questions,
      };

      setSurveySelected(payload);
    }
  }, [campaignId && campaign?.survey]);

  return (
    <>
      {surveys && !surveys.length ? (
        // <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
        <NoData
          style={{ height: '100%' }}
          icon={<SurveyIcon />}
          title="This survey does not have any questions specified"
        ></NoData>
      ) : // </div>
      null}
      {surveys && surveys.length > 0 ? (
        <div style={tabContainerStyles}>
          <div style={{ overflowY: 'auto' }}>
            <SingleChoice
              options={surveys.map((survey) => ({
                id: survey.id,
                value: survey.id,
                label: survey.name,
                isDefault: true,
                questions: survey.questions.questions,
              }))}
              optionsOnPress={[]}
              currentOption={surveySelected}
              setCurrentOption={setSurveySelected}
              withRadioButton
            />
          </div>

          <div style={{ overflowY: 'auto' }}>
            {surveySelected?.questions &&
              surveySelected?.questions.map((question, index) => {
                return <QuestionType question={question} key={index} />;
              })}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default TabQuestion;
