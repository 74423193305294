import React, { useState, useContext, useEffect, useCallback } from 'react';
import Proptypes from 'prop-types';
import Axios from 'axios';
import GoalSettingPopup from '../../components/GoalSettingPopup/GoalSettingPopup';
import {
  ConvertInUserDropDownFormat,
  ConvertIntoUserGoalTableFormat,
  errorChecker,
} from '../../utils/helper/helper';
import baseUrl from '../../utils/services/config';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import CusotmTable from '../../components/CustomTable/CusotmTable';
import getGoalsColumns from '../../Columns/GoalsColumns';
import Loader from '../../components/Loader/Loader';
import useIconTheme from '../../hooks/useIconTheme';
import GoalsDeletePopup from '../../components/GoalsDeletePopup/GoalsDeletePopup';

function Goals(props) {
  const {
    showGoalSetting,
    handleHideGoalSettingPopup,
    handleShowGoalSettingPopup,
    selectedYear,
  } = props;

  const { state, dispatch } = useContext(GlobalContext);
  const { selectedBusiness, selectedLoaction } = state;
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [usersContainer, setUserCotainer] = useState([]);
  const [goalsData, setGoalsData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showDeleteGoalsPopup, setShowDeleteGoalsPopup] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState({});
  const { iconsTheme } = useIconTheme();

  const fetchUsers = useCallback(async () => {
    if (selectedBusiness) {
      try {
        setLoader(true);
        const results = await baseUrl(`${`/location/${selectedLoaction}/user?limit=1000`}`);
        if (results.status === 200) {
          if (results.data.length > 0) {
            setUserCotainer(Array.from(results.data));
          } else {
            setLoader(false);
          }
        }
      } catch (err) {
        setLoader(false);
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  }, [selectedLoaction, dispatch]);

  const fetchUserGoals = useCallback(async () => {
    if (usersContainer) {
      try {
        const promisses = usersContainer.map((user) =>
          baseUrl(
            `${`/user/${user.id}/usergoals?where={"location":"${selectedLoaction}"}&limit=1000`}`,
          ),
        );
        const results = await Axios.all(promisses);
        if (results.length > 0) {
          setLoader(false);

          setGoalsData(
            Array.from(ConvertIntoUserGoalTableFormat(usersContainer, results, selectedYear)),
          );
        } else {
          setLoader(false);
        }
      } catch (err) {
        setLoader(false);
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  }, [usersContainer, dispatch]);

  useEffect(() => {
    if (selectedLoaction) {
      setLoader(true);
      fetchUsers();
    }
  }, [selectedLoaction, selectedYear]);

  useEffect(() => {
    if (usersContainer.length > 0) {
      fetchUserGoals();
    }
  }, [usersContainer]);

  const handleGoalSetting = (dataIndex) => {
    setSelectedUserId(goalsData[dataIndex].id);
    handleShowGoalSettingPopup();
  };

  const handleUpdateGoalSettings = () => {
    setUserCotainer([]);
    setGoalsData([]);
    setSelectedUserId(null);
    fetchUsers();
    handleHideGoalSettingPopup();
  };

  const handleDelete = (dataIndex) => {
    setDeleteRecord(goalsData[dataIndex]);
    setShowDeleteGoalsPopup(true);
  };

  const handleCloseDeleteGoalsPopup = () => {
    setShowDeleteGoalsPopup(false);
    handleUpdateGoalSettings();
  };

  const options = {
    textLabels: {
      body: {
        noMatch: loader ? <Loader /> : 'No data found !',
      },
    },
    selectToolbarPlacement: 'none',
    searchOpen: false,
    filter: true,
    selectableRows: 'none',
    filterType: 'dropdown',
    responsive: 'vertical',
    rowsPerPage: 10,
    selectableRowsHeader: true,
  };

  return (
    <>
      <CusotmTable
        data={goalsData}
        columns={getGoalsColumns(handleGoalSetting, iconsTheme, handleDelete)}
        options={options}
      />
      <GoalSettingPopup
        open={showGoalSetting}
        handleClose={handleUpdateGoalSettings}
        user={ConvertInUserDropDownFormat(usersContainer)}
        selectedUserId={selectedUserId}
        selectedYear={selectedYear}
      />
      <GoalsDeletePopup
        open={showDeleteGoalsPopup}
        handleClose={handleCloseDeleteGoalsPopup}
        deleteRecord={deleteRecord}
        selectedYear={selectedYear}
      />
    </>
  );
}

Goals.propTypes = {
  showGoalSetting: Proptypes.bool.isRequired,
  handleHideGoalSettingPopup: Proptypes.func.isRequired,
  handleShowGoalSettingPopup: Proptypes.func.isRequired,
  selectedYear: Proptypes.number.isRequired,
};

export default Goals;
