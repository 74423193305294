import React from 'react';
import Proptypes from 'prop-types';
import {
  Dialog,
  CircularProgress,
  Backdrop,
  ThemeProvider as MUIThemeProvider,
} from '@material-ui/core';
import usePopupTheme from '../../../hooks/usePopupTheme';
import useInvitePopUpTheme from '../../../hooks/useInvitePopupTheme';

function GenericPopup(props) {
  const { handleClose, open, children, maxWidth, loader, invitePopup } = props;
  const { popUpTheme } = usePopupTheme();
  const { invitePopUpTheme } = useInvitePopUpTheme();

  return loader ? (
    <Backdrop fullScreen>
      <CircularProgress />
    </Backdrop>
  ) : (
    <MUIThemeProvider theme={!invitePopup ? popUpTheme : invitePopUpTheme}>
      <Dialog
        open={open}
        maxWidth={maxWidth}
        fullWidth
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        {children}
      </Dialog>
    </MUIThemeProvider>
  );
}

GenericPopup.propTypes = {
  handleClose: Proptypes.func,
  open: Proptypes.bool.isRequired,
  children: Proptypes.node.isRequired,
  maxWidth: Proptypes.string,
};

GenericPopup.defaultProps = {
  maxWidth: 'xs',
};

export default React.memo(GenericPopup);
