import React from 'react';
import Proptypes from 'prop-types';
import { FormHelperText, Grid, ThemeProvider as MUIThemeProvider } from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import { Controller } from 'react-hook-form';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import StyledTextField from '../../components/GeneralInputField/GeneralInputField';
import defaultDropDownTheme from '../../themes/Dropdown/DefaultDropdownTheme';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import Dropdown from '../../components/Dropdown/Dropdown';
import AntSwitch from '../../components/StyledAntSwitch/StyledAntSwitch';
import CustomButton from '../../components/CustomButton/CustomButton';
import TransperentButtonTheme from '../../themes/Button/TransperentButtonTheme';
import StyledSmartPhoneIcon from '../../components/Icons/MobileIcon';
import StyledLaptopIcon from '../../components/Icons/LaptopIcon';
import dayFrequency from '../../utils/api/DayFrequency';
import OnBoardingButtonTheme from '../../themes/Button/OnBoardingButtonTheme';
import reminders from '../../utils/api/reminders';
import reminderFrequency from '../../utils/api/reminderFrequency';
import Loader from '../../components/Loader/Loader';
import MobileViewContainer from '../../components/MobileViewContainer/MobileViewContainer';
import LaptopViewContainer from '../../components/LaptopViewContainer/LaptopViewContainer';
import { linkRegEx } from '../../utils/helper/regEx';
import { handlePatternError } from '../../utils/helper/helper';
import StyledFormControl from '../../components/StyledFormControl';
import useInputFieldTheme from '../../hooks/useInputFieldTheme';
import useIconTheme from '../../hooks/useIconTheme';
import useTextColors from '../../hooks/useTextColors';
import StyledBeginText from '../../components/BeginText/BeginText';
import StyledImpText from '../../components/ImpText/ImpText';
import StyledDescriptionText from '../../components/DescriptionText';
import useAntSwitchTheme from '../../hooks/useAntSwitchTheme';

const StyledContentContainer = styled(Grid)`
  height: calc(100% - 45px) !important;
  padding: 1em 0 0 2em !important;
`;

// const StyledColorContainer = styled(Grid)`
//   height: 100%;
//   align-items: center;
//   margin: 0 auto !important;
// `;

const StyledFooter = styled(Grid)`
  align-self: flex-end;
`;

const StyledFowArrow = styled(ArrowForwardIcon)`
  color: #fff !important;
  font-size: 3em !important;
`;

const StyledIconContainer = styled(Grid)`
  text-align: right !important;
  margin: 0.5em 0 !important;
`;

const StyledImpTextContainer = styled(Grid)`
  margin: 1em 0 0 0 !important;
`;

const activeToggleIcon = {
  color: '#6C6C6C',
  fontSize: '36px',
  opacity: '100%',
};

const inActiveToggleIcon = {
  color: '#6C6C6C',
  fontSize: '36px',
  opacity: '17%',
};
function NotificationCreationContent(props) {
  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    locationList,
    watchLocationField,
    control,
    watchUsersField,
    handleCampaignTypeToggler,
    campaignViewToggler,
    disableSubmitBtn,
    watchAutoReviewReminder,
    defaultPortals,
    radioValue,
    handleNextStep,
    handleBackStep,
    activeStep,
    userList,
    requestType,
    setValue,
  } = props;
  const { inputTheme } = useInputFieldTheme();
  const { iconsTheme } = useIconTheme();
  const { textColors } = useTextColors();
  const { antSwitchTheme } = useAntSwitchTheme();
  const getPreviews = (type) => {
    switch (type) {
      case 'mms':
        return (
          <MobileViewContainer
            slides={defaultPortals[radioValue].screens}
            radioValue={radioValue}
            handleNextStep={handleNextStep}
            handleBackStep={handleBackStep}
            activeStep={activeStep}
            requestType={requestType}
            type={type}
          />
        );

      case 'email':
        return (
          <LaptopViewContainer
            slides={defaultPortals[radioValue].screens}
            radioValue={radioValue}
            handleNextStep={handleNextStep}
            handleBackStep={handleBackStep}
            activeStep={activeStep}
            requestType={requestType}
            type={type}
          />
        );
      default:
        return (
          <MobileViewContainer
            slides={defaultPortals[radioValue].screens}
            radioValue={radioValue}
            handleNextStep={handleNextStep}
            handleBackStep={handleBackStep}
            activeStep={activeStep}
            requestType={requestType}
            type={type}
          />
        );
    }
  };

  const autoReviewReminder = watchAutoReviewReminder ? (
    <Grid item md={12} lg={12} sm={12} xs={12}>
      <Grid container spacing={2}>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          <ThemeProvider theme={defaultDropDownTheme}>
            <StyledFormControl error={errors.reminders}>
              <Controller
                as={<Dropdown listOptions={Array.from(reminders)} label="Select Reminder *" />}
                name="reminders"
                control={control}
                defaultValue=""
                rules={{ required: true }}
              />
              {errors.reminders ? (
                <FormHelperText>Select Reminder is a required field</FormHelperText>
              ) : null}
            </StyledFormControl>
          </ThemeProvider>
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          <ThemeProvider theme={defaultDropDownTheme}>
            <StyledFormControl error={errors.reminderFrequency}>
              <Controller
                as={
                  <Dropdown
                    listOptions={Array.from(reminderFrequency)}
                    label="Reminder Frequency *"
                  />
                }
                name="reminderFrequency"
                control={control}
                defaultValue=""
                rules={{ required: true }}
              />
              {errors.reminderFrequency ? (
                <FormHelperText>Reminder Frequency is a required field</FormHelperText>
              ) : null}
            </StyledFormControl>
          </ThemeProvider>
        </Grid>
      </Grid>
    </Grid>
  ) : null;

  const previewContainer =
    Object.keys(defaultPortals).length > 0 && defaultPortals[radioValue] ? (
      getPreviews(campaignViewToggler)
    ) : (
      <Loader />
    );

  const unsubscribeContent =
    radioValue === 'singleAction' ? (
      <Grid item md={12} lg={12} sm={12} xs={12}>
        <StyledTextField
          name="externalLink"
          onBlur={(e) => setValue('externalLink', e.target.value.trim())}
          label="Unsubscribe Link *"
          inputRef={register({
            required: true,
            validate: (value) => value.trim() !== '',
            pattern: linkRegEx,
          })}
          error={errors.externalLink}
          helperText={
            errors.externalLink ? handlePatternError(errors.externalLink, 'Unsubscribe Link') : ''
          }
        />
      </Grid>
    ) : null;

  return (
    <>
      <StyledContentContainer>
        <MUIThemeProvider theme={inputTheme}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sm={12}
              style={{
                margin: '0 0 0 1em',
                flex: '1 1 auto',
                overflow: 'auto',
                overflowX: 'hidden',
                height: 'calc(100% - 40px)',
              }}
            >
              <Grid container justify="space-between">
                <ThemeProvider
                  theme={{
                    color: textColors.headingTextColor,
                    margin: '1em  0',
                    fontSize: '2.5em',
                    fontWeight: '300',
                  }}
                >
                  <StyledBeginText item xs={12} md={12} lg={12} sm={12}>
                    Create a Notification
                  </StyledBeginText>
                </ThemeProvider>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Grid container>
                    <Grid item md={12} lg={12} sm={12} xs={12}>
                      <StyledTextField
                        name="name"
                        onBlur={(e) => setValue('name', e.target.value.trim())}
                        label="Notification Name *"
                        inputRef={register({
                          required: true,
                          validate: (value) => value.trim() !== '',
                        })}
                        error={errors.name}
                        helperText={errors.name ? 'Notification Name is a required field' : ''}
                      />
                    </Grid>
                    <Grid item md={12} lg={12} sm={12} xs={12}>
                      <Grid container spacing={2}>
                        <Grid item md={6} lg={6} sm={6} xs={6}>
                          <ThemeProvider theme={defaultDropDownTheme}>
                            <StyledFormControl error={errors.location}>
                              <Controller
                                as={
                                  <MultiSelect
                                    listOptions={Array.from(locationList)}
                                    label="Select Locations *"
                                  />
                                }
                                name="location"
                                value={watchLocationField || []}
                                control={control}
                                defaultValue={[]}
                                rules={{ required: true }}
                              />
                              {errors.location ? (
                                <FormHelperText>Locations is a required field</FormHelperText>
                              ) : null}
                            </StyledFormControl>
                          </ThemeProvider>
                        </Grid>
                        <Grid item md={6} lg={6} sm={6} xs={6}>
                          <ThemeProvider theme={defaultDropDownTheme}>
                            <StyledFormControl error={!!errors.users}>
                              <Controller
                                as={
                                  <MultiSelect
                                    listOptions={Array.from(userList)}
                                    label="Select Users *"
                                  />
                                }
                                value={watchUsersField || []}
                                name="users"
                                control={control}
                                rules={{ required: true }}
                                defaultValue={[]}
                              />
                              {errors.users ? (
                                <FormHelperText>Users is a required field</FormHelperText>
                              ) : null}
                            </StyledFormControl>
                          </ThemeProvider>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={12} lg={12} sm={12} xs={12}>
                      <Grid container spacing={2}>
                        <Grid item md={6} lg={6} sm={12} xs={12}>
                          <ThemeProvider theme={defaultDropDownTheme}>
                            <StyledFormControl error={errors.canResendAfter}>
                              <Controller
                                as={
                                  <Dropdown
                                    listOptions={Array.from(dayFrequency)}
                                    label="Send Frequency "
                                  />
                                }
                                name="canResendAfter"
                                control={control}
                                defaultValue=""
                              />
                              {errors.canResendAfter ? (
                                <FormHelperText>Send Frequency is a required field</FormHelperText>
                              ) : null}
                            </StyledFormControl>
                          </ThemeProvider>
                        </Grid>
                        <Grid item md={6} lg={6} sm={12} xs={12}>
                          <ThemeProvider theme={defaultDropDownTheme}>
                            <StyledFormControl error={errors.attributionPeriod}>
                              <Controller
                                as={
                                  <Dropdown
                                    listOptions={Array.from(dayFrequency)}
                                    label="Attribution Period"
                                  />
                                }
                                name="attributionPeriod"
                                control={control}
                                defaultValue=""
                              />
                              {errors.attributionPeriod ? (
                                <FormHelperText>
                                  Attribution Period is a required field
                                </FormHelperText>
                              ) : null}
                            </StyledFormControl>
                          </ThemeProvider>
                        </Grid>
                      </Grid>
                    </Grid>
                    {unsubscribeContent}
                    <Grid item xs={12} md={12} lg={12} sm={12}>
                      <StyledImpTextContainer container spacing={2} alignItems="center">
                        <ThemeProvider theme={{ color: textColors.impTextColor }}>
                          <StyledImpText item xs={12} md={10} lg={10} sm={12}>
                            Auto Notification Reminder
                          </StyledImpText>
                        </ThemeProvider>
                        <Grid item xs={12} md={2} lg={2} sm={12}>
                          <MUIThemeProvider theme={antSwitchTheme}>
                            <Controller
                              name="autoReminder"
                              as={<AntSwitch />}
                              control={control}
                              defaultValue={false}
                            />
                          </MUIThemeProvider>
                        </Grid>
                      </StyledImpTextContainer>
                      <Grid container spacing={2} alignItems="center">
                        <ThemeProvider
                          theme={{
                            color: textColors.descriptionText2,
                            fontSize: '12px',
                            padding: '1em 1.5em',
                          }}
                        >
                          <StyledDescriptionText item xs={12} md={10} lg={10} sm={12}>
                            Automatically send the customer a review reminder after 24 hours
                          </StyledDescriptionText>
                        </ThemeProvider>
                        <Grid item xs={12} md={2} lg={2} sm={12} />
                      </Grid>
                    </Grid>
                    {autoReviewReminder}
                    <Grid item xs={12} md={12} lg={12} sm={12}>
                      <StyledImpTextContainer container spacing={2} alignItems="center">
                        <ThemeProvider theme={{ color: textColors.impTextColor }}>
                          <StyledImpText item xs={12} md={10} lg={10} sm={12}>
                            Allow text feedback
                          </StyledImpText>
                        </ThemeProvider>
                        <Grid item xs={12} md={2} lg={2} sm={12}>
                          <MUIThemeProvider theme={antSwitchTheme}>
                            <Controller
                              name="allowTextFeedback"
                              as={<AntSwitch />}
                              control={control}
                              defaultValue={false}
                            />
                          </MUIThemeProvider>
                        </Grid>
                      </StyledImpTextContainer>
                      <Grid container spacing={2} alignItems="center">
                        <ThemeProvider
                          theme={{
                            color: textColors.descriptionText2,
                            fontSize: '12px',
                            padding: '1em 1.5em',
                          }}
                        >
                          <StyledDescriptionText item xs={12} md={10} lg={10} sm={12}>
                            When enabled the customer can choose to leave feedback when leaving a
                            review is not possible
                          </StyledDescriptionText>
                        </ThemeProvider>
                        <Grid item xs={12} md={2} lg={2} sm={12} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Grid container>
                    <Grid item xl={2} lg={2} md={2} sm={4} xs={4}>
                      <Grid container>
                        <Grid item>
                          <Grid container justify="flex-end">
                            <StyledIconContainer item xs={12} md={12} lg={12} sm={12}>
                              <ThemeProvider theme={TransperentButtonTheme}>
                                <CustomButton onClick={() => handleCampaignTypeToggler('mms')}>
                                  <ThemeProvider
                                    theme={
                                      campaignViewToggler === 'mms'
                                        ? { ...activeToggleIcon, color: iconsTheme.mobileIconTheme }
                                        : {
                                            ...inActiveToggleIcon,
                                            color: iconsTheme.mobileIconTheme,
                                          }
                                    }
                                  >
                                    <StyledSmartPhoneIcon />
                                  </ThemeProvider>
                                </CustomButton>
                              </ThemeProvider>
                            </StyledIconContainer>
                            <StyledIconContainer item xs={12} md={12} lg={12} sm={12}>
                              <ThemeProvider theme={TransperentButtonTheme}>
                                <CustomButton onClick={() => handleCampaignTypeToggler('email')}>
                                  <ThemeProvider
                                    theme={
                                      campaignViewToggler === 'email'
                                        ? { ...activeToggleIcon, color: iconsTheme.laptopIcon }
                                        : { ...inActiveToggleIcon, color: iconsTheme.laptopIcon }
                                    }
                                  >
                                    <StyledLaptopIcon />
                                  </ThemeProvider>
                                </CustomButton>
                              </ThemeProvider>
                            </StyledIconContainer>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xl={10} lg={10} md={10} sm={8} xs={8} style={{ overflow: 'hidden' }}>
                      {previewContainer}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <StyledFooter
              item
              xs={12}
              sm={12}
              lg={12}
              md={12}
              style={{ flex: '1 0 auto', maxHeight: '60px', minHeight: '60px' }}
            >
              <Grid container justify="flex-end">
                <Grid item>
                  <ThemeProvider theme={OnBoardingButtonTheme}>
                    <CustomButton disabled={disableSubmitBtn} type="submit">
                      <StyledFowArrow />
                    </CustomButton>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </StyledFooter>
          </form>
        </MUIThemeProvider>
      </StyledContentContainer>
    </>
  );
}

NotificationCreationContent.propTypes = {
  handleSubmit: Proptypes.func.isRequired,
  onSubmit: Proptypes.func.isRequired,
  register: Proptypes.objectOf(Proptypes.any).isRequired,
  errors: Proptypes.objectOf(Proptypes.any).isRequired,
  locationList: Proptypes.arrayOf(Proptypes.object).isRequired,
  watchLocationField: Proptypes.string.isRequired,
  control: Proptypes.objectOf(Proptypes.any).isRequired,
  watchUsersField: Proptypes.string.isRequired,
  handleCampaignTypeToggler: Proptypes.func.isRequired,
  campaignViewToggler: Proptypes.bool.isRequired,
  disableSubmitBtn: Proptypes.func.isRequired,
  watchAutoReviewReminder: Proptypes.string.isRequired,
  defaultPortals: Proptypes.objectOf(Proptypes.array).isRequired,
  radioValue: Proptypes.string.isRequired,
  handleNextStep: Proptypes.func.isRequired,
  handleBackStep: Proptypes.func.isRequired,
  activeStep: Proptypes.number.isRequired,
  userList: Proptypes.arrayOf(Proptypes.object).isRequired,
  setValue: Proptypes.func.isRequired,
  requestType: Proptypes.string.isRequired,
};

export default NotificationCreationContent;
