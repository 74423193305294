import React, { useContext, useState } from 'react';
import Proptypes from 'prop-types';
import EditIcon from 'mdi-react/PencilOutlineIcon';
import CheckIcon from '@material-ui/icons/Check';
import styled, { ThemeProvider } from 'styled-components';
import { CircularProgress, Grid } from '@material-ui/core';
import GenricPopup from '../../Popup/GenricPopup';
import CustomButton from '../../CustomButton/CustomButton';
import EmptyScreen from './components/EmptyScreen/EmptyScreen';
import AddTypeRequestModal from './components/AddTypeRequestModal/AddTypeRequestModal';
import ContainerForm from './components/ContainerForm/ContainerForm';
import { GlobalContext } from '../../../Stores/GlobalStateStore';
import baseUrl from '../../../utils/services/config';
import { errorChecker } from '../../../utils/helper/helper';
import useAlert from '../../../hooks/useAlert';
import CusotmAlert from '../../CustomAlert/CustomAlert';

const StyledIcon = styled.div`
  cursor: pointer;
  margin-left: 10px;
`;

const StyledTitle = styled.h1`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
`;

const StyledTitleInput = styled.input`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  transition: border-color 0.3s;
  &:focus {
    border-color: #35eda7;
    outline: none;
    border-radius: 10px;
  }
`;

const buttonTheme = {
  background: 'black',
  margin: '0px',
  color: '#fff',
  fontSize: '18px !important',
  fontWeight: 'bold',
};

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledContainer = styled(GenricPopup)`
  height: 100%;
  width: 100%;
`;

const StyledHeaderContainer = styled(Grid)`
  height: 10%;
  display: flex;
  align-items: center;
  padding-left: 20px;
`;

const StyledMainContent = styled.div`
  height: 512px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledFooterContainer = styled.div`
  display: flex;
  justify-content: end;
  height: 46px;
  width: 98%;
`;

const SurveyBuilderPopover = ({ show, handleClose }) => {
  const surveyAlert = useAlert();

  const [showEditName, setShowEditName] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState('New Survey');

  const [questionAddType, setQuestionAddType] = useState('');
  const { state, dispatch } = useContext(GlobalContext);
  const { selectedBusiness } = state;
  const [isLoading, setIsLoading] = useState(false);

  const [surveyBody, setSurveyBody] = useState({
    questions: [

    ],
  });
  const [questionCategory, setQuestionCategory] = useState('');
  const [questionFlowControl, setQuestionFlowControl] = useState([]);

  const [updateQuestionIndex, setUpdateQuestion] = useState(null);

  const handleEditName = () => {
    setShowEditName(!showEditName);
  };

  const handleAddRequest = (type) => {
    setQuestionAddType(type);
  };




  const handleSaveQuestion = (questions) => {
    if (questionCategory.length > 0) {
      questions.category = questionCategory;
      setQuestionCategory('');
    }
    if (questionFlowControl.length > 0) {
      questions.flowControl = questionFlowControl
      setQuestionFlowControl([])
    } else {
      questions.flowControl = []
    }

    setSurveyBody({
      ...surveyBody,
      questions: [...surveyBody.questions, questions],
    });

  };

  const handleDeleteQuestion = (indexQuestion) => {
    const updateQuestions = surveyBody.questions.filter((obj, index) => index !== indexQuestion);
    setSurveyBody({
      ...surveyBody,
      questions: [...updateQuestions],
    });
  };
  const handleEditQuestion = (index, typeQuestion) => {
    setUpdateQuestion(index);
    setQuestionAddType(typeQuestion);
  };

  const handleSeveSurvey = async () => {
    setIsLoading(true);

    const payload = {
      idDeleted: 1,
      name: newTemplateName,
      displayName: newTemplateName,
      business: selectedBusiness,
      questions: {
        name: newTemplateName,
        questions: surveyBody.questions,
      },
      screens: [
        {
          screen: 'sms',
          title:
            'Hi @customerName, welcome to @business. Please help us know how we are doing by filling out this brief survey. Thanks! @link',
          url: '',
          uploadedFile: null,
          emailTitle: 'Thank you for your business',
        },
        {
          screen: 'question',
          questions: [],
        },
        {
          screen: 'thankyou',
          title: 'Thank you for your time, we look forward to serving you in the future',
          url: '@businessLogo',
          uploadedFile: null,
        },
      ],
    };

    try {
      const results = await baseUrl.post(`/survey`, { ...payload });
      if (results.status === 200) {
        const { handleSetMessage, handleSetMessageType, handleShow } = surveyAlert;
        handleShow();
        handleSetMessageType('success');
        handleSetMessage('survey created');
        setNewTemplateName('New Survey');
        setSurveyBody({
          ...surveyBody,
          questions: [],
        });
        setQuestionAddType('');
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };
  const LoaderCircularProgress = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
      <Grid container alignItems="center" justify="center">
        <CircularProgress />
      </Grid>
    </div>
  );

  return (
    <StyledContainer open={show} fullWidth maxWidth="xl" height="xl" onClose={handleClose}>
      <CusotmAlert
        message={surveyAlert.message}
        handleClose={surveyAlert.handleClose}
        open={surveyAlert.show}
        messageType={surveyAlert.messageType}
      />
      {isLoading ? (
        <LoaderCircularProgress />
      ) : (
        <div>
          <StyledHeaderContainer container>
            {showEditName ? (
              <>
                <StyledTitleInput
                  type="text"
                  value={newTemplateName}
                  onChange={(e) => setNewTemplateName(e.target.value)}
                />
                <StyledIcon>
                  <CheckIcon onClick={handleEditName} />
                </StyledIcon>
              </>
            ) : (
              <>
                <StyledTitle>{newTemplateName}</StyledTitle>
                <StyledIcon>
                  <EditIcon onClick={handleEditName} />
                </StyledIcon>
              </>
            )}
          </StyledHeaderContainer>

          {!questionAddType && surveyBody.questions.length === 0 ? (
            <StyledMainContent>
              <EmptyScreen />
              <AddTypeRequestModal handleAddRequest={handleAddRequest} />
            </StyledMainContent>
          ) : (
            <ContainerForm
              questionAddType={questionAddType}
              handleAddRequest={handleAddRequest}
              handleSaveQuestion={handleSaveQuestion}
              setQuestionCategory={setQuestionCategory}
              handleDeleteQuestion={handleDeleteQuestion}
              handleEditQuestion={handleEditQuestion}
              updateQuestionIndex={updateQuestionIndex}
              setUpdateQuestion={setUpdateQuestion}
              surveyBody={surveyBody}
              setSurveyBody={setSurveyBody}
              setQuestionAddType={setQuestionAddType}
              setQuestionFlowControl={setQuestionFlowControl}
            />
          )}

          <StyledFooterContainer>
            <ThemeProvider
              theme={{
                margin: '0',
                background: buttonTheme.buttonBgColor,
                color: buttonTheme.buttonColor2,
                width: '150px',
              }}
            >
              <StyledButtonContainer>
                <ThemeProvider
                  theme={{
                    margin: '0',
                    background: '#f5f5f5',
                    color: buttonTheme.buttonColor2,
                  }}
                >
                  <CustomButton onClick={handleClose}>Cancel</CustomButton>
                </ThemeProvider>
                <CustomButton onClick={handleSeveSurvey}>Save survey</CustomButton>
              </StyledButtonContainer>
            </ThemeProvider>
          </StyledFooterContainer>
        </div>
      )}
    </StyledContainer>
  );
};

SurveyBuilderPopover.propTypes = {
  show: Proptypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  handleClose: Proptypes.func,
};

export default SurveyBuilderPopover;
