import React, { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Card, CardContent, Typography, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import Logo from '../../assets/images/reveo_logo@1x.png';
import CustomButton from '../CustomButton/CustomButton';
import EmoticonSad from '../Icons/EmoticonSad';
import EmoticonNutral from '../Icons/EmoticonNutral';
import Emoticon from '../Icons/Emoticon';
import UploadLayout from '../UploadLayout';

const CardContentStyle = styled(CardContent)`
  text-align: center;
  padding-top: 0;
`;

const ReviewCommentText = styled.textarea`
  width: 85%;
  height: 90px;
  outline: none;
  border: 2px solid #a7a3a3 !important;
  border-radius: 6px;
  padding: 10px;
  font-size: 14px;
  font-family: Montserrat, regular;
`;

const CardStyle = styled(Card)`
  text-align: center;
  min-width: 100%;
  height: 100%;
  position: relative;
  // @media only screen and (max-width: 600px) {
  //   box-shadow: none;
  // }
`;

const BottomContent = styled(CardContent)`
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 12px 0 !important;
`;

const QuestionTextStyle = styled(Typography)`
  color: #9e9a9a !important;
  font-family: Montserrat, bold !important;
  font-size: 1.2rem !important;
  // @media only screen and (min-device-width: 481px) and (max-device-width: 1000px) {
  //   font-size: 1rem !important;
  // }
`;
const MessageText = styled(Typography)`
  color: #9e9a9a !important;
  font-family: Montserrat, bold !important;
  font-size: 14px !important;
  padding: 10px;
  // @media only screen and (min-device-width: 481px) and (max-device-width: 1000px) {
  //   font-size: 1rem !important;
  // }
`;

const sendBtn = {
  margin: '0 ',
  padding: '5px',
  color: '#fff',
  backgroundColor: '#000',
};

function SentimentCard(props) {
  const { sentimentData, onClientFeedback, powerImageWidth, ratingByFace, url } = props;

  const { title } = sentimentData;
  const [outline, setoutline] = useState({
    sad: '',
    satisfy: '',
    happy: '',
  });

  return (
    <CardStyle>
      <CardContentStyle>
        {url ? (
          <ThemeProvider theme={{ width: '85%', margin: '0 auto', border: 'none' }}>
            <UploadLayout
              handleClick={() => {}}
              url={`${process.env.REACT_APP_IMAGE_ENDPOINT}${url}`}
              imgHeight="350px"
              disabled
            />
          </ThemeProvider>
        ) : (
          <p />
        )}
      </CardContentStyle>
      <CardContentStyle>
        <QuestionTextStyle>{title}</QuestionTextStyle>
      </CardContentStyle>

      <CardContentStyle>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            variant={outline.sad}
            onClick={() => {
              setoutline({
                sad: 'outlined',
                satisfy: '',
                happy: '',
              });
              ratingByFace(0);
            }}
          >
            <EmoticonSad />
          </Button>

          <Button
            variant={outline.satisfy}
            onClick={() => {
              setoutline({
                sad: '',
                satisfy: 'outlined',
                happy: '',
              });
              ratingByFace(1);
            }}
          >
            <EmoticonNutral />
          </Button>
          <Button
            variant={outline.happy}
            onClick={() => {
              setoutline({
                sad: '',
                satisfy: '',
                happy: 'outlined',
              });
              ratingByFace(2);
            }}
          >
            <Emoticon />
          </Button>
        </div>

        <MessageText>Send a message</MessageText>
        <ReviewCommentText onChange={(e) => onClientFeedback(e.target.value)} />
        <ThemeProvider theme={sendBtn}>
          <CustomButton onClick={() => ratingByFace('send')}>send</CustomButton>
        </ThemeProvider>
      </CardContentStyle>

      <CardContentStyle />
      <BottomContent>
        <div style={{ display: 'inline-grid' }}>
          <span style={{ fontSize: '6px', marginLeft: '14px' }}>Powered by</span>
          <img
            src={Logo}
            style={{ width: powerImageWidth ? `${powerImageWidth}em` : '2.3em' }}
            alt="logo"
          />
        </div>
      </BottomContent>
    </CardStyle>
  );
}

SentimentCard.propTypes = {
  sentimentData: PropTypes.objectOf(PropTypes.string).isRequired,
  onClientFeedback: PropTypes.func.isRequired,
  ratingByFace: PropTypes.func.isRequired,
  powerImageWidth: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
};

SentimentCard.defaultProps = {};

export default SentimentCard;
