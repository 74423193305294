import { Grid, Card } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import MarkerComponent from './MarkerComponent';
import ReveoButton from '../../components/Buttons/ReveoButton';
import DirectionsIcon from 'mdi-react/DirectionsForkIcon';

function LocationDetails({ address, meta, googleDetails, getDirections }) {
  const [ll, setLL] = useState({
    lat: null,
    lng: null,
  });

  useEffect(() => {
    if (googleDetails && googleDetails.geometry) {
      const location = googleDetails.geometry.location;
      setLL({
        lat: location.lat && location.lat !== undefined ? location.lat : 40.6371226,
        lng: location.lng && location.lng !== undefined ? location.lng : -111.902314,
      });
    } else {
      if (meta && meta !== undefined) {
        setLL({
          lat: meta.latLan && meta.latLan !== undefined ? meta.latLan.latitude : 40.6371226,
          lng: meta.latLan && meta.latLan !== undefined ? meta.latLan.longitude : -111.902314,
        });
      }
    }
  }, [meta, googleDetails]);

  const googleMapContent =
    ll.lng && ll.lat ? (
      <div style={{ width: '100%', height: '200px' }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
            language: 'en',
          }}
          defaultCenter={ll}
          defaultZoom={13}
        >
          <MarkerComponent
            lat={ll.lat}
            lng={ll.lng}
            text={(googleDetails && googleDetails.formatted_address) || address}
          />
        </GoogleMapReact>
      </div>
    ) : (
      ''
    );
  return (
    <div style={{ marginTop: '1rem' }}>
      <Card style={{ borderRadius: '10px' }} variant="outlined">
        {googleMapContent}
      </Card>
      <Grid container style={{ marginTop: '1rem', justifyContent: 'space-between' }}>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            columnGap: '.5rem',
            width: '50%',
          }}
        >
          <RoomIcon />
          <span style={{ fontSize: '15px', fontWeight: 'normal' }}>
            {(googleDetails && googleDetails.formatted_address) || address}
          </span>
        </div>
        <div>
          <ReveoButton
            title="Get directions"
            variant="outlined"
            onClick={getDirections}
            icon={<DirectionsIcon style={{ height: '16px' }} />}
          />
        </div>
      </Grid>
    </div>
  );
}

export default LocationDetails;
