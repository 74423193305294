import React from 'react';
import Proptypes from 'prop-types';
import { Grid } from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import MobilePreview from '../MobilePreview/MobilePreview';
import CompanyLogo from '../CompanyLogo/CompanyLogo';
import EditableText from '../Editable/EditableText';
import EditableButton from '../Editable/EditableButton';
import googleLogo from '../../assets/images/google.png';
import facebookLogo from '../../assets/images/facebook-circled.png';
import AddNetworkPopover from '../Popover/AddNetworkPopover/AddNetworkPopover';
import usePopover from '../../hooks/usePopover';
import PoweredBy from '../PoweredBy/PoweredBy';
import CustomButton from '../CustomButton/CustomButton';
import StyledTrashIcon from '../Icons/TrashIcon';

const StyledMobileViewContainer = styled(Grid)`
  position: absolute;
  height: 95%;
  margin: 0 !important;
  top: 30px;
  padding: 0 40px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const StyledContentContainer = styled(Grid)`
  min-height: calc(100% - 50px);
`;

const textFeedbackTheme = {
  fontSize: '14px',
  fontWeight: '700',
  margin: '0.5em',
};

const textFeedbackTheme2 = {
  fontSize: '10px',
  background: 'transparent',
  fontWeight: '500',
  margin: '0.5em',
};

const imageTheme = {
  objectFit: 'scale-down',
  height: '125px',
  width: '100%',
};

function SocialSite(props) {
  const { backgroundColor, color, type, url, screen, handleRemovePostSite } = props;
  const addNetworkPopover = usePopover();

  const getLogo = (imgType) => {
    switch (imgType) {
      case 'facebook':
        return facebookLogo;
      case 'google':
        return googleLogo;
      case 'carguru':
        return googleLogo;
      default:
        return '';
    }
  };

  return (
    <MobilePreview>
      <StyledMobileViewContainer container direction="row" justify="center" alignItems="center">
        <StyledContentContainer item xs={12} sm={12} lg={12} md={12}>
          <Grid container style={{ textAlign: 'center' }}>
            <Grid item xs={12} sm={12} lg={12} md={12}>
              <ThemeProvider theme={imageTheme}>
                <CompanyLogo
                  logo={url ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${url}` : getLogo(type)}
                />
              </ThemeProvider>
            </Grid>
            <EditableText {...props} />
            <Grid item xs={12} sm={12} lg={12} md={12}>
              <ThemeProvider
                theme={{
                  ...textFeedbackTheme,
                  background:
                    backgroundColor === '#fff' || backgroundColor === '#ffffff'
                      ? '#333'
                      : backgroundColor,
                  color,
                }}
              >
                <EditableButton {...props} type="positiveButtonText" textColor="#fff" />
              </ThemeProvider>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} md={12}>
              <ThemeProvider
                theme={{
                  ...textFeedbackTheme2,
                  color:
                    backgroundColor === '#fff' || backgroundColor === '#ffffff'
                      ? '#333'
                      : backgroundColor,
                }}
              >
                <EditableButton
                  {...props}
                  type="negativeButtonText"
                  handleClick={addNetworkPopover.handleClick}
                />
              </ThemeProvider>
            </Grid>
          </Grid>
          {screen === 'setup' ? (
            ''
          ) : (
            <Grid item style={{ position: 'absolute', right: 40, top: -20 }}>
              <ThemeProvider
                theme={{
                  background: 'transparent',
                  width: '20px',
                  height: '20px',
                  maxWidth: '20px',
                  minWidth: '20px',
                }}
              >
                <CustomButton onClick={() => handleRemovePostSite(props, 0, 'social-site')}>
                  <ThemeProvider theme={{ color: 'tomato' }}>
                    <StyledTrashIcon />
                  </ThemeProvider>
                </CustomButton>
              </ThemeProvider>
            </Grid>
          )}
        </StyledContentContainer>
        <PoweredBy isPoweredBy />
      </StyledMobileViewContainer>
      <AddNetworkPopover
        anchorEl={addNetworkPopover.anchorEl}
        handleClose={addNetworkPopover.handleClose}
        id={addNetworkPopover.id}
        open={addNetworkPopover.open}
        isAddPostSite={false}
        {...props}
      />
    </MobilePreview>
  );
}

SocialSite.propTypes = {
  negativeButtonText: Proptypes.string.isRequired,
  positiveButtonText: Proptypes.string.isRequired,
  title: Proptypes.string.isRequired,
  color: Proptypes.string.isRequired,
  backgroundColor: Proptypes.string.isRequired,
  type: Proptypes.string.isRequired,
  url: Proptypes.string,
  screen: Proptypes.string.isRequired,
  handleRemovePostSite: Proptypes.func.isRequired,
};

SocialSite.defaultProps = {
  url: '',
};

export default SocialSite;
