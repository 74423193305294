import { Chip } from '@material-ui/core';
import styled from 'styled-components';

const StyledChip = styled(Chip)`
  background: ${(props) => (props.theme.bgColor ? props.theme.bgColor : '#c92929')} !important;
  color: ${(props) => (props.theme.color ? props.theme.color : '#fff')} !important;
  width: ${(props) => (props.theme.width ? props.theme.width : '100%')} !important;
`;

export default StyledChip;
