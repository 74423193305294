import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import UserLogin from '../pages/UserLogin/UserLogin';
import AppWrapper from '../AppWrapper';
import PageNotFound from '../pages/PageNotFound';
import ForgetPassword from '../pages/ForgetPassword';
import ResetPassword from '../pages/ResetPassword';
import Registration from '../pages/Registration/Registration';
import AccountActivate from '../pages/AccountActivate';
import GlobalStateStore from '../Stores/GlobalStateStore';
import OnBoardingStore from '../Stores/OnBoarding';
import ReviewResponse from '../pages/ReviewResponse';
import BusinessReview from '../layout/Business Review/BusinessView';
import Unsubscribe from '../pages/Unsubscribe';
import SurveyReview from '../pages/SurveyReview';
import NotificationReview from '../pages/NotificationReview';
import ReviewSummary from '../pages/iframes/ReviewSummary';
import ReviewList from '../pages/iframes/ReviewsList';
import TopReviewSourcesContainer from '../pages/iframes/TopReviewSourcesContainer';
import HybridWidgetContainer from '../pages/iframes/HybridWidget';
import AllInOneWidgetContainer from '../pages/ReviewsWidgets/AllInOneReviews';
import SurveyStore from '../Stores/SurveyStore';
import BusinessCard from '../pages/BusinessCard';

function InitialRoute() {
  const logedIn =
    localStorage.getItem('AUTH_TOKEN') && localStorage.getItem('AUTH_TOKEN') !== undefined;
  return (
    <>
      <Route
        exact
        path="/"
        render={() => (!logedIn ? <Redirect to="/login" /> : <Redirect to="/app" />)}
      />
      <GlobalStateStore>
        <OnBoardingStore>
          <SurveyStore>
            <Switch>
              <Route
                exact
                path="/login"
                render={() => (!logedIn ? <UserLogin /> : <Redirect to="/app" />)}
              />

              <Route
                path="/app"
                render={() => (logedIn ? <AppWrapper /> : <Redirect to="/login" />)}
              />

              <Route
                exact
                path="/password-recovery"
                render={() => (!logedIn ? <ForgetPassword /> : <Redirect to="/app" />)}
              />
              <Route exact path="/forget-password" component={ResetPassword} />
              <Route path="/register" component={Registration} />
              <Route path="/account-activate" component={AccountActivate} />
              <Route path="/email-unsubscribe" component={Unsubscribe} />
              <Route path="/business-reviews/:businessName" component={BusinessReview} />
              <Route path="/review-request" component={ReviewResponse} />
              <Route path="/survey-review" component={SurveyReview} />
              <Route path="/contact-me/:bid/:uid" component={BusinessCard} />
              <Route path="/notification-review" component={NotificationReview} />
              <Route path="/review-summary-widget" component={ReviewSummary} />
              <Route path="/reviews-widget" component={ReviewList} />
              <Route path="/top-review-summary-widget" component={TopReviewSourcesContainer} />
              <Route path="/hybrid-widget" component={HybridWidgetContainer} />
              <Route path="/all-in-one-reviews-widget" component={AllInOneWidgetContainer} />
              <Route path="*" component={PageNotFound} />
            </Switch>
          </SurveyStore>
        </OnBoardingStore>
      </GlobalStateStore>
    </>
  );
}

export default InitialRoute;
