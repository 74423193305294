import { createTheme } from '@material-ui/core/styles';
import { useContext, useMemo, useState } from 'react';
import { GlobalContext } from '../Stores/GlobalStateStore';

const useInvitePopUpTheme = () => {
  const { state } = useContext(GlobalContext);
  const { themeContainer, thankyouPopUpBR } = state;
  const [theme, setTheme] = useState({});
  useMemo(() => {
    setTheme(
      createTheme({
        overrides: {
          MuiPaper: {
            root: {
              backgroundColor: themeContainer.popupBgColor,
            },
          },
          MuiDialog: {
            paper: {
              overflow: 'visible !important',
            },
          },
          MuiDialogTitle: {
            root: {
              color: themeContainer.popupTitleColor,
              fontFamily: themeContainer.popupTitleFontFamily,
              fontWeight: themeContainer.popupTitleFontWeight,
              fontSize: themeContainer.popupTitleFontSize,
              letterSpacing: themeContainer.popupLetterSpacing,
            },
          },
        },
        shape: {
          borderRadius: '10px !important',
        },
      }),
    );
  }, [themeContainer, thankyouPopUpBR]);
  return {
    invitePopUpTheme: theme,
  };
};
export default useInvitePopUpTheme;
