import React, { createContext, useReducer } from 'react';
import Proptypes from 'prop-types';
import surveyState from '../state/surveyState';
import surveyReducer from '../Reducers/surveyReducer';

export const SurveyContext = createContext(surveyReducer);

const SurveyStore = ({ children }) => {
  const [surveyData, surveyDispatch] = useReducer(surveyReducer, surveyState);
  return (
    <SurveyContext.Provider value={{ surveyData, surveyDispatch }}>
      {children}
    </SurveyContext.Provider>
  );
};

SurveyStore.propTypes = {
  children: Proptypes.node.isRequired,
};

export default SurveyStore;
