import React, { useContext, useCallback, useEffect } from 'react';
import {
  makeStyles,
  createStyles,
  ThemeProvider as MUIThemeProvider,
} from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { ThemeProvider } from 'styled-components';
import { OnBoardingContext } from '../../Stores/OnBoarding';
import OnBoardingPopUp from './OnBoardingPopUp';
import Step1 from './Steps/Step1';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import Step5 from './Steps/Step5';

import {
  ConvertInDropDownFormat,
  ConvertIntoUserDropDown,
  errorChecker,
} from '../../utils/helper/helper';
import baseUrlLogin from '../../utils/services/auth.config';
import baseUrl from '../../utils/services/config';
import { GlobalContext } from '../../Stores/GlobalStateStore';
// import Step9 from './Steps/Step9';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 'calc(100vh - 120px)',
      padding: '1em',
      '& > div:nth-of-type(1)': {
        display: 'none',
      },
      [theme.breakpoints.up('xl')]: {
        height: '834px',
      },
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
);

function getSteps() {
  return ['Step1', 'Step2', 'Step3', 'Step4', 'Step5', 'Step6', 'Step7'];
}

function OnBoardingContainer() {
  const { onBoardingData, onBoardingDispatch } = useContext(OnBoardingContext);
  const { showPopUp } = onBoardingData;
  const { state, dispatch } = useContext(GlobalContext);
  const { selectedBusiness, themeContainer } = state;
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handlePopupClose = () => {
    onBoardingDispatch({ type: 'HIDE_POPUP' });
  };

  const handleSubmit = () => {
    onBoardingDispatch({ type: 'HIDE_POPUP' });
    setActiveStep(0);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const fetchSeoCategory = useCallback(() => {
    baseUrl
      .get('/seoCategory?isDeleted=false')
      .then((res) => {
        if (res.status === 200) {
          onBoardingDispatch({
            type: 'SET_SEO_CATEGORIES',
            payload: Array.from(ConvertInDropDownFormat(res.data)),
          });
        }
      })
      .catch((err) => {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      });
  }, [dispatch, onBoardingDispatch]);

  const fetchIndustries = useCallback(() => {
    baseUrlLogin
      .get('/industry?isDeleted=false')
      .then((res) => {
        if (res.status === 200) {
          onBoardingDispatch({
            type: 'SET_INDUSTRIES_CATEGORIES',
            payload: Array.from(ConvertInDropDownFormat(res.data)),
          });
        }
      })
      .catch((err) => {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      });
  }, [dispatch, onBoardingDispatch]);

  const fetchUser = useCallback(() => {
    baseUrl
      .get(`/business/${selectedBusiness}/user?isDeleted=false`)
      .then((res) => {
        onBoardingDispatch({
          type: 'SET_USER_LIST',
          payload: Array.from(ConvertIntoUserDropDown(res.data)),
        });
      })
      .catch((err) => {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      });
  }, [selectedBusiness, dispatch, onBoardingDispatch]);

  const fetchReviewPortal = useCallback(() => {
    baseUrl
      .get(`/business/${selectedBusiness}/reviewportal`)
      .then((res) => {
        onBoardingDispatch({
          type: 'SET_PORTAL_LIST',
          payload: Array.from(ConvertInDropDownFormat(res.data)),
        });
      })
      .catch((err) => {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      });
  }, [selectedBusiness, dispatch, onBoardingDispatch]);

  useEffect(() => {
    fetchIndustries();
    fetchSeoCategory();
    if (selectedBusiness) {
      fetchUser();
      fetchReviewPortal();
    }
  }, [selectedBusiness, fetchIndustries, fetchSeoCategory, fetchUser, fetchReviewPortal]);

  useEffect(() => {
    if (selectedBusiness) {
      setActiveStep(3);
    }
  }, [selectedBusiness]);

  const popUpTheme = createTheme({
    background: themeContainer.popoverBgColor,
  });

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Step1 handleNext={handleNext} />;
      case 1:
        return <Step3 handleNext={handleNext} />;
      case 2:
        return <Step4 handleNext={handleNext} />;
      case 3:
        return <Step5 handleNext={handleSubmit} />;

      default:
        return 'Unknown step';
    }
  }

  return (
    <>
      <ThemeProvider theme={{ padding: 0, overflowY: 'hidden' }}>
        <MUIThemeProvider theme={popUpTheme}>
          <OnBoardingPopUp
            show={showPopUp}
            handleClose={handlePopupClose}
            handleNext={activeStep === steps.length - 1 ? handleSubmit : handleNext}
            activeStep={activeStep}
          >
            <div className={classes.root}>
              <Stepper alternativeLabel activeStep={activeStep}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {getStepContent(activeStep)}
            </div>
          </OnBoardingPopUp>
        </MUIThemeProvider>
      </ThemeProvider>
    </>
  );
}

OnBoardingContainer.propTypes = {};

export default React.memo(OnBoardingContainer);
