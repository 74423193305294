const OnBoardingButtonTheme = {
  background: '#C1C1C1',
  margin: '.5em .5em .5em 1em',
  width: '60px',
  height: '60px',
  borderRadis: '50%',
  color: '#fff',
};

export default OnBoardingButtonTheme;
