import { useContext, useMemo, useState } from 'react';
import { GlobalContext } from '../Stores/GlobalStateStore';

const useButtonTheme = () => {
  const { state } = useContext(GlobalContext);
  const { themeContainer } = state;
  const [theme, setTheme] = useState({});
  useMemo(() => {
    setTheme({
      buttonBgColor: themeContainer.actionBtnColor,
      buttonColor1: themeContainer.fontColor,
      buttonColor2: themeContainer.fontColor2,
      buttonColor3: themeContainer.fontColor3,
      buttonColor4: themeContainer.fontColor4,
      buttonColor5: themeContainer.fontColor5,
      buttonColor6: themeContainer.fontColor6,
      buttonColor7: themeContainer.fontColor7,
      buttonColor8: themeContainer.fontColor8,
      disabledButtonBGTransparent: themeContainer.disabledButtonBGTransparent,
    });
  }, [themeContainer]);
  return {
    buttonTheme: theme,
  };
};
export default useButtonTheme;
