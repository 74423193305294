/* eslint-disable no-prototype-builtins */
/* eslint-disable array-callback-return */
/* eslint-disable */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import Proptypes from 'prop-types';
import { useHistory } from 'react-router';
import { Grid, Box } from '@material-ui/core';
import Loader from '../../components/Loader/Loader';
import baseUrl from '../../utils/services/config';
import AuthCard from '../../components/AuthCards/AuthCard';
import { ConvertIntoListingCards, errorChecker } from '../../utils/helper/helper';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import NoDataFound from '../../components/NoDataFound/NoDataFound';
import ReviewLinkPopup from '../../components/ReviewLinkPopup/ReviewLinkPopup';
import { OnBoardingContext } from '../../Stores/OnBoarding';
import CusotmAlert from '../../components/CustomAlert/CustomAlert';
import useAlert from '../../hooks/useAlert';
function AccessAuthorizationsContainer(props) {
  const { reviewSitesFilter } = props;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [allCardsListingContainer, setAllCardsListingContaienr] = useState([]);
  const [postMetaDataContainer, setPostMetaDataContainer] = useState([]);
  const [listingData, setLisitingData] = useState([]);
  const { state, dispatch } = useContext(GlobalContext);
  const { onBoardingDispatch } = useContext(OnBoardingContext);
  const [listingPopUp, setLisitngPopUp] = useState(false);
  const [metaDataId, setMetaId] = useState(null);
  const [postSiteId, setPostSiteId] = useState(null);
  const [postReviewURL, setPostReviewLink] = useState('');
  const [listReviewsURL, setListReviewsLink] = useState('');
  const [siteId, setSiteId] = useState(null);
  const fetchReviewsAlert = useAlert();

  const {
    selectedLoaction: selectedLocation,
    businesses,
    selectedBusiness,
    facebookAccountId,
  } = state;

  const [showAlert, setShowAlert] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState('');
  const [alertMessageType, setAlertMessageType] = useState('');

  const fetchPostsites = useCallback(
    async (filterValue) => {
      setLoader(true);
      try {
        const results = await baseUrl.get(`/postsites?isDeleted=${filterValue}`);
        if (results.status === 200) {
          if (results.data.length === 0) {
            setLoader(false);
          }
          // filter connections by matching business's industry
          const activeBusiness = businesses.find((each) => each.id === selectedBusiness);
          const activeBusinessIndustry = activeBusiness.industryName;
          const filteredResults = results.data.filter((each) => {
            if (!each.authRequired && each.industry && each.industry.length) {
              const industryNames = each.industry.map((ind) => ind.name);
              if (activeBusinessIndustry && !industryNames.includes('Others')) {
                return industryNames.includes(activeBusinessIndustry);
              }
            }
            return true;
          });
          setAllCardsListingContaienr(filteredResults);
        }
      } catch (err) {
        setLoader(false);
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    },
    [selectedBusiness, dispatch],
  );

  // const fetchCampaignList = useCallback(async () => {
  //   if (selectedLocation) {
  //     try {
  //       const results = await baseUrl.get(`/campaign/list?&location=${selectedLocation}`);
  //       if (results.status === 200 && results.data.pageMeta.total === 0) {
  // onBoardingDispatch({ type: 'START_ONBOARD_WIZARD' });
  // history.push('/app/setup/campaign-type');
  //       }
  //     } catch (err) {
  //       dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
  //     }
  //   }
  // }, [selectedLocation]);

  const redirectToCampaignCreation = useCallback(() => {
    const redirection =
      localStorage.getItem('redirectToReviewRequest') &&
      localStorage.getItem('redirectToReviewRequest') !== undefined
        ? JSON.parse(localStorage.getItem('redirectToReviewRequest'))
        : false;
    if (redirection) {
      onBoardingDispatch({ type: 'START_ONBOARD_WIZARD' });
      onBoardingDispatch({ type: 'SET_CAMPAIGN_TYPE', payload: 'review request' });
      history.push('/app/setup/campaign-type');
    }
  }, []);

  const fetchPostsitesMetaData = useCallback(async () => {
    if (selectedLocation) {
      try {
        const results = await baseUrl.get(`/location/${selectedLocation}/postSitesMetaData`);
        if (results.status === 200) {
          if (results.data.length === 0) {
            setLoader(false);
          }
          setPostMetaDataContainer(results.data);
          if (results.data.length) {
            const connectedSitesMeta = results.data.filter((el) => el.isDeleted && el.isConnected);

            if (connectedSitesMeta.length) {
              redirectToCampaignCreation();
            }
            // await fetchCampaignList();
          }
        }
      } catch (err) {
        setLoader(false);
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  }, [selectedLocation, selectedBusiness, dispatch]);

  const fetchIsDeletedInPostMetaData = async (
    metaId,
    metaIsDeleted,
    postSitesId,
    key = 'isDeleted',
  ) => {
    const postBody = {
      [key]: metaIsDeleted,
      location: selectedLocation,
      postSites: postSitesId,
    };
    if (metaId) {
      try {
        await baseUrl.patch(`/postSitesMetaData/${metaId}`, { ...postBody });
      } catch (err) {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    } else {
      try {
        const result = await baseUrl.post(`/postSitesMetaData`, { ...postBody });
        if (result.status === 200) {
          setMetaId(result.data.id);
        }
      } catch (err) {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  };

  useEffect(() => {
    if (selectedLocation) {
      setLisitingData([]);
      setAllCardsListingContaienr([]);
      setPostMetaDataContainer([]);
      fetchPostsites(reviewSitesFilter);
      fetchPostsitesMetaData();
    }
  }, [selectedLocation, reviewSitesFilter, fetchPostsites, fetchPostsitesMetaData]);

  useEffect(() => {
    if (allCardsListingContainer.length > 0 || postMetaDataContainer.length > 0) {
      const listingContainer = Array.from(
        ConvertIntoListingCards(allCardsListingContainer, postMetaDataContainer, reviewSitesFilter),
      );
      setLisitingData([
        ...listingContainer.filter((item) => item.authRequired),
        ...listingContainer.filter((item) => !item.authRequired),
      ]);
    }
  }, [allCardsListingContainer, postMetaDataContainer]);

  useEffect(() => {
    if (listingData.length > 0) {
      setLoader(false);
    }
  }, [listingData]);

  const handleOpenListingPopup = () => setLisitngPopUp(true);

  const handleListingClick = (properties) => {
    const { listReviewsLink } = properties;
    if (listReviewsLink) {
      window.open(listReviewsLink, '_blank');
    } else {
      setShowAlertMessage('List link not found, are you in the right location?');
      setAlertMessageType('error');
      setShowAlert(true);
    }
  };

  const handleViewPostClick = (properties) => {
    const { postReviewLink } = properties;
    if (postReviewLink) {
      window.open(postReviewLink, '_blank');
    } else {
      setShowAlertMessage('Post link not found, are you in the right location?');
      setAlertMessageType('error');
      setShowAlert(true);
    }
  };

  const handleFetchExternalReviews = async () => {
    try {
      setShowAlertMessage('Fetching External Reviews... This could take some time');
      setAlertMessageType('success');
      setShowAlert(true);

      const results = await baseUrl.get(`/dashboard/callFetchExternalReviewsJob`);
      if (results.status === 200) {
        if (results.data.length === 0) {
          setLoader(false);
        }
      }
    } catch (err) {
      setLoader(false);
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const handleAuthSiteActivation = (e, properties) => {
    const { index, metaId, id } = properties;
    const listingDataCopy = Array.from(listingData);
    listingDataCopy[index].isConnected = e.target.checked;
    setLisitingData(Array.from(listingDataCopy));
    fetchIsDeletedInPostMetaData(metaId, e.target.checked, id, 'isConnected');
  };

  const handleCloseListingPopup = () => {
    setMetaId(null);
    setPostSiteId(null);
    setPostReviewLink('');
    setListReviewsLink('');
    setLisitingData([]);
    setAllCardsListingContaienr([]);
    setPostMetaDataContainer([]);
    setLisitngPopUp(false);
    fetchPostsites(reviewSitesFilter);
    fetchPostsitesMetaData();
  };

  const handleActivation = (e, properties) => {
    console.log('activating post site...');
    const { index, metaId, postReviewLink, listReviewsLink, id } = properties;
    const listingDataCopy = Array.from(listingData);
    listingDataCopy[index].metaIsDeleted = e.target.checked;
    setLisitingData(Array.from(listingDataCopy));
    fetchIsDeletedInPostMetaData(metaId, e.target.checked, id);
    if (e.target.checked) {
      setMetaId(metaId);
      setPostSiteId(id);
      setPostReviewLink(postReviewLink);
      setListReviewsLink(listReviewsLink);
      handleOpenListingPopup();
    }
  };

  const destroyeFacebookPages = async (id) => {
    const Location = selectedLocation;
    //if (facebookAccountId) {
    try {
      const result = await baseUrl(
        `destroyfacebookPages?location=${Location}&postSite=${id}&userId=${facebookAccountId}`,
      );
      if (result.status === 200) {
        // eslint-disable-next-line no-console
        console.log('Facebook Pages Deleted');
        handleCloseListingPopup();
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
    //}
  };

  const handleConnect = (connectedFlag, properties) => {
    const { authLink } = properties;
    console.log('Connecting.....');
    window.open(`${authLink}?businessId=${selectedBusiness}`, '_self');
    caches.delete();
  };

  const handleOtherSiteConnect = (connectedFlag, properties) => {
    const { metaId, postReviewLink, listReviewsLink, id } = properties;
    if (connectedFlag) {
      setMetaId(metaId);
      setPostSiteId(id);
      setPostReviewLink(postReviewLink);
      setListReviewsLink(listReviewsLink);
      setSiteId(properties.postSitesMetaData[0]?.siteId || '');
      handleOpenListingPopup();
    }
  };

  const handleOtherSiteDisconnect = (connectedFlag, properties) => {
    const { index, metaId, id } = properties;
    const listingDataCopy = Array.from(listingData);
    listingDataCopy[index].metaIsDeleted = connectedFlag;
    setLisitingData(Array.from(listingDataCopy));
    fetchIsDeletedInPostMetaData(metaId, connectedFlag, id);
  };

  const handleDisconnect = async (properties) => {
    const { id, name } = properties;

    if (name.toLowerCase() === 'google' || name.toLowerCase() === 'twitter') {
      try {
        const result = await baseUrl(
          `auth-site-disconnect?business=${selectedBusiness}&postSite=${id}`,
        );
        if (result.status === 200) {
          dispatch({ type: 'SET_GOOGLE_ACCOUNT_ID', payload: null });
        }
      } catch (err) {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
      fetchPostsitesMetaData();
    } else if (name.toLowerCase() === 'facebook') {
      destroyeFacebookPages(id);
    }
  };

  const cardsContainer = listingData.map((el, index) =>
    el.authRequired ? (
      <AuthCard
        key={index}
        {...el}
        index={index}
        showConnect
        handleActivation={handleAuthSiteActivation}
        handleListingClick={handleListingClick}
        handleConnect={handleConnect}
        showSwitch
        showListingLink={!el.name.toLowerCase().includes('twitter')}
        connectionBtnTitle={el.metaIsDeleted ? 'Disconnect' : 'Connect'}
        handleDisconnect={handleDisconnect}
      />
    ) : (
      <AuthCard
        key={index}
        {...el}
        handleListingClick={handleListingClick}
        handleViewPostClick={handleViewPostClick}
        btnColWidth={12}
        index={index}
        handleConnect={handleOtherSiteConnect}
        handleOtherSiteDisconnect={handleOtherSiteDisconnect}
        handleActivation={handleActivation}
        isShowReviewInfo={false}
        showListingLink={true}
        showConnect
        connectionBtnTitle={el.metaIsDeleted ? 'Deactivate' : 'Connect'}
        handleFetchExternalReviews={handleFetchExternalReviews}
      />
    ),
  );
  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const accessViewContainer =
    listingData.length > 0 ? (
      <Box marginTop={'2rem'}>
        <Grid item xs={12} md={12} sm={12} lg={12}>
          <CusotmAlert
            message={showAlertMessage}
            handleClose={handleCloseAlert}
            open={showAlert}
            messageType={alertMessageType}
          />

          <Box
            marginBottom=".8rem"
            flexDirection="column"
            display="flex"
            flex="1"
            gridRowGap={'.8rem'}
          >
            {cardsContainer}
          </Box>
        </Grid>
        <ReviewLinkPopup
          open={listingPopUp}
          handleClose={handleCloseListingPopup}
          metaId={metaDataId}
          metaInfo={allCardsListingContainer}
          postReviewLink={postReviewURL}
          listReviewsLink={listReviewsURL}
          postSiteId={postSiteId}
          siteId={siteId}
        />
      </Box>
    ) : (
      <NoDataFound>No data found !</NoDataFound>
    );

  return !loader ? <>{accessViewContainer}</> : <Loader />;
}

AccessAuthorizationsContainer.propTypes = {
  reviewSitesFilter: Proptypes.any,
};

export default React.memo(AccessAuthorizationsContainer);
