import React, { createContext, useReducer } from 'react';
import Proptypes from 'prop-types';
import CampaignReducer from '../Reducers/CampaignReducer';
import campaignState from '../state/CampaignState';

export const CampaignContext = createContext(campaignState);

const CampaignStore = ({ children }) => {
  const [campaignStates, campaignDispatch] = useReducer(CampaignReducer, campaignState);
  return (
    <CampaignContext.Provider value={{ campaignStates, campaignDispatch }}>
      {children}
    </CampaignContext.Provider>
  );
};

CampaignStore.propTypes = {
  children: Proptypes.node.isRequired,
};

export default CampaignStore;
