import React, { createContext, useReducer } from 'react';
import Proptypes from 'prop-types';
import PermissionState from '../state/PermissionState';
import PermissionReducer from '../Reducers/PermissionReducer';

export const PermissionContext = createContext(PermissionState);

const PermissionStore = ({ children }) => {
  const [PermissionData, PermissionDispatch] = useReducer(PermissionReducer, PermissionState);
  return (
    <PermissionContext.Provider value={{ PermissionData, PermissionDispatch }}>
      {children}
    </PermissionContext.Provider>
  );
};

PermissionStore.propTypes = {
  children: Proptypes.node.isRequired,
};

export default PermissionStore;
