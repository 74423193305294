import React, { useState, useEffect, useCallback } from 'react';
import styled, { ThemeProvider } from 'styled-components';
// import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Avatar } from '@material-ui/core';
import { imageURLCheck, convertIntoPhoneFormat } from '../utils/helper/helper';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import PhoneIcon from '@material-ui/icons/Phone';
import WebsiteIcon from '@material-ui/icons/Language';
import EmailIcon from '@material-ui/icons/Email';
import MessageIcon from '@material-ui/icons/Message';
import NavigationIcon from '@material-ui/icons/LocationOn';
import logo from '../assets/images/Reveo_Logo_Gray.svg';
import CompanyLogo from '../components/CompanyLogo/CompanyLogo';
import vCardsJS from 'vcards-js';
import { Helmet } from 'react-helmet';
import sailsIOClient from 'sails.io.js';
import socketIOClient from 'socket.io-client';

function convertToRgba(color) {
  if (!color) {
    color = '#30feaf';
  }
  if (color.slice(0, 3) === 'rgb') {
    return color.replace(')', ', 0.50)');
  } else {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, 0.50)`;
  }
}

const LargeHeader = styled.div`
  font-size: 30px;
  font-weight: 400;
  text-align: center;
`;
const LocationText = styled.div`
  color: #939393;
  font-size: 0.9rem;
  text-align: center;
`;

const ContactIcons = styled(Grid)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  grid-gap: 31px;
  column-gap: 20px;
  width: 300px;
  margin: 3rem 0;
`;

const ContactTag = styled.div`
  font-size: 0.8rem;
  text-decoration: none;
  color: ${(props) => props.theme.color};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledButton = styled.button`
  color: #fff;
  font-size: 1rem;
  border: none;
  font-weight: 600;
  padding: 0.7rem 2rem;
  border-radius: 3rem;
`;

const iconSize = '2.5rem';

function BusinessCard(props) {
  const [businessInfo, setBusinessInfo] = useState({});
  const { bid, uid } = props.match.params;
  let io;

  if (socketIOClient.sails) {
    io = socketIOClient;
  } else {
    io = sailsIOClient(socketIOClient);
  }

  io.sails.transports = ['websocket'];
  io.sails.url = process.env.REACT_APP_IMAGE_ENDPOINT;

  //console.log(process.env.REACT_APP_IMAGE_ENDPOINT);

  const fetchBusinessInfo = useCallback(async () => {
    try {
      // const result = await baseUrl.get(`business-card?id=${bid}&userId=${uid}`);
      // if (result.status === 200) {
      //   setBusinessInfo(result.data);
      // }
      await io.socket.get(
        `/api/business-card?id=${bid}&userId=${uid}`,
        function serverResponded(body, JWR) {
          if (JWR.statusCode === 200) {
            setBusinessInfo(body);
          } else {
            //console.log('failure...');
          }
        },
      );
    } catch (err) {
      console.log('NO GO');
      //dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  }, []);

  useEffect(() => {
    fetchBusinessInfo();
  }, [fetchBusinessInfo]);

  const socialLink = businessInfo && businessInfo.socialLinks;
  const contact = businessInfo && businessInfo.user?.contact;
  const businessCard = businessInfo && businessInfo.businessCard;
  const iconColor = (businessCard && businessCard.iconColor) || '#000';
  const textColor = (businessCard && businessCard.textColor) || '#000';
  const subtitleColor = (businessCard && convertToRgba(businessCard.textColor)) || '#000';
  const headerColor = (businessCard && businessCard.headerColor) || '#000';
  const backgroundColor = (businessCard && businessCard.backgroundColor) || '#fff';
  const isUserAvatar = businessCard && businessCard.isUserImageAvatar;

  const twitterContent =
    socialLink && socialLink.twitter && socialLink.twitter !== undefined ? (
      <ThemeProvider theme={{ color: subtitleColor }}>
        <ContactTag>
          <a href={businessInfo.socialLinks.twitter} target="_blank">
            <TwitterIcon style={{ color: iconColor, fontSize: iconSize }} />
          </a>
          Twitter
        </ContactTag>
      </ThemeProvider>
    ) : (
      ''
    );

  const instagramContent =
    socialLink && socialLink.instagram && socialLink.instagram !== undefined ? (
      <ThemeProvider theme={{ color: subtitleColor }}>
        <ContactTag>
          <a href={socialLink.instagram} target="_blank">
            <InstagramIcon style={{ color: iconColor, fontSize: iconSize }} />
          </a>{' '}
          Instagram
        </ContactTag>
      </ThemeProvider>
    ) : (
      ''
    );
  const facebookContent =
    socialLink && socialLink.facebook && socialLink.facebook !== undefined ? (
      <ThemeProvider theme={{ color: subtitleColor }}>
        <ContactTag>
          <a href={socialLink.facebook} target="_blank">
            <FacebookIcon style={{ color: iconColor, fontSize: iconSize }} />
          </a>{' '}
          Facebook
        </ContactTag>
      </ThemeProvider>
    ) : (
      ''
    );

  const youtubeContent =
    socialLink && socialLink.youtube && socialLink.youtube !== undefined ? (
      <ThemeProvider theme={{ color: subtitleColor }}>
        <ContactTag>
          <a href={socialLink.youtube} target="_blank">
            <YouTubeIcon style={{ color: iconColor, fontSize: iconSize }} />
          </a>{' '}
          YouTube
        </ContactTag>
      </ThemeProvider>
    ) : (
      ''
    );
  const linkdinContent =
    socialLink && socialLink.linkdin && socialLink.linkdin !== undefined ? (
      <ThemeProvider theme={{ color: subtitleColor }}>
        <ContactTag>
          <a href={socialLink.linkdin} target="_blank">
            <LinkedInIcon style={{ color: iconColor, fontSize: iconSize }} />
          </a>{' '}
          LinkedIn
        </ContactTag>
      </ThemeProvider>
    ) : (
      ''
    );
  const callMeContent =
    contact?.phoneNumber && contact?.phoneNumber && contact?.phoneNumber !== undefined ? (
      <ThemeProvider theme={{ color: subtitleColor }}>
        <ContactTag>
          <a href={`tel:${contact.phoneNumber}`}>
            <PhoneIcon style={{ color: iconColor, fontSize: iconSize }} />
          </a>{' '}
          Call
        </ContactTag>
      </ThemeProvider>
    ) : (
      ''
    );
  const websiteContent =
    businessInfo.website && businessInfo.website && businessInfo.website !== undefined ? (
      <ThemeProvider theme={{ color: subtitleColor }}>
        <ContactTag>
          <a href={businessInfo.website} target="_blank">
            <WebsiteIcon style={{ color: iconColor, fontSize: iconSize }} />
          </a>{' '}
          Website
        </ContactTag>
      </ThemeProvider>
    ) : (
      ''
    );
  const emailContent =
    contact?.email && contact?.email && contact?.email !== undefined ? (
      <ThemeProvider theme={{ color: subtitleColor }}>
        <ContactTag>
          <a href={`mailto:${contact.email}`}>
            <EmailIcon style={{ color: iconColor, fontSize: iconSize }} />
          </a>{' '}
          E-mail
        </ContactTag>
      </ThemeProvider>
    ) : (
      ''
    );

  const messageContent =
    contact?.phoneNumber && contact?.phoneNumber && contact?.phoneNumber !== undefined ? (
      <ThemeProvider theme={{ color: subtitleColor }}>
        <ContactTag style={{ color: convertToRgba(textColor) }}>
          <a href={`sms:${contact.phoneNumber}`}>
            <MessageIcon style={{ color: iconColor, fontSize: iconSize }} />
          </a>{' '}
          Message
        </ContactTag>
      </ThemeProvider>
    ) : (
      ''
    );

  const navigationContent =
    businessInfo.address && businessInfo.address && businessInfo.address !== undefined ? (
      <ThemeProvider theme={{ color: subtitleColor }}>
        <ContactTag>
          <a href={`https://google.com/maps/place/${businessInfo.address}`} target="_blank">
            <NavigationIcon style={{ color: iconColor, fontSize: iconSize }} />
          </a>{' '}
          Directions
        </ContactTag>
      </ThemeProvider>
    ) : (
      ''
    );

  function getImageTypeFromUrl(url) {
    // extract the file extension from the URL using a regular expression
    const extension = url.match(/\.(png|jpe?g|gif|webp)$/i);

    if (extension) {
      // return the lowercase extension without the leading period
      return extension[0].toLowerCase().substring(1);
    } else {
      // if no extension is found, return null
      return null;
    }
  }

  const backgroundStyle = businessInfo.businessCard?.isHeaderImage
    ? { backgroundImage: `url(${imageURLCheck(businessInfo.businessImage)})` }
    : { backgroundColor: headerColor };

  //const backgroundStyle = { backgroundColor: headerColor };

  function generateVCard() {
    // Create a new vCard
    const vCard = vCardsJS();

    const imageURL = imageURLCheck(businessInfo?.businessLogo);
    const imageType = getImageTypeFromUrl(businessInfo?.businessLogo);

    // Set the properties of the vCard
    vCard.firstName = businessInfo.user?.firstName;
    vCard.lastName = businessInfo.user?.lastName;
    vCard.organization = businessInfo?.businessName;
    //vCard.photo.attachFromUrl(imageURL, `'${imageType}'`);
    vCard.workPhone = contact?.phoneNumber;
    vCard.email = contact?.email;

    // Generate the vCard as a string
    const vCardString = vCard.getFormattedString();

    // Create a new blob with the vCard string as the content
    const blob = new Blob([vCardString], { type: 'text/vcard' });

    // Create a new anchor element with a download attribute
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.setAttribute('download', 'contact.vcf');

    // Programmatically click the anchor element to download the vCard file
    a.click();
  }

  return (
    <>
      <Helmet>
        <meta
          property="og:title"
          content={`${businessInfo.user?.firstName} ${businessInfo.user?.lastName} Contact Information`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={businessInfo.website} />
        <meta property="og:image" content={imageURLCheck(businessInfo?.businessLogo)} />
        <meta property="og:description" content="Contact me" />
        <title>{`${businessInfo.user?.firstName} ${businessInfo.user?.lastName} Contact Information`}</title>
        <meta
          name="description"
          content={`${businessInfo.user?.firstName} ${businessInfo.user?.lastName} Contact Information`}
        />
        <link id="favicon" href={imageURLCheck(businessInfo?.businessLogo)} />
        <link
          id="favicon"
          rel="apple-touch-icon"
          href={imageURLCheck(businessInfo?.businessLogo)}
        />
      </Helmet>
      <Grid container style={{ flex: 1, height: '100%' }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            height: '120px',
            backgroundPosition: '50% 50%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% auto',
            zIndex: 1,
            ...backgroundStyle,
          }}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            background: backgroundColor,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            height: '100%',
          }}
        >
          <Avatar
            style={{
              width: '120px',
              height: '120px',
              marginTop: '20px',
              border: `8px solid ${backgroundColor}`,
              zIndex: 2,
            }}
          >
            <img
              style={{ width: '100%' }}
              src={
                isUserAvatar
                  ? imageURLCheck(businessInfo.user?.avatar)
                  : imageURLCheck(businessInfo?.businessLogo)
              }
            />
          </Avatar>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <LargeHeader style={{ color: textColor }}>
              {businessInfo.user?.contact.fullName}
            </LargeHeader>
            <LocationText style={{ color: subtitleColor }}>{businessInfo.name}</LocationText>
            <ContactIcons>
              {callMeContent}
              {messageContent}
              {emailContent}
              {navigationContent}
              {facebookContent}
              {twitterContent}
              {linkdinContent}
              {youtubeContent}
              {instagramContent}
              {websiteContent}
            </ContactIcons>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              padding: '0 2rem',
            }}
          >
            <StyledButton
              style={{ color: backgroundColor, backgroundColor: iconColor }}
              onClick={generateVCard}
            >
              Add me to your contacts
            </StyledButton>
            {/* <LargeHeader>About</LargeHeader>
            <AboutText>{businessInfo.about}</AboutText> */}
          </Grid>

          <Grid
            item
            style={{
              position: 'absolute',
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '1.5rem',
              fontSize: '.6rem',
              alignItems: 'center',
              color: '#6a6a6a',
            }}
          >
            Powered By
            <ThemeProvider theme={{ width: '85px', height: 'auto', marginTop: '.2rem' }}>
              <CompanyLogo logo={logo} alt={logo} />
            </ThemeProvider>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default BusinessCard;
