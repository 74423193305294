import React, { useContext, useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import {
  Box,
  FormHelperText,
  Grid,
  ThemeProvider as MUIThemeProvider,
  InputLabel,
} from '@material-ui/core';
// import StyledTextField from '../../../../../../../GeneralInputField/GeneralInputField';
import defaultDropDownTheme from '../../../../../../../../themes/Dropdown/DefaultDropdownTheme';
import { OnBoardingContext } from '../../../../../../../../Stores/OnBoarding';
import dayFrequency from '../../../../../../../../utils/api/DayFrequency';
import reminders from '../../../../../../../../utils/api/reminders';
import reminderFrequency from '../../../../../../../../utils/api/reminderFrequency';
import TextAreaCampaignSettigs from '../../../../CampaignSettings/TextAreaCampaignSetting';
import StyledSelect from '../../../../../../../Common/StyledSelect';
import StyledMenuItem from '../../../../../../../Common/StyledMenuItem';
import StyledMultiSelect from '../../../../../../../Common/StyledMultiSelect';
import StyledFormControl from '../../../../../../../StyledFormControl';
import { GlobalContext } from '../../../../../../../../Stores/GlobalStateStore';

const StyledInputLabel = styled(InputLabel)`
  font-family: 'Montserrat' !important;
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: '24px' !important;
`;

const TabSettings = ({
  bodyRequest,
  setBodyRequest,
  settingsErrors,
  campaignLocation,
  setCampaignLocation,
}) => {
  const [userSelected, setUserSelected] = useState(bodyRequest.user || []);
  const { onBoardingData } = useContext(OnBoardingContext);
  const { state } = useContext(GlobalContext);
  const { locations } = state;

  const { userList } = onBoardingData;

  const selectedLocation = locations.filter((campaign) => campaign.id === campaignLocation)[0].id;

  useEffect(() => {
    setBodyRequest({
      ...bodyRequest,
      user: userSelected,
    });
  }, [userSelected]);

  useEffect(() => {
    setBodyRequest({
      ...bodyRequest,
      settings: {
        ...bodyRequest.settings,
        autoReminder: bodyRequest.settings.reminders > 0 ? true : false,
      },
    });
  }, [bodyRequest.settings.reminders]);

  const autoReviewReminder =
    bodyRequest.settings.reminders > 0 ? (
      <Grid item md={12} lg={12} sm={12} xs={12}>
        <Grid container spacing={4}>
          <Grid item md={12} lg={12} sm={12} xs={12}>
            <TextAreaCampaignSettigs
              title="Review reminder text"
              value={bodyRequest.settings.reminderText}
              setValue={(value) => {
                setBodyRequest({
                  ...bodyRequest,
                  settings: {
                    ...bodyRequest.settings,
                    reminderText: value,
                    autoReminder: true,
                  },
                });
              }}
              multiline
            />
            {settingsErrors?.reminderText && (
              <StyledFormControl error={!!settingsErrors?.reminderText}>
                <FormHelperText>{settingsErrors?.reminderText}</FormHelperText>
              </StyledFormControl>
            )}
          </Grid>
        </Grid>
      </Grid>
    ) : null;

  return (
    <Grid container spacing={4} style={{ maxHeight: '46vh', overflowY: 'auto' }}>
      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
        <ThemeProvider theme={defaultDropDownTheme}>
          <StyledFormControl>
            <StyledMultiSelect
              name="users"
              options={userList}
              label="Campaign Users *"
              value={userSelected}
              error={!!settingsErrors?.user}
              helpertext={settingsErrors?.user}
              onChange={(e) => setUserSelected(e.target.value)}
            />
          </StyledFormControl>
        </ThemeProvider>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
        <ThemeProvider theme={defaultDropDownTheme}>
          <StyledFormControl>
            <StyledInputLabel>Location *</StyledInputLabel>
            <StyledSelect
              options={Array.from(locations)}
              label="Location"
              value={bodyRequest.location}
              onChange={(e) =>
                setBodyRequest({
                  ...bodyRequest,
                  location: e.target.value,
                })
              }
            >
              {locations.map((location) => (
                <StyledMenuItem key={location.id} value={location.id}>
                  {location.nickName}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </StyledFormControl>
        </ThemeProvider>
      </Grid>
      <Grid item md={4} lg={4} sm={6} xs={12}>
        <ThemeProvider theme={defaultDropDownTheme}>
          <StyledFormControl>
            <StyledInputLabel>Attribution Period</StyledInputLabel>
            <StyledSelect
              options={Array.from(dayFrequency)}
              label="Attribution"
              value={bodyRequest.settings.attributionPeriod}
              onChange={(e) =>
                setBodyRequest({
                  ...bodyRequest,
                  settings: {
                    ...bodyRequest.settings,
                    attributionPeriod: e.target.value,
                  },
                })
              }
            >
              {dayFrequency.map((frequency) => (
                <StyledMenuItem key={frequency.value} value={frequency.value}>
                  {frequency.label}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </StyledFormControl>
        </ThemeProvider>
      </Grid>

      <Grid item md={4} lg={4} sm={6} xs={6}>
        <ThemeProvider theme={defaultDropDownTheme}>
          <StyledFormControl>
            <StyledInputLabel>Send Frequency</StyledInputLabel>
            <StyledSelect
              options={Array.from(dayFrequency)}
              label="Send Frequency"
              value={bodyRequest.settings.canResendAfter}
              onChange={(e) =>
                setBodyRequest({
                  ...bodyRequest,
                  settings: {
                    ...bodyRequest.settings,
                    canResendAfter: parseInt(e.target.value),
                  },
                })
              }
            >
              {dayFrequency.map((frequency) => (
                <StyledMenuItem key={frequency.value} value={frequency.value}>
                  {frequency.label}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </StyledFormControl>
        </ThemeProvider>
      </Grid>
      <Grid item md={4} lg={4} sm={6} xs={6}>
        <ThemeProvider theme={defaultDropDownTheme}>
          <StyledFormControl>
            <StyledInputLabel>Reminders</StyledInputLabel>
            <StyledSelect
              options={Array.from(reminders)}
              label="Select Reminder *"
              value={bodyRequest.settings.reminders}
              error={!!settingsErrors?.reminders}
              helpertext={settingsErrors?.reminders}
              onChange={(e) =>
                setBodyRequest({
                  ...bodyRequest,
                  settings: {
                    ...bodyRequest.settings,
                    reminders: e.target.value,
                  },
                })
              }
            >
              {reminders.map((frequency) => (
                <StyledMenuItem key={frequency.value} value={frequency.value}>
                  {frequency.label}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </StyledFormControl>
        </ThemeProvider>
      </Grid>
      <Grid item md={4} lg={4} sm={6} xs={6}>
        <ThemeProvider theme={defaultDropDownTheme}>
          <StyledFormControl>
            <StyledInputLabel>Reminder Frequency</StyledInputLabel>
            <StyledSelect
              options={Array.from(reminderFrequency)}
              label="Select Reminder Frequency *"
              value={bodyRequest.settings.reminderFrequency}
              error={!!settingsErrors?.reminderFrequency}
              helpertext={settingsErrors?.reminderFrequency}
              onChange={(e) =>
                setBodyRequest({
                  ...bodyRequest,
                  settings: {
                    ...bodyRequest.settings,
                    reminderFrequency: e.target.value,
                  },
                })
              }
            >
              {reminderFrequency.map((frequency) => (
                <StyledMenuItem key={frequency.value} value={frequency.value}>
                  {frequency.label}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </StyledFormControl>
        </ThemeProvider>
      </Grid>

      {/* <Grid item xs={6} md={4} lg={4} sm={6}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={'space-between'}
          marginRight={'-.75rem'}
        >
          <Box display="flex" flexDirection="column">
            <StyledCardTitle>Auto Review Reminder</StyledCardTitle>
            <StyledDescriptionText>
              Automatically send the customer a review reminder after 24 hours
            </StyledDescriptionText>
          </Box>
          <CustomizedSwitches
            checked={bodyRequest.settings.autoReminder}
            onChange={(e) =>
              setBodyRequest({
                ...bodyRequest,
                settings: {
                  ...bodyRequest.settings,
                  autoReminder: e.target.checked,
                },
              })
            }
          />
        </Box>
      </Grid> */}
      {autoReviewReminder}
      <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
        <Grid item xs={12} sm={12} md={3} lg={3} />
      </Box>
    </Grid>
  );
};

export default TabSettings;
