import React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import Proptypes from 'prop-types';
import MobilePreview from '../MobilePreview/MobilePreview';
import PoweredBy from '../PoweredBy/PoweredBy';
import EditableText from '../Editable/EditableText';
import EditableImage from '../Editable/EditableImage';

const StyledMobileViewContainer = styled(Grid)`
  position: absolute;
  height: 92%;
  margin: 0 !important;
  top: 40px;
  padding: 0 40px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const StyledContentContainer = styled(Grid)`
  min-height: calc(100% - 50px);
`;

function ThankyouView(props) {
  const { poweredBy, url } = props;

  const imageContainer = url ? <EditableImage {...props} isEditable={false} /> : null;
  return (
    <MobilePreview>
      <StyledMobileViewContainer container direction="row" justify="center" alignItems="center">
        <StyledContentContainer item xs={12} sm={12} lg={12} md={12}>
          <Grid container style={{ textAlign: 'center' }}>
            <Grid item xs={12} sm={12} lg={12} md={12}>
              {imageContainer}
            </Grid>
            <EditableText {...props} />
          </Grid>
        </StyledContentContainer>
        <PoweredBy isPoweredBy={poweredBy} />
      </StyledMobileViewContainer>
    </MobilePreview>
  );
}

ThankyouView.propTypes = {
  title: Proptypes.string,
  poweredBy: Proptypes.bool,
  url: Proptypes.string,
};

ThankyouView.defaultProps = {
  title: 'Thank you for your time, we look forward to serving you in the future',
  poweredBy: true,
  url: '',
};

export default ThankyouView;
