import React from "react";
import App from "./App"
import SocketStore, { SocketContext } from './Stores/SocketStore';

const AppWrapper = (props) => {
    return (
        <SocketStore>
            <App />
        </SocketStore>
    )
}

export default AppWrapper;