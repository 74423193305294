const dashboardState = {
  recentReviewContainer: [],
  ReviewSummaryContainer: [],
  userGoalContainer: {},
  allUserGoalContainer: [],
  userContainer: [],
  loader: false,
  reviewLoader: false,
  userloader: false,
  leaderBoard: [],
  avgRating: [],
};

export default dashboardState;
