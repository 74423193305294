import React, { useState, useEffect, useContext, useCallback } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import {
  Grid,
  FormHelperText,
  InputAdornment,
  Box,
  IconButton,
  ThemeProvider as MUIThemeProvider,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import baseUrl from '../../../../../utils/services/config';
import CusotmAlert from '../../../../CustomAlert/CustomAlert';
import {
  ConvertIntoTableFormat,
  ConvertIntoLocationDropDown,
  handleEmailError,
  handlePhoneError,
  getMultiSelectIds,
  errorChecker,
  checkIFUSFormat,
} from '../../../../../utils/helper/helper';
import { GlobalContext } from '../../../../../Stores/GlobalStateStore';
import NumberField from '../../../../NumberField/NumberField';
import ButtonLoader from '../../../../ButtonLoader/ButtonLoader';
import Loader from '../../../../Loader/Loader';
import { emailRegEx } from '../../../../../utils/helper/regEx';
import defaultDropDownTheme from '../../../../../themes/Dropdown/DefaultDropdownTheme';
import MultiSelect from '../../../../MultiSelect/MultiSelect';
import useInputFieldTheme from '../../../../../hooks/useInputFieldTheme';
import StyledFormControl from '../../../../StyledFormControl';
import useIconTheme from '../../../../../hooks/useIconTheme';
import TransperentButtonTheme from '../../../../../themes/Button/TransperentButtonTheme';
import { FormHeader } from '../../../../Forms/FormHeader';
import PrimaryButton from '../../../../Common/PrimaryButton';
import StyledTextField from '../../../../Common/StyledTextField';
import PageTitle from '../../../../PageTitle/PageTitle';
import StyledSelect from '../../../../Common/StyledSelect';
import StyledMenuItem from '../../../../Common/StyledMenuItem';
import useUpdateInviteData from '../../../../../hooks/useUpdateInviteData';
import useDebounce from '../../../../../hooks/useDebounce';
import useFuncDebounce from '../../../../../hooks/useFuncDebounce';
import StyledNumberField from '../../../../Common/StyledNumberField';
import StyledChip from '../../../../Chip/BasicChip';
import { SurveyContext } from '../../../../../Stores/SurveyStore';

const StyledInputLabel = styled(InputLabel)`
  font-family: 'Montserrat' !important;
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: '24px' !important;
`;

const StyledDashboard = styled(Grid)`
  padding: 1rem 2rem 2rem 2rem;
  min-height: 100%;
`;

const StyledCloseButton = styled(IconButton)`
  right: 10px;
  top: 10px;
`;

const wrapperStyles = { position: 'relative', padding: '1rem' };
const containerStyles = { margin: '.5rem ', height: 'auto' };
const footerStyles = { display: 'flex', justifyContent: 'flex-end', gap: '1rem' };

function AddClient(props) {
  const { currentContact, handleClose, handleSave } = props;
  const [showAlert, setAlert] = useState(false);
  const [loader, setLoader] = useState(false);
  const [viewLoader, setViewLoader] = useState(false);
  const [tel, setTel] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [phoneHelperText, setPhoneHelperText] = useState('Optional if email is provided');
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState('Optional if phone is provided');
  const [renderView, setRenderView] = useState(true);
  const [disabledBtn, setDisableBtn] = useState(false);
  const [emailLoader, setEmailLoader] = useState(false);
  const [phoneLoader, setPhoneLoader] = useState(false);
  const [unsubscribeMobile, setUnsubscribeMobile] = useState(false);
  const [unsubscribeEmail, setUnsubscribeEmail] = useState(false);
  const { state, dispatch } = useContext(GlobalContext);
  const { selectedBusiness, campaignLocation, selectedLoaction, locations, inviteData } = state;
  const { surveyDispatch } = useContext(SurveyContext);
  // const [locations, setLocations] = useState(Array.from(ConvertIntoTableFormat([])));
  const { register, handleSubmit, errors, setValue, control, watch, setError, clearError } =
    useForm({});
  const { inputTheme } = useInputFieldTheme();
  const { iconsTheme } = useIconTheme();

  const customerId = currentContact === undefined ? 'new' : currentContact;

  const [firstName, setFirstName] = useState(inviteData.firstName);
  const [lastName, setLastName] = useState(inviteData.lastName);
  const [emailAddress, setEmailAddress] = useState(inviteData.emailAddress);
  const [phoneNumber, setPhoneNumber] = useState(inviteData.phoneNumber);
  const [rawPhoneNumber, setRawPhoneNumber] = useState();
  const [location, setLocation] = useState(campaignLocation ? campaignLocation : selectedLoaction);

  useEffect(() => {
    dispatch({ type: 'SET_INVITE_DATA', payload: [] });
  }, []);

  function convertPhoneForDB(phoneNumber) {
    return phoneNumber.replace(/\s/g, '');
  }

  const validatePhone = () => {
    const phoneNo = encodeURIComponent(`+1${rawPhoneNumber}`);
    setDisableBtn(true);
    setPhoneLoader(true);
    baseUrl(`/client-exist?business=${selectedBusiness}&field=phoneNumber&value=${phoneNo}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0 && res.data[0].client !== parseInt(customerId, 10)) {
            setDisableBtn(false);
            setPhoneLoader(false);
            setPhoneError(true);
            setPhoneHelperText('Phone number is already in use');
          } else {
            setPhoneLoader(false);
            setPhoneError(false);
            setPhoneHelperText('Optional if email is provided');
          }
        } else {
          setDisableBtn(false);
          setPhoneLoader(false);
          setPhoneError(false);
        }
      })
      .catch((err) => {
        setDisableBtn(false);
        setPhoneLoader(false);
        //dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      });
  };

  const validateEmail = () => {
    if (!emailAddress) return;
    if (emailRegEx.test(emailAddress)) {
      setDisableBtn(true);
      setEmailLoader(true);
      baseUrl(`/client-exist?business=${selectedBusiness}&field=emailAddress&value=${emailAddress}`)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.length > 0 && res.data[0].client !== parseInt(customerId, 10)) {
              console.log('this email is already in use');
              setDisableBtn(false);
              setEmailLoader(false);
              setEmailError(true);
              setEmailHelperText('Email address is already taken');
            } else {
              setEmailHelperText('Optional if phone is provided');
              setEmailError(false);
            }
          } else {
            setDisableBtn(false);
            setEmailLoader(false);
            setEmailError(false);
          }
        })
        .catch((err) => {
          setDisableBtn(false);
          setEmailLoader(false);
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    } else {
      setEmailError(true);
      setEmailHelperText('Please enter a valid email address');
    }
  };

  const handleSaveContact = () => {
    const finalInviteData = {};
    if (firstName !== undefined) {
      finalInviteData.firstName = firstName;
    }
    if (lastName !== undefined) {
      finalInviteData.lastName = lastName;
    }
    if (emailAddress !== undefined) {
      finalInviteData.emailAddress = emailAddress;
    }
    if (phoneNumber !== undefined) {
      finalInviteData.phoneNumber = phoneNumber;
    }
    if (location !== undefined) {
      finalInviteData.location = location;
    }
    dispatch({ type: 'SET_INVITE_DATA', payload: finalInviteData });
    setLoader(true);
    const postBody = {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber ? `${phoneNumber}` : '',
      emailAddress: emailAddress,
      location: location,
      user: parseInt(localStorage.getItem('USER_ID'), 10),
      createdBy: parseInt(localStorage.getItem('USER_ID'), 10),
    };
    baseUrl
      .post(`/client`, { ...postBody })
      .then((res) => {
        if (res.status === 200) {
          const payload = {
            emailAddress: res.data.emailAddress,
            firstName: res.data.firstName,
            id: res.data.id,
            isDeleted: false,
            lastName: res.data.lastName,
            location_names: res.data.location[0]?.name,
            phoneNumber: res.data.phoneNumber ? convertPhoneForDB(res.data.phoneNumber) : '',
            unsubscribeEmail: false,
            unsubscribeMobile: false,
          };
          dispatch({ type: 'SET_INVITE_DATA', payload: payload });

          surveyDispatch({
            type: 'SET_SELECTED_CONTACTS',
            payload: [payload],
          });

          setLoader(false);
          setAlert(true);
          setTimeout(() => {
            handleClose();
          }, 500);
        }
      })
      .catch((err) => {
        setLoader(false);
        setDisableBtn(false);
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      });
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.formattedValue);
    setRawPhoneNumber(e.value);
  };

  const handleCloseAlert = () => {
    setAlert(false);
  };

  const handleCancel = () => {
    dispatch({ type: 'SET_INVITE_DATA', payload: [] });
    handleClose();
  };

  const emailLoaderContent = emailLoader ? (
    <InputAdornment position="start">
      <ButtonLoader />
    </InputAdornment>
  ) : (
    <></>
  );

  const endAdornmentContainer =
    unsubscribeEmail || unsubscribeMobile ? (
      <InputAdornment position="start">
        <ThemeProvider theme={{ color: iconsTheme.badgeColor, bgColor: iconsTheme.badgebgColor }}>
          <StyledChip label="Unsubscribed" />
        </ThemeProvider>
      </InputAdornment>
    ) : (
      <></>
    );

  const phoneLoaderContent = phoneLoader ? (
    <InputAdornment position="start">
      <ButtonLoader color="primary" />
    </InputAdornment>
  ) : (
    endAdornmentContainer
  );

  const isSaveDisabled = firstName?.length && (emailAddress?.length || phoneNumber?.length);

  return (
    <Box style={wrapperStyles}>
      <Box style={containerStyles}>
        <Box margin=".5rem 0 1.5rem 0">
          <PageTitle title={`New Contact`} />
        </Box>
        <Grid
          style={{
            display: 'grid',
            columnGap: '1.5rem',
            rowGap: '1.5rem',
            gridTemplateColumns: '1fr 1fr',
          }}
        >
          <StyledFormControl fullWidth>
            <StyledTextField
              label={'First Name'}
              helperText={'Optional'}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </StyledFormControl>
          <StyledFormControl fullWidth>
            <StyledTextField
              label={'Last Name'}
              helperText={'Optional'}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </StyledFormControl>
          <StyledFormControl fullWidth>
            <StyledNumberField
              name="phoneNumber"
              format="+1 ### ### ####"
              label="Contact Mobile *"
              onChange={(e) => handlePhoneNumberChange(e)}
              error={phoneError}
              helperText={phoneHelperText}
              defaultValue=""
              value={phoneNumber || ''}
              onBlur={validatePhone}
              InputProps={{
                endAdornment: phoneLoaderContent,
              }}
            />
          </StyledFormControl>
          <StyledFormControl fullWidth>
            <StyledTextField
              label={'Email Address'}
              value={emailAddress}
              helperText={emailHelperText}
              error={emailError}
              onChange={(e) => setEmailAddress(e.target.value)}
              onBlur={validateEmail}
              InputProps={{
                endAdornment: emailLoaderContent,
              }}
            />
          </StyledFormControl>
          <StyledFormControl fullWidth>
            <StyledInputLabel>Location</StyledInputLabel>
            <StyledSelect value={location} onChange={(e) => setLocation(e.target.value)}>
              {locations.map((location) => (
                <StyledMenuItem key={location.id} value={location.id}>
                  {location.nickName}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </StyledFormControl>
        </Grid>
      </Box>
      <Box style={footerStyles}>
        <PrimaryButton text onClick={() => handleCancel()}>
          Cancel
        </PrimaryButton>
        <PrimaryButton disabled={!isSaveDisabled} onClick={() => handleSaveContact()}>
          Save
        </PrimaryButton>
      </Box>
      <CusotmAlert
        open={showAlert}
        message={
          customerId !== 'new' ? 'Contact updated successfully' : 'Contact added successfully'
        }
        handleClose={handleCloseAlert}
      />
      {loader ? (
        <Box position="absolute">
          <Loader />
        </Box>
      ) : null}
    </Box>
  );
}

export default AddClient;
