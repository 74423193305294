import { useContext, useMemo, useState } from 'react';
import { GlobalContext } from '../Stores/GlobalStateStore';

const useTextColors = () => {
  const { state } = useContext(GlobalContext);
  const { themeContainer } = state;
  const [theme, setTheme] = useState({});
  useMemo(() => {
    setTheme({
      headingTextColor: themeContainer.headingTextColor,
      descriptionTextColor: themeContainer.descriptionTextColor,
      fontColor: themeContainer.fontColor,
      fontColor2: themeContainer.fontColor2,
      impTextColor: themeContainer.impTextColor,
      descriptionText2: themeContainer.descriptionText2,
      fontColor3: themeContainer.fontColor3,
      fontColor4: themeContainer.fontColor4,
      fontColor5: themeContainer.fontColor5,
      fontColor6: themeContainer.fontColor6,
      fontColor7: themeContainer.fontColor7,
      fontColor8: themeContainer.fontColor8,
      fontColor9: themeContainer.fontColor9,
      fontColor10: themeContainer.fontColor10,
      fontColor12: themeContainer.fontColor12,
      mobileTextContainerBG: themeContainer.mobileTextContainerBG,
      refreshIcon: themeContainer.checkboxColor,
    });
  }, [themeContainer]);
  return {
    textColors: theme,
  };
};
export default useTextColors;
