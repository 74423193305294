import styled from 'styled-components';
import { Badge } from '@material-ui/core';

const StyledBadge = styled(Badge)`
  & > span {
    background: ${(props) => props.theme.bgColor} !important;
    color: ${(props) => props.theme.color} !important;
    height: 10px;
    width: 10px;
    min-width: 10px;
    padding: 0;
  }
`;

export default StyledBadge;
