const months = [
  {
    label: 'JAN',
    value: 1,
  },
  {
    label: 'FEB',
    value: 2,
  },
  {
    label: 'MAR',
    value: 3,
  },
  {
    label: 'APR',
    value: 4,
  },
  {
    label: 'MAY',
    value: 5,
  },
  {
    label: 'JUN',
    value: 6,
  },
  {
    label: 'JUL',
    value: 7,
  },
  {
    label: 'AUG',
    value: 8,
  },
  {
    label: 'SEP',
    value: 9,
  },
  {
    label: 'OCT',
    value: 10,
  },
  {
    label: 'NOV',
    value: 11,
  },
  {
    label: 'DEC',
    value: 12,
  },
];

export default months;

export const monthsArray = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
