import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, MuiThemeProvider } from '@material-ui/core';
import styled from 'styled-components';
import TabMessage from './Components/TabMessage';
import TabSocial from './Components/TabSocial';
import TabSettings from './Components/TabSettings';
import TabOptions from './Components/TabOptions';
import baseUrl from '../../../../../../utils/services/config';
import useTabsTheme from '../../../../../../hooks/useTabsTheme';
import AntTabs from '../../../../../StyledTabsContainer/StyledTabsContainer';
import AntTab from '../../../../../StyledTabs/StyledTabs';
import { GlobalContext } from '../../../../../../Stores/GlobalStateStore';
import { CampaignContext } from '../../../../../../Stores/CamapignStore';
import {
  errorChecker,
  getMetaImage,
  getSelectedPortalInfo,
  convertIntoCampaignCards,
} from '../../../../../../utils/helper/helper';
import portalJson from '../../../../../../utils/api/portalJson';
import { SurveyContext } from '../../../../../../Stores/SurveyStore';
import TabEmail from '../Survey/components/TabEmail/TabEmail';
import PrimaryButton from '../../../../../Common/PrimaryButton';
import TemplateTitleEdit from '../../../TemplateTitleEdit';
import ContainerLoader from '../../../ContainerLoader';

const containerStyles = {
  margin: '1rem .5rem',
  rowGap: '1rem',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
};
const footerStyles = { display: 'flex', justifyContent: 'space-between' };

const StyledContainer = styled.div`
  height: 55vh;
  @media screen and (max-height: 900px) {
    height: 65vh;
  }
`;

const Request = ({ campaignId, handleClose }) => {
  const { tabsTheme } = useTabsTheme();
  //SET ACTIVE TAB FOR DEVELOPMENT
  const [tabValue, setTabValue] = useState(0);
  const { state, dispatch } = useContext(GlobalContext);
  const { selectedLoaction, selectedBusiness, locationInfo, businessInfo } = state;
  const [businessLogo, setBusinessLogo] = useState({});
  const [templateSelected, setTemplateSelected] = useState({});
  const { campaignDispatch } = useContext(CampaignContext);
  const [campaignLocation, setCampaignLocation] = useState(selectedLoaction);
  const [templates, setTemplates] = useState([]);
  const [selectedPostsites, setSelectedPostsites] = useState([]);

  const [bodyRequest, setBodyRequest] = useState({
    userLink: {
      titleLink: '',
      link: '',
    },
    reviewPortalId: null,
    name: '',
    message:
      'Hi @customerName, it was a pleasure to serve you at @business. Please leave us a review by clicking the link below. Thanks! @link',
    emailSubject: 'It was a pleasure to serve you at @business.',
    uploadedFile: [],
    settings: {
      thankyouText: 'Thank you for your time, we look forward to serving you again in the future.',
      autoReminder: false,
      attributionPeriod: 0,
      canResendAfter: 0,
      allowTextFeedback: false,
      reminders: 0,
      reminderFrequency: 0,
      //emailTitle: '',
      reminderText:
        'Thank you so much for your business! We just wanted to remind you to please take a minute to leave feedback about your experience for others to see by clicking the link in the previous text message link above. Thanks!',
      subType: 'simple',
      campaignUser: 'none',
      userImage: {},
    },
    user: [],

    location: campaignLocation,
    postSites: [],
    campaignType: 'review request',
    type: 'review request',
    title: 'Where would you like to leave feedback?',
  });

  const { surveyData, surveyDispatch } = useContext(SurveyContext);

  const [messageErrors, setmessageErrors] = useState({});
  const [emailErrors, setEmailErrors] = useState({});
  const [socialErrors, setsocialErrors] = useState({});
  const [settingsErrors, setsettingsErrors] = useState({});
  const [buttonIsDisabled, setbuttonIsDisabled] = useState(true);

  const [isLoading, setisLoading] = useState(false);

  const messageValidation = (isOnlyValidation = false) => {
    const { message, settings } = bodyRequest;
    const errors = {};

    if (message.length === 0) {
      errors.message = 'Main message is a required field';
    }

    if (settings.thankyouText.length === 0) {
      errors.thankyouText = 'Thank message is a required field';
    }

    if (!isOnlyValidation) {
      setmessageErrors(errors);
    }

    return Object.keys(errors).length === 0;
  };
  const emailValidation = (isOnlyValidation = false) => {
    const { emailSubject } = bodyRequest;
    const errors = {};

    if (emailSubject.length === 0) {
      errors.emailSubject = 'Email subject is a required field';
    }
    if (!isOnlyValidation) {
      setEmailErrors(errors);
    }
    return Object.keys(errors).length === 0;
  };

  const socialValidation = (isOnlyValidation = false) => {
    const { postSites, title } = bodyRequest;

    const errors = {};
    if (title.length === 0) {
      errors.title = 'required field';
    }

    if (postSites && postSites.length === 0) {
      errors.postSites = 'required review website';
    }

    if (!isOnlyValidation) {
      setsocialErrors(errors);
    }

    return Object.keys(errors).length === 0;
  };
  const settingsValidation = (isOnlyValidation = false) => {
    const {
      name,
      user,
      autoReminder,
      location,

      settings: { reminders, reminderFrequency, reminderText },
    } = bodyRequest;
    const errors = {};
    if (user.length === 0) {
      errors.user = 'required users';
    }

    if (name.length === 0) {
      errors.templateName = 'required template name ';
    }
    // if (emailTitle.length === 0) {
    //   errors.emailTitle = 'required email title ';
    // }
    if (location.length === 0) {
      errors.location = 'please select a location';
    }
    if (autoReminder) {
      if (reminders && reminders.length === 0) {
        errors.reminders = 'required reminders ';
      }
      if (reminderFrequency && reminderFrequency.length === 0) {
        errors.reminderFrequency = 'required reminderFrequency ';
      }
      if (reminderText && reminderText.length === 0) {
        errors.reminderText = 'required reminderText ';
      }
    }
    if (!isOnlyValidation) {
      setsettingsErrors(errors);
    }

    return Object.keys(errors).length === 0;
  };

  const validationTabForm = (tabValue, newValue) => {
    switch (tabValue) {
      case 0:
        // eslint-disable-next-line no-case-declarations
        const isValidMessage = messageValidation();

        if (isValidMessage) {
          setTabValue(newValue);
        }

        break;
      case 1:
        // eslint-disable-next-line no-case-declarations
        const isValidEmail = emailValidation();

        if (isValidEmail) {
          setTabValue(newValue);
        }

        break;
      case 2:
        // eslint-disable-next-line no-case-declarations
        const isValidSocial = socialValidation();

        if (isValidSocial) {
          setTabValue(newValue);
        }
        break;
      case 3:
        // eslint-disable-next-line no-case-declarations
        const isValidSettings = settingsValidation();

        if (isValidSettings) {
          setTabValue(newValue);
        }
        break;
      default:
        setTabValue(newValue);
        break;
    }
  };
  const handleTabChange = (e, newValue) => {
    validationTabForm(tabValue, newValue);
  };

  const getCampaignById = async (selectedCampaign) => {
    setisLoading(true);
    try {
      const result = await baseUrl.get(`/campaign/${selectedCampaign}`, {});
      if (result?.data) {
        const {
          name,
          message,
          emailSubject,
          uploadedFile,
          settings,
          user,
          location,
          reviewPortal,
          subType,
        } = result.data;

        // baseUrl
        //   .get(`/user/${user.id}`)
        //   .then((res) => {
        //    res
        //   })
        //   .catch((err) => {
        //     dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        //     handleLogout();
        //   });

        const reviewWithPostSites = reviewPortal.find((item) => {
          const screens = Object.values(item.meta[subType].screens);
          return screens.some((screen) => screen.hasOwnProperty('postSites'));
        });

        const postSites = reviewWithPostSites
          ? reviewWithPostSites.meta[subType].screens.find((screen) =>
              screen.hasOwnProperty('postSites'),
            ).postSites
          : [];

        const leaveFeedbackText = reviewWithPostSites
          ? reviewWithPostSites.meta[subType].screens.find((screen) =>
              screen.hasOwnProperty('postSites'),
            ).title
          : '';

        setSelectedPostsites(postSites);

        const payload = {
          name,
          message,
          emailSubject,
          uploadedFile,
          location: location[0].id,
          reviewPortalId: reviewPortal.find((item) => item.name === subType).id,
          postSites: postSites,
          title: leaveFeedbackText,
        };

        setBodyRequest({
          ...bodyRequest,
          ...payload,
          settings: {
            ...bodyRequest.settings,
            ...settings,
          },
          user: user && user.map((item) => item.id),
        });
        //console.log('selected postsites: ' + reviewPortal && selectedPostSites);
        setisLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCampaignDeletion = async (campaignId) => {
    console.log('time to delete a campaign');
    try {
      const postBody = {
        isDeleted: true,
      };
      const result = await baseUrl.patch(`/campaign/${campaignId}`, { ...postBody });
      if (result.status === 200) {
        setisLoading(false);
        handleClose();
      }
    } catch (err) {
      console.log('campaign deletion error fired.');
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const fetchNotifications = useCallback(async () => {
    try {
      if (selectedLoaction) {
        dispatch({ type: 'SET_NOTIFICATION_LOADER', payload: true });
        const result = await baseUrl(
          `/getnotifications/${localStorage.getItem('USER_ID')}/${selectedLoaction}`,
        );
        if (result.status === 200) {
          dispatch({ type: 'SET_NOTIFICATION_CONTAINER', payload: result.data });
          dispatch({ type: 'SET_NOTIFICATION_LOADER', payload: false });
        }
      }
    } catch (err) {
      dispatch({ type: 'SET_NOTIFICATION_LOADER', payload: false });
      console.log('fetch notifications error fired.');

      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  }, [selectedLoaction, dispatch]);

  const reviewsrequest = async (postBody) => {
    const result = await baseUrl.post(`/reviewsrequest`, { ...postBody });
    if (result.status === 200) {
      surveyDispatch({
        type: 'SET_SELECTED_CONTACTS',
        payload: [],
      });
      handleCampaignDeletion(postBody.campaign);

      fetchNotifications();
      surveyDispatch({
        type: 'SET_STATUS_MESSAGE',
        payload: {
          redirectTo: '/app/feedback/review-request',
          statusMessage: surveyData.selectedContacts.length
            ? `${surveyData.selectedContacts.length} Message Sent`
            : 'Message Sent',
        },
      });
    }
  };
  const uploadPreview = (clients, id) => {
    baseUrl
      .post(
        `group/client/upload-preview?business=${selectedBusiness}&location=${selectedLoaction}`,
        clients,
      )
      .then(({ data }) => {
        const postBody = {
          client: data.map((client) => client.id),
          user: parseInt(localStorage.getItem('USER_ID'), 10),
          campaign: id,
          location: selectedLoaction,
        };
        reviewsrequest(postBody);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const sendCampign = async (id) => {
    try {
      let postBody;
      if (surveyData.selectedContacts.length > 1) {
        uploadPreview(surveyData.selectedContacts, id);
      } else {
        postBody = {
          client: surveyData.selectedContacts[0].id,
          location: selectedLoaction,
          user: parseInt(localStorage.getItem('USER_ID'), 10),
          campaign: id,
        };
        reviewsrequest(postBody);
      }
    } catch (err) {
      console.log('send campaign error fired.');

      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const createCampaign = async (payload, removeCampaign, isClone) => {
    const {
      attributionPeriod = 0,
      reminders = 0,
      reminderFrequency = 0,
      autoReminder = false,
    } = payload.settings;

    const newPayload = {
      ...payload,
      uploadedFile: payload.uploadedFile.map((item) => item.id),
      location: payload.location,
      name: isClone ? payload.name + ' [copy]' : payload.name,
      settings: {
        ...payload.settings,
        // eslint-disable-next-line radix
        attributionPeriod: attributionPeriod.length ? parseInt(attributionPeriod) : 0,
        // eslint-disable-next-line no-nested-ternary, radix
        reminders: autoReminder ? (reminders.length > 0 ? parseInt(reminders) : 0) : 0,
        // eslint-disable-next-line no-nested-ternary, radix
        reminderFrequency: autoReminder
          ? reminderFrequency.length > 0
            ? parseInt(reminderFrequency)
            : 0
          : 0,
      },
      canResendAfter: parseInt(payload.settings.canResendAfter),
    };

    const response = await baseUrl.post('/campaign', { ...newPayload });
    if (response.status === 200) {
      if (removeCampaign) {
        sendCampign(response.data.id);
      } else {
        if (response.data.location[0].id === selectedLoaction) {
          const finalPayload = {
            ...newPayload,
            id: response.data.id,
            uploadedFile: response.data.uploadedFile,
          };
          campaignDispatch({
            type: 'UPDATE_CAMPAIGN_CONTAINER',
            payload: Array.from(convertIntoCampaignCards([finalPayload])),
          });
        }
        setisLoading(false);
        handleClose();
        //campaignDispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    } else {
      setisLoading(false);
    }
  };

  const handleCreateReviewportal = async (removeCampaign = false, isClone = false) => {
    // console.log('Create campaign');
    // console.log('isClone:', isClone);

    const isValidMessage = messageValidation();
    const isValidEmail = emailValidation();
    const isValidSocial = socialValidation();
    const isValidSettings = settingsValidation();
    if (isValidMessage && isValidSocial && isValidSettings && isValidEmail) {
      setisLoading(true);
      const existingPortal = getSelectedPortalInfo(
        { ...portalJson(businessLogo) },
        bodyRequest.settings.subType,
        selectedBusiness,
      );
      const modifiedScreens = existingPortal.meta[bodyRequest.settings.subType].screens.map(
        (item) => {
          if (item.screen === 'positiveFeedback') {
            return {
              ...item,
              postSites: bodyRequest.postSites,
              title: bodyRequest.title,
            };
          }
          return item;
        },
      );

      existingPortal.meta[bodyRequest.settings.subType].screens = modifiedScreens;

      const result = await baseUrl.post(`/reviewportal`, {
        ...existingPortal,
      });
      if (result.status === 200) {
        let invitationMessageCopy = bodyRequest?.message;
        if (bodyRequest?.userLink.titleLink.length && bodyRequest?.userLink.link.length) {
          invitationMessageCopy += `
          <a href="${bodyRequest.userLink.link}">${bodyRequest.userLink.titleLink}</a>
          `;
        }
        delete bodyRequest.userLink;
        const postBody = {
          ...bodyRequest,
          campaignType: 'review request',
          subType: bodyRequest.settings.subType,
          settings: { ...bodyRequest.settings },
          message: invitationMessageCopy,
          reviewPortal: [result.data.id],
          isActive: true,
        };

        createCampaign(postBody, removeCampaign, isClone);

        setTimeout(() => {}, 1000);
      }
    }
  };

  const handleUpdateCampaign = async () => {
    try {
      setisLoading(true);
      const existingPortal = getSelectedPortalInfo(
        { ...portalJson(businessLogo) },
        bodyRequest.settings.subType,
        selectedBusiness,
      );
      const modifiedScreens = existingPortal.meta[bodyRequest.settings.subType].screens.map(
        (item) => {
          if (item.screen === 'postitiveFeedback') {
            return {
              ...item,
              postSites: bodyRequest.postSites,
              title: bodyRequest.title,
            };
          }
          return item;
        },
      );

      existingPortal.meta[bodyRequest.settings.subType].screens = modifiedScreens;

      await baseUrl.put(`/reviewportal/${bodyRequest.reviewPortalId}`, {
        ...existingPortal,
      });

      if (bodyRequest?.userLink?.titleLink.length) {
        bodyRequest.message += `
          <a href="${bodyRequest.userLink.link}">${bodyRequest.userLink.titleLink}</a>
          `;
      }
      delete bodyRequest.userLink;
      const uploadedFileNew =
        bodyRequest.uploadedFile.length > 0 ? bodyRequest.uploadedFile.map((item) => item.id) : [];

      const postBody = {
        name: bodyRequest.name,
        message: bodyRequest.message,
        settings: { ...bodyRequest.settings },
        emailSubject: bodyRequest.emailSubject,
        uploadedFile: uploadedFileNew,
        postSites: bodyRequest.postSites,
        title: bodyRequest.title,
        user: bodyRequest.user,
        location: bodyRequest.location,
        canResendAfter: bodyRequest.settings.canResendAfter,
        subType: bodyRequest.settings.subType,
      };

      const result = await baseUrl.patch(`/campaign/${campaignId}`, { ...postBody });
      if (result.status === 200) {
        setisLoading(false);
        handleClose();
        const updatedCampaign = {
          ...result.data,
          currentLocation: selectedLoaction,
        };
        campaignDispatch({
          type: 'UPDATE_CAMPAIGN_ITEM',
          payload: Array.from(convertIntoCampaignCards([updatedCampaign])),
        });
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const isDisabled = () => {
    return setbuttonIsDisabled(
      !(
        messageValidation(true) &&
        emailValidation(true) &&
        socialValidation(true) &&
        settingsValidation(true)
      ),
    );
  };

  useEffect(() => {
    isDisabled();
  }, [bodyRequest]);

  useEffect(() => {
    if (campaignId) {
      getCampaignById(campaignId);
    }
    getTemplates();
  }, [campaignId]);

  const fetchBusinessLogo = useCallback(async () => {
    if (selectedLoaction) {
      try {
        const result = await baseUrl(`location/${selectedLoaction}/uploadedFile`);
        if (result.status === 200) {
          setBusinessLogo(getMetaImage(result.data, 'business_logo'));
        }
      } catch (err) {
        console.log('fetch business logo error fired.');

        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  }, [selectedLoaction]);

  const handleCancel = () => {
    handleClose();
  };

  const changeSubject = (value) => {
    setBodyRequest({
      ...bodyRequest,
      emailSubject: value,
    });
  };

  useEffect(() => {
    fetchBusinessLogo();
  }, [fetchBusinessLogo]);

  const handleTemplateNameChange = (value) => {
    setBodyRequest({
      ...bodyRequest,
      name: value,
    });
  };

  const getTemplates = () => {
    //setIsLoading(true);
    baseUrl
      .get(`/emailTemplates?where={"business":${state.selectedBusiness},"isDeleted":0}`)
      .then((res) => {
        setTimeout(() => {
          //setIsLoading(false);
          if (res.data.length) {
            setTemplates(res.data);
            const defaultTemplate = res.data.filter(
              (template) => template.type === 'review request' && template.isDefault,
            );
            setTemplateSelected(defaultTemplate[0]);
          }
        }, 500);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        //setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading && <ContainerLoader hideContent />}
      <StyledContainer style={containerStyles}>
        <Box>
          <TemplateTitleEdit
            campaign={bodyRequest}
            type={'review request'}
            handleChange={handleTemplateNameChange}
            errors={settingsErrors}
            setErrors={setsettingsErrors}
          />
          <MuiThemeProvider theme={tabsTheme}>
            <AntTabs value={tabValue} onChange={handleTabChange} aria-label="ant">
              <AntTab label="Message" />
              <AntTab label="Email" />
              <AntTab label="Social" />
              <AntTab label="Settings" />
              <AntTab label="Options" />
            </AntTabs>
          </MuiThemeProvider>
        </Box>
        {tabValue === 0 && (
          <TabMessage
            bodyRequest={bodyRequest}
            setBodyRequest={setBodyRequest}
            messageErrors={messageErrors}
            businessLogo={businessLogo}
            isLoading={isLoading}
            setTemplateSelected={setTemplateSelected}
          />
        )}
        {tabValue === 1 && (
          <TabEmail
            templateSelected={templateSelected}
            setTemplateSelected={setTemplateSelected}
            bodyRequest={bodyRequest}
            setBodyRequest={setBodyRequest}
            changeSubject={changeSubject}
            subjectValue={bodyRequest.emailSubject}
            emailErrors={emailErrors}
            type={'review request'}
            templates={templates}
          />
        )}
        {tabValue === 2 && (
          <TabSocial
            bodyRequest={bodyRequest}
            setBodyRequest={setBodyRequest}
            socialErrors={socialErrors}
            selectedPostsites={selectedPostsites}
            setSelectedPostsites={setSelectedPostsites}
            locationInfo={locationInfo}
            businessInfo={businessInfo}
          />
        )}
        {tabValue === 3 && (
          <TabSettings
            bodyRequest={bodyRequest}
            setBodyRequest={setBodyRequest}
            settingsErrors={settingsErrors}
            campaignLocation={campaignLocation}
            setCampaignLocation={setCampaignLocation}
          />
        )}

        {tabValue === 4 && <TabOptions bodyRequest={bodyRequest} setBodyRequest={setBodyRequest} />}
      </StyledContainer>
      <Box style={footerStyles}>
        {campaignId && (
          <PrimaryButton outlined onClick={() => handleCreateReviewportal(false, true)}>
            Clone
          </PrimaryButton>
        )}
        <div style={{ display: 'flex', columnGap: '.5rem', marginLeft: 'auto' }}>
          <PrimaryButton text onClick={handleCancel}>
            Cancel
          </PrimaryButton>
          {campaignId ? (
            <PrimaryButton disabled={buttonIsDisabled} onClick={handleUpdateCampaign}>
              Update
            </PrimaryButton>
          ) : (
            <PrimaryButton
              disabled={buttonIsDisabled}
              onClick={() => {
                handleCreateReviewportal(false, false);
              }}
            >
              Save
            </PrimaryButton>
          )}
        </div>
      </Box>
    </>
  );
};

export default Request;
