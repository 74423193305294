import React, { useContext } from 'react';
import Proptypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import laptopPreviewImage from '../../assets/images/laptop_preview.png';

const StyledLaptopPreview = styled.div`
position: relative;
height: 700px;
width: 700px;
  // -webkit-transform-origin: 0 0;
  // transform-origin: 0 0;
  // -webkit-transform: scale(0.8) translate(0%);
  // transform: scale(0.8) translate(0%);
  // min-width: 600px;
  // width: 100%;
  // border-radius: 28px;
  // border-style: solid;
  // border-color: ${(props) => props.theme.color};
  // border-width: 24px 0 42px 24px;
  // background-color: ${(props) => props.theme.bgColor};
  // border-bottom-right-radius: 0;
  // border-top-right-radius: 0;

  // &:after {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   box-shadow: -10px 12px 12px -6px;
  //   width: 1200px;
  //   height: 30px;
  //   top: 106%;
  //   margin: 6px 0 0 -110px;
  //   background: linear-gradient(
  //     90deg,
  //     rgba(177, 177, 177, 1) 0%,
  //     rgba(240, 240, 240, 1) 35%,
  //     rgba(187, 195, 196, 1) 100%
  //   );
  //   border-radius: 10px;
  }
`;

function LaptopPreview(props) {
  const { children } = props;
  const { state } = useContext(GlobalContext);
  const { themeContainer } = state;

  return (
    <ThemeProvider
      theme={{ color: themeContainer.laptopColor, bgColor: themeContainer.laptopBgColor }}
    >
      <StyledLaptopPreview>
        <img
          src={laptopPreviewImage}
          alt="mobile preview"
          style={{ width: '100%', height: '100%', position: 'absolute' }}
        />
        <div style={{ overflow: 'auto !important', position: 'relative', height: '86%' }}>
          {children}
        </div>
      </StyledLaptopPreview>
    </ThemeProvider>
  );
}

LaptopPreview.propTypes = {
  children: Proptypes.node.isRequired,
};

export default LaptopPreview;
