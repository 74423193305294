import React, { useEffect } from 'react';
import styled from 'styled-components';
import { makeStyles, Button } from '@material-ui/core';
import RevLogo from '../../../../../assets/images/Reveo_Logo_New.svg';
import GoogleLogo from '../../../../../assets/images/review_icons/google.svg';
import Star from '../../../../../assets/images/review_icons/star_new.svg';
import PrimaryButton from '../../../../Common/PrimaryButton';

const StyledPostImage = styled.img`
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 280px;
  object-fit: cover;
  position: relative;
`;

const StyledPrimaryButton = styled(Button)`
  padding: 0.5rem 0 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  text-transform: Capitalize !important;
  font-family: 'Montserrat' !important;
`;

const ColorOverlay = styled.div`
  position: absolute;
  inset: 0;
  content: '';
  pointer-events: none;
`;

export const PostImage = (props) => {
  const { bodyRequest, setBodyRequest, showGallery, selectedColor } = props;

  const useStyles = makeStyles(() => ({
    reveoLogo: {
      position: 'absolute',
      width: '100px',
      bottom: '20px',
      right: '20px',
      opacity: 0.5,
      pointerEvents: 'none',
    },
    googleLogo: {
      height: '60px',
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      top: '35px',
      pointerEvents: 'none',
    },
    mainQuote: {
      color: 'white',
      fontStyle: 'italic',
      fontWeight: 600,
      lineHeight: 1.2,
      textAlign: 'center',
      fontSize: '1.8rem',
      width: '95%',
      position: 'absolute',
      top: '135px',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      pointerEvents: 'none',
    },
    reviewerNameDate: {
      color: 'white',
      fontStyle: 'italic',
      fontWeight: 500,
      top: '230px',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      opacity: 0.6,
      position: 'absolute',
      maxWidth: '90px',
      textAlign: 'center',
      pointerEvents: 'none',
    },
    starContainer: {
      position: 'absolute',
      top: '186px',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      columnGap: '.4rem',
      pointerEvents: 'none',
    },
    star: {
      width: 30,
    },
  }));

  const classes = useStyles();

  let imagePreview;

  if (bodyRequest.isPreview) {
    imagePreview = (
      <StyledPostImage src={`${process.env.REACT_APP_IMAGE_ENDPOINT}${bodyRequest.imagePath}`} />
    );
  } else {
    const textColor =
      bodyRequest && bodyRequest.colors[selectedColor].isDarkText ? '#000000' : '#ffffff';

    const mainQuote = '"This is just a placeholder short quote from the review"';
    const reviewerName = 'John Doe 5.9.24';
    imagePreview = (
      <>
        <StyledPostImage
          src={`${process.env.REACT_APP_IMAGE_ENDPOINT}${bodyRequest.backgroundImage.url}`}
          // src={bodyRequest.backgroundImage}
          onClick={() => showGallery()}
        />
        <ColorOverlay
          style={{
            backgroundColor: `rgb(${bodyRequest.colors[selectedColor].bgColor.r} ${bodyRequest.colors[selectedColor].bgColor.g} ${bodyRequest.colors[selectedColor].bgColor.b} / ${bodyRequest.colors[selectedColor].bgColor.a})`,
          }}
        />

        <img src={GoogleLogo} className={classes.googleLogo} alt="google-logo" />
        <div className={classes.mainQuote} style={{ color: textColor }}>
          {mainQuote}
        </div>
        <div className={classes.starContainer}>
          {Array.from({ length: 5 }).map((_, i) => (
            <img key={i} src={Star} className={classes.star} alt="star" />
          ))}
        </div>
        <div className={classes.reviewerNameDate} style={{ color: textColor }}>
          {reviewerName}
        </div>
        <img
          src={RevLogo}
          className={classes.reveoLogo}
          style={{
            filter: bodyRequest.colors[selectedColor].isDarkText
              ? 'brightness(0)'
              : 'brightness(100)',
          }}
          alt="logo"
        />
        <StyledPrimaryButton text onClick={() => showGallery()}>
          Change background image
        </StyledPrimaryButton>
      </>
    );
  }

  return <div style={{ position: 'relative', height: '280px' }}>{imagePreview}</div>;
};
export default PostImage;
