import Axios from 'axios';
import { useCallback, useContext } from 'react';
import { GlobalContext } from '../Stores/GlobalStateStore';
import { errorChecker, selectAllContacts } from '../utils/helper/helper';
import baseUrl from '../utils/services/config';

const useFetch = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { selectedBusiness, groupContainer } = state;
  const fetchGroupName = useCallback(async () => {
    if (selectedBusiness) {
      try {
        const result = await baseUrl(`/groups?business=${selectedBusiness}`);
        if (result.status === 200) {
          dispatch({ type: 'SET_GROUP_NAME_CONTAINER', payload: Array.from(result.data) });
        }
      } catch (err) {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  }, [selectedBusiness, dispatch]);

  const fetchSelectedGroupClients = useCallback(async () => {
    try {
      const promises = groupContainer.selectedGroupName.map((element) =>
        baseUrl.get(`/group/${element}/clients`),
      );
      const dataContainer = [];
      const result = await Axios.all(promises);
      if (result.length) {
        result.forEach((element) => {
          element.data.list.forEach((el) => {
            dataContainer.push(el);
          });
        });
      }
      dispatch({ type: 'SET_EXISTING_GROUP_CONTAINER', payload: Array.from(dataContainer) });
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  }, [groupContainer.selectedGroupName, dispatch]);

  const clientPatch = useCallback(
    async (clientId, clientPatchInfo) => {
      try {
        let postBody = { ...clientPatchInfo };
        postBody = {
          ...postBody,
          phoneNumber: postBody.phoneNumber ? `+1${postBody.phoneNumber}` : '',
        };
        await baseUrl.patch(`/client/${clientId}`, { ...postBody });
      } catch (err) {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    },
    [dispatch],
  );

  const fetchSelectedGroupClient = useCallback(
    async (clientId) => {
      try {
        dispatch({ type: 'SHOW_GROUP_LOADER' });
        const result = await baseUrl.get(`/group/${clientId}/clients?offset=300`);
        if (result.status === 200) {
          dispatch({
            type: 'SET_GROUP_LIST_VIEW_CONTAINER',
            payload: Array.from(result.data.list),
          });
          dispatch({
            type: 'SET_GROUP_LIST_VIEW_SELECTED_CONTACTS',
            payload: Array.from(selectAllContacts(result.data.list)),
          });
        }
      } catch (err) {
        dispatch({ type: 'HIDE_GROUP_LOADER' });
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    },
    [dispatch],
  );

  // API call for fetching send-invite most recent list
  const fetchRecentSendInviteList = useCallback(async () => {
    if (selectedBusiness) {
      try {
        dispatch({ type: 'SHOW_SEND_INVITE_LOADER' });
        const result = await baseUrl.get(`/group/client/search?business=${selectedBusiness}`);
        if (result.status === 200) {
          dispatch({ type: 'HIDE_SEND_INVITE_LOADER' });
          dispatch({ type: 'SET_SEND_INVITE_LIST_CONTAINER', payload: result.data });
        }
      } catch (err) {
        dispatch({ type: 'HIDE_SEND_INVITE_LOADER' });
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  }, [selectedBusiness, dispatch]);

  // API call for fetching send-invite searched list
  const fetchSearchedSendInviteList = useCallback(
    async (text) => {
      if (selectedBusiness) {
        try {
          dispatch({ type: 'SHOW_SEND_INVITE_LOADER' });
          const result = await baseUrl.get(
            `/group/client/search?business=${selectedBusiness}&text=${text}`,
          );
          if (result.status === 200) {
            dispatch({ type: 'HIDE_SEND_INVITE_LOADER' });
            dispatch({ type: 'SET_SEND_INVITE_LIST_CONTAINER', payload: result.data });
          }
        } catch (err) {
          dispatch({ type: 'HIDE_SEND_INVITE_LOADER' });
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        }
      }
    },
    [selectedBusiness, dispatch],
  );

  return {
    fetchGroupName,
    fetchSelectedGroupClients,
    clientPatch,
    fetchSelectedGroupClient,
    fetchRecentSendInviteList,
    fetchSearchedSendInviteList,
  };
};
export default useFetch;
