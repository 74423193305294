import React, { useEffect, useContext, useState } from 'react';
import Proptypes from 'prop-types';
import {
  createStyles,
  Grid,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
  ThemeProvider as MUIThemeProvider,
} from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import ArrowIcon from '@material-ui/icons/NearMe';
import EditIcon from '@material-ui/icons/Edit';
import baseUrl from '../../utils/services/config';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import {
  errorChecker,
  convertIntoCampaignCards,
  getExtraParameter,
  getExtraParameterValue,
} from '../../utils/helper/helper';
import PopupContainer from './PopupContainer';
import CampaignCard from '../../components/CampaignCard/CampaignCard';
import Loader from '../../components/Loader/Loader';
import CusotmAlert from '../../components/CustomAlert/CustomAlert';
import NoDataFound from '../../components/NoDataFound/NoDataFound';
import { CampaignContext } from '../../Stores/CamapignStore';
import { OnBoardingContext } from '../../Stores/OnBoarding';
import OnBoardingPopUp from '../OnBorad/OnBoardingPopUp';
import useModal from '../../hooks/useModal';
import useAlert from '../../hooks/useAlert';
import CancellationPopup from '../../components/Popup/CancellationPopup/CancellationPopup';
import useServerSidePagination from '../../hooks/useServerPagination';
import CustomButton from '../../components/CustomButton/CustomButton';
import usePopupTheme from '../../hooks/usePopupTheme';
import useButtonTheme from '../../hooks/useButtonTheme';
import ButtonLoader from '../../components/ButtonLoader/ButtonLoader';
import SendEmailTemplatePopover from '../../components/Popover/SendEmailTemplatePopover/SendEmailTemplatePopover';
import AddEmailTemplatePopover from '../../components/Popover/AddEmailTemplatePopover/AddEmailTemplatePopover';
import StyledTrashIcon from '../../components/Icons/TrashIcon';
import CreateReviewRequestPopover from '../../components/Popover/CreateReviewRequestPopover/CreateReviewRequestPopover';
import CreateSurveyPopover from '../../components/Popover/CreateSurveyPopover/CreateSurveyPopover';
import CreateMessagePopover from '../../components/Popover/CreateMessagePopover/CreateMessagePopover';
import CreateSocialMediaPopover from '../../components/Popover/CreateSocialMediaPopopover/CreateSocialMediaPopover';

const StyledCampaignContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 'calc(100vh - 120px)',
      '& > div:nth-of-type(1)': {
        display: 'none',
      },
      [theme.breakpoints.up('xl')]: {
        height: '834px',
      },
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
);

function getSteps() {
  return ['Step1'];
}

function CampaignContainer(props) {
  const { open, handleClose, handleOpenPopup, campaignStatusFilter } = props;
  const { state, dispatch } = useContext(GlobalContext);
  const steps = getSteps();
  const classes = useStyles();
  const { campaignStates, campaignDispatch } = useContext(CampaignContext);
  const { onBoardingDispatch } = useContext(OnBoardingContext);
  const { campaignContainer } = campaignStates;
  const { selectedLoaction, themeContainer, roles } = state;
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [showAlert, setAlert] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedId, setSelectedId] = useState(null);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const campaignDeletionPopup = useModal();
  const feedbackAlert = useAlert();
  const { buttonTheme } = useButtonTheme();

  const [templateIdSelected, setTemplateIdSelected] = useState(null);
  const deletionPopupEmailTemplate = useModal();
  const newEmailTemplate = useModal();
  const emailTemplateAlert = useAlert();
  const sendEmailTemplate = useModal();

  const reviewRequestCreationPopup = useModal();
  const surveyCreationPopup = useModal();
  const messageCreationPopup = useModal();
  const socialMediaCreationPopup = useModal();

  const { paginationInfo, tableData, loader, handlePageChange, handleInitTable } =
    useServerSidePagination({
      apiEndpoint: `/campaign/list?includeTemplates=1&status=1`,
      depends: selectedLoaction,
      extraParameter: 'location',
      extraParameterDepends: selectedLoaction,
      originalData: campaignContainer,
      secondParameter: getExtraParameter(campaignStatusFilter),
      secondParameterDepends:
        campaignStatusFilter === 'all' ? null : getExtraParameterValue(campaignStatusFilter),
    });

  const { count, page } = paginationInfo;
  const { popUpTheme } = usePopupTheme();

  // Effect to handle state init of the campaign container

  useEffect(() => {
    campaignDispatch({
      type: 'SET_CAMPAIGN_CONTAINER',
      payload: Array.from(convertIntoCampaignCards(tableData)),
    });
    if (tableData?.length === 1) {
      onBoardingDispatch({ type: 'SHOW_INVITE_POPUP' });
    }
  }, [tableData, campaignDispatch]);

  useEffect(() => {
    if (open === false) {
      setSelectedCampaign(null);
    }
  }, [open]);

  const handleEditCampaign = (id) => {
    setSelectedCampaign(id);
    handleOpenPopup();
  };

  const handleCloseAlert = () => {
    setAlert(false);
  };

  const handleSwitchChange = (e) => {
    const id = parseInt(e.target.value, 10);
    const { checked } = e.target;
    campaignDispatch({
      type: 'UPDATE_CAMPAIGN_CONTAINER_IS_ACTIVE',
      payload: { isActive: checked, id },
    });
    const postBody = {
      isActive: checked,
    };
    baseUrl.patch(`/campaign/${id}`, { ...postBody });
  };

  const handleSubmit = () => {
    handleClose();
    handleInitTable();
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleDeleteCampaign = (id) => {
    const { handleShowModal } = campaignDeletionPopup;
    setSelectedId(id);
    handleShowModal();
  };

  const handleDeleteEmailTemplate = (id) => {
    setSelectedId(id);
    deletionPopupEmailTemplate.handleShowModal();
  };

  const handleEmailTemplateDeletionPopup = async () => {
    const { handleHideShowModal } = deletionPopupEmailTemplate;
    const { handleSetMessage, handleSetMessageType, handleShow } = emailTemplateAlert;
    campaignDispatch({
      type: 'REMOVE_CAMPAIGN',
      payload: selectedId,
    });
    try {
      const postBody = {
        isDeleted: true,
      };
      const result = await baseUrl.patch(`/emailTemplates/${selectedId}`, { ...postBody });
      if (result.status === 200) {
        handleSetMessageType('success');
        handleSetMessage('template deleted successfully');
        handleHideShowModal();
        handleShow(true);
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };
  const handleSentTest = (id) => {
    setTemplateIdSelected(id);
    sendEmailTemplate.handleShowModal();
  };
  const handleEditEmailTemplate = (id) => {
    setTemplateIdSelected(id);
    newEmailTemplate.handleShowModal();
  };

  const handleCampaignDeletionPopup = async () => {
    const { handleHideShowModal } = campaignDeletionPopup;
    const { handleSetMessage, handleSetMessageType, handleShow } = feedbackAlert;
    campaignDispatch({
      type: 'REMOVE_CAMPAIGN',
      payload: selectedId,
    });

    try {
      setDeleteLoader(true);
      const postBody = {
        isDeleted: true,
      };
      const result = await baseUrl.patch(`/campaign/${selectedId}`, { ...postBody });
      if (result.status === 200) {
        handleSetMessageType('success');
        handleSetMessage('Campaign deleted successfully');
        handleHideShowModal();
        handleShow(true);
        setDeleteLoader(false);
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      setDeleteLoader(false);
    }
  };

  const handleCloseCampaignDeletionPopup = () => {
    const { handleHideShowModal } = campaignDeletionPopup;
    handleHideShowModal();
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <PopupContainer handleNext={handleSubmit} selectedCampaign={selectedCampaign} />;
      default:
        return 'Unknown step';
    }
  }

  const handleReviewRequestCreationPopup = (id) => {
    setSelectedCampaign(id);
    reviewRequestCreationPopup.handleShowModal();
  };
  const handleSurveyCreationPopup = (id) => {
    setSelectedCampaign(id);
    surveyCreationPopup.handleShowModal();
  };
  const handleMessageCreationPopup = (id) => {
    setSelectedCampaign(id);
    messageCreationPopup.handleShowModal();
  };

  const handleSocialMediaCreationPopup = (id) => {
    setSelectedCampaign(id);
    socialMediaCreationPopup.handleShowModal();
  };
  const showButtonLoader = campaignContainer.length && loader ? <ButtonLoader /> : null;

  const EmailTemplateOptions = [
    {
      text: 'Edit',
      onPress: handleEditEmailTemplate,
      Icon: EditIcon,
    },
    {
      text: 'Send Test',
      onPress: handleSentTest,
      Icon: ArrowIcon,
    },
    {
      text: 'Delete',
      onPress: handleDeleteEmailTemplate,
      Icon: StyledTrashIcon,
    },
  ];
  const DefaultOptions = [
    {
      text: 'Send Test',
      onPress: handleSentTest,
      Icon: ArrowIcon,
    },
  ];
  const SurveyOptions = [
    {
      text: 'Edit Template',
      onPress: handleSurveyCreationPopup,
      Icon: EditIcon,
    },

    {
      text: 'Delete Template',
      onPress: handleDeleteCampaign,
      Icon: StyledTrashIcon,
    },
  ];
  const ReviewRequestOptions = [
    {
      text: 'Edit Template',
      onPress: handleReviewRequestCreationPopup,
      Icon: EditIcon,
    },

    {
      text: 'Delete Template',
      onPress: handleDeleteCampaign,
      Icon: StyledTrashIcon,
    },
  ];
  const MessageOptions = [
    {
      text: 'Edit Template',
      onPress: handleMessageCreationPopup,
      Icon: EditIcon,
    },

    {
      text: 'Delete Template',
      onPress: handleDeleteCampaign,
      Icon: StyledTrashIcon,
    },
  ];
  const CampaignOptions = [
    {
      text: 'Clone Template',
      onPress: handleEditCampaign,
      Icon: EditIcon,
    },

    {
      text: 'Delete Template',
      onPress: handleDeleteCampaign,
      Icon: StyledTrashIcon,
    },
  ];
  const SocialMediaOptions = [
    {
      text: 'Edit Template',
      onPress: handleSocialMediaCreationPopup,
      Icon: EditIcon,
    },

    {
      text: 'Delete Template',
      onPress: handleDeleteCampaign,
      Icon: StyledTrashIcon,
    },
  ];

  const optionsOnPress = (campaignType, isDefault) => {
    switch (campaignType) {
      case 'email template':
        if (isDefault) {
          return DefaultOptions;
        }
        return EmailTemplateOptions;
      case 'review request':
        return ReviewRequestOptions;
      case 'survey':
        return SurveyOptions;
      case 'message':
        return MessageOptions;
      case 'social post':
        return SocialMediaOptions;
      default:
        return CampaignOptions;
    }
  };

  const getEditType = (campaignType, id) => {
    switch (campaignType) {
      case 'social post':
        handleSocialMediaCreationPopup(id);
        break;
      case 'message':
        handleMessageCreationPopup(id);
        break;
      case 'review request':
        handleReviewRequestCreationPopup(id);
        break;
      case 'survey':
        handleSurveyCreationPopup(id);
        break;
      case 'email template':
        handleEditEmailTemplate(id);
        break;
    }
  };

  const campaignsCards = campaignContainer.map((item) => {
    return (
      <ThemeProvider
        key={item.id}
        theme={{
          titleColor: themeContainer.cardTitleColor,
          descriptionColor: themeContainer.cardDescriptionColor,
          cardBg: themeContainer.cardBgColor,
        }}
      >
        <CampaignCard
          {...item}
          campaignType={item.campaignType}
          isDefault={item.isDefault}
          handleSwitchChange={item.campaignType !== 'email template' ? handleSwitchChange : null}
          optionsOnPress={optionsOnPress(item.campaignType, item.isDefault)}
          handleClick={(campaignType) => getEditType(campaignType, item.id)}
          roles={roles}
        />
      </ThemeProvider>
    );
  });

  const campaignViewContainer =
    campaignContainer.length > 0 ? campaignsCards : <NoDataFound>No data found !</NoDataFound>;

  return !campaignContainer.length && loader ? (
    <Loader />
  ) : (
    <div style={{ maxHeight: 'calc(100vh - 12rem)', overflow: 'auto', marginTop: '1.5rem' }}>
      <StyledCampaignContainer>{campaignViewContainer}</StyledCampaignContainer>

      <CreateReviewRequestPopover
        campaignCreationPopup={reviewRequestCreationPopup}
        selectedId={selectedCampaign}
      />
      <CreateSurveyPopover
        surveyCreationPopup={surveyCreationPopup}
        selectedId={selectedCampaign}
      />
      <CreateMessagePopover
        messageCreationPopup={messageCreationPopup}
        selectedId={selectedCampaign}
      />
      <CreateSocialMediaPopover
        socialMediaCreationPopup={socialMediaCreationPopup}
        selectedId={selectedCampaign}
      />
      <CusotmAlert
        handleClose={handleCloseAlert}
        message="Please select a location"
        open={showAlert}
        messageType="error"
        postionVertical="bottom"
        positionHorizontal="center"
      />
      <ThemeProvider theme={{ padding: '0', overflowY: 'hidden' }}>
        <MUIThemeProvider theme={popUpTheme}>
          <OnBoardingPopUp
            show={open}
            handleClose={handleClose}
            handleNext={activeStep === steps.length - 1 ? handleSubmit : handleNext}
            activeStep={activeStep}
          >
            <div className={classes.root}>
              <Stepper alternativeLabel activeStep={activeStep}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {getStepContent(activeStep)}
            </div>
          </OnBoardingPopUp>
        </MUIThemeProvider>
      </ThemeProvider>
      <MUIThemeProvider theme={popUpTheme}>
        <CancellationPopup
          show={campaignDeletionPopup.showModal}
          showDeletionPopUp={handleCampaignDeletionPopup}
          handleClose={handleCloseCampaignDeletionPopup}
          deleteLoader={deleteLoader}
        />
      </MUIThemeProvider>

      <CusotmAlert
        message={feedbackAlert.message}
        handleClose={feedbackAlert.handleClose}
        open={feedbackAlert.show}
        messageType={feedbackAlert.messageType}
      />

      <SendEmailTemplatePopover
        handleClose={sendEmailTemplate.handleHideShowModal}
        open={sendEmailTemplate.showModal}
        anchorEl={sendEmailTemplate.anchorEl}
        templateIdSelected={templateIdSelected}
        templateAlert={emailTemplateAlert}
      />
      <MUIThemeProvider theme={popUpTheme}>
        <CancellationPopup
          show={deletionPopupEmailTemplate.showModal}
          showDeletionPopUp={handleEmailTemplateDeletionPopup}
          handleClose={() => deletionPopupEmailTemplate.handleHideShowModal()}
        />
      </MUIThemeProvider>
      <AddEmailTemplatePopover
        handleClose={newEmailTemplate.handleHideShowModal}
        open={newEmailTemplate.showModal}
        //open={true}
        anchorEl={newEmailTemplate.anchorEl}
        templateIdSelected={templateIdSelected}
        getTemplates={handleInitTable}
      />
    </div>
  );
}

CampaignContainer.propTypes = {
  open: Proptypes.bool.isRequired,
  handleClose: Proptypes.func.isRequired,
  handleOpenPopup: Proptypes.func.isRequired,
  campaignStatusFilter: Proptypes.string.isRequired,
};

export default CampaignContainer;
