const PermissionReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PERMISSION_DATA':
      return {
        ...state,
        permissionData: { ...action.payload },
      };

    case 'SET_ROLE_PERMISSION': {
      const stateCopy = { ...state };
      stateCopy.rolePermission = Array.from(action.payload);
      return {
        ...stateCopy,
      };
    }

    case 'SET_DEFAULT_PERMISSIONS': {
      const stateCopy = { ...state };
      stateCopy.defaultPermissions = Array.from(action.payload);
      return {
        ...stateCopy,
      };
    }

    case 'SET_TABLE_DATA': {
      const stateCopy = { ...state };
      stateCopy.tableData = Array.from(action.payload);
      return {
        ...stateCopy,
      };
    }
    case 'SET_POST_DATA': {
      const stateCopy = { ...state };
      stateCopy.postDataContainer = Array.from(action.payload);
      return {
        ...stateCopy,
      };
    }
    default:
      return state;
  }
};

export default PermissionReducer;
