import React, { useState, useContext, useCallback } from 'react';
import { makeStyles, Grid, Box, TextareaAutosize, Button, Avatar, Paper } from '@material-ui/core';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import baseUrl from '../../utils/services/config';
import styled from 'styled-components';
import { SketchPicker } from 'react-color';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import CustomSwitch from '../../components/CustomSwitch/CustomSwitch';
import ExpandIcon from 'mdi-react/ChevronDownIcon';
import useAlert from '../../hooks/useAlert';
import { errorChecker } from '../../utils/helper/helper';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useEffect } from 'react';
import CusotmAlert from '../../components/CustomAlert/CustomAlert';
import { NoData } from '../../components/NoData/NoData';
import ChatOutlineIcon from 'mdi-react/ChatOutlineIcon';
import PrimaryButton from '../../components/Common/PrimaryButton';
import Uploader from '../../components/Uploader/Uploader';
import { imageURLCheck } from '../../utils/helper/helper';
import TemplateTitleEdit from '../../components/Popover/SendInvitePopover/TemplateTitleEdit';
import { SmartphoneOutlined } from '@material-ui/icons';

const WidgetSetting = styled.div`
  font-weight: 600;
  min-width: 50%;
  font-size: 14px;
`;
const WidgetSubSetting = styled.div`
  min-width: 50%;
  font-size: 12px;
  font-weight: 600;
`;

const StyledTextarea = styled(TextareaAutosize)`
  border: 2px solid #d3d3d3;
  border-radius: 6px;
  padding: 0.5rem;
  font-size: 14px;
  font-family: 'Montserrat' !important;
  resize: none;
  width: 100%;
`;

const StyledDirectionButton = styled(Button)`
  text-transform: capitalize !important;
  font-weight: 600 !important;
`;

const StyledInput = styled.div`
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  max-width: 500px;
`;

function WebchatContainer() {
  const { state, dispatch } = useContext(GlobalContext);
  const { businessInfo } = state;
  const [webChatInfo, setWebChatInfo] =
    useState(
      businessInfo && Array.isArray(businessInfo.webchats)
        ? businessInfo.webchats[0]
        : businessInfo.webchats,
    ) || null;
  const [renderView, setRenderView] = useState(Boolean(webChatInfo));
  const [webchatCode, setWebchatCode] = useState();
  const [copied, setCopied] = useState(false);
  const [isEnabled, setIsEnabled] = useState(renderView ? webChatInfo.isEnabled : true);
  const [isLiveChatEnabled, setIsLiveChatEnabled] = useState(
    renderView ? webChatInfo.isLiveChatEnabled : false,
  );
  const [isTextUsEnabled, setIsTextUsEnabled] = useState(
    renderView ? webChatInfo.isTextUsEnabled : false,
  );
  const [pagePosition, setPagePosition] = useState(renderView ? webChatInfo.pagePosition : 'right');
  const [greeting, setGreeting] = useState(renderView ? webChatInfo.greeting : null);

  const [offlineAutoResponder, setOfflineAutoResponder] = useState(
    renderView ? webChatInfo.offlineAutoResponder : null,
  );
  const [offlineAutoResponderOnline, setOfflineAutoResponderOnline] = useState(
    renderView ? webChatInfo.offlineAutoResponderOnline : null,
  );

  const [liveChatAutoResponder, setLiveChatAutoResponder] = useState(
    renderView ? webChatInfo.liveChatAutoResponder : null,
  );
  const [liveChatAutoResponderOffline, setLiveChatAutoResponderOffline] = useState(
    renderView ? webChatInfo.liveChatAutoResponderOffline : null,
  );

  const [liveChatIcon, setLiveChatIcon] = useState(renderView ? webChatInfo.liveChatIcon : null);
  const [textUsIcon, setTextUsIcon] = useState(renderView ? webChatInfo.textUsIcon : null);

  const [avatarImage] = useState(renderView ? webChatInfo.avatarImage : null);
  const [webchat, setWebchat] = useState({
    name: renderView ? webChatInfo.website : businessInfo.name,
  });
  const [brandColor, setBrandColor] = useState(renderView ? webChatInfo.brandColor : '#30feaf');
  const [textColor, setTextColor] = useState(renderView ? webChatInfo.textColor : '#000');
  const [displayTextColorPicker, setDisplayTextColorPicker] = useState(false);
  const [displayBrandColorPicker, setDisplayBrandColorPicker] = useState(false);
  const [isWebchatExpanded, setIsWebchatExpanded] = useState(true);
  const webchatAlert = useAlert();
  const [imagesContainer, setImagesContainer] = useState(renderView ? webChatInfo.avatarImage : '');

  const handleImagesContainer = (name, imgId, data) => {
    let imagesContainerCopy = { ...imagesContainer };
    imagesContainerCopy = data.url;
    setImagesContainer(imagesContainerCopy);
    console.log(imagesContainerCopy);
  };

  const handleSaveChanges = async () => {
    const webchatChanges2 = {
      website: webchat.name,
      isEnabled: isEnabled,
      isLiveChatEnabled: isLiveChatEnabled,
      isTextUsEnabled: isTextUsEnabled,
      brandColor: brandColor,
      greeting: greeting,
      textUsIcon: textUsIcon,
      offlineAutoResponder: offlineAutoResponder,
      offlineAutoResponderOnline: offlineAutoResponderOnline,
      liveChatIcon: liveChatIcon,
      liveChatAutoResponder: liveChatAutoResponder,
      liveChatAutoResponderOffline: liveChatAutoResponderOffline,
      textColor: textColor,
      pagePosition: pagePosition,
      avatarImage: imagesContainer,
    };

    const postBody = {
      businessId: parseInt(localStorage.getItem('SELECTED_BUSINESS')),
      updatedWebchat: webchatChanges2,
    };
    const { handleSetMessage, handleSetMessageType, handleShow } = webchatAlert;

    if (!isLiveChatEnabled && !isTextUsEnabled) {
      handleSetMessageType('error');
      handleSetMessage(
        'Please enable Live Chat or Text Us, or the webchat will not be shown on the website.',
      );
      handleShow(true);
    } else {
      try {
        const result = await baseUrl.post(`business/updateWebchat`, postBody);
        if (result.status === 200) {
          setWebChatInfo(result.data);
          setRenderView(true);
          handleSetMessageType('success');
          handleSetMessage(result.data.message);
          handleShow(true);
        }
      } catch (err) {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  };

  const handleFirstCreate = async () => {
    const webchatCreate = {
      website: webchat.name,
      isEnabled: isEnabled,
      isLiveChatEnabled: isLiveChatEnabled,
      isTextUsEnabled: isTextUsEnabled,
      brandColor: brandColor,
      greeting: greeting,
      textUsIcon: textUsIcon,
      offlineAutoResponder: offlineAutoResponder,
      offlineAutoResponderOnline: offlineAutoResponderOnline,
      liveChatIcon: liveChatIcon,
      liveChatAutoResponder: liveChatAutoResponder,
      liveChatAutoResponderOffline: liveChatAutoResponderOffline,
      textColor: textColor,
      pagePosition: pagePosition,
      avatarImage: imagesContainer,
    };

    const { handleSetMessage, handleSetMessageType, handleShow } = webchatAlert;
    try {
      const postBody = {
        businessId: localStorage.getItem('SELECTED_BUSINESS'),
        updatedWebchat: webchatCreate,
      };
      const result = await baseUrl.post(`business/updateWebchat`, postBody);
      if (result.status === 200) {
        handleSetMessageType('success');
        handleSetMessage(result.data.message);
        handleShow(true);
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  useEffect(() => {
    setWebchatCode(
      `<script defer id="reveo-chat-bot" data-uuid="${businessInfo.uuid}" src="https://reveo.com/js/reveo-chatbot.js"></script>`,
    );
  }, [
    avatarImage,
    brandColor,
    textColor,
    greeting,
    isLiveChatEnabled,
    pagePosition,
    businessInfo.uuid,
  ]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCopied(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [copied]);

  const useStyles = makeStyles(() => ({
    '@keyframes fadeIn': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
    root: {
      paddingTop: '2rem',
    },
    webChat: {
      border: '1px solid #959595',
      borderRadius: '10px',
      padding: '1.5rem',
      backgroundColor: 'white',
    },
    chatButtons: {
      display: 'flex',
      columnGap: '.5rem',
    },
    chatButton: {
      width: 'auto',
      height: '50px',
      backgroundColor: brandColor,
      borderRadius: '30px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      padding: '0 20px',
      boxShadow: brandColor + '0px 0px 0px 1px, rgb(0 0 0 / 20%) 0px 10px 20px',
      fontWeight: 700,
      columnGap: '.5rem',
      color: textColor,
      whiteSpace: 'nowrap',
    },
    websiteTitle: {
      fontWeight: 600,
      fontSize: '24px',
    },
    active: {
      borderColor: '#30feaf',
      color: '#000',
      backgroundColor: '#30feaf',
    },
    brandColor: {
      width: '36px',
      height: '14px',
      borderRadius: '2px',
      background: brandColor,
    },
    textColor: {
      width: '36px',
      height: '14px',
      borderRadius: '2px',
      background: textColor,
    },
    swatch: {
      padding: '5px',
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
    },
    popover: {
      position: 'absolute',
      zIndex: '2',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
    chatIcon: {
      fill: 'green',
      color: 'green',
    },
    previewBox: {
      backgroundColor: '#eaeaea',
      padding: '3rem 2rem 2rem 2rem',
      borderRadius: '10px',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: pagePosition === 'right' ? 'flex-end' : 'flex-start',
      marginBottom: '1.5rem',
      position: 'relative',
      '&:after': {
        content: '"Preview"',
        fontWeight: '600',
        position: 'absolute',
        top: '1rem',
        right: '1rem',
        fontSize: '12px',
        zIndex: 2,
        color: '#a9a9a9',
      },
    },
    textUsPopup: {
      boxShadow: 'rgb(136, 136, 136) 0px 0px 20px',
      backgroundColor: ' rgb(245, 245, 245)',
      borderRadius: '18px',
      flexDirection: 'row',
      display: 'flex',
      padding: '15px',
      transform: ' translateY(0px)',
      transition: 'all 0.5s ease 0s',
      opacity: 1,
      marginBottom: '1.5rem',
      alignItems: 'center',
    },
    chatWithText: {
      fontWeight: '700',
      marginBottom: '5px',
      fontSize: '15px',
      marginRight: '2rem',
    },
    greetingText: {
      fontSize: '13px',
      color: '#727272',
    },
    widgetTitle: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
    },
    descriptionText: {
      color: '#979797',
      fontWeight: 'normal',
    },
    expanded: {
      display: 'flex',
      marginTop: '3rem',
      animation: 'fadeIn',
      animationDuration: '.3s',
      transition: 'display: 1s opacity: .8s',
    },
    collapsed: {
      display: 'none',
    },
    webchatCode: {
      fontSize: '14px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    chatIcon: {
      border: '1px solid #d2d2d2',
      height: '50px',
      width: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '40px',
      cursor: 'pointer',
      '& svg': {
        fill: '#d2d2d2 !important',
        color: '#d2d2d2 !important',
      },
      '&:hover': {
        backgroundColor: '#defff2',
        borderColor: '#30feaf',
        '& svg': {
          fill: '#30feaf !important',
          color: '#30feaf !important',
        },
      },
    },
    selected: {
      backgroundColor: '#defff2',
      borderColor: '#30feaf',
      '& svg': {
        fill: '#30feaf !important',
        color: '#30feaf !important',
      },
    },
  }));

  const classes = useStyles();

  const handleTitleChange = (value) => {
    setWebchat({
      name: value,
    });
  };

  const ReveoChatIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 27"
        style={{ width: '24px', marginTop: '3px' }}
        fill={textColor}
      >
        <path
          d="M18,3.5H2c-1.1,0-2,0.9-2,2v18l4-4h14c1.1,0,2-0.9,2-2v-12C20,4.4,19.1,3.5,18,3.5z M9.5,16.2c-0.7,0-1.3-0.6-1.3-1.3
s0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3S10.2,16.2,9.5,16.2L9.5,16.2z M9.5,12.5c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3
c0,0,0,0,0,0C10.8,11.9,10.2,12.5,9.5,12.5L9.5,12.5z M9.5,8.8c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3
c0,0,0,0,0,0C10.8,8.2,10.2,8.8,9.5,8.8C9.5,8.8,9.5,8.8,9.5,8.8L9.5,8.8z M13.5,12.5c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3
s1.3,0.6,1.3,1.3c0,0,0,0,0,0C14.8,11.9,14.2,12.5,13.5,12.5C13.5,12.5,13.5,12.5,13.5,12.5L13.5,12.5z"
        />
      </svg>
    );
  };

  const handleTextIconChange = (icon) => {
    setTextUsIcon(icon);
  };

  const handleLiveChatIconChange = (icon) => {
    setLiveChatIcon(icon);
  };

  const getIcon = (chatType) => {
    switch (chatType) {
      case 'phoneIcon':
        return <SmartphoneOutlined style={{ color: textColor }} />;
      case 'reveoChat':
        return <ReveoChatIcon style={{ fill: textColor }} />;
      case 'chatIcon':
        return <ChatOutlineIcon style={{ color: textColor }} />;
    }
  };

  return renderView ? (
    <>
      <CusotmAlert
        message={webchatAlert.message}
        handleClose={webchatAlert.handleClose}
        open={webchatAlert.show}
        messageType={webchatAlert.messageType}
      />
      <Grid container spacing={5} className={classes.root} wrap="wrap">
        <Grid item xs={12} md={12} lg={12}>
          <Paper variant="outlined" style={{ padding: '2rem' }}>
            <Grid item xs={12} md={12} lg={12}>
              <Box display="flex" alignItems={'center'} justifyContent={'space-between'}>
                <TemplateTitleEdit
                  handleChange={handleTitleChange}
                  campaign={webchat}
                  type={'webchat'}
                />
                <div>
                  <PrimaryButton onClick={() => handleSaveChanges()}>Save Changes</PrimaryButton>
                  <CustomSwitch
                    checked={isEnabled ? true : false}
                    onChange={() => setIsEnabled(!isEnabled)}
                  />
                  <Button onClick={() => setIsWebchatExpanded(!isWebchatExpanded)}>
                    <ExpandIcon size={'20px'} />
                  </Button>
                </div>
              </Box>
            </Grid>
            {isWebchatExpanded ? (
              <Grid
                container
                style={{
                  opacity: !isEnabled ? '.5' : 1,
                  pointerEvents: !isEnabled ? 'none' : null,
                }}
                spacing={3}
                className={isWebchatExpanded ? classes.expanded : classes.collapsed}
              >
                <Grid item xs={12} md={7} lg={7}>
                  <Box display="flex" justifyContent="space-between" flex="1" marginBottom="30px">
                    <WidgetSetting>Button Color</WidgetSetting>
                    <div
                      className={classes.swatch}
                      onClick={() => setDisplayTextColorPicker(!displayTextColorPicker)}
                    >
                      <div className={classes.brandColor} />
                      {displayTextColorPicker ? (
                        <div className={classes.popover}>
                          <div
                            className={classes.cover}
                            onClick={() => setDisplayTextColorPicker(!displayTextColorPicker)}
                          />
                          <SketchPicker
                            color={brandColor}
                            onChange={(color) => setBrandColor(color.hex)}
                          />
                        </div>
                      ) : null}
                    </div>
                  </Box>
                  <Box display="flex" justifyContent="space-between" flex="1" marginBottom="30px">
                    <WidgetSetting>Text Color</WidgetSetting>
                    <div
                      className={classes.swatch}
                      onClick={() => setDisplayBrandColorPicker(!displayBrandColorPicker)}
                    >
                      <div className={classes.textColor} />
                      {displayBrandColorPicker ? (
                        <div className={classes.popover}>
                          <div
                            className={classes.cover}
                            onClick={() => setDisplayBrandColorPicker(!displayBrandColorPicker)}
                          />
                          <SketchPicker
                            color={textColor}
                            onChange={(color) => setTextColor(color.hex)}
                          />
                        </div>
                      ) : null}
                    </div>
                  </Box>
                  <Box display="flex" justifyContent="space-between" flex="1" marginBottom="30px">
                    <WidgetSetting>Page Position</WidgetSetting>
                    <Box display="flex">
                      <StyledDirectionButton
                        variant="outlined"
                        style={{ marginRight: '10px' }}
                        className={pagePosition === 'left' ? classes.active : ''}
                        onClick={() => setPagePosition('left')}
                      >
                        <ArrowLeftIcon size={'18px'} style={{ marginRight: '10px' }} />
                        Left
                      </StyledDirectionButton>
                      <StyledDirectionButton
                        variant="outlined"
                        className={pagePosition === 'right' ? classes.active : ''}
                        onClick={() => setPagePosition('right')}
                      >
                        Right
                        <ArrowRightIcon size={'18px'} style={{ marginLeft: '10px' }} />
                      </StyledDirectionButton>
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="space-between" flex="1" marginBottom="30px">
                    <WidgetSetting>Greeting</WidgetSetting>
                    <StyledTextarea
                      aria-label="webchat greeting"
                      minRows={3}
                      placeholder="Hi there, have a question?"
                      value={greeting}
                      onChange={(e) => setGreeting(e.target.value)}
                    />
                  </Box>
                  <Box display="flex" justifyContent="space-between" flex="1" marginBottom="30px">
                    <WidgetSetting>Avatar Image</WidgetSetting>
                    <div style={{ display: 'flex', columnGap: '1rem' }}>
                      <div
                        style={{
                          borderRadius: '30px',
                          width: '50px',
                          height: '50px',
                          overflow: 'hidden',
                        }}
                      >
                        <Uploader
                          name="avatar"
                          setValue={setImagesContainer}
                          value={imagesContainer}
                          handleImagesContainer={handleImagesContainer}
                          imgWidth={40}
                          imgHeight={40}
                          apiUrl="/uploads/images"
                          imageType="avatar"
                        />
                      </div>
                    </div>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    flex="1"
                    marginBottom="30px"
                    borderTop="1px solid #d2d2d2"
                    paddingTop="1rem"
                  >
                    <WidgetSetting>Enable Live Chat</WidgetSetting>
                    <CustomSwitch
                      checked={isLiveChatEnabled ? true : false}
                      onChange={() => setIsLiveChatEnabled(!isLiveChatEnabled)}
                    />
                  </Box>
                  {isLiveChatEnabled ? (
                    <>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        flex="1"
                        style={{ columnGap: '1rem' }}
                        marginBottom="30px"
                      >
                        <WidgetSubSetting>Icon</WidgetSubSetting>
                        <div style={{ display: 'flex', columnGap: '.5rem' }}>
                          <div
                            className={`${classes.chatIcon} ${
                              liveChatIcon === 'reveoChat' ? classes.selected : ''
                            }`}
                            onClick={() => handleLiveChatIconChange('reveoChat')}
                          >
                            <ReveoChatIcon style={{ fill: '#d2d2d2' }} />
                          </div>
                          <div
                            className={`${classes.chatIcon} ${
                              liveChatIcon === 'chatIcon' ? classes.selected : ''
                            }`}
                            onClick={() => handleLiveChatIconChange('chatIcon')}
                          >
                            <ChatOutlineIcon style={{ color: '#d2d2d2' }} />
                          </div>
                          <div
                            className={`${classes.chatIcon} ${
                              liveChatIcon === 'phoneIcon' ? classes.selected : ''
                            }`}
                            onClick={() => handleLiveChatIconChange('phoneIcon')}
                          >
                            <SmartphoneOutlined style={{ color: '#d2d2d2' }} />
                          </div>
                        </div>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        flex="1"
                        style={{ columnGap: '1rem' }}
                        marginBottom="30px"
                      >
                        <WidgetSubSetting>
                          Auto responder text during business hours. <br />
                          <p className={classes.descriptionText}>
                            @nextOpenDay - will be replaced with the next open business day
                            <br />
                            @nextOpenTime - will be replaced with the opening time of the next
                            business day.
                          </p>
                        </WidgetSubSetting>
                        <StyledTextarea
                          aria-label="live chat online auto responder"
                          minRows={3}
                          placeholder="Live Chat Online Auto Responder"
                          value={liveChatAutoResponder}
                          onChange={(e) => setLiveChatAutoResponder(e.target.value)}
                        />
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        flex="1"
                        style={{ columnGap: '1rem' }}
                        marginBottom="30px"
                      >
                        <WidgetSubSetting>
                          Auto responder text when offline. <br />
                          <p className={classes.descriptionText}>
                            @nextOpenDay - will be replaced with the next open business day
                            <br />
                            @nextOpenTime - will be replaced with the opening time of the next
                            business day.
                          </p>
                        </WidgetSubSetting>
                        <StyledTextarea
                          aria-label="live chat offline auto responder"
                          minRows={3}
                          placeholder="Live Chat Offline Auto Responder"
                          value={liveChatAutoResponderOffline}
                          onChange={(e) => setLiveChatAutoResponderOffline(e.target.value)}
                        />
                      </Box>
                    </>
                  ) : null}
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    flex="1"
                    marginBottom="30px"
                    borderTop="1px solid #d2d2d2"
                    paddingTop="1rem"
                  >
                    <WidgetSetting>Enable Text Us</WidgetSetting>
                    <CustomSwitch
                      checked={isTextUsEnabled ? true : false}
                      onChange={() => setIsTextUsEnabled(!isTextUsEnabled)}
                    />
                  </Box>
                  {isTextUsEnabled ? (
                    <>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        flex="1"
                        style={{ columnGap: '1rem' }}
                        marginBottom="30px"
                      >
                        <WidgetSubSetting>Icon</WidgetSubSetting>
                        <div style={{ display: 'flex', columnGap: '.5rem' }}>
                          <div
                            className={`${classes.chatIcon} ${
                              textUsIcon === 'reveoChat' ? classes.selected : ''
                            }`}
                            onClick={() => handleTextIconChange('reveoChat')}
                          >
                            <ReveoChatIcon />
                          </div>
                          <div
                            className={`${classes.chatIcon} ${
                              textUsIcon === 'chatIcon' ? classes.selected : ''
                            }`}
                            onClick={() => handleTextIconChange('chatIcon')}
                          >
                            <ChatOutlineIcon style={{ color: '#d2d2d2' }} />
                          </div>
                          <div
                            className={`${classes.chatIcon} ${
                              textUsIcon === 'phoneIcon' ? classes.selected : ''
                            }`}
                            onClick={() => handleTextIconChange('phoneIcon')}
                          >
                            <SmartphoneOutlined style={{ color: '#d2d2d2' }} />
                          </div>
                        </div>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        flex="1"
                        style={{ columnGap: '1rem' }}
                        marginBottom="30px"
                      >
                        <WidgetSubSetting>
                          Auto responder text during business hours.
                          <br />
                          <p className={classes.descriptionText}>
                            @nextOpenDay - will be replaced with the next open business day
                            <br />
                            @nextOpenTime - will be replaced with the opening time of the next
                            business day.
                          </p>
                        </WidgetSubSetting>
                        <StyledTextarea
                          aria-label="text us auto responder online"
                          minRows={3}
                          placeholder="Text Us Online Auto Responder"
                          value={offlineAutoResponderOnline}
                          onChange={(e) => setOfflineAutoResponderOnline(e.target.value)}
                        />
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        flex="1"
                        style={{ columnGap: '1rem' }}
                        marginBottom="30px"
                      >
                        <WidgetSubSetting>
                          Auto responder text when offline.
                          <br />
                          <p className={classes.descriptionText}>
                            @nextOpenDay - will be replaced with the next open business day
                            <br />
                            @nextOpenTime - will be replaced with the opening time of the next
                            business day.
                          </p>
                        </WidgetSubSetting>
                        <StyledTextarea
                          aria-label="text us auto responder offline"
                          minRows={3}
                          placeholder="Text Us Offline Auto Responder"
                          value={offlineAutoResponder}
                          onChange={(e) => setOfflineAutoResponder(e.target.value)}
                        />
                      </Box>
                    </>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={5} lg={5}>
                  <div className={classes.previewBox}>
                    <div className={classes.textUsPopup}>
                      <Avatar src={imageURLCheck(imagesContainer)} />
                      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '15px' }}>
                        <span className={classes.chatWithText}>Chat with {businessInfo.name}</span>
                        <span className={classes.greetingText}>{greeting}</span>
                      </div>
                    </div>
                    <div className={classes.chatButtons}>
                      {isTextUsEnabled ? (
                        <div className={classes.chatButton}>
                          {getIcon(textUsIcon)}
                          Text us
                        </div>
                      ) : null}
                      {isLiveChatEnabled ? (
                        <div className={classes.chatButton}>
                          {getIcon(liveChatIcon)}
                          Live chat
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <WidgetSetting>Webchat code</WidgetSetting>
                  <CopyToClipboard text={webchatCode} onCopy={() => setCopied(true)}>
                    <StyledInput>
                      <div className={classes.webchatCode}>{webchatCode}</div>
                      <PrimaryButton style={{ width: '200px' }}>
                        {copied ? 'Copied' : 'Copy'}
                      </PrimaryButton>
                    </StyledInput>
                  </CopyToClipboard>
                </Grid>
              </Grid>
            ) : null}
          </Paper>
        </Grid>
      </Grid>
    </>
  ) : (
    <NoData
      icon={<ChatOutlineIcon />}
      title={"It looks like you don't have a webchat set up yet"}
      handleClick={handleFirstCreate}
      link={'Create webchat'}
      fullScreen
    />

    // <PrimaryButton onClick={() => handleFirstCreate()}>Create Web Chat</PrimaryButton>
  );
}

export default WebchatContainer;
