import React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import theme from '../setup';

const StyledPNFGrid = styled(Grid)`
  height: 100%;
  font-size: 36px;
  color: ${theme.color};
  font-weight: 200;
  letter-spacing: 1.08px;
  background: ${theme.background_color};
`;

function PageNotFound() {
  return (
    <StyledPNFGrid container justifyContent="center" alignItems="center">
      Page Not Found !
    </StyledPNFGrid>
  );
}

export default PageNotFound;
