import React, { useState, useContext } from 'react';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import SendIcon from '@material-ui/icons/Send';
import styled, { ThemeProvider } from 'styled-components';
import { Grid, Badge, Box, TextField, Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import Proptypes from 'prop-types';
import CustomButton from '../CustomButton/CustomButton';
import StyledBorderTextField from '../BorderdInputField/BorderedInputField';
import useIconTheme from '../../hooks/useIconTheme';
import { capitalizeSentence } from '../../utils/helper/helper';
import baseUrl from '../../utils/services/config';
import ButtonOpenAi from '../ButtonOpenAi/ButtonOpenAi';
import useAlert from '../../hooks/useAlert';
import CusotmAlert from '../CustomAlert/CustomAlert';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import PrimaryButton from '../Common/PrimaryButton';

const StyledAttachFileIcon = styled(AttachFileIcon)`
  color: ${(props) => props.theme.color} !important;
`;

const StyledFilterIcon = styled(FilterNoneIcon)`
  color: ${(props) => props.theme.color} !important;
`;

const StyledSendIcon = styled(SendIcon)`
  color: ${(props) => props.theme.color} !important;
`;

const StyledChatActionContainer = styled(Grid)`
  padding: 0 1em;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 1rem;
`;

const StyledCheckIcon = styled(CheckIcon)`
  color: ${(props) => props.theme.color};
  font-size: 14px !important;
`;

// const PrimaryButton = styled(Button)`
//   background-color: #30feaf !important;
//   margin-left: 10px !important;
//   text-transform: capitalize !important;
//   letter-spacing: unset !important;
//   font-family: 'Montserrat' !important;
//   font-weight: 600 !important;
// `;

// const StyledOutlineButton = styled(Button)`
//   text-transform: capitalize !important;
//   letter-spacing: unset !important;
//   font-family: 'Montserrat' !important;
//   font-weight: 600 !important;
// `;

const StyledBorderLessTextArea = styled(StyledBorderTextField)`
  & div textarea {
    border: none !important;
    overflow-y: auto !important;
    line-height: 1.5;
    resize: none;
    height: ${({ rows }) => `${rows * 0.5}em`};
    font-size: 14px;
  }
`;

const textBoxTheme = {
  border: 'none',
  margin: '0',
  borderRadius: '8px',
  fontFamily: 'Montserrat',
};

const buttonTheme = {
  background: 'transparent !important',
  margin: '0 ',
  disabledBackgroundColor: 'transparent',
};

function UserChatActionPanel(props) {
  const {
    capamignListId,
    handleShowCampaignList,
    sendMessage,
    handleSendMessageOnChange,
    handleMessageSendandClose,
    handleMessageSend,
    conversationType,
    handleShowAttachmentPopup,
    handleCampaignRefInit,
    handleShowSelectedAttachment,
    isUnsubscribed,
    campaginLoader,
    currentConversation,
    setSendMessage,
  } = props;

  const { state } = useContext(GlobalContext);
  const { userRole } = state;

  const { iconsTheme } = useIconTheme();
  const [isLoading, setIsLoading] = useState(false);
  const UserChatActionAlert = useAlert();
  const getPlaceholderText = () => {
    const { source, text } = currentConversation.messages[0];
    switch (source.toLowerCase()) {
      case 'system':
        if (text === 'review request') return 'Send Message';
      case 'text message':
        return 'Send Text Message';
      case 'live chat':
        return 'Send Live Chat';
      case 'reveo response':
        return 'Send Message';
      case 'vcard':
        return 'Send Message';
      default:
        return `Respond to ${capitalizeSentence(source)}`;
    }
  };

  const handleSendMessageOnButtonClick = () => {
    const { type } = currentConversation.messages[0];
    if (type.toLowerCase() === 'review') navigator.clipboard.writeText(sendMessage);
    handleMessageSend();
  };

  const handleSendMessageOnEnter = (e) => {
    const type = currentConversation.messages[0].type || '';
    if (e.keyCode === 13) {
      if (type.toLowerCase() === 'review') navigator.clipboard.writeText(sendMessage);
      handleMessageSend();
    }
  };

  const handleGetAiGeneratedResponse = (isReview) => {
    if (isLoading) return;
    const { handleSetMessage, handleSetMessageType, handleShow } = UserChatActionAlert;
    const conversation = currentConversation.messages.map((item) => ({
      created: item.createdAt,
      text: item.text,
      name: item.source !== 'reveo response' ? currentConversation.name : item.source,
    }));

    if (isReview) {
      const { reviewId } = currentConversation.messages[0].meta;
      setIsLoading(true);
      baseUrl.get(`/reviews/openai-response/${reviewId}`).then((res) => {
        setIsLoading(false);
        if (res.status === 200 && res.data?.message) {
          setSendMessage(res.data.message);
        } else {
          handleSetMessageType('error');
          handleSetMessage(
            'Something went wrong. Please check console errors for more information',
          );
          handleShow();
          console.error('Open AI error: ', res);
        }
      });
    } else {
      setIsLoading(true);
      baseUrl
        .post(
          `/conversation/openai-response?conversation=${encodeURIComponent(
            JSON.stringify(conversation),
          )}`,
        )
        .then((res) => {
          setIsLoading(false);
          if (res.status === 200 && res.data?.message) {
            setSendMessage(res.data.message);
          } else {
            setIsLoading(false);

            handleSetMessageType('error');
            handleSetMessage(
              'Something went wrong. Please check console errors for more information',
            );
            handleShow();
            console.error('Open AI error: ', res);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  const placeholderText = currentConversation && getPlaceholderText();
  const isReview = !!(currentConversation && currentConversation.messages[0].type === 'review');

  const panelContent = (
    <StyledChatActionContainer
      id="footer-action-panel"
      container
      alignItems="center"
      onClick={handleShowSelectedAttachment}
    >
      <Box
        flex="1"
        padding="1rem .5rem .5rem .5rem"
        sx={{ borderRadius: 10, backgroundColor: '#fff' }}
      >
        <ThemeProvider theme={textBoxTheme}>
          <StyledBorderLessTextArea
            name="sendText"
            placeholder={placeholderText}
            value={sendMessage}
            onChange={handleSendMessageOnChange}
            onKeyDown={(e) => handleSendMessageOnEnter(e)}
            disabled={isUnsubscribed}
            inputProps={{ maxLength: 500 }}
            multiline
          />
        </ThemeProvider>
        <Box
          flexDirection="row"
          flex="1"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginTop="20px"
        >
          <Box display="flex">
            <ThemeProvider theme={{ ...buttonTheme, color: iconsTheme.attachFileIcon }}>
              <CustomButton
                onClick={handleShowAttachmentPopup}
                title="Attach files"
                disabled={isUnsubscribed}
              >
                <StyledAttachFileIcon />
              </CustomButton>
            </ThemeProvider>
            <ThemeProvider theme={{ ...buttonTheme, color: iconsTheme.filterNoneIcon }}>
              <CustomButton
                innerRef={handleCampaignRefInit}
                aria-describedby={capamignListId}
                onClick={handleShowCampaignList}
                title="Select Campaigns"
                disabled={isUnsubscribed}
              >
                <StyledFilterIcon />
              </CustomButton>
            </ThemeProvider>

            <ThemeProvider theme={{ ...buttonTheme, color: iconsTheme.filterNoneIcon }}>
              <ButtonOpenAi
                handleOnClick={() => handleGetAiGeneratedResponse(isReview)}
                title="Get AI Generated response"
                width="24"
                height="24"
                isLoading={isLoading}
              />
            </ThemeProvider>
          </Box>
          <Box display="flex" style={{ columnGap: '.5rem' }}>
            {!isReview ? (
              handleMessageSendandClose ? (
                <PrimaryButton
                  outlined
                  onClick={handleMessageSendandClose}
                  disabled={!sendMessage || campaginLoader}
                >
                  Send & Close
                </PrimaryButton>
              ) : null
            ) : null}
            {!isReview ? (
              <PrimaryButton onClick={handleMessageSend} disabled={!sendMessage || campaginLoader}>
                Send
              </PrimaryButton>
            ) : (
              <PrimaryButton
                onClick={handleSendMessageOnButtonClick}
                disabled={!sendMessage || campaginLoader}
              >
                Respond to review
              </PrimaryButton>
            )}
          </Box>
        </Box>
      </Box>
      <CusotmAlert
        message={UserChatActionAlert.message}
        handleClose={UserChatActionAlert.handleClose}
        open={UserChatActionAlert.show}
        messageType={UserChatActionAlert.messageType}
      />
    </StyledChatActionContainer>
  );

  const actionPannel = () => {
    if (conversationType === 'open') {
      if (userRole.id === 3 && isReview) {
        return null;
      } else {
        return panelContent;
      }
    }
    return null; // Return null if conversationType is not 'open' or if userRole.id is 3 and isReview is true
  };

  return actionPannel(); // Return the result of the actionPannel function call
}

UserChatActionPanel.propTypes = {
  capamignListId: Proptypes.string,
  handleShowCampaignList: Proptypes.func,
  sendMessage: Proptypes.string.isRequired,
  handleSendMessageOnChange: Proptypes.func.isRequired,
  handleMessageSend: Proptypes.func.isRequired,
  handleMessageSendandClose: Proptypes.func,
  conversationType: Proptypes.string.isRequired,
  handleShowAttachmentPopup: Proptypes.func.isRequired,
  footerPannelRef: Proptypes.node,
  handleShowSelectedAttachment: Proptypes.func.isRequired,
  handleCampaignRefInit: Proptypes.func,
  // handleOnEnterSendMessage: Proptypes.func.isRequired,
  campaginLoader: Proptypes.bool.isRequired,
  setSendMessage: Proptypes.func,
};

export default UserChatActionPanel;
