import React from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';

const StyledIcon = styled.div`
  svg {
    width: ${(props) => (props.fullScreen ? '150px' : '75px')};
    height: ${(props) => (props.fullScreen ? '150px' : '75px')};
    color: #dadada;
  }
`;

const StyledTitle = styled.div`
  /* font-size: ${(props) => (props.fullScreen ? '1.5rem' : '.875rem')}; */
  font-weight: 600;
  color: #b8b8b8;
  font-size: 1rem;
`;

const StyledLink = styled.div`
  color: #58e298;
  margin-top: ${(props) => (props.fullScreen ? '1rem' : '.2rem')};
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  :hover {
    text-decoration: underline;
    transition: all 0.2s;
  }
`;

export const NoData = (props) => {
  const isFullScreen = () => {
    return fullScreen ? { height: '70vh' } : null;
  };
  const { icon, title, handleClick, link, fullScreen, style } = props;
  return (
    <Box
      display="flex"
      alignItems="center"
      {...isFullScreen()}
      style={{ ...style }}
      justifyContent="center"
    >
      <Box display="flex" alignItems="center" flexDirection="column">
        <StyledIcon>{icon}</StyledIcon>
        <StyledTitle style={{ ...style }}>{title}</StyledTitle>
        {link ? <StyledLink onClick={() => handleClick()}>{link}</StyledLink> : null}
      </Box>
    </Box>
  );
};
