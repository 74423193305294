import React from 'react';
import styled from 'styled-components';
import CustomRating from '../../../../../../components/CusotmRating/CusotmRating';

const StyledContainer = styled.div`
  border: 1px solid #7a7a7a;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const Th = styled.th`
  border-bottom: 1px solid #7a7a7a;
  padding: 8px;
  text-align: left;
  min-height: 18px;
  color: #7a7a7a;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Td = styled.td`
  border-bottom: 1px solid #7a7a7a;
  padding: 8px;
  text-align: left;
  min-height: 18px;
  color: #7a7a7a;
  font-size: 14px;
  width: 50%;
`;

const QuestionsAndAnswer = ({ data = [] }) => {
  const typeAnswer = (item) => {
    if (item.type === 'starRating') {
      return <CustomRating ratingValue={item.answerValue} />;
    }
    return item.answerValue;
  };
  return (
    <StyledContainer>
      <Table>
        <thead>
          <tr>
            <Th>Question</Th>
            <Th>Answer</Th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr>
              <Td>{item.question}</Td>
              <Td>{typeAnswer(item)}</Td>
            </tr>
          ))}
        </tbody>
      </Table>
    </StyledContainer>
  );
};

export default QuestionsAndAnswer;
