import React from 'react';
import Proptypes from 'prop-types';
import { Grid, Input, makeStyles, TableCell } from '@material-ui/core';
import { ThemeProvider } from 'styled-components';
import CusotmAvatar from '../CustomAvatar/CustomAvatar';
import StyledChip from '../Chip/BasicChip';
import StyledTextField from '../GeneralInputField/GeneralInputField';
import NumberField from '../NumberField/NumberField';
import { convertIntoPhoneFormat } from '../../utils/helper/helper';

const useStyles = makeStyles((theme) => ({
  selectTableCell: {
    width: 60,
  },
  tableCell: {
    width: 130,
    height: 55,
    padding: '10px',
    color: theme.tableCellColor,
  },
  input: {
    height: 40,
    padding: '10px',
  },
}));

const tableLogoTheme = {
  height: '30px',
  width: '30px',
  margin: '0',
};

const CustomTableCell = ({
  row,
  name,
  onChange,
  isEdit,
  emailError,
  phoneError,
  onBlur,
  handlePhoneChange,
}) => {
  const classes = useStyles();
  const { isEditMode } = row;
  const getIsInvalidField = () => {
    let invalidObj = { isInvalid: false, message: '' };
    if (phoneError) {
      invalidObj = {
        isInvalid: true,
        message: 'Phone already exists',
      };
    }
    if (emailError) {
      invalidObj = {
        isInvalid: true,
        message: 'Email already exists',
      };
    }
    return invalidObj;
  };

  const simpleRowCell = (
    <Grid container alignItems="center">
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{ color: row.isDuplicate ? '#C73626' : '' }}
      >
        {row[name]}
      </Grid>
    </Grid>
  );

  const simpleEditableRowCell = (
    <Grid container alignItems="center">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <StyledTextField
          value={row[name]}
          name={name}
          onChange={(e) => onChange(e, row)}
          onBlur={() => onBlur(row, name)}
          className={classes.input}
          error={getIsInvalidField().isInvalid}
          helperText={getIsInvalidField().message}
        />
      </Grid>
    </Grid>
  );

  const phoneRowCell = (
    <Grid container alignItems="center">
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{ color: row.isDuplicate ? '#C73626' : '' }}
      >
        {row[name] && row[name].length > 5 ? convertIntoPhoneFormat(`+1${row[name]}`) : ''}
      </Grid>
    </Grid>
  );

  const phoneEditableRowCell = (
    <Grid container alignItems="center">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <NumberField
          value={row[name]}
          format="+1 ### ### ####"
          onChange={(values) => handlePhoneChange(values.value, row)}
          onBlur={() => onBlur(row, name)}
          className={classes.input}
          error={getIsInvalidField().isInvalid}
          helperText={getIsInvalidField().message}
        />
      </Grid>
    </Grid>
  );

  const profileRowCell = (
    <Grid container wrap="wrap" alignItems="center">
      <Grid item xs={4} sm={4} md={4} lg={4}>
        <ThemeProvider theme={tableLogoTheme}>
          <CusotmAvatar />
        </ThemeProvider>
      </Grid>
      <Grid item xs={8} sm={8} md={8} lg={8}>
        {row[name]}
      </Grid>
    </Grid>
  );

  const profileEditableRowCell = (
    <Grid container alignItems="center">
      <Grid item xs={4} sm={4} md={4} lg={4}>
        <ThemeProvider theme={tableLogoTheme}>
          <CusotmAvatar />
        </ThemeProvider>
      </Grid>
      <Grid item xs={8} sm={8} md={8} lg={8}>
        <Input
          value={row[name]}
          name={name}
          onChange={(e) => onChange(e, row)}
          className={classes.input}
        />
      </Grid>
    </Grid>
  );

  const unsubscribedSimpleRowCell = (
    <Grid container alignItems="center">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        Subscribed
      </Grid>
    </Grid>
  );
  const unsubscribedChipRowCell = (
    <Grid container alignItems="center">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <StyledChip label="Unsubscribed" />
      </Grid>
    </Grid>
  );
  const getCustomTableCellContent = (type, isEditTableCell, rowValue) => {
    switch (type) {
      case 'firstName':
        return isEditTableCell ? profileEditableRowCell : profileRowCell;
      case 'lastName':
        return isEditTableCell ? simpleEditableRowCell : simpleRowCell;
      case 'emailAddress':
        return isEditTableCell ? simpleEditableRowCell : simpleRowCell;
      case 'phoneNumber':
        return isEditTableCell ? phoneEditableRowCell : phoneRowCell;
      case 'tags':
        return simpleRowCell;
      case 'unsubscribeMobile':
        return !rowValue ? unsubscribedSimpleRowCell : unsubscribedChipRowCell;
      case 'unsubscribe':
        return !rowValue ? unsubscribedSimpleRowCell : unsubscribedChipRowCell;

      default:
        return simpleRowCell;
    }
  };

  return (
    <TableCell align="left" className={classes.tableCell}>
      {getCustomTableCellContent(name, isEditMode && isEdit, row[name])}
    </TableCell>
  );
};

CustomTableCell.propTypes = {
  row: Proptypes.objectOf(Proptypes.string).isRequired,
  name: Proptypes.string.isRequired,
  onChange: Proptypes.func.isRequired,
  isEdit: Proptypes.bool.isRequired,
  emailError: Proptypes.bool,
  phoneError: Proptypes.bool,
  onBlur: Proptypes.func,
  handlePhoneChange: Proptypes.func.isRequired,
};

CustomTableCell.defaultProps = {
  emailError: false,
  phoneError: false,
  onBlur: () => {},
};

export default CustomTableCell;
