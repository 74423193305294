/* eslint-disable */
import React, { useContext } from 'react';
import { ThemeProvider } from '@material-ui/core';
import baseUrl from '../services/config';
import StyledCheckbox from '../../components/StyledCheckbox/StyledCheckbox';
import facebookAvatar from '../../assets/images/facebook.png';
import googleAvatar from '../../assets/images/googleLogo.jpg';
import carguruAvatar from '../../assets/images/carguruLogo.jpg';
import appRoutes, {
  appAccountTabs,
  appRoutesRedirection,
  appSetupTabs,
} from '../../routes/AuthRoutes';
import managerRoutes, {
  managerAccountTabs,
  managerRoutesRedirection,
  managerSetupTabs,
} from '../../routes/managerRoutes';
import empRoutes, {
  empSetupTabs,
  empAccountTabs,
  empRoutesRedirection,
} from '../../routes/empRoutes';
import businessAdminRoutes, {
  businessAdminSetupTabs,
  businessAdminAccountTabs,
  businessAdminRoutesRedirection,
} from '../../routes/businessAdminRoutes';
import adminRoutes, {
  adminSetupTabs,
  adminAccountTabs,
  adminRoutesRedirection,
} from '../../routes/adminRoutes';
import navList, { empNavList, managerNavList } from '../api/DummySideNavBar';
import { monthsArray } from '../api/Months';
import StyledTableCell from '../../components/TableRowCell';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import { parseISO, getTime, format } from 'date-fns';
import { SurveyBadge } from '../../components/CampaignTypeBadges/SurveyBadge';
import { EmailTemplateBadge } from '../../components/CampaignTypeBadges/EmailTemplateBadge';
import { MessageBadge } from '../../components/CampaignTypeBadges/MessageBadge';
import { ReviewRequestBadge } from '../../components/CampaignTypeBadges/ReviewRequestBadge';
import { DefaultBadge } from '../../components/CampaignTypeBadges/DefaultBadge';

import xlsLogo from '../../assets/images/xls.png';
import pdfLogo from '../../assets/images/pdf.png';
import pptLogo from '../../assets/images/ppt.png';
import docLogo from '../../assets/images/doc.png';
import reveoLogo from '../../assets/images/reveo_logo.png';
import { SocialImageBadge } from '../../components/CampaignTypeBadges/SocialImageBadge';

export const getAgentName = (id) => {
  const { state } = useContext(GlobalContext);
  const { businessInfo } = state;

  const agentName = businessInfo.user;

  let finalAgentName;
  if (id) {
    const agentInfo = agentName.find((x) => x.id === id);
    if (agentInfo) {
      return (finalAgentName = `${agentInfo.firstName || ''} ${agentInfo.lastName || ''}`);
    }
  }
  return (finalAgentName = 'Response from the owner');
};

export const ConvertInDropDownFormat = (data) => {
  const dropDownValues = [];
  data.forEach((element) => {
    dropDownValues.push({ label: element.name, value: element.id });
  });
  return dropDownValues;
};

export const ConvertIntoLocationDropDown = (data) => {
  const dropDownValues = [];
  data.forEach((element) => {
    dropDownValues.push({ label: element.nickName || element.name, value: element.id });
  });

  return dropDownValues;
};

export const ConvertIntoLocationDropDownSelector = (data) => {
  const dropDownValues = [];
  data.forEach((element) => {
    dropDownValues.push({ label: element.nickname, value: element.id });
  });

  return dropDownValues;
};

export const ConvertIntoUserDropDown = (data, disabled) => {
  const dropDownValues = [];
  data.forEach((element) => {
    dropDownValues.push({
      label: `${element.firstName} ${element.lastName}`,
      value: element.id,
      disabledMenuItem: disabled
        ? element.id === 1 || element.id === parseInt(localStorage.getItem('USER_ID'), 10)
        : false,
    });
  });
  return dropDownValues;
};

export const ConvertIntoGroupNameDropDown = (data) => {
  const dropDownValues = [];
  data.forEach((element) => {
    dropDownValues.push({
      label: `${element.name}`,
      value: element.id,
    });
  });
  return dropDownValues;
};

export const ConvertIntoSynuplocationsDropDown = (data) => {
  const dropDownValues = [];
  data.forEach((element) => {
    dropDownValues.push({ label: element.locationName, value: element.id });
  });
  return dropDownValues;
};

export const convertIntoGoogleLocationDropDown = (data) => {
  const dropDownValues = [];
  data.forEach((element) => {
    dropDownValues.push({ label: `${element.locationName}`, value: element.id });
  });
  return dropDownValues;
};

export const convertedIntoFacebookPagesDropDown = (data) => {
  const dropDownValues = [];
  data.forEach((element) => {
    dropDownValues.push({ label: `${element.page_name}`, value: element.id });
  });
  return dropDownValues;
};

export const getAllIds = (data) => {
  const idsContainer = [];
  data.forEach((element) => {
    idsContainer.push(element.value);
  });
  idsContainer.splice(0, 1);
  return idsContainer;
};

export function capitalizeFirstLetter(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
}

export const ConvertToAutoCompleteFormat = (data) => {
  const autoCompleteValues = [];
  data.forEach((element) => {
    autoCompleteValues.push({
      day: element.day,
      isOpen: element.isOpen,
      fromTime: { label: element.fromTime, value: element.fromTime },
      toTime: { label: element.toTime, value: element.toTime },
    });
  });
  return autoCompleteValues;
};

export const ConvertIntoBusinessHoursFormat = (data) => {
  const businessHours = [];
  if (data) {
    data.forEach((element) => {
      businessHours.push({
        isOpen: element.isOpen,
        fromTime: element.isOpen === 'open' ? element.fromTime : null,
        toTime: element.isOpen === 'open' ? element.toTime : null,
      });
    });
  }
  return businessHours;
};

export function getParameterByName(name, url) {
  let urlValue = '';
  let nameValue = '';
  if (!url) {
    urlValue = window.location.href;
  }
  nameValue = name.replace(/[\\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${nameValue}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(urlValue);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

// export const convertIntoPhoneFormat = (phone) => {
//   if (phone) {
//     const firstThreeDigits = `(${phone.substring(2, 5)})`;
//     const secondThreeDigits = `${phone.substring(5, 8)}`;
//     const thirdThreeDigits = `${phone.substring(8, 12)}`;
//     return `${firstThreeDigits} ${secondThreeDigits}-${thirdThreeDigits}`;
//   }
//   return '';
// };
export const convertIntoPhoneFormat = (phone) => {
  if (phone) {
    // Remove any non-digit characters
    const cleanedPhone = phone.replace(/\D/g, '');

    // Check if the phone number starts with '1'
    const countryCode = cleanedPhone.startsWith('1') ? '+1 ' : '';

    // Extract the remaining digits
    const remainingDigits = cleanedPhone.slice(cleanedPhone.startsWith('1') ? 1 : 0);

    // Format the phone number
    const areaCode = remainingDigits.substring(0, 3);
    const firstThreeDigits = remainingDigits.substring(3, 6);
    const lastFourDigits = remainingDigits.substring(6);

    // Build the formatted phone number without spaces
    return `${countryCode} (${areaCode}) ${firstThreeDigits}-${lastFourDigits}`;
  }
  return '';
};

export const handleResetPasswordError = (error, id) => {
  let errorMessage;
  const errorType = error.type !== '' ? error.type : error.types.type;
  if (errorType === 'manual') {
    errorMessage = 'Password and confirm password must be same';
  } else if (errorType === 'required') {
    errorMessage = `${id} is a required field`;
  } else {
    errorMessage = `${id} atleast of 6 characters and contains atleast one digit
    and one special character`;
  }
  return errorMessage;
};

export const ConvertIntoTableFormat = (data) => {
  const finalTableData = [];
  data.forEach((element) => {
    const contact = JSON.parse(element.contact);
    finalTableData.push({
      nickname: element.nickName || element.name,
      address: contact.addressLine1 ? contact.addressLine1 : '',
      phone: convertIntoPhoneFormat(contact.phoneNo ? contact.phoneNo : ''),
      email: contact.emailAddress ? contact.emailAddress : '',
      status: element.isDeleted ? 'Inactive' : 'Active',
      id: element.id,
      type: element.type ? element.type : '',
      microSiteUrl: element.microSiteUrl,
      uuid: element.uuid,
      isDeleted: element.isDeleted ? element.isDeleted : '',
    });
  });
  return finalTableData;
};

export const handleLocationViewErrors = (errors, key) => {
  let showError = false;
  if (Object.keys(errors).length > 0) {
    if (key in errors) {
      showError = true;
    }
  } else {
    showError = false;
  }
  return showError;
};

const getLocationNames = (locationList) => {
  let locationName = '';

  if (locationList && locationList !== undefined && locationList.length) {
    locationList.map((location, index) => {
      locationName +=
        index !== 0 && index !== locationList.length
          ? `, ${location.nickName || location.name}`
          : `${location.nickName || location.name}`;
      return null;
    });
  }
  return locationName;
};

export const ConvertIntoEmployeeTableFormat = (data, roles) => {
  const finalTableData = [];
  data.forEach((element) => {
    if (element.id !== 1 && !roles.isSuperAdmin) {
      finalTableData.push({
        name: {
          name: `${element.firstName} ${element.lastName}`,
          url: element.url && element.url !== undefined ? element.url : '',
        },
        location_names: element.location_names,
        phone: convertIntoPhoneFormat(element.phoneNumber),
        email: element.emailAddress,
        linkSent: element.linkSent ? element.linkSent : 0,
        reviews: element.reviews ? element.reviews : 0,
        roles: element.roleName,
        firstName: element.firstName,
        lastName: element.lastName,
        id: element.id,
        accountType: capitalizeFirstLetter(element.accountType),
        isDeleted: element.isDeleted,
      });
    } else {
      finalTableData.push({
        name: {
          name: `${element.firstName} ${element.lastName}`,
          url: element.url && element.url !== undefined ? element.url : '',
        },
        location_names: element.location_names,
        phone: convertIntoPhoneFormat(element.phoneNumber),
        email: element.emailAddress,
        linkSent: element.linkSent ? element.linkSent : 0,
        reviews: element.reviews ? element.reviews : 0,
        roles: element.roleName,
        firstName: element.firstName,
        lastName: element.lastName,
        id: element.id,
        accountType: capitalizeFirstLetter(element.accountType),
        isDeleted: element.isDeleted,
      });
    }
  });
  return finalTableData;
};

export const ConvertIntoBusinessTableFormat = (data) => {
  const finalTableData = [];
  data.forEach((element) => {
    finalTableData.push({
      id: element.id,
      name: element.name,
      email: element.email,
      phone: convertIntoPhoneFormat(JSON.parse(element.contact).phoneNumber),
      website: JSON.parse(element.contact).webSite,
      status: element.isDeleted ? 'Inactive' : 'Active',
      type: element.industryName,
      uuid: element.uuid,
      isDeleted: element.isDeleted,
    });
  });
  return finalTableData;
};

export const handlePhoneError = (errors, name, limit) => {
  let message = '';
  const displayName = name || 'Phone Number';
  const displayLimit = limit || 10;
  if (errors) {
    const { type, isManual, types } = errors;
    if (isManual) {
      switch (types.type) {
        case 'exists':
          message = types.message;
          break;

        default:
          break;
      }
    } else {
      switch (type) {
        case 'minLength':
          message = `${displayName} must be atleast of ${displayLimit} digits`;
          break;

        case 'required':
          message = `${displayName} is a required field`;
          break;

        case 'maxLength':
          message = `${displayName} must be atmost of ${displayLimit} digits only`;
          break;

        case 'min':
          message = `${displayName} must be greater than ${displayLimit}`;
          break;

        default:
          break;
      }
    }
  }
  return message;
};

export const handleEmailError = (errors) => {
  let messageText = '';
  if (errors) {
    const { type, isManual, types } = errors;
    if (isManual) {
      switch (types.type) {
        case 'exists':
          messageText = types.message;
          break;
        default:
          break;
      }
    } else {
      switch (type) {
        case 'pattern':
          messageText = 'Please enter valid email address';
          break;

        case 'required':
          messageText = 'Email is a required field';
          break;

        default:
          break;
      }
    }
  }
  return messageText;
};

export const getValidValues = (values) => {
  const valuesContainer = [];
  values.forEach((element) => {
    if (element) {
      valuesContainer.push(element);
    }
  });
  return valuesContainer;
};

export const ConvertIntoClientTableFormat = (data) => {
  const finalClinetTableData = [];
  data.forEach((element) => {
    finalClinetTableData.push({
      name: element.name,
      contact: { email: element.email, phone: convertIntoPhoneFormat(element.phone) },
      salesRepName: element.salesRepName,
      requestSent: new Date(element.requestSent),
      opened: element.opened,
      sentiment: element.sentiment,
      leaveReviewAt: element.leaveReviewAt,
      id: element.id,
    });
  });
  return finalClinetTableData;
};

export const campaignImageUploadErrorHandling = (data) => {
  const errorsContianer = [];
  Object.keys(data).forEach((element) => {
    if (data[element] && data[element] !== undefined) {
      errorsContianer.push(false);
    } else {
      errorsContianer.push(true);
    }
  });
  return errorsContianer.includes(false);
};

export const convertIntoCampaignCards = (data) => {
  const campaignCards = [];
  data.forEach((element) => {
    campaignCards.push({
      ...element,
      name: element.name,
      message: element.message,
      campaignType: element.campaignType,
      isDeleted: element.isDeleted,
      isActive: element.isActive,
      id: element.id,
      url: element.url,
      updatedAt: element.updatedAt,
      lastSent: element.lastSent,
      canResendAfter: element.canResendAfter,
      isDefault: element.isDefault ?? false,
      uploadedFile: element.uploadedFile,
    });
  });
  return campaignCards;
};

export const modifyCampaignCards = (data, img) => {
  const campaignCards = [];
  data.forEach((element) => {
    if (img && img.id === element.id) {
      campaignCards.push({
        name: element.name,
        message: element.message,
        campaignType: element.campaignType,
        isDeleted: element.isDeleted,
        isActive: element.isActive,
        id: element.id,
        url: img.url,
        updatedAt: element.updatedAt,
      });
    }
  });
  return campaignCards;
};

export const getIdFromData = (originalData, data) => {
  return originalData.length - 1 === data.length ? 0 : parseInt(data[0].id, 10);
};

export const sortArrayUsingId = (element1, element2) => {
  if (element1.updatedAt < element2.updatedAt) {
    return -1;
  }
  if (element1.updatedAt > element2.updatedAt) {
    return 1;
  }
  return 0;
};

export const setAuthToken = (token) => {
  if (token) {
    // applying token
    baseUrl.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    // deleting the token from header
    delete baseUrl.defaults.headers.common.Authorization;
  }
};

export const ConvertIntoMultiSelectFormat = (selected, list, placeholder, value) => {
  if (selected.length <= 1 && value.length === 0) {
    return placeholder;
  }
  const selectedLabels = [];
  list.forEach((element) => {
    selected.forEach((item) => {
      if (element.value === item) {
        selectedLabels.push(element.label);
      }
    });
  });
  return selectedLabels.join(', ');
};

export const getMultiSelectIds = (data) => {
  const selectedValues = [];
  data.forEach((element) => {
    selectedValues.push(element.id);
  });
  return selectedValues;
};

export const showSelectedLocationOnCard = (listOptions, selected) => {
  const copySelected = [];

  if (selected.length > 0) {
    listOptions.forEach((item) => {
      selected.forEach((value) => {
        if (copySelected.length < 2) {
          if (item.value === value) {
            copySelected.push(item.label);
          }
        }
      });
    });
    if (selected.length > 2) copySelected.push('...');
    return copySelected.join(', ');
  }
  return 'Location';
};

export const getLocationName = (data) => {
  const locationNames = [];
  data.forEach((element) => {
    locationNames.push(element.nickName);
  });
  return locationNames.join(', ');
};

export const checkAppendZero = (num) => {
  return num < 10 ? `0${num}` : num;
};

export const getDateFromTimeStamp = (timeStamp) => {
  const date = new Date(timeStamp);
  const year = date.getUTCFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  let hours = date.getHours();
  const min = date.getMinutes();
  const period = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  hours = hours % 12 || 12;

  return `${month}/${day}/${year} ${hours}:${checkAppendZero(min)} ${period}`;
};

export const getTimeAgoString = (timeStamp) => {
  const currentTime = new Date().getTime();
  const timeDiff = currentTime - timeStamp;

  if (timeDiff < 60 * 1000) {
    return 'Just now';
  } else if (timeDiff < 60 * 60 * 1000) {
    const minutes = Math.floor(timeDiff / (60 * 1000));
    return `${minutes} minutes ago`;
  } else if (timeDiff < 24 * 60 * 60 * 1000) {
    const hours = Math.floor(timeDiff / (60 * 60 * 1000));
    return `${hours} hours ago`;
  } else if (timeDiff < 7 * 24 * 60 * 60 * 1000) {
    const days = Math.floor(timeDiff / (24 * 60 * 60 * 1000));
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayName = dayNames[new Date(timeStamp).getDay()];
    return `${dayName}`;
  } else if (timeDiff < 30 * 24 * 60 * 60 * 1000) {
    return 'Last month';
  } else if (timeDiff < 12 * 30 * 24 * 60 * 60 * 1000) {
    const months = Math.floor(timeDiff / (30 * 24 * 60 * 60 * 1000));
    return `${months} months ago`;
  } else {
    const year = new Date(timeStamp).getUTCFullYear();
    const month = new Date(timeStamp).getMonth();
    const day = new Date(timeStamp).getDate();
    return `${checkAppendZero(month + 1)}/${checkAppendZero(day)}/${checkAppendZero(year)}`;
  }
};

export const getOnlyDateFromTimeStamp = (timeStamp) => {
  const year = new Date(timeStamp).getUTCFullYear();
  const month = new Date(timeStamp).getMonth();
  const day = new Date(timeStamp).getDate();
  return `${checkAppendZero(month + 1)}/${checkAppendZero(day)}/${checkAppendZero(year)}`;
};

export const convertIntoCustomerTable = (data) => {
  const customerRows = [];
  data.forEach((element) => {
    customerRows.push({
      id: element.id,
      name: element.name,
      contact: {
        email: element.email,
        phone: convertIntoPhoneFormat(element.phone),
      },
      phone: element.phone ? convertIntoPhoneFormat(element.phone) : '',
      email: element.email,
      requestSent: element.requestSent ? getDateFromTimeStamp(element.requestSent) : '',
      opened: element.opened,
      salesRepName: element.salesRepName,
      sentiment: element.sentiment,
      leaveReviewAt: element.leaveReviewAt,
      updatedAt: element.updatedAt,
      isDeleted: element.isDeleted,
    });
  });
  return customerRows;
};

export const checkForNums = (input) => {
  let result = /^\d+$/.test(input);
  return result;
};

export const checkIFUSFormat = (phone) => {
  let finalPhone = '';
  if (phone && phone.includes('+1')) {
    finalPhone = phone;
  } else {
    finalPhone = `+1${phone}`;
  }
  return finalPhone;
};

export const convertIntoContactTable = (data) => {
  const customerRows = [];

  data.forEach((element) => {
    customerRows.push({
      id: element.id,
      name: `${element.firstName} ${element.lastName}`,
      phone: element.phoneNumber
        ? convertIntoPhoneFormat(checkIFUSFormat(element.phoneNumber))
        : '',
      email: element.emailAddress,
      requestSent: element.requestSent ? getDateFromTimeStamp(element.requestSent) : '',
      isDeleted: element.isDeleted,
      location_names: element.location_names,
    });
  });
  return customerRows;
};

export const convertIntoCustomerDropDown = (data) => {
  const customerRows = [];
  data.forEach((element) => {
    customerRows.push({
      id: element.id,
      name: `${element.firstName} ${element.lastName}`,
      phone: element.phoneNumber ? convertIntoPhoneFormat(element.phoneNumber) : '',
      email: element.emailAddress,
      suggestedBy: element.suggestedBy ?? null,
    });
  });
  return customerRows;
};

// Method to convert into the send invite contact list format
export const convertIntoContactList = (data) => {
  const customerRows = [];
  data.forEach((element) => {
    customerRows.push({
      id: element.id,
      locations: element.locations,
      name: `${element.firstName} ${element.lastName}`,
      phone: element.phoneNumber ? convertIntoPhoneFormat(element.phoneNumber) : '',
      email: element.emailAddress,
      unsubscribeEmail: element.unsubscribeEmail ? element.unsubscribeEmail : false,
      unsubscribeMobile: element.unsubscribeMobile ? element.unsubscribeMobile : false,
    });
  });
  return customerRows;
};

export const convertIntoCampaignClientTable = (data) => {
  const dropDownValues = [];
  data.forEach((element) => {
    dropDownValues.push({
      id: element.id,
      name: `${element.firstName} ${element.lastName}`,
      emailAddress: element.emailAddress,
      phoneNumber: convertIntoPhoneFormat(element.phoneNumber),
      unsubscribe: element.unsubscribeMobile || element.unsubscribeEmail,
    });
  });
  return dropDownValues;
};

export const getSelectedRowsId = (data, originalData) => {
  const rowsId = [];
  data.forEach((element) => {
    rowsId.push(originalData[element].id);
  });
  return rowsId;
};

export const ConvertIntoPortalTableFormat = (data) => {
  const finalClinetTableData = [];
  data.forEach((element) => {
    finalClinetTableData.push({
      name: element.name,
      welcomeMessage: element.meta.welcomeMessage,
      positiveFeedback: element.meta.positiveFeedback,
      negativeFeedback: element.meta.negativeFeedback,
      id: element.id,
    });
  });
  return finalClinetTableData;
};

export const roleChecker = (roles) => {
  const roleContainer = [];
  const superAdmin = 'Super Admin';
  roles.forEach((element) => roleContainer.push(element.name));
  return roleContainer.includes(superAdmin);
};

export const businessAdminRoleChecker = (roles) => {
  const roleContainer = [];
  const admin = 'Business Admin';
  roles.forEach((element) => roleContainer.push(element.name));
  return roleContainer.includes(admin);
};

const roleCheck = (type, state) => {
  switch (type) {
    case 'Super Admin':
      return {
        ...state,
        roles: {
          ...state.roles,
          isSuperAdmin: true,
        },
        routes: {
          routesContainer: appRoutes,
          setupTabContainer: appSetupTabs,
          accountTabContainer: appAccountTabs,
          routeRedirectContainer: appRoutesRedirection,
          navListContainer: navList,
        },
      };
    case 'Business Admin':
      return {
        ...state,
        roles: {
          ...state.roles,
          isBusinessAdmin: true,
        },
        routes: {
          routesContainer: businessAdminRoutes,
          setupTabContainer: businessAdminSetupTabs,
          accountTabContainer: businessAdminAccountTabs,
          routeRedirectContainer: businessAdminRoutesRedirection,
          navListContainer: navList,
        },
      };

    case 'Admin':
      return {
        ...state,
        roles: {
          ...state.roles,
          isAdmin: true,
        },
        routes: {
          routesContainer: adminRoutes,
          setupTabContainer: adminSetupTabs,
          accountTabContainer: adminAccountTabs,
          routeRedirectContainer: adminRoutesRedirection,
          navListContainer: navList,
        },
      };

    case 'Manager':
      return {
        ...state,
        roles: {
          ...state.roles,
          isManager: true,
        },
        routes: {
          routesContainer: managerRoutes,
          setupTabContainer: managerSetupTabs,
          accountTabContainer: managerAccountTabs,
          routeRedirectContainer: managerRoutesRedirection,
          navListContainer: managerNavList,
        },
      };

    case 'Employee':
      return {
        ...state,
        roles: {
          ...state.roles,
          isEmployee: true,
        },
        routes: {
          routesContainer: empRoutes,
          setupTabContainer: empSetupTabs,
          accountTabContainer: empAccountTabs,
          routeRedirectContainer: empRoutesRedirection,
          navListContainer: empNavList,
        },
      };

    default:
      return { ...state };
  }
};

export const roleHandling = (roles, state) => {
  return roles.map((item) => roleCheck(item.name, state))[0];
};

export const createDynamicTableHeader = (
  columns,
  handleCheck,
  permissionContainer,
  checkBoxTheme,
) => {
  const columnsContainer = [
    {
      name: 'permission',
      label: 'Permissions',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <StyledTableCell>
              {capitalizeFirstLetter(value.split('_')[0])}
              <br />
              {value.split('_')[1]}
            </StyledTableCell>
          );
        },
      },
    },
  ];
  columns.forEach((element) => {
    columnsContainer.push({
      name: element.name,
      label: element.name.replace(/ /g, '').toLowerCase(),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ThemeProvider theme={checkBoxTheme}>
              <StyledCheckbox
                checked={value}
                onChange={(e) => {
                  updateValue(e.target.checked);
                  handleCheck(e.target.checked, tableMeta.rowIndex, tableMeta.columnIndex);
                }}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </ThemeProvider>
          );
        },
      },
    });
  });
  return columnsContainer;
};

export const getCurrentPermissionValue = (name, defaultPermissionContainer, permissiomId) => {
  let defaultValue = false;
  if (name !== 'Permissions') {
    // console.log(name, defaultPermissionContainer, permissiomId);
    defaultPermissionContainer.filter((element) => {
      if (element[name] !== undefined) {
        element[name].filter((el) => {
          if (el.id === permissiomId) {
            defaultValue = true;
          }
          return 0;
        });
      }
      return 0;
    });
  }
  return defaultValue;
};

export const createColumnsSkeleton = (columns, id, name, defaultPermissionContainer) => {
  const columnObj = {};
  columns.forEach((element, index) => {
    Object.assign(columnObj, {
      [element.name]:
        index === 0
          ? name
          : getCurrentPermissionValue(element.label, defaultPermissionContainer, id),
    });
  });
  return columnObj;
};

export const convertIntoColumnName = (defaultPermissions, columns, role) => {
  const array = [];
  const columnsArray = Array.from(columns.splice(1, columns.length + 1));
  defaultPermissions.forEach((element, index) => {
    array.push({
      [columnsArray[index].label]: element[role[index].id],
    });
  });
  return array;
};

export const convertIntoPermissionTable = (columns, data, rolePermission, defaultPermissions) => {
  const permissionData = [];
  const defaultPermissionContainer = convertIntoColumnName(
    defaultPermissions,
    Array.from(columns),
    rolePermission,
  );
  data.forEach((element) => {
    permissionData.push(
      createColumnsSkeleton(
        columns,
        element.id,
        `${element.name}_(${element.action})`,
        defaultPermissionContainer,
      ),
    );
  });
  return permissionData;
};

export const getPermissionsIds = (data) => {
  const permissionIdCotnainer = [];
  data.forEach((element) => {
    permissionIdCotnainer.push(element.id);
  });
  return permissionIdCotnainer;
};

const getPermissionIdByName = (data, permissions) => {
  const idsArray = [];
  permissions.forEach((element) => {
    data.forEach((item) => {
      if (item.split('_')[0] === element.name) {
        idsArray.push(element.id);
      }
    });
  });
  return idsArray;
};

const getRoleIdByName = (name, roles) => {
  let id = null;
  roles.forEach((item) => {
    if (item.name === name) {
      id = item.id;
    }
  });
  return id;
};

const extractSameRolePermissions = (permissionArray, permissions, roles) => {
  const finalData = [];
  const superAdmin = [];
  const admin = [];
  const businessAdmin = [];
  const employee = [];
  const manager = [];
  permissionArray.forEach((el) => {
    Object.keys(el).forEach((item) => {
      if (item === 'Super Admin') {
        superAdmin.push(el[item]);
      } else if (item === 'Admin') {
        admin.push(el[item]);
      } else if (item === 'Business Admin') {
        businessAdmin.push(el[item]);
      } else if (item === 'Employee') {
        employee.push(el[item]);
      } else if (item === 'Manager') {
        manager.push(el[item]);
      }
    });
  });
  finalData.push({
    [getRoleIdByName('Super Admin', roles)]: getPermissionIdByName(superAdmin, permissions),
  });
  // finalData.push({
  //   [getRoleIdByName('Admin', roles)]: getPermissionIdByName(admin, permissions),
  // });
  finalData.push({
    [getRoleIdByName('Business Admin', roles)]: getPermissionIdByName(businessAdmin, permissions),
  });
  finalData.push({
    [getRoleIdByName('Employee', roles)]: getPermissionIdByName(employee, permissions),
  });
  finalData.push({
    [getRoleIdByName('Manager', roles)]: getPermissionIdByName(manager, permissions),
  });
  return finalData;
};

export const convertIntoPermissionPostData = (permissions, tableData, roles) => {
  const permissionArray = [];
  tableData.forEach((element) => {
    Object.keys(element).forEach((item) => {
      if (element[item] === true) {
        permissionArray.push({ [item]: element.permission });
      }
    });
  });
  return extractSameRolePermissions(permissionArray, permissions, roles);
};

export const errorChecker = (err) => {
  if (err && err.response && err.response.data && typeof err.response.data.message === 'string') {
    return err.response.data.message;
  }
  return 'Something went wrong';
};

export const reviewerrorChecker = (err) => {
  return err;
};

export const ConvertIntoPostSitesTableFormat = (data) => {
  const finalClinetTableData = [];
  data.forEach((element) => {
    finalClinetTableData.push({
      name: element.name,
      siteTag: element.siteTag || '',
      authRequired: element.authRequired ? 'Yes' : 'No',
      locationListViewIcon: '',
      chartIcon: '',
      id: element.id,
    });
  });
  return finalClinetTableData;
};

export const convertIntoIndustryTable = (data) => {
  const categoryIndustryRows = [];
  data.forEach((element) => {
    categoryIndustryRows.push({
      id: element.id,
      name: element.name,
      status: element.isDeleted ? 'Inactive' : 'Active',
    });
  });
  return categoryIndustryRows;
};

export const convertIntoSeoTable = (data) => {
  const categorySeoRows = [];
  data.forEach((element) => {
    categorySeoRows.push({
      id: element.id,
      name: element.name,
    });
  });
  return categorySeoRows;
};

// Helper method to get the resultant apiEnd point for server side pagination,search and sort
export const getPaginationUrl = (
  apiEndPoint,
  searchValue,
  sortDirection,
  page,
  rowsPerPage,
  searchKey,
  sortKey,
  extraParameter,
  extraParameterDepends,
  // extraParameterPagination,
  // extraParameterPaginationDepends,
  secondParameter,
  secondParameterDepends,
  defaultSkip,
) => {
  let finalUrl = '';
  const initUrl = `${apiEndPoint}?`;
  finalUrl = initUrl;
  const searchCondition = {
    [searchKey]: { contains: encodeURIComponent(searchValue) },
  };
  const searchUrl = `&where=${JSON.stringify(searchCondition)}`;
  const sortUrl = `&sort=${sortKey} ${sortDirection}`;

  if (searchValue) {
    finalUrl = `${finalUrl}${searchUrl}`;
  }
  if (sortDirection) {
    finalUrl = `${finalUrl}${sortUrl}`;
  }
  if (extraParameterDepends) {
    finalUrl = `${finalUrl}&${extraParameter}=${extraParameterDepends}`;
  }
  // if (extraParameterPaginationDepends) {
  //   finalUrl = `${finalUrl}&${extraParameterPagination}=${extraParameterPaginationDepends}`;
  // }
  if (secondParameterDepends) {
    finalUrl = `${finalUrl}&${secondParameter}=${secondParameterDepends}`;
  }
  if (defaultSkip && defaultSkip !== undefined) {
    finalUrl = `${finalUrl}&skip=${defaultSkip}`;
  }

  return finalUrl;
};

// Helper method to get the resultant apiEnd point for server side pagination,search and sort
export const getPaginationUrl1 = (
  apiEndPoint,
  searchValue,
  page,
  rowsPerPage,
  extraParameter,
  extraParameterDepends,
  // extraParameterPagination,
  // extraParameterPaginationDepends,
  secondParameter,
  secondParameterDepends,
  sortBy,
  thirdParameter,
  thirdParameterDepends,
  fourthParameter,
  fourthParameterDepends,
  fifthParameter,
  fifthParameterDepends,
  sixthParameter,
  sixthParameterDepends,
  seventhParameter,
  seventhParameterDepends,
  defaultSkip,
) => {
  let finalUrl = '';
  const initUrl = `${apiEndPoint}?`;
  finalUrl = initUrl;
  if (extraParameterDepends) {
    finalUrl = `${finalUrl}&${extraParameter}=${encodeURIComponent(extraParameterDepends)}`;
  }
  // if (extraParameterPaginationDepends) {
  //   finalUrl = `${finalUrl}&${extraParameterPagination}=${encodeURIComponent(
  //     extraParameterPaginationDepends,
  //   )}`;
  // }
  if (secondParameterDepends || secondParameterDepends === 0) {
    finalUrl = `${finalUrl}&${secondParameter}=${encodeURIComponent(secondParameterDepends)}`;
  }
  if (thirdParameterDepends) {
    finalUrl = `${finalUrl}&${thirdParameter}=${encodeURIComponent(thirdParameterDepends)}`;
  }
  if (fourthParameterDepends) {
    finalUrl = `${finalUrl}&${fourthParameter}=${encodeURIComponent(fourthParameterDepends)}`;
  }
  if (fifthParameterDepends) {
    finalUrl = `${finalUrl}&${fifthParameter}=${encodeURIComponent(fifthParameterDepends)}`;
  }
  if (sixthParameterDepends) {
    finalUrl = `${finalUrl}&${sixthParameter}=${encodeURIComponent(sixthParameterDepends)}`;
  }
  if (seventhParameterDepends) {
    finalUrl = `${finalUrl}&${seventhParameter}=${encodeURIComponent(seventhParameterDepends)}`;
  }
  if (defaultSkip && defaultSkip !== undefined) {
    finalUrl = `${finalUrl}&skip=${defaultSkip}`;
  }
  if (searchValue) {
    finalUrl = `${finalUrl}&text=${encodeURIComponent(searchValue)}`;
  }
  if (sortBy) {
    finalUrl = `${finalUrl}&sort=${encodeURIComponent(sortBy)}`;
  }
  finalUrl = `${finalUrl}&page=${page}&offset=${rowsPerPage}`;

  return finalUrl;
};

export const getSearchUrl = (
  searchURL,
  firstParameter,
  secondParameter,
  firstParameterDepends,
  secondParameterDepends,
) => {
  let finalUrl = '';
  const initUrl = `${searchURL}`;
  finalUrl = initUrl;
  if (secondParameter === 'deleted' && secondParameterDepends) {
    finalUrl = `${finalUrl}&${secondParameter}=${secondParameterDepends}`;
  }
  if (firstParameter === 'deleted' && firstParameterDepends) {
    finalUrl = `${finalUrl}&${firstParameter}=${firstParameterDepends}`;
  }

  return finalUrl;
};

export const getUniqueArray = (arr1) => {
  const finalArray = arr1.filter(
    (thing, index, self) => index === self.findIndex((t) => t.id === thing.id && t.id === thing.id),
  );
  return finalArray;
};

export const isActionRequired = (data, postMetaDataContainer, defaultValue) => {
  if (postMetaDataContainer.length > 0) {
    const flagsContainer = [];
    data.forEach((item) => {
      flagsContainer.push(item.isSynced && item.isConnected);
    });
    return flagsContainer.includes(true);
  }
  return defaultValue;
};

export const ConvertIntoListingCards = (sites, metaData) => {
  const listingCardsContainer = Array.from(sites);
  listingCardsContainer.forEach((item, index) => {
    metaData.forEach((el) => {
      if (item.id === el.postSites) {
        listingCardsContainer[index].averageReviews = el.averageReviews;
        listingCardsContainer[index].isConnected = el.isConnected;
        listingCardsContainer[index].isSynced = el.isSynced;
        listingCardsContainer[index].listReviewsLink = el.listReviewsLink;
        listingCardsContainer[index].location = el.location;
        listingCardsContainer[index].postReviewLink = el.postReviewLink;
        listingCardsContainer[index].postSites = el.postSites;
        listingCardsContainer[index].totalReviews = el.totalReviews;
        listingCardsContainer[index].metaId = el.id;
        listingCardsContainer[index].metaIsDeleted = el.isDeleted;
        listingCardsContainer[index].isAuthRequired = item.authRequired;
      }
    });
  });
  return listingCardsContainer;
};

export const ConvertSyncedIntoListingCards = (sites, metaData) => {
  const listingCardsContainer = Array.from([]);
  sites.forEach((item) => {
    metaData.forEach((el) => {
      if (item.id === el.postSites) {
        listingCardsContainer.push({
          averageReviews: el.averageReviews,
          isConnected: el.isConnected,
          isSynced: el.isSynced,
          listReviewsLink: el.listReviewsLink,
          isDeleted: el.isDeleted,
          location: el.location,
          postReviewLink: el.postReviewLink,
          postSites: el.postSites,
          totalReviews: el.totalReviews,
          metaId: el.id,
          name: item.name,
          uploadedFile: item.uploadedFile,
          backgroundColor: item.backgroundColor,
        });
      }
    });
  });
  return listingCardsContainer;
};

const conversionIntoPercenatge = (value, totalReviews) => {
  let data = 0;
  if (value && totalReviews) {
    data = (value / totalReviews) * 100;
  }
  return data;
};

export const summaryDataConversion = (data) => {
  const summaryData = [];
  data.forEach((element) => {
    const progrssData = [];
    Object.keys(element.starRating).forEach((item, index) => {
      progrssData.push({
        level: index + 1,
        value: conversionIntoPercenatge(element.starRating[item], element.totalReviewCount),
        reviewsValue: element.starRating[item],
      });
    });
    summaryData.push({
      averageRating: element.averageRating,
      reviewSrc: element.reviewSrc,
      imageUrl: element.logo,
      totalReviewCount: element.totalReviewCount,
      starRating: progrssData,
      sinceReveoAverageRating: element.sinceReveoAverageRating,
      sinceReveoReviews: element.sinceReveoReviews,
    });
  });
  return summaryData;
};

const addImageUrlforRecentReview = (value) => {
  let url = '';

  switch (value) {
    case 'facebook':
      url = facebookAvatar;
      break;

    case 'google':
      url = googleAvatar;
      break;

    case 'carguru':
      url = carguruAvatar;
      break;

    default:
      break;
  }
  return url;
};

export const recentReviewsConversion = (data) => {
  const reviewData = [];
  data.forEach((element) => {
    reviewData.push({
      reviewMeta: element.reviewMeta,
      reviewSrc: element.reviewSrc,
      id: element.id,
      reviewerMeta: element.reviewerMeta,
      reviewSrcUrl: addImageUrlforRecentReview(element.reviewSrc),
    });
  });
  return reviewData;
};

export const ConvertInUserDropDownFormat = (data) => {
  const dropDownValues = [];
  data.forEach((element) => {
    dropDownValues.push({ label: `${element.firstName} ${element.lastName}`, value: element.id });
  });
  return dropDownValues;
};

export const showImageOnreview = (src) => {
  let image;
  switch (src) {
    case 'facebook':
      image = facebookAvatar;
      break;
    case 'google':
      image = googleAvatar;
      break;
    default:
      break;
  }
  return image;
};

export const convertIntoNumber = (value) => {
  switch (value) {
    case 'FIVE':
      return 5;
    case 'FOUR':
      return 4;
    case 'THREE':
      return 3;
    case 'TWO':
      return 2;
    case 'ONE':
      return 1;
    default:
      return 0;
  }
};

export const ConvertIntoFilterFormat = (data) => {
  const finalTableData = [];
  const removeExtraQuotes = (element) => {
    return element.replace(/\n\s*/g, ' ');
  };

  data.forEach((element, index) => {
    const reviewerMeta =
      typeof element.reviewerMeta === 'object'
        ? element.reviewerMeta
        : JSON.parse(element.reviewerMeta);

    const reviewMeta =
      typeof element.reviewMeta === 'object' ? element.reviewMeta : JSON.parse(element.reviewMeta);

    const reviewReply = element.reviewReply
      ? typeof element.reviewMeta === 'object'
        ? element.reviewReply
        : JSON.parse(removeExtraQuotes(element.reviewReply))
      : null;
    finalTableData.push({
      profileName: element.name || reviewerMeta.displayName,
      // eslint-disable-next-line no-restricted-globals
      maxRat: isNaN(Number(element.rating))
        ? convertIntoNumber(element.rating) || convertIntoNumber(reviewMeta.starRating)
        : Number(element.rating),
      // eslint-disable-next-line no-restricted-globals
      minRat: isNaN(Number(element.rating))
        ? convertIntoNumber(element.rating) || convertIntoNumber(reviewMeta.starRating)
        : Number(element.rating),
      reviewSrc: element.reviewSrc,
      AttributedUser: element.user ? element.user : '',
      comment: element.comment ? element.comment : '' || reviewMeta.comment,
      logo:
        element.logo && element.logo !== undefined && element.logo.length
          ? element.logo[0].url
          : '',
      id: element.id,
      metaData: element,
      createdAt:
        reviewMeta.createTime &&
        reviewMeta.createTime !== undefined &&
        reviewMeta.createTime !== 'STAR_RATING_UNSPECIFIED'
          ? reviewMeta.createTime
          : element.createdAt,
      authRequired: element.authRequired,
      listReviewLink: element.listReviewLink,
      reviewReply,
      reviewId: element.reviewId,
      updatedAt: element.updatedAt,
      name: element.name,
      businessName: element.businessName,
      locationName: element.locationName,
      userName: element.userName ? element.userName : '',
      replyId: element.user,
    });
  });
  return finalTableData;
};

export const getMonth = (monthId) => {
  let month;
  switch (monthId) {
    case 1:
      month = 'jan';
      break;
    case 2:
      month = 'feb';
      break;
    case 3:
      month = 'mar';
      break;
    case 4:
      month = 'apr';
      break;
    case 5:
      month = 'may';
      break;
    case 6:
      month = 'jun';
      break;
    case 7:
      month = 'jul';
      break;
    case 8:
      month = 'aug';
      break;
    case 9:
      month = 'sep';
      break;
    case 10:
      month = 'oct';
      break;
    case 11:
      month = 'nov';
      break;
    case 12:
      month = 'dec';
      break;

    default:
      break;
  }

  return month;
};

// Method to get array of object from object of object
export const getArrayFromObjectOfObject = (data) => {
  const dataContainer = [];
  Object.keys(data).forEach((item) => {
    dataContainer.push(data[item]);
  });
  return dataContainer;
};

export const ConvertIntoUserGoalTableFormat = (users, goals, year) => {
  const goalsContainer = {};
  users.forEach((user) => {
    goals.forEach((goalResult) => {
      goalResult.data.forEach((goalData) => {
        if (user.id === goalData.user && year === goalData.year) {
          if (Object.keys(goalsContainer).includes(`${user.id}`)) {
            goalsContainer[user.id] = {
              ...goalsContainer[user.id],
              [getMonth(goalData.month)]: { total: goalData.total, id: goalData.id },
            };
          } else {
            Object.assign(goalsContainer, {
              [user.id]: {
                name: `${user.firstName} ${user.lastName}`,
                id: user.id,
                [getMonth(goalData.month)]: { total: goalData.total, id: goalData.id },
              },
            });
          }
        }
      });
    });
  });
  return getArrayFromObjectOfObject(goalsContainer);
};

export const ConvertInFilterFormat = (data) => {
  const Values = [];
  data.forEach((element) => {
    Values.push({
      name: `${element.firstName} ${element.lastName}`,
      data: element,
    });
  });
  return Values;
};
export const ConvertInUserDropDownFormatForFilter = (data) => {
  const dropDownValues = [
    {
      value: '',
      label: 'All User',
    },
  ];
  data.forEach((element) => {
    dropDownValues.push({
      label: `${element.firstName} ${element.lastName}`,
      value: element.id,
      id: element.id,
    });
  });
  return dropDownValues;
};

export const conversationsFilter = (filter, data) => {
  let conversationsContainer = [];
  let filterType;
  const userId = parseInt(localStorage.getItem('USER_ID'));

  data.forEach((item) => {
    if (filter === 'all') {
      filterType = item.messages[0].type !== 'review';
    } else if (
      filter === 'conversations' ||
      filter === 'attributed' ||
      //filter === 'unattributed' ||
      (filter === 'review' && item.messages[0].attributed === null) ||
      item.messages[0].attributed === undefined
    ) {
      filterType = item.messages[0].type === filter;
    } else if (filter === 'attributedMe') {
      //console.log(item);
      filterType =
        //item.messages[0].type === 'attributed' ||
        // (item.messages[item.messages.length - 1].type === 'review' &&
        item.messages[item.messages.length - 1].attributed === userId;
    } else if (filter === 'unattributed') {
      filterType =
        item.messages[0].attributed === null || item.messages[0].attributed === undefined;
    } else if (filter === 'leads') {
      filterType = item.messages[0].source === 'text message';
    } else if (filter === 'survey') {
      filterType = item.messages[0].source !== 'reviews';
    } else {
      filterType = item.inbox === filter;
    }
    if (item.messages && item.messages.length && filterType) {
      conversationsContainer.push(item);
    }
  });
  return conversationsContainer;
};

export const getAttributedUserList = (userList) => {
  const users = [];
  userList.forEach((user) => {
    users.push({
      id: user.data.id,
      name: `${user.data.firstName} ${user.data.lastName}`,
      phoneNumber: user.data.phoneNumber,
      src: user.data.uploadedFile.length > 0 ? user.data.uploadedFile[0].url : null,
    });
  });
  return users;
};

export const getCampaignType = (type) => {
  switch (type) {
    case 'sms':
      return 'Text and Image';
    case 'email':
      return 'Email Review Request';
    case 'mms':
      return 'MMS Review Request';
    case 'review request':
      return 'Reviews';
    case 'notification':
      return 'Notification';
    case 'survey':
      return 'Survey';

    default:
      return 'Unknown';
  }
};

export const getTypeBadge = (type) => {
  switch (type) {
    case 'survey':
      return <SurveyBadge>{type}</SurveyBadge>;
    case 'review request':
      return <ReviewRequestBadge>{type}</ReviewRequestBadge>;
    case 'email template':
      return <EmailTemplateBadge>{type}</EmailTemplateBadge>;
    case 'message':
      return <MessageBadge>{type}</MessageBadge>;
    case 'social post':
      return <SocialImageBadge>{type}</SocialImageBadge>;
    case 'default':
      return <DefaultBadge>{type}</DefaultBadge>;
    default:
      return <></>;
  }
};

export function getParaByName(name, url) {
  let urlValue = '';
  let nameValue = '';
  if (!url) {
    urlValue = window.location.href;
  }
  nameValue = name.replace(/[\\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${nameValue}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(urlValue);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const getYear = (nextYearsLength, prevYearLength, nextInitZero) => {
  const yearContainer = [];
  const currentYear = new Date().getFullYear();
  const yearsLength = nextInitZero ? 0 : Number(nextYearsLength) || 1;
  const previousYearLength = Number(prevYearLength) || 0;
  for (let i = currentYear - previousYearLength; i <= currentYear + yearsLength; i += 1) {
    yearContainer.push(i);
  }
  return yearContainer;
};

export const getYearDropDown = (nextYrsLen = 0, prevYrsLen = 0, nextInitZero = false) => {
  const years = getYear(nextYrsLen, prevYrsLen, nextInitZero);
  const yearsDropdownContainer = [];
  years.forEach((year) => {
    yearsDropdownContainer.push({
      label: year,
      value: year,
    });
  });
  return yearsDropdownContainer;
};

export const getSelectedLeaderBoardOption = (type) => {
  switch (type) {
    case 'requestSent':
      return 'Reviews Requested';

    case 'response':
      return 'Reviews Clicked';

    case 'clicked':
      return 'Reviews Opened';

    case 'ratings':
      return 'Average Rating';

    case 'feedbacks':
      return 'Feedback count';

    default:
      return 'Reviews Requested';
  }
};

export const convertIntoPostPhoneFormat = (phone) => {
  return `+1${phone.substr(1, 3)}${phone.substr(6, 3)}${phone.substr(10, 4)}`;
};

export const getDefaultPortalViews = (portals) => {
  // Views container
  let viewsContainer = {};
  portals.forEach((portal) => {
    if (!portal.meta.skipWelcome && portal.meta.skipPositiveFeedback) {
      viewsContainer = {
        ...viewsContainer,
        guided: {
          screens: ['sms', 'postitiveFeedback', 'thankyou'],
          info: { meta: portal.meta, id: portal.id },
        },
      };
    } else if (portal.meta.skipWelcome && !portal.meta.skipPositiveFeedback) {
      viewsContainer = {
        ...viewsContainer,
        simple: {
          screens: ['sms', 'recommend', 'thankyou'],
          info: { meta: portal.meta, id: portal.id },
        },
      };
    } else if (!portal.meta.skipWelcome && !portal.meta.skipPositiveFeedback) {
      viewsContainer = {
        ...viewsContainer,
        semiFiltered: {
          screens: ['sms', 'recommend', 'postitiveFeedback', 'sendfeedback', 'thankyou'],
          info: { meta: portal.meta, id: portal.id },
        },
      };
    }
  });
  return viewsContainer;
};

export const getGraphSeries = (data, key, type, name, isFoat = false) => {
  // Get Series object with the data for a graph
  const dataContainer = [];
  data.forEach((item) => {
    isFoat
      ? dataContainer.push(parseFloat(item[key]).toFixed(2) || 0)
      : dataContainer.push(Math.ceil(item[key], 2) || 0);
  });
  return {
    name,
    data: dataContainer,
    type,
  };
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getPortlaName = (type) => {
  switch (type) {
    case 'simple':
      return 'Simple';

    case 'semiFiltered':
      return 'Semi-Filtered';

    case 'guided':
      return 'Guided';

    default:
      return 'Simple';
  }
};

export const getPortalData = (data) => {
  const obj = {};
  data.forEach((el) => {
    Object.assign(obj, {
      [Object.keys(el.meta)[0]]: {
        ...el.meta[Object.keys(el.meta)[0]],
        id: el.id,
      },
    });
  });
  return obj;
};

export const getSelectedPortalInfo = (data, selectedPortal, businessId) => {
  return {
    name: selectedPortal,
    meta: { [selectedPortal]: data[selectedPortal] },
    business: businessId,
    createdBy: parseInt(localStorage.getItem('USER_ID'), 10),
  };
};

export const getActivePostsites = (postSites, meta) => {
  const postSitesInfo = [];
  postSites.forEach((el) => {
    meta.forEach((el1) => {
      if (el.id === el1.postSites && el1.isDeleted) {
        postSitesInfo.push({
          name: el.name,
          postReviewLink: el1.postReviewLink,
          listReviewsLink: el1.listReviewsLink,
          backgroundColor: el.backgroundColor,
          url:
            el.uploadedFile && el.uploadedFile !== undefined && el.uploadedFile.length
              ? el.uploadedFile[0].url
              : '',
        });
      }
    });
  });
  return postSitesInfo;
};

export const getSocialSiteLastIndex = (screens) => {
  let lastIndex = null;

  screens.forEach((el, index) => {
    if (el.screen === 'social-site') {
      lastIndex = index;
    }
  });

  return lastIndex + 1;
};

export const getEmpRoutes = (value) => {
  switch (value) {
    case 0:
      return '/app/setup/users';
    case 1:
      return '/app/account/contacts';
    case 2:
      return '/app/setup/goals';
    case 3:
      return '/app/setup/permissions';

    default:
      return '/app/setup/users';
  }
};

export const getEmpValues = (value) => {
  switch (value) {
    case '/app/setup/users':
      return 0;
    case '/app/account/contacts':
      return 1;
    case '/app/setup/goals':
      return 2;
    case '/app/setup/permissions':
      return 3;

    default:
      return 0;
  }
};

export const getFeedbackRoutes = (value) => {
  switch (value) {
    case 0:
      return '/app/feedback/review-request';
    case 1:
      return '/app/feedback/survey';
    case 2:
      return '/app/feedback/message';

    default:
      return '/app/feedback/review-request';
  }
};

export const getFeedbackValues = (value) => {
  switch (value) {
    case '/app/feedback/review-request':
      return 0;
    case '/app/feedback/survey':
      return 1;
    case '/app/feedback/message':
      return 2;

    default:
      return 0;
  }
};

export const getSurveyRoutes = (value) => {
  switch (value) {
    case 0:
      return '/app/survey/active-survey';
    case 1:
      return '/app/survey/completed-survey';
    case 2:
      return '/app/survey/paused-survey';

    default:
      return '/app/survey/active-survey';
  }
};

export const getSurveyValues = (value) => {
  switch (value) {
    case '/app/survey/active-survey':
      return 0;
    case '/app/survey/completed-survey':
      return 1;
    case '/app/survey/paused-survey':
      return 2;

    default:
      return 0;
  }
};

export const convertIntoSearchPhoneFormat = (phone) => {
  return `${phone.substr(1, 3)}${phone.substr(6, 3)}${phone.substr(10, 4)}`;
};

export const getFeedbackPathFromType = (type) => {
  switch (type) {
    case 'review request':
      return '/app/feedback/review-request';

    case 'survey':
      return '/app/feedback/survey';

    case 'message':
      return '/app/feedback/message';

    default:
      return '/app/feedback/review-request';
  }
};

export const getSetupRoutes = (value) => {
  switch (value) {
    case 0:
      return '/app/setup/business';
    case 1:
      return '/app/setup/location';
    case 2:
      return '/app/setup/connections';
    case 3:
      return '/app/setup/widgets';
    case 4:
      return '/app/setup/auto-posting';
    case 5:
      return '/app/setup/campaigns';
    case 6:
      return '/app/setup/post-sites';
    case 7:
      return '/app/setup/seo-category';
    case 8:
      return '/app/setup/industry';
    case 9:
      return '/app/setup/goals';

    default:
      return '/app/setup/business';
  }
};

export const getSetupValues = (value) => {
  switch (value) {
    case '/app/setup/business':
      return 0;
    case '/app/setup/location':
      return 1;
    case '/app/setup/connections':
      return 2;
    case '/app/setup/widgets':
      return 3;
    case '/app/setup/auto-posting':
      return 4;
    case '/app/setup/campaigns':
      return 5;
    case '/app/setup/post-sites':
      return 6;
    case '/app/setup/seo-category':
      return 7;
    case '/app/setup/industry':
      return 8;
    case '/app/setup/goals':
      return 9;
    default:
      return 0;
  }
};

export const roleListAsPerUser = (list) => {
  const UserInfo = JSON.parse(localStorage.getItem('User'));
  const { role } = UserInfo;
  let newList = [];
  if (list.length > 0) {
    newList = list;
    switch (role[0].name.toLowerCase()) {
      case 'manager':
        newList = [];
        newList.push(list.find((element) => element.label === 'User'));
        break;
      case 'User':
        newList = [];
        newList.push(list.find((element) => element.label === 'User'));
        break;
      case 'admin':
        newList = [];
        newList.push(
          list.find((element) => element.label === 'User' || element.label === 'Manager'),
        );
        break;
      default:
        break;
    }
  }
  return newList;
};

const getURLAndMessage = (type, messageInfo) => {
  switch (type) {
    case 'campaign': {
      const obj = {
        url: messageInfo[type].image,
        message: messageInfo[[type]].message,
      };
      return obj;
    }

    default:
      return {
        url: '',
        message: '',
      };
  }
};

export const getRequestConversationInfo = (messageContainer) => {
  if (messageContainer) {
    const messages = {};
    const keys = Object.keys(messageContainer).slice(0, 1);
    keys.forEach((type) => {
      Object.assign(messages, getURLAndMessage(type, messageContainer));
    });
    return messages;
  }
  return null;
};

const getFeedbackValue = (type, element) => {
  switch (type) {
    case 'sentiment':
      return { text: '', rating: null, sentiment: element.sentiment };

    case 'rating':
      return { text: '', rating: element.rating, sentiment: null };

    case 'yesNo':
      return { text: element.recommended ? 'yes' : 'no', rating: null, sentiment: null };

    case 'optInOrOut':
      return element.subscribe ? 'Opt In' : ' Opt Out';
    case 'singleAction':
      return '';
    case 'confirmation':
      return element.recommended ? 'Confirmed' : 'Unconfirmed';

    case 'noReply':
      return '';

    default:
      return { text: '', rating: null, sentiment: null };
  }
};

export const convertIntoNotificationFeedbackTable = (data) => {
  const customerRows = [];
  data.forEach((element) => {
    customerRows.push({
      id: element.id,
      userInfo: {
        name: `${element.firstName ? element.firstName : ''} ${
          element.lastName ? element.lastName : ''
        }`,
        location: element.name,
      },
      contact: {
        email: element.emailAddress,
        phone: convertIntoPhoneFormat(element.phoneNumber),
      },
      salesRep: {
        salesPersonName: `${element.salesFirstName} ${element.salesLastName}`,
        salesPicture: element.salesPicture,
      },
      requestInfo: {
        requestSentAt: getDateFromTimeStamp(element.createdAt),
        campaignTitle: element.campaignName,
      },
      type: element.subType && element.subType !== undefined ? element.subType : '',
      optIn: element.isOpened ? getFeedbackValue(element.subType, element) : '',
    });
  });
  return customerRows;
};

export const convertIntoSurveyFeedbackTable = (data) => {
  const customerRows = [];
  data.forEach((element) => {
    customerRows.push({
      id: element.id,
      userInfo: {
        name: `${element.firstName ? element.firstName : ''} ${
          element.lastName ? element.lastName : ''
        }`,
        location: element.name,
      },
      contact: {
        email: element.emailAddress,
        phone: convertIntoPhoneFormat(element.phoneNumber),
      },
      salesRep: {
        salesPersonName: `${element.salesFirstName} ${element.salesLastName}`,
        salesPicture: element.salesPicture,
      },
      requestInfo: {
        requestSentAt: getDateFromTimeStamp(element.createdAt),
        campaignTitle: element.campaignName,
      },
      opened: element.linkClicks && element.linkClicks !== undefined ? element.linkClicks : 0,
      feedback: element.linkClicks ? getFeedbackValue(element.subType, element) : null,
    });
  });
  return customerRows;
};

export const convertIntoReviewRequestFeedbackTable = (data) => {
  const customerRows = [];
  data.forEach((element) => {
    customerRows.push({
      userInfo: {
        name: `${element.firstName ? element.firstName : ''} ${
          element.lastName ? element.lastName : ''
        }`,
        location: element.name,
      },
      contact: {
        email: element.emailAddress,
        phone: convertIntoPhoneFormat(element.phoneNumber),
      },
      salesRep: {
        salesPersonName: `${element.salesFirstName} ${element.salesLastName}`,
        salesPicture: element.salesPicture,
      },
      requestInfo: {
        requestSentAt: element.isDeleted ? 'Blocked' : getDateFromTimeStamp(element.createdAt),
        campaignTitle: element.campaignName,
      },
      // eslint-disable-next-line no-nested-ternary
      linkClicks: element.isOpened
        ? element.linkClicks && element.linkClicks !== undefined
          ? element.linkClicks
          : 1
        : 0,
      reviewAt: {
        src:
          element.isOpened && element.reviewAt && element.reviewAt !== undefined
            ? element.reviewAt
            : '',
        logo: element.isOpened && element.postSitelogo.length ? element.postSitelogo[0].url : '',
      },
      recommended: element.linkClicks > 0 ? element.recommended : null,
    });
  });
  return customerRows;
};

// Method to get notification count

export const getNotificationCount = (notifications) => {
  let conversations = 0;
  let reports = 0;
  let reviews = 0;
  let survey = 0;
  let listings = 0;
  let feedback = 0;
  let setup = 0;
  notifications.forEach((notification) => {
    switch (notification.type) {
      case 'conversations':
        return (conversations += 1);
      case 'reports':
        return (reports += 1);
      case 'reviews':
        return (reviews += 1);
      case 'survey':
        return (survey += 1);
      case 'listings':
        return (listings += 1);
      case 'feedback':
        return (feedback += 1);
      case 'setup':
        return (setup += 1);

      default:
        return {
          conversations: 0,
          reports: 0,
          reviews: 0,
          survey: 0,
          listings: 0,
          feedback: 0,
          setup: 0,
        };
    }
  });
  return {
    conversations,
    reports,
    reviews,
    survey,
    listings,
    feedback,
    setup,
  };
};

// Method to convert into group table data

export const getGroupTableData = (rows) => {
  const groupDataContainer = [];
  rows.forEach((element) => {
    groupDataContainer.push({
      name: `${element.firstName} ${element.lastName}`,
      ...element,
    });
  });
  return groupDataContainer;
};

//  Method to remove uploaded contacts not selected

export const getSelectedContacts = (existingList, selectedIds) => {
  const finalList = [];
  const existingListContainer = Array.from(existingList);
  existingListContainer.forEach((element, index) => {
    selectedIds.forEach((el) => {
      if (element.id === el) {
        if (element.isEditMode !== undefined) {
          delete existingListContainer[index].isEditMode;
        }
        finalList.push(element);
      }
    });
  });
  return finalList;
};
// Method to handle Pattern errors
export const handlePatternError = (errors, fieldName) => {
  let messageText = '';
  if (errors) {
    const { type, isManual, types } = errors;
    if (isManual) {
      switch (types.type) {
        case 'exists':
          messageText = types.message;
          break;
        default:
          break;
      }
    } else {
      switch (type) {
        case 'pattern':
          messageText = `Please enter valid ${fieldName.toLowerCase()} address`;
          break;

        case 'required':
          messageText = `${fieldName} is a required field`;
          break;

        default:
          break;
      }
    }
  }
  return messageText;
};

//  Method to remove uploaded contacts not selected

export const selectAllContacts = (contactList, key) => {
  const keyContainer = key || 'unsubscribeMobile';
  const finalList = [];
  contactList.forEach((element) => {
    if (!element[keyContainer]) {
      finalList.push(element.id);
    }
  });

  return finalList;
};

export const getArrayOfObjectForm = (list, key) => {
  const finalArray = [];

  list.forEach((element) => {
    finalArray.push({
      [key]: element,
    });
  });

  return finalArray;
};

// Method to get the group members info in list format

export const getGroupMemberList = (list) => {
  const finalList = [];
  list.forEach((el) => {
    finalList.push({
      name: el.name,
      id: el.id,
      membersCount: el.totalmembers,
    });
  });
  return finalList;
};

// Convert into survey dashboard comment json format

export const convertIntoSurveyComment = (data) => {
  const finalData = [];
  data.forEach((el) => {
    finalData.push({
      name: `${el.firstName} ${el.lastName}`,
      phone: convertIntoPhoneFormat(el.phoneNumber),
      email: el.emailAddress,
      timeAgo: el.createdAt,
      result: getFeedbackValue(el.subType, el),
      message: el.feedback,
    });
  });
  return finalData;
};

// Convert into survey dashboard filter json format

export const convertIntoSurveyFilterCampaignList = (data) => {
  const finalData = [{ value: '', label: 'All', subType: '' }];
  data.forEach((el) => {
    finalData.push({
      value: el.id,
      label: el.name,
      subType: el.subType,
    });
  });
  return finalData;
};

export const getAttributedDropDownList = (data) => {
  const finalData = [{ value: '', label: 'All' }];
  data.forEach((el) => {
    finalData.push({
      value: el.id,
      label: `${el.firstName} ${el.lastName}`,
    });
  });
  return finalData;
};

export const getCurrentAndPreviousDate = (timeStamp) => {
  let finalDateMessage;

  const prevYear = new Date(timeStamp).getFullYear();
  const prevMonth = new Date(timeStamp).getMonth();
  const prevDate = new Date(timeStamp).getDate();

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const currentDate = new Date().getDate();

  if (prevYear === currentYear) {
    finalDateMessage = `${monthsArray[currentMonth]} ${currentDate} - ${monthsArray[prevMonth]} ${prevDate}, ${prevYear}`;
  } else {
    finalDateMessage = `${monthsArray[currentMonth]} ${currentDate}, ${currentYear} - ${monthsArray[prevMonth]} ${prevDate}, ${prevYear}`;
  }
  return finalDateMessage;
};

export const getTagsList = (data) => {
  const finalData = [];
  const dataContainer = [...new Set(data.map((x) => x.tags))];
  dataContainer.forEach((el) => {
    if (el !== '') {
      finalData.push({
        value: el,
        title: el,
      });
    }
  });
  return finalData;
};

export const getTagsListDropDown = (data) => {
  const finalData = [];
  const dataContainer = [...new Set(data.map((x) => x.tags))];
  finalData.push({ label: 'All', value: '' });
  dataContainer.forEach((el) => {
    if (el !== '') {
      finalData.push({
        value: el,
        label: el,
      });
    }
  });

  return finalData;
};

export const getImagesInfoFromData = (imagesInfo) => {
  const finalData = [];

  imagesInfo.forEach((el) => {
    finalData.push({
      imgId: el.id,
      id: el.id,
      fileId: el.fileId,
      url: el.url,
      templateType: el.templateType,
    });
  });
  return finalData;
};

export const getTemplateListTableData = (data) => {
  const finalData = [];

  data.forEach((el) => {
    finalData.push({
      id: el.id,
      templateType: el.templateType,
      campaignType: el.campaignType,
      url: `${process.env.REACT_APP_IMAGE_ENDPOINT}${el.url}`,
      industry: el.name ? el.name : null,
    });
  });
  return finalData;
};

// Function to convert array of array of object to array of object

export const convertIntoArrayOfObj = (data) => {
  const finalData = [];

  data.forEach((el) => {
    if (el.length) {
      el.forEach((el1) => {
        finalData.push(el1);
      });
    }
  });

  return finalData;
};

// Function to check if the selected campaign is present in the updated campaign list

export const checkCampaignPresent = (data, selectedCampaign) => {
  let campaignPresent = false;

  data.forEach((el) => {
    if (el.value === selectedCampaign) {
      campaignPresent = true;
    }
  });
  return campaignPresent;
};

export const getExtraParameter = (type) => {
  switch (type) {
    case 'active':
      return 'status';

    case 'in-active':
      return 'status';

    case 'deleted':
      return 'deleted';

    default:
      return 'type';
  }
};

export const getExtraParameterValue = (type) => {
  switch (type) {
    case 'active':
      return 1;

    case 'in-active':
      return 0;

    case 'deleted':
      return 1;

    default:
      return type;
  }
};

export function validateURL(str) {
  let finalString = '';
  if (str.indexOf('http://') === 0 || str.indexOf('https://') === 0) {
    finalString = str;
  }
  finalString = `https://${str}`;
  return finalString;
}

export function getURLWithoutPrefix(str) {
  let finalString = str;
  if (str) {
    if (str.indexOf('http://') === 0) {
      finalString = str.split('http://');
    } else if (str.indexOf('https://') === 0) {
      finalString = str.split('https://');
    }
    return finalString[1];
  }
  return '';
}

//  Method to get multi-select array of ids
export const getValueFromArray = (list, key) => {
  const finalArray = [];
  if (list && list !== undefined && list.length) {
    list.forEach((item) => {
      finalArray.push(item[key]);
    });
  }

  return finalArray;
};

export const getMultiSelectSelectedRecords = (list, allList) => {
  const finalArray = [];
  if (list && list !== undefined && list.length) {
    allList.forEach((el) => {
      list.forEach((item) => {
        if (el.value === item.id) {
          finalArray.push({
            label: item.name,
            value: item.id,
          });
        }
      });
    });
  }

  return finalArray;
};

export const checkIfValuePresent = (list, value) => {
  let finalValue = false;
  list.forEach((item) => {
    if (item.value === value.value) {
      finalValue = true;
    }
  });
  return finalValue;
};

export const convertIntoBusinessCardPhoneFormat = (phone) => {
  if (phone) {
    const firstThreeDigits = `${phone.substring(0, 3)}`;
    const secondThreeDigits = `${phone.substring(3, 6)}`;
    const thirdThreeDigits = `${phone.substring(6, 10)}`;
    return `${firstThreeDigits}.${secondThreeDigits}.${thirdThreeDigits}`;
  }
  return '';
};

export const getMetaImage = (list, meta) => {
  let finalURL = '';
  list.forEach((item) => {
    if (item.meta === meta) {
      finalURL = item.url;
    }
  });
  return finalURL;
};

export const getGalleryPopupTabValue = (type) => {
  switch (type) {
    case 'email':
      return 1;
    case 'sms':
      return 0;
    case 'socialimage':
      return 2;
    default:
      return 0;
  }
};

export const getLocalStorageItem = (key, defaultValue) => {
  return localStorage.getItem(key) && localStorage.getItem(key) !== undefined
    ? JSON.parse(localStorage.getItem(key))
    : defaultValue;
};

export const checkObjectArrayIsEmpty = (obj) => {
  let isEmpty = false;
  Object.keys(obj).forEach((key) => {
    if (obj[key].length) {
      isEmpty = false;
    } else {
      isEmpty = true;
    }
  });

  return isEmpty;
};

export const imageURLCheck = (url) => {
  let finalURL = '';
  if (!url || url === undefined) {
    return finalURL;
  }
  if (url.includes('https') || url.includes('https')) {
    finalURL = url;
  } else {
    finalURL = `${process.env.REACT_APP_IMAGE_ENDPOINT}${url}`;
  }
  return finalURL;
};

// Method to get reviews sites

export const ConvertReviewSitesFormat = (sites, metaData) => {
  const listingCardsContainer = Array.from([
    {
      value: '',
      label: 'All',
    },
    {
      value: 'reveo',
      label: 'Reveo',
    },
  ]);
  sites.forEach((item) => {
    metaData.forEach((el) => {
      if (item.id === el.postSites && el.isDeleted) {
        listingCardsContainer.push({
          value: item.name,
          label: item.name,
        });
      }
    });
  });
  return listingCardsContainer;
};

export const getReportsRoutes = (value) => {
  switch (value) {
    case 0:
      return '/app/reports/summary';
    case 1:
      return '/app/reports/rating';
    case 2:
      return '/app/reports/conversions';
    case 3:
      return '/app/reports/leaderboard';
    case 4:
      return '/app/reports/surveys';
    case 5:
      return '/app/reports/sentiment';
    case 6:
      return '/app/reports/goals';
    case 7:
      return '/app/reports/reputation';
    default:
      return '/app/reports';
  }
};

export const getReportsValues = (value) => {
  switch (value) {
    case '/app/reports/summary':
      return 0;
    case '/app/reports/rating':
      return 1;
    case '/app/reports/conversions':
      return 2;
    case '/app/reports/leaderboard':
      return 3;
    case '/app/reports/surveys':
      return 4;
    case '/app/reports/sentiment':
      return 5;
    case '/app/reports/goals':
      return 6;
    case '/app/reports/reputation':
      return 7;

    default:
      return 0;
  }
};

export const getParaByPara = (name, url = window.location.href) => {
  const paraname = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${paraname}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const filterByReference = (arr1, arr2, key = 'name') => {
  let res = [];
  res = arr1.filter((el) => {
    return !arr2.find((element) => {
      return element[key] === el[key];
    });
  });
  return res;
};

export const getMonthAndYear = (next, prev) => {
  const monthsYearContainer = [];

  for (let i = prev; i >= 1; i -= 1) {
    const prevCurrentDate = new Date();
    prevCurrentDate.setMonth(prevCurrentDate.getMonth() - i);
    const updatedDate = {
      month: prevCurrentDate.getMonth() + 1,
      year: prevCurrentDate.getFullYear(),
    };
    monthsYearContainer.push(updatedDate);
  }
  monthsYearContainer.push({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });
  const nextCurrentDate = new Date();
  for (let i = 1; i <= next; i += 1) {
    nextCurrentDate.setMonth(nextCurrentDate.getMonth() + 1);
    const updatedDate = {
      month: nextCurrentDate.getMonth() + 1,
      year: nextCurrentDate.getFullYear(),
    };
    monthsYearContainer.push(updatedDate);
  }
  return monthsYearContainer;
};

export const getMonthYearParaValue = (month, year) => {
  return `month=${month}&year=${year}`;
};

export const getMonthYearDropDown = (next = 0, prev = 0) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const monthsAndYear = getMonthAndYear(next, prev);
  const monthsYearContainer = [];
  monthsAndYear.forEach((item) => {
    monthsYearContainer.push({
      label: `${months[item.month - 1]}, ${item.year}`,
      value: getMonthYearParaValue(item.month, item.year),
    });
  });
  return monthsYearContainer;
};

export const getDateAfterDays = (days = 1) => {
  const d = new Date();
  return d.setDate(d.getDate() + days);
};

export const reviewsActionsPermission = (roles) => {
  let styles = {};
  if (roles.isEmployee) {
    styles = { visibility: 'hidden' };
  }
  return styles;
};

export const getRatingFromNameValue = (value) => {
  switch (value) {
    case 'FIVE':
      return 5;

    case 'FOUR':
      return 4;

    case 'THREE':
      return 3;

    case 'TWO':
      return 2;

    case 'ONE':
      return 1;

    default:
      return 5;
  }
};

export const getDateBeforeDays = (days) => {
  const date = new Date();
  if (days) {
    date.setDate(date.getDate() - Number(days));
  }

  return date.getTime();
};

export const ratingStringToInt = (starRating) => {
  switch (starRating) {
    case 'ONE':
      return 1;
    case 'TWO':
      return 2;
    case 'THREE':
      return 3;
    case 'FOUR':
      return 4;
    case 'FIVE':
      return 5;
    case 'POSITIVE':
      return 5;
    case 'NEGATIVE':
      return 1;
  }
};

export const capitalizeSentence = (sentence) => {
  let words = sentence.split(' ');

  let cpWords = words.map((word) => {
    let lcWord = word.toLowerCase();
    return lcWord.charAt(0).toUpperCase() + lcWord.slice(1);
  });

  return cpWords.join(' ');
};

export function getInitials(string) {
  if (string === undefined) return;
  let names = string.split(' '),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
}

export function getUserName(userList, userId) {
  let fullName;
  if (userId === null || userId === undefined) {
    fullName = 'unassigned';
  } else {
    const agentName = userList.user.filter((user) => user.id === userId);
    if (agentName.length === 0) {
      fullName = 'unknown';
    } else {
      fullName = agentName[0].firstName + ' ' + agentName[0].lastName;
    }
  }
  return fullName;
}

export const removeReviewWord = (str) => {
  const lastIndexOfSpace = str.lastIndexOf(' ');
  return str.substring(0, lastIndexOfSpace);
};

export const truncateList = (value, maxLength = 10) => {
  if (value && value.length > maxLength) {
    return value.substring(0, maxLength) + '...';
  } else {
    return value;
  }
};

export function camelize(str) {
  const words = str.split(/[^a-zA-Z0-9]+/);

  if (words.length === 0) {
    return '';
  }

  const camelCaseString =
    words[0].toLowerCase() +
    words
      .slice(1)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join('');

  return camelCaseString;
}

export const formatDate = (date) => {
  const month = (date.getMonth() + 1).toString();
  const day = date.getDate().toString();
  const year = date.getFullYear().toString();
  return `${month}/${day}/${year}`;
};

export const getConversationDate = (message) => {
  if (message.meta === null || message.meta.campaign) {
    const date = message.updatedAt;
    return date;
  } else if (message.meta && message.meta.createTime === null) {
    const date = message.meta.updateTime;
    return date;
  } else if (message.meta && message.meta.updateTime) {
    const date = message.meta.updateTime;
    return date;
  } else {
    return message.updatedAt;
  }
};

export function getLastImportantMessageDate(messages) {
  if (messages) {
    for (let i = messages.length - 1; i >= 0; i--) {
      if (
        messages[i].type === 'reviewresponse' ||
        messages[i].text === 'review request' ||
        messages[i].text === 'survey' ||
        messages[i].source === 'vcard' ||
        messages[i].source === 'note'
      ) {
        return getConversationDate(messages[0]);
      } else if (
        messages[i].type !== 'all' &&
        messages[i].type !== 'note' &&
        messages[i].source !== 'system'
      ) {
        messages[i].message;
        return getConversationDate(messages[i]);
      }
    }
    return 0;
  }
}

export const sortByRelaventMessageDate = (a, b) => {
  let aTimestamp, bTimestamp;
  if (a.messages.length === 0) return -1;
  if (b.messages.length === 0) return 1;

  const valid2 = (messages) => {
    if (messages[0].type === 'review') {
      return getMessageDate(messages[0]);
    } else {
      return getLastImportantMessageDate(messages);
    }
  };

  aTimestamp = valid2(a.messages);
  bTimestamp = valid2(b.messages);
  return bTimestamp - aTimestamp;
};

export function getMessageDate(message) {
  return message.meta && message.meta.createTime
    ? parseISO(message.meta.updateTime ?? message.meta.createTime).getTime()
    : message.updatedAt;
}

export function getLastImportantMessage(messages) {
  if (messages) {
    for (let i = messages.length - 1; i >= 0; i--) {
      if (
        messages[i].type !== 'all' &&
        messages[i].type !== 'note' &&
        messages[i].source !== 'system'
      ) {
        return messages[i].text;
      }
    }
  }
}

export function increase_brightness(hex, percent) {
  // strip the leading # if it's there
  hex = hex.replace(/^\s*#|\s*$/g, '');

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (hex.length == 3) {
    hex = hex.replace(/(.)/g, '$1$1');
  }

  var r = parseInt(hex.substr(0, 2), 16),
    g = parseInt(hex.substr(2, 2), 16),
    b = parseInt(hex.substr(4, 2), 16);

  return (
    '#' +
    (0 | ((1 << 8) + r + ((256 - r) * percent) / 100)).toString(16).substr(1) +
    (0 | ((1 << 8) + g + ((256 - g) * percent) / 100)).toString(16).substr(1) +
    (0 | ((1 << 8) + b + ((256 - b) * percent) / 100)).toString(16).substr(1)
  );
}

export function decrease_brightness(hex, percent) {
  // strip the leading # if it's there
  hex = hex.replace(/^\s*#|\s*$/g, '');

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (hex.length === 3) {
    hex = hex.replace(/(.)/g, '$1$1');
  }

  var r = parseInt(hex.substr(0, 2), 16),
    g = parseInt(hex.substr(2, 2), 16),
    b = parseInt(hex.substr(4, 2), 16);

  r = Math.max(0, r - (r * percent) / 100);
  g = Math.max(0, g - (g * percent) / 100);
  b = Math.max(0, b - (b * percent) / 100);

  return (
    '#' +
    Math.round(r).toString(16).padStart(2, '0') +
    Math.round(g).toString(16).padStart(2, '0') +
    Math.round(b).toString(16).padStart(2, '0')
  );
}

export const getIcon = (title) => {
  const url = 'https://static.reveo.com/reviews/icons/';
  const logoUrl = 'https://static.reveo.com/reviews/logos/';

  switch (title.toLowerCase()) {
    case 'all':
      return { title: 'Overall Rating', logo: null, icon: null };
    case 'apartments':
      return {
        title: 'Apartments',
        logo: logoUrl + 'apartments.png',
        icon: url + 'apartments.jpg',
      };
    case 'apartmentratings':
      return {
        title: 'Apartment Ratings',
        logo: logoUrl + 'apartmentratings.svg',
        icon: url + 'apartment_ratings.svg',
      };
    case 'apartmentguide':
      return {
        title: 'Apartmentguide',
        logo: logoUrl + 'apartmentguide.png',
        icon: url + 'apartment_guide.png',
      };
    case 'dealerrater':
      return {
        title: 'Dealer Rater',
        logo: logoUrl + 'dealerrater.svg',
        icon: url + 'dealer_rater.svg',
      };
    case 'avvo':
      return {
        title: 'Avvo',
        logo: logoUrl + 'avvo.svg',
        icon: url + 'avvo.png',
      };
    case 'cargurus':
      return {
        title: 'CarGurus',
        logo: logoUrl + 'cargurus.svg',
        icon: url + 'cargurus.svg',
      };
    case 'caredash':
      return {
        title: 'CareDash',
        logo: logoUrl + 'caredash.png',
        icon: url + 'caredash.png',
      };
    case 'cars':
      return {
        title: 'Cars',
        logo: logoUrl + 'carslogo.png',
        icon: url + 'cars.svg',
      };
    case 'facebook':
      return {
        title: 'Facebook',
        logo: logoUrl + 'facebook.svg',
        icon: url + 'facebook.svg',
      };
    case 'google':
      return {
        title: 'Google',
        logo: logoUrl + 'google.svg',
        icon: url + 'google.svg',
      };
    case 'glassdoor':
      return {
        title: 'Glassdoor',
        logo: logoUrl + 'glassdoor.svg',
        icon: url + 'glassdoor.png',
      };
    case 'healthgrades':
      return {
        title: 'Healthgrades',
        logo: logoUrl + 'healthgrades.svg',
        icon: url + 'healthgrades.jpg',
      };
    case 'homeadvisor':
      return {
        title: 'HomeAdvisor',
        logo: logoUrl + 'homeadvisor.svg',
        icon: url + 'home_advisor.png',
      };
    case 'indeed':
      return {
        title: 'Indeed',
        logo: logoUrl + 'indeed.svg',
        icon: url + 'indeed.png',
      };
    case 'thumbtack':
      return {
        title: 'Thumbtack',
        logo: logoUrl + 'thumbtack.svg',
        icon: url + 'thumbtack.png',
      };
    case 'tripadvisor':
      return {
        title: 'Tripadvisor',
        logo: logoUrl + 'tripadvisor.svg',
        icon: url + 'tripadvisor.svg',
      };
    case 'twitter':
      return {
        title: 'Twitter',
        logo: logoUrl + 'twitter.svg',
        icon: url + 'twitter.jpg',
      };
    case 'webmd':
      return {
        title: 'WebMD',
        logo: logoUrl + 'webmd.svg',
        icon: url + 'webmd.jpg',
      };
    case 'wellness':
      return {
        title: 'Wellness',
        logo: logoUrl + 'wellness.png',
        icon: url + 'wellness.png',
      };
    case 'yelp':
      return {
        title: 'Yelp',
        logo: logoUrl + 'yelp.svg',
        icon: url + 'yelp.png',
      };
    case 'zillow':
      return {
        title: 'Zillow',
        logo: logoUrl + 'zillow.svg',
        icon: url + 'zillow.jpg',
      };
    case 'zocdoc':
      return {
        title: 'ZocDoc',
        logo: logoUrl + 'zocdoc.png',
        icon: url + 'zoc_doc.png',
      };
    case 'houzz':
      return {
        title: 'Houzz',
        logo: logoUrl + 'houzz.svg',
        icon: url + 'houzz.svg',
      };

    default:
      return 'Unknown';
  }
};

export function isEmptyObject(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
}

export function replaceBusinessLogoInSurveys(surveyTypes, businessLogo = '') {
  if (Object.keys(surveyTypes).length !== 0) {
    for (const surveyKey in surveyTypes) {
      // eslint-disable-next-line no-prototype-builtins
      if (surveyTypes.hasOwnProperty(surveyKey)) {
        const survey = surveyTypes[surveyKey];
        if (survey.screens && Array.isArray(survey.screens)) {
          survey.screens.forEach((screen) => {
            if (screen.url === '@businessLogo') {
              screen.url = businessLogo;
            }
          });
        }
      }
    }
  }
  return surveyTypes;
}

export const createCampaignName = () => {
  const currentDate = new Date();
  const month = currentDate.toLocaleString('default', { month: 'long' });
  const day = currentDate.getDate();
  let hour = currentDate.getHours();
  const minute = currentDate.getMinutes().toString().padStart(2, '0');
  const period = hour >= 12 ? 'PM' : 'AM';

  // Convert 24-hour format to 12-hour format
  if (hour > 12) {
    hour -= 12;
  }

  return `${month} ${day}, ${hour}:${minute} ${period}`;
};

export const getLogo = (type, attachmentUrl) => {
  switch (type) {
    case 'pdf':
      return pdfLogo;
    case 'xls':
      return xlsLogo;
    case 'ppt':
      return pptLogo;
    case 'pptx':
      return pptLogo;
    case 'doc':
      return docLogo;
    case 'docx':
      return docLogo;
    case 'jpeg':
      return `${process.env.REACT_APP_IMAGE_ENDPOINT}${attachmentUrl}`;
    case 'jpg':
      return `${process.env.REACT_APP_IMAGE_ENDPOINT}${attachmentUrl}`;
    case 'png':
      return `${process.env.REACT_APP_IMAGE_ENDPOINT}${attachmentUrl}`;
    case 'jiff':
      return `${process.env.REACT_APP_IMAGE_ENDPOINT}${attachmentUrl}`;

    default:
      return reveoLogo;
  }
};

export const capitalize = (str) => {
  return str.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
};
