import React, { useContext } from 'react';
import Proptypes from 'prop-types';
import { Chip, Grid } from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import MobilePreview from '../MobilePreview/MobilePreview';
import EditableImage from '../Editable/EditableImage';
import EditableText from '../Editable/EditableText';
import { OnBoardingContext } from '../../Stores/OnBoarding';
import useTextColors from '../../hooks/useTextColors';

const StyledMobileViewContainer = styled(Grid)`
  position: absolute;
  height: 92%;
  margin: 0 !important;
  top: 40px;
  padding: 0 40px 0 30px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const StyledTextContainer = styled(Grid)`
  height: ${(props) => props.theme.height};
  width: ${(props) => props.theme.width};
  color: ${(props) => props.theme.color};
  background: ${(props) => props.theme.bgColor};
  border-radius: 20px 20px 20px 0px;
  padding: 15px;
  font-size: ${(props) => props.theme.fontSize};
`;

const textContainerMobile = {
  height: 'auto',
  width: '186px',
  fontSize: '12px',
};

const chip = ['@customerName', '@business', '@nickname', '@website', '@phone', '@link'];

function SmsView(props) {
  const { activeStep, radioValue, isEditable } = props;
  const { onBoardingDispatch, onBoardingData } = useContext(OnBoardingContext);
  const { defaultPortals } = onBoardingData;
  const { textColors } = useTextColors();

  const handleClick = (value) => {
    const defaultPortalCopy = { ...defaultPortals };
    defaultPortalCopy[radioValue].screens[activeStep].title += ` ${value}`;
    onBoardingDispatch({ type: 'SET_DEFAULT_PORTAL', payload: { ...defaultPortalCopy } });
  };

  const getChip = (item) => (
    <Chip
      label={item}
      variant="outlined"
      onClick={() => handleClick(item)}
      style={{ color: textColors.fontColor3, border: `1px solid ${textColors.fontColor3}` }}
    />
  );

  const chipContainer = isEditable ? chip.map((item) => getChip(item)) : null;

  return (
    <MobilePreview>
      <StyledMobileViewContainer container wrap="nowrap" direction="column" spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <EditableImage {...props} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container>
            <ThemeProvider
              theme={{
                ...textContainerMobile,
                bgColor: textColors.mobileTextContainerBG,
                color: textColors.fontColor3,
              }}
            >
              <StyledTextContainer item>
                <EditableText {...props} />
              </StyledTextContainer>
            </ThemeProvider>
            <Grid item>{chipContainer}</Grid>
          </Grid>
        </Grid>
      </StyledMobileViewContainer>
    </MobilePreview>
  );
}

SmsView.propTypes = {
  title: Proptypes.string.isRequired,
  activeStep: Proptypes.number.isRequired,
  radioValue: Proptypes.string.isRequired,
  isEditable: Proptypes.bool,
};

SmsView.defaultProps = {
  isEditable: true,
};

export default SmsView;
