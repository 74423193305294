/* eslint-disable react/forbid-prop-types */
import { Box, Divider } from '@material-ui/core';
import React from 'react';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import styled from 'styled-components';
import Proptypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import PageTitle from '../../../../../components/PageTitle/PageTitle';
import RespondentInformation from './components/RespondentInformation';
import QuestionsAndAnswer from './components/QuestionsAndAnswer';

const DetailTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #959595;
`;
const DetailDescription = styled.div`
  min-height: 18px;
  color: #7a7a7a;
  font-size: 14px;
`;
const BackButton = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: bold;
  cursor: pointer;
`;

const ResponseDetails = ({ handleGoBack, data, textBack }) => {
  const {
    firstName,
    lastName,
    emailAddress,
    phoneNumber,
    locationName,
    surveyName,
    surveyId,
    questions,
    createdDate,
  } = data;
  return (
    <div style={{ margin: 20 }}>
      <BackButton onClick={() => handleGoBack()}>
        <ArrowLeftIcon size={25} />
        {textBack.length ? textBack : 'Back'}
      </BackButton>

      <PageTitle title={`${surveyName} in response ID #${surveyId}`} />
      <Divider />
      <Box style={{ display: 'flex', marginTop: 20, marginBottom: 20 }}>
        <Box
          style={{
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <DetailTitle>Response Detail</DetailTitle>
          <DetailDescription>{`This response for the ${surveyName}`}</DetailDescription>
        </Box>
        <Box style={{ width: '50%' }}>
          <DetailTitle>Respondent information</DetailTitle>

          <RespondentInformation
            name={`${firstName} ${lastName}`}
            email={emailAddress}
            locationName={locationName}
            cellPhone={phoneNumber}
            createdDate={format(parseISO(createdDate), 'MMM d, yyyy h:mm a')}
          />
        </Box>
      </Box>
      <QuestionsAndAnswer data={questions} />
    </div>
  );
};

ResponseDetails.propTypes = {
  handleGoBack: Proptypes.func,
  data: Proptypes.object,
};

ResponseDetails.defaultProps = {
  handleGoBack: () => {},
  data: {},
};
export default ResponseDetails;
