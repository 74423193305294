import { useCallback, useContext, useEffect } from 'react';
import { GlobalContext } from '../Stores/GlobalStateStore';
import { errorChecker } from '../utils/helper/helper';
import baseUrl from '../utils/services/config';

const useFetchLocation = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { selectedBusiness } = state;
  const fetchLocation = useCallback(async () => {
    if (selectedBusiness) {
      try {
        const result = await baseUrl.get(
          `/getuserlocation/${localStorage.getItem('USER_ID')}/${selectedBusiness}`,
        );
        if (result.status === 200) {
          dispatch({ type: 'SET_LOCATIONS_CONTAINER', payload: Array.from(result.data) });
        }
      } catch (err) {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  }, [selectedBusiness]);

  useEffect(() => {
    fetchLocation();
  }, [fetchLocation]);

  return { fetchLocation: () => fetchLocation() };
};

export default useFetchLocation;
