export const getPlaceholderText = (name) => {
  switch (name.toLowerCase()) {
    // Replace $ /DEALER NUMBER/DEALER_NAME/
    // https://www.cars.com/dealers/23515/brent-brown-toyota/
    case 'cars':
      return 'https://www.cars.com/dealers/00000/dealer_name/';

    case 'cargurus':
      // Replace $ with everything after Cars/
      // https://www.cargurus.com/Cars/m-Brent-Brown-Toyota-sp278614
      return 'https://www.cargurus.com/Cars/business-name-sp00000';

    case 'apartmentratings':
      //!! CURRENTLY DOES NOT WORK!!! MUST CHANGE SCRAPER API
      return 'https://www.apartmentratings.com/state/apartment-city/apartment-name_000000000000/';

    case 'apartmentguide':
      //!! CURRENTLY DOES NOT WORK!!! MUST CHANGE SCRAPER API
      return 'https://www.apartmentguide.com/state/city/apartment-name/000000/';

    case 'dealerrater':
      // Replace $ with just the number
      // https://www.dealerrater.com/dealer/Jody-Wilkinson-Acura-review-19262/
      return 'https://www.cars.com/dealers/00000/dealer_name/';

    case 'homeadvisor':
      // https://www.homeadvisor.com/rated.ShaddayCleaningService.38911112.html
      // replace $ with the number between business name and html
      return 'https://www.homeadvisor.com/rated.BusinessName.00000000.html';

    case 'tripadvisor':
      // Replace $ with everything after location_review-
      // example https://www.tripadvisor.com/Hotel_Review-g28970-d23149085-Reviews-Lyle_Washington_DC-Washington_DC_District_of_Columbia.html
      return 'https://www.tripadvisor.com/location_Review-g00000-d200000-Reviews-location_name.html';

    case 'healthgrades':
      // Replace $ with everything after healthgrades.com/...
      // Does not have dedicated leave review link
      return 'https://www.healthgrades.com/dentist/dr-name-xsk00';

    case 'thumbtack':
      // https://www.thumbtack.com/ut/salt-lake-city/lawn-care/lawn-love-lawn-care/service/428184310832807947
      return 'https://www.thumbtack.com/ut/salt-lake-city/service-type/company-name/service/000000000000000000';

    case 'indeed':
      // Replace $ with company name
      //https://www.indeed.com/cmp/Gap-Solutions,-Inc./reviews
      return 'https://www.indeed.com/cmp/company-name/reviews';

    case 'yelp':
      // Replace $ with business name
      // Can't use for leaving a review
      // example https://www.yelp.com/biz/castillito-yucateco-san-francisco-5
      return 'https://www.yelp.com/biz/business-name';

    case 'apartments':
      // replace $ with location name/locid
      // sample https://www.apartments.com/concord-at-geneva-vineyard-ut/bq3ttmc/
      return 'https://www.apartments.com/property-name/xx0xxxx/';

    case 'wellness':
      //sample url
      // https://www.wellness.com/dir/6179674/chiropractor/ut/salt-lake-city/eric-brown-creekside-chiropractic--massage-chiropractor
      //strip everything after the id
      //final url should be https://www.wellness.com/reviews/0000000/
      //replace $ with doctor id
      return 'https://www.wellness.com/dir/0000000/discipline/ut/salt-lake-city/doctor-name';

    case 'caredash':
      // sample url https://www.caredash.com/doctors/corinne-xanthos-dpt-salt-lake-city-ut
      // $ replace with discipline/doctor-info
      // no direct link to leave review
      return 'https://www.caredash.com/discipline/doctor-name-and-location/';

    case 'glassdoor':
      //sample url https://www.glassdoor.com/Overview/Working-at-Apple-EI_IE1138.11,16.htm
      //reviews    https://www.glassdoor.com/Reviews/Apple-Reviews-E1138.htm

      //           https://www.glassdoor.com/Overview/Working-at-Bath-and-Body-Works-EI_IE13902.11,30.htm
      //           https://www.glassdoor.com/Reviews/Bath-and-Body-Works-Reviews-E13902.htm
      // replace $ with numbers from id only
      return 'https://www.glassdoor.com/Overview/company-EI_IE0000.00,30.htm';

    case 'avvo':
      //sample url https://www.avvo.com/attorneys/84020-ut-thomas-weber-4468754.html
      //tack on #client_reviews to the end of URL
      //replace $ with entire string minus .html
      return 'https://www.avvo.com/attorneys/00000-ut-lawyer-name-0000000.html';

    case 'webmd':
      //Does not have link to leave review, post link is same as list link
      //sample url https://doctor.webmd.com/doctor/d64421/gary-wiest-ratings
      return 'https://doctor.webmd.com/doctor/d00000/dentist-name-ratings';

    case 'zocdoc':
      //sample url https://www.zocdoc.com/dentist/paul-huffaker-dmd-336699
      //add #reviews to end or url to get to reviews
      //users are required to have an account to leave a review so post site link is login page
      return 'https://www.zocdoc.com/dentist/dentist-name-000000';

    case 'zillow':
      // https://www.zillow.com/profile/brettbelknap/
      return 'https://www.zillow.com/profile/agent_name/';
    case 'houzz':
      return 'https://www.houzz.com/professionals/landscape-contractors/landscaping-company-pfveqs-pf~690019920';
  }
};

export const getPostReviewLink = (name, reviewLink, siteId) => {
  switch (name.toLowerCase()) {
    // Replace $ /DEALER NUMBER/DEALER_NAME/
    // https://www.cars.com/dealers/23515/brent-brown-toyota/
    case 'cars':
      return `https://www.cars.com/dealers${reviewLink.substring(
        28,
      )}dealer_reviews/write-a-review/`;

    case 'cargurus':
      // Replace $ with everything after Cars/
      // https://www.cargurus.com/Cars/m-Brent-Brown-Toyota-sp278614
      return `https://www.cargurus.com/Cars/${reviewLink.substring(
        30,
      )}#dealer-review/verify-contact-details`;

    case 'apartmentratings':
      //!! CURRENTLY DOES NOT WORK!!! MUST CHANGE SCRAPER API
      let aId;
      const ar = reviewLink;
      const ay = '_';
      const az = ar.slice(ar.indexOf(ay) + ay.length);
      if (az.includes('/')) {
        aId = az.substring(0, az.length - 1);
      } else {
        aId = aId;
      }
      //https://www.apartmentratings.com/md/chevy-chase/aldon-of-chevy-chase_301656762620815/#ratingsReviews
      return `https://www.apartmentratings.com/writereview/?complexId=${aId}`;

    case 'apartmentguide':
      return reviewLink + '/Reviews/new/';

    case 'dealerrater':
      const x = reviewLink;
      const y = 'review-';
      const xId = x.slice(x.indexOf(y) + y.length);
      // Replace $ with just the number
      // https://www.dealerrater.com/dealer/Jody-Wilkinson-Acura-review-19262/
      return `https://www.dealerrater.com/consumer/writereviews/${xId}?source=drp`;

    case 'homeadvisor':
      const a = reviewLink.substring(34);
      const b = a.slice(0, a.length - 5);
      const c = b.substring(b.lastIndexOf('.'));
      const d = c.substring(1, c.length);
      // https://www.homeadvisor.com/rated.ShaddayCleaningService.38911112.html
      // replace $ with the number between business name and html
      return `https://www.homeadvisor.com/write-a-review/${d}/`;

    case 'tripadvisor':
      const e = reviewLink;
      const f = 'Review-';
      const g = e.slice(e.indexOf(f) + f.length); //removed everything before Review-
      const h = g.slice(0, g.length - 5); // remove trailing .html
      const i = h.replace('-Reviews', '');
      // Replace $ with everything after Hotel_Review-
      // example https://www.tripadvisor.com/Hotel_Review-g28970-d23149085-Reviews-Lyle_Washington_DC-Washington_DC_District_of_Columbia.html
      // reviews https://www.tripadvisor.com/UserReviewEdit-g28970-d23149085-Lyle_Washington_DC-Washington_DC_District_of_Columbia.html
      return `https://www.tripadvisor.com/UserReviewEdit-${i}`;

    case 'healthgrades':
      // Replace $ with everything after healthgrades.com/...
      // Does not have dedicated leave review link
      // https://www.healthgrades.com/physician/dr-michael-lauret-yshfp
      return reviewLink;

    case 'thumbtack':
      // https://www.thumbtack.com/ut/salt-lake-city/lawn-care/lawn-love-lawn-care/service/428184310832807947
      return 'https://www.thumbtack.com/customer/inbox';

    case 'indeed':
      // Replace $ with company name
      //https://www.indeed.com/cmp/Gap-Solutions,-Inc./reviews
      const aa = reviewLink.substring(27);
      console.log(aa);
      const cc = aa.replace('/reviews', '');
      return `https://www.indeed.com/cmp/${cc}/write-review?campaignid=review-this-company-btn-reviews-tab`;

    case 'yelp':
      // Replace $ with business name
      // Can't use for leaving a review
      // example https://www.yelp.com/biz/castillito-yucateco-san-francisco-5
      return reviewLink;

    case 'apartments':
      // replace $ with location name/locid
      // sample https://www.apartments.com/concord-at-geneva-vineyard-ut/bq3ttmc/
      return reviewLink + '#reviewsSection';

    case 'wellness':
      //sample url
      // https://www.wellness.com/dir/6179674/chiropractor/ut/salt-lake-city/eric-brown-creekside-chiropractic--massage-chiropractor
      //strip everything after the id
      //final url should be https://www.wellness.com/reviews/0000000/
      //replace $ with doctor id
      let substr;
      if (reviewLink.includes('.com/dir/')) {
        substr = 29;
      } else {
        substr = 33;
      }
      const bb = reviewLink.substring(substr);
      return `https://www.wellness.com/reviews/${bb}/`;

    case 'caredash':
      // sample url https://www.caredash.com/doctors/corinne-xanthos-dpt-salt-lake-city-ut
      // $ replace with discipline/doctor-info
      // no direct link to leave review
      return reviewLink;

    case 'glassdoor':
      //sample url   https://www.glassdoor.com/Overview/Working-at-Apple-EI_IE1138.11,16.htm
      // replace $ with numbers from id only
      // review link https://www.glassdoor.com/mz-survey/employer/collectReview_input.htm?i=1138&j=true&y=&c=PAGE_INFOSITE_TOP
      const ii = reviewLink;
      const ff = 'EI_IE';
      const gg = ii.slice(ii.indexOf(ff) + ff.length); //removed everything before Review-
      const hh = gg.slice(0, gg.indexOf('.')); // remove trailing .html
      return `https://www.glassdoor.com/mz-survey/employer/collectReview_input.htm?i=${hh}&j=true&y=&c=PAGE_INFOSITE_TOP`;

    case 'avvo':
      //sample url https://www.avvo.com/attorneys/84020-ut-thomas-weber-4468754.html
      //tack on #client_reviews to the end of URL
      //replace $ with entire string minus .html
      const companyStuff = reviewLink.replace('.html', '');
      const companyId = companyStuff.substring(companyStuff.lastIndexOf('/') + 1);

      return `https://www.avvo.com/attorney-reviews/${companyId}/write_review.html`;

    case 'webmd':
      //Does not have link to leave review, post link is same as list link
      //sample url https://doctor.webmd.com/doctor/d64421/gary-wiest-ratings
      return reviewLink;

    case 'zocdoc':
      //sample url https://www.zocdoc.com/dentist/paul-huffaker-dmd-336699
      //add #reviews to end or url to get to reviews
      //users are required to have an account to leave a review so post site link is login page
      return 'https://www.zocdoc.com/signin';

    case 'zillow':
      // https://www.zillow.com/profile/brettbelknap/
      // need scraper to return the post site link !!! Ask Mujahid to do it
      return reviewLink;

    case 'houzz':
      return `https://www.houzz.com/writeReview/cmd=r/n=${siteId}`;
  }
};

export const getListReviewLink = (name, reviewLink) => {
  let finalReviewLink;
  switch (name.toLowerCase()) {
    case 'cars':
      return reviewLink;

    case 'cargurus':
      return reviewLink;

    case 'apartmentratings':
      //!! CURRENTLY DOES NOT WORK!!! MUST CHANGE SCRAPER API
      return reviewLink;

    case 'apartmentguide':
      return reviewLink;

    case 'dealerrater':
      return reviewLink;

    case 'homeadvisor':
      return reviewLink;

    case 'tripadvisor':
      if (reviewLink.includes('#REVIEWS')) {
        finalReviewLink = reviewLink;
      } else {
        finalReviewLink = reviewLink + '#REVIEWS';
      }
      return finalReviewLink;

    case 'healthgrades':
      return reviewLink;

    case 'thumbtack':
      return reviewLink;

    case 'indeed':
      if (reviewLink.includes('/reviews')) {
        finalReviewLink = reviewLink;
      } else {
        finalReviewLink = reviewLink + '/reviews';
      }
      return finalReviewLink;

    case 'yelp':
      return reviewLink;

    case 'apartments':
      if (reviewLink.includes('/#reviewsSection')) {
        finalReviewLink = reviewLink;
      } else {
        finalReviewLink = reviewLink + '/#reviewsSection';
      }
      return finalReviewLink;

    case 'wellness':
      const a = reviewLink.replace('dir', 'reviews');
      return a;

    case 'caredash':
      // sample url https://www.caredash.com/doctors/corinne-xanthos-dpt-salt-lake-city-ut
      // $ replace with discipline/doctor-info
      // no direct link to leave review
      return reviewLink;

    case 'glassdoor':
      //sample url https://www.glassdoor.com/Overview/Working-at-Apple-EI_IE1138.11,16.htm
      // replace $ with numbers from id only
      //   const aaa = reviewLink.replace('Overview', 'Reviews');
      //   const bbb = aaa.replace('Working-at-', '');
      //   const ccc = bbb.replace('-EI_I', '-Reviews-');
      //   const ddd = ccc.slice(0, ccc.length - 4);
      //   const eee = ddd.slice(0, ddd.indexOf('.')); // remove trailing .html

      //get company id
      const ii = reviewLink;
      const ff = 'EI_IE';
      const gg = ii.slice(ii.indexOf(ff) + ff.length); //removed everything before Review-
      const companyId = gg.slice(0, gg.indexOf('.')); // remove trailing .html

      //get company name
      const jj = reviewLink;
      const kk = 'Working-at-';
      const nameAndStuff = jj.slice(jj.indexOf(kk) + kk.length); //removed everything before Review-
      const companyName = nameAndStuff.substring(0, nameAndStuff.indexOf('-EI_IE'));

      return `https://www.glassdoor.com/Reviews/${companyName}-Reviews-E${companyId}.htm`;

    case 'avvo':
      //sample url https://www.avvo.com/attorneys/84020-ut-thomas-weber-4468754.html
      //tack on #client_reviews to the end of URL
      //replace $ with entire string minus .html
      return reviewLink + '#client_reviews';

    case 'webmd':
      //Does not have link to leave review, post link is same as list link
      //sample url https://doctor.webmd.com/doctor/d64421/gary-wiest-ratings
      return reviewLink;

    case 'zocdoc':
      //sample url https://www.zocdoc.com/dentist/paul-huffaker-dmd-336699
      //add #reviews to end or url to get to reviews
      //users are required to have an account to leave a review so post site link is login page
      return reviewLink;

    case 'zillow':
      // https://www.zillow.com/profile/brettbelknap/
      if (reviewLink.includes('#reviews')) {
        finalReviewLink = reviewLink;
      } else {
        finalReviewLink = reviewLink + '#reviews';
      }
      return finalReviewLink;

    case 'houzz':
      return reviewLink;
  }
};
export const getRespondReviewLink = (siteName) => {
  let respondLink;
  switch (siteName.toLowerCase()) {
    case 'cars':
      respondLink = 'https://www.cars.com/signin/';
      return respondLink;

    case 'cargurus':
      respondLink = 'https://www.cargurus.com/Cars/authentication/renderRegisterLoginForm.action';
      return respondLink;

    case 'apartmentratings':
      respondLink =
        'https://exchange.satisfacts.com/reviews?complexId=&complexName=&sortDirection=0&sortField=object_update_date';
      return respondLink;

    case 'apartmentguide':
      respondLink = 'https://apartmentguide.com';
      return respondLink;

    case 'dealerrater':
      respondLink = 'https://www.dealerrater.com/login/';
      return respondLink;

    case 'homeadvisor':
      respondLink = 'https://pro.homeadvisor.com/login';
      return respondLink;

    case 'tripadvisor':
      respondLink = 'https://www.tripadvisor.com';
      return respondLink;

    case 'healthgrades':
      respondLink = 'https://update.healthgrades.com/?pCID=mhg-mobile-nav';
      return respondLink;

    case 'thumbtack':
      respondLink = 'https://www.thumbtack.com/login';
      return respondLink;

    case 'indeed':
      respondLink = 'https://secure.indeed.com/';
      return respondLink;

    case 'yelp':
      respondLink = 'https://biz.yelp.com/login';
      return respondLink;

    case 'apartments':
      respondLink = 'https://www.apartments.com/customers/reviews-dashboard/';
      return respondLink;

    case 'wellness':
      respondLink = 'https://www.wellness.com/secure/login';
      return respondLink;

    case 'caredash':
      respondLink = 'https://www.caredash.com/portal?active=login';
      return respondLink;

    case 'glassdoor':
      respondLink = 'https://www.glassdoor.com/employers/sign-up/?source=employermenu#/';
      return respondLink;

    case 'avvo':
      respondLink = 'https://www.avvo.com/account/login';
      return respondLink;

    case 'webmd':
      respondLink = 'https://member.webmd.com/';
      return respondLink;

    case 'zocdoc':
      respondLink = 'https://www.zocdoc.com/signin';
      return respondLink;

    case 'zillow':
      respondLink = 'https://www.zillow.com/rental-manager/properties?subNavFilterType=all';
      return respondLink;

    case 'houzz':
      respondLink = 'https://www.houzz.com/houzz-login/ps=1';
  }
};
