import React from 'react';
import PropTypes from 'prop-types';
import useTextColors from '../../hooks/useTextColors';

const { default: styled } = require('styled-components');
const { Grid } = require('@material-ui/core');

const StyledFieldGroupLable = styled(Grid)`
  //letter-spacing: 0.36px;
  color: #888888;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  margin-top: 1.5rem;
  font-size: ${(props) =>
    props.theme.fieldLabelFontSize ? props.theme.fieldLabelFontSize : '14px'} !important;
  margin-bottom: -2rem;
`;

const FieldGroupLable = ({ children }) => {
  const { textColors } = useTextColors();
  return (
    <StyledFieldGroupLable style={{ color: textColors.fontColor9 }}>
      {children}
    </StyledFieldGroupLable>
  );
};

FieldGroupLable.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FieldGroupLable;
