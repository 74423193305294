const ReportsReducer = (state, action) => {
  switch (action.type) {
    case 'INIT_CONTAINER': {
      return {
        ...state,
        npsData: [],
      };
    }
    case 'SET_NPS_DATA':
      return {
        ...state,
        npsData: [...action.payload],
      };

    case 'SHOW_LOADER':
      return {
        ...state,
        loader: true,
      };

    case 'HIDE_LOADER':
      return {
        ...state,
        loader: false,
      };

    default:
      return state;
  }
};

export default ReportsReducer;
