const { withStyles } = require('@material-ui/styles');
const { Switch } = require('@material-ui/core');

const StyledSwitch = withStyles((theme) => ({
  switchBase: {
    color: theme.switchCheckColor,
    '&$checked': {
      color: theme.backgroundColor,
    },
    '&$checked + $track': {
      backgroundColor: theme.backgroundColor,
    },
  },
  track: {
    backgroundColor: theme.switchTrackColor,
  },
  checked: {},
}))(Switch);

export default StyledSwitch;
