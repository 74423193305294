import React from 'react';
import Proptypes from 'prop-types';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';

const StyledAppContainer = styled(Grid)`
  max-width: 100% !important;
  min-height: 100%;
  overflow: hidden;
  grid-template-rows: 50px auto;
  display: grid;
`;

function AppContainer(props) {
  const { children } = props;
  return (
    <StyledAppContainer container disablegutters="true">
      {children}
    </StyledAppContainer>
  );
}

AppContainer.propTypes = {
  children: Proptypes.arrayOf(Proptypes.any).isRequired,
};

AppContainer.defaultProps = {};

export default React.memo(AppContainer);
