import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { ContentState, EditorState, convertFromHTML } from 'draft-js';
// eslint-disable-next-line import/no-extraneous-dependencies
import EditIcon from 'mdi-react/PencilOutlineIcon';
import CheckIcon from '@material-ui/icons/Check';
// eslint-disable-next-line import/no-extraneous-dependencies
import { stateToHTML } from 'draft-js-export-html';
import htmlToDraft from 'html-to-draftjs';
import GenricPopup from '../../Popup/GenricPopup';
import TemplateBuilder from './components/TemplateBuilder/TemplateBuilder';
import baseUrl from '../../../utils/services/config';
import { GlobalContext } from '../../../Stores/GlobalStateStore';
import { errorChecker } from '../../../utils/helper/helper';
import PrimaryButton from '../../Common/PrimaryButton';
import { CampaignContext } from '../../../Stores/CamapignStore';
import { convertIntoCampaignCards } from '../../../utils/helper/helper';

const StyleIcon = styled.div`
  cursor: pointer;
`;
const StyleTitle = styled.h1`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  text-align: left;
`;

const StyleTitleInput = styled.input`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  text-align: left;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  transition: border-color 0.3s;
  &:focus {
    border-color: #35eda7;
    outline: none;
    border-radius: 10px;
  }
`;

const buttonTheme = {
  background: '#f5f5f5',
  color: '#000000',
};
const containerButtons = {
  height: '10%',
  display: 'flex',
  paddingLeft: 20,
  paddingRight: 20,
};

const footerStyles = {
  display: 'flex',
  justifyContent: 'flex-end',
  columnGap: '.5rem',
  marginTop: '1rem',
};

const AddEmailTemplatePopover = ({
  id,
  open,
  anchorEl,
  handleClose,
  templateIdSelected,
  getTemplates,
}) => {
  const [emailBody, setEmailBody] = useState(EditorState.createEmpty());
  const [previewEmailBody, setPreviewEmailBody] = useState(EditorState.createEmpty());

  const [emailFooter, setEmailFooter] = useState(EditorState.createEmpty());
  const [previewEmailFooter, setPreviewEmailFooter] = useState(EditorState.createEmpty());

  const [showEditTamplateName, setShowEditTamplateName] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState('New Email Template');

  const [uploadedFile, setUploadedFile] = useState(null);

  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { state, dispatch } = useContext(GlobalContext);
  const { businessInfo } = state;

  const { campaignDispatch } = useContext(CampaignContext);

  const footerContent = `If you’d rather not get messages from us, @unsubscribeLink[click here] to unsubscribe. Future communications from us will follow your preferences. Please do not reply to this automated email as we won’t be able to respond. If you need additional assistance, see our FAQ page or contact @business for details.\n
  © 2024 ${businessInfo?.nickName} All trademarks shown are the property of ${businessInfo?.nickName}.
  \n${businessInfo?.nickName} • All Rights Reserved • ${businessInfo.contact?.addressLine1} ${businessInfo.contact?.addressLine2}, ${businessInfo.contact?.city}, ${businessInfo.contact?.state} ${businessInfo.contact?.zip} `;

  const cleanState = () => {
    setNewTemplateName('New Email Template');
    setEmailBody(EditorState.createEmpty());
    setPreviewEmailBody(EditorState.createEmpty());
    setEmailFooter(EditorState.createEmpty());
    setPreviewEmailFooter(EditorState.createEmpty());
    setUploadedFile(null);
  };
  const handleEditorChangeBody = (newEditorState) => {
    setEmailBody(newEditorState);

    const contentState = newEditorState.getCurrentContent();
    const newContentState = ContentState.createFromBlockArray(contentState.getBlockMap().toArray());
    setPreviewEmailBody(EditorState.createWithContent(newContentState));
  };
  const handleEditorChangeFooter = (newEditorState) => {
    setEmailFooter(newEditorState);

    const contentState = newEditorState.getCurrentContent();
    const newContentState = ContentState.createFromBlockArray(contentState.getBlockMap().toArray());

    setPreviewEmailFooter(EditorState.createWithContent(newContentState));
  };

  const createEmailTemplate = async ({ contentHTMLBody, contentHTMLFooter }) => {
    try {
      const payload = {
        name: newTemplateName,
        headerFile: uploadedFile.fileId,
        body: contentHTMLBody,
        footer: contentHTMLFooter,
        business: state.selectedBusiness,
        type: 'email template',
      };
      const result = await baseUrl.post(`/emailTemplates/`, {
        ...payload,
      });
      if (result.status === 200) {
        // eslint-disable-next-line no-unused-expressions
        //getTemplates && getTemplates();

        const forDispatch = {
          campaignType: 'email template',
          ...result.data,
        };

        campaignDispatch({
          type: 'UPDATE_CAMPAIGN_CONTAINER',
          payload: Array.from(convertIntoCampaignCards([forDispatch])),
        });

        handleClose();
        cleanState();
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(error) });
    }
  };
  const stateToHTMLOptions = {
    blockStyleFn: (block) => {
      const data = block.getData();
      if (data.size === 0) return;

      let style = {};
      if (data.get('text-align')) style = { ...style, textAlign: data.get('text-align') };
      return { style };
    },
  };
  const handleSaveTemplate = () => {
    setIsLoading(true);
    const contentHTMLBody = stateToHTML(emailBody.getCurrentContent(), stateToHTMLOptions);
    const contentHTMLFooter = stateToHTML(emailFooter.getCurrentContent(), stateToHTMLOptions);
    createEmailTemplate({ contentHTMLBody, contentHTMLFooter });
  };
  const handleUpdateTemplate = async () => {
    setIsLoading(true);
    const contentHTMLBody = stateToHTML(emailBody.getCurrentContent(), stateToHTMLOptions);
    const contentHTMLFooter = stateToHTML(emailFooter.getCurrentContent(), stateToHTMLOptions);
    try {
      const payload = {
        name: newTemplateName,
        headerFile: uploadedFile.fileId ?? uploadedFile.id,
        body: contentHTMLBody,
        footer: contentHTMLFooter,
        business: state.selectedBusiness,
      };
      const result = await baseUrl.put(`/emailTemplates/${templateIdSelected}`, {
        ...payload,
      });
      if (result.status === 200) {
        setIsLoading(false);
        handleClose();

        // eslint-disable-next-line no-unused-expressions
        getTemplates && getTemplates();
      }
    } catch (error) {
      setIsLoading(false);
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(error) });
    }
  };

  const handleEditName = () => {
    setShowEditTamplateName(!showEditTamplateName);
  };
  const handleCancel = () => {
    handleClose();
    cleanState();
  };
  const isEditorStateEmpty = (editorState) => {
    const contentState = editorState.getCurrentContent();
    return !contentState.hasText();
  };
  const convertFromHTMLContent = (htmlContentString) => {
    const blocksFromHtml = htmlToDraft(htmlContentString);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    return EditorState.createWithContent(contentState);
  };

  const getEmailTemplateById = async (id) => {
    try {
      const result = await baseUrl.get(`/emailTemplates/${id}`);
      if (result.status === 200) {
        const { footer, body, name, headerFile } = result.data;
        if (body.length > 0) {
          setEmailBody(convertFromHTMLContent(body));
          setPreviewEmailBody(convertFromHTMLContent(body));
        }
        if (footer.length > 0) {
          setEmailFooter(convertFromHTMLContent(footer));
          setPreviewEmailFooter(convertFromHTMLContent(footer));
        }
        if (headerFile) {
          setUploadedFile(headerFile);
        }
        setNewTemplateName(name);
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      setIsLoading(false);
    }
  };

  const LoaderCircularProgress = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
      <Grid container alignItems="center" justifyContent="center">
        <CircularProgress />
      </Grid>
    </div>
  );

  useEffect(() => {
    if (
      newTemplateName.length > 0 &&
      !isEditorStateEmpty(emailBody) &&
      !isEditorStateEmpty(emailFooter) &&
      uploadedFile
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [newTemplateName, emailBody, emailFooter, uploadedFile]);

  useEffect(() => {
    if (templateIdSelected) {
      getEmailTemplateById(templateIdSelected);
    }
  }, [templateIdSelected]);

  useEffect(() => {
    cleanState();
  }, []);

  return (
    <GenricPopup
      id={id}
      open={open}
      anchorEl={anchorEl}
      fullWidth
      maxWidth="lg"
      handleClose={handleClose}
    >
      <div style={{ height: 'auto', margin: '1rem' }}>
        {isLoading ? (
          <LoaderCircularProgress />
        ) : (
          <Grid container direction="column">
            <Grid
              item
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '.5rem',
                height: '3.5rem',
              }}
            >
              {showEditTamplateName ? (
                <>
                  <StyleTitleInput
                    type="text"
                    value={newTemplateName}
                    onChange={(e) => setNewTemplateName(e.target.value)}
                  />

                  <StyleIcon>
                    <CheckIcon onClick={handleEditName} />
                  </StyleIcon>
                </>
              ) : (
                <>
                  <StyleTitle onClick={handleEditName}>{newTemplateName}</StyleTitle>
                  <StyleIcon>
                    <EditIcon onClick={handleEditName} />
                  </StyleIcon>
                </>
              )}
            </Grid>
            <TemplateBuilder
              emailBody={emailBody}
              emailFooter={emailFooter}
              handleEditorChangeBody={handleEditorChangeBody}
              handleEditorChangeFooter={handleEditorChangeFooter}
              previewEmailBody={previewEmailBody}
              previewEmailFooter={previewEmailFooter}
              uploadedFile={uploadedFile}
              setUploadedFile={setUploadedFile}
            />
          </Grid>
        )}
        <div style={footerStyles}>
          <PrimaryButton text onClick={handleCancel}>
            Cancel
          </PrimaryButton>

          {templateIdSelected ? (
            <PrimaryButton disabled={isDisabled} onClick={handleUpdateTemplate}>
              update template
            </PrimaryButton>
          ) : (
            <PrimaryButton disabled={isDisabled} onClick={handleSaveTemplate}>
              Save template
            </PrimaryButton>
          )}
        </div>
      </div>
    </GenricPopup>
  );
};

AddEmailTemplatePopover.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  anchorEl: PropTypes.node,
  handleClose: PropTypes.func,
  getTemplates: PropTypes.func,
  templateIdSelected: PropTypes.number,
};

AddEmailTemplatePopover.defaultProps = {
  id: '',
  open: false,
  anchorEl: null,
  handleClose: () => {},
  getTemplates: () => {},
  templateIdSelected: null,
};

export default React.memo(AddEmailTemplatePopover);
