import styled from 'styled-components';
import { Button } from '@material-ui/core';
import theme from '../../setup';

const CustomButton = styled(Button)`
  background: ${(props) =>
    props.theme.background ? props.theme.background : theme.btn_background_color} !important;
  color: ${(props) => (props.theme.color ? props.theme.color : '#000')} !important;
  font-weight: ${(props) => (props.theme.fontWeight ? props.theme.fontWeight : '600')} !important;
  font-family: 'Montserrat' !important;
  font-weight: 600 !important;
  text-transform: ${(props) =>
    props.theme.textTransform ? props.theme.textTransform : 'capitalize'} !important;
  border-radius: ${(props) =>
    props.theme.borderRadis ? props.theme.borderRadis : '20px'} !important;
  margin: ${(props) => (props.theme.margin ? props.theme.margin : '1.5em auto 0.5em')} !important;
  width: ${(props) => (props.theme.width ? props.theme.width : '80%')};
  height: ${(props) => (props.theme.height ? props.theme.height : 'auto')};
  // box-shadow: ${(props) => (props.theme.boxShadow ? props.theme.boxShadow : 'none')};
  border: ${(props) => (props.theme.border ? props.theme.border : 'none')} !important;
  border-top-right-radius: ${(props) => props.theme.borderTopRight} !important;
  border-bottom-right-radius: ${(props) => props.theme.borderBottomRight} !important;
  border-top-left-radius: ${(props) => props.theme.borderTopLeft} !important;
  border-bottom-left-radius: ${(props) => props.theme.borderBottomLeft} !important;
  padding: ${(props) => (props.theme.padding ? props.theme.padding : '')};
  font-size: ${(props) => (props.theme.fontSize ? props.theme.fontSize : '')} !important;
  padding: ${(props) => (props.theme.padding ? props.theme.padding : '5px 8px')} !important;
  text-align: ${(props) => props.theme.textAlign};
  min-width: ${(props) => props.theme.minWidth} !important;
  max-width: ${(props) => props.theme.maxWidth} !important;
  word-break: ${(props) => props.theme.wordBreak} !important;
  &:disabled,
  &[disabled] {
    cursor: not-allowed !important;
    pointer-events: auto !important;
    color: ${(props) => props.theme.disabledColor} !important;
    background: ${(props) =>
      props.theme.disabledBackgroundColor
        ? props.theme.disabledBackgroundColor
        : '#a0a0a0'} !important;
  }
  // @media screen and (min-width: 1920px) {
  //   & {
  //     font-size: 18px !important;
  //   }
  // }
  // @media screen and (max-width: 600px) {
  //   & {
  //     font-size: 10px !important;
  //   }
  // }
`;

export default CustomButton;
