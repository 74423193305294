import { Box, MuiThemeProvider } from '@material-ui/core';
import styled from 'styled-components';

import React, { useCallback, useContext, useEffect, useRef, useState, useMemo } from 'react';
import GalleryPopup from '../../../../../Popup/Gallarypopup';
import useModal from '../../../../../../hooks/useModal';
import EditCampaign from '../../EditCampaign';
import { errorChecker, getFeedbackPathFromType } from '../../../../../../utils/helper/helper';
import { GlobalContext } from '../../../../../../Stores/GlobalStateStore';
import { CampaignContext } from '../../../../../../Stores/CamapignStore';
import baseUrl from '../../../../../../utils/services/config';
import { SurveyContext } from '../../../../../../Stores/SurveyStore';
import TextAreaCampaignSettigs from '../../CampaignSettings/TextAreaCampaignSetting';

import CusotmAlert from '../../../../../CustomAlert/CustomAlert';
import useAlert from '../../../../../../hooks/useAlert';

import PrimaryButton from '../../../../../Common/PrimaryButton';
import { convertIntoCampaignCards } from '../../../../../../utils/helper/helper';
import SMSImageUpload from '../../../../../SMSImageUpload/SMSImageUpload';
import AntTab from '../../../../../StyledTabs/StyledTabs';
import AntTabs from '../../../../../StyledTabsContainer/StyledTabsContainer';
import useTabsTheme from '../../../../../../hooks/useTabsTheme';
import TemplateTitleEdit from '../../../TemplateTitleEdit';
import ContainerLoader from '../../../ContainerLoader';
import AttachElementsGallery from '../../../AttachElementsGallery';

const StyledCardTitle = styled.span`
  font-weight: 600;
  font-size: 12px;
  color: #6c6c6c;
`;
const StyledSubtitle = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: #585858;
`;

const containerStyles = {
  margin: '0 .5rem .5rem .5rem',
  height: '55vh',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '1.5rem',
};
const footerStyles = { display: 'flex', justifyContent: 'space-between' };

const Message = ({ handleClose, campaignId }) => {
  const [showEditCampaign, setShowEditCampaign] = useState(false);
  const [userLink, setUserLink] = useState({
    titleLink: '',
    link: '',
  });
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  // const ContactAlert = useAlert();
  const { tabsTheme } = useTabsTheme();

  // eslint-disable-next-line max-len
  const inputMessageDefault = `Hi @customerName, it was a pleasure to serve you at @business. Please leave us a review by clicking the link below. Thanks!`;
  const [campaign, setCampaign] = useState({
    uploadedFile: [],
    name: '',
    message: inputMessageDefault,
    settings: {},
  });

  const [isLoading, setisLoading] = useState(false);
  const [campaignViewToggler] = useState('sms');

  const [buttonIsDisabled, setbuttonIsDisabled] = useState(true);

  const gallaryModal = useModal();
  const fileInputRef = useRef(null);
  const { state, dispatch } = useContext(GlobalContext);
  const { campaignStates, campaignDispatch } = useContext(CampaignContext);
  const { campaignContainer, campaignToEdit } = campaignStates;
  const { surveyData, surveyDispatch } = useContext(SurveyContext);
  const { selectedLoaction, selectedBusiness } = state;

  const imgSMS = (
    campaign?.uploadedFile.find((item) => item?.meta === 'sms' || item?.templateType === 'sms') ||
    {}
  ).url;
  // const imgSMS = useMemo(() => {
  //   return (
  //     uploadedFile.find((item) => item?.meta === 'sms' || item?.templateType === 'sms')?.url || null
  //   );
  // }, [uploadedFile]);

  const attachElements =
    campaign?.uploadedFile.filter(
      (item) => item?.meta === 'attachment' || item?.templateType === 'attachment',
    ) || [];

  const handleCancel = () => {
    handleClose();
  };

  const handleCampaignDeletionPopup = async (campaignId) => {
    try {
      const postBody = {
        isDeleted: true,
      };
      const result = await baseUrl.patch(`/campaign/${campaignId}`, { ...postBody });
      if (result.status === 200) {
        setisLoading(false);
        handleClose();
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const handleClone = async () => {
    setisLoading(true);
    let invitationMessageCopy = campaign?.message;
    if (userLink.titleLink.length) {
      invitationMessageCopy += `
      <a href="${userLink.link}">${userLink.titleLink}</a>
      `;
    }
    const uploadedFileNew = campaign.uploadedFile.map((item) => item.id);
    try {
      const postBody = {
        // eslint-disable-next-line radix
        user: parseInt(localStorage.getItem('USER_ID')),
        // eslint-disable-next-line radix
        location: selectedLoaction,
        name: campaign.name + ' [copy]',
        reviewPortal: [],
        campaignType: 'message',
        message: invitationMessageCopy,
        canResendAfter: 0,
        subType: 'simple',
        settings: { ...campaign.settings },
      };
      if (uploadedFileNew.length) {
        postBody.uploadedFile = uploadedFileNew;
      }
      const result = await baseUrl.post('/campaign', { ...postBody });
      if (result.status === 200) {
        setisLoading(false);

        handleClose();

        campaignDispatch({
          type: 'UPDATE_CAMPAIGN_CONTAINER',
          payload: Array.from(convertIntoCampaignCards([result.data])),
        });
      }
      return;
    } catch (err) {
      setisLoading(false);

      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const fetchNotifications = useCallback(async () => {
    try {
      if (selectedLoaction) {
        dispatch({ type: 'SET_NOTIFICATION_LOADER', payload: true });
        const result = await baseUrl(
          `/getnotifications/${localStorage.getItem('USER_ID')}/${selectedLoaction}`,
        );
        if (result.status === 200) {
          dispatch({ type: 'SET_NOTIFICATION_CONTAINER', payload: result.data });
          dispatch({ type: 'SET_NOTIFICATION_LOADER', payload: false });
        }
      }
    } catch (err) {
      dispatch({ type: 'SET_NOTIFICATION_LOADER', payload: false });
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  }, [selectedLoaction, dispatch]);

  const reviewsrequest = async (postBody) => {
    const result = await baseUrl.post(`/reviewsrequest`, { ...postBody });
    if (result.status === 200) {
      surveyDispatch({
        type: 'SET_SELECTED_CONTACTS',
        payload: [],
      });
      handleCampaignDeletionPopup(postBody.campaign);
      console.log(postBody.campaign);

      fetchNotifications();
      surveyDispatch({
        type: 'SET_STATUS_MESSAGE',
        payload: {
          redirectTo: '/app/feedback/message',
          statusMessage: surveyData.selectedContacts.length
            ? `${surveyData.selectedContacts.length} Message Sent`
            : 'Message Sent',
        },
      });
    }
  };

  const uploadPreview = (clients, id) => {
    baseUrl
      .post(
        `group/client/upload-preview?business=${selectedBusiness}&location=${selectedLoaction}`,
        clients,
      )
      .then(({ data }) => {
        const postBody = {
          client: data.map((client) => client.id),
          user: parseInt(localStorage.getItem('USER_ID'), 10),
          campaign: id,
          location: selectedLoaction,
        };
        reviewsrequest(postBody);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const sendSurvey = async (id) => {
    try {
      let postBody;
      if (surveyData.selectedContacts.length > 1) {
        uploadPreview(surveyData.selectedContacts, id);
      } else {
        postBody = {
          client: surveyData.selectedContacts[0].id,
          location: selectedLoaction,
          user: parseInt(localStorage.getItem('USER_ID'), 10),
          campaign: id,
        };
        reviewsrequest(postBody);
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };
  const submitCreateMessage = async () => {
    setisLoading(true);

    let invitationMessageCopy = campaign?.message;
    if (userLink.titleLink.length) {
      invitationMessageCopy += `
        <a href="${userLink.link}">${userLink.titleLink}</a>
        `;
    }

    const uploadedFileNew = campaign.uploadedFile.map((item) => item.id);

    try {
      const postBody = {
        // eslint-disable-next-line radix
        user: parseInt(localStorage.getItem('USER_ID')),
        // eslint-disable-next-line radix
        location: parseInt(localStorage.getItem('SELECTED_LOCATION')),
        name: campaign.name,
        emailTitle: '',
        reviewPortal: [],
        campaignType: 'message',
        isActive: true,
        message: invitationMessageCopy,
        canResendAfter: 0,
        subType: 'simple',
        settings: {},
      };

      if (uploadedFileNew.length) {
        postBody.uploadedFile = uploadedFileNew;
      }
      const result = await baseUrl.post('/campaign', { ...postBody });
      if (result.status === 200) {
        handleClose();

        campaignDispatch({
          type: 'UPDATE_CAMPAIGN_CONTAINER',
          payload: Array.from(convertIntoCampaignCards([postBody])),
        });
      }
      return;
    } catch (err) {
      setisLoading(false);
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const submitUpdateCampaign = async () => {
    let invitationMessageCopy = campaign?.message;
    if (userLink.titleLink.length) {
      invitationMessageCopy += `
        <a href="${userLink.link}">${userLink.titleLink}</a>
        `;
    }
    const uploadedFileNew = campaign.uploadedFile.map((item) => item.id);
    const postBody = {
      name: campaign.name,
      message: invitationMessageCopy,
      settings: { ...campaign.settings },
      id: campaign.id,
    };
    if (uploadedFileNew.length) {
      postBody.uploadedFile = uploadedFileNew;
    }
    try {
      const result = await baseUrl.patch(`/campaign/${campaignId || campaignToEdit}`, {
        ...postBody,
      });

      if (result.status === 200) {
        setisLoading(false);
        handleClose();
        campaignDispatch({
          type: 'UPDATE_CAMPAIGN_ITEM',
          payload: Array.from(convertIntoCampaignCards([result.data])),
        });
      }
      return;
    } catch (error) {
      console.log(error);
    }
  };

  const getCampaignById = async () => {
    try {
      const result = await baseUrl.get(`/campaign/${campaignId || campaignToEdit}`, {});
      if (result?.data) {
        setCampaign(result.data);
        setisLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setbuttonIsDisabled(!(campaign?.name.length && campaign?.message.length && imgSMS));
  }, [campaign?.name, campaign?.message, imgSMS]);

  useEffect(() => {
    if (campaignId || campaignToEdit) {
      setisLoading(true);
      getCampaignById();
    }
  }, [campaignId, campaignToEdit]);

  useEffect(() => {
    if (campaign?.message.length) {
      const regex = /<a href="([^"]+)">([^<]+)<\/a>/;
      const matches = campaign?.message.match(regex);
      if (matches) {
        const url = matches[1];
        const linkText = matches[2];
        setUserLink({
          titleLink: linkText,
          link: url,
        });
        const updatedMessage = campaign.message.replace(regex, '');
        setCampaign({
          ...campaign,
          message: updatedMessage,
        });
      }
    }
  }, [campaign?.message]);

  const handleTemplateTitleChange = (value) => {
    setCampaign({
      ...campaign,
      name: value,
    });
  };

  return (
    <>
      {isLoading && <ContainerLoader hideContent />}
      <Box style={containerStyles}>
        <Box>
          <TemplateTitleEdit
            campaign={campaign}
            handleChange={handleTemplateTitleChange}
            type={'message'}
          />
          <MuiThemeProvider theme={tabsTheme}>
            <AntTabs value={tabValue} onChange={handleTabChange} aria-label="ant">
              <AntTab label="Message" />
            </AntTabs>
          </MuiThemeProvider>
        </Box>
        <Box display="flex" style={{ columnGap: '1.5rem' }}>
          <SMSImageUpload
            title="SMS Image"
            url={imgSMS ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${imgSMS}` : ''}
            handleClick={gallaryModal.handleShowModal}
            type={campaignViewToggler}
            width="158px"
            height="280px"
            imageCustomStyle={{ borderRadius: '15px' }}
          />
          <Box display="flex" flexDirection="column" flex="1" style={{ rowGap: '1rem' }}>
            <TextAreaCampaignSettigs
              value={campaign?.message}
              setValue={(value) => {
                setCampaign({
                  ...campaign,
                  message: value,
                });
              }}
              multiline
              withLink
              userLink={userLink}
              handleAttachFileIcon={() => setShowEditCampaign(true)}
            />
            <Box display="flex" flexDirection="column" style={{ rowGap: '.5rem' }}>
              <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '.5rem' }}>
                <StyledCardTitle>Message Attachments</StyledCardTitle>
                <StyledSubtitle>
                  Add or remove attachments that you would like to include with your message
                </StyledSubtitle>
              </div>
              <AttachElementsGallery
                campaign={campaign}
                setCampaign={setCampaign}
                attachElements={attachElements}
                showEditCampaign={showEditCampaign}
                setShowEditCampaign={setShowEditCampaign}
                userLink={userLink}
                setUserLink={setUserLink}
                setIsLoading={setisLoading}
              />
            </Box>
          </Box>
        </Box>

        <GalleryPopup
          show={gallaryModal.showModal}
          handleClose={gallaryModal.handleHideShowModal}
          key="gallary-popup-modal"
          handleImagesContainer={(payload) => {
            payload.id = payload.fileId;
            const index = campaign?.uploadedFile.findIndex((item) => item.meta === 'sms');
            if (index !== -1) {
              const updatedUploadedFile = [...campaign.uploadedFile];
              updatedUploadedFile[index] = payload;
              setCampaign({
                ...campaign,
                uploadedFile: updatedUploadedFile,
              });
            } else {
              const updatedUploadedFile = [...campaign?.uploadedFile, payload];
              setCampaign({
                ...campaign,
                uploadedFile: updatedUploadedFile,
              });
            }
          }}
          type={campaignViewToggler}
        />
      </Box>
      <Box style={footerStyles}>
        {campaignId && (
          <PrimaryButton outlined onClick={handleClone} disabled={buttonIsDisabled}>
            Clone
          </PrimaryButton>
        )}
        <div style={{ columnGap: '.5rem', display: 'flex', marginLeft: 'auto' }}>
          <PrimaryButton text onClick={handleCancel}>
            Cancel
          </PrimaryButton>
          {campaignId && (
            <PrimaryButton onClick={submitUpdateCampaign} disabled={buttonIsDisabled}>
              Update
            </PrimaryButton>
          )}
          {!campaignId && (
            <PrimaryButton onClick={submitCreateMessage} disabled={buttonIsDisabled}>
              Save
            </PrimaryButton>
          )}
        </div>
      </Box>
    </>
  );
};

export default Message;
