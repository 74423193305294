import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { format, parseISO, isWithinInterval, addDays } from 'date-fns';

export const OpenOrClosed = (props) => {
  const { businessInfo, googleDetails, businessDetails } = props;

  const isOpen =
    googleDetails && googleDetails.current_opening_hours ? (
      googleDetails.current_opening_hours.open_now ? (
        <>
          <span style={{ color: '#1dc01d' }}>Open</span> •{' '}
        </>
      ) : (
        <>
          <span style={{ color: '#ff3d3d' }}>Closed</span> •{' '}
        </>
      )
    ) : null;

  const category =
    businessDetails && businessDetails?.categories?.primaryCategory?.displayName
      ? businessDetails.categories.primaryCategory.displayName
      : businessInfo.category;

  function formatTime(date) {
    const formattedTime = format(date, 'h:mm a').replace(/\./g, ''); // Remove periods from AM/PM
    return formattedTime.replace('AM', 'a.m.').replace('PM', 'p.m.');
  }
  function findOpenHours(openHoursObject) {
    let currentDate = new Date(); // Get the current date and time

    if (openHoursObject.open_now) {
      for (const period of openHoursObject.periods) {
        const closeTime = parseISO(`1970-01-01T${period.close.time}`);
        const closeDate = parseISO(period.close.date);

        if (
          closeDate.getFullYear() === currentDate.getFullYear() &&
          closeDate.getMonth() === currentDate.getMonth() &&
          closeDate.getDate() === currentDate.getDate()
        ) {
          const intervalEnd = new Date(currentDate);
          intervalEnd.setHours(closeTime.getHours(), closeTime.getMinutes(), 0, 0);

          if (isWithinInterval(currentDate, { start: currentDate, end: intervalEnd })) {
            return `Closes at ${formatTime(closeTime)} • `;
          }
        }
      }
    } else {
      let matchingDateFound = false;

      while (!matchingDateFound) {
        for (const period of openHoursObject.periods) {
          const openDate = parseISO(period.open.date);

          if (
            openDate.getFullYear() === currentDate.getFullYear() &&
            openDate.getMonth() === currentDate.getMonth() &&
            openDate.getDate() === currentDate.getDate()
          ) {
            matchingDateFound = true;

            const openTime = parseISO(`1970-01-01T${period.open.time}`);
            const intervalStart = new Date(currentDate);
            intervalStart.setHours(openTime.getHours(), openTime.getMinutes(), 0, 0);

            const intervalEnd = new Date(intervalStart);
            const closeTime = parseISO(`1970-01-01T${period.close.time}`);
            intervalEnd.setHours(closeTime.getHours(), closeTime.getMinutes(), 0, 0);

            if (isWithinInterval(intervalStart, { start: intervalStart, end: intervalEnd })) {
              const formattedOpenTime = formatTime(openTime);
              return `Opens at ${formattedOpenTime} • `;
            }
          }
        }

        currentDate = addDays(currentDate, 1);
      }
    }

    return 'Sorry, the current open status is not available.';
  }

  const cityState =
    googleDetails && googleDetails.address_components
      ? `${googleDetails.address_components[3].long_name}, ${googleDetails.address_components[5].short_name}`
      : `${businessInfo.city}, ${businessInfo.state}`;

  return (
    <Grid item style={{ marginTop: '1rem' }}>
      <Typography style={{ fontFamily: 'inherit', color: '#939393', fontWeight: 500 }}>
        {googleDetails && googleDetails.current_opening_hours ? isOpen : null}
        {googleDetails && googleDetails.current_opening_hours
          ? findOpenHours(googleDetails.current_opening_hours)
          : null}
        <span style={{ textTransform: 'capitalize' }}>{category}</span> • {cityState}
      </Typography>
    </Grid>
  );
};
