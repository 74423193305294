import React from 'react';
import Proptypes from 'prop-types';
import styled from 'styled-components';
import Rating from '@material-ui/lab/Rating';

const StyledCustomRating = styled(Rating)`
  font-size: 20px !important;
  color: #ffd643 !important;
  //margin: 0 0.5rem;

  // @media screen and (min-width: 1920px) {
  //   & {
  //     font-size: 24px !important;
  //   }
  // }
`;

function CustomRating(props) {
  const { ratingValue } = props;
  return <StyledCustomRating readOnly value={parseFloat(ratingValue)} precision={0.5} />;
}

// CustomRating.propTypes = {
//   ratingValue: Proptypes.number.isRequired,
// };

export default CustomRating;
