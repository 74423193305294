import { useCallback, useContext, useState } from 'react';
import { GlobalContext } from '../Stores/GlobalStateStore';
import { errorChecker } from '../utils/helper/helper';
import baseUrl from '../utils/services/config';

const useCampaigns = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [isLoadingCampaigns, setIsLoadingCampaigns] = useState(false); // Initialize with false
  const { dispatch, state } = useContext(GlobalContext);

  const { selectedLoaction } = state;

  const fetchCampaigns = useCallback(
    async (campaignType, location) => {
      try {
        setIsLoadingCampaigns(true);
        const result = await baseUrl.get(
          `campaign/list?deleted=0&location=${
            location || selectedLoaction
          }&type=${campaignType}&page=0&includeTemplates=0&status=1&user=${localStorage.getItem(
            'USER_ID',
          )}`,
        );
        if (result.status === 200) {
          setCampaigns([...result.data.list]);
        }
      } catch (err) {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      } finally {
        setIsLoadingCampaigns(false);
      }
    },
    [dispatch],
  );

  return {
    fetchCampaigns,
    campaigns,
    isLoadingCampaigns,
  };
};

export default useCampaigns;
