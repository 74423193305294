import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Card, CardContent, Typography } from '@material-ui/core';
import Proptypes from 'prop-types';
import Logo from '../../assets/images/reveo_logo@1x.png';
import UploadLayout from '../UploadLayout';

const CardStyle = styled(Card)`
  text-align: center;
  min-width: 100%;
  height: 100%;
  position: relative;
  // @media only screen and (max-width: 600px) {
  //   box-shadow: none;
  // }
`;
const CardContentStyle = styled(CardContent)`
  text-align: center;
`;

const BottomContent = styled(CardContent)`
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 12px 0 !important;
`;

const QuestionTextStyle = styled(Typography)`
  color: #9e9a9a !important;
  font-family: Montserrat, bold !important;
  font-size: 1.2rem !important;
  // @media only screen and (min-device-width: 481px) and (max-device-width: 1000px) {
  //   font-size: 1rem !important;
  // }
`;

function NoReplyCard(props) {
  const { noReplyData, url } = props;
  const { title } = noReplyData;

  return (
    <CardStyle>
      <CardContentStyle>
        {url ? (
          <ThemeProvider theme={{ width: '85%', margin: '0 auto', border: 'none' }}>
            <UploadLayout
              handleClick={() => {}}
              url={`${process.env.REACT_APP_IMAGE_ENDPOINT}${url}`}
              imgHeight="350px"
              disabled
            />
          </ThemeProvider>
        ) : (
          <p />
        )}
      </CardContentStyle>
      <CardContentStyle>
        <QuestionTextStyle>{title}</QuestionTextStyle>
      </CardContentStyle>

      <CardContentStyle />

      <BottomContent>
        <div style={{ display: 'inline-grid' }}>
          <span style={{ fontSize: '6px', marginLeft: '14px' }}>Powered by</span>
          <img src={Logo} style={{ width: '2.3em' }} alt="logo" />
        </div>
      </BottomContent>
    </CardStyle>
  );
}

NoReplyCard.propTypes = {
  noReplyData: Proptypes.string.isRequired,
  url: Proptypes.string.isRequired,
};

NoReplyCard.defaultProps = {};

export default NoReplyCard;
