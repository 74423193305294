import React, { useContext, useEffect, useMemo, useState } from 'react';
import Proptypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import { Grid, TablePagination, ThemeProvider as MUIThemeProvider } from '@material-ui/core';
import { ThemeProvider } from 'styled-components';
import CustomTableCell from '../CustomTableCell';
import StyledEditIcon from '../Icons/EditIcon';
import StyledDoneIcon from '../Icons/DoneIcon';
import StyledCheckbox from '../StyledCheckbox/StyledCheckbox';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import { errorChecker, selectAllContacts } from '../../utils/helper/helper';
import baseUrl from '../../utils/services/config';
import { emailRegEx } from '../../utils/helper/regEx';
import useFetch from '../../hooks/useFetch';
import StyledBlockIcon from '../Icons/Blocked';
import useIconTheme from '../../hooks/useIconTheme';
import useCheckBoxTheme from '../../hooks/useCheckBoxTheme';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 550,
    marginTop: '1.5em',
  },
  selectTableCell: {
    width: 60,
    borderBottom: `${theme.editableTableBorderBottom} !important`,
  },
  tableCell: {
    width: 130,
    height: 55,
    background: theme.tableCellBackground,
    color: theme.tableCellColor,
  },
  input: {
    width: 130,
    height: 40,
  },
  headerTableCell: {
    padding: '0 1em',
    borderRight: theme.tableEditBorderRight,
    color: theme.tableCellColor,
    borderBottom: 'none',
    background: theme.tableCellBackground,
  },
  borderBottomNone: {
    borderBottom: 'none',
    background: theme.tableCellBackground,
  },
  bodyTableCell: {
    borderBottom: `${theme.editableTableBorderBottom} !important`,
    padding: '0 .95em',
    color: theme.tableCellColor,
  },
  contactInfo: {
    fontSize: '14px',
    fontFamily: 'Montserrat',
    color: theme.contactInfoColor,
  },
  pagination: {
    color: theme.tablePaginationColor,
    '& > div > div > button': {
      color: `${theme.tablePaginationColor} !important`,
    },
    '& > div > div > svg': {
      color: `${theme.tablePaginationColor} !important`,
    },
    '& > div > div > button .Mui-disabled': {
      color: `${theme.tablePaginationDisabledBtnColor} !important`,
    },
  },
}));

function CustomTable2({ data, isEdit, columns, selectedContactsList, selectActionType }) {
  const [rows, setRows] = React.useState(data);
  const [previous, setPrevious] = React.useState({});
  const [selected, setSelected] = React.useState(selectedContactsList);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [selectedIndexNumber, setSelectedIndexNumber] = useState(null);
  const { dispatch, state } = useContext(GlobalContext);
  const { iconsTheme } = useIconTheme();
  const { checkBoxTheme } = useCheckBoxTheme();
  const { selectedBusiness } = state;
  const classes = useStyles();
  const { clientPatch } = useFetch();
  useMemo(() => {
    if (data.length) {
      setRows(data);
    }
  }, [data]);

  useEffect(() => {
    setSelected(selectedContactsList);
  }, [selectedContactsList]);

  const validateEmail = (emailAddress, customerId) => {
    if (emailRegEx.test(emailAddress)) {
      setDisableBtn(true);
      baseUrl(`/client-exist?business=${selectedBusiness}&field=emailAddress&value=${emailAddress}`)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.length > 0 && res.data[0].id !== parseInt(customerId, 10)) {
              setEmailError(true);
            } else {
              setDisableBtn(false);
            }
          }
        })
        .catch((err) => {
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    }
  };

  const validatePhone = (phoneNumber, customerId) => {
    if (phoneNumber && phoneNumber.length === 10) {
      setDisableBtn(true);
      const phoneNo = encodeURIComponent(`+1${phoneNumber}`);
      baseUrl(`/client-exist?business=${selectedBusiness}&field=phoneNumber&value=${phoneNo}`)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.length > 0 && res.data[0].id !== parseInt(customerId, 10)) {
              setPhoneError(true);
            } else {
              setDisableBtn(false);
            }
          }
        })
        .catch((err) => {
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    }
  };

  const handleEditMode = (id) => {
    setRows(() => {
      return rows.map((row, index) => {
        if (row.id === id) {
          setSelectedIndexNumber(index);
          return { ...row, isEditMode: true };
        }
        return row;
      });
    });
  };

  const handleEditDone = (id) => {
    setRows(() => {
      const rowsContainer = rows.map((row) => {
        if (row.id === id) {
          clientPatch(id, row);
          return { ...row, isEditMode: false };
        }
        return row;
      });
      setSelectedIndexNumber(null);
      dispatch({ type: 'SET_GROUP_CONTAINER', payload: Array.from(rowsContainer) });
      return rowsContainer;
    });
  };

  // useEffect(() => {
  //   if (rows.length) {
  // dispatch({ type: 'SET_GROUP_CONTAINER', payload: Array.from(rows) });
  //   }
  // }, [rows]);

  const onChange = (e, row) => {
    if (!previous[row.id]) {
      setPrevious((stateInfo) => ({ ...stateInfo, [row.id]: row }));
    }
    setEmailError(false);
    const { value } = e.target;
    const { name } = e.target;
    const { id } = row;
    const newRows = rows.map((rowInfo) => {
      if (rowInfo.id === id) {
        return { ...rowInfo, [name]: value };
      }
      return rowInfo;
    });
    setRows(newRows);
  };

  const handlePhoneChange = (value, row) => {
    setPhoneError(false);
    const { id } = row;
    const newRows = rows.map((rowInfo) => {
      if (rowInfo.id === id) {
        return { ...rowInfo, phoneNumber: value };
      }
      return rowInfo;
    });
    setRows(newRows);
  };

  const onBlur = (rowInfo, name) => {
    if (name === 'phoneNumber') {
      validatePhone(rowInfo.phoneNumber, rowInfo.id);
    }
    if (name === 'emailAddress') {
      validateEmail(rowInfo.emailAddress, rowInfo.id);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = selectAllContacts(rows);
      dispatch({ type: selectActionType, payload: newSelecteds });
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
    dispatch({ type: selectActionType, payload: [] });
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    dispatch({ type: selectActionType, payload: newSelected });
    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getEditableAction = (row, index) => {
    return isEdit && !row.unsubscribe ? (
      <TableCell className={classes.selectTableCell}>
        {row.isEditMode ? (
          <>
            <IconButton
              aria-label="done"
              onClick={() => handleEditDone(row.id)}
              disabled={
                disableBtn || phoneError || emailError || selectedIndexNumber
                  ? selectedIndexNumber !== index
                  : false
              }
            >
              <ThemeProvider theme={{ color: iconsTheme.doneIcon }}>
                <StyledDoneIcon />
              </ThemeProvider>
            </IconButton>
          </>
        ) : (
          <IconButton
            aria-label="delete"
            onClick={() => handleEditMode(row.id)}
            disabled={
              disableBtn || phoneError || emailError || selectedIndexNumber
                ? selectedIndexNumber !== index
                : false
            }
          >
            <ThemeProvider theme={{ color: iconsTheme.editIcon }}>
              <StyledEditIcon />
            </ThemeProvider>
          </IconButton>
        )}
      </TableCell>
    ) : null;
  };

  const editableTableHeader = isEdit ? (
    <TableCell align="left" className={classes.borderBottomNone} />
  ) : null;

  const columnsContent = columns.map((column) => (
    <TableCell align="left" className={classes.headerTableCell}>
      {column.label}
    </TableCell>
  ));

  const getCheckboxCell = (row, index) => {
    if (row.unsubscribeMobile || row.unsubscribe) {
      return (
        <TableCell
          padding="checkbox"
          className={classes.bodyTableCell}
          style={{ padding: '0 0em 0 1.5em' }}
        >
          <ThemeProvider theme={{ color: iconsTheme.blockIcon }}>
            <StyledBlockIcon />
          </ThemeProvider>
        </TableCell>
      );
    }
    return (
      <TableCell
        padding="checkbox"
        className={classes.bodyTableCell}
        onClick={(event) => handleClick(event, row.id)}
      >
        <MUIThemeProvider theme={checkBoxTheme}>
          <StyledCheckbox
            checked={isSelected(row.id)}
            inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${index}` }}
          />
        </MUIThemeProvider>
      </TableCell>
    );
  };

  return (
    <>
      <Table className={classes.table} aria-label="caption table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" className={classes.headerTableCell}>
              <MUIThemeProvider theme={checkBoxTheme}>
                <StyledCheckbox
                  indeterminate={selected.length > 0 && selected.length < rows.length}
                  checked={rows.length > 0 && selected.length === rows.length}
                  onChange={handleSelectAllClick}
                  inputProps={{ 'aria-label': 'select all desserts' }}
                />
              </MUIThemeProvider>
            </TableCell>
            {columnsContent}
            {editableTableHeader}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
            <TableRow key={row.id}>
              {getCheckboxCell(row, index)}
              <CustomTableCell {...{ row, name: 'firstName', onChange }} isEdit />
              <CustomTableCell {...{ row, name: 'lastName', onChange }} isEdit />
              <CustomTableCell
                {...{ row, name: 'emailAddress', onChange }}
                isEdit
                emailError={emailError}
                indexNumber={index}
                onBlur={onBlur}
              />
              <CustomTableCell
                {...{ row, name: 'phoneNumber', onChange }}
                isEdit
                phoneError={phoneError}
                indexNumber={index}
                onBlur={onBlur}
                handlePhoneChange={handlePhoneChange}
              />
              <CustomTableCell {...{ row, name: 'tags', onChange }} isEdit={false} />
              <CustomTableCell
                {...{
                  row,
                  name: row.unsubscribeMobile ? 'unsubscribeMobile' : 'unsubscribe',
                  onChange,
                }}
                isEdit={false}
              />
              {getEditableAction(row, index)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Grid container justify="space-between">
        <Grid item xs={8} sm={8} md={8} lg={8}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <div className={classes.contactInfo}>
                {rows.length}
                &nbsp;
                <span>Contacts</span>
              </div>
            </Grid>
            <Grid item xs={9} sm={9} md={9} lg={9}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                className={classes.pagination}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} />
      </Grid>
    </>
  );
}
CustomTable2.propTypes = {
  data: Proptypes.arrayOf(Proptypes.object).isRequired,
  isEdit: Proptypes.bool,
  columns: Proptypes.arrayOf(Proptypes.object).isRequired,
  selectedContactsList: Proptypes.arrayOf(Proptypes.number),
  selectActionType: Proptypes.string.isRequired,
};

CustomTable2.defaultProps = {
  isEdit: false,
  selectedContactsList: [],
};

export default React.memo(CustomTable2);
