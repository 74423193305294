import React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import CustomRating from '../../components/CusotmRating/CusotmRating';
import { Skeleton } from '@material-ui/lab';

function SmallReviewSummary(props) {
  const { reviewSummary } = props;
  const averageRating = reviewSummary && reviewSummary.averageRating;
  const totalReviewCount = reviewSummary && reviewSummary.totalReviewCount;

  const SubText = styled.p`
    margin: 0 !important;
    font-size: 15px;
    color: #939393;
  `;
  const BoldText = styled.p`
    margin: 0 !important;
    font-size: 15px;
    font-weight: 600;
  `;

  const content =
    totalReviewCount && totalReviewCount !== undefined ? (
      <>
        <BoldText>{averageRating}</BoldText>
        <CustomRating ratingValue={Number(averageRating)} />
        <SubText>{`${totalReviewCount} reviews`}</SubText>
      </>
    ) : (
      <Skeleton width={'15rem'} />
    );

  return (
    <Grid
      container
      alignItems="center"
      direction="row"
      style={{ marginTop: '.5rem', columnGap: '.5rem' }}
    >
      {content}
    </Grid>
  );
}

export default SmallReviewSummary;
