import PersonAddIcon from '@material-ui/icons/PersonAdd';
import styled from 'styled-components';

const StyledPersonAddIcon = styled(PersonAddIcon)`
  color: ${(props) => props.theme.color} !important;
  font-size: ${(props) => props.theme.fontSize} !important;
  opacity: ${(props) => props.theme.opacity} !important;
`;

export default StyledPersonAddIcon;
