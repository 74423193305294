import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

function ConfirmationDialog(props) {
  const { onClose, open, handleOk, ...other } = props;

  const handleClose = (reason) => {
    if (reason === 'backdropClick') {
      // ignore clicks outside the dialog
      return;
    }
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      disableEscapeKeyDown
      maxWidth="xs"
      //   onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">Comfirm Dilog</DialogTitle>
      <DialogContent>Are you sure to exit ?</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string,
  handleOk: PropTypes.func.isRequired,
};

export default ConfirmationDialog;
