import { useContext, useMemo, useState } from 'react';
import { GlobalContext } from '../Stores/GlobalStateStore';

const useIconTheme = () => {
  const { state } = useContext(GlobalContext);
  const { themeContainer } = state;
  const [theme, setTheme] = useState({});
  useMemo(() => {
    setTheme({
      addCircleIcon: themeContainer.addCircleIcon,
      postAddIcon: themeContainer.postAddIcon,
      blockIcon: themeContainer.blockIcon,
      checkCircleIcon: themeContainer.checkCircleIcon,
      clearIcon: themeContainer.clearIcon,
      doneIcon: themeContainer.doneIcon,
      editIcon: themeContainer.editIcon,
      groupAddIcon: themeContainer.groupAddIcon,
      helpIcon: themeContainer.helpIcon,
      laptopIcon: themeContainer.laptopIcon,
      locationOnIcon: themeContainer.locationOnIcon,
      lockIcon: themeContainer.lockIcon,
      mobileIconTheme: themeContainer.mobileIconTheme,
      moreHorizIcon: themeContainer.moreHorizIcon,
      nightsStayIcon: themeContainer.nightsStayIcon,
      notificationsOffIcon: themeContainer.notificationsOffIcon,
      personAddIcon: themeContainer.personAddIcon,
      reportProblemIcon: themeContainer.reportProblemIcon,
      sendIcon: themeContainer.sendIcon,
      splitIcon: themeContainer.splitIcon,
      swapHorizIcon: themeContainer.swapHorizIcon,
      thumbsDownIcon: themeContainer.thumbsDownIcon,
      thumbsUpIconTheme: themeContainer.thumbsUpIconTheme,
      trashIconTheme: themeContainer.trashIconTheme,
      tuneIcon: themeContainer.tuneIcon,
      wbSunnyIcon: themeContainer.wbSunnyIcon,
      emoticon: themeContainer.emoticon,
      emoticonNutral: themeContainer.emoticonNutral,
      emoticonSad: themeContainer.emoticonSad,
      dataUsageIcon: themeContainer.dataUsageIcon,
      attachFileIcon: themeContainer.attachFileIcon,
      filterNoneIcon: themeContainer.filterNoneIcon,
      conversationSendIcon: themeContainer.conversationSendIcon,
      conversationSendAndCloseIcon: themeContainer.conversationSendAndCloseIcon,
      conversationCheckIcon: themeContainer.conversationCheckIcon,
      badgebgColor: themeContainer.badgebgColor,
      badgeColor: themeContainer.badgeColor,
    });
  }, [themeContainer]);
  return {
    iconsTheme: theme,
  };
};
export default useIconTheme;
