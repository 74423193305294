import React from 'react';
import Proptypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { makeStyles } from '@material-ui/core';
import CustomButton from '../CustomButton/CustomButton';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));

const StyledLable = styled.label`
  display: flex;
`;

const buttonTheme = {
  margin: '0',
  width: '100%',
};

function UploaderButtons(props) {
  const { id, handleChange, title } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <input className={classes.input} id={id} type="file" onChange={handleChange} accept="*" />
      <StyledLable htmlFor={id}>
        <ThemeProvider theme={buttonTheme}>
          <CustomButton component="span">{title}</CustomButton>
        </ThemeProvider>
      </StyledLable>
    </div>
  );
}

UploaderButtons.propTypes = {
  id: Proptypes.string.isRequired,
  handleChange: Proptypes.func.isRequired,
  title: Proptypes.string.isRequired,
};

export default UploaderButtons;
