/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { FormControl, Box, Button } from '@material-ui/core';
import styled from 'styled-components';
import GenericPopup from '../../Popup/GenricPopup/index';
import baseUrl from '../../../utils/services/config';
import OneSignal from 'react-onesignal';
import { FormHeader } from '../../Forms/FormHeader';
import CustomSwitch from '../../../components/CustomSwitch/CustomSwitch';
import PrimaryButton from '../../Common/PrimaryButton';

const WidgetSetting = styled.div`
  font-weight: 600;
  min-width: 50%;
`;

const NotificationDescription = styled.div`
  font-size: 12px;
  color: #949494;
  margin-top: 0.5rem;
`;

function NotificationPreferencesPopover(props) {
  const { open, handleClose } = props;
  const [isWebNotificationToken, setIsWebNotificationToken] = useState('');
  const [isUserSubscribed, setIsUserSubscribed] = useState(false);
  const [isSubscriptionSwitchEnabled, setIsSubscriptionSwitchEnabled] = useState(false);
  const [isEmailNotificationsEnabled, setIsEmailNotificationsEnabled] = useState('');
  const [initialized, setInitialized] = useState(false);
  const user = localStorage.getItem('USER_ID', 10);
  const [currentNotificationSettings, setCurrentNotificationSettings] = useState();

  const handleEnableWebNotifications = async () => {
    OneSignal.init({ appId: process.env.REACT_APP_NOTIFICATIONS_KEY }).then(() => {
      OneSignal.showSlidedownPrompt();
    });
    OneSignal.on('subscriptionChange', function (isSubscribed) {
      if (isSubscribed) {
        console.log('estoy subscrito');
        setInitialized(true);
        storeWebNotificationToken();
        setIsUserSubscribed(true);
        setIsSubscriptionSwitchEnabled(true);
      }
    });
  };

  const storeWebNotificationToken = async () => {
    await OneSignal.getUserId(async function (userId) {
      let chatNotificationSubscription = 1;
      const result = await baseUrl.post(
        `account/store-subscriber-token?token=${userId}&userId=${user}&chatNotificationSubscription=${chatNotificationSubscription}`,
      );
      setIsSubscriptionSwitchEnabled(true);
      setIsWebNotificationToken(userId);
      setIsUserSubscribed(true);
    });
  };

  const getNotificationStatus = async () => {
    let userId = localStorage.getItem('USER_ID', 10);
    await baseUrl
      .get(`account/get-notification-subscription-status?userId=${userId}`)
      .then((res) => {
        if (res.status === 200) {
          setCurrentNotificationSettings(res.data.checkSubscriptionUser.notifications);
          setIsEmailNotificationsEnabled(
            res.data.checkSubscriptionUser.notifications.webchatEmailNotificationsEnabled,
          );
          setIsWebNotificationToken(res.data.webNotificationToken);
          if (res.data.webNotificationToken !== null) {
            res.data.checkSubscriptionUser.chatNotificationSubscription === false
              ? setIsSubscriptionSwitchEnabled(false)
              : setIsSubscriptionSwitchEnabled(true);
            setIsUserSubscribed(true);
          } else {
            setIsSubscriptionSwitchEnabled(false);
          }
        }
      });
  };

  const handleUnsubscriptionUser = async () => {
    let chatNotificationSubscription = isSubscriptionSwitchEnabled ? 0 : 1;
    await baseUrl
      .post(
        `account/change-status-subscription?userId=${user}&chatNotificationSubscription=${chatNotificationSubscription}`,
      )
      .then((res) => {
        if (res.status === 200) {
          console.log('we are good!');
        }
      });
  };

  useEffect(() => {
    getNotificationStatus();
  }, []);

  useEffect(() => {
    if (isSubscriptionSwitchEnabled) {
      handleEnableWebNotifications();
    }
  }, [isUserSubscribed]);

  const handleToggleEmailNotifications = async () => {
    setIsEmailNotificationsEnabled(!isEmailNotificationsEnabled);
  };

  const handleSaveChanges = async () => {
    const newSettings = {
      ...currentNotificationSettings,
      webchatEmailNotificationsEnabled: isEmailNotificationsEnabled,
    };
    const postBody = { userId: parseInt(user), settings: newSettings };
    await baseUrl.post('account/updateNotificationPreferences', postBody).then((res) => {
      if (res.status === 200) {
        //console.log('we are good!');
      }
    });
    handleClose();
  };

  const preferences = (
    <Box>
      <Box padding=".5rem">
        <FormControl component="fieldset" style={{ width: '100%' }}>
          <FormHeader>Notification Preferences</FormHeader>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flex="1"
            marginBottom="30px"
          >
            <Box display="flex" flexDirection="column">
              <WidgetSetting>Push Notifications</WidgetSetting>
              <NotificationDescription>
                Turn on to receive a web push notification when new messages are received
              </NotificationDescription>
            </Box>
            {!isWebNotificationToken && !isUserSubscribed ? (
              <PrimaryButton
                id="webpushr-subscription-button"
                title="assign"
                variant="contained"
                data-show-subscriber-count="false"
                onClick={() => handleEnableWebNotifications()}
              >
                Enable notifications
              </PrimaryButton>
            ) : (
              false
            )}
            {isUserSubscribed ? (
              <CustomSwitch
                checked={isSubscriptionSwitchEnabled ? true : false}
                onChange={() => {
                  handleUnsubscriptionUser();
                  setIsSubscriptionSwitchEnabled(!isSubscriptionSwitchEnabled);
                }}
              />
            ) : (
              false
            )}
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flex="1"
            marginBottom="30px"
          >
            <Box display="flex" flexDirection="column">
              <WidgetSetting>Email Notifications</WidgetSetting>
              <NotificationDescription>
                Turn on to receive an email when new messages are received
              </NotificationDescription>
            </Box>
            <CustomSwitch
              checked={isEmailNotificationsEnabled}
              onChange={() => {
                handleToggleEmailNotifications();
              }}
            />
          </Box>
        </FormControl>
      </Box>
      <Box style={{ gap: '.5rem' }} justifyContent={'flex-end'} display="flex" marginTop={'30px'}>
        <PrimaryButton title="cancel" outlined onClick={() => handleClose()}>
          Cancel
        </PrimaryButton>
        <PrimaryButton title="assign" onClick={() => handleSaveChanges()}>
          Save Preferences
        </PrimaryButton>
      </Box>
    </Box>
  );

  return (
    <GenericPopup open={open} maxWidth="xs" fullWidth handleClose={handleClose}>
      <Box padding="1rem">{preferences}</Box>
    </GenericPopup>
  );
}

export default React.memo(NotificationPreferencesPopover);
