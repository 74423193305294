import React from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import useModal from '../../../../../hooks/useModal';
import GalleryPopup from '../../../../Popup/Gallarypopup';
import UploadLayout from '../../../../UploadLayout';
import DraftEmailTemplate from '../DraftEmailTemplate/DraftEmailTemplate';

const Builder = ({
  emailBody,
  emailFooter,
  handleEditorChangeBody,
  handleEditorChangeFooter,
  uploadedFile,
  setUploadedFile,
}) => {
  const gallaryModal = useModal();

  return (
    <div>
      <div
        style={{
          display: 'flex',
          height: 120,
          margin: 10,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ThemeProvider
          theme={{
            marginTop: '20px',
            height: '100%',
            minHeight: '120px',
            maxHeight: '120px',
            borderRadius: '15px',
            margin: '0px',
          }}
        >
          <UploadLayout
            title=""
            url={uploadedFile ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${uploadedFile.url} ` : ''}
            handleClick={gallaryModal.handleShowModal}
            type="email"
            imageCustomStyle={{ marginTop: 0, borderRadius: '15px' }}
            imgHeight="120px"
            imgWidth="250px"
          />
        </ThemeProvider>
      </div>
      <DraftEmailTemplate
        editorState={emailBody}
        setEditorState={handleEditorChangeBody}
        title="Email Body"
        prefixes={[
          {
            visualName: 'Business Name',
            tag: '@business',
          },
          {
            visualName: 'Location Name',
            tag: '@location',
          },
          {
            visualName: 'Website',
            tag: '@website',
          },
          {
            visualName: 'Phone',
            tag: '@phone',
          },
          {
            visualName: 'Customer Name',
            tag: '@customerName',
          },
          {
            visualName: 'Review Link',
            tag: '@link',
          },
          {
            visualName: 'Unsubscribe Link',
            tag: '@unsubscribeLink',
          },
          {
            visualName: 'Survey Link',
            tag: '@surveyLink',
          },
          {
            visualName: 'Work Order Content',
            tag: '@workOrderContent',
          },
        ]}
      />
      <DraftEmailTemplate
        editorState={emailFooter}
        setEditorState={handleEditorChangeFooter}
        title="Email Footer"
        prefixes={[
          {
            visualName: 'Business Name',
            tag: '@business',
          },
          {
            visualName: 'Location Name',
            tag: '@location',
          },
          {
            visualName: 'Website',
            tag: '@website',
          },
          {
            visualName: 'Phone',
            tag: '@phone',
          },
          {
            visualName: 'Customer Name',
            tag: '@customerName',
          },
          {
            visualName: 'Review Link',
            tag: '@link',
          },
          {
            visualName: 'Unsubscribe Link',
            tag: '@unsubscribeLink',
          },
          {
            visualName: 'Survey Link',
            tag: '@surveyLink',
          },
        ]}
      />

      <GalleryPopup
        show={gallaryModal.showModal}
        handleClose={gallaryModal.handleHideShowModal}
        key="gallary-popup-modal"
        allImageSize
        handleImagesContainer={(payload) => {
          setUploadedFile(payload);
        }}
        type="email"
      />
    </div>
  );
};

Builder.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  emailBody: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  emailFooter: PropTypes.any,
  handleEditorChangeBody: PropTypes.func,
  handleEditorChangeFooter: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  uploadedFile: PropTypes.object,
  setUploadedFile: PropTypes.func,
};

Builder.defaultProps = {
  emailBody: null,
  emailFooter: null,
  handleEditorChangeBody: () => {},
  handleEditorChangeFooter: () => {},
  uploadedFile: null,
  setUploadedFile: () => {},
};

export default React.memo(Builder);
