import { createTheme } from '@material-ui/core/styles';
import { useContext, useMemo, useState } from 'react';
import { GlobalContext } from '../Stores/GlobalStateStore';

const useInputFieldTheme = () => {
  const { state } = useContext(GlobalContext);
  const { themeContainer } = state;
  const [theme, setTheme] = useState({});
  useMemo(() => {
    setTheme(
      createTheme({
        inputLabelColor: themeContainer.inputLabelColor,
        inputLabelColorOnFocus: themeContainer.inputLabelColorOnFocus,
        inputBorderBottomBefore: themeContainer.inputBorderBottomBefore,
        inputBorderBottomAfter: themeContainer.inputBorderBottomAfter,
        inputOnFocusBefore: themeContainer.inputOnFocusBefore,
        inputTextColor: themeContainer.inputTextColor,
      }),
    );
  }, [themeContainer]);
  return {
    inputTheme: theme,
  };
};
export default useInputFieldTheme;
