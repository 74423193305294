import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, MuiThemeProvider, IconButton } from '@material-ui/core';
import useModal from '../../../../../../hooks/useModal';
import baseUrl from '../../../../../../utils/services/config';
import TabMessage from './components/TabMessage/TabMessage';
import TabEmail from './components/TabEmail/TabEmail';
import { GlobalContext } from '../../../../../../Stores/GlobalStateStore';
import TabQuestion from './components/TabQuestion/TabQuestion';
import { SurveyContext } from '../../../../../../Stores/SurveyStore';
import {
  errorChecker,
  getFeedbackPathFromType,
  getMetaImage,
  getSelectedPortalInfo,
} from '../../../../../../utils/helper/helper';
import AntTabs from '../../../../../StyledTabsContainer/StyledTabsContainer';
import AntTab from '../../../../../StyledTabs/StyledTabs';
import useTabsTheme from '../../../../../../hooks/useTabsTheme';
import portalJson from '../../../../../../utils/api/portalJson';
import GenricPopup from '../../../../../Popup/GenricPopup';
import StyledTextField from '../../../../../Common/StyledTextField';
import PageTitle from '../../../../../PageTitle/PageTitle';
import PrimaryButton from '../../../../../Common/PrimaryButton';
import { convertIntoCampaignCards } from '../../../../../../utils/helper/helper';
import { CampaignContext } from '../../../../../../Stores/CamapignStore';
import TemplateTitleEdit from '../../../TemplateTitleEdit';
import ContainerLoader from '../../../ContainerLoader';

const containerStyles = {
  margin: '0 .5rem 1rem .5rem',
  height: '55vh',
  display: 'flex',
  flexDirection: 'column',
};

const footerStyles = { display: 'flex', justifyContent: 'space-between', gap: '1rem' };

const Survey = ({ campaignId, handleClose, isOnlySave = false }) => {
  const { tabsTheme } = useTabsTheme();
  const [tabValue, setTabValue] = useState(0);

  const [showEditCampaign, setShowEditCampaign] = useState(false);
  const [userLink, setUserLink] = useState({
    titleLink: '',
    link: '',
  });
  const [templateSelected, setTemplateSelected] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [emailErrors, setEmailErrors] = useState({});
  const { state, dispatch } = useContext(GlobalContext);
  const { selectedLoaction, selectedBusiness } = state;
  const [businessLogo, setBusinessLogo] = useState({});
  const [questionsSurveySelected, setquestionsSurveySelected] = useState([]);

  const { campaignDispatch } = useContext(CampaignContext);

  const [campaign, setCampaign] = useState({
    name: '',
    message:
      'Hi @customerName, it was a pleasure to serve you at @business. Please take a moment to complete this survey. Thanks! @link',
    uploadedFile: [],
    settings: {
      thankyouText: 'Thank you for your time, we look forward to serving you in the future',
      subType: 'simple',
    },
    emailTemplates: [],
    type: 'survey',
    isDefault: 0,
    subType: 'simple',
    campaignType: 'survey',
    emailSubject: 'We would love to get your feedback',
    emailTitle: '',
    location: selectedLoaction,
    postSites: [],
  });
  // eslint-disable-next-line max-len
  const [buttonIsDisabled, setbuttonIsDisabled] = useState(true);

  const [saveAsName, setSaveAsName] = useState('');
  const [saveAsNameError, setSaveAsNameError] = useState(false);
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);

  const gallaryModal = useModal();
  const gallaryModalEmail = useModal();
  const saveAsModal = useModal();
  const { surveyData, surveyDispatch } = useContext(SurveyContext);

  const imgSMS = (
    campaign?.uploadedFile.find((item) => item?.meta === 'sms' || item?.templateType === 'sms') ||
    {}
  ).url;
  const imgEmail = (
    campaign?.uploadedFile.find(
      (item) => item.meta === 'email' || item?.templateType === 'email',
    ) || {}
  ).url;

  const handleCancel = () => {
    // eslint-disable-next-line no-unused-expressions
    handleClose && handleClose();
  };
  const handleCampaignDeletion = async (campaignId) => {
    try {
      const postBody = {
        isDeleted: true,
      };
      const result = await baseUrl.patch(`/campaign/${campaignId}`, { ...postBody });
      if (result.status === 200) {
        setisLoading(false);
        handleClose();
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };
  const fetchNotifications = useCallback(async () => {
    try {
      if (selectedLoaction) {
        dispatch({ type: 'SET_NOTIFICATION_LOADER', payload: true });
        const result = await baseUrl(
          `/getnotifications/${localStorage.getItem('USER_ID')}/${selectedLoaction}`,
        );
        if (result.status === 200) {
          dispatch({ type: 'SET_NOTIFICATION_CONTAINER', payload: result.data });
          dispatch({ type: 'SET_NOTIFICATION_LOADER', payload: false });
        }
      }
    } catch (err) {
      dispatch({ type: 'SET_NOTIFICATION_LOADER', payload: false });
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  }, [selectedLoaction, dispatch]);

  const reviewsrequest = async (postBody) => {
    const result = await baseUrl.post(`/reviewsrequest`, { ...postBody });
    if (result.status === 200) {
      /*  surveyDispatch({
         type: 'SET_SELECTED_CONTACTS',
         payload: [],
       }); */
      handleCampaignDeletion(postBody.campaign);

      fetchNotifications();
      surveyDispatch({
        type: 'SET_STATUS_MESSAGE',
        payload: {
          redirectTo: '/app/feedback/survey',
          statusMessage: surveyData.selectedContacts.length
            ? `${surveyData.selectedContacts.length} Message Sent`
            : 'Message Sent',
        },
      });
    }
  };
  const uploadPreview = (clients, id) => {
    baseUrl
      .post(
        `group/client/upload-preview?business=${selectedBusiness}&location=${selectedLoaction}`,
        clients,
      )
      .then(({ data }) => {
        const postBody = {
          client: data.map((client) => client.id),
          user: parseInt(localStorage.getItem('USER_ID'), 10),
          campaign: id,
          location: selectedLoaction,
        };
        reviewsrequest(postBody);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const sendCampign = async (id) => {
    try {
      let postBody;
      if (surveyData.selectedContacts.length > 1) {
        uploadPreview(surveyData.selectedContacts, id);
      } else {
        postBody = {
          client: surveyData.selectedContacts[0].id,
          location: selectedLoaction,
          user: parseInt(localStorage.getItem('USER_ID'), 10),
          campaign: id,
        };
        reviewsrequest(postBody);
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const submitSaveAs = async (campaignData, removeCampaign = false) => {
    let invitationMessageCopy = campaign?.message;
    // if (userLink.titleLink.length) {
    //   invitationMessageCopy += `
    //   <a href="${userLink.link}">${userLink.titleLink}</a>
    //   `;
    // }
    // console.log(selectedLoaction);
    // console.log(campaignData.survey);
    // console.log(campaign);
    const uploadedFileNew = campaignData.uploadedFile.map((item) => item.id);
    const postBody = {
      ...campaignData,
      name: campaign.name,
      message: invitationMessageCopy,
      uploadedFile: campaign.uploadedFile ? campaign?.uploadedFile?.at(0).id : null,
      settings: { ...campaign.settings },
      emailTemplates: templateSelected?.id ? [templateSelected.id] : [],
      location: selectedLoaction,
      survey: campaign?.survey.id ?? null,
      reviewPortal: campaignData.reviewPortal,
      subType: campaignData.name.toLowerCase().replace(/\s/g, ''),
      campaignType: campaign.campaignType,
    };
    if (uploadedFileNew.length) {
      postBody.uploadedFile = uploadedFileNew;
    }

    // try {
    const result = await baseUrl.post(`/campaign`, { ...postBody });

    // if (result.status === 200) {
    // setisLoading(false);
    // eslint-disable-next-line no-unused-expressions
    if (removeCampaign) {
      sendCampign(result.data.id);
    } else {
      campaignDispatch({
        type: 'UPDATE_CAMPAIGN_CONTAINER',
        payload: Array.from(convertIntoCampaignCards([postBody])),
      });
      setisLoading(false);
      handleClose();
    }
    // }
    //   return;
    // } catch (error) {
    //   console.log(error);
    // }
  };
  const submitUpdate = async () => {
    let invitationMessageCopy = campaign?.message;
    if (userLink.titleLink.length) {
      invitationMessageCopy += `
      <a href="${userLink.link}">${userLink.titleLink}</a>
      `;
    }
    const uploadedFileNew = campaign.uploadedFile.map((item) => item.id);
    const postBody = {
      name: campaign.name,
      message: invitationMessageCopy,
      uploadedFile: campaign.uploadedFile,
      settings: { ...campaign.settings },
      emailTemplates: templateSelected?.id ? [templateSelected.id] : [],
      emailTitle: campaign.emailTitle,
      location: selectedLoaction,
      survey: campaign.survey.id ?? null,
    };

    if (uploadedFileNew.length) {
      postBody.uploadedFile = uploadedFileNew;
    }

    try {
      const result = await baseUrl.patch(`/campaign/${campaignId}`, { ...postBody });
      console.log(postBody);

      if (result.status === 200) {
        setisLoading(false);
        // eslint-disable-next-line no-unused-expressions
        handleClose && handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateReviewportal = async (removeCampaign = false) => {
    const displayName = campaign.survey.displayName.toLowerCase().replace(/\s/g, '');
    const existingPortal = getSelectedPortalInfo(
      { ...portalJson(businessLogo) },
      displayName,
      selectedBusiness,
    );

    const payloadMeta = {
      id: campaign.survey.id,
      title: campaign.survey.displayName,
      screens: campaign.survey.screens.screens,
    };
    existingPortal.meta[displayName] = payloadMeta;
    existingPortal.meta[displayName].screens = existingPortal.meta[displayName].screens.map(
      (item) => {
        if (item.screen === 'question') {
          item.questions = campaign.survey.questions.questions;
          return item;
        }
        return item;
      },
    );

    const result = await baseUrl.post(`/reviewportal`, {
      ...existingPortal,
    });

    if (result.status === 200) {
      let invitationMessageCopy = campaign?.message;
      // if (campaign?.userLink.titleLink.length && campaign?.userLink.link.length) {
      //   invitationMessageCopy += `
      //     <a href="${campaign.userLink.link}">${campaign.userLink.titleLink}</a>
      //     `;
      // }
      delete campaign.userLink;
      const postBody = {
        ...campaign,
        campaignType: 'survey',
        subType: campaign.settings.subType,
        settings: { ...campaign.settings },
        message: invitationMessageCopy,
        reviewPortal: [result.data.id],
        isActive: true,
      };
      submitSaveAs(postBody, removeCampaign);
    }
  };

  const getCampaignById = async () => {
    try {
      const result = await baseUrl.get(`/campaign/${campaignId}`, {});

      if (result?.data) {
        if (result.data.emailTemplates.length > 0) {
          setTemplateSelected({
            id: result.data.emailTemplates[0].id,
            value: result.data.emailTemplates[0].id,
            label: result.data.emailTemplates[0].name,
          });
        }

        setCampaign({
          ...result.data,
          location: result.data.location[0].id,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditCampaingSubmit = (value) => {
    setUserLink({
      titleLink: value.title,
      link: value.link,
    });
  };

  const changeSubject = (value) => {
    setCampaign({
      ...campaign,
      emailSubject: value,
    });
  };

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  const handleSaveAsNameChange = (e) => {
    if (e.target.value !== '') {
      setSaveAsName(e.target.value);
      setSaveAsNameError(false);
      setDisableSaveBtn(false);
    } else {
      setSaveAsNameError(true);
      setDisableSaveBtn(true);
    }
  };

  const handleSaveAs = () => {
    setCampaign((prevCampaign) => ({
      ...prevCampaign,
      name: saveAsName,
    }));
    handleCreateReviewportal(false);
  };

  const fetchBusinessLogo = useCallback(async () => {
    if (selectedLoaction) {
      try {
        const result = await baseUrl(`location/${selectedLoaction}/uploadedFile`);
        if (result.status === 200) {
          setBusinessLogo(getMetaImage(result.data, 'business_logo'));
        }
      } catch (err) {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  }, [selectedLoaction]);

  useEffect(() => {
    if (campaignId) {
      getCampaignById();
    }
  }, [campaignId]);

  useEffect(() => {
    setbuttonIsDisabled(
      !(campaign?.name.length && campaign?.message.length && campaign?.survey && imgSMS),
    );
  }, [campaign?.name, campaign?.message, campaign?.survey, imgSMS]);

  useEffect(() => {
    fetchBusinessLogo();
  }, [fetchBusinessLogo]);

  const handleTemplateTitleChange = (value) => {
    setCampaign({
      ...campaign,
      name: value,
    });
  };

  return (
    <>
      {isLoading && <ContainerLoader hideContent />}
      <Box style={containerStyles}>
        <TemplateTitleEdit
          campaign={campaign}
          handleChange={handleTemplateTitleChange}
          type="Survey"
        />
        <MuiThemeProvider theme={tabsTheme}>
          <AntTabs value={tabValue} onChange={handleTabChange} aria-label="ant">
            <AntTab label="Message" />
            <AntTab label="Email" />
            <AntTab label="Questions" />
          </AntTabs>
        </MuiThemeProvider>
        {tabValue === 0 && (
          <TabMessage
            imgSMS={imgSMS}
            gallaryModal={gallaryModal}
            imgEmail={imgEmail}
            gallaryModalEmail={gallaryModalEmail}
            campaign={campaign}
            setcampaign={setCampaign}
            userLink={userLink}
            isLoading={isLoading}
            setShowEditCampaign={setShowEditCampaign}
            handleCancel={handleCancel}
            buttonIsDisabled={buttonIsDisabled}
            submitSaveAs={submitSaveAs}
            showEditCampaign={showEditCampaign}
            handleEditCampaingSubmit={handleEditCampaingSubmit}
          />
        )}
        {tabValue === 1 && (
          <TabEmail
            templateSelected={templateSelected}
            setTemplateSelected={setTemplateSelected}
            changeSubject={changeSubject}
            subjectValue={campaign?.emailSubject}
            emailErrors={emailErrors}
          />
        )}

        {tabValue === 2 && (
          <TabQuestion campaignId={campaignId} campaign={campaign} setcampaign={setCampaign} />
        )}
      </Box>
      <Box style={footerStyles}>
        {campaignId && (
          <PrimaryButton outlined disabled={buttonIsDisabled} onClick={saveAsModal.handleShowModal}>
            Save as...
          </PrimaryButton>
        )}
        {!isOnlySave && (
          <Box display="flex" marginLeft="auto" style={{ gap: '.5rem' }}>
            <PrimaryButton text onClick={handleCancel}>
              Cancel
            </PrimaryButton>
            {campaignId ? (
              <PrimaryButton disabled={buttonIsDisabled} onClick={submitUpdate}>
                Update
              </PrimaryButton>
            ) : (
              <PrimaryButton
                disabled={buttonIsDisabled}
                onClick={() => handleCreateReviewportal(true)}
              >
                Save Survey
              </PrimaryButton>
            )}
          </Box>
        )}
      </Box>

      <GenricPopup open={saveAsModal.showModal} handleClose={saveAsModal.handleHideShowModal}>
        <Box padding="1rem" style={{ rowGap: '1rem', display: 'flex', flexDirection: 'column' }}>
          <Box padding=".5rem" style={{ rowGap: '1rem', display: 'flex', flexDirection: 'column' }}>
            <PageTitle title="Save as..." />
            <StyledTextField
              placeholder="Survey name"
              value={saveAsName}
              onChange={(e) => handleSaveAsNameChange(e)}
              error={saveAsNameError}
              autoFocus
              helperText={saveAsNameError ? "Survey name can't be empty" : ''}
            />
          </Box>
          <Box display="flex" justifyContent={'flex-end'} style={{ columnGap: '.5rem' }}>
            <PrimaryButton text onClick={saveAsModal.handleHideShowModal}>
              Cancel
            </PrimaryButton>
            <PrimaryButton disabled={disableSaveBtn} onClick={handleSaveAs}>
              Save
            </PrimaryButton>
          </Box>
        </Box>
      </GenricPopup>
    </>
  );
};

export default Survey;
