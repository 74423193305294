import React from 'react';
import Switch from '@material-ui/core/Switch';
import Proptypes from 'prop-types';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

export default function CustomizedSwitches(props) {
  const { name } = props;

  const theme = createTheme({
    palette: {
      primary: {
        main: '#30FEAF',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Switch
        color="primary"
        inputProps={{ 'aria-label': 'Without label' }}
        checked
        name={name}
        {...props}
      />
    </ThemeProvider>
  );
}
CustomizedSwitches.propTypes = {
  flag: Proptypes.bool,
  name: Proptypes.string,
};
