import React, { useState, useEffect, useRef } from 'react';
import EditIcon from 'mdi-react/PencilOutlineIcon';
import CheckIcon from '@material-ui/icons/Check';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

const StyleTitle = styled.h1`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  padding: 10px;
  border: 1px solid transparent;
`;

const StyleTitleInput = styled.input`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 7px 10px;
  width: 100%;
  transition: border-color 0.3s;
  &:focus {
    border-color: #35eda7;
    outline: none;
    border-radius: 10px;
  }
`;

const TemplateTitleEdit = (props) => {
  const { handleChange, campaign, type, errors, setErrors } = props;

  const [showEditTemplateName, setShowEditTemplateName] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState(campaign.name);

  const emptyTemplateName = `New ${type} template`;

  const inputRef = useRef(null);

  // useEffect(() => {
  //   errors ? setShowEditTemplateName(true) : setShowEditTemplateName(false);
  // }, [errors, setShowEditTemplateName]);

  useEffect(() => {
    setNewTemplateName(campaign.name ? campaign.name : emptyTemplateName);
  }, [campaign]);

  useEffect(() => {
    if (showEditTemplateName) {
      inputRef.current.select();
    }
  }, [showEditTemplateName]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (newTemplateName !== emptyTemplateName) {
        setErrors && setErrors({});
      }
      setShowEditTemplateName(false);
    }
  };

  const handleFinishEdit = () => {
    if (newTemplateName !== emptyTemplateName) {
      setErrors && setErrors({});
    }
    setShowEditTemplateName(false);
  };

  const handleEditName = () => {
    setShowEditTemplateName(!showEditTemplateName);
  };

  const changeTemplateName = (value) => {
    setNewTemplateName(value);
    handleChange(value);
  };

  const titleColor = (templateName) => {
    if (errors && errors.templateName) {
      setShowEditTemplateName(true);
      return 'red';
    } else if (templateName === emptyTemplateName) {
      return '#bbbbbb';
    } else {
      return 'black';
    }
  };
  return (
    <div style={{ marginLeft: '-10px' }}>
      {showEditTemplateName ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '45px',
          }}
        >
          <StyleTitleInput
            type="text"
            value={newTemplateName}
            onChange={(e) => changeTemplateName(e.target.value)}
            onKeyDown={handleKeyDown}
            autoFocus
            ref={inputRef}
          />

          <IconButton onClick={handleFinishEdit}>
            <CheckIcon />
          </IconButton>
        </div>
      ) : (
        <div style={{ display: 'flex', height: '45px', alignItems: 'center' }}>
          <StyleTitle
            style={{
              color: titleColor(newTemplateName),
            }}
            onClick={handleEditName}
          >
            {newTemplateName}
          </StyleTitle>
          <IconButton onClick={handleEditName}>
            <EditIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default TemplateTitleEdit;
