import React from 'react';
import AldonLogo from '../../assets/images/aldon_logo.svg';
import { ReactSVG } from 'react-svg';
import styles from './surveyCard.module.scss';
import SocketStore from '../../Stores/SocketStore';

const SurveyComplete = (props) => {
  return (
    <SocketStore>
      <div className={styles.survey}>
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '1rem' }}>
          <ReactSVG src={AldonLogo} />
        </div>

        <div className={styles.title}>
          {"It looks like you've already completed this survey, thanks for your time!"}
        </div>
      </div>
    </SocketStore>
  );
};

export default SurveyComplete;
