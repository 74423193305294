import React, { useEffect, useState, useContext } from 'react';
import {
  Grid,
  Paper,
  Box,
  Button,
  makeStyles,
  IconButton,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import CloseIcon from 'mdi-react/CloseCircleIcon';
import styled from 'styled-components';
import ExpandIcon from 'mdi-react/ChevronDownIcon';
import CustomSwitch from '../../components/CustomSwitch/CustomSwitch';
import ReviewsCarousel from './ReviewTypes/ReviewsCarousel';
import ReviewsGrid from './ReviewTypes/ReviewsGrid';
import ReviewsMasonry from './ReviewTypes/ReviewsMasonry';
import Badge from './ReviewTypes/Badge';
import WidgetHeader from './WidgetHeader';
import SettingsPanel from '../../components/SettingsPanel/SettingsPanel';
import baseUrl from '../../utils/services/config';
import { CSSTransition } from 'react-transition-group';
import { errorChecker } from '../../utils/helper/helper';
import useAlert from '../../hooks/useAlert';
import CusotmAlert from '../../components/CustomAlert/CustomAlert';
import EditIcon from 'mdi-react/EditIcon';
import Summary from './ReviewTypes/Summary';

const PrimaryButton = styled(Button)`
  background-color: #30feaf !important;
  margin-left: 10px !important;
  text-transform: capitalize !important;
  letter-spacing: unset !important;
  font-weight: 600 !important;
  padding: 6px 20px !important;
`;

const DeleteButton = styled(Button)`
  background-color: #ff3d3d !important;
  margin-left: 10px !important;
  text-transform: capitalize !important;
  letter-spacing: unset !important;
  font-weight: 600 !important;
  padding: 6px 20px !important;
  color: white !important;
`;

const StyledWidgetTitle = styled.div`
  font-weight: 600;
  font-size: ${(props) => props.titleSize};
  text-align: center;
  margin-bottom: 2rem;
  color: ${(props) => props.widgetTitleColor};
  margin-top: ${(props) => props.marginTop};
`;

function ReviewsWidget(props) {
  const { id, uuid, widgetSettings, isDeleted, fetchWidgets } = props;

  const [isEnabled, setIsEnabled] = useState(isDeleted);
  const [isWidgetExpanded, setIsWidgetExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [postSites, setPostSites] = useState([]);
  const [isSlideoutVisible, setIsSlideoutVisible] = useState(false);
  const [widgetCode, setWidgetCode] = useState('');
  const [editingTitle, setEditingTitle] = useState(false);

  const { state, dispatch } = useContext(GlobalContext);
  const widgetAlert = useAlert();

  // const [settings, setSettings] = useState({
  //   widgetName: 'My First Widget',
  //   isLight: true,
  //   showTabs: true,
  //   showHeader: true,
  //   widgetStyle: 'badge',
  //   widgetPlacement: 'left',
  //   showWidgetTitle: true,
  //   widgetTitleText: 'What our customers say',
  //   radius: '10',
  //   gap: '30',
  //   isTextExpanded: false,
  //   reviewStyle: 'default',

  //   colors: {
  //     brandColor: '#6e08ff',
  //     backgroundColor: '#f1f1f1',
  //     containerBgColor: '#000000',
  //     containerBgOpacity: '18', //'ff' is fully opaque,
  //     widgetTitleColor: '#000000',
  //   },
  //   reviewButton: {
  //     isRounded: false,
  //     isSolid: true,
  //     textColor: '#ffffff',
  //     buttonColor: '#6e08ff',
  //     showWriteReviewButton: true,
  //   },
  //   carousel: {
  //     autoPlay: 0,
  //   },
  // });

  const [settings, setSettings] = useState(widgetSettings);

  const { selectedLoaction } = state;

  const brandColor = settings.colors.brandColor;
  const textColor = settings.isLight ? '#000000' : '#ffffff';

  const updateSetting = (settingPath, value) => {
    setIsLoading(true);
    const keys = settingPath.split('.');
    const updatedSettings = { ...settings };

    let currentLevel = updatedSettings;
    for (let i = 0; i < keys.length - 1; i++) {
      currentLevel = currentLevel[keys[i]];
    }

    currentLevel[keys[keys.length - 1]] = value;
    setSettings(updatedSettings);
    setIsLoading(false);
  };

  const useStyles = makeStyles(() => ({
    '@keyframes fadeIn': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
    root: {
      paddingTop: '2rem',
    },

    reviewContainer: {
      borderRadius: '8px',
      overflow: 'hidden',
    },
    swatch: {
      padding: '5px',
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
    },
    expanded: {
      display: 'flex',
      marginTop: '3rem',
      animation: 'fadeIn',
      animationDuration: '.3s',
      transition: 'display: 1s opacity: .8s',
    },
    collapsed: {
      display: 'none',
    },
    webchatCode: {
      fontSize: '14px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    edit_stuff: {
      display: 'flex',
      alignItems: 'center',
    },
    //new styles
    widget_title: {
      fontWeight: 600,
      fontSize: '24px',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      '&:hover $edit_widget_title': {
        display: 'block',
      },
    },
    edit_widget_title: {
      display: 'none',
      marginLeft: '1rem',
    },

    Review_Sidebar: {
      width: '450px',
      position: 'absolute',
      backgroundColor: settings.colors.backgroundColor,
      top: 0,
      left: 0,
      bottom: 0,
      padding: '0 1.5rem',
      zIndex: 999,
      cursor: 'default',
    },
    Review_Sidebar_Content: {
      overflowY: 'auto',
      height: '100%',
    },
    bgOverlay: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      backgroundColor: '#00000080',
      zIndex: 998,
    },
    bg_enter: {
      opacity: '0',
    },

    bg_enter_active: {
      opacity: '1',
      transition: 'opacity 300ms ease-in-out',
    },

    bg_exit: {
      opacity: '0',
    },

    bg_exit_active: {
      opacity: '0',
      transition: 'opacity 300ms ease-in-out',
    },
    slide_enter: {
      transform: 'translateX(-100%)',
    },

    slide_enter_active: {
      transform: 'translateX(0%)',
      transition: 'transform 300ms ease-in-out',
    },

    slide_exit: {
      transform: 'translateX(0%)',
    },

    slide_exit_active: {
      transform: 'translateX(-100%)',
      transition: 'transform 300ms ease-in-out',
    },
    loader: {
      display: 'flex',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: '#00000080',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '8px',
      zIndex: 10,
    },
  }));

  const classes = useStyles();

  const fetchReviewData = async () => {
    try {
      const result = await baseUrl.get(`/reviewWidgetData?id=${uuid}`);
      if (result.status === 200) {
        const reviews = result.data.list.slice(0, 10);
        setReviews(reviews);
        setPostSites(result.data.postSites);
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  useEffect(() => {
    fetchReviewData();
    setWidgetCode(
      `<script defer id="reveo-reviews" data-uuid="${uuid}" data-id=${id} src="https://static.reveo.com/reviews/js/build/reveo-reviews.js"></script><div id=${uuid}></div>`,
    );
  }, [uuid]);

  const getStyledReviews = () => {
    if (settings.widgetStyle === 'carousel') {
      return <ReviewsCarousel reviews={reviews} settings={settings} postSites={postSites} />;
    } else if (settings.widgetStyle === 'masonry') {
      return <ReviewsMasonry reviews={reviews} settings={settings} postSites={postSites} />;
    } else if (settings.widgetStyle === 'grid' || settings.widgetStyle === 'list') {
      return <ReviewsGrid reviews={reviews} settings={settings} postSites={postSites} />;
    } else {
      return <ReviewsGrid reviews={reviews} settings={settings} postSites={postSites} />;
    }
  };

  const titleAndReviewContent = (
    <>
      {settings.showWidgetTitle && (
        <StyledWidgetTitle
          widgetTitleColor={settings.colors.widgetTitleColor}
          titleSize={
            settings.widgetStyle === 'badge' || settings.widgetStyle === 'summary' ? '22px' : '32px'
          }
          marginTop={
            settings.widgetStyle === 'badge' || settings.widgetStyle === 'summary' ? '1rem' : null
          }
        >
          {settings.widgetTitleText}
        </StyledWidgetTitle>
      )}
      {settings.showHeader && (
        <WidgetHeader settings={settings} reviews={reviews} postSites={postSites} />
      )}
      {getStyledReviews()}
    </>
  );

  const badgeContent = (
    <>
      <Badge
        settings={settings}
        reviews={reviews}
        postSites={postSites}
        onClick={() => showReviewSlideout()}
      />
      <CSSTransition
        in={isSlideoutVisible}
        timeout={300}
        classNames={{
          enter: classes.bg_enter,
          enterActive: classes.bg_enter_active,
          exit: classes.bg_exit,
          exitActive: classes.bg_exit_active,
        }}
        unmountOnExit
      >
        <div className={classes.bgOverlay} onClick={() => setIsSlideoutVisible(false)}></div>
      </CSSTransition>
      <CSSTransition
        in={isSlideoutVisible}
        timeout={300}
        classNames={{
          enter: classes.slide_enter,
          enterActive: classes.slide_enter_active,
          exit: classes.slide_exit,
          exitActive: classes.slide_exit_active,
        }}
        unmountOnExit
      >
        <div className={classes.Review_Sidebar}>
          <IconButton
            style={{ position: 'absolute', right: 0, top: 0 }}
            onClick={() => setIsSlideoutVisible(false)}
          >
            <CloseIcon size="36px" color={textColor + '80'} />
          </IconButton>
          <div className={classes.Review_Sidebar_Content}>{titleAndReviewContent}</div>
        </div>
      </CSSTransition>
    </>
  );

  const summaryContent = (
    <>
      <Summary
        settings={settings}
        reviews={reviews}
        postSites={postSites}
        onClick={() => showReviewSlideout()}
      />
      <CSSTransition
        in={isSlideoutVisible}
        timeout={300}
        classNames={{
          enter: classes.bg_enter,
          enterActive: classes.bg_enter_active,
          exit: classes.bg_exit,
          exitActive: classes.bg_exit_active,
        }}
        unmountOnExit
      >
        <div className={classes.bgOverlay} onClick={() => setIsSlideoutVisible(false)}></div>
      </CSSTransition>
      <CSSTransition
        in={isSlideoutVisible}
        timeout={300}
        classNames={{
          enter: classes.slide_enter,
          enterActive: classes.slide_enter_active,
          exit: classes.slide_exit,
          exitActive: classes.slide_exit_active,
        }}
        unmountOnExit
      >
        <div className={classes.Review_Sidebar}>
          <IconButton
            style={{ position: 'absolute', right: 0, top: 0 }}
            onClick={() => setIsSlideoutVisible(false)}
          >
            <CloseIcon size="36px" color={textColor + '80'} />
          </IconButton>
          <div className={classes.Review_Sidebar_Content}>{titleAndReviewContent}</div>
        </div>
      </CSSTransition>
    </>
  );

  const showReviewSlideout = () => {
    setIsSlideoutVisible(true);
  };

  const editTitle = (e) => {
    e.stopPropagation();
    setEditingTitle(true);
  };

  const handleSaveChanges = async () => {
    const { handleSetMessage, handleSetMessageType, handleShow } = widgetAlert;
    try {
      const postBody = {
        widgetId: id,
        updatedWidget: JSON.stringify({ ...settings, location: selectedLoaction }),
        isDeleted: isDeleted,
      };
      const result = await baseUrl.post(`business/updateWidget`, { ...postBody });
      if (result.status === 200) {
        handleSetMessageType('success');
        handleSetMessage(result.data.message);
        handleShow(true);
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const handleDeleteWidget = async (e, value) => {
    const { handleSetMessage, handleSetMessageType, handleShow } = widgetAlert;
    try {
      const postBody = {
        widgetId: id,
        updatedWidget: JSON.stringify(settings),
        isDeleted: value,
      };
      const result = await baseUrl.post(`business/updateWidget`, { ...postBody });
      if (result.status === 200) {
        handleSetMessageType('success');
        handleSetMessage(result.data.message);
        handleShow(true);
        fetchWidgets();
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const saveTitle = (e) => {
    e.stopPropagation();
    handleSaveChanges();
    setEditingTitle(false);
  };

  const disableWidget = async (e, value) => {
    e.stopPropagation();
    setIsEnabled(value);
  };

  return (
    <Grid container style={{ marginBottom: '1rem', height: '100%' }}>
      <Grid item xs={12} md={12} lg={12} style={{ position: 'relative', overflow: 'hidden' }}>
        {isLoading ? (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        ) : null}
        <Paper
          variant="outlined"
          style={{
            padding: '2rem',
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <Box display="flex" alignItems={'center'} justifyContent={'space-between'}>
              <div
                className={classes.widget_title}
                onClick={() => setIsWidgetExpanded(!isWidgetExpanded)}
              >
                <div className={classes.edit_stuff}>
                  {editingTitle ? (
                    <>
                      <TextField
                        variant="outlined"
                        size="small"
                        onChange={(e) => updateSetting('widgetName', e.target.value)}
                        value={settings.widgetName}
                        onClick={(e) => e.stopPropagation()}
                        style={{ width: '300px' }}
                      />
                      <PrimaryButton onClick={(e) => saveTitle(e)}>Save</PrimaryButton>
                    </>
                  ) : (
                    <span>{settings.widgetName}</span>
                  )}
                  <EditIcon
                    color={'#30feaf'}
                    className={classes.edit_widget_title}
                    onClick={(e) => editTitle(e)}
                    style={{ display: editingTitle ? 'none' : '' }}
                  />
                </div>
              </div>
              <div>
                {!isEnabled ? (
                  <PrimaryButton
                    onClick={() => {
                      handleSaveChanges();
                    }}
                  >
                    Save Changes
                  </PrimaryButton>
                ) : (
                  <DeleteButton
                    onClick={(e) => {
                      handleDeleteWidget(e, isEnabled);
                    }}
                  >
                    Delete Widget
                  </DeleteButton>
                )}
                <CustomSwitch
                  checked={isEnabled ? false : true}
                  onChange={(e) => disableWidget(e, !isEnabled)}
                />
                <Button onClick={() => setIsWidgetExpanded(!isWidgetExpanded)}>
                  <ExpandIcon
                    size={'20px'}
                    style={{
                      transition: 'transform 0.3s ease-in-out',
                      transform: isWidgetExpanded ? 'rotate(180deg)' : null,
                    }}
                  />
                </Button>
              </div>
            </Box>
          </Grid>
          {isWidgetExpanded ? (
            <Grid container style={{ marginTop: '2rem', height: '100%' }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
                style={{ display: 'block', paddingRight: '1rem' }}
              >
                {/* {settingsContent} */}
                <SettingsPanel
                  settings={settings}
                  updateSetting={updateSetting}
                  widgetCode={widgetCode}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                <Paper
                  variant="outlined"
                  style={{
                    padding: '2rem',
                    flexDirection: 'column',
                    backgroundColor: settings.colors.backgroundColor,
                    overflow: settings.widgetStyle === 'badge' ? 'hidden' : 'auto',
                    position: 'relative',
                    minHeight: '300px',
                  }}
                >
                  {(() => {
                    switch (settings.widgetStyle) {
                      case 'badge':
                        return badgeContent;
                      case 'summary':
                        return summaryContent;
                      default:
                        return titleAndReviewContent;
                    }
                  })()}
                </Paper>
              </Grid>
            </Grid>
          ) : null}
        </Paper>
      </Grid>
      <CusotmAlert
        message={widgetAlert.message}
        handleClose={widgetAlert.handleClose}
        open={widgetAlert.show}
        messageType={widgetAlert.messageType}
      />
    </Grid>
  );
}

export default ReviewsWidget;
