import React, { useEffect } from 'react';
import InitialRoute from '../routes/InitialRoute';

const scriptsContainer = [
  // '../scripts/chatBot.js',
  // '../scripts/reviewsOnly.js',
  // '../scripts/reviewSummary.js',
  // '../scripts/reviewsWidgetList.js',
  // '../scripts/topReviewSources.js',
  // '../scripts/widgetsPageComponent.js',
];

function ScriptLoader() {
  const handleScriptInsertion = (scripts) => {
    scripts.forEach((item) => {
      const script = document.createElement('script');
      script.src = item;
      script.type = 'text/javascript';
      script.async = true;
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    handleScriptInsertion(scriptsContainer);
  }, []);
  return <InitialRoute />;
}

export default ScriptLoader;
