import { createTheme } from '@material-ui/core/styles';
import { useContext, useMemo, useState } from 'react';
import { GlobalContext } from '../Stores/GlobalStateStore';

const useAntSwitchTheme = () => {
  const { state } = useContext(GlobalContext);
  const { themeContainer } = state;
  const [theme, setTheme] = useState({});
  useMemo(() => {
    setTheme(
      createTheme({
        backgroundColor: themeContainer.switchColor,
        borderColor: themeContainer.switchColor,
        switchTrackColor: themeContainer.switchTrackColor,
        switchCheckColor: themeContainer.switchCheckColor,
      }),
    );
  }, [themeContainer]);
  return {
    antSwitchTheme: theme,
  };
};
export default useAntSwitchTheme;
