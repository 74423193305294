import React, { useState, useEffect } from 'react';
import {
  Box,
  Select,
  MenuItem,
  OutlinedInput,
  makeStyles,
  InputAdornment,
  Slider,
} from '@material-ui/core';
import styled from 'styled-components';
import CustomSwitch from '../../components/CustomSwitch/CustomSwitch';
import useDebounce from '../../hooks/useDebounce';
import { CompactPicker } from 'react-color';

const SettingItem = (props) => {
  const {
    title,
    settingsTitle,
    value,
    updateSetting,
    menuItems,
    type,
    label,
    children,
    adornment,
  } = props;

  const [tempValue, setTempValue] = useState(value);
  const debouncedValue = useDebounce(tempValue, 1000);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [tempColor, setTempColor] = useState(value);

  const useStyles = makeStyles(() => ({
    colorBlock: {
      width: '28px',
      height: '28px',
      borderRadius: '50%',
    },
    swatch: {
      borderRadius: '50%',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
      marginRight: '.1rem',
    },
    popover: {
      position: 'absolute',
      zIndex: '2',
      right: '2.5rem',
      transform: 'translateY(-2rem)',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
    menuItem: {
      textTransform: 'capitalize',
      fontFamily: 'inherit',
      fontSize: '14px',
    },
    select: {
      textTransform: 'capitalize',
      padding: '10.5px 0 10.5px 15px',
      fontSize: '14px',
    },
    input: {
      padding: '11px 0 15px 15px',
      fontSize: '14px',
      fontFamily: 'inherit',
    },
    adornment: {
      fontSize: '14px !important',
      fontFamily: 'inherit !important',
    },
    slider: {
      '& .MuiSlider-rail': {
        backgroundColor: '#ccc',
      },
      '& .MuiSlider-track': {
        backgroundColor: '#30feaf',
      },
      '& .MuiSlider-thumb': {
        backgroundColor: '#30feaf',
        boxShadow: '0px 0px 0px 8px #30feaf50',
      },
      '& .MuiSlider-thumb.MuiSlider-active': {
        boxShadow: '0px 0px 0px 14px #30feaf50',
      },
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (debouncedValue !== tempValue) {
        console.log('changing settings: ' + settingsTitle, tempValue);
        updateSetting(settingsTitle, tempValue);
      }
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [tempValue]);

  const WidgetSetting = styled.div`
    font-weight: 500;
    min-width: 50%;
    font-size: 14px;
  `;

  const doStuff = (value) => {
    setTempValue(value);

    if (settingsTitle === 'isLight') {
      if (!value) {
        updateSetting('colors.backgroundColor', '#3e3e3e');
        updateSetting('colors.containerBgColor', '#ffffff');
        updateSetting('colors.widgetTitleColor', '#ffffff');
      } else {
        updateSetting('colors.backgroundColor', '#f1f1f1');
        updateSetting('colors.containerBgColor', '#000000');
        updateSetting('colors.widgetTitleColor', '#000000');
      }
    }
  };

  const handleTextChange = (e) => {
    const inputValue = e.target.value;
    let finalValue = inputValue;
    if (settingsTitle === 'carousel.autoPlay') {
      finalValue = inputValue * 1000;
    }
    setTempValue(finalValue);
  };

  const control = () => {
    if (type === 'menu') {
      return (
        <Select
          variant="outlined"
          size="small"
          value={value}
          onChange={(e) => setTempValue(e.target.value)}
          fullWidth
          classes={{ select: classes.select }}
        >
          {menuItems &&
            menuItems.map((item, index) => (
              <MenuItem
                key={index}
                value={item}
                selected={value === item}
                className={classes.menuItem}
              >
                {item}
              </MenuItem>
            ))}
        </Select>
      );
    } else if (type === 'color') {
      return (
        <div style={{ height: '38px' }}>
          <div className={classes.swatch} onClick={() => setShowColorPicker(!showColorPicker)}>
            <div className={classes.colorBlock} style={{ backgroundColor: tempColor }} />
          </div>
          {showColorPicker ? (
            <div className={classes.popover}>
              <div className={classes.cover} onClick={() => setShowColorPicker(!showColorPicker)} />
              <CompactPicker
                color={value}
                disableAlpha
                onChange={(color) => setTempColor(color.hex)}
                onChangeComplete={(color) => setTempValue(color.hex)}
              />
            </div>
          ) : null}
        </div>
      );
    } else if (type === 'text') {
      return (
        <OutlinedInput
          classes={{ input: classes.input }}
          value={settingsTitle === 'carousel.autoPlay' ? tempValue / 1000 : tempValue}
          fullWidth
          onChange={handleTextChange}
          endAdornment={
            adornment && (
              <InputAdornment className={classes.adornment} position="end">
                {adornment}
              </InputAdornment>
            )
          }
        />
      );
    } else if (type === 'toggle') {
      return <CustomSwitch checked={value} onChange={() => doStuff(!value)} />;
    } else if (type === 'slider') {
      return (
        <Slider
          className={classes.slider}
          style={{ margin: '0 20px' }}
          defaultValue={() => {
            let tempValue = value;
            if (value === '00') {
              tempValue = 0;
            } else if (value === 'ff') {
              tempValue = 100;
            }
            return tempValue;
          }}
          step={1}
          onChange={(event, newValue) => {
            let tempValue = newValue;
            if (newValue === 0) {
              tempValue = '00';
            } else if (newValue === 100) {
              tempValue = 'ff';
            }
            setTempValue(tempValue);
          }}
        />
      );
    } else if (type === 'children') {
      return children;
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flex="1"
      marginBottom="10px"
    >
      {label === false ? null : <WidgetSetting>{title}</WidgetSetting>}
      {control()}
    </Box>
  );
};

export default SettingItem;
