import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Loader from '../components/Loader/Loader';
import baseUrlLogin from '../utils/services/auth.config';
import { getParameterByName } from '../utils/helper/helper';
import CusotmAlert from '../components/CustomAlert/CustomAlert';

function AccountActivate() {
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const history = useHistory();
  useEffect(() => {
    const code = getParameterByName('code');
    baseUrlLogin(`/account/activate?code=${code}`)
      .then((res) => {
        if (res.status === 200) {
          setShowAlert(true);
          localStorage.clear();
          setTimeout(() => {
            setShowAlert(false);
            history.push('/');
          }, 2000);
        }
      })
      .catch(() => {
        setShowAlert(true);
        setShowError(true);
        localStorage.clear();
        setTimeout(() => {
          history.push('/');
        }, 2000);
      });
  }, [history]);

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <div>
      <CusotmAlert
        open={showAlert}
        message={showError ? 'Invalid activation link' : 'Account activated successfully'}
        handleClose={handleCloseAlert}
        messageType={showError ? 'error' : 'sucess'}
      />
      <Loader title="Activating Account" />
    </div>
  );
}

export default AccountActivate;
