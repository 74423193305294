import React from 'react';
import Proptypes from 'prop-types';
import { Grid, ListItem, ListItemAvatar, ListItemText, Box } from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../setup';
import CusotmAvatar from '../../CustomAvatar/CustomAvatar';
import { getCampaignType } from '../../../utils/helper/helper';
import StyledLockIcon from '../../Icons/LockIcon';
import useIconTheme from '../../../hooks/useIconTheme';
import useTextColors from '../../../hooks/useTextColors';
import { getTypeBadge } from '../../../utils/helper/helper';

const StyledListItemContainer = styled.div`
  cursor: pointer !important;
  border-bottom: 1px solid #e8e7e7 !important;
  background: ${(props) => props.theme.background} !important;
  &:hover {
    background-color: #e8e7e7 !important;
  }
  display: flex !important;
  padding: 8px 4px !important;
`;

const StyledListItem = styled(ListItem)`
  cursor: ${(props) => (props.theme.cursor ? props.theme.cursor : 'pointer')} !important;
  font-family: 'Montserrat' !important;
`;

const StyledName = styled(ListItemText)`
  & > span {
    font-weight: 600 !important;
    color: ${(props) => props.theme.color} !important;
    font-family: 'Montserrat' !important;
    font-size: 14px !important;
  }
  & > p {
    color: ${(props) => props.theme.color} !important;
    font-family: 'Montserrat' !important;
    font-size: 12px !important;
    max-width: 150ch !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
  }
`;

const StyledType = styled.div`
  font-size: 12px !important;
  color: ${(props) => props.theme.color}!important;
  cursor: pointer !important;
  font-family: 'Montserrat' !important;
`;

const StyledCardTitle = styled.div`
  color: ${(props) => props.theme.titleColor};
  font-family: Montserrat;
  font-weight: 600;
  font-size: 14px;
  padding: 0.1rem 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
`;

const StyledDescription = styled.div`
  color: ${(props) => props.theme.descriptionColor};
  font-family: Montserrat;
  font-weight: 400;
  font-size: 12px;
  padding: 0.1rem 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: gray;
  max-width: 350px;
`;

const avatarTheme = {
  width: '50px',
  height: '50px',
  lgWidth: '60px',
  lgHeight: '60px',
  margin: '0',
  bgColor: theme.btn_background_color,
};

const lockTheme = {
  fontSize: '12px',
};

function CampaignList(props) {
  const {
    selectedCampaign,
    index,
    handleSelection,
    name,
    url,
    message,
    campaignType,
    lastSent,
    canResendAfter,
  } = props;
  const { iconsTheme } = useIconTheme();
  const { textColors } = useTextColors();

  const lastSentContent = lastSent ? (
    <Grid item>
      <div title={`Campaign is under ${canResendAfter} days of lock in period`}>
        <ThemeProvider theme={{ ...lockTheme, color: iconsTheme.lockIcon }}>
          <StyledLockIcon />
        </ThemeProvider>
      </div>
    </Grid>
  ) : null;
  return (
    <ThemeProvider
      theme={{
        background: selectedCampaign === index ? theme.btn_background_color : 'transparent',
      }}
    >
      <StyledListItemContainer>
        <ThemeProvider theme={{ cursor: lastSent ? 'not-allowed' : 'pointer' }}>
          <div
            onClick={() => (lastSent ? {} : handleSelection(index))}
            style={{ display: 'flex', flex: 1 }}
          >
            <Box flex={1} display="flex">
              {url && url != undefined ? (
                <img
                  style={{
                    height: '45px',
                    borderRadius: '4px',
                    marginRight: '.5rem',
                    aspectRatio: '9 / 16',
                    objectFit: 'cover',
                  }}
                  src={`${process.env.REACT_APP_IMAGE_ENDPOINT}${url}`}
                />
              ) : null}
              <Box display="flex" justifyContent="space-between" flex={1}>
                <Box display="flex" flexDirection={'column'} flex={1}>
                  <Box display="flex" alignItems="center" justifyContent={'space-between'}>
                    <StyledCardTitle>{name}</StyledCardTitle>
                    {getTypeBadge(campaignType)}
                  </Box>
                  {message ? <StyledDescription>{message}</StyledDescription> : null}
                </Box>
                {lastSentContent}
              </Box>
            </Box>
          </div>
        </ThemeProvider>
      </StyledListItemContainer>
    </ThemeProvider>
  );
}

CampaignList.propTypes = {
  selectedCampaign: Proptypes.number,
  index: Proptypes.number,
  handleSelection: Proptypes.func,
  name: Proptypes.string,
  url: Proptypes.string,
  message: Proptypes.string,
  campaignType: Proptypes.string,
  lastSent: Proptypes.bool,
  canResendAfter: Proptypes.number,
};

CampaignList.defaultProps = {
  url: '',
  lastSent: false,
};

export default CampaignList;
