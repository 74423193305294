import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import baseUrl from '../../utils/services/config';
import ResponseDetails from './SurveyReport/components/ResponseDetails';
import GenericPopup from '../../components/Popup/GenricPopup';

const StyledCircularProgress = styled(CircularProgress)`
  color: ${(props) => (props.theme.color ? props.theme.color : '#000')} !important;
`;

const SurveyInformation = ({ match }) => {
  const { id, business } = match.params;
  const history = useHistory();

  const [surverLocationDetails, setSurverLocationDetails] = useState(null);
  const [isVisibleDetail, setIsVisibleDetail] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleGoBack = () => {
    setSurverLocationDetails(null);
    history.push('/app/reports');
  };
  const mapQuestionAndResponse = (item, questions) => {
    const question = questions.find((q) => q.code === item.questionCode);

    if (!question) {
      return null;
    }

    return {
      questionCode: item.questionCode,
      answerValue: item.answerValue,
      question: question.text,
      type: question.type,
    };
  };
  const handleSurveyDetails = (result, surveysQuestionsBusiness) => {
    if (result.status !== 200 || !surveysQuestionsBusiness) {
      setIsLoading(false);
      return;
    }

    const { surveyId } = result.data;
    const survey = surveysQuestionsBusiness.data.find((item) => item.id === surveyId);

    if (!survey) {
      setIsLoading(false);
      return;
    }

    const { questions } = survey.questions;

    const questionsAndResponse = result.data.questions
      .map((item) => mapQuestionAndResponse(item, questions))
      .filter((item) => item !== null);

    const databody = {
      firstName: result.data.firstName,
      lastName: result.data.lastName,
      emailAddress: result.data.emailAddress,
      phoneNumber: result.data.phoneNumber,
      locationName: result.data.locationName,
      surveyName: result.data.surveyName,
      surveyId: result.data.surveyId,
      questions: questionsAndResponse,
      createdDate: result.data.createdDate,
    };

    setSurverLocationDetails(databody);
    setIsLoading(false);
  };
  const getDetailsById = async (analyticsId) => {
    setIsLoading(true);
    if (!business) {
      return;
    }

    try {
      const surveysQuestionsBusiness = await baseUrl.get(`/survey?where={"business":${business}}`);
      const result = await baseUrl.get(`/survey/survey-details?analyticsId=${analyticsId}`);

      handleSurveyDetails(result, surveysQuestionsBusiness);
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!surverLocationDetails) {
      setIsVisibleDetail(false);
    } else {
      setIsVisibleDetail(true);
    }
  }, [surverLocationDetails]);

  if (!id || !business) {
    history.push('/app/reports');
  }

  useEffect(() => {
    if (id && business) {
      getDetailsById(id);
    }
  }, [id, business]);

  return (
    <div>
      <GenericPopup open={isVisibleDetail} fullWidth maxWidth="xl" onClose={handleGoBack}>
        {isLoading ? (
          <StyledCircularProgress size={20} />
        ) : (
          surverLocationDetails && (
            <ResponseDetails
              textBack="Back"
              handleGoBack={handleGoBack}
              data={surverLocationDetails}
            />
          )
        )}
      </GenericPopup>
    </div>
  );
};

export default SurveyInformation;
