import React from 'react';
import Proptypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
  progressLabel: {
    color: '#fff',
    position: 'absolute',
    width: '100%',
    height: '100%',
    right: 10,
    zIndex: 1,
    top: -1,
    maxHeight: '75px', // borderlinearprogress root.height
    textAlign: 'end',
    display: 'flex',
    alignItems: 'center',
    '& span': {
      width: '100%',
    },
  },
}));

function ProgressBar(props) {
  const { value, progressBarStyle } = props;
  const classes = useStyles();
  const fontColor =
    progressBarStyle && progressBarStyle !== undefined ? progressBarStyle.fontColor : '#fff';
  return (
    <>
      <div className={classes.root}>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} style={{ position: 'relative' }}>
            <div className={classes.progressLabel} style={{ color: fontColor }}>
              <span>{`${value} reviews`}</span>
            </div>
            <LinearProgress
              variant="determinate"
              style={{ height: '22px', ...progressBarStyle }}
              value={(value / 5) * 100}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

ProgressBar.propTypes = {
  value: Proptypes.number.isRequired,
  progressBarStyle: Proptypes.objectOf(Proptypes.string).isRequired,
};

export default ProgressBar;
