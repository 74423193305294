import React from 'react';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { Cancel } from '@material-ui/icons';
import styled from 'styled-components';
import { Box, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { convertIntoPhoneFormat } from '../../utils/helper/helper';
import { format, isToday } from 'date-fns';
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon';

const StyledFailIcon = withStyles({
  root: {
    width: 24,
    height: 24,
    color: '#cc5353', // Override the color
  },
})(Cancel);

const StyledCheckIcon = withStyles({
  root: {
    width: 24,
    height: 24,
    color: '#32a753', // Override the color
  },
})(CheckCircle);

const StyledInfoIcon = withStyles({
  root: {
    width: 14,
    height: 14,
    color: '#868686', // Override the color
  },
})(AlertCircleOutlineIcon);

const StyledErrorText = styled.div`
  /* color: #cc5353; */
  font-size: 14px;
  font-weight: 400;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  align-items: center;
`;

const StyledGoodText = styled.div`
  /* color: #32a753; */
  font-size: 14px;
  font-weight: 400;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  align-items: center;
`;

const BoldText = styled.div`
  font-weight: 600;
`;

const SendResultItem = (props) => {
  const { name, phone, email, lastSent, status } = props;

  const lastSentDate = new Date(lastSent);

  const getValue = (status) => {
    if (status === 'error') {
      return (
        <>
          <StyledErrorText>
            <BoldText>{name}</BoldText>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                whiteSpace: 'nowrap',
                fontSize: '13px',
              }}
            >
              {phone ? convertIntoPhoneFormat(phone) : email}
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <BoldText
                style={{
                  color: '#cc5353',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  fontSize: '13px',
                }}
              >
                {isToday(lastSentDate) ? 'Today' : format(lastSentDate, 'M/d/yy')}
              </BoldText>
            </div>
          </StyledErrorText>
        </>
      );
    } else {
      return (
        <>
          <StyledGoodText>
            <BoldText>{name}</BoldText>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                whiteSpace: 'nowrap',
                fontSize: '13px',
              }}
            >
              {phone ? convertIntoPhoneFormat(phone) : email}
            </div>
            <div
              style={{
                color: '#6d6d6d',
                whiteSpace: 'nowrap',
                display: 'flex',
                justifyContent: 'flex-end',
                fontSize: '13px',
              }}
            >
              {isToday(lastSentDate) ? 'Today' : format(lastSentDate, 'M/d/yy')}
            </div>
          </StyledGoodText>
        </>
      );
    }
  };
  return (
    <Box display="flex" alignItems="center">
      {getValue(status)}
    </Box>
  );
};

export default SendResultItem;
