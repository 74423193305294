import { Grid, Typography, Card, CardContent } from '@material-ui/core';
import React from 'react';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

function FollowUs({ socialLinks }) {
  const twitterContent =
    socialLinks && socialLinks.twitter && socialLinks.twitter !== undefined ? (
      <a href={socialLinks.twitter}>
        <TwitterIcon style={{ color: '#000' }} />
      </a>
    ) : (
      ''
    );

  const instagramContent =
    socialLinks && socialLinks.instagram && socialLinks.instagram !== undefined ? (
      <a href={socialLinks.instagram}>
        <InstagramIcon style={{ color: '#000' }} />
      </a>
    ) : (
      ''
    );
  const facebookContnet =
    socialLinks && socialLinks.facebook && socialLinks.facebook !== undefined ? (
      <a href={socialLinks.facebook}>
        <FacebookIcon style={{ color: '#000' }} />
      </a>
    ) : (
      ''
    );

  const youtubeContent =
    socialLinks && socialLinks.youtube && socialLinks.youtube !== undefined ? (
      <a href={socialLinks.youtube}>
        <YouTubeIcon style={{ color: '#000' }} />
      </a>
    ) : (
      ''
    );
  const linkdinContent =
    socialLinks && socialLinks.linkdin && socialLinks.linkdin !== undefined ? (
      <a href={socialLinks.linkdin}>
        <LinkedInIcon style={{ color: '#000' }} />
      </a>
    ) : (
      ''
    );

  return (
    <>
      <Typography
        style={{
          color: '#000',
          fontFamily: 'inherit',
          fontWeight: '600',
        }}
      >
        Follow us
      </Typography>

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{ display: 'flex', columnGap: '1rem', marginTop: '1rem' }}
      >
        {facebookContnet}
        {twitterContent}
        {linkdinContent}
        {youtubeContent}
        {instagramContent}
      </Grid>
    </>
  );
}

export default FollowUs;
