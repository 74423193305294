import logo from './assets/images/reveo_logo@1x.png';

const theme = {
  logo,
  background_color: '#F5F5F4',
  btn_background_color: '#35EDA7',
  sideBar_background_color: '#1a1f1d',
};

export default theme;

export const lightTheme = {
  // General configurations
  fontColor: '#333', // table cell color
  fontColor2: '#333', // fontColor 2
  fontColor3: '#37423E', // font Color3
  fontColor4: '#6c6c6c', // font color4
  fontColor5: '#7e7e7e', // font color 5
  fontColor6: 'tomato', // font color 6
  fontColor7: '#8d8d8d', // font color 7
  fontColor8: '#1877F2', // font color 8
  fontColor9: ' #888888', // font Color 9
  fontColor10: '#BFBFBF', // font color 10
  fontColor11: '#fff', // font color 11
  fontColor12: '#BCBCBC', // font color 12
  mailtoColor: '#49d6a0', // mailto color
  successColor: '#00cd7f', //green success color
  failColor: '#de2626',
  // SideBar Color configuration
  tableActionBtnColor: '#58e298', // table button color
  actionBtnColor: '#35EDA7', // Action btn color
  disabledBtnColor: '#37423e', // disabledBtn Color
  disabledBtnBgColor: '#a0a0a0', // disabledBtn bg color
  sideNavLinkColor: '#FFFFFF', // side bar inactive navlink color
  sideNavLinkBgColor: 'transparent', // side bar inactive navlink bgcolor
  sideNavLinkBorderLeft: '5px solid transparent', // left border configuration of sidebar navlink
  sideNavLinkActiveColor: '#30feaf', // side bar active navlink color
  sideNavLinkActiveBgColor: '#999', // side bar active navlink bgColor
  sideNavLinkActiveBorderLeft: '5px solid #30feaf', // side bar active navlink border left
  badgebgColor: '#c92929', // Badge Background color
  badgeColor: '#fff', // badge color
  sideBarBgColor: '#171e1b', // Side bar bgColor
  avatarBorder: '3px solid #f5f5f5', // sidebar avatar border color
  avtarBgColor: '#ccc', // sidebar avatar background color
  notificationIconColor: '#FFFFFF', // sidebar notification icon color
  // main body color configuration
  pageBackgroundColor: '#f5f5f5', // background color
  pageTitleColor: '#6C6C6C', // Page Title color
  activeConversationBorder: '5px solid #35EDA7', // Active user conversation border
  inActiveConversationBorder: '5px solid transparent', // InActive user conversation border
  conversationDotColor: '#1877f2', // Conversation dot color
  conversationToggleActiveBtnBgColor: '#DEDEDE', // Conversation toggle Active Btn Theme
  conversationToggleActiveBtnColor: '#888888', // Conversation toggle Active Btn Theme
  conversationToggleInActiveBtnBgColor: '#fff', // Conversation toggle in-active btn theme
  conversationToggleInActiveBtnColor: '#888888', // Conversation toggle in-active btn theme
  conversationChatHeaderBtnTextColor: '#333', // Conversation chat header btn color
  conversationChatHeaderIconColor: '#6c6c6c', // Conversation chat header icon color
  timeLapsedColor1: '#656565', // Time lapsed component color
  tabColor: '#6C6C6C', // Tab  font color
  selectedTabColor: '#000', // selected tab font color
  focusedTabColor: '#6C6C6C', // focused tab font color
  tabIndicator: '#35EDA7', // Tab indicator color
  tabScrollBtn: '#333', // Tab scroll button
  popoverBgColor: '#f5f5f5', // Popover bg color
  popupBgColor: '#f5f5f5', // Popup bg Color
  inputLabelColor: '#333', // general input field label color,
  inputLabelColorOnFocus: '#e3e3e3', // general input field label color on focus
  inputBorderBottomBefore: '#333', // general input field border bottom color ::before
  inputBorderBottomAfter: '#35EDA7', // general input field border bottom color ::after
  inputOnFocusBefore: '#35EDA7', // general input field border bottom on focus ::before
  inputTextColor: '#333', // input field text color
  tableBackgroundColor: '#f5f5f5', // Table Background color
  tableCheckBoxColor: '#35EDA7', // Table row check checkbox color
  tableHeaderBackgroundColor: '#f5f5f5', // Table Header Background color
  tableHeaderBorderRight: '1px solid #888', // Table header border right color
  tableOddRowColor: '#FAFAFA', // Table odd row color
  tableEvenRowColor: '#F5F5F5', // Table even row color
  tableHeaderColor: '#888', // Table header cell color
  tableRowCellColor: '#37423e', // Table Row Cell color
  tableRowOnHoverColor: '#e7e7e7', // Table Row on hover color (:hover)
  tablePaginationColor: '#333', // Table pagination color
  tablePaginationDisabledBtnColor: '#ccc', // Table pagination disabled btn color
  tableSearchBoxColor: '#333', // Table search box component theme
  editableTableBorderBottom: '1px solid #ccc', // Editable table border bottom for eah row
  tableCellBackground: '#f5f5f5', // Editable Table table cell bg color
  tableEditBorderRight: '1px solid #888', // Editable Table header cell border right
  tableCellColor: '#888', // Editable Table cell color
  contactInfoColor: 'rgba(0, 0, 0, 0.87)', // Editable table contact info color
  popupTitleColor: '#6c6c6c', // Popup title Text color
  popupTitleFontSize: '24px', // Popup text font size
  popupTitleFontWeight: '400', // popup title text font weight
  popupLetterSpacing: '1.08px', // Popup Letter spacing
  popupTitleFontFamily: 'Montserrat', // Popup title font family
  mobileColor: '#000', // Mobile frame color
  laptopColor: '#000', // laptop frame color
  laptopBgColor: '#fff', // laptop frame bg color
  cardBgColor: '#fff', // Card Bg color
  cardTitleColor: '#37423e', // Card title color
  cardDescriptionColor: '#939393', // Card description color
  cardIconColor: '#6c6c6c', // Card Icon color
  addCircleIcon: '#a7a7a7', // Add Circle icon theme
  postAddIcon: '#a7a7a7', // Add post icon theme
  blockIcon: '#c92929', // Block icon them
  checkCircleIcon: '#32a753', // Checked circle icon theme
  clearIcon: '', // Clear icon theme
  doneIcon: '#58e298', // Done icon theme
  editIcon: '#58e298', // Edit icon theme
  groupAddIcon: '#333', // Group Add icon theme
  helpIcon: '#c1c1c1', // Help icon theme
  laptopIcon: '#6C6C6C', // Laptop icon theme
  locationOnIcon: '#333', // Location on icon theme
  lockIcon: '#C73626', // Lock icon theme
  mobileIconTheme: '#6C6C6C', // Mobile icon theme
  moreHorizIcon: '#35EDA7', // More Horizontal icon theme
  nightsStayIcon: '#fff', // Night stay icon theme
  notificationsOffIcon: '#333', // Notification off icon theme
  personAddIcon: '#333', // Person add icon theme
  reportProblemIcon: '#c92929', // Report problem icon theme
  sendIcon: '', // Send icon theme
  splitIcon: '#a7a7a7', // Split icon theme
  swapHorizIcon: '', // Swap horiz icon theme
  thumbsDownIcon: '#c92929', // Thumbs down icon theme
  thumbsUpIconTheme: '#3fb51c', // Thumbs up icon theme
  trashIconTheme: '#c92929', // Trash icon theme
  tuneIcon: '', // Tune icon theme
  wbSunnyIcon: '#f2f200', // Wb sunny Icon
  emoticon: '#3fb51c', // Emoticon icon theme
  emoticonNutral: '#feb359', // Emoticon nutral icon theme
  emoticonSad: '#f44235', // Emoticon sad icon theme
  dataUsageIcon: 'tomato', // Data usage icon
  attachFileIcon: '#a7a7a7', // Attach file icon
  filterNoneIcon: '#a7a7a7', // Filter none icon
  conversationSendIcon: '#a7a7a7', // Send Icon  in conversation
  conversationSendAndCloseIcon: 'tomato', // Send and close icon in conversation
  conversationCheckIcon: 'tomato', // Conversation check icon
  recieverMessageBoxColor: '#2EFFAF', // Reciever message box color
  senderMesasgeBox: '#fff', // Senders message box color
  reviewRequestBox: 'rgb(46 255 175 / 0.2)', // Review request box
  attachmentBox: '#2EFFAF', // Attachment Box
  headingTextColor: '#727272', // Heading text color
  descriptionTextColor: '#727272', // Description text color
  impTextColor: '#6c6c6c', // Important text color
  descriptionText2: '#9d9d9d', // Description text color in onboarding business category selection
  dropDownBackGroundColor: '#fff', // Drop down menu props bg color
  dropdownColor: '#37423E', // Drop down menu props color
  radioColor: '#37423E', // Radio Color
  checkboxColor: '#35EDA7', // Checkbox color
  switchColor: '#35EDA7', // Switch color
  switchTrackColor: '#6C6C6C', // switch track color
  switchCheckColor: '#fff', // switch check color
  mobileTextContainerBG: '#e8e8e8', // Text container bg color
  disabledButtonBGTransparent: 'transparent', // Disabled button bg color transparent
};

export const darkTheme = {
  // General configurations
  fontColor: '#fff', // table cell color
  fontColor2: '#fff', // fontColor 2
  fontColor3: '#fff', // fontColor3
  fontColor4: '#fff', // font color4
  fontColor5: '#fff', // font color 5
  fontColor6: 'tomato', // font color 6
  fontColor7: '#fff', // font color 7
  fontColor8: '#fff', // font color 8
  fontColor9: ' #fff', // font Color 9
  fontColor10: '#fff', // font color 10
  fontColor11: '#fff', // font color 11
  fontColor12: '#BCBCBC', // font color 12

  mailtoColor: '#49d6a0', // mailto color
  // SideBar Color configuration
  tableActionBtnColor: '#58e298', // table button color
  actionBtnColor: '#35EDA7', // Action btn color
  disabledBtnColor: '#37423e', // disabledBtn Color
  disabledBtnBgColor: '#a0a0a0', // disabledBtn bg color
  sideNavLinkColor: '#fff', // side bar inactive navlink color
  sideNavLinkBgColor: 'transparent', // side bar inactive navlink bgcolor
  sideNavLinkBorderLeft: '5px solid transparent', // left border configuration of sidebar navlink
  sideNavLinkActiveColor: '#30feaf', // side bar active navlink color
  sideNavLinkActiveBgColor: 'transparent', // side bar active navlink bgColor
  sideNavLinkActiveBorderLeft: '5px solid #30feaf', // side bar active navlink border left
  badgebgColor: '#c92929', // Badge Background color
  badgeColor: '#fff', // badge color
  sideBarBgColor: '#2a2a2a', // Side bar bgColor
  avatarBorder: '3px solid #2a2a2a', // sidebar avatar border color
  avtarBgColor: '#2a2a2a', // sidebar avatar background color
  notificationIconColor: '#fff', // sidebar notification icon color
  // main body color configuration
  pageBackgroundColor: '#2a2a2a', // background color
  pageTitleColor: '#fff', // Page Title color
  activeConversationBorder: '5px solid red', // Active user conversation border
  inActiveConversationBorder: '5px solid transparent', // InActive user conversation border
  conversationDotColor: '#1877f2', // Conversation dot color
  conversationToggleActiveBtnBgColor: '#DEDEDE', // Conversation toggle Active Btn Theme
  conversationToggleActiveBtnColor: '#888888', // Conversation toggle Active Btn Theme
  conversationToggleInActiveBtnBgColor: '#fff', // Conversation toggle in-active btn theme
  conversationToggleInActiveBtnColor: '#888888', // Conversation toggle in-active btn theme
  conversationChatHeaderBtnTextColor: '#fff', // Conversation chat header btn color
  conversationChatHeaderIconColor: '#fff', // Conversation chat header icon color
  timeLapsedColor1: '#939393', // Time lapsed component color
  tabColor: '#fff', // Tab  font color
  selectedTabColor: '#fff', // selected tab font color
  focusedTabColor: '#fff', // focused tab font color
  tabIndicator: '#fff', // Tab indicator color
  tabScrollBtn: '#fff', // Tab scroll button
  popoverBgColor: '#2a2a2a', // Popover bg color
  popupBgColor: '#2a2a2a', // Popup bg Color
  inputLabelColor: '#fff', // general input field label color,
  inputLabelColorOnFocus: '#35EDA7', // general input field label color on focus
  inputBorderBottomBefore: '#fff', // general input field border bottom color ::before
  inputBorderBottomAfter: '#35EDA7', // general input field border bottom color ::after
  inputOnFocusBefore: '#fff', // general input field border bottom on focus ::before
  inputTextColor: '#fff', // input field text color
  tableBackgroundColor: '#2a2a2a', // Table Background color
  tableCheckBoxColor: '#35EDA7', // Table row check checkbox color
  tableHeaderBackgroundColor: '#2a2a2a', // Table Header Background color
  tableHeaderBorderRight: '1px solid #fff', // Table header border right color
  tableOddRowColor: '#585858', // Table odd row color
  tableEvenRowColor: '#303031', // Table even row color
  tableHeaderColor: '#fff', // Table header cell color
  tableRowCellColor: '#fff', // Table Row Cell color
  tableRowOnHoverColor: '#04040e', // Table Row on hover color (:hover)
  tablePaginationColor: '#fff', // Table pagination color
  tablePaginationDisabledBtnColor: '#ccc', // Table pagination disabled btn color
  tableSearchBoxColor: '#fff', // Table search box component theme
  editableTableBorderBottom: '1px solid #fff', // Editable table border bottom for eah row
  tableCellBackground: '#2a2a2a', // Editable Table table cell bg color
  tableEditBorderRight: '1px solid #fff', // Editable Table header cell border right
  tableCellColor: '#fff', // Editable Table cell color
  contactInfoColor: 'rgba(255, 255, 255, 0.87)', // Editable table contact info color
  popupTitleColor: '#fff', // Popup title Text color
  popupTitleFontSize: '24px', // Popup text font size
  popupTitleFontWeight: '400', // popup title text font weight
  popupLetterSpacing: '1.08px', // Popup Letter spacing
  popupTitleFontFamily: 'Montserrat', // Popup title font family
  mobileColor: '#fff', // Mobile frame color
  laptopColor: '#fff', // laptop frame color
  laptopBgColor: '#000', // laptop frame bg color
  cardBgColor: '#303031', // Card Bg color
  cardTitleColor: '#fff', // Card title color
  cardDescriptionColor: '#fff', // Card description color
  cardIconColor: '#fff', // Card Icon color
  addCircleIcon: '#a7a7a7', // Add Circle icon theme
  postAddIcon: '#a7a7a7', // Add post icon theme
  blockIcon: '#c92929', // Block icon them
  checkCircleIcon: '#32a753', // Checked circle icon theme
  clearIcon: '', // Clear icon theme
  doneIcon: '#58e298', // Done icon theme
  editIcon: '#58e298', // Edit icon theme
  groupAddIcon: '#fff', // Group Add icon theme
  helpIcon: '#fff', // Help icon theme
  laptopIcon: '#fff', // Laptop icon theme
  locationOnIcon: '#fff', // Location on icon theme
  lockIcon: '#C73626', // Lock icon theme
  mobileIconTheme: '#fff', // Mobile icon theme
  moreHorizIcon: '#35EDA7', // More Horizontal icon theme
  nightsStayIcon: '#fff', // Night stay icon theme
  notificationsOffIcon: '#fff', // Notification off icon theme
  personAddIcon: '#fff', // Person add icon theme
  reportProblemIcon: '#fff', // Report problem icon theme
  sendIcon: '', // Send icon theme
  splitIcon: '#a7a7a7', // Split icon theme
  swapHorizIcon: '#fff', // Swap horiz icon theme
  thumbsDownIcon: '#c92929', // Thumbs down icon theme
  thumbsUpIconTheme: '#3fb51c', // Thumbs up icon theme
  trashIconTheme: '#c92929', // Trash icon theme
  tuneIcon: '', // Tune icon theme
  wbSunnyIcon: '#f2f200', // Wb sunny Icon
  emoticon: '', // Emoticon icon theme
  emoticonNutral: '', // Emoticon nutral icon theme
  emoticonSad: '', // Emoticon sad icon theme
  dataUsageIcon: 'tomato', // Data usage icon
  attachFileIcon: '#fff', // Attach file icon
  filterNoneIcon: '#fff', // Filter none icon
  conversationSendIcon: '#fff', // Send Icon  in conversation
  conversationSendAndCloseIcon: 'tomato', // Send and close icon in conversation
  conversationCheckIcon: 'tomato', // Conversation check icon
  recieverMessageBoxColor: '#303031', // Reciever message box color
  senderMesasgeBox: 'rgb(98 98 100 / 0.7)', // Senders message box color
  reviewRequestBox: 'rgb(48 48 49 / 0.2)', // Review request box
  attachmentBox: 'rgb(48 48 49 / 0.2)', // Attachment Box
  headingTextColor: '#fff', // Heading text color
  descriptionTextColor: '#fff', // Description text color
  impTextColor: '#fff', // Important text color
  descriptionText2: '#fff', // Description text color in onboarding business category selection
  dropDownBackGroundColor: '#303031', // Drop down menu props bg color
  dropdownColor: '#fff', // Drop down menu props color
  radioColor: '#35EDA7', // Radio Color
  checkboxColor: '#35EDA7', // Checkbox color
  switchColor: '#35EDA7', // Switch color
  switchTrackColor: '#fff', // switch track color
  switchCheckColor: '#6c6c6c', // switch check color
  mobileTextContainerBG: '#303031', // Text container bg color
  disabledButtonBGTransparent: 'transparent', // Disabled button bg color transparent
};
