import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import TagOutlineIcon from 'mdi-react/TagOutlineIcon';

function AboutCard({ about, category }) {
  return (
    <Grid container style={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}>
      <Typography style={{ color: '#070100', fontFamily: 'inherit', fontWeight: '600' }}>
        About this business
      </Typography>
      {/* <Grid container style={{ alignItems: 'center', columnGap: '10px' }}>
        <TagOutlineIcon style={{ fill: '#939393' }} size="1.2rem" />
        <Typography style={{ fontFamily: 'inherit', color: '#939393', fontWeight: 'normal' }}>
          {category}
        </Typography>
      </Grid> */}
      <Grid
        item
        style={{
          wordBreak: 'break-word',
          lineHeight: '23px',
          fontWeight: 'normal',
          fontSize: '15px',
        }}
      >
        {about}
      </Grid>
    </Grid>
  );
}

export default AboutCard;
