import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import ThankYouCard from '../components/ReviewRequestPages/ThankYouCard';
import Loader from '../components/Loader/Loader';
import FirstCard from '../components/SurveyReview/FirstCard';
import NotificationReviewData from '../utils/api/DummyNotification';
import SingleActionCard from '../components/NotificationReviewPages/SingleAction';
import NoReplyCard from '../components/NotificationReviewPages/NoReplyCard';

const StyledPNFGrid = styled(Grid)`
  height: 100%;
  font-size: 36px;
  color: #37423e;
  font-weight: 200;
  letter-spacing: 1.08px;
  background: #fff;
  overflow: scroll;
`;

function NotificationReview() {
  const [thankYouCard, setThankYouCard] = useState({});
  const [notificationReviewData, setNotificationReview] = useState({});
  const [textSurvey, setTextSurvey] = useState({});
  const [singleAction, setSingleAction] = useState({});
  const [showSingleAction, setShowSingleAction] = useState(false);
  const [noReplyData, setNoReplyData] = useState({});
  const [showNoReplyCard, setShowNoReplyCard] = useState(false);
  const [firstCard, setFirstCard] = useState(false);
  const [showThankyou, setshowThankyou] = useState(false);
  const [renderView, setRenderView] = useState(false);

  useEffect(() => {
    setRenderView(true);
    const type = NotificationReviewData.name;
    const data = NotificationReviewData;
    setNotificationReview(NotificationReviewData);
    // eslint-disable-next-line array-callback-return
    data[type].screens.map((item) => {
      if (item.screen === 'confirmation') setTextSurvey(item);
      if (item.screen === 'thankyou') setThankYouCard(item);
      if (item.screen === 'no-reply') setNoReplyData(item);
      if (item.screen === 'opt-in-or-out') setTextSurvey(item);
      if (item.screen === 'single-action') setSingleAction(item);
    });
    if (type === 'singleAction') {
      setShowSingleAction(true);
    } else if (type === 'confirmation' || type === 'optInOrOut') {
      setFirstCard(true);
    } else if (type === 'noReply') {
      setShowNoReplyCard(true);
    }
  }, []);

  const onYesNoClick = (type) => {
    if (type === 'yes' || type === 'no') {
      setshowThankyou(true);
      setFirstCard(false);
    }
  };

  const confimationInput = () => {
    setshowThankyou(true);
    setShowSingleAction(false);
  };

  return renderView ? (
    <>
      <StyledPNFGrid container justify="center">
        <Grid xs={12} md={4} lg={3} sm={5}>
          {firstCard ? (
            <FirstCard
              textSurvey={textSurvey}
              onYesNoClick={onYesNoClick}
              notificationType={notificationReviewData.name}
            />
          ) : (
            ''
          )}

          {showSingleAction ? (
            <SingleActionCard
              notificationReviewData={notificationReviewData}
              singleActionData={singleAction}
              onYesClick={confimationInput}
            />
          ) : (
            ''
          )}
          {showNoReplyCard ? <NoReplyCard noReplyData={noReplyData} /> : ''}

          {showThankyou ? <ThankYouCard thankyouCard={thankYouCard} /> : ''}
        </Grid>
      </StyledPNFGrid>
    </>
  ) : (
    <Loader />
  );
}

NotificationReview.propTypes = {};
export default NotificationReview;
