import React from 'react';
import { ThemeProvider } from 'styled-components';
import CustomButton from '../components/CustomButton/CustomButton';
import TransperentButtonTheme from '../themes/Button/TransperentButtonTheme';
import StyledTrashIcon from '../components/Icons/TrashIcon';
import StyledTableCell from '../components/TableRowCell';
import StyledEditIcon from '../components/Icons/EditIcon';

const getSeoColumns = (handleEdit, isSuperAdmin, handleDelete, iconsTheme) => [
  {
    name: 'name',
    label: 'Seocategory',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => {
        return <StyledTableCell>{value}</StyledTableCell>;
      },
    },
  },
  {
    name: 'status',
    label: 'STATUS',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => {
        return <StyledTableCell>{value}</StyledTableCell>;
      },
    },
  },

  {
    name: '',
    label: 'Action',
    options: {
      filter: false,
      sort: false,
      searchable: false,
      customBodyRenderLite: (dataIndex, rowIndex) => {
        return isSuperAdmin ? (
          <div style={{ display: 'flex' }}>
            <ThemeProvider theme={{ ...TransperentButtonTheme, color: iconsTheme.editIcon }}>
              <CustomButton onClick={() => handleEdit(dataIndex, rowIndex)} title="Edit">
                <StyledEditIcon />
              </CustomButton>
            </ThemeProvider>
            <ThemeProvider theme={{ ...TransperentButtonTheme, color: iconsTheme.trashIconTheme }}>
              <CustomButton onClick={() => handleDelete(dataIndex, rowIndex)} title="Delete">
                <StyledTrashIcon />
              </CustomButton>
            </ThemeProvider>
          </div>
        ) : null;
      },
    },
  },
];

export default getSeoColumns;
