import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { ThemeProvider } from 'styled-components';
import loginLeftSideImage from '../../assets/images/right_login_background.jpg';
import Logo from '../../assets/images/Reveo_Logo_New.svg';
import GeneralInputField from '../../components/GeneralInputField/GeneralInputField';
import baseUrlLogin from '../../utils/services/auth.config';
import useShowPassword from '../../state/ShowPassword';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import ButtonLoader from '../../components/ButtonLoader/ButtonLoader';
import CustomButton from '../../components/CustomButton/CustomButton';
import { setAuthToken } from '../../utils/helper/helper';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    height: '100%',
    width: '100%',
  },

  loginRightSection: {
    backgroundImage: `url(${loginLeftSideImage})`,
    height: '100%',
    backgroundSize: 'cover',
    display: 'flex',
    padding: '0 2rem 0 5rem',
    fontFamily: 'Montserrat',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  headerText: {
    color: '#fff',
    fontWeight: 700,
    fontSize: '50px',
    textTransform: 'uppercase',
    lineHeight: 1,
    marginBottom: '10px',
  },
  benefitList: {
    color: '#fff',
    width: '80%',
    lineHeight: 2.5,
  },
  benefitListItem: {
    lineHeight: 1.5,
    marginBottom: '20px',
    '&::marker': {
      color: '#30feaf',
    },
  },
  subtitleText: {
    fontWeight: 700,
    fontSize: '18px',
    color: '#30feaf',
    marginBottom: '20px',
  },
  loginLeftSection: {
    backgroundColor: '#f5f5f5',
    height: '100vh',
    position: 'relative',
  },
  forgotPassword: {
    fontSize: '14px',
    fontWeight: 700,
    color: '#58e298',
    cursor: 'pointer',
    marginTop: '25px',
  },
  loginForm: {
    top: '50%',
    position: 'absolute',
    transform: 'translateY(-50%)',
    textAlign: 'center',
  },
  form: {
    width: '50%',
  },
  logo: {
    width: '200px',
    marginBottom: '20px',
  },
  items: {
    padding: '10px',
  },
}));

const buttonTheme = {
  boxShadow: '5px 2px 14px -3px',
};

const forgetPasswordTheme = {
  boxShadow: '5px 2px 14px -3px',
  border: '1px solid #333',
  background: 'transparent',
};

const inputFieldTheme = {
  inputMargin: '.25em',
};

export default function UserLogin() {
  const classes = useStyles();
  const history = useHistory();
  const { state, dispatch } = useContext(GlobalContext);
  const [FLAG_ERROR, setError] = useState(false);
  const [loader, setLoader] = useState(false);
  const { register, handleSubmit, errors } = useForm({ emailAddress: '', password: '' });
  const { handlePasswordViewToggle, passwordInfo } = useShowPassword({
    password: false,
  });
  const { businesses, locations } = state;
  const redirectToForgetPassword = () => {
    history.push('/password-recovery');
  };

  const onSubmit = (data) => {
    setLoader(true);
    if (data) {
      baseUrlLogin
        .post('/account/login', { ...data })
        .then((res) => {
          if (res.status === 200) {
            setLoader(false);
            setAuthToken(res.data.jwt);
            dispatch({ type: 'SET_TOKEN', payload: res.data.jwt });
            dispatch({ type: 'SET_USER_INFO', payload: res.data });
            localStorage.setItem('AUTH_TOKEN', res.data.jwt);
            localStorage.setItem('USER_ID', res.data.id);
            localStorage.setItem('USER_INFO', JSON.stringify(res.data));
            // const preferredBusiness =
            //   res.data.contact.preferredBusiness && res.data.contact.preferredBusiness;
            // const preferredLocation =
            //   res.data.contact.preferredLocation && res.data.contact.preferredLocation;

            // if (preferredBusiness) {
            //   dispatch({ type: 'SET_BUSINESS', payload: preferredBusiness });
            //   localStorage.setItem('SELECTED_BUSINESS', preferredBusiness);
            // }
            // if (preferredLocation) {
            //   dispatch({ type: 'SET_LOCATION', payload: preferredLocation });
            //   localStorage.setItem('SELECTED_LOCATION', preferredLocation);
            //}
            history.push('/');
          }
        })
        .catch(() => {
          setLoader(false);
          setError(true);
        });
    }
  };

  useEffect(() => {}, [state]);

  let userNameHelpText = '';
  let passwordHelpText = '';
  if (FLAG_ERROR) {
    passwordHelpText = 'Please Enter valid username and password';
    userNameHelpText = 'Please Enter valid username and password';
  } else {
    userNameHelpText = errors.emailAddress ? 'Username is a required field' : '';
    passwordHelpText = errors.password ? 'Password is a required field' : '';
  }

  const showLoader = loader ? <ButtonLoader /> : null;

  return (
    <ThemeProvider theme={inputFieldTheme}>
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6}>
            <div className={classes.loginLeftSection}>
              <Grid
                className={classes.loginForm}
                item
                xs={12}
                sm={12}
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item className={classes.items}>
                  <img src={Logo} className={classes.logo} alt="logo" />
                </Grid>
                {/* <Grid item className={classes.items}>
                  <Typography variant="subtitle1" gutterBottom>
                    Welcome back! Please login to your account.
                  </Typography>
                </Grid> */}
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className={classes.form}>
                  <Grid item className={classes.items}>
                    <GeneralInputField
                      name="emailAddress"
                      type="email"
                      disabled={loader}
                      error={!!errors.emailAddress || FLAG_ERROR}
                      label="Username"
                      inputRef={register({ required: true })}
                      helperText={userNameHelpText}
                    />
                  </Grid>
                  <Grid item className={classes.items}>
                    <GeneralInputField
                      name="password"
                      disabled={loader}
                      type={passwordInfo.password ? 'text' : 'password'}
                      error={!!errors.password || FLAG_ERROR}
                      label="Password"
                      inputRef={register({ required: true })}
                      helperText={passwordHelpText}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handlePasswordViewToggle('password')}
                            >
                              {passwordInfo.password ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} sm={12}>
                    <Grid container>
                      <Grid item xs={12} md={12} lg={12} sm={12}>
                        <ThemeProvider theme={buttonTheme}>
                          <CustomButton
                            style={{ width: '90%', boxShadow: 'none !imporant' }}
                            type="submit"
                            disabled={loader}
                          >
                            Login
                            {showLoader}
                          </CustomButton>
                        </ThemeProvider>
                      </Grid>
                    </Grid>
                    <div
                      className={classes.forgotPassword}
                      onClick={redirectToForgetPassword}
                      disabled={loader}
                    >
                      Forgot password
                    </div>
                  </Grid>
                </form>
                {/* <Grid item>
                  <span>Don&apos;t have an account ? </span>
                  <Link to="/register">Create account</Link>
                </Grid> */}
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.loginRightSection}>
              <span className={classes.headerText}>Refer a business, Reward yourself.</span>
              <span className={classes.subtitleText}>
                Refer a business to Reveo and get one month FREE
              </span>
              <ul className={classes.benefitList}>
                <li className={classes.benefitListItem}>
                  Refer a colleague, friend, or anyone else in your network to Reveo.
                </li>
                <li className={classes.benefitListItem}>
                  Once your referral signs up as a customer, you'll receive the following month{' '}
                  <strong>FREE</strong>
                </li>
                <li className={classes.benefitListItem}>
                  Plus, there's no limit to the number of referrals you can earn, so keep the
                  referrals coming!
                </li>
              </ul>
              <CustomButton onClick={() => window.open('https://reveo.com/refer/')}>
                Start Now
              </CustomButton>
            </div>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
}
