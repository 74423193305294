import React from 'react';
import { Grid } from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import Proptypes from 'prop-types';
import MobilePreview from '../MobilePreview/MobilePreview';
import PoweredBy from '../PoweredBy/PoweredBy';
import EditableText from '../Editable/EditableText';
import EditableImage from '../Editable/EditableImage';
import StyledTextField from '../GeneralInputField/GeneralInputField';
import Emoticon from '../Icons/Emoticon';
import EmoticonNutral from '../Icons/EmoticonNutral';
import EmoticonSad from '../Icons/EmoticonSad';

const StyledMobileViewContainer = styled(Grid)`
  position: absolute;
  height: 92%;
  margin: 0 !important;
  top: 40px;
  padding: 0 40px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const StyledContentContainer = styled(Grid)`
  min-height: calc(100% - 50px);
`;

const textFieldTheme = {
  inputMargin: '0',
};

function SentimentSurvey(props) {
  const { poweredBy, allowTextFeedback, url } = props;

  const textFeedbackContainer = allowTextFeedback ? (
    <Grid item xs={12} sm={12} lg={12} md={12}>
      <EditableText {...props} id="textFeedbackTitle" />
      <ThemeProvider theme={textFieldTheme}>
        <StyledTextField multiline rowsMax={4} rows={4} />
      </ThemeProvider>
    </Grid>
  ) : null;

  const imageContainer = url ? <EditableImage {...props} isEditable={false} /> : null;

  return (
    <MobilePreview>
      <StyledMobileViewContainer container direction="row" justify="center" alignItems="center">
        <StyledContentContainer item xs={12} sm={12} lg={12} md={12}>
          <Grid container style={{ textAlign: 'center' }}>
            <Grid item xs={12} sm={12} lg={12} md={12}>
              {imageContainer}
            </Grid>
            <EditableText {...props} />
          </Grid>
          <Grid item xs={12} sm={12} lg={12} md={12}>
            <Grid container justify="space-evenly" style={{ margin: '1em 0' }}>
              <Grid item>
                <EmoticonSad />
              </Grid>
              <Grid item>
                <EmoticonNutral />
              </Grid>
              <Grid item>
                <Emoticon />
              </Grid>
            </Grid>
          </Grid>
          {textFeedbackContainer}
        </StyledContentContainer>
        <PoweredBy isPoweredBy={poweredBy} />
      </StyledMobileViewContainer>
    </MobilePreview>
  );
}

SentimentSurvey.propTypes = {
  title: Proptypes.string,
  poweredBy: Proptypes.bool,
  allowTextFeedback: Proptypes.bool.isRequired,
  url: Proptypes.string,
};

SentimentSurvey.defaultProps = {
  title: 'Thank you for your time, we look forward to serving you in the future',
  poweredBy: true,
  url: '',
};

export default SentimentSurvey;
