import React, { useContext } from 'react';
import Proptypes from 'prop-types';
import { Card, CardContent, CardActions, Grid, Box, Button, IconButton } from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import CusotmAvatar from '../CustomAvatar/CustomAvatar';
import CustomRating from '../CusotmRating/CusotmRating';
import StyledReportProblemIcon from '../Icons/ReportProblemIcon';
import StyledCheckCircle from '../Icons/CheckedCircle';
import useIconTheme from '../../hooks/useIconTheme';
import useCardTheme from '../../hooks/useCardTheme';
import useTextColors from '../../hooks/useTextColors';
import CustomSwitch from '../../components/CustomSwitch/CustomSwitch';
import StatusIcon from '@material-ui/icons/FiberManualRecord';
import MoreIcon from '@material-ui/icons/MoreVert';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import ConnectionsMorePopover from '../Popover/ConnectionsMorePopover/ConnectionsMorePopover';
import usePopover from '../../hooks/usePopover';

const StyledCardContent = styled(CardContent)`
  min-height: 40px;
  padding: 0.8rem !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledCardActions = styled(CardActions)`
  padding: 0 !important;
  display: flex;
  align-items: center;
`;

const StyledBgContainer = styled.div`
  // min-height: 100px;
  // background: ${(props) => props.theme.bgColor};
  position: relative;
  // @media screen and (min-width: 1600px) {
  //   & {
  //     min-height: 120px;
  //   }
  // }
`;

const StyledLogoText = styled.div`
  //margin: 1.6em 0 0 0;
  //text-align: center;
  color: ${(props) => props.theme.color};
  //letter-spacing: 0.28px;
  font-weight: 600;
  font-size: 1rem;
  font-family: Montserrat;
  margin-left: 0.8rem;

  // @media screen and (min-width: 1920px) {
  //   & {
  //     margin: 1.5em 0 0 0;
  //   }
  // }
`;

const StyledReviewContainer = styled(Grid)`
  margin: 1em 0 !important;
`;

const StyledReviewsText = styled(Grid)`
  color: ${(props) => props.theme.color} !important;
  letter-spacing: 0.28px;
  font-weight: 400;
  font-size: 12px;
  font-family: Montserrat;
  display: flex;
  align-item: center;
  justify-content: center;
  & > span {
    margin: 0 0.25em;
  }
`;

const StyledRatingText = styled(Grid)`
  color: ${(props) => props.theme.color} !important;
  letter-spacing: 0.28px;
  font-weight: 600;
  font-size: 16px;
  font-family: Montserrat;
  margin: 0 0.25em !important;
`;

const StyledActionNeededText = styled(Grid)`
  color: #c92929;
  letter-spacing: 0.28px;
  font-weight: 400;
  font-size: 12px;
  font-family: Montserrat;
  & > div > span {
    margin: 0 0.25em;
  }
`;

const StyledSyncedText = styled(Grid)`
  color: #32a753;
  letter-spacing: 0.28px;
  font-weight: 400;
  font-size: 12px;
  font-family: Montserrat;
  & > div > span {
    margin: 0 0.25em;
  }
`;

const StyledStatusText = styled.div`
  font-size: 0.8rem;
  color: #909090;
  margin-top: 0.3rem;
`;

const StatusConnectedIcon = styled(StatusIcon)`
  width: 0.8rem !important;
  height: 0.8rem !important;
  margin: 0.3rem 0.3rem 0 0.8rem;
  color: #00cd7f;
`;

const StatusUnconnectedIcon = styled(StatusIcon)`
  width: 0.8rem !important;
  height: 0.8rem !important;
  margin: 0.3rem 0.3rem 0 0.8rem;
  color: #d9d9d9;
`;

const avatarTheme = {
  margin: '10rem auto',
  position: 'absolute',
  top: '30px',
  bottom: '0',
  left: '0',
  right: '0',
  border: '4px solid #fff',
  width: '50px',
  height: '50px',
  lgWidth: '100px',
  lgHeight: '100px',
  objectFit: 'cover',
  bgColor: '#FFF',
};

const PrimaryButton = styled(Button)`
  background-color: #30feaf !important;
  margin-left: 10px !important;
  text-transform: capitalize !important;
  letter-spacing: unset !important;
  font-weight: 600 !important;
`;

const StyledOutlineButton = styled(Button)`
  text-transform: capitalize !important;
  letter-spacing: unset !important;
  font-weight: 600 !important;
`;

const StyledListingButton = styled(Button)`
  text-transform: capitalize !important;
  letter-spacing: unset !important;
  font-weight: 600 !important;
  margin-right: 1rem !important;
`;

const StyledMoreIcon = styled(MoreIcon)`
  color: ${(props) => props.theme.color}!important;
`;
function AuthCard(props) {
  const {
    backgroundColor,
    name,
    uploadedFile,
    averageReviews,
    totalReviews,
    handleListingClick,
    handleViewPostClick,
    handleFetchExternalReviews,
    btnColWidth,
    isShowReviewInfo,
    metaIsDeleted,
    handleActivation,
    showConnect,
    handleConnect,
    switchActionTitle,
    showListingLink,
    showSwitch,
    connectionBtnTitle,
    showActionNeeded,
    showSyncText,
    isSynced,
    errorText,
    syncedText,
    isConnected,
    handleDisconnect,
    isAuthRequired,
    handleOtherSiteDisconnect,
  } = props;
  const { cardTheme } = useCardTheme();
  const { iconsTheme } = useIconTheme();
  const { textColors } = useTextColors();
  const { state } = useContext(GlobalContext);
  const { themeContainer, userRole } = state;

  const canEdit = userRole.id < 3;

  const connectionsMorePopover = usePopover();

  const authConnectBtn =
    showConnect && connectionBtnTitle !== 'Disconnect' ? (
      <Box>
        <PrimaryButton
          title="connect"
          variant="contained"
          onClick={() => handleConnect(!metaIsDeleted, { ...props })}
        >
          Connect
        </PrimaryButton>
      </Box>
    ) : (
      <Box display="flex">
        <StyledListingButton
          title="assign"
          variant="outlined"
          onClick={() => handleListingClick({ ...props })}
        >
          View Listing
        </StyledListingButton>
        {canEdit && (
          <StyledOutlineButton
            title="disconnect"
            variant="outlined"
            onClick={() => handleDisconnect({ ...props })}
          >
            Disconnect
          </StyledOutlineButton>
        )}
      </Box>
    );

  const connectBtn =
    showConnect && connectionBtnTitle && canEdit !== 'Deactivate' ? (
      <Box flexDirection={'row'} alignItems="center" justifyContent={'center'} flexGrow="1">
        <PrimaryButton
          title="connect"
          variant="contained"
          onClick={(e) => handleConnect(e, { ...props })}
        >
          Connect
        </PrimaryButton>
      </Box>
    ) : (
      <Box>
        <CustomSwitch
          checked={connectionBtnTitle !== 'Activate'}
          onChange={() => handleOtherSiteDisconnect(!metaIsDeleted, { ...props })}
        />
      </Box>
    );

  //
  const viewLinksBtn =
    showListingLink && connectionBtnTitle === 'Deactivate' ? (
      <Box alignItems="center">
        <IconButton onClick={connectionsMorePopover.handleClick}>
          <ThemeProvider theme={{ color: themeContainer.conversationChatHeaderIconColor }}>
            <StyledMoreIcon />
          </ThemeProvider>
        </IconButton>

        <ConnectionsMorePopover
          anchorEl={connectionsMorePopover.anchorEl}
          handleClose={connectionsMorePopover.handleClose}
          open={connectionsMorePopover.open}
          onViewReview={() => handleListingClick({ ...props })}
          onViewPostReview={() => handleViewPostClick({ ...props })}
          onCheckForReviews={() => handleFetchExternalReviews()}
        />
      </Box>
    ) : null;

  const viewOptionsBtn =
    showListingLink && connectionBtnTitle === 'Deactivate' ? (
      <Box>
        <StyledOutlineButton
          title="assign"
          variant="outlined"
          onClick={(e) => handleConnect(e, { ...props })}
        >
          View Options
        </StyledOutlineButton>
      </Box>
    ) : null;

  const switchBtn =
    showSwitch && isSynced ? (
      <CustomSwitch
        checked={isConnected || false}
        onChange={(e) => handleActivation(e, { ...props })}
      />
    ) : null;

  const actionNeededText =
    showActionNeeded && !isSynced ? (
      <Grid item xs={12} sm={12} lg={btnColWidth} md={btnColWidth}>
        <StyledActionNeededText container justify="center" alignItems="center">
          <Grid item>
            <span>{errorText}</span>
          </Grid>
          <Grid item>
            <ThemeProvider theme={{ color: iconsTheme.reportProblemIcon }}>
              <StyledReportProblemIcon />
            </ThemeProvider>
          </Grid>
        </StyledActionNeededText>
      </Grid>
    ) : null;

  const syncText =
    showSyncText && isSynced ? (
      <Grid item xs={12} sm={12} lg={btnColWidth} md={btnColWidth}>
        <StyledSyncedText container justify="center" alignItems="center">
          <Grid item>
            <span>{syncedText}</span>
          </Grid>
          <Grid item>
            <ThemeProvider theme={{ color: iconsTheme.checkCircleIcon }}>
              <StyledCheckCircle />
            </ThemeProvider>
          </Grid>
        </StyledSyncedText>
      </Grid>
    ) : null;

  const syncContainer = (
    <Box display="flex" alignItems="center">
      {viewLinksBtn}
      {viewOptionsBtn}
      {isAuthRequired ? authConnectBtn : connectBtn}
      {switchBtn}
      {actionNeededText}
      {syncText}
    </Box>
  );

  const reviewInfoContainer = isShowReviewInfo ? (
    <StyledReviewContainer container alignItems="center" justify="center" direction="column">
      <Grid item>
        <Grid container>
          <ThemeProvider theme={{ color: textColors.fontColor3 }}>
            <StyledRatingText item>{averageReviews}</StyledRatingText>
          </ThemeProvider>
          <Grid item>
            <CustomRating ratingValue={averageReviews} />
          </Grid>
        </Grid>
      </Grid>
      <ThemeProvider theme={{ color: textColors.fontColor7 }}>
        <StyledReviewsText item>
          {totalReviews}
          &nbsp; Total Reviews
        </StyledReviewsText>
      </ThemeProvider>
    </StyledReviewContainer>
  ) : null;

  const statusContainer =
    (showListingLink && connectionBtnTitle === 'Deactivate') || isConnected === true ? (
      <Box alignItems="flex-start">
        <ThemeProvider theme={{ color: textColors.fontColor3 }}>
          <StyledLogoText>{name}</StyledLogoText>
          <Box alignItems="center" display="flex">
            <StatusConnectedIcon />
            <StyledStatusText>Connected</StyledStatusText>
          </Box>
        </ThemeProvider>
      </Box>
    ) : (
      <Box alignItems="flex-start">
        <ThemeProvider theme={{ color: textColors.fontColor3 }}>
          <StyledLogoText>{name}</StyledLogoText>
          <Box alignItems="center" display="flex">
            <StatusUnconnectedIcon />
            <StyledStatusText>Disconnected</StyledStatusText>
          </Box>
        </ThemeProvider>
      </Box>
    );

  const actionPanel = syncContainer;

  const logo = uploadedFile.length > 0 ? uploadedFile[0].url : '';
  return (
    <Card
      style={{
        backgroundColor: cardTheme.cardBgColor,
        padding: '0.5rem',
        paddingRight: '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <StyledCardContent>
        <Box display="flex" alignItems="center">
          <ThemeProvider theme={{ bgColor: backgroundColor }}>
            <StyledBgContainer>
              <ThemeProvider theme={avatarTheme}>
                <CusotmAvatar alt={name} src={`${process.env.REACT_APP_IMAGE_ENDPOINT}${logo}`} />
              </ThemeProvider>
            </StyledBgContainer>
          </ThemeProvider>
          {statusContainer}
        </Box>
        {reviewInfoContainer}
      </StyledCardContent>
      <StyledCardActions>{actionPanel}</StyledCardActions>
    </Card>
  );
}

AuthCard.propTypes = {
  backgroundColor: Proptypes.string,
  name: Proptypes.string,
  uploadedFile: Proptypes.arrayOf(Proptypes.object),
  averageReviews: Proptypes.any,
  totalReviews: Proptypes.any,
  handleListingClick: Proptypes.func,
  btnColWidth: Proptypes.number,
  isShowReviewInfo: Proptypes.bool,
  handleActivation: Proptypes.func,
  showConnect: Proptypes.bool,
  handleConnect: Proptypes.func,
  switchActionTitle: Proptypes.string,
  metaIsDeleted: Proptypes.bool,
  showListingLink: Proptypes.bool,
  showSwitch: Proptypes.bool,
  connectionBtnTitle: Proptypes.string,
  showActionNeeded: Proptypes.bool,
  showSyncText: Proptypes.bool,
  isSynced: Proptypes.bool,
  errorText: Proptypes.string,
  syncedText: Proptypes.string,
  isConnected: Proptypes.bool,
  handleDisconnect: Proptypes.func,
  isAuthRequired: Proptypes.bool,
  handleOtherSiteDisconnect: Proptypes.func,
};

AuthCard.defaultProps = {
  backgroundColor: '#fff',
  uploadedFile: [],
  averageReviews: '0',
  totalReviews: '0',
  btnColWidth: 3,
  isShowReviewInfo: false,
  handleListingClick: () => {},
  showConnect: false,
  handleConnect: () => {},
  switchActionTitle: 'Active',
  showListingLink: true,
  showSwitch: false,
  connectionBtnTitle: 'connect',
  showActionNeeded: false,
  showSyncText: false,
  handleDisconnect: () => {},
};

export default AuthCard;
