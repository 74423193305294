import React, { useContext } from 'react';
import Proptypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import ReactTimeAgo from 'react-time-ago';
import { Grid } from '@material-ui/core';
import { GlobalContext } from '../../Stores/GlobalStateStore';

const StyledTimeLapsedContainer = styled.div`
  //letter-spacing: 0.28px;
  color: ${(props) => props.theme.color};
  opacity: 0.4;
  font-size: 12px;
  margin-left: 0.5em;
  //margin: -0.5rem 0.5em 0 0.5em;
  // @media screen and (min-width: 1920px) {
  //   & {
  //     font-size: 14px !important;
  //   }
  // }
  font-weight: 400 !important;
`;

function TimeLapsed(props) {
  const { time } = props;
  const { state } = useContext(GlobalContext);
  const { themeContainer } = state;

  return (
    <ThemeProvider theme={{ color: themeContainer.timeLapsedColor1 }}>
      <StyledTimeLapsedContainer>
        <ReactTimeAgo date={time} />
      </StyledTimeLapsedContainer>
    </ThemeProvider>
  );
}

TimeLapsed.propTypes = {
  time: Proptypes.number.isRequired,
};

TimeLapsed.defaultProps = {
  time: new Date().getTime(),
};

export default TimeLapsed;
