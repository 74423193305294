import React from 'react';
import Proptypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { Card, CardContent, Typography } from '@material-ui/core';
import Logo from '../../assets/images/Reveo_Logo_Gray.svg';
import UploadLayout from '../UploadLayout';

const CardStyle = styled(Card)`
  text-align: center;
  min-width: 100%;
  height: 100%;
  position: relative;
  margin: auto 0;
  // height: 450px;
  // @media only screen and (max-width: 600px) {
  //   box-shadow: none;
  // }
`;
const BottomContent = styled(CardContent)`
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 12px 0 !important;
`;
const CardContentStyle = styled(CardContent)`
  text-align: center;
`;

const QuestionTextStyle = styled(Typography)`
  color: #9e9a9a !important;
  font-family: Montserrat, bold !important;
  font-size: 1.2rem !important;
  // @media only screen and (min-device-width: 481px) and (max-device-width: 1000px) {
  //   font-size: 1rem !important;
  // }
`;

function ThankYouCard(props) {
  const { powerImageWidth, thankyouCard, url } = props;
  const { title } = thankyouCard;
  return (
    <CardStyle>
      <div>
        <CardContentStyle>
          {url ? (
            <ThemeProvider theme={{ width: '85%', margin: '0 auto', border: 'none' }}>
              <UploadLayout
                handleClick={() => {}}
                url={`${process.env.REACT_APP_IMAGE_ENDPOINT}${url}`}
                imgHeight="350px"
                disabled
              />
            </ThemeProvider>
          ) : (
            ''
          )}
        </CardContentStyle>
        <CardContentStyle />
        <CardContentStyle>
          <QuestionTextStyle>{title}</QuestionTextStyle>
          <CardContentStyle />
          <CardContentStyle />
        </CardContentStyle>
      </div>
      <BottomContent>
        <div style={{ display: 'inline-grid' }}>
          <span style={{ fontSize: '8px', marginLeft: '14px' }}>Powered by</span>
          <img
            src={Logo}
            style={{ width: powerImageWidth ? `${powerImageWidth}em` : '7rem' }}
            alt="logo"
          />
        </div>
      </BottomContent>
    </CardStyle>
  );
}

ThankYouCard.propTypes = {
  powerImageWidth: Proptypes.number.isRequired,
  thankyouCard: Proptypes.object.isRequired,
  url: Proptypes.string.isRequired,
};

ThankYouCard.defaultProps = {};

export default ThankYouCard;
