import React from 'react';

const HoursDisplay = ({ schedule }) => {
  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  return (
    <div
      style={{
        width: '100%',
        fontSize: '15px',
        rowGap: '.5rem',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {schedule.map((dayInfo) => {
        const [day, time] = dayInfo.split(': ');
        const index = daysOfWeek.findIndex((d) => d === day);
        const formattedTime = time === 'Closed' ? 'Closed' : time.replace(/\s+/g, '');

        return (
          <div key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ fontWeight: '600', color: '#7f7f7f' }} key={day}>
              {daysOfWeek[index]}
            </div>{' '}
            <div style={{ fontWeight: 'normal' }}>{formattedTime}</div>
          </div>
        );
      })}
    </div>
  );
};

export default HoursDisplay;
