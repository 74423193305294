import { Box } from '@material-ui/core';
import StarIcon from 'mdi-react/StarIcon';
import React from 'react';

export const StarRating = (props) => {
  const { starRating } = props;

  const stars = (starRating) => {
    switch (starRating) {
      case 1:
        return (
          <>
            <StarIcon size={'16px'} color="orange" />
            <StarIcon size={'16px'} color="#d7d7d7de" />
            <StarIcon size={'16px'} color="#d7d7d7de" />
            <StarIcon size={'16px'} color="#d7d7d7de" />
            <StarIcon size={'16px'} color="#d7d7d7de" />
          </>
        );
      case 2:
        return (
          <>
            <StarIcon size={'16px'} color="orange" />
            <StarIcon size={'16px'} color="orange" />
            <StarIcon size={'16px'} color="#d7d7d7de" />
            <StarIcon size={'16px'} color="#d7d7d7de" />
            <StarIcon size={'16px'} color="#d7d7d7de" />
          </>
        );
      case 3:
        return (
          <>
            <StarIcon size={'16px'} color="orange" />
            <StarIcon size={'16px'} color="orange" />
            <StarIcon size={'16px'} color="orange" />
            <StarIcon size={'16px'} color="#d7d7d7de" />
            <StarIcon size={'16px'} color="#d7d7d7de" />
          </>
        );
      case 4:
        return (
          <>
            <StarIcon size={'16px'} color="orange" />
            <StarIcon size={'16px'} color="orange" />
            <StarIcon size={'16px'} color="orange" />
            <StarIcon size={'16px'} color="orange" />
            <StarIcon size={'16px'} color="#d7d7d7de" />
          </>
        );
      case 5:
        return (
          <>
            <StarIcon size={'16px'} color="orange" />
            <StarIcon size={'16px'} color="orange" />
            <StarIcon size={'16px'} color="orange" />
            <StarIcon size={'16px'} color="orange" />
            <StarIcon size={'16px'} color="orange" />
          </>
        );
      default:
        return (
          <>
            <StarIcon size={'16px'} color="orange" />
            <StarIcon size={'16px'} color="#d7d7d7de" />
            <StarIcon size={'16px'} color="#d7d7d7de" />
            <StarIcon size={'16px'} color="#d7d7d7de" />
            <StarIcon size={'16px'} color="#d7d7d7de" />
          </>
        );
    }
  };
  return (
    <Box display="flex" width="fit-content">
      {stars(starRating)}
    </Box>
  );
};
