import React from 'react';
import { makeStyles, Popover } from '@material-ui/core';
import StyledMenuItem from '../../../../../../../Common/StyledMenuItem';
import { getIcon } from '../../../../../../../../utils/helper/helper';

const WebsitePopover = ({
  id,
  open,
  anchorEl,
  handleClose,
  allPostsites,
  selectedPostsites,
  setSelectedPostsites,
  bodyRequest,
  setBodyRequest,
}) => {
  const useStyles = makeStyles((theme) => ({
    popover: {
      padding: '.5em',
      minWidth: '200px',
      maxWidth: '250px',
    },
    paper: {
      padding: theme.spacing(1),
      minWidth: '200px',
      maxWidth: '250px',
      background: theme.background,
    },
    sourceImage: {
      width: '24px',
      height: '24px',
      marginRight: '.5rem',
    },
  }));
  const classes = useStyles();

  const handleSelectedNetwork = (selectedSite) => {
    const selectedExists = selectedPostsites.some(
      (selected) => selected.name === selectedSite.name,
    );

    if (!selectedExists) {
      const updatedSites = [...selectedPostsites, selectedSite];
      setSelectedPostsites(updatedSites);
      setBodyRequest({
        ...bodyRequest,
        postSites: updatedSites,
      });
      handleClose();
    }
  };

  const sitesToFilter = ['instagram', 'twitter'];
  const filteredPostSites = allPostsites.filter(
    (site) => !sitesToFilter.includes(site.name.toLowerCase()),
  );

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
      >
        {filteredPostSites.map((site) => (
          <StyledMenuItem
            key={site.name}
            value={site}
            disabled={selectedPostsites.some((selected) => selected.name === site.name)}
            onClick={() => handleSelectedNetwork(site)}
          >
            <img className={classes.sourceImage} src={getIcon(site.name).icon} />
            {site.name}
          </StyledMenuItem>
        ))}
      </Popover>
    </>
  );
};

export default WebsitePopover;
