import React, { createContext, useReducer } from 'react';
import Proptypes from 'prop-types';
import dashboardState from '../state/DashboardState';
import DashboardReducer from '../Reducers/DashboardReducer';

export const OnDashboardContext = createContext(dashboardState);

const DashboardStore = ({ children }) => {
  const [state, dashboardDispatch] = useReducer(DashboardReducer, dashboardState);
  return (
    <OnDashboardContext.Provider value={{ state, dashboardDispatch }}>
      {children}
    </OnDashboardContext.Provider>
  );
};

DashboardStore.propTypes = {
  children: Proptypes.node.isRequired,
};

export default DashboardStore;
