import React, { useState, useEffect, useContext, useCallback } from 'react';
import Proptypes from 'prop-types';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormHelperText,
  ThemeProvider as MUIThemeProvider,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import styled, { ThemeProvider } from 'styled-components';
import ClearIcon from '@material-ui/icons/Clear';
import ButtonLoader from '../ButtonLoader/ButtonLoader';
import CustomButton from '../CustomButton/CustomButton';
import CusotmAlert from '../CustomAlert/CustomAlert';
import Dropdown from '../Dropdown/Dropdown';
import months from '../../utils/api/Months';
import StyledTextField from '../GeneralInputField/GeneralInputField';
import defaultDropDownTheme from '../../themes/Dropdown/DefaultDropdownTheme';
import baseUrl from '../../utils/services/config';
import Loader from '../Loader/Loader';
import {
  ConvertIntoLocationDropDown,
  errorChecker,
  getYearDropDown,
} from '../../utils/helper/helper';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import StyledFormControl from '../StyledFormControl';
import GenericPopup from '../Popup/GenricPopup/index';
import useInputFieldTheme from '../../hooks/useInputFieldTheme';
import useButtonTheme from '../../hooks/useButtonTheme';
import StyledImpText from '../ImpText/ImpText';

const StyledContentDialog = styled(DialogContent)`
  padding: 0 0.5em !important;
`;

const StyledActions = styled(DialogActions)`
  padding: 0 !important;
`;

const buttonTheme1 = {
  background: 'transparent !important',
  margin: '0 ',
};

const inputFieldTheme = {
  inputMargin: '.5em 0',
};

function GoalSettingPopup(props) {
  const { open, handleClose, user, selectedUserId, selectedYear } = props;
  const { register, handleSubmit, errors, control, setValue, watch } = useForm();
  const [showAlert, setAlert] = useState(false);
  const [loader, setLoader] = useState(false);
  const [renderView, setRenderView] = useState(true);
  const [goalsData, setGoalsData] = useState([]);
  const [locationContainer, setLocationContainer] = useState([]);
  const [totalReviews, setTotalReviews] = useState(0);
  const [reviewInfo, setReviewInfo] = useState({ conversionRate: 10, reviewCount: 0 });
  const { dispatch, state } = useContext(GlobalContext);
  const { selectedBusiness, selectedLoaction } = state;
  const watchMonth = watch('month');
  const watchTarget = watch('total');
  const { inputTheme } = useInputFieldTheme();
  const { buttonTheme } = useButtonTheme();

  const fetchUserGoalsCount = useCallback(async () => {
    if (selectedUserId) {
      try {
        const result = await baseUrl(
          `user/reviews-info?user=${selectedUserId}&location=${selectedLoaction}`,
        );
        setReviewInfo(result.data);
      } catch (err) {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  }, [selectedUserId]);
  const fetchUserGoalSetting = useCallback(async () => {
    if (selectedUserId) {
      try {
        const results = await baseUrl(`/user/${selectedUserId}/usergoals`);
        if (results.status === 200) {
          setRenderView(true);
          setValue('user', selectedUserId);
          const goalsContainer = [];
          results.data.forEach((goal) => {
            if (goal.year === selectedYear) {
              goalsContainer.push(goal);
            }
          });
          setGoalsData(goalsContainer);
          results.data.forEach((item, index) => {
            if (item.month === new Date().getMonth() + 1 && item.year === selectedYear) {
              setValue('total', results.data[index].total);
              setValue('month', results.data[index].month);
              setValue('year', results.data[index].year);
              setValue('location', results.data[index].location);
            }
          });
        }
      } catch (err) {
        setLoader(false);
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        handleClose();
      }
    }
  }, [selectedUserId]);

  const fetchChangedMonthGoal = useCallback(async () => {
    if (watchMonth && selectedUserId) {
      try {
        const results = await baseUrl(`/user/${selectedUserId}/usergoals`);
        if (results.status === 200) {
          let monthIndex;
          results.data.forEach((item, index) => {
            if (item.month === watchMonth && item.year === selectedYear) {
              monthIndex = index;
            }
          });
          if (monthIndex >= 0) {
            setValue('total', results.data[monthIndex].total);
          } else {
            setValue('total', null);
          }
        }
      } catch (err) {
        setLoader(false);
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        handleClose();
      }
    }
  }, [watchMonth, selectedUserId]);

  const fetchLocation = useCallback(async () => {
    if (selectedBusiness) {
      try {
        const result = await baseUrl.get(`/business/${selectedBusiness}/location?isDeleted=false`);
        if (result.status === 200) {
          setLocationContainer(ConvertIntoLocationDropDown(result.data));
        }
      } catch (err) {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  }, [selectedBusiness, dispatch]);

  useEffect(() => {
    if (selectedUserId) {
      setRenderView(false);
      fetchUserGoalSetting();
    }
  }, [selectedUserId]);

  useEffect(() => {
    fetchChangedMonthGoal();
  }, [fetchChangedMonthGoal]);
  useEffect(() => {
    fetchUserGoalsCount();
  }, [fetchUserGoalsCount]);

  useEffect(() => {
    fetchLocation();
  }, [selectedBusiness, selectedUserId, fetchLocation]);

  const onSubmit = async (data) => {
    setLoader(true);
    let selectedGoal;
    goalsData.forEach((item) => {
      if (item.month === watchMonth) {
        selectedGoal = item.id;
      }
    });

    if (selectedGoal) {
      try {
        const results = await baseUrl.patch(`/usergoals/${selectedGoal}`, {
          ...data,
          total: totalReviews,
        });
        if (results.status === 200) {
          setLoader(false);
          handleClose();
        }
      } catch (err) {
        setLoader(false);
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    } else {
      try {
        const results = await baseUrl.post(`/usergoals`, { ...data, total: totalReviews });
        if (results.status === 200) {
          setLoader(false);
          handleClose();
        }
      } catch (err) {
        setLoader(false);
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  };

  useEffect(() => {
    if (watchTarget && watchTarget !== undefined) {
      const totalReviewsToSend =
        (Number(watchTarget) - Number(reviewInfo.reviewCount)) * Number(reviewInfo.conversionRate);
      setTotalReviews(totalReviewsToSend);
    }
  }, [watchTarget]);

  const handleAlertClose = () => setAlert(false);

  const showLoader = loader ? <ButtonLoader /> : null;

  const reviewInfoMessageContainer =
    watchTarget && watchTarget !== undefined ? (
      <StyledImpText item xs={12} sm={12} md={12} lg={12}>
        {`You need to send
        ${totalReviews}
         review request to reach your goal`}
      </StyledImpText>
    ) : (
      ''
    );

  return renderView ? (
    <>
      <CusotmAlert
        open={showAlert}
        message="Goal settings created successfully"
        handleClose={handleAlertClose}
      />

      <GenericPopup open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>
          <Grid container justify="space-between">
            <Grid item>Goal Setting</Grid>
            <Grid item>
              <ThemeProvider theme={buttonTheme1}>
                <CustomButton onClick={handleClose} title="Close">
                  <ClearIcon />
                </CustomButton>
              </ThemeProvider>
            </Grid>
          </Grid>
        </DialogTitle>
        <MUIThemeProvider theme={inputTheme}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <StyledContentDialog>
              <Grid container justify="center">
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <ThemeProvider theme={defaultDropDownTheme}>
                    <StyledFormControl error={!!errors.user}>
                      <Controller
                        as={
                          <Dropdown
                            disabled={selectedUserId}
                            listOptions={Array.from(user)}
                            label="Select User *"
                          />
                        }
                        name="user"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                      />
                      {errors.user ? (
                        <FormHelperText>User is a required field</FormHelperText>
                      ) : null}
                    </StyledFormControl>
                  </ThemeProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <ThemeProvider theme={inputFieldTheme}>
                    <StyledTextField
                      name="total"
                      type="number"
                      value={watchTarget}
                      inputRef={register({ required: true })}
                      label="Review Target *"
                      error={errors.total}
                      helperText={errors.total ? 'Review Target is a required field' : null}
                    />
                  </ThemeProvider>
                  {reviewInfoMessageContainer}
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <ThemeProvider theme={defaultDropDownTheme}>
                    <StyledFormControl error={!!errors.month}>
                      <Controller
                        as={<Dropdown listOptions={Array.from(months)} label="Select Month *" />}
                        name="month"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                      />
                      {errors.month ? (
                        <FormHelperText>Month is a required field</FormHelperText>
                      ) : null}
                    </StyledFormControl>
                  </ThemeProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <ThemeProvider theme={defaultDropDownTheme}>
                    <StyledFormControl error={!!errors.year}>
                      <Controller
                        as={
                          <Dropdown
                            listOptions={Array.from(getYearDropDown())}
                            label="Select Year *"
                          />
                        }
                        name="year"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                      />
                      {errors.year ? (
                        <FormHelperText>Year is a required field</FormHelperText>
                      ) : null}
                    </StyledFormControl>
                  </ThemeProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <ThemeProvider theme={defaultDropDownTheme}>
                    <StyledFormControl error={!!errors.location}>
                      <Controller
                        as={
                          <Dropdown
                            listOptions={Array.from(locationContainer)}
                            label="Select Location *"
                          />
                        }
                        name="location"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                      />
                      {errors.location ? (
                        <FormHelperText>location is a required field</FormHelperText>
                      ) : null}
                    </StyledFormControl>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </StyledContentDialog>
            <StyledActions>
              <Grid container justify="center">
                <Grid item xs={12} sm={12} md={3} lg={4}>
                  <ThemeProvider
                    theme={{ background: buttonTheme.buttonBgColor, color: buttonTheme.fontColor3 }}
                  >
                    <CustomButton type="submit" color="primary" disabled={loader}>
                      {'Add'}
                      {showLoader}
                    </CustomButton>
                  </ThemeProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9} style={{ padding: '0 50px' }}>
                  <p>
                    At Reveo, we estimate the conversion rate of review requests to be around 10%.
                  </p>
                  <p>
                    In other words, on an average, you will receive one review for every 10 requests
                    sent. We use this probability in the following equation to calculate the number
                    of review requests you would need to send to achieve your review goal:
                  </p>
                  <p>
                    Minimum number of review requests needed = (Desired Review Count – Existing
                    Review Count) * 10
                  </p>
                  <p>
                    As and example, if you have 100 current reviews and you would like to grow your
                    reviews to 200, the equation would look like this: (200 Reviews Goal – 100
                    Current Reviews) * 10 = 1,000 Review Requests So, you you need to send at least
                    1,000 review requests to achieve your goal!
                  </p>
                </Grid>
              </Grid>
            </StyledActions>
          </form>
        </MUIThemeProvider>
      </GenericPopup>
    </>
  ) : (
    <Loader />
  );
}

GoalSettingPopup.propTypes = {
  open: Proptypes.bool.isRequired,
  handleClose: Proptypes.func.isRequired,
  user: Proptypes.arrayOf(Proptypes.object).isRequired,
  selectedUserId: Proptypes.string.isRequired,
  selectedYear: Proptypes.number.isRequired,
};

export default React.memo(GoalSettingPopup);
