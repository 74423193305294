/* eslint-disable no-prototype-builtins */
/* eslint-disable array-callback-return */
/* eslint-disable */
import React, { useEffect, useState, useContext, useCallback, useRef } from 'react';
// import Proptypes from 'prop-types';
import {
  ThemeProvider as MUIThemeProvider,
  InputAdornment,
  Box,
  IconButton,
  Badge,
} from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import { useHistory } from 'react-router-dom';
import CustomButton from '../../components/CustomButton/CustomButton';
import baseUrl from '../../utils/services/config';
import { FormHeader } from '../../components/Forms/FormHeader';
import TransperentButtonTheme from '../../themes/Button/TransperentButtonTheme';
// import BusinessHours from '../../components/BusinessHours/BusinessHours';
import { GeneralTab } from './GeneralTab';

import {
  ConvertInDropDownFormat,
  ConvertIntoBusinessHoursFormat,
  getValidValues,
  ConvertIntoUserDropDown,
  getMultiSelectIds,
  errorChecker,
  validateURL,
  getURLWithoutPrefix,
} from '../../utils/helper/helper';

import baseUrlLogin from '../../utils/services/auth.config';
import Loader from '../../components/Loader/Loader';
import CusotmAlert from '../../components/CustomAlert/CustomAlert';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import { emailRegEx } from '../../utils/helper/regEx';
import ButtonLoader from '../../components/ButtonLoader/ButtonLoader';
import useFetchLocation from '../../hooks/useFetchLocation';
import useIconTheme from '../../hooks/useIconTheme';
import CloseIcon from '@material-ui/icons/Close';
import AntTabs from '../../components/StyledTabsContainer/StyledTabsContainer';
import AntTab from '../../components/StyledTabs/StyledTabs';
import useTabsTheme from '../../hooks/useTabsTheme';
import { ContactTab } from './ContactTab';
import { SocialConnections } from './SocialConnections';
import { BusinessCardTab } from './BusinessCardTab';
import { MicrositeTab } from './MicrositeTab';
import { useFormik } from 'formik';
import * as yup from 'yup';

const StyledDashboard = styled.div`
  min-height: 100%;
  grid-gap: 1.5rem;
`;

const StyledBox = styled(Box)`
  background-color: #f5f5f5;
`;

const StyledBadge = styled(Badge)``;

function Locations(props) {
  const [seoCategories, setSeoCategory] = useState([]);
  const [industries, setIndustry] = useState([]);
  const [renderView, setRenderView] = useState(false);
  const [showAlert, setAlert] = useState(false);
  const [showBusinessCardAlert, setShowBusinessCardAlert] = useState(false);
  const [imagesContainer, setImagesContainer] = useState({});
  const [locationImages, setLocationImages] = useState({});
  const [loader, setLoader] = useState(false);
  const [usersContainer, setUserContainer] = useState([]);
  const { state, dispatch } = useContext(GlobalContext);
  const [disabledMMSNo, setDisabledMMSNo] = useState(false);
  const [emailLoader, setEmailLoader] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [disabledBtn, setDisableBtn] = useState(false);
  const { iconsTheme } = useIconTheme();
  const [tabValue, setTabValue] = useState(0);
  const { tabsTheme } = useTabsTheme();
  const { locationId, handleClose } = props;

  const trial_MMS_number = '3852472073';

  const { selectedBusiness, googleAccountId, businessInfo, userInfo, roles, facebookAccountId } =
    state;
  const { isSuperAdmin } = roles;
  const { accountType } = userInfo;

  // const validateEmail = async (value) => {
  //   try {
  //     const trimmedValue = value.trim();
  //     if (emailRegEx.test(trimmedValue)) {
  //       setDisableBtn(true);
  //       setEmailLoader(true);
  //       const res = await baseUrl(`/location/checkDuplicateEmail?emailAddress=${trimmedValue}`);
  //       if (res.status === 200) {
  //         //formik.setFieldError('contact.emailAddress', 'Email address already taken');
  //         setDisableBtn(false);
  //         setEmailLoader(false);
  //         //console.log(formik.touched);
  //         //setEmailError(true);
  //       } else {
  //         // setEmailError(false);
  //         setEmailLoader(false);
  //         setDisableBtn(false);
  //       }
  //     } else {
  //       setDisableBtn(false);
  //     }
  //   } catch (err) {
  //     setDisableBtn(false);
  //     setEmailLoader(false);
  //     dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
  //     return {
  //       type: 'error',
  //       message: 'An error occurred while checking the email address',
  //     };
  //   }
  // };

  const validateEmail = async (value) => {
    try {
      const trimmedValue = value.trim();
      if (emailRegEx.test(trimmedValue)) {
        setDisableBtn(true);
        setEmailLoader(true);
        const res = await baseUrl(`/location/checkDuplicateEmail?emailAddress=${trimmedValue}`);
        if (res.status === 200) {
          const locations = res.data;
          let isDuplicate = false;

          locations.data.forEach((location) => {
            //console.log(location);
            const contact = JSON.parse(location.contact); // remove JSON.parse() call
            //console.log(contact);
            console.log('trimmedValue: ' + trimmedValue.toLowerCase());
            console.log('CurrentValue: ' + formik.values.contact.emailAddress);
            if (
              contact.emailAddress.toLowerCase() === trimmedValue.toLowerCase() &&
              contact.emailAddress !== formik.values.contact.emailAddress
            ) {
              isDuplicate = false;
              //console.log('weve got a dupe!');
            } else if (contact.emailAddress.toLowerCase() === formik.values.contact.emailAddress) {
              isDuplicate = false;
            }
          });
          if (isDuplicate) {
            console.log('EMAIL EXISTS IN THE SYSTEM');
            formik.setFieldError('contact.emailAddress', 'Email address already exists');
          }
          setDisableBtn(false);
          setEmailLoader(false);
        } else {
          setDisableBtn(false);
          setEmailLoader(false);
        }
      } else {
        setDisableBtn(false);
      }
    } catch (err) {
      setDisableBtn(false);
      setEmailLoader(false);
      //dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      return {
        type: 'error',
        message: 'An error occurred while checking the email address',
      };
    }
  };

  const validationSchema = yup.object({
    name: yup.string('Location Name').required('Location name is required'),
    nickName: yup.string('Location Nickname').required('Nickname is required'),
    noEmployees: yup.number('Enter the number of employees'),
    users: yup
      .array()
      .of(yup.mixed().required('Please select at least one user'))
      .test('atLeastOneUserSelected', 'Please select at least one user', function (value) {
        return value && value.length > 0;
      }),
    contact: yup.object({
      addressLine1: yup.string('Address 1').required('Please enter a street address'),
      city: yup.string('City').required('Please enter a city'),
      state: yup.string('State').required('Please enter a state'),
      phoneNo: yup.string('Phone Number').required('Please enter a valid phone number'),
      mmsNumber: yup.string('MMS Number').required('Please enter an mms number'),
      emailAddress: yup
        .string('Email Address')
        .email('Enter a valid email address')
        .required('Email address is required'),
    }),
    googleLocation: yup.string('Google Location').required('Google business location is required'),
    seoCategory: yup.string('Seo Category').required('SEO category is required'),
    industry: yup
      .string('Google Business Category')
      .required('Google business category is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      nickName: '',
      noEmployees: '',
      users: [],
      aboutUs: '',
      acceptReviews: false,
      contact: {
        addressLine1: '',
        addressLine2: '',
        suite: '',
        city: '',
        state: '',
        zip: '',
        timeZone: '',
        webSite: '',
        phoneNo: '',
        mmsNumber: '',
        emailAddress: '',
        facebook: '',
        twitter: '',
        instagram: '',
        linkedIn: '',
        youTube: '',
      },
      googleLocation: '',
      facebookPages: '',
      industry: '',
      seoCategory: '',
      business_Reviews: '',
      business_logo: '',
      businessCard: {
        headerColor: '',
        backgroundColor: '',
        iconColor: '',
        textColor: '',
        isUserImageAvatar: false,
        isHeaderImage: false,
      },
      microsite: {
        allowWebchat: false,
        linkColor: '#1976d2',
      },
      uploadedFile: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        // perform form submission
        console.log('Form values:', values);
      } catch (error) {
        console.error(error);
      }
      setSubmitting(false);
    },
  });

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  const history = useHistory();
  const { fetchLocation } = useFetchLocation();

  const fetchLocationFormValues = useCallback(() => {
    if (locationId && locationId !== 'new') {
      baseUrl.get(`/location/${locationId}`).then((res) => {
        if (res.status === 200) {
          // console.log(res.data);
          let imagesContainerCopy = { ...imagesContainer };
          res.data.uploadedFile.forEach((element) => {
            if (element.meta) {
              imagesContainerCopy = Object.assign(imagesContainer, {
                ...{ [element.meta]: element.id, imagePath: element.path },
              });
              formik.values.uploadFile = (element.meta, element.url);
              setLocationImages({ type: element.meta, url: element.url });
            }
          });
          let contactDetails = { ...res.data.contact };

          contactDetails = {
            ...contactDetails,
            phoneNo: contactDetails.phoneNo ? contactDetails.phoneNo.substring(2) : '',
            mmsNumber: contactDetails.mmsNumber ? contactDetails.mmsNumber : trial_MMS_number,
            webSite: getURLWithoutPrefix(contactDetails.webSite || contactDetails.webAddress),
          };
          const images = res.data.uploadedFile;
          const micrositeHeader =
            images && images.filter((image) => image.meta === 'business_Reviews');
          const businessLogo = images && images.filter((image) => image.meta === 'business_logo');

          setImagesContainer(imagesContainer);

          formik.values.name = res.data.name;
          formik.values.nickName = res.data.nickName;
          formik.values.noEmployees = res.data.noEmployees;
          formik.values.users = getMultiSelectIds(res.data.user);
          formik.values.aboutUs = res.data.aboutUs;
          formik.values.acceptReviews = res.data.acceptReviews;
          formik.values.contact.addressLine1 = contactDetails.addressLine1;
          formik.values.contact.addressLine2 = contactDetails.addressLine2;
          formik.values.contact.suite = contactDetails.suite;
          formik.values.contact.city = contactDetails.city;
          formik.values.contact.state = contactDetails.state;
          formik.values.contact.zip = contactDetails.zip;
          formik.values.contact.timeZone = contactDetails.timeZone;
          formik.values.contact.webSite = contactDetails.webSite;
          formik.values.contact.phoneNo = contactDetails.phoneNo;
          formik.values.contact.mmsNumber = contactDetails.mmsNumber;
          formik.values.contact.emailAddress = contactDetails.emailAddress;
          formik.values.contact.facebook = contactDetails.facebook;
          formik.values.contact.twitter = contactDetails.twitter;
          formik.values.contact.instagram = contactDetails.instagram;
          formik.values.contact.linkedIn = contactDetails.linkedIn;
          formik.values.contact.youTube = contactDetails.youTube;
          formik.values.googleLocation =
            res.data.googleLocation && res.data.googleLocation.length > 0
              ? res.data.googleLocation[0].id
              : '';
          formik.values.facebookPages =
            res.data.facebookPages && res.data.facebookPages.length > 0
              ? res.data.facebookPages[0].id
              : '';
          formik.values.industry = res.data.industry.length
            ? parseInt(res.data.industry[0].id, 10)
            : null;
          formik.values.seoCategory = parseInt(res.data.seoCategory[0].id, 10);
          formik.values.business_Reviews =
            micrositeHeader && micrositeHeader.length > 0 ? micrositeHeader[0].url : '';
          formik.values.business_logo =
            businessLogo && businessLogo.length > 0 ? businessLogo[0].url : '';
          formik.values.businessCard.headerColor =
            res.data.businessCard && Object.keys(res.data.businessCard).length > 0
              ? res.data.businessCard.headerColor
              : '#30feaf';
          formik.values.businessCard.backgroundColor =
            res.data.businessCard && Object.keys(res.data.businessCard).length > 0
              ? res.data.businessCard.backgroundColor
              : '#fff';
          formik.values.businessCard.iconColor =
            res.data.businessCard && Object.keys(res.data.businessCard).length > 0
              ? res.data.businessCard.iconColor
              : '#000';
          formik.values.businessCard.textColor =
            res.data.businessCard && Object.keys(res.data.businessCard).length > 0
              ? res.data.businessCard.textColor
              : '#000';
          formik.values.businessCard.isUserImageAvatar =
            res.data.businessCard && Object.keys(res.data.businessCard).length > 0
              ? res.data.businessCard.isUserImageAvatar
              : false;
          formik.values.businessCard.isHeaderImage =
            res.data.businessCard && Object.keys(res.data.businessCard).length > 0
              ? res.data.businessCard.isHeaderImage
              : false;
          formik.values.microsite.linkColor =
            res.data.microsite && Object.keys(res.data.microsite).length > 0
              ? res.data.microsite.linkColor
              : '#1976d2';
          formik.values.microsite.allowWebchat =
            res.data.microsite && Object.keys(res.data.microsite).length > 0
              ? res.data.microsite.allowWebchat
              : false;
          setRenderView(true);
        }
      });
    } else {
      formik.values.contact.mmsNumber = trial_MMS_number;
    }
  }, []);

  const fetchSeoCategory = useCallback(() => {
    baseUrl
      .get('/seoCategory?isDeleted=false')
      .then((res) => {
        if (res.status === 200) {
          setSeoCategory(Array.from(ConvertInDropDownFormat(res.data)));
        }
      })
      .catch((err) => {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      });
  }, [dispatch]);

  const fetchIndustries = useCallback(() => {
    baseUrlLogin
      .get('/industry?isDeleted=false')
      .then((res) => {
        if (res.status === 200) {
          setIndustry(Array.from(ConvertInDropDownFormat(res.data)));
        }
      })
      .catch((err) => {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      });
  }, [dispatch]);

  const fetchUsers = useCallback(() => {
    if (selectedBusiness) {
      baseUrl
        .get(`/business/${selectedBusiness}/user?isDeleted=false`)
        .then((res) => {
          if (res.status === 200) {
            setUserContainer(Array.from(ConvertIntoUserDropDown(res.data)));
          }
        })
        .catch((err) => {
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    }
  }, [selectedBusiness, dispatch]);

  // const fetchSynuplocations = useCallback(() => {
  //   baseUrl
  //     .get(`/synuplocations?isDeleted=false`)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setSynuplocationsList(Array.from(ConvertIntoSynuplocationsDropDown(res.data)));
  //       }
  //     })
  //     .catch((err) => {
  //       dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
  //     });
  // }, [dispatch]);

  const linkGoogleAccount = useCallback(async () => {
    if (googleAccountId) {
      try {
        await baseUrl.post(`link-auth-sites`, { business: selectedBusiness });
      } catch (err) {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  }, [googleAccountId]);

  const linkFBAccount = useCallback(async () => {
    if (facebookAccountId) {
      try {
        await baseUrl.post(`link-fb-post-sites`, { business: selectedBusiness });
      } catch (err) {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  }, [facebookAccountId]);

  // const isValidateEmail = () => {
  //   console.log('VALIDATING EMAIL....');
  //   if (emailRegEx.test(formik.values.contact.emailAddress)) {
  //     setDisableBtn(true);
  //     setEmailLoader(true);
  //     baseUrl(`/location/checkDuplicateEmail?emailAddress=${formik.values.contact.emailAddress}`)
  //       .then((res) => {
  //         if (res.status === 200) {
  //           //formik.setFieldError('contact.emailAddress', 'Email address already taken');
  //           setDisableBtn(false);
  //           setEmailLoader(false);
  //           setEmailError(false);
  //         } else {
  //           setEmailError(false);
  //           setEmailLoader(false);
  //           setDisableBtn(false);
  //         }
  //         // }
  //       })
  //       .catch((err) => {
  //         setDisableBtn(false);
  //         setEmailLoader(false);
  //         dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
  //       });
  //   } else {
  //     setDisableBtn(false);
  //   }
  // };

  useEffect(() => {
    fetchLocationFormValues();
    fetchSeoCategory();
    fetchIndustries();
    //isValidateEmail();
    if (isSuperAdmin) {
      // fetchSynuplocations();
    }
    if (selectedBusiness) {
      fetchUsers();
    }
    if (accountType === 'trial' && !isSuperAdmin) {
      setDisabledMMSNo(true);
    }
  }, [
    fetchLocationFormValues,
    fetchSeoCategory,
    fetchIndustries,
    fetchUsers,
    // fetchSynuplocations
  ]);

  useEffect(() => {
    if (!locationId && businessInfo && Object.keys(businessInfo).length) {
      setRenderView(true);
    }
    if (locationId === 'new') {
      setRenderView(true);
    }
  }, [businessInfo, locationId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log(formik.errors);
    formik.setSubmitting(true);
    if (!emailError) {
      if (
        (formik.values.users && formik.values.users.length > 0) ||
        formik.values.users === undefined
      ) {
        formik.setSubmitting(false);
        let contactDetails = { ...formik.values.contact };
        contactDetails = {
          ...contactDetails,
          phoneNo: `+1${contactDetails.phoneNo}`,
          webSite: validateURL(contactDetails.webSite),
        };

        const postBody = {
          contact: { ...contactDetails },
          //businessHours: Array.from(ConvertIntoBusinessHoursFormat(data.businessHours)),
          name: formik.values.name,
          createdBy: parseInt(localStorage.getItem('USER_ID'), 10),
          seoCategory: [formik.values.seoCategory],
          industry: [formik.values.industry],
          nickName: formik.values.nickName,
          noEmployees: formik.values.noEmployees ? parseInt(formik.values.noEmployees, 10) : 0,
          uploadedFile: [],
          description: formik.values.description,
          keywords: formik.values.keywords,
          services: formik.values.services,
          business: [selectedBusiness],
          acceptReviews: formik.values.acceptReviews,
          aboutUs: formik.values.aboutUs,
          googleLocation: formik.values.googleLocation ? formik.values.googleLocation : [],
          facebookPages: formik.values.facebookPages ? formik.values.facebookPages : [],
          user: formik.values.users
            ? [
                ...new Set(
                  [parseInt(localStorage.getItem('USER_ID'), 10), 1].concat(formik.values.users),
                ),
              ]
            : [parseInt(localStorage.getItem('USER_ID'), 10), 1],
          //synupLocations: data.synuplocations ? data.synuplocations : [],
          businessCard: formik.values.businessCard,
          microsite: formik.values.microsite ? formik.values.microsite : [],
        };

        //console.log(postBody);

        const uploadedFile = [
          //imagesContainer.local_listing,
          imagesContainer.business_Reviews,
          // imagesContainer.first_image,
          // imagesContainer.second_image,
          // imagesContainer.third_image,
          // imagesContainer.fouth_image,
          // imagesContainer.fifth_image,
          imagesContainer.business_logo,
        ];

        if (locationId && locationId !== 'new') {
          Object.assign(postBody, {
            uploadedFile: Array.from(getValidValues(uploadedFile)),
          });
          baseUrl
            .patch(`/location/${locationId}`, { ...postBody })
            .then((res) => {
              if (res.status === 200) {
                linkGoogleAccount();
                linkFBAccount();
                setAlert(true);
                handleClose();
                fetchLocationFormValues();
                setTimeout(() => {
                  // history.push('/app/setup/location');
                  setLoader(false);
                }, 1000);
              }
            })
            .catch((err) => {
              setLoader(false);
              dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
            });
          //console.log(postBody);
        } else {
          //console.log(postBody);
          baseUrl
            .post('/location', { ...postBody })
            .then((res) => {
              if (res.status === 200) {
                baseUrl
                  .patch(`/location/${res.data.id}`, {
                    uploadedFile: Array.from(getValidValues(uploadedFile)),
                  })
                  .then(() => {
                    setAlert(true);
                    baseUrl.get(`/business/${selectedBusiness}/location`).then(() => {
                      fetchLocation();
                      handleClose();
                      linkGoogleAccount();
                      linkFBAccount();
                      setTimeout(() => {
                        ////history.push('/app/setup/location');
                        setLoader(false);
                      }, 1000);
                    });
                  })
                  .catch((err) => {
                    setLoader(false);
                    dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
                  });
              }
            })
            .catch((err) => {
              setLoader(false);
              dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
            });
        }
      } else {
        formik.setFieldError('contact.emailAddress', 'Users is a required field');
      }
      formik.setSubmitting(false);
    } else if (emailError) {
      formik.setFieldError('contact.emailAddress', 'Email address already taken');
    }
  };

  const handleCloseAlert = () => {
    setAlert(false);
    setShowBusinessCardAlert(false);
  };

  const emailLoaderContent = emailLoader ? (
    <InputAdornment position="start">
      <ButtonLoader />
    </InputAdornment>
  ) : null;

  const showLoader = loader ? <ButtonLoader /> : null;
  return renderView ? (
    <Box padding={'0 3rem 2rem 3rem'} minHeight={'77vh'}>
      <StyledDashboard container>
        <CusotmAlert
          open={showBusinessCardAlert}
          message={'Business card updated successfully'}
          handleClose={handleCloseAlert}
        />
        <CusotmAlert
          open={showAlert}
          message={
            locationId !== 'new' ? 'Location updated successfully' : 'Location added successfully'
          }
          handleClose={handleCloseAlert}
        />
        <form onSubmit={handleSubmit}>
          <StyledBox
            position={'sticky'}
            top={'0'}
            display="flex"
            justifyContent={'space-between'}
            alignItems={'center'}
            flexDirection="column"
            zIndex={1}
            padding="3rem 1rem 1rem 1rem"
            margin={'0 -1rem'}
          >
            <Box display="flex" justifyContent={'space-between'} width="100%">
              <FormHeader>{locationId !== 'new' ? 'Edit Location' : 'Add Location'}</FormHeader>
              <Box width="200px" marginTop="-1.5rem" marginRight="-3rem">
                <CustomButton
                  type="submit"
                  width={'300px'}
                  disabled={loader || emailLoader || disabledBtn}
                >
                  Save
                  {showLoader}
                </CustomButton>
              </Box>
              <ThemeProvider theme={TransperentButtonTheme}>
                <IconButton
                  onClick={() => props.handleClose()}
                  style={{ position: 'absolute', top: 0, right: '-28px' }}
                >
                  <ThemeProvider theme={{ color: iconsTheme.clearIcon }}>
                    <CloseIcon />
                  </ThemeProvider>
                </IconButton>
              </ThemeProvider>
            </Box>
            <Box marginTop={'-10px'} alignSelf="flex-start">
              <MUIThemeProvider theme={tabsTheme}>
                <AntTabs value={tabValue} onChange={handleTabChange} aria-label="ant">
                  <AntTab label="General" />
                  <AntTab label="Contact Information" />
                  <AntTab label="Social Connections" />
                  <AntTab label="Microsite" />
                  <AntTab label="Business Card" />
                </AntTabs>
              </MUIThemeProvider>
            </Box>
          </StyledBox>

          <GeneralTab
            tabValue={tabValue}
            selectedBusiness={selectedBusiness}
            usersContainer={usersContainer}
            formik={formik}
            imagesContainer={imagesContainer}
            setImagesContainer={setImagesContainer}
          />

          <ContactTab
            tabValue={tabValue}
            formik={formik}
            disabledMMSNo={disabledMMSNo}
            validateEmail={validateEmail}
          />
          <SocialConnections
            tabValue={tabValue}
            formik={formik}
            selectedBusiness={selectedBusiness}
            loader={loader}
            googleAccountId={googleAccountId}
            facebookAccountId={facebookAccountId}
            dispatch={dispatch}
            industries={industries}
            seoCategories={seoCategories || []}
          />

          <MicrositeTab
            tabValue={tabValue}
            formik={formik}
            imagesContainer={imagesContainer}
            setImagesContainer={setImagesContainer}
          />

          <BusinessCardTab
            tabValue={tabValue}
            formik={formik}
            locationImages={locationImages}
            businessInfo={businessInfo}
          />
        </form>
      </StyledDashboard>
    </Box>
  ) : (
    <Loader />
  );
}

Locations.propTypes = {};

export default React.memo(Locations);
