import React, { useState } from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';
import TabPanel from '../../components/TabPanel/TabPanel';
import { ThemeProvider } from 'styled-components';
import StyledDescriptionText from '../../components/DescriptionText';
import Uploader from '../../components/Uploader/Uploader';
import StyledImpText from '../../components/ImpText/ImpText';
import CustomSwitch from '../../components/CustomSwitch/CustomSwitch';
import useTextColors from '../../hooks/useTextColors';
import { ChromePicker } from 'react-color';

const fullSizeUploader = {
  maxWidth: '100%',
  height: '224px',
};

export const MicrositeTab = (props) => {
  const { tabValue, formik, imagesContainer, setImagesContainer } = props;

  const micrositeLinkColor = formik.values.microsite.linkColor;

  const [allowWebchat, setAllowWebchat] = useState(formik.values.microsite.allowWebchat || false);

  const toggleSetAllowWebchat = () => {
    setAllowWebchat(!allowWebchat);
    formik.setFieldValue('microsite.allowWebchat', !formik.values.microsite.allowWebchat);
  };

  const [displayLinkColorPicker, setDisplayLinkColorPicker] = useState(false);

  const handleImagesContainer = (name, imgId, imgData) => {
    let imagesContainerCopy = { ...imagesContainer };
    imagesContainerCopy = Object.assign(imagesContainer, { ...{ [name]: imgId } });
    setImagesContainer(imagesContainerCopy);
  };

  const { textColors } = useTextColors();

  const useStyles = makeStyles(() => ({
    linkColor: {
      width: '36px',
      height: '14px',
      borderRadius: '2px',
      background: micrositeLinkColor,
    },
    swatch: {
      padding: '5px',
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
      marginTop: '.5rem',
    },
    popover: {
      position: 'absolute',
      zIndex: '2',
      bottom: '110px',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
  }));

  const classes = useStyles();

  return (
    <TabPanel value={tabValue} index={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ThemeProvider theme={fullSizeUploader}>
            <Uploader
              name="business_Reviews"
              setValue={(name, url) => url && formik.setFieldValue(name, url)}
              value={formik.values.business_Reviews}
              handleImagesContainer={handleImagesContainer}
              imgWidth={1920}
              imgHeight={600}
              apiUrl="/uploads/images"
              imageType="location"
              description={'Microsite header image (1920 x 600)'}
            />
          </ThemeProvider>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box container marginTop={'1rem'} display="flex" justifyContent={'space-between'}>
            <Box>
              <ThemeProvider theme={{ color: textColors.impTextColor }}>
                <StyledImpText item>Show Webchat</StyledImpText>
              </ThemeProvider>
              <ThemeProvider theme={{ color: textColors.descriptionTextColor }}>
                <StyledDescriptionText item>
                  If enabled, the microsite will include a webchat
                </StyledDescriptionText>
              </ThemeProvider>
            </Box>
            <Box>
              <CustomSwitch checked={allowWebchat} onChange={() => toggleSetAllowWebchat()} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
          <Box display="flex" alignItems="flex-start" flexDirection="column">
            <ThemeProvider theme={{ color: '#888888', margin: '0' }}>
              <StyledDescriptionText>Link Color </StyledDescriptionText>
            </ThemeProvider>
            <div
              className={classes.swatch}
              onClick={() => setDisplayLinkColorPicker(!displayLinkColorPicker)}
            >
              <div className={classes.linkColor} />
            </div>
          </Box>
          {displayLinkColorPicker ? (
            <div className={classes.popover}>
              <div
                className={classes.cover}
                onClick={() => setDisplayLinkColorPicker(!displayLinkColorPicker)}
              />
              <ChromePicker
                color={micrositeLinkColor}
                disableAlpha
                validateOnChange={false}
                onChange={(color) => formik.setFieldValue('microsite.linkColor', color.hex)}
                onChangeComplete={(color) => formik.setFieldValue('microsite.linkColor', color.hex)}
              />
            </div>
          ) : null}
        </Grid>
      </Grid>
    </TabPanel>
  );
};
