import React from 'react';
import Proptypes from 'prop-types';
import { Grid } from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import MobilePreview from '../MobilePreview/MobilePreview';
import CustomRating from '../CusotmRating/CusotmRating';
import StyledBorderTextField from '../BorderdInputField/BorderedInputField';
import PoweredBy from '../PoweredBy/PoweredBy';
import EditableText from '../Editable/EditableText';
import EditableButton from '../Editable/EditableButton';
import EditableImage from '../Editable/EditableImage';

const StyledMobileViewContainer = styled(Grid)`
  position: absolute;
  height: 92%;
  margin: 0 !important;
  top: 40px;
  padding: 0 40px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const StyledContentContainer = styled(Grid)`
  min-height: calc(100% - 10px) !important;
`;

const StyledRatingContainer = styled(Grid)`
  margin: 3em 0 0 !important;
`;

const textFeedbackTheme = {
  fontSize: '12px',
  background: '#000',
  fontWeight: '600',
  color: '#fff',
  margin: '0.5em',
};

function NegativeFeedbackView(props) {
  const { poweredBy, url } = props;
  const imageContainer = url ? <EditableImage {...props} isEditable={false} /> : null;
  return (
    <MobilePreview>
      <StyledMobileViewContainer container direction="row" justify="center" alignItems="center">
        <StyledContentContainer item xs={12} sm={12} lg={12} md={12}>
          <Grid container style={{ textAlign: 'center' }}>
            <Grid item xs={12} sm={12} lg={12} md={12}>
              {imageContainer}
            </Grid>
            <EditableText {...props} />
            <StyledRatingContainer item xs={12} sm={12} lg={12} md={12}>
              <CustomRating ratingValue={4.5} />
            </StyledRatingContainer>
            <Grid item xs={12} sm={12} lg={12} md={12}>
              <StyledBorderTextField multiline rows={5} rowsMax={7} />
            </Grid>
            <Grid item xs={12} sm={12} lg={12} md={12}>
              <ThemeProvider theme={textFeedbackTheme}>
                <EditableButton {...props} type="positiveButtonText" textColor="#fff" />
              </ThemeProvider>
            </Grid>
          </Grid>
        </StyledContentContainer>
        <PoweredBy isPoweredBy={poweredBy} />
      </StyledMobileViewContainer>
    </MobilePreview>
  );
}

NegativeFeedbackView.propTypes = {
  title: Proptypes.string.isRequired,
  images: Proptypes.arrayOf(Proptypes.string),
  poweredBy: Proptypes.bool,
  positiveButtonText: Proptypes.string.isRequired,
  url: Proptypes.string,
};

NegativeFeedbackView.defaultProps = {
  images: '',
  poweredBy: true,
  url: '',
};

export default NegativeFeedbackView;
