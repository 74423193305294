import React from 'react';
import Proptypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Button } from '@material-ui/core';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} style={{ zIndex: 1500 }} />;
}

function CusotmAlert(props) {
  const { open, handleClose, message, messageType, postionVertical, positionHorizontal, action } =
    props;
  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <Alert onClose={handleClose} severity={messageType} color={messageType} action={action}>
        {message}
      </Alert>
    </Snackbar>
  );
}

CusotmAlert.propTypes = {
  open: Proptypes.bool,
  handleClose: Proptypes.func,
  message: Proptypes.string,
  messageType: Proptypes.string,
  postionVertical: Proptypes.string,
  positionHorizontal: Proptypes.string,
};

CusotmAlert.defaultProps = {
  messageType: 'success',
  postionVertical: 'top',
  positionHorizontal: 'center',
};

export default CusotmAlert;
