import React from 'react';
import styled from 'styled-components';
import CircleOutlineIcon from 'mdi-react/CircleOutlineIcon';
import LightningBoltIcon from 'mdi-react/LightningBoltIcon';
import ArrowDecisionIcon from 'mdi-react/ArrowDecisionIcon';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from 'mdi-react/DeleteIcon';

const StyledTitle = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  align-items: center;
`;
const StyledQuestion = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  width: 80%;
  display: flex;
  align-items: center;
`;
const SingleChoicePreview = ({ data, handleDeleteQuestion, handleEditQuestion }) => {
  const { text, choices } = data;

  return (
    <div>
      <StyledTitle>
        {text}
        <EditIcon
          onClick={handleEditQuestion}
          size={25}
          style={{
            marginLeft: 10,
            marginRight: 10,
            cursor: 'pointer',
          }}
        />
        <DeleteIcon
          onClick={handleDeleteQuestion}
          size={25}
          style={{
            marginLeft: 10,
            marginRight: 10,
            cursor: 'pointer',
          }}
        />
      </StyledTitle>

      {choices.length > 0 &&
        choices.map((item) => (
          <div
            key={item}
            style={{
              display: 'flex',
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 15,
              paddingBottom: 15,
            }}
          >
            <StyledQuestion>
              <CircleOutlineIcon size={25} style={{ marginRight: 10 }} />
              {item}
            </StyledQuestion>
            <div style={{ width: '20%' }}>
              <LightningBoltIcon size={20} style={{ marginRight: 10 }} />
              <ArrowDecisionIcon size={20} style={{ marginRight: 10 }} />
            </div>
          </div>
        ))}
    </div>
  );
};

export default SingleChoicePreview;
