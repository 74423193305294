import { createTheme } from '@material-ui/core/styles';
import { useContext, useMemo, useState } from 'react';
import { GlobalContext } from '../Stores/GlobalStateStore';

const useTabsTheme = () => {
  const { state } = useContext(GlobalContext);
  const { themeContainer } = state;
  const [theme, setTheme] = useState({});

  useMemo(() => {
    setTheme(
      createTheme({
        tabIndicator: themeContainer.tabIndicator,
        tabColor: themeContainer.tabColor,
        selectedTabColor: themeContainer.selectedTabColor,
        focusedTabColor: themeContainer.focusedTabColor,
        tabScrollBtn: themeContainer.tabScrollBtn,
      }),
    );
  }, [themeContainer]);

  return {
    tabsTheme: theme,
  };
};
export default useTabsTheme;
