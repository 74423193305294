import React from 'react';
import { FormControlLabel, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  label: {
    fontFamily: 'Montserrat !important',
    fontWeight: '500 !important',
    letterSpacing: 'unset !important',
    fontSize: '14px !important',
  },
}));

const StyledFormControlLabel = (props) => {
  const classes = useStyles();
  return <FormControlLabel className={classes.label} {...props} />;
};

export default StyledFormControlLabel;
