import React from 'react';
import Proptypes from 'prop-types';
import styled from 'styled-components';
import { Avatar } from '@material-ui/core';

const StyledCusotmAvatar = styled(Avatar)`
  width: ${(props) => (props.theme.width ? props.theme.width : '20px')} !important;
  height: ${(props) => (props.theme.height ? props.theme.height : '20px')} !important;
  background-color: ${(props) =>
    props.theme.bgColor ? props.theme.bgColor : '#bdbdbd'} !important;
  //position: ${(props) => props.theme.position} !important;
  //top: ${(props) => props.theme.top};
  //bottom: ${(props) => props.theme.bottom};
  //left: ${(props) => props.theme.left};
  //right: ${(props) => props.theme.right};
  color: ${(props) => props.theme.color};
  //margin-top: 0;
  //margin: 0 auto;
  font-size: ${(props) => props.theme.fontSize} !important;
  //border: 8px solid #fff !important;
  text-transform: 'capitalize' !important;

  & > img {
    object-fit: ${(props) => props.theme.objectFit} !important;
    width: 100% !important;
    height: 100% !important;
  }
  ,
  // @media screen and (min-width: 1920px) {
  //   & {
  //     width: ${(props) => (props.theme.lgWidth ? props.theme.lgWidth : '115px')} !important;
  //     height: ${(props) => (props.theme.lgHeight ? props.theme.lgHeight : '115px')} !important;
  //   }
  // }
`;

export function CusotmAvatarWithChildren(props) {
  const { alt, src, variant, children } = props;
  return (
    <StyledCusotmAvatar alt={alt} src={src} variant={variant}>
      {children}
    </StyledCusotmAvatar>
  );
}

function CusotmAvatar(props) {
  const { alt, src, variant } = props;
  return <StyledCusotmAvatar alt={alt} src={src} variant={variant} />;
}

CusotmAvatar.propTypes = {
  alt: Proptypes.string,
  src: Proptypes.string,
  variant: Proptypes.string,
};

CusotmAvatar.defaultProps = {
  alt: 'Avatar',
  src: '',
  variant: null,
};

CusotmAvatarWithChildren.propTypes = {
  ...CusotmAvatar.propTypes,
  children: Proptypes.node.isRequired,
};

export default CusotmAvatar;
