import React, { useCallback, useContext, useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { CircularProgress, Grid, List, makeStyles, Popover } from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import { GlobalContext } from '../../../Stores/GlobalStateStore';
import baseUrl from '../../../utils/services/config';
import { errorChecker, convertIntoCampaignCards } from '../../../utils/helper/helper';
import CustomButton from '../../CustomButton/CustomButton';
import CampaignList from '../../List/CampaignList/CampaignList';
import NoDataFound from '../../NoDataFound/NoDataFound';
import ButtonLoader from '../../ButtonLoader/ButtonLoader';
import { CampaignContext } from '../../../Stores/CamapignStore';
import useButtonTheme from '../../../hooks/useButtonTheme';

const StyledContainer = styled(Grid)`
  padding: 0.5em !important;
  min-width: 500px !important;
  max-width: 500px !important;
  font-family: 'Montserrat' !important;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: 'hidden',
    //background: theme.background,
  },
}));

function CampaignListPopOver(props) {
  const classes = useStyles();
  const { id, open, anchorEl, handleClose, handleSelectedCampaign, campaginLoader, contactId } =
    props;
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const { state, dispatch } = useContext(GlobalContext);
  const { selectedLoaction } = state;
  const { campaignStates, campaignDispatch } = useContext(CampaignContext);
  const { campaignContainer, loader } = campaignStates;
  const { buttonTheme } = useButtonTheme();

  const fetchUserList = useCallback(async () => {
    if (selectedLoaction) {
      campaignDispatch({
        type: 'SHOW_LOADER',
      });
      baseUrl
        .get(
          `/campaing-list?location=${selectedLoaction}&user=${localStorage.getItem(
            'USER_ID',
          )}&client=${contactId}`,
        )
        .then((res) => {
          campaignDispatch({
            type: 'SET_CAMPAIGN_CONTAINER',
            payload: Array.from(convertIntoCampaignCards(res.data)),
          });
        })
        .catch((err) => {
          campaignDispatch({
            type: 'HIDE_LOADER',
          });
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    } else {
      campaignDispatch({
        type: 'HIDE_LOADER',
      });
    }
  }, [selectedLoaction, campaignDispatch, dispatch, contactId]);

  useEffect(() => {
    if (open) {
      setSelectedCampaign(null);
      fetchUserList();
    }
  }, [open, fetchUserList]);

  //const handleCampaignSelection = (index) => setSelectedCampaign(index);

  const handleSelectCampaign = (campaignId) => {
    if (campaignId >= 0) {
      handleSelectedCampaign({
        id: campaignContainer[campaignId].id,
        message: campaignContainer[campaignId].message,
        type: campaignContainer[campaignId].campaignType,
        name: campaignContainer[campaignId].name,
      });
    }
  };

  const campaignDataNotFound =
    campaignContainer.length > 0 ? (
      campaignContainer.map((campaign, index) => (
        <CampaignList
          key={index}
          {...campaign}
          selectedCampaign={selectedCampaign}
          index={index}
          handleSelection={() => handleSelectCampaign(index)}
        />
      ))
    ) : (
      <NoDataFound>No Campaign Found !</NoDataFound>
    );

  const campaignListContainer = loader ? (
    <Grid container alignItems="center" justifyContent="center">
      <CircularProgress />
    </Grid>
  ) : (
    campaignDataNotFound
  );

  const showLoader = campaginLoader ? <ButtonLoader /> : null;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      classes={{
        paper: classes.paper,
      }}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <StyledContainer container direction="column">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ overflow: 'auto', flex: '1 1 auto', height: '500px' }}
        >
          <List>{campaignListContainer}</List>
        </Grid>
        {/* <Grid item xs={12} sm={12} md={12} lg={12} style={{ flex: '1 0 auto', height: '60px' }}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={12} md={8} lg={8} style={{ display: 'flex' }}>
              <ThemeProvider
                theme={{ background: buttonTheme.buttonBgColor, color: buttonTheme.fontColor3 }}
              >
                <CustomButton
                  type="submit"
                  color="primary"
                  onClick={handleSelectCampaign}
                  disabled={selectedCampaign === null}
                >
                  Select
                  {showLoader}
                </CustomButton>
              </ThemeProvider>
            </Grid>
          </Grid>
        </Grid> */}
      </StyledContainer>
    </Popover>
  );
}

// CampaignListPopOver.propTypes = {
//   id: Proptypes.string.isRequired,
//   open: Proptypes.bool.isRequired,
//   anchorEl: Proptypes.node.isRequired,
//   handleClose: Proptypes.func.isRequired,
//   campaginLoader: Proptypes.bool.isRequired,
//   handleSelectedCampaign: Proptypes.func.isRequired,
//   contactId: Proptypes.number.isRequired,
// };

export default CampaignListPopOver;
