import { useState } from 'react';

function useShowPassword(initialState) {
  const [passwordInfo, setPasswordInfo] = useState(initialState);

  const handlePasswordViewToggle = (id) => {
    const passwordInfoCopy = { ...passwordInfo };
    passwordInfoCopy[id] = !passwordInfoCopy[id];
    setPasswordInfo(passwordInfoCopy);
  };

  return { handlePasswordViewToggle, passwordInfo };
}

export default useShowPassword;
