/* eslint-disable */
import { io } from './initSocket';

const USER_ID = JSON.parse(localStorage.getItem('USER_ID'));
const currentLocation = localStorage.getItem('SELECTED_LOCATION');

export const socketEvents = ({ socketDispatch, dispatch }) => {
  // io.socket.on('clientIsTyping', (message) => {
  //   console.log('client is typing');
  //   console.log(message);
  // });
  io.socket.on('message', (message) => {
    //console.log(message);
    io.socket.get(
      `/api/conversations-count?locationId=${currentLocation}&userId=${USER_ID}`,
      function serverResponded(body, JWR) {
        if (JWR.statusCode === 200) {
          // console.log(body);
          socketDispatch({ type: 'UPDATE_CONVERSATION_COUNTS', payload: body });
        }
      },
    );

    if (message.clientIsTyping) {
      console.log('client is typing');
    }

    if (
      message.message === 'campaign send update' &&
      message.location === Number(currentLocation)
    ) {
      //console.log(message);
      socketDispatch({ type: 'CAMPAIGN_SEND_UPDATE', payload: message });
    }

    if (message.message === 'send complete' && message.location === Number(currentLocation)) {
      console.log(message);
      socketDispatch({ type: 'CAMPAIGN_SEND_COMPLETE', payload: message });
    }

    if (message.is_delete) {
      socketDispatch({ type: 'UPDATE_AFTER_DELETE', payload: true });
    } else {
      if (message.location === Number(currentLocation) && message.isClosed === false) {
        socketDispatch({ type: 'UPDATE_CONVERSATIONS', payload: message });
        socketDispatch({ type: 'UPDATE_DOCUMENT_TITLE' });
        if (message.attributed !== USER_ID) {
          const parsedMessage = {
            ...message,
            description: `${message.name} - ${message.phone}`,
            linkTitle: 'view message',
            title: 'New Text Message',
            url: '/app/conversations',
          };
          dispatch({ type: 'UPDATE_NOTIFICATION_CONTAINER', payload: parsedMessage });
        }
      }
    }

    // business has been deleted, force logout
    if (message.message === 'force logout' && message.location === Number(currentLocation)) {
      socketDispatch({ type: 'FORCE_LOGOUT', payload: true });
    }
  });

  // console.log('socketEvents initialized');

  io.socket.on('connect', () => {
    //console.log('Connected to socketIO');

    io.socket.get(`/api/subscribeAll`, function serverResponded(body, JWR) {
      if (JWR.statusCode === 200) {
        //console.log('SUBSCRIBED!!!!');
      }
    });
  });
  io.socket.on('disconnect', function () {
    io.socket._raw.io._reconnection = true;
  });
};
