import { createTheme } from '@material-ui/core/styles';
import { useContext, useMemo, useState } from 'react';
import { GlobalContext } from '../Stores/GlobalStateStore';

const usePopOverTheme = () => {
  const { state } = useContext(GlobalContext);
  const { themeContainer } = state;
  const [theme, setTheme] = useState({});
  useMemo(() => {
    setTheme(
      createTheme({
        background: themeContainer.popoverBgColor,
      }),
    );
  }, [themeContainer]);
  return {
    popOverTheme: theme,
  };
};
export default usePopOverTheme;
