import React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import MobilePreview from '../MobilePreview/MobilePreview';
import PoweredBy from '../PoweredBy/PoweredBy';
// import EditableText from '../Editable/EditableText';
// import EditableImage from '../Editable/EditableImage';
// import EditableButton from '../Editable/EditableButton';
// import StyledTextField from '../GeneralInputField/GeneralInputField';
import { YesNo } from '../SurveyAnswers/YesNo';
import { LongAnswer } from '../SurveyAnswers/LongAnswer';
import { MultipleChoice } from '../SurveyAnswers/MultipleChoice';
import { StarRating } from '../SurveyAnswers/StarRating';
import CustomButton from '../CustomButton/CustomButton';

const StyledMobileViewContainer = styled(Grid)`
  position: absolute;
  height: 92%;
  margin: 0 !important;
  top: 40px;
  padding: 0 40px 0 30px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const StyledContentContainer = styled(Grid)`
  min-height: calc(100% - 50px);
`;

const QuestionText = styled.div`
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  text-align: left;
`;

const QuestionBlock = styled.div`
  font-size: 0.8rem;
  padding: 1rem;
  line-height: 1.2;
  min-height: 250px;
  font-weight: normal;
  border-radius: 4px;
  min-height: 200px;
  box-shadow: 0 3px 6px #00000025;
  margin: 0.5rem;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const getQuestionComponent = (type, choices) => {
  switch (type) {
    case 'yesNo':
      return <YesNo disabled style={{ rowGap: 0 }} />;
    case 'starRating':
      return <StarRating disabled />;
    case 'longAnswer':
      return <LongAnswer disabled style={{ fontSize: '.8rem' }} />;
    case 'multipleChoice':
      return <MultipleChoice disabled style={{ rowGap: 0 }} choices={choices} />;
  }
};

function QuestionView(props) {
  const { poweredBy, url, activeStep } = props;

  const type = props.slides[0].questions[0].type;
  const choice = props.slides[0].questions[0].choices;
  const question = props.slides[0].questions[0].text;

  return (
    <MobilePreview>
      <StyledMobileViewContainer
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <StyledContentContainer item xs={12} sm={12} lg={12} md={12}>
          <Grid container>
            <QuestionBlock>
              <QuestionText>{question}</QuestionText>
              {getQuestionComponent(type, choice)}
            </QuestionBlock>
            <div style={{ display: 'flex', columnGap: '10px', width: '100%', margin: '0 6px' }}>
              <CustomButton>Previous</CustomButton>
              <CustomButton>Next</CustomButton>
            </div>
          </Grid>
        </StyledContentContainer>
        <PoweredBy isPoweredBy={poweredBy} />
      </StyledMobileViewContainer>
    </MobilePreview>
  );
}

export default QuestionView;
