import React, { useState, useEffect, useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import baseUrl from '../../utils/services/config';
import { ConvertIntoTableFormat, errorChecker } from '../../utils/helper/helper';
import getLocationColumns from '../../Columns/LocationColumns';
import CusotomSearchBox from '../../components/CustomSearchBox/CustomSearchBox';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import CusotmAlert from '../../components/CustomAlert/CustomAlert';
import useAlert from '../../hooks/useAlert';
import CancellationPopup from '../../components/Popup/CancellationPopup/CancellationPopup';
import useIconTheme from '../../hooks/useIconTheme';
import ReActivePopup from '../../components/Popup/ReActivePopup/ReActivePopup';
import EditLocationPopover from '../../components/Popover/EditLocationPopover/EditLocationPopover';
import useModal from '../../hooks/useModal';
import CustomersList from '../Customers/CustomersList';
import useDebounce from '../../hooks/useDebounce';

function LocationsContainer(props) {
  const { locationStatusFilter } = props;
  const { state, dispatch } = useContext(GlobalContext);
  const { selectedBusiness, themeContainer } = state;
  const [showModal, setshowModal] = useState(false);
  const [deleteLoader, setDeleteloader] = useState(false);
  const { iconsTheme } = useIconTheme();
  const [showReactiveModal, setShowReactiveModal] = useState(false);
  const [reactiveLoader, setReactiveLoader] = useState(false);
  const [currentLocation, setCurrentLocation] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerms, setSearchTerms] = useState({
    page: 0,
    count: 0,
    perPage: 30,
  });
  const [stringSearch, setStringSearch] = useState('');

  const debouncedSearchTerm = useDebounce(stringSearch, 500);
  const [locations, setLocations] = useState([]);

  const editLocationPopover = useModal();

  const locationAlert = useAlert();

  const handleEdit = (dataIndex) => {
    setCurrentLocation(locations[dataIndex].id);
    editLocationPopover.handleShowModal();
  };

  useEffect(() => {
    localStorage.removeItem('ROW_ID');
  }, [selectedBusiness]);

  const handleDelete = (dataIndex) => {
    localStorage.setItem('delete_row_id', dataIndex);
    setshowModal(true);
  };

  const handleClose = () => {
    setshowModal(false);
    localStorage.removeItem('delete_row_id');
  };

  const showDeletionPopUp = async () => {
    const dataIndex = localStorage.getItem('delete_row_id');
    setDeleteloader(true);
    const { handleSetMessage, handleSetMessageType, handleShow } = locationAlert;
    const locationId = locations[dataIndex].id;
    const locationsContainerCopy = Array.from(locations);
    locationsContainerCopy.splice(dataIndex, 1);
    const postBody = {
      isDeleted: true,
    };
    try {
      const results = await baseUrl.patch(`/location/${locationId}`, { ...postBody });
      if (results.status === 200) {
        handleSetMessageType('success');
        handleSetMessage('Location deleted successfully');
        handleShow(true);
        handleClose();
        setLocations(locationsContainerCopy);
        setDeleteloader(false);
      }
    } catch (err) {
      setDeleteloader(false);
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const handleReactive = (dataIndex) => {
    localStorage.setItem('reActive_row_id', dataIndex);
    setShowReactiveModal(true);
  };

  const handleReActiveClose = () => {
    setShowReactiveModal(false);
    localStorage.removeItem('reActive_row_id');
  };

  const showReactivePopUp = async () => {
    const dataIndex = localStorage.getItem('reActive_row_id');
    setReactiveLoader(true);
    const { handleSetMessage, handleSetMessageType, handleShow } = locationAlert;
    const locationId = locations[dataIndex].id;
    const locationsContainerCopy = Array.from(locations);
    locationsContainerCopy.splice(dataIndex, 1);
    const postBody = {
      isDeleted: false,
    };
    try {
      const results = await baseUrl.patch(`/location/${locationId}`, { ...postBody });
      if (results.status === 200) {
        handleSetMessageType('success');
        handleSetMessage('Location Re Activated successfully');
        handleShow(true);
        handleReActiveClose();
        setLocations(Array.from(locationsContainerCopy));
      }
    } catch (err) {
      setReactiveLoader(false);
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const getLocations = () => {
    setIsLoading(true);
    const user = localStorage.getItem('USER_ID');
    const order = 'DESC';
    const deleted = locationStatusFilter;
    const { count, page, perPage } = searchTerms;
    const text = debouncedSearchTerm;
    const businessId = selectedBusiness;
    // eslint-disable-next-line max-len
    const urlParams = `user=${user}&businessId=${businessId}&deleted=${deleted}&text=${text}&order=${order}&count=${count}&page=${page}&offset=${perPage}`;
    baseUrl
      .get(`/location/list?${urlParams}`)
      .then((res) => {
        setTimeout(() => {
          setIsLoading(false);
          if (res.data.list.length) {
            setSearchTerms({
              ...searchTerms,
              page: res.data.pageMeta.currentPage + 1,
              count: res.data.pageMeta.total,
            });

            setLocations([...locations, ...Array.from(ConvertIntoTableFormat([...res.data.list]))]);
          }
        }, 500);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        setIsLoading(false);
      });
  };

  const cleanStateLocations = () => {
    setLocations([]);
  };
  const cleanSearchTerms = () => {
    setSearchTerms((prevCounter) => ({
      ...prevCounter,
      page: 0,
      count: 0,
    }));
  };
  const clearSearchParameters = () => {
    setIsLoading(true);
    setLocations([]);
    cleanSearchTerms();
    setStringSearch('');
  };
  const handleChangeSearchBox = (e) => {
    setIsLoading(true);
    setLocations([]);
    cleanSearchTerms();
    setStringSearch(e.target.value);
  };
  useEffect(() => {
    cleanSearchTerms();
    cleanStateLocations();
    getLocations();
  }, [state.selectedLoaction, locationStatusFilter]);

  useEffect(() => {
    getLocations();
  }, [debouncedSearchTerm]);
  useEffect(() => {
    getLocations();
  }, [searchTerms.count === 0 && searchTerms.page === 0]);

  return (
    <>
      <CusotmAlert
        message={locationAlert.message}
        handleClose={locationAlert.handleClose}
        open={locationAlert.show}
        messageType={locationAlert.messageType}
      />

      <ThemeProvider theme={{ color: themeContainer.tableSearchBoxColor }}>
        <CusotomSearchBox
          loader={isLoading}
          placeholder="Search…"
          handleChange={handleChangeSearchBox}
          clearSearch={clearSearchParameters}
          searchValue={stringSearch}
        />
      </ThemeProvider>

      <CustomersList
        data={locations}
        columns={getLocationColumns(
          locations,
          handleEdit,
          handleDelete,
          iconsTheme,
          handleReactive,
        )}
        getCustomers={getLocations}
        searchTerms={searchTerms}
        isLoading={isLoading}
      />
      <CancellationPopup
        show={showModal}
        showDeletionPopUp={showDeletionPopUp}
        handleClose={handleClose}
        deleteLoader={deleteLoader}
      />
      <ReActivePopup
        show={showReactiveModal}
        showReactivePopUp={showReactivePopUp}
        handleReActiveClose={handleReActiveClose}
        reactiveLoader={reactiveLoader}
      />

      <EditLocationPopover
        handleClose={editLocationPopover.handleHideShowModal}
        open={editLocationPopover.showModal}
        locationId={currentLocation}
      />
    </>
  );
}

export default LocationsContainer;
