import React, { useCallback, useState, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import PublishIcon from '@material-ui/icons/Publish';
import UploaderInput from '../UploaderInput/UploaderInput';
import UploaderPreview from '../UploaderPreview/UploaderPreview';
import { IconButton } from '@material-ui/core';
import FileUploadOutlineIcon from 'mdi-react/FileUploadOutlineIcon';

const StyledUploader = styled.div`
  width: ${(props) => (props.theme.width ? props.theme.width : '100%')};
  height: ${(props) => (props.theme.height ? props.theme.height : 'initial')};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px dashed #bfbfbf;
  border-radius: 5px;
  margin: 0.5em 0;
  cursor: pointer !important;
  text-align: ${(props) => props.theme.textAlign}!important;
  min-height: ${(props) => (props.theme.height ? props.theme.height : 'calc(100vh - 300px)')};
  position: relative;
`;

const FullScreenUploader = styled.div`
  position: absolute;
  z-index: 1;
  inset: 0;
`;

const DragOverlay = styled.div`
  position: absolute;
  inset: 0;
  background-color: #0000005c;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  justify-content: center;
  color: white;
  border-radius: 10px;
  z-index: 5;
  &::after {
    content: '';
    border: 4px dashed #fff;
    border-radius: 10px;
    position: absolute;
    inset: 2rem;
  }
`;

const StyledUploadIcon = styled(FileUploadOutlineIcon)`
  color: #888 !important;
  font-size: 24px !important;
`;

const Uploader = forwardRef((props, ref) => {
  const { name, value, title, handleGetFile, accept, icon, heading, fullScreen } = props;
  const [overlayVisible, setOverlayVisible] = useState(false);
  const inputRef = useRef(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      handleGetFile(acceptedFiles);
      setOverlayVisible(false);
    },
    [handleGetFile, setOverlayVisible],
  );

  const onDragEnter = useCallback(() => {
    setOverlayVisible(true);
  }, []);

  const onDragLeave = useCallback(() => {
    setOverlayVisible(false);
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept,
    onDragEnter,
    onDragLeave,
    noClick: true,
  });

  const handleUploaderClick = (event) => {
    // If the click event originated from the input element, do not prevent propagation
    if (event.target === inputRef.current) return;

    // Prevent propagation for all other click events to avoid activating the dropzone
    event.stopPropagation();
  };

  const uploaderView =
    value !== undefined ? (
      <UploaderPreview value={value} type="file" />
    ) : (
      <UploaderInput title={title} icon={icon} heading={heading} />
    );

  return fullScreen ? (
    <FullScreenUploader ref={ref} {...getRootProps()}>
      <input {...getInputProps()} name={name} ref={inputRef} />
      <IconButton title="Upload CSV file" onClick={open}>
        <StyledUploadIcon />
      </IconButton>
      {overlayVisible ? <DragOverlay>Drop to upload</DragOverlay> : null}
    </FullScreenUploader>
  ) : (
    <StyledUploader ref={ref} {...getRootProps()} onClick={handleUploaderClick}>
      <input {...getInputProps()} name={name} ref={inputRef} />
      {uploaderView}
      {overlayVisible ? <DragOverlay>Drop to upload</DragOverlay> : null}
    </StyledUploader>
  );
});

Uploader.propTypes = {
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func,
  value: PropTypes.string,
  uploaderText: PropTypes.string,
  filesLimit: PropTypes.number,
  title: PropTypes.string,
  handleGetFile: PropTypes.func.isRequired,
  accept: PropTypes.string,
  heading: PropTypes.string,
  icon: PropTypes.node,
  fullScreen: PropTypes.bool,
};

Uploader.defaultProps = {
  uploaderText: 'Upload or Click',
  filesLimit: 1,
  title: 'Upload or select',
  accept: null,
  heading: '',
  icon: <PublishIcon />,
  fullScreen: false,
};

export default React.memo(Uploader);
