import React from 'react';
import Proptypes from 'prop-types';
import { FormHelperText, Grid } from '@material-ui/core';
import { ThemeProvider } from 'styled-components';
import { Controller } from 'react-hook-form';
import FieldGroupLable from '../../FieldGroupLable/FieldGroupLable';
import Dropdown from '../../Dropdown/Dropdown';
import StyledFormControl from '../../StyledFormControl';

const dropDownTheme = {
  maxWidth: '100%',
  minWidth: '100%',
};

const getRatings = (rating, operation = 'max') => {
  const ratings = [
    { label: '1 Star', value: 1 },
    { label: '2 Star', value: 2 },
    { label: '3 Star', value: 3 },
    { label: '4 Star', value: 4 },
    { label: '5 Star', value: 5 },
  ];
  if (rating) {
    if (operation === 'min') {
      return ratings.filter((item) => item.value < rating);
    }
    if (operation === 'max') {
      return ratings.filter((item) => item.value > rating);
    }
    return ratings;
  }
  return ratings;
};

function RatingFilter(props) {
  const { errors, control, required, maxRating, minRating } = props;
  const astrisk = required ? '*' : '';
  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Grid container justify="space-around">
        <FieldGroupLable item xs={12} sm={12} md={12} lg={12}>
          Min Rating
        </FieldGroupLable>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ThemeProvider theme={dropDownTheme}>
            <StyledFormControl error={!!errors.minRating}>
              <Controller
                as={
                  <Dropdown
                    listOptions={Array.from(getRatings(maxRating, 'min'))}
                    label={`Select Min Rating ${astrisk}`}
                  />
                }
                name="minRating"
                control={control}
                rules={{ required }}
                defaultValue=""
              />
              {errors.minRating ? (
                <FormHelperText>Min Rating is a required field</FormHelperText>
              ) : null}
            </StyledFormControl>
          </ThemeProvider>
        </Grid>
        <FieldGroupLable item xs={12} sm={12} md={12} lg={12}>
          Max Rating
        </FieldGroupLable>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ThemeProvider theme={dropDownTheme}>
            <StyledFormControl error={!!errors.maxRating}>
              <Controller
                as={
                  <Dropdown
                    listOptions={Array.from(getRatings(minRating))}
                    label={`Select Max Rating ${astrisk}`}
                  />
                }
                name="maxRating"
                control={control}
                rules={{ required }}
                defaultValue=""
              />
              {errors.maxRating ? (
                <FormHelperText>Max Rating is a required field</FormHelperText>
              ) : null}
            </StyledFormControl>
          </ThemeProvider>
        </Grid>
      </Grid>
    </Grid>
  );
}

RatingFilter.propTypes = {
  errors: Proptypes.objectOf(Proptypes.string).isRequired,
  control: Proptypes.node.isRequired,
  required: Proptypes.bool,
  maxRating: Proptypes.number,
  minRating: Proptypes.number,
};

RatingFilter.defaultProps = {
  required: true,
  maxRating: null,
  minRating: null,
};

export default RatingFilter;
