import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import BubbleReview from './BubbleReview';
import DefaultReview from './DefaultReview';
import Masonry from 'react-masonry-css';

const ReviewsMasonry = (props) => {
  const { reviews, settings, postSites } = props;

  const useStyles = makeStyles((theme) => ({
    Review__Container: {
      backgroundColor: `${settings.colors.containerBgColor}${settings.colors.containerBgOpacity}`,
      position: 'relative',
      padding: '24px',
      transition: 'background-color 0.1s ease 0s',
      borderRadius: `${settings.radius}px`,
      display: 'flex',
      flexDirection: 'column',
    },

    Review__Bubble: {
      marginBottom: `${settings.widgetStyle === 'grid' ? '' : settings.gap + 'px'}`,
    },
    Review__Container_Bubble: {
      backgroundColor: `${settings.colors.containerBgColor}${settings.colors.containerBgOpacity}`,
      position: 'relative',
      padding: '24px',
      transition: 'background-color 0.1s ease 0s',
      borderRadius: `${settings.radius}px`,
      display: 'flex',
      flexDirection: 'column',
    },
    my_masonry_grid: {
      display: 'flex',
      marginLeft: '-' + settings.gap + 'px' /* gutter size offset */,
      //width: 'auto',
    },
    my_masonry_grid_column: {
      borderLeft: settings.gap + 'px solid transparent' /* gutter size */,
      backgroundClip: 'padding-box',
    },

    /* Style your items */
    // my_masonry_grid_column > div { /* change div to reference your elements you put in <Masonry> */
    //   background: grey;
    //   margin-bottom: 30px;
    // }
  }));

  const classes = useStyles();

  const getReviewStyle = (review) => {
    if (settings.reviewStyle === 'bubble') {
      return (
        <div className={classes.Review__Bubble}>
          <BubbleReview review={review} settings={settings} postSites={postSites} />
        </div>
      );
    } else {
      return (
        // <div className={classes.Review__Container}>
        <DefaultReview review={review} settings={settings} postSites={postSites} />
        // </div>
      );
    }
  };

  const allReviews = reviews && reviews.map((review, index) => getReviewStyle(review));

  const bpColsBubble = {
    default: 4,
    1965: 3,
    1585: 2,
    1200: 1,
  };

  const bpColsDefault = {
    default: 4,
    2442: 3,
    1943: 2,
    1436: 1,
  };

  return (
    <Masonry
      breakpointCols={settings.reviewStyle === 'bubble' ? bpColsBubble : bpColsDefault}
      className={classes.my_masonry_grid}
      columnClassName={classes.my_masonry_grid_column}
    >
      {allReviews}
    </Masonry>
  );
};

export default ReviewsMasonry;
