import React, { useContext, useState, useEffect } from 'react';
import Proptypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { Grid, ThemeProvider as MUIThemeProvider } from '@material-ui/core';
import StyledEditIcon from '../Icons/EditIcon';
import CustomButton from '../CustomButton/CustomButton';
import TransperentButtonTheme from '../../themes/Button/TransperentButtonTheme';
// import StyledCloseIcon from '../Icons/CloseIcon';
import StyledTextField from '../GeneralInputField/GeneralInputField';
import { OnBoardingContext } from '../../Stores/OnBoarding';
import useTextColors from '../../hooks/useTextColors';
import useInputFieldTheme from '../../hooks/useInputFieldTheme';

const StyledTextContainer = styled(Grid)`
  position: relative !important;
  display: block;
  word-break: break-all;
  &:hover {
    & > div {
      border: 1px dotted #ccc;
    }
    & > span {
      display: block;
    }
  }
`;

const StyledEditIconContainer = styled.span`
  position: absolute;
  display: ${(props) => props.theme.displayStyle};
  right: 0;
`;

const StyledWelcomeText = styled(Grid)`
  font-size: ${(props) => (props.theme.fontSize ? props.theme.fontSize : '12px')} !important;
  color: ${(props) => (props.theme.color ? props.theme.color : '#7c7c7c')} !important;
  margin: ${(props) => (props.theme.margin ? props.theme.margin : '1.5em 0')} !important;
  border: 1px dotted transparent;
  justify-content: center !important;
`;

const buttonAdditionalStyle = {
  minWidth: '20px',
  maxWidth: '20px',
  padding: '0',
};

const editIconStyle = {
  fontSize: '16px',
};

const displayNone = {
  displayStyle: 'none',
};

const displayBlock = {
  displayStyle: 'block',
};

const inputFieldStyle = {
  padding: '0',
  fontSize: '12px',
  textAlign: 'center',
};

function EditableText(props) {
  const { activeStep, radioValue, isEditable, id, welcomeTheme } = props;
  const { onBoardingDispatch, onBoardingData } = useContext(OnBoardingContext);
  const { defaultPortals } = onBoardingData;
  const [isEdit, setIsEdit] = useState(false);
  const [editValue, setEditValue] = useState(defaultPortals[radioValue].screens[activeStep][id]);
  const { textColors } = useTextColors();
  const { inputTheme } = useInputFieldTheme();
  const handleEdit = () => setIsEdit(true);
  const handleChange = (e) => {
    setEditValue(e.target.value);
  };

  useEffect(() => {
    if (defaultPortals[radioValue].screens[activeStep][id]) {
      setEditValue(defaultPortals[radioValue].screens[activeStep][id]);
    }
  }, [defaultPortals[radioValue].screens[activeStep][id]]);

  const handleBlur = () => {
    setIsEdit(false);
    const defaultPortalCopy = { ...defaultPortals };
    defaultPortalCopy[radioValue].screens[activeStep][id] = editValue;
    onBoardingDispatch({ type: 'SET_DEFAULT_PORTAL', payload: { ...defaultPortalCopy } });
  };

  const iconContainer =
    isEdit || !isEditable ? null : (
      <ThemeProvider theme={editIconStyle}>
        <StyledEditIcon />
      </ThemeProvider>
    );

  const textContainer =
    isEdit && isEditable ? (
      <MUIThemeProvider theme={inputTheme}>
        <ThemeProvider theme={inputFieldStyle}>
          <StyledTextField
            value={editValue}
            onChange={handleChange}
            onBlur={handleBlur}
            autoFocus={isEdit}
            multiline
            rowsMax={4}
          />
        </ThemeProvider>
      </MUIThemeProvider>
    ) : (
      <ThemeProvider theme={{ ...welcomeTheme, color: textColors.fontColor3 }}>
        <StyledWelcomeText container>
          {defaultPortals[radioValue].screens[activeStep][id]}
        </StyledWelcomeText>
      </ThemeProvider>
    );

  return (
    <StyledTextContainer item xs={12} sm={12} lg={12} md={12}>
      <StyledEditIconContainer theme={isEdit ? displayBlock : displayNone}>
        <ThemeProvider theme={{ ...TransperentButtonTheme, ...buttonAdditionalStyle }}>
          <CustomButton onClick={handleEdit}>{iconContainer}</CustomButton>
        </ThemeProvider>
      </StyledEditIconContainer>
      {textContainer}
    </StyledTextContainer>
  );
}

EditableText.propTypes = {
  activeStep: Proptypes.number.isRequired,
  radioValue: Proptypes.string.isRequired,
  isEditable: Proptypes.bool,
  id: Proptypes.string,
  welcomeTheme: Proptypes.objectOf(Proptypes.string),
};

EditableText.defaultProps = {
  isEditable: true,
  id: 'title',
  welcomeTheme: {},
};

export default EditableText;
