import React from 'react';
import Proptypes from 'prop-types';
import { Grid } from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import MobilePreview from '../MobilePreview/MobilePreview';
import CustomButton from '../CustomButton/CustomButton';
import StyledAddCircleIcon from '../Icons/AddCircle';
import PoweredBy from '../PoweredBy/PoweredBy';
import EditableText from '../Editable/EditableText';
import EditableImage from '../Editable/EditableImage';
import TransperentButtonTheme from '../../themes/Button/TransperentButtonTheme';
import usePopover from '../../hooks/usePopover';
import AddNetworkPopover from '../Popover/AddNetworkPopover/AddNetworkPopover';
import StyledTrashIcon from '../Icons/TrashIcon';

const StyledMobileViewContainer = styled(Grid)`
  position: absolute;
  height: 92%;
  margin: 0 !important;
  top: 40px;
  padding: 0 40px !important;

  overflow-y: auto !important;
  overflow-x: hidden !important;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const StyledContentContainer = styled(Grid)`
  min-height: calc(100% - 50px);
`;

const StyledSiteInitials = styled(Grid)`
  font-size: 16px;
  font-weight: 700;
  text-transform: none;
  font-family: Montserrat;
`;

const addNetworkTheme = {
  fontSize: '10px',
  background: 'transparent',
  fontWeight: '600',
  color: '#A7A7A7',
  margin: '2em 0 ',
};

const textFeedbackTheme = {
  fontSize: '12px',
  background: '#A7A7A7',
  fontWeight: '600',
  color: '#fff',
  margin: '0.5em',
};

const StyledText = styled(Grid)`
  color: #6c6c6c !important;
  font-size: 12px !important;
`;

const StyledGrid = styled(Grid)`
  position: relative;
`;

function PositiveFeedback(props) {
  const { poweredBy, postSites, allowTextFeedback, url, handleRemovePostSite } = props;
  const addNetworkPopover = usePopover();
  const dynamicBtn = postSites.map((item, index) => {
    const extraBtnTheme = {
      background: item.backgroundColor,
      backgroundSize: '20px',
      borderRadis: '60px',
      fontSize: '18px !important',
      fontWeight: 'bold',
      color:
        item.backgroundColor === '#ffffff' || item.backgroundColor === '#fafafa' ? '#000' : '#fFF',
      margin: '10px auto 0 0',
      width: '80%',
      position: 'relative',
      padding: '6px 8px',
    };

    return (
      <Grid item xs={12} sm={12} lg={12} md={12}>
        <StyledGrid container>
          <Grid item xs={12} sm={12} lg={12} md={12}>
            <ThemeProvider theme={extraBtnTheme}>
              <CustomButton>
                <Grid container alignItems="center">
                  <Grid item xs={2} sm={2} lg={2} md={2}>
                    <StyledSiteInitials container>{item.name.slice(0, 1)}</StyledSiteInitials>
                  </Grid>
                  <Grid item xs={8} sm={8} lg={8} md={8}>
                    {item.name}
                  </Grid>
                  <Grid item xs={2} sm={2} lg={2} md={2} />
                </Grid>
              </CustomButton>
            </ThemeProvider>
          </Grid>
          <Grid item style={{ position: 'absolute', right: 0, top: -1 }}>
            <ThemeProvider
              theme={{
                background: 'transparent',
                width: '20px',
                height: '20px',
                maxWidth: '20px',
                minWidth: '20px',
              }}
            >
              <CustomButton onClick={() => handleRemovePostSite(props, index)}>
                <ThemeProvider theme={{ color: 'tomato' }}>
                  <StyledTrashIcon />
                </ThemeProvider>
              </CustomButton>
            </ThemeProvider>
          </Grid>
        </StyledGrid>
      </Grid>
    );
  });

  const allowTextFeedbackContent = allowTextFeedback ? (
    <>
      <StyledText item xs={12} sm={12} lg={12} md={12}>
        or
      </StyledText>
      <Grid item xs={12} sm={12} lg={12} md={12}>
        <ThemeProvider theme={textFeedbackTheme}>
          <CustomButton>Text us feedback</CustomButton>
        </ThemeProvider>
      </Grid>
    </>
  ) : null;

  const imageContainer = url ? <EditableImage {...props} isEditable={false} /> : null;

  return (
    <MobilePreview>
      <StyledMobileViewContainer container direction="row" justify="center" alignItems="center">
        <StyledContentContainer item xs={12} sm={12} lg={12} md={12}>
          <Grid container style={{ textAlign: 'center' }}>
            <Grid item xs={12} sm={12} lg={12} md={12}>
              {imageContainer}
            </Grid>
            <EditableText {...props} />
            {dynamicBtn}
            <Grid item xs={12} sm={12} lg={12} md={12}>
              <ThemeProvider theme={addNetworkTheme}>
                <CustomButton>
                  <ThemeProvider theme={TransperentButtonTheme}>
                    <CustomButton onClick={addNetworkPopover.handleClick}>
                      <Grid container alignItems="center">
                        <Grid item xs={2} sm={2} lg={2} md={2}>
                          <Grid container>
                            <StyledAddCircleIcon />
                          </Grid>
                        </Grid>
                        <Grid item xs={10} sm={10} lg={10} md={10}>
                          Add another network
                        </Grid>
                      </Grid>
                    </CustomButton>
                  </ThemeProvider>
                </CustomButton>
              </ThemeProvider>
            </Grid>
            {allowTextFeedbackContent}
          </Grid>
        </StyledContentContainer>
        <PoweredBy isPoweredBy={poweredBy} />
      </StyledMobileViewContainer>
      <AddNetworkPopover
        anchorEl={addNetworkPopover.anchorEl}
        handleClose={addNetworkPopover.handleClose}
        id={addNetworkPopover.id}
        open={addNetworkPopover.open}
        {...props}
      />
    </MobilePreview>
  );
}

PositiveFeedback.propTypes = {
  images: Proptypes.arrayOf(Proptypes.string),
  poweredBy: Proptypes.bool,
  title: Proptypes.string.isRequired,
  postSites: Proptypes.arrayOf(Proptypes.object),
  allowTextFeedback: Proptypes.bool,
  url: Proptypes.string,
  handleRemovePostSite: Proptypes.func.isRequired,
};

PositiveFeedback.defaultProps = {
  poweredBy: true,
  postSites: [],
  images: '',
  allowTextFeedback: false,
  url: '',
};

export default PositiveFeedback;
