export const getPostLink = (source, postSites) => {
  let finalSource;
  if (source.toLowerCase() === 'google') finalSource = 'maps.google';
  const postReviewLink = postSites.filter((site) =>
    site.listReviewsLink.includes(finalSource ? finalSource : source.toLowerCase() + '.com'),
  );

  return postReviewLink[0]?.postReviewLink;
};

export const getReviewLink = (source, postSites) => {
  let finalSource;
  if (source.toLowerCase() === 'google') finalSource = 'maps.google';
  const postReviewLink = postSites.filter((site) =>
    site.listReviewsLink.includes(finalSource ? finalSource : source.toLowerCase() + '.com'),
  );

  return postReviewLink[0]?.listReviewsLink;
};

export function generateSourcesList(reviews, postSites) {
  const sources = {};
  let allCount = 0;
  let allTotal = 0;

  reviews.forEach((review) => {
    let source = review.source;
    const rating = wordToNumber(review.rating);

    if (sources[source]) {
      sources[source].name = source;
      sources[source].count++;
      sources[source].total += rating;
      sources[source].average = (sources[source].total / sources[source].count).toFixed(1);
      sources[source].postReviewLink = getPostLink(source, postSites);
    } else {
      sources[source] = {
        count: 1,
        total: rating,
        average: rating,
      };
    }

    allCount++;
    allTotal += rating;
  });

  const allAverageRating = (allTotal / allCount).toFixed(1);

  return { allAverageRating: allAverageRating, allCount: allCount, allTotal: allTotal, ...sources };
}

export const getSource = (title) => {
  const url = 'https://reveo.com/images/review_icons/';
  const logoUrl = 'https://reveo.com/images/review_icons/logos/';

  switch (title.toLowerCase()) {
    case 'all':
      return { title: 'Overall Rating', logo: null, icon: null };
    case 'apartments':
      return {
        title: 'Apartments',
        logo: logoUrl + 'apartments.png',
        icon: url + 'apartments.jpg',
      };
    case 'apartmentratings':
      return {
        title: 'Apartment Ratings',
        logo: logoUrl + 'apartmentratings.svg',
        icon: url + 'apartment_ratings.png',
      };
    case 'apartmentguide':
      return {
        title: 'Apartmentguide',
        logo: logoUrl + 'apartmentguide.png',
        icon: url + 'apartment_guide.png',
      };
    case 'dealerrater':
      return {
        title: 'Dealer Rater',
        logo: logoUrl + 'dealerrater.svg',
        icon: url + 'dealer_rater.svg',
      };
    case 'avvo':
      return {
        title: 'Avvo',
        logo: logoUrl + 'avvo.svg',
        icon: url + 'avvo.png',
      };
    case 'cargurus':
      return {
        title: 'CarGurus',
        logo: logoUrl + 'cargurus.svg',
        icon: url + 'car_gurus.png',
      };
    case 'caredash':
      return {
        title: 'CareDash',
        logo: logoUrl + 'caredash.png',
        icon: url + 'caredash.png',
      };
    case 'cars':
      return { title: 'Cars', logo: logoUrl + 'carslogo.png', icon: url + 'cars.svg' };
    case 'facebook':
      return {
        title: 'Facebook',
        logo: logoUrl + 'facebook.svg',
        icon: url + 'facebook.png',
      };
    case 'google':
      return {
        title: 'Google',
        logo: logoUrl + 'google.svg',
        icon: url + 'google.svg',
      };
    case 'glassdoor':
      return {
        title: 'Glassdoor',
        logo: logoUrl + 'glassdoor.svg',
        icon: url + 'glassdoor.png',
      };
    case 'healthgrades':
      return {
        title: 'Healthgrades',
        logo: logoUrl + 'healthgrades.svg',
        icon: url + 'healthgrades.jpg',
      };
    case 'homeadvisor':
      return {
        title: 'HomeAdvisor',
        logo: logoUrl + 'homeadvisor.svg',
        icon: url + 'homeadvisor.svg',
      };
    case 'indeed':
      return { title: 'Indeed', logo: logoUrl + 'indeed.svg', icon: url + 'indeed.png' };
    case 'thumbtack':
      return { title: 'Thumbtack', logo: logoUrl + 'thumbtack.svg', icon: url + 'thumbtack.png' };
    case 'tripadvisor':
      return {
        title: 'Tripadvisor',
        logo: logoUrl + 'tripadvisor.svg',
        icon: url + 'tripadvisor.jpg',
      };
    case 'twitter':
      return { title: 'Twitter', logo: logoUrl + 'twitter.svg', icon: url + 'twitter.jpg' };
    case 'webmd':
      return { title: 'WebMD', logo: logoUrl + 'webmd.svg', icon: url + 'webmd.jpg' };
    case 'wellness':
      return { title: 'Wellness', logo: logoUrl + 'wellness.png', icon: url + 'wellness.png' };
    case 'yelp':
      return { title: 'Yelp', logo: logoUrl + 'yelp.svg', icon: url + 'yelp.png' };
    case 'zillow':
      return { title: 'Zillow', logo: logoUrl + 'zillow.svg', icon: url + 'zillow.jpg' };
    case 'zocdoc':
      return { title: 'ZocDoc', logo: logoUrl + 'zocdoc.png', icon: url + 'zoc_doc.png' };

    default:
      return 'Unknown';
  }
};

export function formatDate(dateString) {
  const date = new Date(dateString);
  const currentDate = new Date();
  const diffTime = Math.abs(currentDate - date);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays === 1) {
    return '1 day ago';
  } else if (diffDays <= 7) {
    return `${diffDays} days ago`;
  } else if (date.getFullYear() === currentDate.getFullYear()) {
    return date.toLocaleString('en-us', { month: 'short', day: 'numeric' });
  } else {
    return date.toLocaleString('en-us', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }
}

//Calculate Brighter color
export function increase_brightness(hex, percent) {
  // strip the leading # if it's there
  hex = hex.replace(/^\s*#|\s*$/g, '');

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (hex.length == 3) {
    hex = hex.replace(/(.)/g, '$1$1');
  }

  var r = parseInt(hex.substr(0, 2), 16),
    g = parseInt(hex.substr(2, 2), 16),
    b = parseInt(hex.substr(4, 2), 16);

  return (
    '#' +
    (0 | ((1 << 8) + r + ((256 - r) * percent) / 100)).toString(16).substr(1) +
    (0 | ((1 << 8) + g + ((256 - g) * percent) / 100)).toString(16).substr(1) +
    (0 | ((1 << 8) + b + ((256 - b) * percent) / 100)).toString(16).substr(1)
  );
}

export function wordToNumber(value) {
  const ratingMap = {
    ZERO: 0,
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
  };

  return ratingMap[value.toUpperCase()];
}

export function wordToStars(word, settings, textColor, backgroundColor) {
  const ratingMap = {
    ZERO: 0,
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
  };

  const rating = ratingMap[word.toUpperCase()];
  return makeStars(rating, 15, settings, textColor, backgroundColor);
}

export function makeStars(rating, size, settings, textColor, backgroundColor) {
  const starSize = `height="${size}px" width="${size}px"`;
  let color;

  let stars = '';
  const filledStar = (color, starSize) => {
    return `<svg xmlns="http://www.w3.org/2000/svg" ${starSize} viewBox="0 0 1000 1000">
        <path
          fill=${color}
          d="M990,394.9C990,403.5 984.9,413 974.7,423.2L760.9,631.6L811.6,926.1C812,928.8 812.2,932.8 812.2,937.9C812.2,946.1 810.1,953.1 806,958.8C801.9,964.5 795.9,967.3 788,967.3C780.5,967.3 772.7,964.9 764.4,960.2L500,821.3L235.6,960.3C227,965 219.1,967.4 212,967.4C203.8,967.4 197.6,964.6 193.4,958.9C189.2,953.2 187.2,946.2 187.2,938C187.2,935.6 187.6,931.7 188.4,926.2L239,631.7L24.7,423.1C14.9,412.5 10,403.1 10,394.8C10,380.3 21,371.2 43,367.7L338.6,324.7L471.1,56.7C478.6,40.6 488.2,32.6 500,32.6C511.8,32.6 521.4,40.6 528.9,56.7L661.4,324.7L957,367.7C979,371.3 990,380.3 990,394.9Z"
        />
      </svg>`;
  };

  const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  //svgElement.setAttribute("viewBox", "0 0 1000 1000");
  svgElement.innerHTML = filledStar;

  const maxStars = 5;
  const numStars = parseInt(rating);

  for (let i = 1; i <= maxStars; i++) {
    if (i <= numStars) {
      color = '#fcbf02';
      stars += filledStar(color, starSize);
    } else {
      color = settings.isLight
        ? increase_brightness(textColor, 75)
        : increase_brightness(backgroundColor, 40);
      stars += filledStar(color, starSize);
    }
  }

  return stars;
}
