import React from 'react';
import styled from 'styled-components';
import { makeStyles, Popover, MenuItem, ListItemIcon, Paper, MenuList } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: 'hidden',
    background: theme.background,
  },
}));

const StyledListItemText = styled.div`
  font-family: 'Montserrat' !important;
  font-weight: 600;
  margin: 0.2rem 0;
`;

function CampaignPopover(props) {
  const classes = useStyles();
  const { id, open, anchorEl, handleClose, optionsOnPress, idSelected } = props;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      classes={{
        paper: classes.paper,
      }}
    >
      <Paper sx={{ width: 320, maxWidth: '100%' }}>
        <MenuList dense onClick={handleClose}>
          {optionsOnPress.map((option) => {
            const { Icon } = option;
            return (
              <MenuItem onClick={() => option.onPress(idSelected)} key={option.text}>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <StyledListItemText>{option.text}</StyledListItemText>
              </MenuItem>
            );
          })}
        </MenuList>
      </Paper>
    </Popover>
  );
}

export default CampaignPopover;
