import styled from 'styled-components';
import LaptopIcon from '@material-ui/icons/Laptop';

const StyledLaptopIcon = styled(LaptopIcon)`
  color: ${(props) => props.theme.color} !important;
  font-size: ${(props) => props.theme.fontSize} !important;
  opacity: ${(props) => props.theme.opacity} !important;
`;

export default StyledLaptopIcon;
