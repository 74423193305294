const dayFrequency = [
  { label: 'None', value: '0' },
  {
    label: '30 days',
    value: '30',
  },
  {
    label: '60 days',
    value: '60',
  },
  {
    label: '90 days',
    value: '90',
  },
];

// Used in conversation summary and leaderboard filter
export const filterFrequency = [
  {
    label: 'All time',
    value: '',
  },
  {
    label: '7 days',
    value: '7',
  },
  {
    label: '1 Month',
    value: '30',
  },
  {
    label: '2 Month',
    value: '60',
  },
  {
    label: '3 Month',
    value: '90',
  },
  {
    label: '6 Month',
    value: '180',
  },
  {
    label: '1 Year',
    value: '365',
  },
];

export default dayFrequency;
