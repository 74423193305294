import React, { useCallback, useContext, useEffect, useState } from 'react';
// import Proptypes from 'prop-types';
import { FormHelperText, Grid } from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import FieldGroupLable from '../../FieldGroupLable/FieldGroupLable';
import StyledTextField from '../../GeneralInputField/GeneralInputField';
import Dropdown from '../../Dropdown/Dropdown';
import CustomButton from '../../CustomButton/CustomButton';
import defaultDropDownTheme from '../../../themes/Dropdown/DefaultDropdownTheme';
import MultiSelect from '../../MultiSelect/MultiSelect';
import GenricPopup from '../../Popup/GenricPopup';
import PageTitle from '../../PageTitle/PageTitle';
import useModal from '../../../hooks/useModal';
import { GlobalContext } from '../../../Stores/GlobalStateStore';
import baseUrl from '../../../utils/services/config';
import {
  ConvertIntoGroupNameDropDown,
  ConvertIntoUserDropDown,
  errorChecker,
  getArrayOfObjectForm,
  getCurrentAndPreviousDate,
} from '../../../utils/helper/helper';
import CusotmAlert from '../../CustomAlert/CustomAlert';
import useAlert from '../../../hooks/useAlert';
import useFetch from '../../../hooks/useFetch';
import ButtonLoader from '../../ButtonLoader/ButtonLoader';
import SentimentFilter from '../Sentiment';
import YesNoFilter from '../YesNo';
import RatingFilter from '../Rating';
import StyledFormControl from '../../StyledFormControl';
import useDebounce from '../../../hooks/useDebounce';

const StyledGroupFilterContainer = styled(Grid)`
  background: #ccc !important;
  padding: 0 !important;
  margin: 0 !important;
  padding: 1em !important;
  overflow-y: auto !important;
  height: 100% !important;
`;

const StyledTimeLapsedContainer = styled(Grid)`
  letter-spacing: 0.28px;
  color: #888888;
  opacity: 0.5;
  font-size: 12px;
`;

const dropDownTheme = {
  maxWidth: '100%',
  minWidth: '100%',
};

const inputTheme = {
  inputMargin: '0',
};

const reviewResults = [
  { value: 'feedback', label: 'Left Written Review' },
  { value: 'recommended', label: 'Recommended' },
  { value: 'not_recommended', label: 'Not Recommended' },
];

const surveyResults = [{ value: 'feedback', label: 'Left Written Review' }];

const notificationResults = [
  { value: 'feedback', label: 'Left Written Review' },
  { value: 'subscribe', label: 'Opt In' },
  { value: 'not_subscribe', label: 'Opt Out' },
  { value: 'recommended', label: 'Confirmed' },
  { value: 'not_recommended', label: 'Not Confirmed' },
];

const reviewFeedbackStage = [
  { value: 'opened', label: 'Opened' },
  { value: 'google', label: 'Review At Google' },
  { value: 'facebook', label: 'Review At Facebook' },
  { value: 'reveo', label: 'Review At Reveo' },
];

const surveyFeedbackStage = [
  { value: 'opened', label: 'Opened' },
  { value: 'reveo', label: 'Review At Reveo' },
];

const notificationFeedbackStage = [
  { value: 'opened', label: 'Opened' },
  { value: 'reveo', label: 'Review At Reveo' },
];

const feedbackType = [
  { value: 'review', label: 'Review' },
  { value: 'survey', label: 'Survey' },
  { value: 'notification', label: 'Notification' },
];

const timeSpan = [{ value: 'last', label: 'Last' }];

const duration = [
  { value: 'days', label: 'Days' },
  { value: 'month', label: 'Month' },
  { value: 'year', label: 'Year' },
];

function GroupFilter() {
  const groupModal = useModal();
  const groupFilterAlert = useAlert();
  const [groupName, setGroupName] = useState('');
  const [groupNameError, setGroupNameError] = useState(false);
  const [userContainer, setUserContainer] = useState([]);
  const { state, dispatch } = useContext(GlobalContext);
  const { groupContainer, selectedBusiness, selectedGroupId } = state;
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);
  const [loader, setLoader] = useState(false);
  const [timeStamp, setTimeStamp] = useState(null);
  const [recommended, setRecommedned] = useState(null);
  const [sentiment, setSentiment] = useState(null);
  const [recommendedError, setRecommednedError] = useState(null);
  const [sentimentError, setSentimentError] = useState(null);
  const [filterLoader, setFilterLoader] = useState(false);
  const { fetchGroupName } = useFetch();
  const debouncedSearchTerm = useDebounce(groupName, 500);

  const { handleSubmit, control, errors, watch, register } = useForm();

  const watchDurationChange = watch('duration');

  const watchTypedDurationChange = watch('typedDuration');

  // Fetch user list API method
  const fetchUsers = useCallback(() => {
    if (selectedBusiness) {
      baseUrl
        .get(`/business/${selectedBusiness}/user?isDeleted=false`)
        .then((res) => {
          if (res.status === 200) {
            setUserContainer(Array.from(ConvertIntoUserDropDown(res.data)));
          }
        })
        .catch((err) => {
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    }
  }, [selectedBusiness, dispatch]);

  // Effect to fetchGroupName

  useEffect(() => {
    fetchGroupName();
    fetchUsers();
  }, []);

  const handleSaveGroup = useCallback(async () => {
    try {
      setLoader(true);
      const postBody = {
        groupname: groupName,
        business: selectedBusiness,
        clients: getArrayOfObjectForm(groupContainer.listViewSelectedContact, 'id'),
      };
      const result = await baseUrl.post(`/group/client/create`, { ...postBody });
      if (result.status === 200) {
        dispatch({
          type: 'SET_SELECTED_GROUP_INFO',
          payload: { id: null, name: groupName, totalmembers: result.data.group.list.length },
        });
        dispatch({
          type: 'SET_IS_GROUP_SAVED',
          payload: true,
        });
        dispatch({ type: 'SET_SELECTED_GROUP_ID', payload: result.data.groupId });
        const { handleSetMessage, handleSetMessageType, handleShow } = groupFilterAlert;
        groupModal.handleHideShowModal();
        handleShow();
        handleSetMessageType('success');
        handleSetMessage(result.data.message);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      dispatch({
        type: 'SET_IS_GROUP_SAVED',
        payload: false,
      });
      setLoader(false);
    }
  }, [
    dispatch,
    groupContainer.listViewSelectedContact,
    groupFilterAlert,
    groupModal,
    groupName,
    selectedBusiness,
  ]);

  const handleUpdateGroup = useCallback(async () => {
    try {
      const postBody = {
        groupid: selectedGroupId,
        business: selectedBusiness,
        clients: getArrayOfObjectForm(groupContainer.listViewSelectedContact, 'id'),
      };
      const result = await baseUrl.post(`/group/${selectedGroupId}/update`, { ...postBody });
      if (result.status === 200) {
        const { handleSetMessage, handleSetMessageType, handleShow } = groupFilterAlert;
        groupModal.handleHideShowModal();
        handleShow();
        handleSetMessageType('success');
        handleSetMessage(result.data.message);
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  }, [
    dispatch,
    groupContainer.listViewSelectedContact,
    groupFilterAlert,
    groupModal,
    selectedBusiness,
    selectedGroupId,
  ]);

  const validateGroupName = async () => {
    try {
      const result = await baseUrl.get(`business/${selectedBusiness}/groups?name=${groupName}`);
      if (result.status === 200) {
        if (result.data.length) {
          setGroupNameError(true);
        } else {
          setGroupNameError(false);
          setDisableSaveBtn(false);
        }
      }
    } catch (err) {
      setGroupNameError(false);
      setDisableSaveBtn(false);
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  useEffect(() => {
    validateGroupName();
  }, [debouncedSearchTerm]);

  const handleGroupNameChange = (e) => {
    setGroupName(e.target.value.trim());
    setDisableSaveBtn(true);
    setGroupNameError(false);
  };

  const handleMultiSelectChange = (e) => {
    dispatch({ type: 'SET_SELECTED_GROUP_NAME', payload: e.target.value });
  };

  // useEffect(() => {
  //   fetchSelectedGroupClients();
  // }, [fetchSelectedGroupClients]);

  const handleGroupSave = () => {
    if (selectedGroupId !== null && selectedGroupId >= 0) {
      handleUpdateGroup();
    } else {
      groupModal.handleShowModal();
    }
  };

  const handleFilterPopupClose = () => {
    groupModal.handleHideShowModal();
    setGroupNameError(false);
  };

  const watchFeedbackType = watch('feedbackType');
  const minRating = watch('minRating');
  const maxRating = watch('maxRating');

  const getConditionalFilter = (type) => {
    switch (type) {
      case 'survey':
        return (
          <>
            <RatingFilter
              errors={errors}
              control={control}
              required={false}
              maxRating={maxRating}
              minRating={minRating}
            />
            <SentimentFilter
              setSentiment={setSentiment}
              sentiment={sentiment}
              sentimentError={sentimentError}
              setSentimentError={setSentimentError}
            />
            <YesNoFilter
              setRecommedned={setRecommedned}
              recommended={recommended}
              recommendedError={recommendedError}
              setRecommednedError={setRecommednedError}
              title="Recommended"
            />
          </>
        );

      default:
        return null;
    }
  };

  const getConditionalResultFilterList = (type) => {
    switch (type) {
      case 'survey':
        return surveyResults;
      case 'notification':
        return notificationResults;
      case 'review':
        return reviewResults;
      default:
        return reviewResults;
    }
  };

  const getConditionalFeedbackStageFilterList = (type) => {
    switch (type) {
      case 'survey':
        return surveyFeedbackStage;
      case 'notification':
        return notificationFeedbackStage;
      case 'review':
        return reviewFeedbackStage;
      default:
        return reviewFeedbackStage;
    }
  };

  const getTimeStamp = (type) => {
    const currentDate = new Date();
    switch (type) {
      case 'days':
        return new Date(
          currentDate.setDate(currentDate.getDate() - watchTypedDurationChange),
        ).getTime();

      case 'month':
        return new Date(
          currentDate.setMonth(currentDate.getMonth() - watchTypedDurationChange),
        ).getTime();

      case 'year':
        return new Date(
          currentDate.setFullYear(currentDate.getFullYear() - watchTypedDurationChange),
        ).getTime();

      default:
        return new Date().getTime();
    }
  };

  useEffect(() => {
    if (watchDurationChange) {
      setTimeStamp(getTimeStamp(watchDurationChange));
    }
  }, [watchDurationChange, watchTypedDurationChange]);

  const getFeedbackStage = (stage) => {
    switch (stage) {
      case 'opened':
        return `&stage=1`;

      default:
        return stage ? `&reviewat=${stage.toLowerCase().trim()}` : '';
    }
  };

  const getResult = (result) => {
    switch (result) {
      case 'feedback':
        return `&feedback=1`;
      case 'recommended':
        return `&recommended=1`;
      case 'not_recommended':
        return `&recommended=0`;
      case 'subscribe':
        return `&subscribe=1`;
      case `not_subscribe`:
        return `&subscribe=0`;
      default:
        return '';
    }
  };

  const getDataWithParameter = (data, parameter) => {
    return data ? `&${parameter}=${data}` : '';
  };

  const getStringFromArray = (data) => {
    let finalString = '';
    data.forEach((el, index) => {
      if (el !== '') {
        finalString += index !== data.length - 1 ? `${el}-` : el;
      }
    });
    return finalString;
  };

  const getArrayDataWithParameter = (data, parameter) => {
    const finalString = getStringFromArray(data);
    return data.length && finalString !== '' ? `&${parameter}=${finalString}` : '';
  };

  const getReviewPostURL = (data) => {
    return `business=${selectedBusiness}${getDataWithParameter(
      data.feedbackType,
      'type',
    )}${getResult(data.result)}${getFeedbackStage(data.feedback_stage)}${getDataWithParameter(
      data.user,
      'user',
    )}${getDataWithParameter(timeStamp, 'time')}${getArrayDataWithParameter(
      groupContainer.selectedGroupName,
      'groups',
    )}`;
  };

  const getSurveyPostURL = (data) => {
    return `business=${selectedBusiness}${getDataWithParameter(
      data.feedbackType,
      'type',
    )}${getResult(data.result)}${getFeedbackStage(data.feedback_stage)}${getDataWithParameter(
      data.user,
      'user',
    )}${getArrayDataWithParameter(
      [data.minRating, data.maxRating],
      'rating',
    )}${getDataWithParameter(sentiment, 'sentiment')}${getDataWithParameter(
      recommended,
      'recommended',
    )}${getDataWithParameter(timeStamp, 'time')}${getArrayDataWithParameter(
      groupContainer.selectedGroupName,
      'groups',
    )}`;
  };

  const getFilterPostBody = (type, data) => {
    switch (type) {
      case 'review':
        return getReviewPostURL(data);
      case 'survey':
        return getSurveyPostURL(data);
      case 'notification':
        return getReviewPostURL(data);
      default:
        return null;
    }
  };

  const handleFilter = async (data) => {
    try {
      setFilterLoader(true);
      const result = await baseUrl(
        `group/client/filter?${getFilterPostBody(data.feedbackType, data)}`,
      );
      if (result.status === 200) {
        setFilterLoader(false);
        if (result.data.length) {
          dispatch({ type: 'SET_EXISTING_GROUP_CONTAINER', payload: Array.from(result.data) });
        }
      }
    } catch (err) {
      setFilterLoader(false);
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  return (
    <ThemeProvider theme={{ fieldLabelPadding: '1.5em 0 0.5em 0', fieldLabelFontSize: '12px' }}>
      <StyledGroupFilterContainer item xs={12} sm={12} md={3} lg={3}>
        <CusotmAlert
          message={groupFilterAlert.message}
          handleClose={groupFilterAlert.handleClose}
          open={groupFilterAlert.show}
          messageType={groupFilterAlert.messageType}
        />
        <form onSubmit={handleSubmit(handleFilter)}>
          <Grid container>
            <FieldGroupLable item xs={12} sm={12} md={12} lg={12}>
              Filter Group
            </FieldGroupLable>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ThemeProvider theme={{ ...defaultDropDownTheme, margin: '0' }}>
                <MultiSelect
                  listOptions={ConvertIntoGroupNameDropDown(groupContainer.groupNameContainer)}
                  label="Select Group"
                  onChange={handleMultiSelectChange}
                  value={groupContainer.selectedGroupName}
                />
              </ThemeProvider>
            </Grid>
            <FieldGroupLable item xs={12} sm={12} md={12} lg={12}>
              Date range
            </FieldGroupLable>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container spacing={2}>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <ThemeProvider theme={dropDownTheme}>
                    <StyledFormControl error={!!errors.from}>
                      <Controller
                        as={<Dropdown listOptions={Array.from(timeSpan)} label="From *" disabled />}
                        name="from"
                        control={control}
                        defaultValue="last"
                      />
                      {errors.from ? (
                        <FormHelperText>User is a required field</FormHelperText>
                      ) : null}
                    </StyledFormControl>
                  </ThemeProvider>
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                  <ThemeProvider theme={inputTheme}>
                    <StyledTextField
                      name="typedDuration"
                      type="number"
                      label=""
                      inputProps={{ 'aria-label': 'description' }}
                      inputRef={
                        register
                        //   ({
                        //   required: true,
                        //   validate: (value) => value.trim() !== '',
                        // })
                      }
                      error={errors.typedDuration}
                      helperText={errors.typedDuration ? 'Please enter some value' : ''}
                    />
                  </ThemeProvider>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <ThemeProvider theme={dropDownTheme}>
                    <StyledFormControl error={!!errors.duration}>
                      <Controller
                        as={
                          <Dropdown listOptions={Array.from(duration)} label="Select Duration *" />
                        }
                        name="duration"
                        control={control}
                        defaultValue=""
                      />
                      {errors.duration ? (
                        <FormHelperText>User is a required field</FormHelperText>
                      ) : null}
                    </StyledFormControl>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </Grid>
            <StyledTimeLapsedContainer item xs={12} sm={12} md={12} lg={12}>
              {getCurrentAndPreviousDate(timeStamp)}
            </StyledTimeLapsedContainer>
            <FieldGroupLable item xs={12} sm={12} md={12} lg={12}>
              Attributed User
            </FieldGroupLable>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ThemeProvider theme={dropDownTheme}>
                <StyledFormControl error={!!errors.user}>
                  <Controller
                    as={<Dropdown listOptions={Array.from(userContainer)} label="Select User" />}
                    name="user"
                    control={control}
                    defaultValue=""
                  />
                  {errors.user ? <FormHelperText>User is a required field</FormHelperText> : null}
                </StyledFormControl>
              </ThemeProvider>
            </Grid>
            <FieldGroupLable item xs={12} sm={12} md={12} lg={12}>
              Feedback Type
            </FieldGroupLable>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ThemeProvider theme={dropDownTheme}>
                <StyledFormControl error={!!errors.feedbackType}>
                  <Controller
                    as={<Dropdown listOptions={Array.from(feedbackType)} label="Feedback Type" />}
                    name="feedbackType"
                    control={control}
                    defaultValue=""
                  />
                  {errors.feedbackType ? (
                    <FormHelperText>Feedback Type is a required field</FormHelperText>
                  ) : null}
                </StyledFormControl>
              </ThemeProvider>
            </Grid>
            {getConditionalFilter(watchFeedbackType)}
            <FieldGroupLable item xs={12} sm={12} md={12} lg={12}>
              Result
            </FieldGroupLable>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ThemeProvider theme={dropDownTheme}>
                <StyledFormControl error={!!errors.result}>
                  <Controller
                    as={
                      <Dropdown
                        listOptions={getConditionalResultFilterList(watchFeedbackType)}
                        label="Select Result"
                      />
                    }
                    name="result"
                    control={control}
                    defaultValue=""
                  />
                  {errors.result ? (
                    <FormHelperText>Result is a required field</FormHelperText>
                  ) : null}
                </StyledFormControl>
              </ThemeProvider>
            </Grid>
            <FieldGroupLable item xs={12} sm={12} md={12} lg={12}>
              Feedback Stage
            </FieldGroupLable>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ThemeProvider theme={dropDownTheme}>
                <StyledFormControl error={!!errors.feedback_stage}>
                  <Controller
                    as={
                      <Dropdown
                        listOptions={getConditionalFeedbackStageFilterList(watchFeedbackType)}
                        label="Select Feedback Stage"
                      />
                    }
                    name="feedback_stage"
                    control={control}
                    defaultValue=""
                  />
                  {errors.feedback_stage ? (
                    <FormHelperText>Feedback stage sis a required field</FormHelperText>
                  ) : null}
                </StyledFormControl>
              </ThemeProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={8} sm={8} md={8} lg={8}>
                  <ThemeProvider theme={{ background: '#6C6C6C', color: '#fff' }}>
                    <CustomButton disabled={filterLoader} type="submit">
                      Apply Filter
                      {filterLoader ? <ButtonLoader /> : null}
                    </CustomButton>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container justify="space-between" alignItems="center">
                <Grid item xs={5} sm={5} md={5} lg={5} style={{ textAlign: 'center' }}>
                  <ThemeProvider theme={{ background: '#6C6C6C', color: '#fff' }}>
                    <CustomButton
                      disabled={!(groupContainer.listViewSelectedContact.length > 0)}
                      onClick={groupModal.handleShowModal}
                    >
                      Save as...
                    </CustomButton>
                  </ThemeProvider>
                </Grid>

                <Grid item xs={5} sm={5} md={5} lg={5} style={{ textAlign: 'center' }}>
                  <ThemeProvider theme={{ background: '#6C6C6C', color: '#fff' }}>
                    <CustomButton
                      disabled={!(groupContainer.listViewSelectedContact.length > 0)}
                      onClick={handleGroupSave}
                    >
                      Save
                    </CustomButton>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </StyledGroupFilterContainer>
      <GenricPopup open={groupModal.showModal} handleClose={handleFilterPopupClose}>
        <Grid container style={{ padding: '1em' }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <PageTitle title="Save Filter Group" />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <StyledTextField
              placeholder="Group name"
              onChange={handleGroupNameChange}
              error={groupNameError}
              helperText={groupNameError ? 'Name already taken' : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container justify="flex-end">
              <Grid item xs={5} sm={5} md={5} lg={5}>
                <ThemeProvider theme={{ color: '#fff' }}>
                  <CustomButton
                    disabled={disableSaveBtn || !groupName.trim().length || loader}
                    onClick={handleSaveGroup}
                  >
                    Save Filter Group
                    {loader ? <ButtonLoader /> : null}
                  </CustomButton>
                </ThemeProvider>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </GenricPopup>
    </ThemeProvider>
  );
}

GroupFilter.propTypes = {};

export default GroupFilter;
