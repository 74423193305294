import React from 'react';
import Proptypes from 'prop-types';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import styled from 'styled-components';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from 'mdi-react/CloseCircleIcon';

const StyledBorderTextField = styled(TextField)`
  width: -webkit-fill-available;
  border: none !important;
  background: ${(props) => (props.theme.bgColor ? props.theme.bgColor : 'transparent')} !important;
  margin: ${(props) => (props.theme.margin ? props.theme.margin : '1em 0 0.5em')} !important;
  border-bottom: ${(props) => (props.theme.borderBottom ? props.theme.borderBottom : 'none')};
  max-width: ${(props) => (props.theme.maxWidth ? props.theme.maxWidth : '316px;')};
  //border: ${(props) =>
    props.theme.border ? props.theme.border : '1px solid #c9c9c9 !important;'};
  border-radius: ${(props) => (props.theme.borderRadius ? props.theme.borderRadius : '30px')};
  border-width: ${(props) => (props.theme.borderWidth ? props.theme.borderWidth : '1px;')};
  padding-left: 15px !important;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input {
    font-family: 'Montserrat' !important;
    font-size: 15px !important;
  }

  & div {
    //border: 1px solid #dedede !important;
    color: ${(props) => props.theme.color} !important;
  }

  & div:after {
    border-bottom: none !important;
  }

  & div:before {
    border-bottom: none !important;
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  color: #6d6d6d !important;
`;

const StyledClearIcon = styled(ClearIcon)`
  color: #7a7a7a !important;
  width: 18px;
  height: 18px;
`;

const StyledIconButton = styled(IconButton)`
  padding: 8px !important;
`;
function CusotomSearchBox(props) {
  const {
    placeholder,
    handleChange,
    handleBlur,
    clearSearch,
    searchValue,
    additionalSearchComponenttion,
  } = props;

  const ClearSearch = searchValue ? (
    <>
      <StyledIconButton onClick={clearSearch} position="end">
        <StyledClearIcon />
      </StyledIconButton>
      {additionalSearchComponenttion && additionalSearchComponenttion}
    </>
  ) : null;

  return (
    <StyledBorderTextField
      placeholder={placeholder}
      onChange={handleChange}
      value={searchValue}
      onBlur={handleBlur}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <StyledSearchIcon />
          </InputAdornment>
        ),
        endAdornment: ClearSearch,
      }}
    />
  );
}
CusotomSearchBox.defaultProps = {
  handleBlur: () => {},
};
CusotomSearchBox.propTypes = {
  placeholder: Proptypes.string.isRequired,
  handleChange: Proptypes.func.isRequired,
  handleBlur: Proptypes.func,
};

export default CusotomSearchBox;
