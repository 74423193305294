const NotificationReview = {
  optInOrOut: {
    screens: [
      {
        screen: 'sms',
        title:
          'Hi @customerName, please provide your feedback by clicking the link. Thanks! http://goo.gl/138e!2Ca3. Txt STOP to unsub.',
        uploadedFile: null,
        url: '',
        emailTitle: 'Thank you for your business',
      },
      {
        screen: 'opt-in-or-out',
        title: 'Would you like to be on our product announcement mailing list',
        positiveButtonText: 'Yes',
        negativeButtonText: 'No',
        uploadedFile: null,
        url: '',
      },
      {
        screen: 'thankyou',
        title: 'Thank you for your time, we look forward to serving you in the future',
        url: '',
        uploadedFile: null,
      },
    ],
  },
  noReply: {
    screens: [
      {
        screen: 'sms',
        title:
          'Hi @customerName, please provide your feedback by clicking the link. Thanks! http://goo.gl/138e!2Ca3. Txt STOP to unsub.',
        uploadedFile: null,
        url: '',
        emailTitle: 'Thank you for your business',
      },
      {
        screen: 'no-reply',
        title: 'Your car will be ready to be picked up at 5pm.',
        uploadedFile: null,
        url: '',
      },
      {
        screen: 'thankyou',
        title: 'Thank you for your time, we look forward to serving you in the future',
        uploadedFile: null,
        url: '',
      },
    ],
  },
  confirmation: {
    screens: [
      {
        screen: 'sms',
        title:
          'Hi @customerName, please provide your feedback by clicking the link. Thanks! http://goo.gl/138e!2Ca3. Txt STOP to unsub.',
        url: '',
        uploadedFile: null,
        emailTitle: 'Thank you for your business',
      },
      {
        screen: 'confirmation',
        title:
          'This is confirm your appointment on 11/05/20 with doctor knowlton Click YES to confirm No to Cancel',
        textFeedbackTitle: 'Is There anything we need to know prior to your appointment',
        positiveButtonText: 'Yes',
        negativeButtonText: 'No',
        uploadedFile: null,
        url: '',
      },
      {
        screen: 'thankyou',
        title: 'Thank you for your time, we look forward to serving you in the future',
        url: '',
        uploadedFile: null,
      },
    ],
  },
  singleAction: {
    screens: [
      {
        screen: 'sms',
        title:
          'Hi @customerName, please provide your feedback by clicking the link. Thanks! http://goo.gl/138e!2Ca3. Txt STOP to unsub.',
        url: '',
        uploadedFile: null,
        emailTitle: 'Thank you for your business',
      },
      {
        screen: 'single-action',
        title: 'Would you like to schedule an appointment',
        positiveButtonText: 'Schedule Now',
        uploadedFile: null,
        url: '',
      },
      {
        screen: 'thankyou',
        title: 'Thank you for your time, we look forward to serving you in the future',
        url: '',
        uploadedFile: null,
      },
    ],
  },
  name: 'confirmation',
};

export default NotificationReview;
