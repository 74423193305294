import React, { useContext, useMemo, useState } from 'react';
import Proptypes from 'prop-types';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormHelperText,
  ThemeProvider as MUIThemeProvider,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import styled, { ThemeProvider } from 'styled-components';
import ClearIcon from '@material-ui/icons/Clear';
import ButtonLoader from '../ButtonLoader/ButtonLoader';
import CustomButton from '../CustomButton/CustomButton';
import CusotmAlert from '../CustomAlert/CustomAlert';
import Dropdown from '../Dropdown/Dropdown';
import { monthsArray } from '../../utils/api/Months';
import defaultDropDownTheme from '../../themes/Dropdown/DefaultDropdownTheme';
import Loader from '../Loader/Loader';
import StyledFormControl from '../StyledFormControl';
import GenericPopup from '../Popup/GenricPopup/index';
import useInputFieldTheme from '../../hooks/useInputFieldTheme';
import useButtonTheme from '../../hooks/useButtonTheme';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import baseUrl from '../../utils/services/config';
import { errorChecker } from '../../utils/helper/helper';

const StyledContentDialog = styled(DialogContent)`
  padding: 0 0.5em !important;
`;

const StyledActions = styled(DialogActions)`
  padding: 0 !important;
`;

const buttonTheme1 = {
  background: 'transparent !important',
  margin: '0 ',
};

function GoalsDeletePopup(props) {
  const { open, handleClose, deleteRecord } = props;
  const { handleSubmit, errors, control } = useForm();
  const [showAlert, setAlert] = useState(false);
  const [loader, setLoader] = useState(false);
  const [renderView] = useState(true);
  const { dispatch } = useContext(GlobalContext);
  const { inputTheme } = useInputFieldTheme();
  const { buttonTheme } = useButtonTheme();

  const onSubmit = async (data) => {
    try {
      if (deleteRecord[data.month] && deleteRecord[data.month] !== undefined);
      {
        setLoader(true);
        const result = await baseUrl.delete(`usergoals/${deleteRecord[data.month].id}`);
        if (result.status === 200) {
          setLoader(false);
          handleClose();
        }
      }
    } catch (err) {
      setLoader(false);
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const monthsContainer = useMemo(() => {
    return monthsArray.reduce((container, item) => {
      container.push({
        label: item,
        value: item.toLocaleLowerCase(),
      });
      return container;
    }, []);
  }, [monthsArray]);

  const handleAlertClose = () => setAlert(false);

  const showLoader = loader ? <ButtonLoader /> : null;

  return renderView ? (
    <>
      <CusotmAlert
        open={showAlert}
        message="Goal settings created successfully"
        handleClose={handleAlertClose}
      />

      <GenericPopup open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>
          <Grid container justify="space-between">
            <Grid item>Delete Goals</Grid>
            <Grid item>
              <ThemeProvider theme={buttonTheme1}>
                <CustomButton onClick={handleClose} title="Close">
                  <ClearIcon />
                </CustomButton>
              </ThemeProvider>
            </Grid>
          </Grid>
        </DialogTitle>
        <MUIThemeProvider theme={inputTheme}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <StyledContentDialog>
              <Grid container justify="center">
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <ThemeProvider theme={defaultDropDownTheme}>
                    <StyledFormControl error={!!errors.month}>
                      <Controller
                        as={
                          <Dropdown
                            listOptions={Array.from(monthsContainer)}
                            label="Select Month *"
                          />
                        }
                        name="month"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                      />
                      {errors.month ? (
                        <FormHelperText>Month is a required field</FormHelperText>
                      ) : null}
                    </StyledFormControl>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </StyledContentDialog>
            <StyledActions>
              <Grid container justify="center">
                <Grid item xs={12} sm={12} md={3} lg={4}>
                  <ThemeProvider
                    theme={{ background: buttonTheme.buttonBgColor, color: buttonTheme.fontColor3 }}
                  >
                    <CustomButton type="submit" color="primary" disabled={loader}>
                      {'Delete'}
                      {showLoader}
                    </CustomButton>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </StyledActions>
          </form>
        </MUIThemeProvider>
      </GenericPopup>
    </>
  ) : (
    <Loader />
  );
}

GoalsDeletePopup.propTypes = {
  open: Proptypes.bool.isRequired,
  handleClose: Proptypes.func.isRequired,
  deleteRecord: Proptypes.objectOf(Proptypes.string).isRequired,
};

export default React.memo(GoalsDeletePopup);
