import React from 'react';
import Proptypes from 'prop-types';
import { Grid } from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import CompanyLogo from '../CompanyLogo/CompanyLogo';
import dummyPreview from '../../assets/images/reveo_logo@1x.png';
import useTextColors from '../../hooks/useTextColors';

const StyledPoweredByText = styled(Grid)`
  font-size: 10px;
  color: ${(props) => props.theme.color};
`;

const poweredImageTheme = {
  height: '25px',
  width: '70px',
};

function PoweredBy({ isPoweredBy }) {
  const { textColors } = useTextColors();
  return isPoweredBy ? (
    <Grid item xs={12} sm={12} lg={12} md={12} style={{ padding: '1em 0' }}>
      <Grid container style={{ textAlign: 'center' }}>
        <ThemeProvider theme={{ color: textColors.fontColor5 }}>
          <StyledPoweredByText item xs={12} sm={12} lg={12} md={12}>
            Powered by
          </StyledPoweredByText>
        </ThemeProvider>
        <Grid item xs={12} sm={12} lg={12} md={12}>
          <ThemeProvider theme={poweredImageTheme}>
            <CompanyLogo logo={dummyPreview} />
          </ThemeProvider>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    ''
  );
}

PoweredBy.propTypes = {
  isPoweredBy: Proptypes.bool.isRequired,
};

export default PoweredBy;
