import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Tooltip } from '@material-ui/core';
import TransperentButtonTheme from '../themes/Button/TransperentButtonTheme';
import CustomButton from '../components/CustomButton/CustomButton';
import StyledEditIcon from '../components/Icons/EditIcon';
import StyledTableCell from '../components/TableRowCell';
import StyledTrashIcon from '../components/Icons/TrashIcon';
import StyledDirectMessageIcon from '../components/Icons/DirectMessageIcon';
import ButtonLoader from '../components/ButtonLoader/ButtonLoader';

const StyledHyperLink = styled.a`
  color: #49d6a0;
`;

const StyledName = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #37423e !important;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const getContactColumns = (
  handleEdit,
  handleDelete,
  customersContainer,
  iconsTheme,
  handleMessageContact,
) => [
  {
    name: 'name',
    label: 'NAME',
    options: {
      customBodyRender: (value) => {
        return <StyledName>{value}</StyledName>;
      },
      filter: true,
      sort: false,
    },
  },
  {
    name: 'phone',
    label: 'Phone',
    options: {
      customBodyRender: (value) => {
        return (
          <span>
            <StyledTableCell>{value}</StyledTableCell>
          </span>
        );
      },
      filter: true,
      sort: false,
    },
  },
  {
    name: 'email',
    label: 'Email',
    options: {
      customBodyRender: (value) => {
        return (
          <span>
            <StyledTableCell>
              <StyledHyperLink href={`mailto:${value}`}>{value}</StyledHyperLink>
            </StyledTableCell>
          </span>
        );
      },
      filter: true,
      sort: false,
    },
  },
  {
    name: 'location_names',
    label: 'Locations',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => {
        return (
          <StyledTableCell>
            {(
              <Tooltip title={value} arrow position="top">
                <span>{value}</span>
              </Tooltip>
            ) || <ButtonLoader />}
          </StyledTableCell>
        );
      },
    },
  },
  {
    name: '',
    label: 'Action',
    textAlign: 'right',
    options: {
      customBodyRenderLite: (dataIndex, rowIndex) => {
        if (!customersContainer[dataIndex].isDeleted) {
          return (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <ThemeProvider theme={{ ...TransperentButtonTheme, color: iconsTheme.editIcon }}>
                <CustomButton onClick={() => handleEdit(dataIndex, rowIndex)} title="Edit">
                  <StyledEditIcon />
                </CustomButton>
              </ThemeProvider>
              <ThemeProvider theme={{ ...TransperentButtonTheme, color: iconsTheme.editIcon }}>
                <CustomButton
                  onClick={() => handleMessageContact(dataIndex, rowIndex)}
                  title="Direct Message"
                >
                  <StyledDirectMessageIcon />
                </CustomButton>
              </ThemeProvider>
              <ThemeProvider theme={TransperentButtonTheme}>
                <CustomButton onClick={() => handleDelete(dataIndex, rowIndex)} title="Delete">
                  <StyledTrashIcon />
                </CustomButton>
              </ThemeProvider>
            </div>
          );
        }
        return '';
      },
      sort: false,
    },
  },
];

export default getContactColumns;
