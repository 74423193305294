import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { Grid, RadioGroup } from '@material-ui/core';
import StyledFormControlLabel from '../../../../../../../StyledFormControlLabel';
import StyledRadioButton from '../../../../../../../StyledRadioButton';
import StyledDescriptionText from '../../../../../../../DescriptionText';
import TextAreaCampaignSettigs from '../../../../CampaignSettings/TextAreaCampaignSetting';

const TabOptions = ({ bodyRequest, setBodyRequest }) => {
  const [radioValue, setRadioValue] = useState(bodyRequest.settings.subType ?? 'simple');
  const [filterQuestionValue, setfilterQuestionValue] = useState('');
  const [negativeResponseMessage, setNegativeResponseMessage] = useState('');

  const handleSubmit = () => {};

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
    setBodyRequest({
      ...bodyRequest,
      settings: {
        ...bodyRequest.settings,
        subType: e.target.value,
      },
    });
  };

  return (
    <Grid style={{ overflowY: 'auto' }}>
      <Grid item xs={12} md={12} lg={12} sm={12}>
        <Grid container>
          <Grid item xs={12} md={7} lg={7} sm={12}>
            <form onSubmit={handleSubmit}>
              <RadioGroup
                aria-label="quiz"
                name="quiz"
                value={radioValue}
                onChange={handleRadioChange}
              >
                <StyledFormControlLabel
                  value="simple"
                  control={<StyledRadioButton color="default" />}
                  label="Simple campaign"
                />
                <ThemeProvider
                  theme={{ color: '#888888', margin: '0', padding: '0 20px 10px 32px' }}
                >
                  <StyledDescriptionText item xs={12} md={12} lg={12} sm={12}>
                    Direct customers to social links without filtering
                  </StyledDescriptionText>
                </ThemeProvider>
                {/* <StyledFormControlLabel
                  value="guided"
                  control={<StyledRadioButton color="default" />}
                  label="Targeted campaign"
                />
                <ThemeProvider
                  theme={{ color: '#888888', margin: '0', padding: '0 20px 10px 32px' }}
                >
                  <StyledDescriptionText item xs={12} md={12} lg={12} sm={12}>
                    Direct customers to the most important social sites one at a time, if none are
                    selected, the customer is sent back to the page with all of the social sites
                  </StyledDescriptionText>
                </ThemeProvider> */}
                <StyledFormControlLabel
                  value="semiFiltered"
                  control={<StyledRadioButton color="default" />}
                  label="Filtered campaign"
                />
                <ThemeProvider
                  theme={{ color: '#888888', margin: '0', padding: '0 20px 10px 32px' }}
                >
                  <StyledDescriptionText item xs={12} md={12} lg={12} sm={12}>
                    Filters negative reviews without sending customers to social sites
                  </StyledDescriptionText>
                </ThemeProvider>
              </RadioGroup>
            </form>
          </Grid>
        </Grid>
      </Grid>

      {radioValue === 'filtered' && (
        <Grid style={{ paddingLeft: 30 }} item xs={12} md={12} lg={12} sm={12}>
          <Grid item xs={12} md={7} lg={7} sm={12}>
            <TextAreaCampaignSettigs
              title="Filter question"
              value={filterQuestionValue}
              placeholder="Would you recommend us to others?"
              setValue={(value) => {
                setfilterQuestionValue(value);
              }}
              withChip={false}
              multiline
            />
          </Grid>
          <Grid item xs={12} md={7} lg={7} sm={12}>
            <TextAreaCampaignSettigs
              title="Negative response message"
              value={negativeResponseMessage}
              placeholder="We’re sorry that our service did not meet your expectations, please leave feedback on your experience so we can improve our services."
              setValue={(value) => {
                setNegativeResponseMessage(value);
              }}
              withChip={false}
              multiline
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default TabOptions;
