import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  Typography,
  Box,
  IconButton,
  ThemeProvider as MUIThemeProvider,
} from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import CustomButton from '../../components/CustomButton/CustomButton';
import baseUrl from '../../utils/services/config';
import Uploader from '../../components/Uploader/Uploader';
import CusotmAlert from '../../components/CustomAlert/CustomAlert';
import PageTitle from '../../components/PageTitle/PageTitle';
import ButtonLoader from '../../components/ButtonLoader/ButtonLoader';
import StyledSwitch from '../../components/StyledSwitch/StyledSwitch';
import StyledTextField from '../../components/GeneralInputField/GeneralInputField';
// import Dropdown from '../../components/Dropdown/Dropdown';
// import theme from '../../setup';
import defaultDropDownTheme from '../../themes/Dropdown/DefaultDropdownTheme';
import useInputFieldTheme from '../../hooks/useInputFieldTheme';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import StyledFormControl from '../../components/StyledFormControl';
import useTextColors from '../../hooks/useTextColors';
import useButtonTheme from '../../hooks/useButtonTheme';
import useAntSwitchTheme from '../../hooks/useAntSwitchTheme';
import Dropdown from '../../components/Dropdown/Dropdown';
import { FormHeader } from '../../components/Forms/FormHeader';
import TransperentButtonTheme from '../../themes/Button/TransperentButtonTheme';
import CloseIcon from '@material-ui/icons/Close';
import useIconTheme from '../../hooks/useIconTheme';

const StyledDashboard = styled(Grid)`
  padding: 1em 2em 2rem 2rem;
  min-height: 100%;
`;

const StyleColorPicker = styled(TextField)`
  width: 100px;
  padding: 3px !important;
  & div {
    width: -webkit-fill-available;
    padding: 0 !important;
  }
  & div input {
    height: 30px !important;
    cursor: pointer;
  }

  & div textarea {
    border: 1px solid #dedede !important;
  }
  & div:after {
    border-bottom: none !important;
  }

  & div:before {
    border-bottom: none !important;
  }
`;

const StyleHeader = styled(Grid)`
  font-size: 16px;
  color: ${(props) => props.theme.color} !important;
  font-family: Montserrat, medium;
  margin: auto 0 !important;
  text-align: left;
`;
const iconUploader = {
  textAlign: 'center',
  width: '100%',
  height: '140px',
};
const defaultValues = {
  backgroundColor: '#ffffff',
  webFontColor: '#ffffff',
};
// const dropDownTheme = {
//   borderBottom: '1px solid #0000006b',
//   border: 'none',
//   padding: '.825em 0.5em',
//   margin: '1.25em 0 1em',
//   borderBottomAfter: `2px solid ${theme.btn_background_color}`,
//   maxWidth: '100%',
// };
const ErrorTxt = styled(Typography)`
  font-size: 12px !important;
  color: #f44336;
  font-family: Montserrat, Medium !important;
  // @media screen and (max-width: 600px) {
  //   & {
  //     font-size: 10px !important;
  //   }
  // }
`;

const StyledCloseButton = styled(IconButton)`
  right: 10px;
  top: 10px;
`;

const StyledBox = styled(Box)`
  background-color: #f5f5f5;
`;

function PostSites(props) {
  const { postSiteId, handleClose } = props;
  const [imagesContainer, setImagesContainer] = useState({});
  const postSiteRowId = postSiteId;
  const [loader, setLoader] = useState(false);
  const [showAlert, setAlert] = useState([
    {
      show: false,
      messageType: '',
      message: '',
    },
  ]);
  const history = useHistory();
  const { handleSubmit, register, watch, setValue, control, errors } = useForm({
    defaultValues,
  });
  const [renderView, setRenderView] = useState(false);
  const [seoCategoryList, setSEOCategory] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const { inputTheme } = useInputFieldTheme();
  const { textColors } = useTextColors();
  const { buttonTheme } = useButtonTheme();
  const { antSwitchTheme } = useAntSwitchTheme();
  const watchIconUploader = watch('iconImg');
  const watchFields = watch('seoCategory');
  const authRequiredField = watch('authRequired');
  const paginationField = watch('pagination');
  const authTokenField = watch('authToken');
  const { iconsTheme } = useIconTheme();

  useEffect(() => {
    register({ name: 'iconImg' });
  }, [register]);

  useEffect(() => {
    if (postSiteRowId && postSiteRowId !== 'new') {
      baseUrl.get(`/postsites/${postSiteRowId}`).then((res) => {
        if (res.status === 200) {
          setRenderView(true);
          let imagesContainerCopy = { ...imagesContainer };
          if (res.data.uploadedFile.length > 0) {
            imagesContainerCopy = {
              url: res.data.uploadedFile[0].url || '',
              id: res.data.uploadedFile[0].id || '',
            };
            setImagesContainer(imagesContainerCopy);
            setValue('iconImg', res.data.uploadedFile[0].url || '');
          }
          setValue('name', res.data.name);
          setValue('backgroundColor', res.data.backgroundColor);
          setValue('authRequired', res.data.authRequired);
          setValue('authLink', res.data.authLink);
          setValue('apiEndpoint', res.data.apiEndpoint);
          setValue('pagination', res.data.pagination);
          setValue('authToken', res.data.authToken);
          setValue('authToken', res.data.authToken);
          setValue('subsequentPageParameter', res.data.subsequentPageParameter);
          setValue('initialPageParameter', res.data.initialPageParameter);
          if (res.data.seoCategory.length > 0) {
            const copySEO = [];
            res.data.seoCategory.forEach((item) => {
              copySEO.push(item.id);
            });
            setValue('seoCategory', res.data.seoCategory.length > 0 ? copySEO : '');
          }
          if (res.data.industry !== undefined && res.data.industry.length > 0) {
            setValue(
              'industry',
              res.data.industry.map((item) => item.id),
            );
          } else {
            setValue('industry', '');
          }
        }
      });
    } else {
      setRenderView(true);
    }

    baseUrl.get(`/seoCategory?&isDeleted=false`).then((res) => {
      if (res.status === 200) {
        const copyCategory = [];
        res.data.map((data) => copyCategory.push({ label: data.name, value: data.id }));
        setSEOCategory(copyCategory);
      }
    });
    baseUrl.get(`/industry?&isDeleted=false`).then((res) => {
      if (res.status === 200) {
        const industries = [];
        res.data.map((data) => industries.push({ label: data.name, value: data.id }));
        setIndustryList(industries);
      }
    });
  }, [postSiteRowId]);

  const onSubmit = (siteData) => {
    const uploadedFile = imagesContainer.id ? [imagesContainer.id] : [];
    const body = {
      backgroundColor: siteData.backgroundColor,
      name: siteData.name,
      authRequired: siteData.authRequired,
      authLink: siteData.authLink,
      apiEndpoint: siteData.apiEndpoint,
      seoCategory: siteData.seoCategory,
      industry: siteData.industry,
      initialPageParameter: siteData.initialPageParameter
        ? Number(siteData.initialPageParameter)
        : 0,
      subsequentPageParameter: siteData.subsequentPageParameter
        ? Number(siteData.subsequentPageParameter)
        : 0,
      authTokenValue: siteData.authToken ? siteData.authTokenValue : '',
      authToken: siteData.authToken,
      pagination: siteData.pagination,
    };

    if (postSiteRowId && postSiteRowId !== 'new') {
      setLoader(true);
      baseUrl
        .patch(`/postsites/${postSiteRowId}`, { ...body, uploadedFile })
        .then((res) => {
          if (res.status === 200) {
            setAlert({
              show: true,
              message: 'Post Site Updated Successfully',
              messageType: 'success',
            });
            handleClose();
            setTimeout(() => {
              history.push('/app/setup/post-sites');
              setLoader(false);
            }, 1000);
          }
        })
        .catch(() => {});
    } else {
      setLoader(true);
      baseUrl
        .post(`/postsites`, { ...body })
        .then((res) => {
          if (res.status === 200) {
            baseUrl
              .patch(`/postsites/${res.data.id}`, {
                uploadedFile,
              })
              .then((imgres) => {
                if (imgres.status === 200) {
                  setAlert({
                    show: true,
                    message: 'Post Site Created Successfully',
                    messageType: 'success',
                  });
                  setTimeout(() => {
                    history.push('/app/setup/post-sites');
                    setLoader(false);
                  }, 1000);
                }
              })
              .catch(() => {});
          }
        })
        .catch(() => {});
    }
  };
  const showLoader = loader ? <ButtonLoader /> : null;

  const handleImagesContainer = (name, imgId) => {
    let imagesContainerCopy = { ...imagesContainer };
    imagesContainerCopy = { url: name, id: imgId };
    setImagesContainer(imagesContainerCopy);
  };

  const handleCloseAlert = () => {
    setAlert({
      show: false,
    });
  };

  const paginationContent = paginationField ? (
    <Grid container xs={12} sm={12} md={12} lg={12} spacing={3}>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <StyledTextField
          type="number"
          name="initialPageParameter"
          onBlur={(e) => setValue('naminitialPageParametere', e.target.value.trim())}
          label="Initial Page parameter *"
          inputRef={register({
            required: true,
            validate: (value) => value.trim() !== '',
          })}
          error={errors.initialPageParameter}
          helperText={
            errors.initialPageParameter ? 'Initial Page parameter is a required field' : ''
          }
        />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <StyledTextField
          type="number"
          name="subsequentPageParameter"
          onBlur={(e) => setValue('subsequentPageParameter', e.target.value.trim())}
          label="Subsequent Page Parameter *"
          inputRef={register({
            required: true,
            validate: (value) => value.trim() !== '',
          })}
          error={errors.subsequentPageParameter}
          helperText={
            errors.subsequentPageParameter ? 'Subsequent Page Parameter is a required field' : ''
          }
        />
      </Grid>
    </Grid>
  ) : (
    ''
  );

  const authTokenContent = authTokenField ? (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <StyledTextField
        type="text"
        name="authTokenValue"
        onBlur={(e) => setValue('authTokenValue', e.target.value.trim())}
        label="Auth Token *"
        inputRef={register({
          required: true,
          validate: (value) => value.trim() !== '',
        })}
        error={errors.authTokenValue}
        helperText={errors.authTokenValue ? 'Auth Token is a required field' : ''}
      />
    </Grid>
  ) : (
    ''
  );

  return renderView ? (
    <>
      <StyledDashboard container xs={12} sm={12} md={12} lg={12} direction="column">
        <CusotmAlert
          open={showAlert.show}
          message={showAlert.message}
          messageType={showAlert.messageType}
          handleClose={handleCloseAlert}
        />
        <MUIThemeProvider theme={inputTheme}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <StyledBox
              position={'sticky'}
              top={'0'}
              display="flex"
              justifyContent={'space-between'}
              alignItems={'center'}
              zIndex={1}
              padding="1rem 1rem 0"
              margin={'0 -1rem'}
            >
              <FormHeader>Edit Postsite</FormHeader>
              <Box marginTop={'-7rem'} marginRight="-3rem" display="flex" height="3rem">
                <Box width="200px" marginTop="1.5rem" marginRight="-3rem">
                  <CustomButton type="submit" width={'300px'} disabled={loader}>
                    Save
                    {showLoader}
                  </CustomButton>
                </Box>
                <ThemeProvider theme={TransperentButtonTheme}>
                  <StyledCloseButton onClick={() => handleClose()}>
                    <ThemeProvider theme={{ color: iconsTheme.clearIcon }}>
                      <CloseIcon />
                    </ThemeProvider>
                  </StyledCloseButton>
                </ThemeProvider>
              </Box>
            </StyledBox>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <StyledTextField
                  type="text"
                  name="name"
                  onBlur={(e) => setValue('name', e.target.value.trim())}
                  label="Site Name *"
                  inputRef={register({
                    required: true,
                    validate: (value) => value.trim() !== '',
                  })}
                  error={errors.name}
                  helperText={errors.name ? 'Site Name is a required field' : ''}
                />
                <StyledTextField
                  type="text"
                  name="authLink"
                  onBlur={(e) => setValue('authLink', e.target.value.trim())}
                  label={authRequiredField ? 'Auth Link *' : 'Auth Link'}
                  inputRef={register({
                    required: authRequiredField || false,
                  })}
                  error={errors.authLink}
                  helperText={errors.authLink ? 'Auth Link is a required field' : ''}
                />
                <StyledTextField
                  type="text"
                  name="apiEndpoint"
                  onBlur={(e) => setValue('apiEndpoint', e.target.value.trim())}
                  label="API Endpoint"
                  inputRef={register}
                />
                <ThemeProvider theme={defaultDropDownTheme}>
                  <StyledFormControl error={!!errors.seoCategory}>
                    <Controller
                      as={
                        <MultiSelect
                          listOptions={Array.from(seoCategoryList)}
                          label="Select SEO Category *"
                        />
                      }
                      value={watchFields || []}
                      name="seoCategory"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={[]}
                    />
                    {errors.seoCategory && <ErrorTxt>SEO Category is a required field</ErrorTxt>}
                  </StyledFormControl>
                </ThemeProvider>
                <ThemeProvider theme={defaultDropDownTheme}>
                  <StyledFormControl error={!!errors.industry}>
                    <Controller
                      as={
                        <Dropdown
                          listOptions={Array.from(industryList)}
                          label="Select Industry *"
                        />
                      }
                      value={watchFields || []}
                      name="industry"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={[]}
                    />
                    {errors.industry && <ErrorTxt>SEO Category is a required field</ErrorTxt>}
                  </StyledFormControl>
                </ThemeProvider>
              </Grid>

              <Grid container xs={12} sm={12} md={4} lg={4}>
                <ThemeProvider theme={{ color: textColors.fontColor4 }}>
                  <StyleHeader item xs={6} md={6} sm={6} lg={6}>
                    Icon Image:
                  </StyleHeader>
                </ThemeProvider>
                <ThemeProvider theme={iconUploader}>
                  <Grid item md={6} sm={6} xs={6} lg={6} style={{ marginTop: '1rem' }}>
                    <Uploader
                      name="iconImg"
                      setValue={setValue}
                      value={watchIconUploader}
                      handleImagesContainer={handleImagesContainer}
                      imgWidth={100}
                      imgHeight={60}
                      apiUrl="/uploads/images"
                      imageType="postsites"
                    />
                  </Grid>
                </ThemeProvider>
                <ThemeProvider theme={{ color: textColors.fontColor4 }}>
                  <StyleHeader item xs={6} md={6} sm={6} lg={6}>
                    Auth required:
                  </StyleHeader>
                </ThemeProvider>
                <Grid item xs={6} md={6} sm={6} lg={6}>
                  <MUIThemeProvider theme={antSwitchTheme}>
                    <Controller
                      name="authRequired"
                      as={<StyledSwitch />}
                      control={control}
                      defaultValue={false}
                    />
                  </MUIThemeProvider>
                </Grid>
                <ThemeProvider theme={{ color: textColors.fontColor4 }}>
                  <StyleHeader item xs={6} md={6} sm={6}>
                    Authorization Token:
                  </StyleHeader>
                </ThemeProvider>
                <Grid item xs={6} md={6} sm={6} lg={6}>
                  <MUIThemeProvider theme={antSwitchTheme}>
                    <Controller
                      name="authToken"
                      as={<StyledSwitch />}
                      control={control}
                      defaultValue={false}
                      disabled={authRequiredField}
                    />
                  </MUIThemeProvider>
                </Grid>
                {authTokenContent}
                <ThemeProvider theme={{ color: textColors.fontColor4 }}>
                  <StyleHeader item xs={6} md={6} sm={6}>
                    pagination:
                  </StyleHeader>
                </ThemeProvider>
                <Grid item xs={6} md={6} sm={6}>
                  <MUIThemeProvider theme={antSwitchTheme}>
                    <Controller
                      name="pagination"
                      as={<StyledSwitch />}
                      control={control}
                      defaultValue={false}
                      disabled={authRequiredField}
                    />
                  </MUIThemeProvider>
                </Grid>
                {paginationContent}
                <ThemeProvider theme={{ color: textColors.fontColor4 }}>
                  <StyleHeader item xs={6} md={6} sm={6}>
                    Icon Background Color:
                  </StyleHeader>
                </ThemeProvider>
                <Grid item xs={6} md={6} sm={6} lg={6}>
                  <StyleColorPicker name="backgroundColor" type="color" inputRef={register} />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </MUIThemeProvider>
      </StyledDashboard>
    </>
  ) : (
    <Loader />
  );
}

PostSites.propTypes = {};

export default PostSites;
