import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import styled from 'styled-components';

const { MenuItem } = require('@material-ui/core');

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 500,
    textTransform: 'capitalize',
  },
}));

const StyledMenuItem = forwardRef((props, ref) => {
  const classes = useStyles();
  return <MenuItem className={classes.root} {...props} />;
});

export default StyledMenuItem;
