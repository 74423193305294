import { CircularProgress } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { GlobalContext } from '../../../Stores/GlobalStateStore';
import { errorChecker } from '../../../utils/helper/helper';
import { emailRegEx } from '../../../utils/helper/regEx';
import baseUrl from '../../../utils/services/config';
import CustomButton from '../../CustomButton/CustomButton';
import GenricPopup from '../../Popup/GenricPopup';
import PrimaryButton from '../../Common/PrimaryButton';
import PageTitle from '../../PageTitle/PageTitle';
import StyledTextField from '../../Common/StyledTextField';

const StyledContainer = styled.div`
  padding: 1rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin: 0.5rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  column-gap: 0.5rem;
`;

const StyleContainerLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const SendEmailTemplatePopover = ({
  id,
  open,
  anchorEl,
  handleClose,
  templateIdSelected,
  templateAlert,
}) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch } = useContext(GlobalContext);

  const handleSendTestEmail = async () => {
    const { handleSetMessage, handleSetMessageType, handleShow } = templateAlert;

    setIsLoading(true);
    setTimeout(async () => {
      try {
        const payload = {
          emailTemplate: templateIdSelected,
          email,
        };
        const result = await baseUrl.post(`/campaign/send-email-template-preview`, {
          ...payload,
        });
        if (result.status === 200) {
          handleClose();
          setIsLoading(false);
          handleSetMessageType('success');
          handleSetMessage('Message sent successfully');
          handleShow();
        }
      } catch (error) {
        setIsLoading(false);
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(error) });
      }
    }, 1000);
  };
  const LoaderCircularProgress = () => (
    <StyleContainerLoading>
      <CircularProgress />
    </StyleContainerLoading>
  );

  return (
    <GenricPopup id={id} open={open} anchorEl={anchorEl} handleClose={handleClose}>
      {isLoading ? (
        <LoaderCircularProgress />
      ) : (
        <StyledContainer>
          <ContentWrapper>
            <PageTitle title={'Send test email'} />
            <StyledTextField
              type="text"
              placeholder={'Recipient E-main Address'}
              value={email}
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
            ></StyledTextField>
          </ContentWrapper>
          <ButtonWrapper>
            <PrimaryButton text onClick={handleClose}>
              Cancel
            </PrimaryButton>

            <PrimaryButton disabled={!emailRegEx.test(email)} onClick={handleSendTestEmail}>
              Send
            </PrimaryButton>
          </ButtonWrapper>
        </StyledContainer>
      )}
    </GenricPopup>
  );
};

export default SendEmailTemplatePopover;
