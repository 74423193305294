import { useContext, useMemo, useState } from 'react';
import { GlobalContext } from '../Stores/GlobalStateStore';

const useCardTheme = () => {
  const { state } = useContext(GlobalContext);
  const { themeContainer } = state;
  const [theme, setTheme] = useState({});
  useMemo(() => {
    setTheme({
      cardTitleColor: themeContainer.cardTitleColor,
      cardDescriptionColor: themeContainer.cardDescriptionColor,
      cardBgColor: themeContainer.cardBgColor,
    });
  }, [themeContainer]);
  return {
    cardTheme: theme,
  };
};
export default useCardTheme;
