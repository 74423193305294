import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Card, CardContent, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Logo from '../../assets/images/reveo_logo@1x.png';
import CustomButton from '../CustomButton/CustomButton';
import UploadLayout from '../UploadLayout';

const CardContentStyle = styled(CardContent)`
  text-align: center;
  padding-top: 0;
`;

const ReviewCommentText = styled.textarea`
  width: 85%;
  height: 100px;
  outline: none;
  border: 2px solid #a7a3a3 !important;
  border-radius: 6px;
  padding: 10px;
  font-size: 14px;
  font-family: Montserrat, regular;
`;

const CardStyle = styled(Card)`
  text-align: center;
  min-width: 100%;
  height: 100%;
  position: relative;
  // @media only screen and (max-width: 600px) {
  //   box-shadow: none;
  // }
`;

const StyledCustomRating = styled(Rating)`
  font-size: 40px !important;
  & svg {
    padding: 6px;
  }

  @media screen and (max-width: 330px) and {
    & {
      font-size: 24px !important;
    }
    & svg {
      padding: 8px;
    }
  }

  @media only screen and (min-device-width: 1000px) and (max-device-width: 1050px) {
    font-size: 24px !important;
    & svg {
      padding: 8px;
    }
  }
`;

const StarBorderIconStyle = styled(StarBorderIcon)`
  color: #ffb400 !important;
`;

const BottomContent = styled(CardContent)`
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 12px 0 !important;
`;

const QuestionTextStyle = styled(Typography)`
  color: #9e9a9a !important;
  font-family: Montserrat, bold !important;
  font-size: 1.2rem !important;
  // @media only screen and (min-device-width: 481px) and (max-device-width: 1000px) {
  //   font-size: 1rem !important;
  // }
`;
const MessageText = styled(Typography)`
  color: #9e9a9a !important;
  font-family: Montserrat, bold !important;
  font-size: 14px !important;
  // @media only screen and (min-device-width: 481px) and (max-device-width: 1000px) {
  //   font-size: 1rem !important;
  // }
`;

const sendBtn = {
  margin: '0 ',
  padding: '5px',
  color: '#fff',
  backgroundColor: '#000',
};

function RatingCard(props) {
  const { ratingSurvey, onClientFeedback, powerImageWidth, onClientRating, onSendCard, url } =
    props;
  const { title } = ratingSurvey;
  return (
    <CardStyle>
      <CardContentStyle>
        {url ? (
          <ThemeProvider theme={{ width: '85%', margin: '0 auto', border: 'none' }}>
            <UploadLayout
              handleClick={() => {}}
              url={`${process.env.REACT_APP_IMAGE_ENDPOINT}${url}`}
              imgHeight="350px"
              disabled
            />
          </ThemeProvider>
        ) : (
          <p />
        )}
      </CardContentStyle>
      <CardContentStyle>
        <QuestionTextStyle>{title}</QuestionTextStyle>
      </CardContentStyle>

      <CardContentStyle>
        <StyledCustomRating
          name="customized-empty"
          emptyIcon={<StarBorderIconStyle fontSize="inherit" />}
          onChange={(event, newValue) => {
            onClientRating(event, newValue);
          }}
        />
        <MessageText>Send a message</MessageText>
        <ReviewCommentText onChange={(e) => onClientFeedback(e.target.value)} />
        <ThemeProvider theme={sendBtn}>
          <CustomButton onClick={() => onSendCard()}>send</CustomButton>
        </ThemeProvider>
      </CardContentStyle>

      <CardContentStyle />
      <BottomContent>
        <div style={{ display: 'inline-grid' }}>
          <span style={{ fontSize: '6px', marginLeft: '14px' }}>Powered by</span>
          <img
            src={Logo}
            style={{ width: powerImageWidth ? `${powerImageWidth}em` : '2.3em' }}
            alt="logo"
          />
        </div>
      </BottomContent>
    </CardStyle>
  );
}

RatingCard.propTypes = {
  ratingSurvey: PropTypes.objectOf(PropTypes.string).isRequired,
  onClientFeedback: PropTypes.func.isRequired,
  onClientRating: PropTypes.func.isRequired,
  powerImageWidth: PropTypes.number.isRequired,
  onSendCard: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

RatingCard.defaultProps = {};

export default RatingCard;
