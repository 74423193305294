import React from 'react';
import styled from 'styled-components';

const StyledTypeBadge = styled.div`
  font-size: 8px;
  letter-spacing: 0.5px;
  background-color: ${(props) => props.theme.backgroundColor || '#d2ffee'};
  padding: 0.2rem 0.4rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.color || '#13ac71'};
  text-transform: uppercase;
  font-weight: 600;
  white-space: nowrap;
`;

export const ReviewRequestBadge = (props) => {
  const { children } = props;

  return <StyledTypeBadge>{children}</StyledTypeBadge>;
};
