import React, { useContext, useState, useEffect } from 'react';
import Proptypes from 'prop-types';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  ThemeProvider as MUIThemeProvider,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import styled, { ThemeProvider } from 'styled-components';
import ClearIcon from '@material-ui/icons/Clear';
import baseUrl from '../../utils/services/config';
import { errorChecker } from '../../utils/helper/helper';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import ButtonLoader from '../ButtonLoader/ButtonLoader';
import CustomButton from '../CustomButton/CustomButton';
import CusotmAlert from '../CustomAlert/CustomAlert';
import Loader from '../Loader/Loader';
import StyledTextField from '../GeneralInputField/GeneralInputField';
import TransperentButtonTheme from '../../themes/Button/TransperentButtonTheme';
import useInputFieldTheme from '../../hooks/useInputFieldTheme';
import GenericPopup from '../Popup/GenricPopup/index';
import useButtonTheme from '../../hooks/useButtonTheme';

const StyledContentDialog = styled(DialogContent)`
  padding: 0 0.5em !important;
`;

const StyledActions = styled(DialogActions)`
  padding: 0 !important;
`;

function IndustryPopup(props) {
  const { open, handleClose, selectedIndustry } = props;
  const { register, handleSubmit, errors, setValue } = useForm();
  const [showAlert, setAlert] = useState(false);
  const [loader, setLoader] = useState(false);
  const [renderView, setRenderView] = useState(true);
  const title = selectedIndustry ? 'Edit Industry' : 'Add Industry';
  const { dispatch } = useContext(GlobalContext);
  const { inputTheme } = useInputFieldTheme();
  const { buttonTheme } = useButtonTheme();

  useEffect(() => {
    if (selectedIndustry) {
      setRenderView(false);
      baseUrl.get(`industry/${selectedIndustry}`).then((res) => {
        if (res.status === 200) {
          setRenderView(true);
          setValue('name', res.data.name);
        }
      });
    }
  }, [selectedIndustry, setValue]);

  const onSubmit = (data) => {
    const body = {
      name: data.name,
      createdBy: parseInt(localStorage.getItem('USER_ID'), 10),
    };
    setLoader(true);
    if (selectedIndustry) {
      baseUrl
        .patch(`industry/${selectedIndustry}`, { ...body })
        .then(() => {
          setLoader(false);
          setAlert(true);
          setTimeout(() => {
            setAlert(false);
            handleClose();
          }, 1000);
        })
        .catch((err) => {
          setLoader(false);
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    } else {
      baseUrl
        .post(`/industry`, { ...body })
        .then(() => {
          setLoader(false);
          setAlert(true);
          setTimeout(() => {
            setAlert(false);
            handleClose();
          }, 1000);
        })
        .catch((err) => {
          setLoader(false);
          dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
        });
    }
  };

  const handleAlertClose = () => setAlert(false);

  const showLoader = loader ? <ButtonLoader /> : null;

  return renderView ? (
    <>
      <CusotmAlert
        open={showAlert}
        message={
          selectedIndustry ? 'Industry updated successfully' : 'Industry created successfully'
        }
        handleClose={handleAlertClose}
      />
      <GenericPopup open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          <Grid container justify="space-between">
            <Grid item>{title}</Grid>
            <Grid item>
              <ThemeProvider theme={TransperentButtonTheme}>
                <CustomButton onClick={handleClose} title="Close">
                  <ClearIcon />
                </CustomButton>
              </ThemeProvider>
            </Grid>
          </Grid>
        </DialogTitle>
        <MUIThemeProvider theme={inputTheme}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <StyledContentDialog>
              <Grid container justify="center">
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <StyledTextField
                    name="name"
                    onBlur={(e) => setValue('name', e.target.value.trim())}
                    label="Please enter a Industry *"
                    inputRef={register({
                      required: true,
                      validate: (value) => value.trim() !== '',
                    })}
                    error={errors.name}
                    helperText={errors.name ? 'Industry is a required field' : null}
                  />
                </Grid>
              </Grid>
            </StyledContentDialog>
            <StyledActions>
              <Grid container justify="center">
                <Grid item xs={12} sm={12} md={3} lg={4}>
                  <ThemeProvider
                    theme={{ background: buttonTheme.buttonBgColor, color: buttonTheme.fontColor3 }}
                  >
                    <CustomButton type="submit" color="primary" disabled={loader}>
                      {selectedIndustry ? 'Update' : 'Add'}
                      {showLoader}
                    </CustomButton>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </StyledActions>
          </form>
        </MUIThemeProvider>
      </GenericPopup>
    </>
  ) : (
    <Loader />
  );
}

IndustryPopup.propTypes = {
  open: Proptypes.bool.isRequired,
  handleClose: Proptypes.func.isRequired,
  selectedIndustry: Proptypes.number.isRequired,
};

export default React.memo(IndustryPopup);
