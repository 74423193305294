import { FormHelperText, ThemeProvider as MUIThemeProvider } from '@material-ui/core';

import React, { useContext, useEffect, useState } from 'react';
import { ContentState, EditorState, convertFromHTML } from 'draft-js';
import ArrowIcon from '@material-ui/icons/NearMe';
import EditIcon from '@material-ui/icons/Edit';
import Preview from '../../../../../../AddEmailTemplatePopover/components/Preview/Preview';
import baseUrl from '../../../../../../../../utils/services/config';
import { GlobalContext } from '../../../../../../../../Stores/GlobalStateStore';
import SingleChoice from '../../../../../../../SingleChoice/SingleChoice';
import { errorChecker } from '../../../../../../../../utils/helper/helper';
import StyledTrashIcon from '../../../../../../../Icons/TrashIcon';
import SendEmailTemplatePopover from '../../../../../../SendEmailTemplatePopover/SendEmailTemplatePopover';
import useModal from '../../../../../../../../hooks/useModal';
import useAlert from '../../../../../../../../hooks/useAlert';
import AddEmailTemplatePopover from '../../../../../../AddEmailTemplatePopover/AddEmailTemplatePopover';
import CancellationPopup from '../../../../../../../Popup/CancellationPopup/CancellationPopup';
import usePopupTheme from '../../../../../../../../hooks/usePopupTheme';
import StyledTextField from '../../../../../../../GeneralInputField/GeneralInputField';
import StyledFormControl from '../../../../../../../StyledFormControl';
import { NoData } from '../../../../../../../NoData/NoData';
import EmailOpenOutlineIcon from 'mdi-react/EmailOpenOutlineIcon';
import ContainerLoader from '../../../../../ContainerLoader';

const tabContainerStyles = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: '1.5rem',
  height: 'calc(55vh - 5rem)',
};

const TabEmail = ({
  templateSelected,
  setTemplateSelected,
  changeSubject,
  subjectValue,
  emailErrors,
  getTemplates,
  templates,
  type,
}) => {
  const [previewEmailBody, setPreviewEmailBody] = useState(EditorState.createEmpty());
  const [previewEmailFooter, setPreviewEmailFooter] = useState(EditorState.createEmpty());
  const [uploadedFile, setUploadedFile] = useState(null);

  const deletionPopup = useModal();
  const newEmailTemplate = useModal();
  const sendEmailTemplate = useModal();
  const templateAlert = useAlert();
  const { popUpTheme } = usePopupTheme();

  const [isLoading, setIsLoading] = useState(false);
  const { state, dispatch } = useContext(GlobalContext);

  const handleSend = () => {
    sendEmailTemplate.handleShowModal();
  };
  const handleEdit = () => {
    newEmailTemplate.handleShowModal();
  };

  const handleDelete = () => {
    deletionPopup.handleShowModal();
  };
  const convertFromHTMLContent = (htmlContentString) => {
    const blocksFromHTML = convertFromHTML(htmlContentString);
    const contentState = ContentState.createFromBlockArray(blocksFromHTML);

    return EditorState.createWithContent(contentState);
  };

  const getEmailTemplateById = async (id) => {
    try {
      const result = await baseUrl.get(`/emailTemplates/${id}`);
      if (result.status === 200) {
        const { footer, body, headerFile } = result.data;
        if (body.length > 0) {
          setPreviewEmailBody(convertFromHTMLContent(body));
        } else {
          setPreviewEmailBody(EditorState.createEmpty());
        }
        if (footer.length > 0) {
          setPreviewEmailFooter(convertFromHTMLContent(footer));
        } else {
          setPreviewEmailFooter(EditorState.createEmpty());
        }
        if (headerFile) {
          setUploadedFile(headerFile);
        } else {
          setUploadedFile(null);
        }
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      setIsLoading(false);
    }
  };

  const handleCampaignDeletionPopup = async () => {
    const { handleHideShowModal } = deletionPopup;
    const { handleSetMessage, handleSetMessageType, handleShow } = templateAlert;
    try {
      setIsLoading(true);
      const postBody = {
        isDeleted: true,
      };
      const result = await baseUrl.patch(`/emailTemplates/${templateSelected.id}`, { ...postBody });
      if (result.status === 200) {
        handleSetMessageType('success');
        handleSetMessage('template deleted successfully');
        handleHideShowModal();
        handleShow(true);
        setIsLoading(false);
        getTemplates();
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   getTemplates();
  // }, []);

  useEffect(() => {
    if (Object.keys(templateSelected).length > 0) {
      getEmailTemplateById(templateSelected.id);
    }
  }, [templateSelected]);

  return (
    <>
      {isLoading ? <ContainerLoader /> : null}
      <div style={tabContainerStyles}>
        <div style={{ overflowY: 'auto' }}>
          <SingleChoice
            options={templates.map((template) => ({
              id: template.id,
              value: template.id,
              label: template.name,
            }))}
            optionsOnPress={[
              {
                text: 'Edit',
                onPress: handleEdit,
                Icon: EditIcon,
              },
              {
                text: 'Send Test',
                onPress: handleSend,
                Icon: ArrowIcon,
              },
              {
                text: 'Delete',
                onPress: handleDelete,
                Icon: StyledTrashIcon,
              },
            ]}
            currentOption={templateSelected}
            setCurrentOption={setTemplateSelected}
            withRadioButton
            isLoading={isLoading}
          />
        </div>
        {!Object.keys(templateSelected).length ? (
          <NoData
            style={{ height: '100%' }}
            icon={<EmailOpenOutlineIcon />}
            title="Select an Email Template"
          ></NoData>
        ) : (
          <div style={{ overflowY: 'auto', marginBottom: '2.5rem' }}>
            <div style={{ position: 'sticky', top: '0', background: '#f5f5f5', zIndex: 3 }}>
              <StyledTextField
                value={subjectValue}
                placeholder="Email Subject *"
                onChange={(e) => changeSubject(e.target.value)}
                label="Email Subject *"
              />
            </div>
            {emailErrors?.emailSubject && (
              <StyledFormControl error={!!emailErrors?.emailSubject}>
                <FormHelperText>{emailErrors?.emailSubject}</FormHelperText>
              </StyledFormControl>
            )}
            <Preview
              previewEmailBody={previewEmailBody}
              previewEmailFooter={previewEmailFooter}
              uploadedFile={uploadedFile}
              noEmptyTitle
            />
          </div>
        )}
      </div>

      <SendEmailTemplatePopover
        handleClose={sendEmailTemplate.handleHideShowModal}
        open={sendEmailTemplate.showModal}
        anchorEl={sendEmailTemplate.anchorEl}
        templateIdSelected={templateSelected.id}
        templateAlert={templateAlert}
      />
      <MUIThemeProvider theme={popUpTheme}>
        <CancellationPopup
          show={deletionPopup.showModal}
          showDeletionPopUp={handleCampaignDeletionPopup}
          handleClose={() => deletionPopup.handleHideShowModal()}
          deleteLoader={isLoading}
        />
      </MUIThemeProvider>
      <AddEmailTemplatePopover
        handleClose={newEmailTemplate.handleHideShowModal}
        open={newEmailTemplate.showModal}
        anchorEl={newEmailTemplate.anchorEl}
        templateIdSelected={templateSelected.id}
        getTemplates={getTemplates}
      />
    </>
  );
};

export default TabEmail;
