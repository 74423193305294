import PropTypes from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const EScrollDirection = {
  Up: 'up',
  Down: 'down',
};

EScrollDirection.propTypes = {
  Up: PropTypes.string.isRequired,
  Down: PropTypes.string.isRequired,
};
