const OnBoardingReducer = (state, action) => {
  switch (action.type) {
    case 'SHOW_POPUP':
      return {
        ...state,
        showPopUp: true,
      };

    case 'HIDE_POPUP':
      return {
        ...state,
        showPopUp: false,
      };

    case 'SET_USER_NAME':
      return {
        ...state,
        userName: action.payload,
      };

    case 'SET_SEO_CATEGORIES':
      return {
        ...state,
        seoCategories: Array.from(action.payload),
      };

    case 'SET_INDUSTRIES_CATEGORIES':
      return {
        ...state,
        industries: Array.from(action.payload),
      };

    case 'SET_LOCATION_LIST':
      return {
        ...state,
        locationList: Array.from(action.payload),
      };

    case 'SET_USER_LIST':
      return {
        ...state,
        userList: Array.from(action.payload),
      };

    case 'SET_PORTAL_LIST':
      return {
        ...state,
        portalList: Array.from(action.payload),
      };

    case 'SET_BUSNIENSS_DETALS':
      return {
        ...state,
        businessDetails: { ...action.payload },
      };

    case 'SET_LOCATION_DETAILS':
      return {
        ...state,
        locationDetails: { ...action.payload },
      };

    case 'SET_LOCATION_CARD_DETAILS':
      return {
        ...state,
        locationCardDetails: { ...action.payload },
      };

    case 'SET_CAMPAIGN_DETAILS':
      return {
        ...state,
        campaignDetails: { ...action.payload },
      };

    case 'SET_SELECTED_PORTAL':
      return {
        ...state,
        selectedPortal: action.payload,
      };

    case 'SET_DEFAULT_PORTAL':
      return {
        ...state,
        defaultPortals: action.payload,
      };

    case 'SET_SELECTED_PORTAL_RADIO_VALUE':
      return {
        ...state,
        radioValue: action.payload,
      };

    case 'SET_POST_SITES':
      return {
        ...state,
        postSites: Array.from(action.payload),
      };

    case 'SET_CAMPAIGN_TYPE':
      return {
        ...state,
        campaignType: action.payload,
      };

    case 'SET_ALLOW_TEXT_FEEDBACK':
      return {
        ...state,
        allowTextFeedback: action.payload,
      };

    case 'SHOW_INVITE_POPUP':
      return {
        ...state,
        showInvitePopup: true,
      };

    case 'HIDE_INVITE_POPUP':
      return {
        ...state,
        showInvitePopup: false,
        isWizardMode: false,
      };

    case 'START_ONBOARD_WIZARD':
      return {
        ...state,
        isWizardMode: true,
      };

    case 'STOP_ONBOARD_WIZARD':
      return {
        ...state,
        isWizardMode: false,
      };
    case 'SAVE_LOCAL_ANSWERS':
      let found = false;
      let newLocalAnswers = state.localAnswers.map((item) => {
        if (item.questionCode === action.payload.questionCode) {
          found = true;
          return action.payload;
        }
        return item;
      });

      if (!found) {
        newLocalAnswers = [...state.localAnswers, action.payload];
      }
      return {
        ...state,
        localAnswers: newLocalAnswers,
      };
    default:
      return state;
  }
};

export default OnBoardingReducer;
