import React from 'react';
import { ListItemIcon, MenuList, MenuItem, Paper, makeStyles, Popover } from '@material-ui/core';
import styled from 'styled-components';

const StyledListItemText = styled.div`
  font-family: 'Montserrat' !important;
  font-weight: 600;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 36px !important;
`;

const CampaignTemplatePopover = ({ open, handleClose, anchorEl, optionsOnPress, campaignId }) => {
  const useStyles = makeStyles((theme) => ({
    paper: {
      overflow: 'hidden',
      background: theme.background,
    },
  }));

  const classes = useStyles();
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      classes={{
        paper: classes.paper,
      }}
    >
      <Paper sx={{ width: 320, maxWidth: '100%' }}>
        <MenuList dense onClick={handleClose}>
          {optionsOnPress &&
            optionsOnPress.length > 0 &&
            optionsOnPress.map((option) => {
              const { Icon } = option;
              return (
                <MenuItem key={option.text} onClick={option.onPress}>
                  <StyledListItemIcon>
                    <Icon fontSize="small" />
                  </StyledListItemIcon>
                  <StyledListItemText>{option.text}</StyledListItemText>
                </MenuItem>
              );
            })}
        </MenuList>
      </Paper>
    </Popover>
  );
};

export default CampaignTemplatePopover;
