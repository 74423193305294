import SMSImageUpload from '../../SMSImageUpload/SMSImageUpload';
import EditCampaign from './components/EditCampaign';
import CusotmAlert from '../../CustomAlert/CustomAlert';
import React, { useRef, useEffect } from 'react';
import useAlert from '../../../hooks/useAlert';
import { getLogo } from '../../../utils/helper/helper';
import baseUrl from '../../../utils/services/config';

const AttachElementsGallery = ({
  campaign,
  setCampaign,
  attachElements,
  showEditCampaign,
  setShowEditCampaign,
  userLink,
  setUserLink,
  hasAttachments,
  setIsLoading,
  customStyles,
}) => {
  const fileInputRef = useRef(null);
  const ContactAlert = useAlert();

  const isAllowedFileType = (type) => {
    switch (type) {
      case 'pdf':
        return true;
      case 'xls':
        return true;
      case 'jpeg':
        return true;
      case 'jpg':
        return true;
      case 'png':
        return true;
      case 'csv':
        return true;
      default:
        return false;
    }
  };

  const handleEditCampaingSubmit = (value) => {
    setUserLink({
      titleLink: value.title,
      link: value.link,
    });
  };

  const handleAttachElementsDelete = (index) => {
    if (index < 0 || index >= attachElements.length) {
      return;
    }
    const IdAttach = attachElements[index].id;
    const updatedUploadedFile = campaign?.uploadedFile.filter((item) => item?.id !== IdAttach);

    setCampaign({
      ...campaign,
      uploadedFile: [...updatedUploadedFile],
    });
  };

  useEffect(() => {
    if (hasAttachments && campaign?.uploadedFile?.length === 0) {
      fileInputRef.current.click();
    }
  }, [hasAttachments]);

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };
  const handleFileInputChange = (event) => {
    const { handleSetMessage, handleSetMessageType, handleShow } = ContactAlert;
    const selectedFiles = event.target.files;
    const files = new FormData();

    for (let i = 0; i < selectedFiles.length; i++) {
      const typeFile = selectedFiles[i].name.split('.')[1].toLowerCase();
      if (isAllowedFileType(typeFile)) {
        files.append('picture', selectedFiles[i]);
      } else {
        handleSetMessageType('error');
        handleSetMessage(`file type not allowed: ${typeFile}`);
        handleShow(true);
      }
    }

    baseUrl
      .post(`uploads/campaign?meta=attachment&type=campaign`, files)
      .then((res) => {
        if (res.status === 200) {
          setCampaign({
            ...campaign,
            uploadedFile: [...campaign.uploadedFile, ...res.data],
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <div style={{ display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
      <CusotmAlert
        message={ContactAlert.message}
        handleClose={ContactAlert.handleClose}
        open={ContactAlert.show}
        messageType={ContactAlert.messageType}
      />
      {
        // eslint-disable-next-line array-callback-return
        attachElements.map((attachment, index) => {
          const attachmentType =
            attachment && attachment !== undefined && attachment.name
              ? attachment.name.split('.')[1].toLowerCase()
              : '';
          return (
            <SMSImageUpload
              key={index}
              width={(customStyles && customStyles.width + 'px') || '75px'}
              height={(customStyles && customStyles.height + 'px') || '75px'}
              imageCustomStyle={{ borderRadius: '15px' }}
              showDeleteOption
              handleDelete={() => handleAttachElementsDelete(index)}
              url={getLogo(attachmentType, attachment?.url)}
            />
          );
        })
      }
      <div>
        <input
          onChange={handleFileInputChange}
          ref={fileInputRef}
          type="file"
          style={{ display: 'none' }}
          multiple
        />
        <SMSImageUpload
          width={(customStyles && customStyles.width + 'px') || '75px'}
          height={(customStyles && customStyles.height - 7 + 'px') || '67px'}
          imageCustomStyle={{ borderRadius: '15px' }}
          handleClick={handleFileInputClick}
          hideLabel
          url=""
        />
      </div>
      <EditCampaign
        handleClose={() => {
          setShowEditCampaign(false);
        }}
        open={showEditCampaign}
        handleSubmit={handleEditCampaingSubmit}
        isAddLink
        linkData={{
          title: userLink.titleLink,
          link: userLink.link,
        }}
      />
    </div>
  );
};

export default AttachElementsGallery;
