import React, { useContext, useState } from 'react';
import Proptypes from 'prop-types';
import { Grid } from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
// import styled from 'styled-components';
import GetAppIcon from '@material-ui/icons/GetApp';
import PageTitle from '../../../components/PageTitle/PageTitle';
import Dropdown from '../../../components/Dropdown/Dropdown';
import NoBorderDropDownTheme from '../../../themes/Dropdown/NoBorderDropDownTheme';
import CustomButton from '../../../components/CustomButton/CustomButton';
import FileUploader from '../../../components/FileUploader/FileUploader';
import StyledReportProblemIcon from '../../../components/Icons/ReportProblemIcon';
// import GroupFilter from '../../../components/Filters/GroupFilter';
// import StyledTuneIcon from '../../../components/Icons/TuneIcon';
import baseUrl from '../../../utils/services/config';
import { GlobalContext } from '../../../Stores/GlobalStateStore';
import {
  errorChecker,
  ConvertIntoLocationDropDown,
  selectAllContacts,
} from '../../../utils/helper/helper';
import Loader from '../../../components/Loader/Loader';
import useIconTheme from '../../../hooks/useIconTheme';
import useButtonTheme from '../../../hooks/useButtonTheme';
import CSVfile from '../../../assets/files/smpleCSV.csv';
import theme from '../../../setup';

const StyledHintText = styled.div`
  letter-spacing: 1.08px;
  color: #6c6c6c;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 400 !important;
  display: flex;
  align-items: center;
`;

const StyledDownloadIcon = styled(GetAppIcon)`
  color: ${theme.btn_background_color} !important;
`;

function GroupUpload(props) {
  const { handleNext } = props;
  const [value, setValue] = useState(null);
  const [fileContainer, setFileContainer] = useState(null);
  const [columnsWidth] = useState(12);
  // const [toggleFilterView, setToggleFilterView] = useState(false);
  const { state, dispatch } = useContext(GlobalContext);
  const { groupContainer, selectedBusiness, locations } = state;
  const { iconsTheme } = useIconTheme();
  const { buttonTheme } = useButtonTheme();

  const handleGetFile = (file) => {
    setValue(file[0].name);
    setFileContainer(file[0]);
  };

  const handleFileImport = async () => {
    try {
      dispatch({ type: 'SHOW_GROUP_LOADER' });
      const clients = new FormData();
      clients.append('clients', fileContainer);
      const result = await baseUrl.post(
        `/group/client/uploads?location=${groupContainer.selectedGroupLocation}&business=${selectedBusiness}`,
        clients,
      );
      if (result.status === 200) {
        dispatch({ type: 'SET_GROUP_CONTAINER', payload: Array.from(result.data) });
        dispatch({
          type: 'SET_SELECTED_CONTACTS',
          payload: Array.from(selectAllContacts(result.data, 'unsubscribe')),
        });
        if (!groupContainer.loader) {
          handleNext(2);
        }
      }
    } catch (err) {
      dispatch({ type: 'HIDE_GROUP_LOADER' });
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  // Effect to handle view Toggling
  // useEffect(() => {
  //   if (toggleFilterView) {
  //     setColumnsWidth(9);
  //   } else {
  //     setColumnsWidth(12);
  //   }
  // }, [toggleFilterView]);

  const handleSelectLocation = (e) => {
    dispatch({ type: 'SET_GROUP_LOCATION', payload: e.target.value });
  };

  // const groupViewContent = toggleFilterView ? <GroupFilter /> : null;

  const loaderContainer = groupContainer.loader ? <Loader /> : null;

  return (
    <Grid container style={{ height: '100%' }}>
      {loaderContainer}
      <Grid item xs={12} sm={12} md={columnsWidth} lg={columnsWidth} style={{ height: '100%' }}>
        <Grid container direction="column" style={{ height: '100%' }} wrap="nowrap">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ margin: '1em', flex: ' 1 1 auto', minHeight: '40px', maxHeight: '40px' }}
          >
            <Grid container alignItems="center">
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <PageTitle title="New Group" />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Grid container alignItems="center" justify="flex-end">
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <ThemeProvider theme={NoBorderDropDownTheme}>
                      <Dropdown
                        listOptions={ConvertIntoLocationDropDown(locations)}
                        label="Select Location"
                        handleChange={handleSelectLocation}
                        value={groupContainer.selectedGroupLocation}
                      />
                    </ThemeProvider>
                  </Grid>
                  {/* <Grid item xs={2} sm={2} md={2} lg={2}>
                        <ThemeProvider theme={{ color: '#fff', margin: '0' }}>
                          <CustomButton onClick={() => setToggleFilterView((prev) => !prev)}>
                            <StyledTuneIcon />
                          </CustomButton>
                        </ThemeProvider>
                      </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ flex: '1 1 auto', maxHeight: 'calc(100% - 120px)', overflow: 'auto' }}
          >
            <Grid container alignItems="center" style={{ height: '100%' }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ padding: '1em 1em 3.5em 1em', height: '100%' }}
              >
                <ThemeProvider theme={{ height: '100%' }}>
                  <FileUploader
                    handleGetFile={handleGetFile}
                    value={value}
                    icon={
                      <ThemeProvider
                        theme={{ fontSize: '28px', color: iconsTheme.reportProblemIcon }}
                      >
                        <StyledReportProblemIcon />
                      </ThemeProvider>
                    }
                    title="Upload or select CSV file"
                    heading="No contacts found"
                    accept=".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"
                  />
                  <StyledHintText>
                    <span>Download Sample CSV file </span>
                    <span>
                      <a href={CSVfile} download title="Sample CSV">
                        <StyledDownloadIcon />
                      </a>
                    </span>
                  </StyledHintText>
                </ThemeProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ flex: '1 0 auto', minHeight: '40px', maxHeight: '40px' }}
          >
            <Grid container justify="flex-end" alignItems="center">
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <ThemeProvider
                  theme={{
                    margin: '0',
                    background: buttonTheme.buttonBgColor,
                    color: buttonTheme.buttonColor2,
                  }}
                >
                  <CustomButton disabled={!fileContainer} onClick={handleFileImport}>
                    Preview Contacts
                  </CustomButton>
                </ThemeProvider>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* {groupViewContent} */}
    </Grid>
  );
}

GroupUpload.propTypes = {
  handleNext: Proptypes.func.isRequired,
};

export default GroupUpload;
