import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import Loader from '../components/Loader/Loader';
import baseUrlLogin from '../utils/services/auth.config';
import { getParameterByName } from '../utils/helper/helper';
import CusotmAlert from '../components/CustomAlert/CustomAlert';
import StyledCheckCircle from '../components/Icons/CheckedCircle';
import useIconTheme from '../hooks/useIconTheme';
import StyledCloseIcon from '../components/Icons/CloseIcon';

function Unsubscribe() {
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [succsess, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Invalid Link');
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const { iconsTheme } = useIconTheme();
  useEffect(() => {
    const code = getParameterByName('id');
    setLoader(true);
    baseUrlLogin(`/email-unsubscribe?id=${code}`)
      .then((res) => {
        if (res.status === 200) {
          setShowAlert(true);
          setLoader(false);
          setSuccess(true);
        }
      })
      .catch((err) => {
        setShowAlert(true);
        setShowError(true);
        setLoader(false);
        setErrorMessage(err.response.data.message);
      });
  }, [history]);

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const iconContainer =
    !loader && !showError && succsess ? (
      <ThemeProvider theme={{ color: iconsTheme.checkCircleIcon, fontSize: '1.5em' }}>
        <StyledCheckCircle />
      </ThemeProvider>
    ) : (
      <ThemeProvider theme={{ color: iconsTheme.dataUsageIcon, fontSize: '1.5em' }}>
        <StyledCloseIcon />
      </ThemeProvider>
    );

  const iconContent = loader && !showError ? <CircularProgress /> : iconContainer;

  const textContainer = !loader && !showError ? 'Unsubscribed Successfully' : errorMessage;
  const textContent = loader && !showError ? 'Unsubscribing email' : textContainer;

  return (
    <div>
      <CusotmAlert
        open={showAlert}
        message={showError ? errorMessage : 'Email unsubscribed successfully'}
        handleClose={handleCloseAlert}
        messageType={showError ? 'error' : 'sucess'}
      />
      <Loader title={textContent} Icon={iconContent} />
    </div>
  );
}

export default Unsubscribe;
