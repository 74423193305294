const CampaignReducer = (state, action) => {
  switch (action.type) {
    case 'SET_CAMPAIGN_CONTAINER':
      const containerOrder = ['message', 'review request', 'survey', 'email template'];

      const sortedContainer = action.payload.sort((a, b) => {
        const aIndex = containerOrder.indexOf(a.campaignType);
        const bIndex = containerOrder.indexOf(b.campaignType);

        // First, sort by campaign type
        if (aIndex !== bIndex) {
          return aIndex - bIndex;
        }

        // If campaign types are the same, then sort by name
        return a.name.localeCompare(b.name);
      });

      return {
        ...state,
        campaignContainer: sortedContainer,
        loader: false,
      };

    case 'UPDATE_CAMPAIGN_CONTAINER':
      const combinedCampaigns = [...state.campaignContainer, ...action.payload];

      const campaignTypeOrder = ['message', 'review request', 'survey', 'email template'];

      const sortedCampaigns = combinedCampaigns.sort((a, b) => {
        const aIndex = campaignTypeOrder.indexOf(a.campaignType);
        const bIndex = campaignTypeOrder.indexOf(b.campaignType);

        // First, sort by campaign type
        if (aIndex !== bIndex) {
          return aIndex - bIndex;
        }

        // If campaign types are the same, then sort by name
        return a.name.localeCompare(b.name);
      });

      return {
        ...state,
        campaignContainer: sortedCampaigns,
        loader: false,
      };

    case 'REMOVE_CAMPAIGN':
      const updatedCampaigns = state.campaignContainer.filter(
        (campaign) => campaign.id !== action.payload,
      );
      return {
        ...state,
        campaignContainer: updatedCampaigns,
      };

    case 'UPDATE_CAMPAIGN_ITEM':
      const updatedItem = action.payload.at(0);
      let updatedContainer;

      if (
        action.payload.some(
          (item) => item.currentLocation && updatedItem.currentLocation !== item.location[0].id,
        )
      ) {
        updatedContainer = state.campaignContainer.filter((item) => item.id !== updatedItem.id);
      } else {
        updatedContainer = state.campaignContainer.map((item) => {
          if (item.id === updatedItem.id) {
            return updatedItem;
          }
          return item;
        });
      }

      return {
        ...state,
        campaignContainer: updatedContainer,
      };

    case 'SET_PORTAL_CONTAINER':
      return {
        ...state,
        portalContainer: Array.from(action.payload),
        loader: false,
      };
    case 'UPDATE_CAMPAIGN_CONTAINER_URL': {
      const stateCopy = { ...state };
      stateCopy.campaignContainer.filter((element, index) => {
        if (element.id === action.payload.id) {
          stateCopy.campaignContainer[index].url =
            action.payload.imgData && action.payload.imgData.length
              ? action.payload.imgData[0].url
              : 'No Image Found';
        }
        return null;
      });
      return {
        ...state,
        ...stateCopy,
      };
    }

    case 'UPDATE_CAMPAIGN_CONTAINER_ISDELETED': {
      const stateCopy = { ...state };
      stateCopy.campaignContainer.filter((element, index) => {
        if (element.id === action.payload.id) {
          stateCopy.campaignContainer[index].isDeleted = action.payload.isDeleted;
        }
        return null;
      });
      return {
        ...state,
        ...stateCopy,
      };
    }

    case 'UPDATE_CAMPAIGN_CONTAINER_IS_ACTIVE': {
      const stateCopy = { ...state };
      stateCopy.campaignContainer.filter((element, index) => {
        if (element.id === action.payload.id) {
          stateCopy.campaignContainer[index].isActive = action.payload.isActive;
        }
        return null;
      });
      return {
        ...state,
        ...stateCopy,
      };
    }

    case 'SHOW_LOADER':
      return {
        ...state,
        loader: true,
      };
    case 'HIDE_LOADER':
      return {
        ...state,
        loader: false,
      };
    case 'SET_SEND_INVITE_CAMPAIGN':
      return {
        ...state,
        sendInviteCampaigns: Array.from(action.payload),
        loader: false,
      };

    case 'INIT_SEND_CAMPAIGN_DATA':
      return {
        ...state,
        sendInviteCampaigns: [],
      };

    case 'UPDATE_SEND_INVITE_CAMPAIGN_CONTAINER': {
      const stateCopy = { ...state };
      stateCopy.sendInviteCampaigns.filter((element, index) => {
        if (element.id === action.payload.id) {
          stateCopy.sendInviteCampaigns[index].url =
            action.payload.imgData && action.payload.imgData.length
              ? action.payload.imgData[0].url
              : 'No Image Found';
        }
        return null;
      });
      return {
        ...state,
        ...stateCopy,
      };
    }

    case 'SET_CAMPAIGN_ID': {
      return {
        ...state,
        campaignToEdit: action.payload,
      };
    }
    default:
      return state;
  }
};

export default CampaignReducer;
