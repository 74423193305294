import React, { createContext, useReducer } from 'react';
import Proptypes from 'prop-types';
import AccountState from '../state/AccountState';
import AccountReducer from '../Reducers/AccountReducer';

export const AccountContext = createContext(AccountState);

const AccountStore = ({ children }) => {
  const [accountState, accountDispatch] = useReducer(AccountReducer, AccountState);
  return (
    <AccountContext.Provider value={{ accountState, accountDispatch }}>
      {children}
    </AccountContext.Provider>
  );
};

AccountStore.propTypes = {
  children: Proptypes.node.isRequired,
};

export default AccountStore;
