import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import loginLeftSideImage from '../assets/images/right_login_background.jpg';
import Logo from '../assets/images/Reveo_Logo_New.svg';
import GeneralInputField from '../components/GeneralInputField/GeneralInputField';
import baseUrlLogin from '../utils/services/auth.config';
import CusotmAlert from '../components/CustomAlert/CustomAlert';
import ButtonLoader from '../components/ButtonLoader/ButtonLoader';
import CustomButton from '../components/CustomButton/CustomButton';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    height: '100%',
    width: '100%',
  },

  loginRightSection: {
    backgroundImage: `url(${loginLeftSideImage})`,
    height: '100%',
    backgroundSize: 'cover',
    display: 'flex',
    padding: '0 2rem 0 5rem',
    fontFamily: 'Montserrat',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  headerText: {
    color: '#fff',
    fontWeight: 700,
    fontSize: '50px',
    textTransform: 'uppercase',
    lineHeight: 1,
    marginBottom: '10px',
  },
  benefitList: {
    color: '#fff',
    width: '80%',
    lineHeight: 2.5,
  },
  benefitListItem: {
    lineHeight: 1.5,
    marginBottom: '20px',
    '&::marker': {
      color: '#30feaf',
    },
  },
  subtitleText: {
    fontWeight: 700,
    fontSize: '18px',
    color: '#30feaf',
    marginBottom: '20px',
  },
  loginLeftSection: {
    backgroundColor: '#f5f5f5',
    height: '100vh',
    position: 'relative',
  },
  loginForm: {
    top: '50%',
    position: 'absolute',
    transform: 'translateY(-50%)',
    textAlign: 'center',
  },
  form: {
    width: '50%',
  },
  logo: {
    width: '200px',
    marginBottom: '20px',
  },
  items: {
    padding: '10px',
  },
}));

const buttonTheme = {
  boxShadow: '5px 2px 14px -3px',
};

const forgetPasswordTheme = {
  boxShadow: '5px 2px 14px -3px',
  border: '1px solid #333',
  background: 'transparent',
};

function ForgetPassword() {
  const classes = useStyles();
  const history = useHistory();
  const [showAlert, setAlert] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [loader, setLoader] = useState(false);
  const { register, handleSubmit, errors } = useForm({ userName: '' });

  const redirectToLogin = () => {
    history.push('/login');
    setServerError(false);
  };

  const onSubmit = (data) => {
    setLoader(true);
    baseUrlLogin
      .post('/account/password-recovery', { ...data })
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          setAlert(true);
          setServerError(false);
          setTimeout(() => {
            setAlert(false);
            history.push('/');
          }, 2000);
        }
      })
      .catch(() => {
        setLoader(false);
        setServerError(true);
        setAlert(true);
      });
  };

  const handleCloseAlert = () => {
    setAlert(false);
  };

  const showLoader = loader ? <ButtonLoader /> : null;

  return (
    <div className={classes.root}>
      <CusotmAlert
        open={showAlert}
        message={serverError ? 'Invalid email address' : 'Reset password link sent successfully'}
        handleClose={handleCloseAlert}
        messageType={serverError ? 'error' : 'success'}
      />
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6}>
          <div className={classes.loginLeftSection}>
            <Grid
              className={classes.loginForm}
              item
              xs={12}
              sm={12}
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item className={classes.items}>
                <img src={Logo} className={classes.logo} alt="logo" />
              </Grid>
              <Grid item className={classes.items}>
                <Typography variant="subtitle1" gutterBottom>
                  Please enter username of the account you wish to reset.
                </Typography>
              </Grid>
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className={classes.form}>
                <Grid item className={classes.items}>
                  <GeneralInputField
                    name="emailAddress"
                    type="email"
                    error={!!errors.emailAddress}
                    label="Username"
                    inputRef={register({ required: true })}
                    helperText={errors.emailAddress ? 'Username is a required field' : null}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12} sm={12}>
                  <Grid container>
                    <Grid item xs={12} md={6} lg={6} sm={12}>
                      <ThemeProvider theme={buttonTheme}>
                        <CustomButton type="submit">
                          Reset
                          {showLoader}
                        </CustomButton>
                      </ThemeProvider>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} sm={12}>
                      <ThemeProvider theme={forgetPasswordTheme}>
                        <CustomButton onClick={redirectToLogin}>Back to Login</CustomButton>
                      </ThemeProvider>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.loginRightSection}>
            <span className={classes.headerText}>Refer a business, Reward yourself.</span>
            <span className={classes.subtitleText}>
              Refer a business to Reveo and get one month FREE
            </span>
            <ul className={classes.benefitList}>
              <li className={classes.benefitListItem}>
                Refer a colleague, friend, or anyone else in your network to Reveo.
              </li>
              <li className={classes.benefitListItem}>
                Once your referral sings up as a customer, you'll receive the the following month{' '}
                <strong>FREE</strong>
              </li>
              <li className={classes.benefitListItem}>
                Plus, there's no limit to the number of referrals you can earn, so keep the
                referrals coming.
              </li>
            </ul>
            <ThemeProvider theme={buttonTheme}>
              <CustomButton
                style={{ marginRight: '100px !important' }}
                onClick={() => window.open('https://reveo.com/refer/')}
              >
                Start Now
              </CustomButton>
            </ThemeProvider>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default ForgetPassword;
