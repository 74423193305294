import { useCallback, useContext, useEffect } from 'react';
import { GlobalContext } from '../Stores/GlobalStateStore';
import { errorChecker } from '../utils/helper/helper';
import baseUrl from '../utils/services/config';

const useFetchBusiness = () => {
  const { dispatch } = useContext(GlobalContext);
  const fetchBusiness = useCallback(async () => {
    try {
      const result = await baseUrl.get(
        `/business/list?id=${localStorage.getItem('USER_ID')}&deleted=false&page=0&offset=1000`,
      );
      if (result.status === 200) {
        dispatch({ type: 'SET_BUSINESS_CONTAINER', payload: Array.from(result.data.list) });
      }
    } catch (err) {
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  }, [localStorage.getItem('USER_ID')]);

  useEffect(() => {
    fetchBusiness();
  }, [fetchBusiness]);

  return { fetchBusiness: () => fetchBusiness() };
};

export default useFetchBusiness;
