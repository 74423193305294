import React, { useState } from 'react';
import { Box, ThemeProvider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import StyledDescriptionText from '../../../../DescriptionText';
import { ChromePicker } from 'react-color';

export const ColorPickerInput = (props) => {
  const { title, colorToChange, setBodyRequest, bodyRequest, selectedColor, setSelectedColor } =
    props;
  const [colorToChangePicker, setColorToChangePicker] = useState(false); // Define state and its setter function

  const useStyles = makeStyles(() => ({
    swatch: {
      padding: '3px',
      borderRadius: '1px',
      //boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
      borderRadius: '20px',
      width: '18px',
      height: '18px',
      border: '3px solid #f5f5f5',
    },
    popover: {
      position: 'absolute',
      zIndex: '2',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
  }));

  const classes = useStyles();

  const handleChange = (color) => {
    setColorToChangePicker(!colorToChangePicker);
    setSelectedColor(color);
  };

  const handleColorChangeComplete = (color) => {
    setBodyRequest((prevBodyRequest) => ({
      ...prevBodyRequest,
      colors: {
        ...prevBodyRequest.colors,
        [colorToChange]: {
          ...prevBodyRequest.colors[colorToChange],
          bgColor: color.rgb,
        },
      },
    }));
  };

  return (
    <Box display="flex" alignItems="flex-start" flexDirection="column">
      {title && <StyledDescriptionText>{title}</StyledDescriptionText>}
      <div
        className={classes.swatch}
        style={{
          backgroundColor: `rgb(${bodyRequest.colors[colorToChange].bgColor.r} ${bodyRequest.colors[colorToChange].bgColor.g} ${bodyRequest.colors[colorToChange].bgColor.b} / ${bodyRequest.colors[colorToChange].bgColor.a})`,
          borderColor: selectedColor === colorToChange ? '#30feaf' : '#f5f5f5',
        }}
        onClick={() => handleChange(colorToChange)}
      >
        <div className={classes.headerColor} />
      </div>
      {colorToChangePicker ? (
        <div className={classes.popover}>
          <div
            className={classes.cover}
            onClick={() => setColorToChangePicker(!colorToChangePicker)}
          />
          <ChromePicker
            color={bodyRequest.colors[colorToChange].bgColor}
            validateOnChange={false}
            onChange={(color) => setBodyRequest({ ...bodyRequest, [colorToChange]: color.rgb })}
            onChangeComplete={handleColorChangeComplete}
          />
        </div>
      ) : null}
    </Box>
  );
};

export default ColorPickerInput;
