import React from 'react';
import Proptypes from 'prop-types';
import { Grid, makeStyles, Popover, Button } from '@material-ui/core';
import UploaderButtons from '../../UploaderButtons/UploaderButtons';
import CustomButton from '../../CustomButton/CustomButton';
import StyledMenuItem from '../../Common/StyledMenuItem';

const useStyles = makeStyles((theme) => ({
  popover: {
    padding: '.5em',
    minWidth: '400px',
  },
  paper: {
    padding: theme.spacing(1),
    minWidth: '400px',
    background: theme.background,
  },
}));
const buttonTheme = {
  width: '100%',
  margin: '0',
};

function DynamicPopover(props) {
  const { id, open, anchorEl, handleClose, dynamicTags, handleAddTag } = props;

  const classes = useStyles();

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      className={classes.popover}
      classes={{
        paper: classes.paper,
      }}
    >
      {dynamicTags.map((dynamicTag, index) => (
        <StyledMenuItem
          key={index}
          value={dynamicTag.value}
          onClick={(e) => handleAddTag(e, dynamicTag.value)}
        >
          <div style={{ display: 'flex', columnGap: '.5rem', flex: 1 }}>
            {dynamicTag.label}
            <span style={{ color: '#bdbdbd' }}>{dynamicTag.actual}</span>
          </div>
        </StyledMenuItem>
      ))}
    </Popover>
  );
}

// AttachmentPopover.propTypes = {
//   id: Proptypes.string.isRequired,
//   open: Proptypes.bool.isRequired,
//   anchorEl: Proptypes.node.isRequired,
//   handleClose: Proptypes.func.isRequired,
//   handleBusinessCardChange: Proptypes.func.isRequired,
//   handleOtherAttachmentChange: Proptypes.func.isRequired,
//   otherCardFileName: Proptypes.string.isRequired,
//   businessCardFileName: Proptypes.string.isRequired,
// };

export default DynamicPopover;
