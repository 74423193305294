import React from 'react';
import Proptypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { Button, CircularProgress, FormControlLabel, Grid, Radio } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { withStyles } from '@material-ui/styles';
import StyledTrashIcon from '../Icons/TrashIcon';
import CustomButton from '../CustomButton/CustomButton';
import TransperentButtonTheme from '../../themes/Button/TransperentButtonTheme';
import ButtonLoader from '../ButtonLoader/ButtonLoader';

const StyledUploadLayout = styled.div`
  border: ${(props) => (props.theme.border ? props.theme.border : '2px dashed #ccc')};
  border-radius: ${(props) => (props.theme.borderRadius ? props.theme.borderRadius : '0px')};
  width: ${(props) => (props.theme.width ? props.theme.width : '100%')} !important;
  height: 100% !important;
  min-height: ${(props) => (props.theme.minHeight ? props.theme.minHeight : '220px')} !important;
  max-height: ${(props) => props.theme.maxHeight} !important;
  margin: ${(props) => props.theme.margin} !important;
  text-transform: inherit;
  color: #6c6c6c;
  font-family: 'Montserrat';
  position: relative;

  &:hover {
    & > button:nth-child(2) {
      display: block;
    }
  }

  & {
    & > button:nth-child(2) {
      display: none;
    }
  }
`;

const StyledUploadLayoutContainer = styled(Button)`
  width: 100% !important;
  height: 100% !important;
  min-height: 120px !important;
  padding: 0 !important;
  cursor: pointer !important;
`;

const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  & svg {
    font-size: 3em !important;
    color: #bfbfbf !important;
  }
`;

const StyledTitle = styled.div`
  color: #bfbfbf !important;
  padding: 0.5em 0;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
`;

const GreenRadio = withStyles({
  root: {
    padding: 0,
    '&$checked': {
      color: '#35EDA7',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

function UploadLayout(props) {
  const {
    title,
    icon,
    handleClick,
    showDeleteOption,
    handleDelete,
    url,
    id,
    showRadioOption,
    imgWidth,
    imgHeight,
    imgId,
    disabled,
    deleteId,
    imageCustomStyle = {},
    isLoading,
  } = props;

  const deleteButton = showDeleteOption ? (
    <ThemeProvider theme={{ ...TransperentButtonTheme, minWidth: '40px', maxWidth: '40px' }}>
      <CustomButton
        style={{ position: 'absolute', right: '1em', top: '.5em' }}
        onClick={() => handleDelete(imgId)}
      >
        {deleteId === imgId ? (
          <ButtonLoader />
        ) : (
          <ThemeProvider theme={{ color: '#c92929' }}>
            <StyledTrashIcon />
          </ThemeProvider>
        )}
      </CustomButton>
    </ThemeProvider>
  ) : null;

  const radioOption = showRadioOption ? (
    <FormControlLabel
      value={id}
      control={<GreenRadio color="default" />}
      label=""
      style={{
        position: 'absolute',
        left: '1em',
        top: '.5em',
        borderRadius: '30px',
      }}
    />
  ) : null;

  const contentContainer = url ? (
    <img src={url} alt="logo" style={{ width: imgWidth, height: imgHeight, ...imageCustomStyle }} />
  ) : (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {isLoading ? (
          <StyledIcon>
            <CircularProgress />
          </StyledIcon>
        ) : (
          <StyledIcon>{icon}</StyledIcon>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <StyledTitle>{title}</StyledTitle>
      </Grid>
    </Grid>
  );

  return (
    <StyledUploadLayout>
      <ThemeProvider theme={{ cursor: disabled ? 'text' : 'auto' }}>
        <StyledUploadLayoutContainer onClick={(e) => handleClick(e, id)} disabled={disabled}>
          {contentContainer}
        </StyledUploadLayoutContainer>
      </ThemeProvider>
      {deleteButton}
      {radioOption}
    </StyledUploadLayout>
  );
}

UploadLayout.propTypes = {
  title: Proptypes.string,
  icon: Proptypes.node,
  handleClick: Proptypes.func,
  showDeleteOption: Proptypes.bool,
  handleDelete: Proptypes.func,
  url: Proptypes.string,
  id: Proptypes.number,
  showRadioOption: Proptypes.bool,
  imgWidth: Proptypes.string,
  imgHeight: Proptypes.string,
  imgId: Proptypes.number.isRequired,
  disabled: Proptypes.bool,
  deleteId: Proptypes.number,
  isLoading: Proptypes.bool,
};

UploadLayout.defaultProps = {
  title: 'Upload Logo',
  icon: <PublishIcon />,
  handleClick: () => {},
  showDeleteOption: false,
  handleDelete: () => {},
  url: '',
  id: null,
  showRadioOption: false,
  imgWidth: '100%',
  imgHeight: '100%',
  disabled: false,
  deleteId: null,
  isLoading: false,
};

export default UploadLayout;
