import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Grid, makeStyles } from '@material-ui/core';
import logo from '../../../../../../../../assets/images/Reveo_Logo_Gray.svg';
import CompanyLogo from '../../../../../../../CompanyLogo/CompanyLogo';
import { imageURLCheck, getIcon } from '../../../../../../../../utils/helper/helper';

const CampaignPreview = (props) => {
  const {
    businessLogo,
    settings,
    feedbackText,
    postSites,
    imgSMS,
    message,
    locationInfo,
    isMessage,
  } = props;

  const useStyles = makeStyles(() => ({
    avatar: {
      width: '50px',
      height: '50px',
      borderRadius: '40px',
    },
    inviteText: {
      fontSize: '.6rem',
      color: '#9e9a9a',
      textAlign: 'center',
      width: '80%',
      marginTop: '.5rem',
    },
    allowTextFeedbackButton: {
      textAlign: 'center',
      width: '60%',
      backgroundColor: '#dfdfdf',
      padding: '.3rem',
      fontSize: '.6rem',
      whiteSpace: 'nowrap',
      borderRadius: '20px',
      fontWeight: 600,
      marginTop: '.7rem',
    },
    postSiteButtons: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '80%',
      justifyContent: 'center',
      columnGap: '.5rem',
      rowGap: '1rem',
      marginTop: '.2rem',
    },
    postSiteButton: {
      width: '40px',
      height: '40px',
      borderRadius: '5px',
      border: 'none',
      backgroundColor: '#fff',
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '#cacaca 0 0 10px',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'Montserrat',
      fontSize: '0.7rem',
      fontWeight: '500',
      color: '#000000',
    },
    postsiteName: {
      fontSize: '.3rem',
      transform: 'translateY(18px)',
      marginTop: '-7px',
      textTransform: 'capitalize',
    },
    postSiteIcon: {
      width: '24px',
      height: '24px',
    },
    message: {
      width: '80%',
      backgroundColor: '#f1f0f0',
      borderRadius: '10px',
      padding: '.5rem',
      fontSize: '9px',
    },
    smsImage: {
      width: '80%',
      height: '160px',
      objectFit: 'cover',
      borderRadius: '10px',
    },
  }));

  const classes = useStyles();

  const PostSite = (site) => {
    return (
      <div className={classes.postSiteButton}>
        <img
          alt="post site icon"
          className={classes.postSiteIcon}
          src={imageURLCheck(getIcon(site.site).icon)}
        />
        <div className={classes.postsiteName}>{site.site}</div>
      </div>
    );
  };

  const imageType = () => {
    return settings?.campaignUser !== 'none' ? (
      <img className={classes.avatar} src={imageURLCheck(settings.userImage?.url)} />
    ) : null;
  };

  function personalizeMessage(message) {
    const businessName = locationInfo.name;
    const customerName = 'Jane';
    return (
      message
        .replace('@customerName', customerName)
        .replace('@business', businessName)
        .replace(
          '@link',
          '<span style="color: #1977ff; text-decoration: underline">app.reveo.com/review-request?code=1o9h63</span>',
        ) + '<p style="margin-bottom: 0">Text STOP to opt out</p>'
    );
  }

  const content = isMessage ? (
    <div style={{ padding: '1rem' }}>
      {imgSMS ? (
        <img
          alt="sms image"
          className={classes.smsImage}
          src={`${process.env.REACT_APP_IMAGE_ENDPOINT}${imgSMS}`}
        />
      ) : null}
      <div
        className={classes.message}
        dangerouslySetInnerHTML={{ __html: personalizeMessage(message) }}
      />
    </div>
  ) : (
    <>
      <img alt="business logo" src={imageURLCheck(businessLogo)} width={'50%'} height={'auto'} />
      {imageType()}
      <div className={classes.inviteText}>{feedbackText}</div>
      <div className={classes.postSiteButtons}>
        {postSites.map((site) => (
          <PostSite key={site.name} site={site.name} />
        ))}
      </div>
      {settings.allowTextFeedback ? (
        <div className={classes.allowTextFeedbackButton}>Text us feedback </div>
      ) : null}
      <Grid
        item
        style={{
          position: 'absolute',
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '1rem',
          fontSize: '.2rem',
          alignItems: 'center',
          color: '#6a6a6a',
        }}
      >
        Powered By
        <ThemeProvider theme={{ width: '39px', height: 'auto', marginTop: '.2rem' }}>
          <CompanyLogo logo={logo} alt={logo} />
        </ThemeProvider>
      </Grid>
    </>
  );

  return (
    <div style={{ backgroundColor: '#fff', height: '100%', zIndex: 1 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: '2.2rem',
          rowGap: '.6rem',
        }}
      >
        {content}
      </div>
    </div>
  );
};

export default CampaignPreview;
