import React, { useRef, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { makeStyles, CircularProgress } from '@material-ui/core';
import {
  increase_brightness,
  generateSourcesList,
  makeStars,
  getSource,
} from './ReviewsWidget.logic';
import { Skeleton } from '@material-ui/lab';

const WidgetHeader = (props) => {
  const { settings, reviews, postSites } = props;

  const StyledContainer = styled.div`
    background-color: ${(props) => props.bgColor};
    border-radius: ${(props) => props.borderRadius};
    margin-bottom: ${(props) => props.gap};
    display: flex;
    flex-direction: column;
    position: relative;
  `;

  let textColor = settings && settings.isLight ? '#000000' : '#ffffff';
  let backgroundColor = settings && settings.isLight ? '#ffffff' : '#000000';
  let secondaryTextColor = settings && settings.isLight ? textColor + '91' : textColor + '91';
  let writeReviewButtonBorderRadius = settings && settings.reviewButton.isRounded ? '200px' : '5px';

  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);
  const tabBarRef = useRef(null);
  const allReviewsLinkRef = useRef(null);

  const [isNextMax, setIsNextMax] = useState(false);
  const [isPrevMax, setIsPrevMax] = useState(false);
  const [sources, setSources] = useState({});

  const sourcesKeys = Object.keys(sources);

  const useStyles = makeStyles(() => ({
    widget_header: {
      backgroundColor: `${settings.colors.containerBgColor}${settings.colors.containerBgOpacity}`,
      borderRadius: `${settings.radius}px`,
      marginBottom: settings.gap + 'px',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    tab_bar: {
      position: 'relative',
      display: 'flex',
      overflowX: 'hidden',
      WebkitOverflowScrolling: 'touch',
      alignItems: 'center',
    },
    mask_next: {
      '--mask-next': `linear-gradient(to right, rgba(0,0,0, 1) 20%, rgba(0,0,0, 1) 90%, rgba(0,0,0, 0) 96%, rgba(0,0,0, 0) 10%) left 100% / 100% 100% no-repeat`,
      WebkitMask: 'var(--mask-next)',
      mask: 'var(--mask-next)',
    },
    mask_prev: {
      '--mask-prev': `linear-gradient(to left, rgba(0,0,0, 1) 20%, rgba(0,0,0, 1) 90%, rgba(0,0,0, 0) 96%, rgba(0,0,0, 0) 10%) left 100% / 100% 100% no-repeat`,
      WebkitMask: 'var(--mask-prev)',
      mask: 'var(--mask-prev)',
    },
    fade_out: {
      opacity: 0,
    },
    fade_in: {
      opacity: 1,
    },
    hidden: {
      display: 'none',
    },
    tabBar_next: {
      position: 'absolute',
      height: '50px',
      width: '50px',
      right: 0,
      top: 0,
      zIndex: 1,
      transition: 'all 0.3s ease-out',
      cursor: 'pointer',
    },
    scroll_arrow: {
      transform: 'translate(10px,50%)',
      width: '24px',
      height: '24px',
    },
    scroll_arrow_previous: {
      rotate: '180deg',
      transform: 'translate(-10px,-50%)',
      width: '24px',
      height: '24px',
    },
    tabBar_next_svg: {
      transform: 'translate(10px,50%)',
      height: '24px',
      width: '24px',
    },
    tabBar_previous: {
      position: 'absolute',
      height: '50px',
      width: '50px',
      top: 0,
      zIndex: 1,
      transition: 'all 0.3s ease-out',
      cursor: 'pointer',
    },
    tabBar_previous_before: {
      left: '0px',
      backgroundImage: `linear-gradient(to left, rgba(243, 243, 243, 0.25), rgb(243, 243, 243) 50%)`,
    },
    tabBar_previous_svg: {
      transform: 'translateY(10px, 50%) rotate(180deg)',
      height: '24px',
      width: '24px',
    },

    overall_rating: {
      display: 'flex',
      justifyContent: 'space-between',
      ...(settings.widgetStyle === 'badge' || settings.widgetStyle === 'summary'
        ? { flexDirection: 'column', rowGap: '2rem', paddingBottom: '2.5rem' }
        : { alignItems: 'center' }),
      padding: '1.5rem',
      ...(settings.showTabs && {
        borderTop: `1px solid ${
          settings.isLight
            ? increase_brightness(textColor, 85)
            : increase_brightness(backgroundColor, 25)
        }`,
      }),
    },
    other_rating_stuff: {
      display: 'flex',
      alignItems: 'center',
      gap: '.5rem',
    },
    large_average_rating: {
      fontWeight: 600,
      fontSize: '24px',
      color: textColor,
    },
    main_stars: {
      display: 'flex',
      gap: '.15rem',
    },
    source_total_reviews: {
      alignSelf: 'flex-end',
      fontSize: '14px',
      color: secondaryTextColor,
      marginTop: '-.4rem',
      fontWeight: 400,
      lineHeight: '1.7rem',
    },
    source_rating: {
      fontSize: '24px',
      height: '40px',
      marginBottom: '.5rem',
      lineHeight: 2,
      color: textColor,
    },
    source_link: {
      cursor: 'pointer',
      fontSize: '.95rem',
      display: 'flex',
      alignItems: 'center',
      gap: '.5rem',
      height: '3rem',
      margin: '0 1rem',
      whiteSpace: 'nowrap',
      borderBottom: '3px solid transparent',
      color: textColor,
      '&.selected': {
        borderBottom: `3px solid ${settings.colors.brandColor}`,
      },
    },
    Review__ReviewSource: {
      display: 'flex',
      marginTop: '1rem',
      gap: '.5rem',
      alignItems: 'center',
      justifyContent: `${settings.reviewStyle === 'center' ? 'center;' : null}`,
    },
    Review__ReviewSourceImage: {
      width: '24px',
      borderRadius: '50%',
    },
    write_review_button: {
      borderRadius: `${writeReviewButtonBorderRadius}`,
      borderColor: `${
        settings.reviewButton.isSolid ? 'rgba(0, 0, 0, 0)' : settings.reviewButton.buttonColor
      }`,
      color: `${settings.reviewButton.textColor}`,
      fontStyle: 'normal',
      backgroundColor: `${
        settings.reviewButton.isSolid ? settings.reviewButton.buttonColor : 'transparent'
      }`,
      border: `3px solid ${settings.reviewButton.buttonColor}`,
      fontSize: '16px',
      fontWeight: 600,
      padding: '1rem 2rem',
      cursor: 'pointer',
      transition: 'all 0.3s ease-in-out',
      textDecoration: 'none',
      '&:hover': {
        backgroundColor: increase_brightness(settings.reviewButton.buttonColor, 30),
        transition: 'all 0.3s ease-in-out',
        color: increase_brightness(settings.reviewButton.buttonColor, 99),
      },
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    const sourcesResult = generateSourcesList(reviews, postSites);
    setSources(sourcesResult);
  }, [reviews, postSites]);

  const scrollNext = useCallback(() => {
    const tabBar = tabBarRef.current;
    const maxScrollLeft = tabBar.scrollWidth - tabBar.clientWidth;
    const increment = tabBar.clientWidth * 0.5;
    const nextScrollLeft = Math.min(tabBar.scrollLeft + increment, maxScrollLeft);

    tabBar.scroll({
      left: nextScrollLeft,
      behavior: 'smooth',
    });

    if (nextScrollLeft === maxScrollLeft) {
      setIsNextMax(true);
    } else {
      setIsNextMax(false);
    }
    setIsPrevMax(false);
  }, [tabBarRef, setIsNextMax, setIsPrevMax]);

  const scrollPrev = () => {
    const tabBar = tabBarRef.current;
    const increment = tabBar.clientWidth * 0.5;
    const prevScrollLeft = Math.max(tabBar.scrollLeft - increment, 0);

    tabBar.scroll({
      left: prevScrollLeft,
      behavior: 'smooth',
    });

    if (prevScrollLeft === 0) {
      setIsPrevMax(true);
    } else {
      setIsPrevMax(false);
    }

    //setIsNextMax(false);
  };

  const finalSources = Object.keys(sources).map((sourceKey, index) => {
    const source = sources[sourceKey];
    if (source.postReviewLink) {
      return (
        <div key={index} className={classes.source_link} datasource={getSource(sourceKey).title}>
          <img
            src={getSource(sourceKey).icon}
            alt="Review Source Image"
            datasource={getSource(sourceKey).title}
            className={classes.Review__ReviewSourceImage}
          />
          {getSource(sourceKey).title}
          <div className={classes.average_rating} datasource={getSource(sourceKey).title}>
            {source.average}
          </div>
        </div>
      );
    }
  });

  const overallRatingBadgeStyles = () => {
    return settings.widgetStyle === 'badge'
      ? {
          gap: '2rem',
          marginBottom: '1rem',
          alignItems: 'flex-start',
          flexDirection: 'column',
        }
      : {};
  };

  return (
    <>
      <StyledContainer
        bgColor={settings.colors.containerBgColor + settings.colors.containerBgOpacity}
        borderRadius={settings.radius + 'px'}
        gap={settings.gap + 'px'}
      >
        <div id="widget_header">
          {settings.showTabs ? (
            <>
              <div className={`${classes.tab_bar} ${classes.mask_next}`} ref={tabBarRef}>
                <div
                  className={`${classes.source_link} ${classes.selected}`}
                  datasource="All"
                  ref={allReviewsLinkRef}
                >
                  All Reviews
                  <div className={classes.average_rating} datasource="All">
                    {sources.allAverageRating === undefined ||
                    sources.allAverageRating === 'NaN' ? (
                      <Skeleton width={24} />
                    ) : (
                      sources.allAverageRating
                    )}
                  </div>
                </div>
                {finalSources}
              </div>
              {isPrevMax && (
                <div
                  className={classes.tabBar_previous}
                  ref={prevButtonRef}
                  onClick={() => {
                    scrollPrev();
                  }}
                >
                  <svg
                    viewBox="0 0 24 24"
                    stroke={textColor}
                    className={classes.scroll_arrow_previous}
                  >
                    <path
                      fill="none"
                      fillRule="evenodd"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M8 4l8 8-8 8"
                    ></path>
                  </svg>
                </div>
              )}
              {!isNextMax && (
                <div
                  className={classes.tabBar_next}
                  ref={nextButtonRef}
                  onClick={() => scrollNext()}
                >
                  <svg viewBox="0 0 24 24" stroke={textColor} className={classes.scroll_arrow}>
                    <path
                      fill="none"
                      fillRule="evenodd"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M8 4l8 8-8 8"
                    ></path>
                  </svg>
                </div>
              )}
            </>
          ) : null}
          <div className={classes.overall_rating} style={overallRatingBadgeStyles()}>
            <div className={classes.large_rating}>
              <div className={classes.source_rating}>Overall rating</div>
              <div className={classes.other_rating_stuff}>
                <div className={classes.large_average_rating}>
                  {sources && sources.allAverageRating}
                </div>
                <div
                  className={classes.main_stars}
                  dangerouslySetInnerHTML={{
                    __html: makeStars(
                      sources && sources.allAverageRating,
                      20,
                      settings,
                      textColor,
                      backgroundColor,
                    ),
                  }}
                ></div>
                <div className={classes.source_total_reviews}>{`${
                  sources && sources.allCount
                } reviews`}</div>
              </div>
            </div>
            <div>
              {settings.reviewButton.showWriteReviewButton && (
                <a className={classes.write_review_button} href="#" target="_blank">
                  Write a review
                </a>
              )}
              {/* <div className={classes.review_menu}>
                <div className={classes.menu_header}>Where do you want to leave a review?</div>
                <a
                  href="https://www.cars.com/dealers/208308/haus-auto-group/dealer_reviews/write-a-review/"
                  target="_blank"
                >
                  <div className={classes.menu_item}>
                    <img
                      src="http://192.168.182.209:3000/review_icons/cars.svg"
                      className={classes.Review__ReviewSourceImage}
                    />
                    <div className={classes.menu_content}>
                      Cars
                      <div className={classes.menu_subtitle}>
                        https://www.cars.com/dealers/208308/haus-auto-group/dealer_reviews/write-a-review/
                      </div>
                    </div>
                    <div className={classes.menu_chevron}>
                      <svg viewBox="0 0 24 24" stroke="#ffffff" className={classes.right_chevron}>
                        <path
                          fill="none"
                          fill-rule="evenodd"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8 4l8 8-8 8"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </a>
                <a
                  href="https://www.dealerrater.com/consumer/writereviews/31406/?source=drp"
                  target="_blank"
                >
                  <div className={classes.menu_item}>
                    <img
                      src="http://192.168.182.209:3000/review_icons/dealer_rater.svg"
                      className={classes.Review__ReviewSourceImage}
                    />
                    <div className={classes.menu_content}>
                      Dealer Rater
                      <div className={classes.menu_subtitle}>
                        https://www.dealerrater.com/consumer/writereviews/31406/?source=drp
                      </div>
                    </div>
                    <div className={classes.menu_chevron}>
                      <svg viewBox="0 0 24 24" stroke="#ffffff" className={classes.right_chevron}>
                        <path
                          fill="none"
                          fill-rule="evenodd"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8 4l8 8-8 8"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </a>
                <a
                  href="https://search.google.com/local/writereview?placeid=ChIJjT1wGJ-JUocR3lPU70MhSBg"
                  target="_blank"
                >
                  <div className={classes.menu_item}>
                    <img
                      src="http://192.168.182.209:3000/review_icons/google.svg"
                      className={classes.Review__ReviewSourceImage}
                    />
                    <div className={classes.menu_content}>
                      Google
                      <div className={classes.menu_subtitle}>
                        https://search.google.com/local/writereview?placeid=ChIJjT1wGJ-JUocR3lPU70MhSBg
                      </div>
                    </div>
                    <div className={classes.menu_chevron}>
                      <svg viewBox="0 0 24 24" stroke="#ffffff" className={classes.right_chevron}>
                        <path
                          fill="none"
                          fill-rule="evenodd"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8 4l8 8-8 8"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </a>
                <a
                  href="https://www.apartmentratings.com/writereview/?complexId=9199332346275193729"
                  target="_blank"
                >
                  <div className={classes.menu_item}>
                    <img
                      src="http://192.168.182.209:3000/review_icons/apartment_ratings.png"
                      className={classes.Review__ReviewSourceImage}
                    />
                    <div className={classes.menu_content}>
                      Apartment Ratings
                      <div className={classes.menu_subtitle}>
                        https://www.apartmentratings.com/writereview/?complexId=9199332346275193729
                      </div>
                    </div>
                    <div className={classes.menu_chevron}>
                      <svg viewBox="0 0 24 24" stroke="#ffffff" className={classes.right_chevron}>
                        <path
                          fill="none"
                          fill-rule="evenodd"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8 4l8 8-8 8"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </a>
                <a
                  href="https://www.apartmentguide.com/apartments/Maryland/Bethesda/The-Glens-at-Battery-Lane/81004//Reviews/new/"
                  target="_blank"
                >
                  <div className={classes.menu_item}>
                    <img
                      src="http://192.168.182.209:3000/review_icons/apartment_guide.png"
                      className={classes.Review__ReviewSourceImage}
                    />
                    <div className={classes.menu_content}>
                      Apartmentguide
                      <div className={classes.menu_subtitle}>
                        https://www.apartmentguide.com/apartments/Maryland/Bethesda/The-Glens-at-Battery-Lane/81004//Reviews/new/
                      </div>
                    </div>
                    <div className={classes.menu_chevron}>
                      <svg viewBox="0 0 24 24" stroke="#ffffff" className={classes.right_chevron}>
                        <path
                          fill="none"
                          fill-rule="evenodd"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8 4l8 8-8 8"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </a>
                <a
                  href="https://www.apartments.com/concord-at-geneva-vineyard-ut/bq3ttmc/#reviewsSection"
                  target="_blank"
                >
                  <div className={classes.menu_item}>
                    <img
                      src="http://192.168.182.209:3000/review_icons/apartments.jpg"
                      className={classes.Review__ReviewSourceImage}
                    />
                    <div className={classes.menu_content}>
                      Apartments
                      <div className={classes.menu_subtitle}>
                        https://www.apartments.com/concord-at-geneva-vineyard-ut/bq3ttmc/#reviewsSection
                      </div>
                    </div>
                    <div className={classes.menu_chevron}>
                      <svg viewBox="0 0 24 24" stroke="#ffffff" className={classes.right_chevron}>
                        <path
                          fill="none"
                          fill-rule="evenodd"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8 4l8 8-8 8"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </StyledContainer>
    </>
  );
};

export default WidgetHeader;
