import { createTheme } from '@material-ui/core/styles';
import { useContext, useMemo, useState } from 'react';
import { GlobalContext } from '../Stores/GlobalStateStore';

const useTableTheme = () => {
  const { state } = useContext(GlobalContext);
  const { themeContainer } = state;
  const [theme, setTheme] = useState({});
  useMemo(() => {
    setTheme(
      createTheme({
        overrides: {
          MUIDataTableToolbarSelect: {
            root: {
              display: 'none !important',
            },
          },
          MUIDataTableSelectCell: {
            root: {
              background: 'transparent !important',
              borderBottom: 'none !important',
              color: `${themeContainer.tableCheckBoxColor} !important`,
              fontFamily: 'Montserrat !important',
            },

            checked: { color: `${themeContainer.tableCheckBoxColor} !important` },
          },
          MUIDataTableToolbar: {
            root: {
              width: '100%',
              paddingLeft: 0,
            },
            left: {
              MuiIconButton: {
                root: {
                  display: 'none',
                },
              },
              MUIDataTableSearch: {
                display: 'none',
                main: {
                  display: 'none',
                  backgroundColor: 'red',
                  MuiButtonBase: {
                    root: {
                      display: 'none',
                    },
                  },
                },
              },
            },
            actions: {
              display: 'none',
            },
          },
          MUIDataTable: {
            root: {
              backgroundColor: `${themeContainer.tableBackgroundColor}`,
            },
            paper: {
              boxShadow: 'none',
              backgroundColor: `${themeContainer.tableBackgroundColor}`,
            },
          },

          MUIDataTableHeadCell: {
            root: {
              backgroundColor: `${themeContainer.tableBackgroundColor} !important`,
              borderBottom: 'none ',
              fontSize: '14px',
              // width: 'auto',
              color: `${themeContainer.tableHeaderColor} !important`,
              padding: '10px 0',

              whiteSpace: 'nowrap !important',
              '& div': {
                padding: '0 10px !important',
                alignItems: 'center',
              },
              '& div > div': {
                borderRight: 'none !important',
                padding: '0 9px !important',
                fontFamily: 'Montserrat !important',
                fontWeight: '500',
                textTransform: 'uppercase !important',
              },
              '& span > div > div > span': {
                border: 'none !important',
              },
            },
          },
          MUIDataTableBodyRow: {
            root: {
              '&:hover': {
                backgroundColor: `${themeContainer.tableRowOnHoverColor} !important`,
              },
              '&:nth-of-type(even)': {
                backgroundColor: themeContainer.tableEvenRowColor,
                '& td': {
                  borderBottom: 'none !important',
                  // padding: 0,
                },
              },
              '&:nth-of-type(odd)': {
                backgroundColor: themeContainer.tableEvenRowColor,
                '& td': {
                  borderBottom: 'none !important',
                  // padding: 0,
                },
              },
              '& td': {
                // width: '-webkit-fill-available',
                padding: '0 9px !important ',
              },
            },
          },
          MuiTableCell: {
            root: {
              borderBottom: 'none !important',
              fontFamily: 'Montserrat !important',
              fontWeight: '400',
              '& > div > div': {
                color: '#1f1f1f',
              },
              '& > div ': {
                color: '#1f1f1f',
              },
            },
          },
          MuiTablePagination: {
            root: {
              borderBottom: 'none !important',
              color: themeContainer.tablePaginationColor,
              fontFamily: 'Montserrat !important',
            },
            actions: {
              '& .MuiIconButton-root.Mui-disabled': {
                color: themeContainer.tablePaginationDisabledBtnColor,
              },
              selectIcon: {
                color: themeContainer.tablePaginationColor,
              },
            },
          },
        },
      }),
    );
  }, [themeContainer]);
  return {
    tableTheme: theme,
  };
};
export default useTableTheme;
