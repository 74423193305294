import { createTheme } from '@material-ui/core/styles';
import { useContext, useMemo, useState } from 'react';
import { GlobalContext } from '../Stores/GlobalStateStore';

const useCheckBoxTheme = () => {
  const { state } = useContext(GlobalContext);
  const { themeContainer } = state;
  const [theme, setTheme] = useState({});
  useMemo(() => {
    setTheme(
      createTheme({
        checkboxColor: themeContainer.checkboxColor,
      }),
    );
  }, [themeContainer]);
  return {
    checkBoxTheme: theme,
  };
};
export default useCheckBoxTheme;
