import React from 'react';
import Proptypes from 'prop-types';
import {
  Select,
  Input,
  MenuItem,
  ListItemText,
  ThemeProvider as MUIThemeProvider,
  Box,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';
import styled from 'styled-components';
import { withStyles } from '@material-ui/styles';
import { ConvertIntoMultiSelectFormat } from '../../utils/helper/helper';
import StyledCheckbox from '../StyledCheckbox/StyledCheckbox';
import useDropDownTheme from '../../hooks/useDropDownTheme';
import useCheckBoxTheme from '../../hooks/useCheckBoxTheme';

const MuiStyledSelect = withStyles((theme) => ({
  root: {
    color: theme.inputTextColor,
    fontFamily: 'Montserrat !important',
    fontWeight: '500 !important',
  },
  icon: {
    color: theme.inputTextColor,
  },
}))(Select);

const StyledInputLabel = styled(InputLabel)`
  font-family: 'Montserrat' !important;
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: '24px' !important;

  &.MuiFormLabel.Mui-focused.Mui-error label {
    color: '#f44336';
  }

  &.Mui-focused {
    color: #0000008a !important;
  }
`;

const StyledDropDown = styled(MuiStyledSelect)`
  max-width: ${(props) => (props.theme.maxWidth ? props.theme.maxWidth : '140px')};
  min-width: ${(props) => (props.theme.minWidth ? props.theme.minWidth : '140px')};
  width: 100%;
  font-size: 14px !important;
  margin: ${(props) => props.theme.margin};
  //border-radius: 2px !important;
  border: ${(props) => props.theme.border} !important;
  background: ${(props) => props.theme.background} !important;
  color: ${(props) => props.theme.color} !important;
  padding: 0 !important;

  font-family: 'Montserrat' !important;
  &:before {
    border-bottom: ${(props) => props.theme.borderBottom} !important;
  }

  /* & div {
    padding: 6px 0 7px;
    margin-top: 6px;
  } */

  & > svg {
    color: ${(props) => props.theme.color} !important;
  }

  // @media screen and (min-width: 1920px) {
  //   & {
  //     font-size: ${(props) => props.theme.fontSizeLgScreen} !important;
  //   }
  // }
`;

const StyledMenuItem = styled(MenuItem)`
  font-family: 'Montserrat' !important;
  font-weight: 500 !important;
  font-size: 14px !important;
`;

const StyledFormHelperText = styled(FormHelperText)`
  font-family: 'Montserrat' !important;
  font-weight: 500 !important;
  color: ${(props) => (!!props.error === true ? '#0f0' : props.theme.color)} !important;
`;

const StyledListItemText = styled.span`
  font-family: 'Montserrat' !important;
  color: ${(props) => props.theme.color} !important;
  font-family: 'Montserrat' !important;
`;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function StyledMultiSelect(props) {
  const { options, label, name, disabled, value, helpertext, error } = props;
  const { dropDownTheme } = useDropDownTheme();
  const { checkBoxTheme } = useCheckBoxTheme();
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        backgroundColor: dropDownTheme.dropDownBackGroundColor,
        color: dropDownTheme.dropdownColor,
      },
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  return (
    <Box display="flex" marginTop="auto" flexDirection="column">
      <StyledInputLabel shrink>{label}</StyledInputLabel>
      <StyledDropDown
        name={name}
        disabled={disabled}
        displayEmpty
        multiple
        error={error}
        // helperText={helperText}
        input={<Input />}
        renderValue={(selected) => ConvertIntoMultiSelectFormat(selected, options, label, value)}
        MenuProps={MenuProps}
        {...props}
      >
        <StyledMenuItem disabled value="">
          <em>{label}</em>
        </StyledMenuItem>
        {options.map((options) => (
          <StyledMenuItem
            key={options.value}
            value={options.value}
            disabled={options.disabledMenuItem}
          >
            <MUIThemeProvider theme={checkBoxTheme}>
              <StyledCheckbox checked={value.indexOf(options.value) > -1} />
            </MUIThemeProvider>

            <StyledListItemText>{options.label}</StyledListItemText>
          </StyledMenuItem>
        ))}
      </StyledDropDown>
      {error && helpertext && <StyledFormHelperText>{helpertext}</StyledFormHelperText>}
    </Box>
  );
}

StyledMultiSelect.propTypes = {
  options: Proptypes.arrayOf(Proptypes.object),
  label: Proptypes.string,
  name: Proptypes.string.isRequired,
  disabled: Proptypes.bool,
  value: Proptypes.arrayOf(Proptypes.number),
};

StyledMultiSelect.defaultProps = {
  label: 'Select values',
  disabled: false,
};

export default StyledMultiSelect;
