import React from 'react';
import Proptypes from 'prop-types';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/core';
import { ThemeProvider } from 'styled-components';
import { Controller } from 'react-hook-form';
import Dropdown from '../../components/Dropdown/Dropdown';
import defaultDropDownTheme from '../../themes/Dropdown/DefaultDropdownTheme';
import useInputFieldTheme from '../../hooks/useInputFieldTheme';
import StyledFormControl from '../../components/StyledFormControl';

function FirstDropDown(props) {
  const { control, name, list, disabled } = props;
  const { inputTheme } = useInputFieldTheme();
  return (
    <MUIThemeProvider theme={inputTheme}>
      <StyledFormControl>
        <ThemeProvider theme={defaultDropDownTheme}>
          <Controller
            as={<Dropdown listOptions={list} label="Select options" disabled={disabled} />}
            name={name}
            control={control}
          />
        </ThemeProvider>
      </StyledFormControl>
    </MUIThemeProvider>
  );
}

FirstDropDown.propTypes = {
  control: Proptypes.node.isRequired,
  name: Proptypes.string.isRequired,
  list: Proptypes.arrayOf(Proptypes.object).isRequired,
  disabled: Proptypes.bool,
};

FirstDropDown.defaultProps = {
  disabled: false,
};

export default FirstDropDown;
