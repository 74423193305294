const { Switch } = require('@material-ui/core');
const { withStyles } = require('@material-ui/styles');

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 30,
    height: 16,
    padding: 2,
    display: 'flex',
  },
  switchBase: {
    padding: 3,
    color: theme.switchCheckColor,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.switchCheckColor,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.backgroundColor,
        borderColor: theme.backgroundColor,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid #999`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.switchTrackColor,
  },
  checked: {},
}))(Switch);

export default AntSwitch;
