import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { Card, CardContent, Typography } from '@material-ui/core';
// import CustomButton from '../CustomButton/CustomButton';
import Logo from '../../assets/images/reveo_logo@1x.png';
import UploadLayout from '../UploadLayout';

const CardStyle = styled(Card)`
  text-align: center;
  min-width: 100%;
  height: 100%;
  position: relative;
  // @media only screen and (max-width: 600px) {
  //   box-shadow: none;
  // }
`;
const CardContentStyle = styled(CardContent)`
  text-align: center;
`;
const TextCardContentStyle = styled(CardContent)`
  text-align: center;
  padding-bottom: 5px;
  padding-top: 0;
`;

const ButtonStyle = styled.div`
  border: 2px solid black;
  width: 70%;
  border-radius: 25px;
  margin: auto;
  text-align: center;

  display: flex;
  font-size: 18px;
  font-weight: bold;
`;
const YesButtonStyle = styled.div`
  width: 50%;
  border-right: 2px solid;
  padding: 10px;
  background: black;
  color: #fff;
  border-radius: 20px 0px 0px 20px !important;
  cursor: pointer;
`;
const NoButtonStyle = styled.div`
  width: 50%;
  padding: 10px;
  cursor: pointer;
`;

const NegativeContentStyle = styled(CardContent)`
  text-align: center;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`;

const BottomContent = styled(CardContent)`
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 12px 0 !important;
`;

const ReviewCommentText = styled.textarea`
  width: 85%;
  height: 90px;
  outline: none;
  border: 2px solid #a7a3a3 !important;
  border-radius: 6px;
  padding: 10px;
  font-size: 14px;
  font-family: Montserrat, regular;
`;

const MessageText = styled(Typography)`
  color: #9e9a9a !important;
  font-family: Montserrat, bold !important;
  font-size: 14px !important;
  // @media only screen and (min-device-width: 481px) and (max-device-width: 1000px) {
  //   font-size: 1rem !important;
  // }
`;
const QuestionTextStyle = styled(Typography)`
  color: #9e9a9a !important;
  font-family: Montserrat, bold !important;
  font-size: 1.2rem !important;
  // @media only screen and (min-device-width: 481px) and (max-device-width: 1000px) {
  //   font-size: 1rem !important;
  // }
`;

const SubTextStyle = styled(Typography)`
  color: #9e9a9a !important;
  font-family: Montserrat, bold !important;
  font-size: 1rem !important;
  padding-top: 12px;
`;

function WelcomeCard(props) {
  const { textSurvey, onYesNoClick, notificationType, allowTextFeedback, url } = props;
  const { title, textFeedbackTitle, positiveButtonText, negativeButtonText } = textSurvey;
  const showContent = notificationType === 'confirmation';

  return (
    <CardStyle>
      <CardContentStyle>
        {url ? (
          <ThemeProvider theme={{ width: '85%', margin: '0 auto', border: 'none' }}>
            <UploadLayout
              handleClick={() => {}}
              url={`${process.env.REACT_APP_IMAGE_ENDPOINT}${url}`}
              imgHeight="350px"
              disabled
            />
          </ThemeProvider>
        ) : (
          <p />
        )}
      </CardContentStyle>
      <TextCardContentStyle>
        <QuestionTextStyle>{title}</QuestionTextStyle>
        {showContent ? <SubTextStyle>{textFeedbackTitle}</SubTextStyle> : ''}
      </TextCardContentStyle>

      {showContent ? (
        <NegativeContentStyle>
          <MessageText />
          {allowTextFeedback ? <ReviewCommentText /> : ''}
        </NegativeContentStyle>
      ) : (
        ''
      )}

      <CardContentStyle>
        <ButtonStyle>
          <YesButtonStyle
            onClick={() => {
              onYesNoClick('yes');
            }}
          >
            {positiveButtonText || 'Yes'}
          </YesButtonStyle>
          <NoButtonStyle
            onClick={() => {
              onYesNoClick('no');
            }}
          >
            {negativeButtonText || 'No'}
          </NoButtonStyle>
        </ButtonStyle>
      </CardContentStyle>
      <BottomContent>
        <div style={{ display: 'inline-grid' }}>
          <span style={{ fontSize: '6px', marginLeft: '14px' }}>Powered by</span>
          <img
            src={Logo}
            // style={{ width: powerImageWidth ? `${powerImageWidth}em` : '2.3em' }}
            style={{ width: '2.3em' }}
            alt="logo"
          />
        </div>
      </BottomContent>
    </CardStyle>
  );
}

WelcomeCard.propTypes = {
  textSurvey: PropTypes.objectOf(PropTypes.string).isRequired,
  onYesNoClick: PropTypes.func.isRequired,
  notificationType: PropTypes.string.isRequired,
  allowTextFeedback: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
};

WelcomeCard.defaultProps = {};

export default WelcomeCard;
