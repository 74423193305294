import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Checkbox, makeStyles } from '@material-ui/core';
import RadioButtonUncheckedIcon from 'mdi-react/RadioButtonUncheckedIcon';
import RadioButtonCheckedIcon from 'mdi-react/RadioButtonCheckedIcon';
import Dropdown from '../../../../Dropdown/Dropdown';

const ContainerSeletector = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 55px;
  background-color: #fff;
`;

const StyleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: max-content;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  overflow: hidden;
  margin-bottom:10px;
`;

const ContainerIcons = styled.div`
  cursor: pointer;
`;

const StyleTitle = styled.span`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
`;
const StyleSubTitle = styled.span`
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: #b3b3b3;
`;

const StyledTextQuestion = styled.span`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#208c91',
    '&$checked': {
      color: '#208c91',
    },
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
  },
  checked: {},
}));

const QuestionCategory = ({
  questionAddType,
  surveyBody,
  setQuestionCategory,
  updateQuestionIndex,
}) => {
  const [show, setShow] = useState(false);
  const [categorySelected, setCategorySelected] = useState(null);
  const [isRequired, setIsRequired] = useState(false);
  const classes = useStyles();

  const [categories, setcategories] = useState([
    { value: 'none', label: 'None' },
    { value: 'Overall Satisfaction', label: 'Overall Satisfaction' },
    { value: 'Overall Engagement', label: 'Overall Engagement' },
    { value: 'preparation', label: 'preparation' },
    { value: 'recovery', label: 'recovery' },
    { value: 'Pride in Community', label: 'Pride in Community' },
    { value: 'Management Performance', label: 'Management Performance' },
    { value: 'Perception of Value', label: 'Perception of Value' },
    { value: 'Renewal Likelihood', label: 'Renewal Likelihood' },
    { value: 'Front Office', label: 'Front Office' },
    { value: 'Overall Office Performance', label: 'Overall Office Performance' },
    { value: 'Overall Maintenance Performance', label: 'Overall Office Performance' },
  ]);

  const renderTypeTitle = (type) => {
    if (type === 'singleChoice') {
      return 'Single Choice';
    }

    return '';
  };

  const handleSelectCategory = (e) => {
    setCategorySelected(e.target.value);
    setQuestionCategory(e.target.value);
  };

  const CheckQuestion = ({ text, checked }) => {
    return (
      <ContainerIcons>
        <Checkbox
          color="default"
          onChange={() => !checked && setIsRequired(!isRequired)}
          className={classes.root}
          value=""
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<RadioButtonCheckedIcon />}
          checked={checked}
        />
        <StyledTextQuestion>{text}</StyledTextQuestion>
      </ContainerIcons>
    );
  };

  useEffect(() => {
    if (surveyBody?.questions[updateQuestionIndex]) {
      setCategorySelected(surveyBody.questions[updateQuestionIndex].category);
    }
  }, [updateQuestionIndex]);

  useEffect(() => {
    if (questionAddType.length === 0) {
      setCategorySelected(null);
    }
  }, [questionAddType]);

  return (
    <div>
      {questionAddType !== '' && (
        <StyleContainer>
          <ContainerSeletector>
            <StyleTitle>{renderTypeTitle(questionAddType)}</StyleTitle>
            {show ? (
              <ContainerIcons>
                <KeyboardArrowUpIcon onClick={() => setShow(false)} />
              </ContainerIcons>
            ) : (
              <ContainerIcons>
                <KeyboardArrowDownIcon onClick={() => setShow(true)} />
              </ContainerIcons>
            )}
          </ContainerSeletector>
          {show && (
            <div style={{ display: 'flex' }}>
              <div style={{ width: '50%' }}>
                <StyleSubTitle>Question Category</StyleSubTitle>
                <ThemeProvider
                  theme={{
                    marginTop: '0px',
                    minWidth: '100%',
                  }}
                >
                  <Dropdown
                    value={categorySelected}
                    listOptions={categories}
                    label="Question Category"
                    title={false}
                    handleChange={handleSelectCategory}
                    disableUnderline
                  />
                </ThemeProvider>
              </div>

              <div style={{ width: '50%' }}>
                <StyleSubTitle>Required</StyleSubTitle>

                <div style={{ display: 'flex' }}>
                  <CheckQuestion text="Yes" checked={isRequired} />
                  <CheckQuestion text="No" checked={!isRequired} />
                </div>
              </div>
            </div>
          )}
        </StyleContainer>
      )}
    </div>
  );
};

export default QuestionCategory;
