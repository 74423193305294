import TuneIcon from '@material-ui/icons/Tune';
import styled from 'styled-components';

const StyledTuneIcon = styled(TuneIcon)`
  color: ${(props) => props.theme.color} !important;
  font-size: ${(props) => props.theme.fontSize} !important;
  opacity: ${(props) => props.theme.opacity} !important;
`;

export default StyledTuneIcon;
