import { useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { EScrollDirection } from '../models/enums/infiniteScroll';

const useInfiniteScroll = (
  onScrollEnd,
  scrollDirection = EScrollDirection.Down,
  distanceFromEnd = 100,
) => {
  // Hooks
  const scrollRef = useRef(null);

  // Effects
  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (!scrollElement) {
      return;
    }

    // eslint-disable-next-line no-use-before-define
    const handleScrollDebounced = debounce(handleScrollDown, 100);

    scrollElement.addEventListener('scroll', handleScrollDebounced);

    // eslint-disable-next-line consistent-return
    return () => {
      scrollElement.removeEventListener('scroll', handleScrollDebounced);
    };
  }, [scrollRef.current, scrollDirection]);

  // Handlers
  const handleScrollDown = async () => {
    const scrollElement = scrollRef.current;

    if (scrollElement) {
      const { scrollTop, scrollHeight, clientHeight } = scrollElement;

      if (
        scrollTop + clientHeight >= scrollHeight - clientHeight - distanceFromEnd &&
        scrollDirection === EScrollDirection.Down
      ) {
        onScrollEnd();
      }
    }
  };

  return { scrollRef };
};

export default useInfiniteScroll;
