import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const StyledTextArea = styled.textarea`
  border-color: #e2e2e2;
  font-family: inherit;
  width: 100%;
  font-size: 1rem;
  line-height: 1.4;
`;

export const LongAnswer = ({ code, currentAnswer = '', setCurrentAnswer }) => {
  const [value, setValue] = useState('');
  const textAreaRef = useRef();

  useEffect(() => {
    textAreaRef.current.select();
  }, []);
  useEffect(() => {
    if (currentAnswer?.length > 0) {
      setValue(currentAnswer);
    }
  }, [currentAnswer]);

  return (
    <div>
      <StyledTextArea
        ref={textAreaRef}
        placeholder="Type your answer here"
        value={value}
        spellCheck
        rows={8}
        onChange={(e) => {
          setValue(e.target.value);
          setCurrentAnswer && setCurrentAnswer(e.target.value);
        }}
        maxLength={200}
      />
      <p>Characters left: {value.length} / 200 </p>
    </div>
  );
};
