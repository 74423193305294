import React from 'react';
import GenericPopup from '../../Popup/GenricPopup/index';
import Locations from '../../../pages/Locations/Locations';
import { Box } from '@material-ui/core';

function EditLocationsPopover(props) {
  const { open, handleClose, locationId } = props;

  return (
    <GenericPopup open={open} fullWidth maxWidth="lg" handleClose={handleClose}>
      <Locations handleClose={handleClose} locationId={locationId} />
    </GenericPopup>
  );
}

export default React.memo(EditLocationsPopover);
