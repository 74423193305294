import React, { useState, useEffect, useContext, useCallback } from 'react';
import Proptypes from 'prop-types';
import { useHistory } from 'react-router';
import styled, { ThemeProvider } from 'styled-components';
import {
  FormHelperText,
  Grid,
  InputAdornment,
  ThemeProvider as MUIThemeProvider,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import HelpIcon from '@material-ui/icons/Help';
import { Controller, useForm } from 'react-hook-form';
import CompanyLogo from '../../../components/CompanyLogo/CompanyLogo';
import CustomButton from '../../../components/CustomButton/CustomButton';
import Logo from '../../../assets/images/reveo_logo@2x.png';
import OnBoardingButtonTheme from '../../../themes/Button/OnBoardingButtonTheme';
import OnBoardingHeaderLogoTheme from '../../../themes/Logo/OnBoardingHeaderLogoTheme';
import { GlobalContext } from '../../../Stores/GlobalStateStore';
import baseUrl from '../../../utils/services/config';
import {
  errorChecker,
  getURLWithoutPrefix,
  handleEmailError,
  handleLocationViewErrors,
  handlePhoneError,
  validateURL,
} from '../../../utils/helper/helper';
import Loader from '../../../components/Loader/Loader';
import defaultDropDownTheme from '../../../themes/Dropdown/DefaultDropdownTheme';
import Dropdown from '../../../components/Dropdown/Dropdown';
import Timezone from '../../../utils/api/Timezone';
import NumberField from '../../../components/NumberField/NumberField';
import StyledTextField from '../../../components/GeneralInputField/GeneralInputField';
import { emailRegEx } from '../../../utils/helper/regEx';
import StyledFormControl from '../../../components/StyledFormControl';
import StyledDescriptionText from '../../../components/DescriptionText';
import useInputFieldTheme from '../../../hooks/useInputFieldTheme';
import useTextColors from '../../../hooks/useTextColors';
import StyledBeginText from '../../../components/BeginText/BeginText';
import StyledImpText from '../../../components/ImpText/ImpText';
import StyledIosSwitch from '../../../components/StyledIosSwitch';
import useAntSwitchTheme from '../../../hooks/useAntSwitchTheme';
import { OnBoardingContext } from '../../../Stores/OnBoarding';

const StyledGridContaienr = styled(Grid)`
  width: 100% !important;
  min-height: 480px !important;
  height: 100% !important;
`;

const StyledContentContainer = styled(Grid)`
  height: calc(100% - 45px) !important;
`;

const StyledHeadingContainer = styled(Grid)`
  margin: 1.5em 0 !important;
`;

const StyledImpTextContainer = styled(Grid)`
  margin: 1em 0 0 0 !important;
`;

const StyledFooter = styled(Grid)`
  align-self: flex-end;
`;

const StyledFowArrow = styled(ArrowForwardIcon)`
  color: #fff !important;
  font-size: 3em !important;
`;

const StyledHelpIcon = styled(HelpIcon)`
  color: #c1c1c1 !important;
  font-size: 2em !important;
`;

function Step5(props) {
  const { handleNext } = props;
  const history = useHistory();
  const { onBoardingDispatch } = useContext(OnBoardingContext);
  const [renderView, setRenderView] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [acceptReviews, setAcceptReviews] = useState(false);
  const { register, control, errors, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      name: '',
      nickName: '',
      contact: {
        businessContact: '',
        phoneNo: '',
        addressLine1: '',
        emailAddress: '',
        addressLine2: '',
        webSite: '',
        city: '',
        state: '',
        zip: '',
        timeZone: '',
        acceptReviews: false,
      },
    },
  });
  const { state, dispatch } = useContext(GlobalContext);
  const { businessInfo, selectedBusiness } = state;
  const { inputTheme } = useInputFieldTheme();
  const { textColors } = useTextColors();
  const { antSwitchTheme } = useAntSwitchTheme();

  const fetchLocations = useCallback(async () => {
    if (selectedBusiness) {
      try {
        const result = await baseUrl.get(`/business/${selectedBusiness}/location?isDeleted=false`);
        if (result.status === 200) {
          dispatch({
            type: 'SET_LOCATIONS_CONTAINER',
            payload: Array.from(result.data),
          });
          handleNext();
          setDisableBtn(false);
          if (result.data.length === 1) {
            onBoardingDispatch({ type: 'START_ONBOARD_WIZARD' });
            history.push('/app/setup/connections');
          }
        }
      } catch (err) {
        setDisableBtn(false);
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      }
    }
  }, [selectedBusiness, dispatch, handleNext]);

  useEffect(() => {
    register({ name: 'contact.phoneNo' }, { required: true, minLength: 10 });
    register({ name: 'contact.zip' }, { required: true, minLength: 5 });
    register({ name: 'contact.businessContact' }, { minLength: 10 });
  }, [register]);

  useEffect(() => {
    if (businessInfo && Object.keys(businessInfo).length) {
      setRenderView(true);
      // Object.keys(portalJson).forEach(async (el) => {
      //   await baseUrl.post(`/reviewportal`, {
      //     name: getPortlaName(el),
      //     meta: { [el]: portalJson[el] },
      //     business: businessInfo.id,
      //     createdBy: parseInt(localStorage.getItem('USER_ID'), 10),
      //   });
      // });
    }
  }, [businessInfo]);

  useEffect(() => {
    if (renderView) {
      let contactDetails = { ...businessInfo.contact };
      contactDetails = {
        ...contactDetails,
        phoneNo: contactDetails.phoneNumber ? contactDetails.phoneNumber.substring(2) : '',
        businessContact:
          contactDetails.businessContact && contactDetails.businessContact !== undefined
            ? contactDetails.businessContact.substring(2)
            : '',
        emailAddress: businessInfo.email,
        webSite: getURLWithoutPrefix(contactDetails.webSite),
      };
      delete contactDetails.phoneNumber;
      setValue('contact', contactDetails);
      setValue('name', businessInfo.name);
      setValue('nickName', businessInfo.nickName);
    }
  }, [
    renderView,
    businessInfo.contact,
    businessInfo.email,
    businessInfo.name,
    businessInfo.nickName,
    setValue,
  ]);
  const onSubmit = async (data) => {
    setDisableBtn(true);
    try {
      let contactDetails = { ...data.contact };
      contactDetails = {
        ...contactDetails,
        phoneNo: `+1${contactDetails.phoneNo}`,
        businessContact: contactDetails.businessContact
          ? `+1${contactDetails.businessContact}`
          : '',
        webSite: validateURL(contactDetails.webSite),
      };
      const postBody = {
        contact: { ...contactDetails },
        name: data.name,
        createdBy: parseInt(localStorage.getItem('USER_ID'), 10),
        seoCategory: [businessInfo.seoCategory[0].id],
        industry: [businessInfo.industry[0].id],
        nickName: data.nickName,
        noEmployees: businessInfo.noEmployees ? parseInt(businessInfo.noEmployees, 10) : 0,
        uploadedFile: [],
        description: '',
        keywords: '',
        services: '',
        business: [state.selectedBusiness],
        acceptReviews,
        aboutUs: '',
        googleLocation: data.googleLocation ? data.googleLocation : [],
        user: data.user
          ? [...new Set([parseInt(localStorage.getItem('USER_ID'), 10), 1].concat(data.users))]
          : [parseInt(localStorage.getItem('USER_ID'), 10), 1],
      };

      const result = await baseUrl.post(`location`, { ...postBody });
      if (result.status === 200) {
        fetchLocations();
      }
    } catch (err) {
      setDisableBtn(false);
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const handleTrimAndSetValue = (name, e) => {
    setValue(name, e.target.value.trim());
  };

  const handlePhoneNumber = (key, values) => {
    setValue(key, values.value);
  };

  const watchAllFields = watch({ nest: true });

  return renderView ? (
    <StyledGridContaienr>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        sm={12}
        style={{ flex: '1 0 auto', maxHeight: '40px', minHeight: '40px', padding: '.5em 1em' }}
      >
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <StyledHelpIcon />
          </Grid>
          <Grid item>
            <Grid container justify="flex-end">
              <ThemeProvider theme={OnBoardingHeaderLogoTheme}>
                <CompanyLogo alt="Reveo" logo={Logo} />
              </ThemeProvider>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={12} sm={12} style={{ height: 'calc(100% - 40px)' }}>
        <MUIThemeProvider theme={inputTheme}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sm={12}
              style={{
                margin: '0 0 0 1em',
                flex: '1 1 auto',
                overflow: 'auto',
                overflowX: 'hidden',
                height: 'calc(100% - 65px)',
              }}
            >
              <StyledContentContainer container justify="center">
                <Grid item xs={10} sm={10} lg={10} md={10}>
                  <Grid item xs={12} sm={12} lg={12} md={12}>
                    <Grid container justify="center">
                      <Grid item xs={12} md={12} lg={12} sm={12}>
                        <Grid container justify="center">
                          <StyledHeadingContainer item xs={12} md={12} lg={12} sm={12}>
                            <ThemeProvider theme={{ color: textColors.headingTextColor }}>
                              <StyledBeginText item xs={12} md={12} lg={12} sm={12}>
                                Let’s set up your first location
                              </StyledBeginText>
                            </ThemeProvider>
                            <ThemeProvider
                              theme={{ color: textColors.descriptionTextColor, padding: '5px 0' }}
                            >
                              <StyledDescriptionText item xs={12} md={12} lg={12} sm={12}>
                                Don’t worry, you can add more later
                              </StyledDescriptionText>
                            </ThemeProvider>
                          </StyledHeadingContainer>
                          <Grid item xs={12} md={12} lg={12} sm={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6} lg={6} sm={12}>
                                <StyledTextField
                                  name="name"
                                  onBlur={(e) => setValue('name', e.target.value.trim())}
                                  label="Business Name *"
                                  inputRef={register({
                                    required: true,
                                    validate: (value) => value.trim() !== '',
                                  })}
                                  error={!!errors.name}
                                  helperText={
                                    errors.name ? 'Business name is a required field' : ''
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} md={5} lg={5} sm={12}>
                                <StyledTextField
                                  name="nickName"
                                  onBlur={(e) => setValue('nickName', e.target.value.trim())}
                                  label="Nickname *"
                                  inputRef={register({
                                    required: true,
                                    validate: (value) => value.trim() !== '',
                                  })}
                                  error={!!errors.nickName}
                                  helperText={errors.nickName ? 'Nickname is a required field' : ''}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6} lg={6} sm={12}>
                                <NumberField
                                  name="contact.businessContact"
                                  format="+1 ### ### ####"
                                  label="Business Contact Number"
                                  onChange={(values) =>
                                    handlePhoneNumber('contact.businessContact', values)
                                  }
                                  // error={
                                  //   handleLocationViewErrors(errors, 'contact')
                                  //     ? errors.contact.businessContact
                                  //     : false
                                  // }
                                  // helperText={
                                  //   handleLocationViewErrors(errors, 'contact') &&
                                  //   errors.contact.businessContact
                                  //     ? handlePhoneError(
                                  //         errors.contact.businessContact,
                                  //         'Business Contact',
                                  //       )
                                  //     : ''
                                  // }
                                  defaultValue={
                                    watchAllFields.contact && watchAllFields.contact.businessContact
                                      ? watchAllFields.contact.businessContact
                                      : ''
                                  }
                                  value={
                                    watchAllFields.contact && watchAllFields.contact.businessContact
                                      ? watchAllFields.contact.businessContact
                                      : ''
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} md={5} lg={5} sm={12}>
                                <NumberField
                                  name="contact.phoneNo"
                                  format="+1 ### ### ####"
                                  label="Contact Mobile *"
                                  onChange={(values) => setValue('contact.phoneNo', values.value)}
                                  error={
                                    handleLocationViewErrors(errors, 'contact')
                                      ? errors.contact.phoneNo
                                      : false
                                  }
                                  helperText={
                                    handleLocationViewErrors(errors, 'contact') &&
                                    errors.contact.phoneNo
                                      ? handlePhoneError(errors.contact.phoneNo, 'Contact Mobile')
                                      : ''
                                  }
                                  defaultValue={
                                    watchAllFields.contact && watchAllFields.contact.phoneNo
                                      ? watchAllFields.contact.phoneNo
                                      : ''
                                  }
                                  value={
                                    watchAllFields.contact && watchAllFields.contact.phoneNo
                                      ? watchAllFields.contact.phoneNo
                                      : ''
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6} lg={6} sm={12}>
                                <StyledTextField
                                  name="contact.addressLine1"
                                  onBlur={(e) => handleTrimAndSetValue('contact.addressLine1', e)}
                                  label="Address 1 *"
                                  inputRef={register({
                                    required: true,
                                    validate: (value) => value.trim() !== '',
                                  })}
                                  error={
                                    handleLocationViewErrors(errors, 'contact')
                                      ? errors.contact.addressLine1
                                      : false
                                  }
                                  helperText={
                                    handleLocationViewErrors(errors, 'contact') &&
                                    errors.contact.addressLine1
                                      ? 'Address 1 is a required field'
                                      : ''
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} md={5} lg={5} sm={12}>
                                <StyledTextField
                                  name="contact.emailAddress"
                                  onBlur={(e) => handleTrimAndSetValue('contact.emailAddress', e)}
                                  label="Contact Email *"
                                  inputRef={register({ required: true, pattern: emailRegEx })}
                                  error={
                                    handleLocationViewErrors(errors, 'contact')
                                      ? errors.contact.emailAddress
                                      : false
                                  }
                                  helperText={
                                    errors.contact && errors.contact.emailAddress
                                      ? handleEmailError(errors.contact.emailAddress)
                                      : ''
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6} lg={6} sm={12}>
                                <StyledTextField
                                  name="contact.addressLine2"
                                  onBlur={(e) => handleTrimAndSetValue('contact.addressLine2', e)}
                                  label="Address 2"
                                  inputRef={register}
                                  error={
                                    handleLocationViewErrors(errors, 'contact')
                                      ? errors.contact.addressLine2
                                      : false
                                  }
                                  helperText={
                                    handleLocationViewErrors(errors, 'contact') &&
                                    errors.contact.addressLine2
                                      ? 'Address 2 is a required field'
                                      : ''
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} md={5} lg={5} sm={12}>
                                <StyledTextField
                                  name="contact.webSite"
                                  onBlur={(e) => handleTrimAndSetValue('contact.webSite', e)}
                                  label="Web Address *"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">https://</InputAdornment>
                                    ),
                                  }}
                                  inputRef={register({
                                    required: true,
                                    validate: (value) => value.trim() !== '',
                                  })}
                                  error={
                                    handleLocationViewErrors(errors, 'contact')
                                      ? errors.contact.webSite
                                      : false
                                  }
                                  helperText={
                                    handleLocationViewErrors(errors, 'contact') &&
                                    errors.contact.webSite
                                      ? 'Web Address is a required field'
                                      : ''
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6} lg={6} sm={12}>
                                <Grid container spacing={1}>
                                  <Grid item xs={12} md={4} lg={4} sm={12}>
                                    <StyledTextField
                                      name="contact.city"
                                      onBlur={(e) => handleTrimAndSetValue('contact.city', e)}
                                      label="City *"
                                      inputProps={{ 'aria-label': 'description' }}
                                      inputRef={register({
                                        required: true,
                                        validate: (value) => value.trim() !== '',
                                      })}
                                      error={
                                        handleLocationViewErrors(errors, 'contact')
                                          ? errors.contact.city
                                          : false
                                      }
                                      helperText={
                                        handleLocationViewErrors(errors, 'contact') &&
                                        errors.contact.city
                                          ? 'City is a required field'
                                          : ''
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={4} lg={4} sm={12}>
                                    <StyledTextField
                                      name="contact.state"
                                      onBlur={(e) => handleTrimAndSetValue('contact.state', e)}
                                      label="State *"
                                      inputProps={{ 'aria-label': 'description' }}
                                      inputRef={register({
                                        required: true,
                                        validate: (value) => value.trim() !== '',
                                      })}
                                      error={
                                        handleLocationViewErrors(errors, 'contact')
                                          ? errors.contact.state
                                          : false
                                      }
                                      helperText={
                                        handleLocationViewErrors(errors, 'contact') &&
                                        errors.contact.state
                                          ? 'State is a required field'
                                          : ''
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={4} lg={4} sm={12}>
                                    <NumberField
                                      name="contact.zip"
                                      format="#####"
                                      label="Zip *"
                                      onChange={(values) => setValue('contact.zip', values.value)}
                                      error={
                                        handleLocationViewErrors(errors, 'contact')
                                          ? errors.contact.zip
                                          : false
                                      }
                                      helperText={
                                        handleLocationViewErrors(errors, 'contact') &&
                                        errors.contact.zip
                                          ? handlePhoneError(errors.contact.zip, 'Zip', 5)
                                          : ''
                                      }
                                      defaultValue={
                                        watchAllFields.contact && watchAllFields.contact.zip
                                          ? watchAllFields.contact.zip
                                          : ''
                                      }
                                      value={
                                        watchAllFields.contact && watchAllFields.contact.zip
                                          ? watchAllFields.contact.zip
                                          : ''
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} md={5} lg={5} sm={12}>
                                <ThemeProvider theme={defaultDropDownTheme}>
                                  <StyledFormControl
                                    error={
                                      handleLocationViewErrors(errors, 'contact')
                                        ? errors.contact.timeZone
                                        : false
                                    }
                                  >
                                    <Controller
                                      as={
                                        <Dropdown
                                          listOptions={Array.from(Timezone)}
                                          label="Time Zone *"
                                        />
                                      }
                                      name="contact.timeZone"
                                      control={control}
                                      defaultValue=""
                                      rules={{ required: true }}
                                    />
                                    {handleLocationViewErrors(errors, 'contact') &&
                                    errors.contact.timeZone ? (
                                      <FormHelperText>Time Zone is a required field</FormHelperText>
                                    ) : null}
                                  </StyledFormControl>
                                </ThemeProvider>
                              </Grid>
                            </Grid>
                            {/* <TabPanel value={currentTab} index={0}>
                        <LocationForm
                          control={control}
                          errors={errors}
                          register={register}
                          setValue={setValue}
                          watchAllFields={watchAllFields}
                        />
                      </TabPanel> */}
                          </Grid>
                          <Grid item xs={12} md={7} lg={7} sm={12}>
                            <StyledImpTextContainer container spacing={2} alignItems="center">
                              <ThemeProvider theme={{ color: textColors.impTextColor }}>
                                <StyledImpText item xs={12} md={10} lg={10} sm={12}>
                                  This location accepts reviews
                                </StyledImpText>
                              </ThemeProvider>
                              <Grid item xs={12} md={2} lg={2} sm={12}>
                                <MUIThemeProvider theme={antSwitchTheme}>
                                  <StyledIosSwitch
                                    checked={acceptReviews}
                                    onChange={(e) => setAcceptReviews(e.target.checked)}
                                  />
                                </MUIThemeProvider>
                              </Grid>
                            </StyledImpTextContainer>
                            <Grid container spacing={2}>
                              <ThemeProvider
                                theme={{
                                  color: textColors.descriptionTextColor,
                                  padding: '5px 20px',
                                }}
                              >
                                <StyledDescriptionText item xs={12} md={11} lg={11} sm={12}>
                                  If switched off, this location will be treated as a corporate
                                  location, and will not accept reviews.
                                </StyledDescriptionText>
                              </ThemeProvider>
                              <Grid item xs={12} md={1} lg={1} sm={12} />
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={5} lg={5} sm={12} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </StyledContentContainer>
            </Grid>
            <StyledFooter
              item
              xs={12}
              sm={12}
              lg={12}
              md={12}
              style={{ flex: '1 0 auto', maxHeight: '60px', minHeight: '60px' }}
            >
              <Grid container justify="flex-end">
                <Grid item>
                  <ThemeProvider theme={OnBoardingButtonTheme}>
                    <CustomButton type="submit" disabled={disableBtn}>
                      <StyledFowArrow />
                    </CustomButton>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </StyledFooter>
          </form>
        </MUIThemeProvider>
      </Grid>
    </StyledGridContaienr>
  ) : (
    <Loader />
  );
}

Step5.propTypes = {
  handleNext: Proptypes.func.isRequired,
};

export default Step5;
