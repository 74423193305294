import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, IconButton } from '@material-ui/core';
import { Facebook, Twitter, Instagram, LinkedIn, YouTube } from '@material-ui/icons';
import SocialMediaIcons from './SocialMediaIcons';

function MicrositeFooter(props) {
  const { socialLinks } = props;
  const [footerData, setFooterData] = useState(null);

  const useStyles = makeStyles(() => ({
    footerStyle: {
      backgroundColor: 'black',
      padding: '2rem 0',

      '@media screen and (max-width:1300px)': { padding: '2rem 2rem' },
    },
    footerContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      maxWidth: '1200px',
      margin: '0 auto',
    },
    footerHeader: {
      textTransform: 'uppercase',
      color: '#30feaf',
      fontWeight: 600,
    },
    footerLinks: {
      listStyle: 'none',
      padding: 0,
      '& li': {
        marginBottom: '.5rem',
      },
      '& li a': {
        color: '#838383',
        transitionDuration: '0.3s',
        marginBottom: '1rem',
        textTransform: 'capitalize',
        fontSize: '14px',
        '&:hover': {
          color: 'white',
        },
      },
    },
    tinyFooterLink: {
      color: '#838383',
      fontSize: '13px',
      '&:hover': {
        color: '#30feaf',
      },
    },
    copyrightText: {
      color: '#838383',
      fontSize: '13px',
    },
    badgeImage: {
      width: '118px',
      marginRight: '1rem',
      opacity: '0.5',
      transitionDuration: '.2s',
      filter: 'grayscale(100%)',
      cursor: 'pointer',
      '&:hover': {
        transition: '.2s',
        filter: 'grayscale(0%)',
        opacity: 1,
      },
    },
    socialBar: {
      maxWidth: '1200px',
      display: 'flex',
      flex: 1,
      borderTop: '1px solid #2d2d2d',
      borderBottom: '1px solid #2d2d2d',
      paddingBottom: '.25rem',
      marginTop: '1rem',
      justifyContent: 'space-between',
    },
    legalBar: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '1rem',
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    // Replace 'your-json-url' with the actual URL of the JSON file
    fetch('/footer.json')
      .then((response) => response.json())
      .then((data) => setFooterData(data))
      .catch((error) => console.error('Error loading JSON:', error));
  }, []);

  return (
    <Grid container className={classes.footerStyle}>
      <Grid item xs={12} md={12} lg={12} xl={12} style={{ maxWidth: '1200px', margin: '0 auto' }}>
        <Grid container spacing={4}>
          {footerData &&
            Object.keys(footerData.footerNavigation).map((sectionKey, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <div className={classes.footerContainer}>
                  <div className={classes.footerHeader}>
                    {footerData.footerNavigation[sectionKey].title}
                  </div>
                  <ul className={classes.footerLinks}>
                    {footerData.footerNavigation[sectionKey].links.map((link, subindex) => (
                      <li key={subindex}>
                        <a href={link.link}>{link.title}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </Grid>
            ))}
        </Grid>
        <div className={classes.socialBar}>
          <div className={classes.appstores}>
            <img
              src="https://static.reveo.com/microsite/app-store-badge.png"
              className={classes.badgeImage}
              alt="App Store"
            />
            <img
              src="https://static.reveo.com/microsite/google-play-badge.png"
              className={classes.badgeImage}
              alt="Google Play"
            />
          </div>
          <div>
            <SocialMediaIcons socialLinks={socialLinks.socialLinks} />
          </div>
        </div>
        <Grid container className={classes.legalBar}>
          <div style={{ display: 'flex', columnGap: '1rem' }}>
            <a href="http://reveo.com/terms-and-conditions" className={classes.tinyFooterLink}>
              Terms & Conditions
            </a>
            <a href="http://reveo.com/privacy-policy" className={classes.tinyFooterLink}>
              Privacy Policy
            </a>
            <a href="http://reveo.com/security" className={classes.tinyFooterLink}>
              Security
            </a>
            <a href="http://reveo.com/hipaa" className={classes.tinyFooterLink}>
              HIPAA
            </a>
          </div>
          <div className={classes.copyrightText}>Copyright © 2023 Reveo. All Rights Reserved.</div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MicrositeFooter;
