import { Box } from '@material-ui/core';
import React from 'react';
import GenricPopup from '../../Popup/GenricPopup';
import SocialMediaPostCreator from './SocialMediaPostCreator/SocialMediaPostCreator';

const CreateSocialMediaPopover = ({ socialMediaCreationPopup, selectedId = null }) => {
  const handleClose = () => {
    socialMediaCreationPopup.handleHideShowModal();
  };
  return (
    <GenricPopup
      open={socialMediaCreationPopup.showModal}
      //open={true}
      handleClose={socialMediaCreationPopup.handleHideShowModal}
      maxWidth="md"
      fullWidth
    >
      <Box style={{ height: 'auto', overflow: 'hidden' }}>
        <SocialMediaPostCreator handleClose={handleClose} campaignId={selectedId} />
      </Box>
    </GenricPopup>
  );
};

export default CreateSocialMediaPopover;
