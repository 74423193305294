import React from 'react';
import PropTypes from 'prop-types';
import RoomIcon from '@material-ui/icons/Room';

const MarkerComponent = ({ text }) => (
  <div style={{ fontSize: '14px', color: '#5bae82', width: '100px' }}>
    <RoomIcon style={{ fontSize: '3em' }} />
    <div>{text}</div>
  </div>
);

MarkerComponent.propTypes = {
  text: PropTypes.string.isRequired,
};

export default MarkerComponent;
