import React from 'react';
import Proptypes from 'prop-types';
import { Card, CardContent, Box, IconButton } from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import MoreIcon from '@material-ui/icons/MoreVert';
import CustomizedSwitches from '../CustomSwitch/CustomSwitch';
import usePopover from '../../hooks/usePopover';
import CampaignPopover from '../Popover/CampaignPopover/CampaignPopover';
import { getTypeBadge, imageURLCheck } from '../../utils/helper/helper';

const StyledCard = styled(Card)`
  transition: all 0.3s ease-out;

  &:hover {
    background-color: #30feaf30;
    transition: all 0.3s ease-out;
  }
`;
const StyledCardContent = styled(CardContent)`
  padding: 0.5rem 0 0.5rem 0.7rem !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const StatusLabel = styled.div`
  color: ${(props) => props.theme.descriptionColor};
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;
`;

const StyledCardTitle = styled.div`
  color: ${(props) => props.theme.titleColor};
  font-family: Montserrat;
  font-weight: 600;
  font-size: 14px;
  padding: 0.1rem 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  max-width: 150ch;

  @media (max-width: 1500px) {
    max-width: 90ch; // Adjust for medium screens
  }
  @media (max-width: 1300px) {
    max-width: 60ch; // Adjust for medium screens
  }
  @media (max-width: 1100px) {
    max-width: 37ch; // Adjust for small screens
  }
`;

const StyledDescription = styled.div`
  color: ${(props) => props.theme.descriptionColor};
  font-family: Montserrat;
  font-weight: 400;
  font-size: 12px;
  padding: 0.2rem 0.5rem 0.1rem 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 150ch;

  @media (max-width: 1500px) {
    max-width: 90ch; // Adjust for medium screens
  }
  @media (max-width: 1300px) {
    max-width: 60ch; // Adjust for medium screens
  }
  @media (max-width: 1100px) {
    max-width: 43ch; // Adjust for small screens
  }
`;

const StyledImage = styled.img`
  height: 45px;
  width: 45px;
  border-radius: 4px;
  margin-right: 0.5rem;
  aspect-ratio: 16 / 9;
  object-fit: cover;
`;

const StyledImageMultiple = styled.div`
  height: 45px;
  width: 45px;
  border-radius: 4px;
  margin-right: 0.5rem;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  position: relative;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;

  &::after {
    content: '${(props) => props.count}';
    width: 15px;
    height: 15px;
    background-color: #30feaf;
    position: absolute;
    right: -12px;
    bottom: -5px;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: 800;
    color: #000;
    border: 3px solid white;
  }
`;

function CampaignCard(props) {
  const {
    name,
    message,
    isActive,
    id,
    handleSwitchChange,
    isDeleted,
    roles,
    optionsOnPress,
    campaignType,
    isDefault,
    uploadedFile,
    handleClick,
  } = props;

  const campaignPopover = usePopover();
  const canEdit = roles.isAdmin || roles.isBusinessAdmin || roles.isSuperAdmin;

  const getPreview = () => {
    if (!uploadedFile) return;
    if (uploadedFile.length === 1) {
      return <StyledImage src={imageURLCheck(uploadedFile[0].url)} />;
    } else if (uploadedFile.length > 1) {
      return (
        <StyledImageMultiple count={uploadedFile.length} src={imageURLCheck(uploadedFile[0].url)} />
      );
    } else {
      return null;
    }
  };

  return (
    <StyledCard variant="outlined">
      <StyledCardContent>
        <Box display="flex" alignItems="center" onClick={() => handleClick(campaignType)} flex="1">
          {getPreview()}
          <Box display="flex" flexDirection={'column'}>
            <Box display="flex" alignItems="center">
              <StyledCardTitle>{name}</StyledCardTitle>
              {getTypeBadge(campaignType)}
              {isDefault && (
                <ThemeProvider
                  theme={{
                    backgroundColor: '#e0e0e0',
                    color: '#333',
                  }}
                >
                  {getTypeBadge('default')}
                </ThemeProvider>
              )}
            </Box>
            {message ? <StyledDescription>{message}</StyledDescription> : null}
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          marginRight=".5rem"
        >
          {!isDefault && handleSwitchChange && (
            <>
              <StatusLabel>{isActive ? 'Active' : 'Inactive'}</StatusLabel>
              <CustomizedSwitches
                checked={isActive}
                name={name}
                value={id}
                onChange={handleSwitchChange}
                disabled={isDeleted || roles.isEmployee}
              />
            </>
          )}
          {canEdit ? (
            <IconButton onClick={campaignPopover.handleClick}>
              <MoreIcon />
            </IconButton>
          ) : null}
        </Box>
      </StyledCardContent>

      <CampaignPopover
        anchorEl={campaignPopover.anchorEl}
        handleClose={campaignPopover.handleClose}
        open={campaignPopover.open}
        idSelected={id}
        id={campaignPopover.id}
        optionsOnPress={optionsOnPress}
      />
    </StyledCard>
  );
}

CampaignCard.propTypes = {
  name: Proptypes.string,
  message: Proptypes.string,
  isActive: Proptypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  url: Proptypes.string,
  id: Proptypes.number,
  handleSwitchChange: Proptypes.func,
  isDeleted: Proptypes.bool,
  roles: Proptypes.object,
};

export default React.memo(CampaignCard);
