import React from 'react';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { Cancel } from '@material-ui/icons';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const StyledFailIcon = withStyles({
  root: {
    width: 24,
    height: 24,
    color: '#cc5353', // Override the color
  },
})(Cancel);

const StyledCheckIcon = withStyles({
  root: {
    width: 24,
    height: 24,
    color: '#32a753', // Override the color
  },
})(CheckCircle);

const StyledErrorText = styled.div`
  color: #cc5353;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  column-gap: 0.3rem;
  white-space: nowrap;
`;

const StyledGoodText = styled.div`
  color: #32a753;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  column-gap: 0.3rem;
  white-space: nowrap;
`;

const StyledValue = styled.span`
  font-weight: 800;
`;

const StatisticItem = (props) => {
  const { message, value, status } = props;

  const getValue = (status) => {
    if (status === 'error') {
      return (
        <>
          <StyledFailIcon />
          <StyledErrorText>
            <StyledValue>{value}</StyledValue>
            {message}
          </StyledErrorText>
        </>
      );
    } else {
      return (
        <>
          <StyledCheckIcon />
          <StyledGoodText>
            <StyledValue>{value}</StyledValue>
            {message}
          </StyledGoodText>
        </>
      );
    }
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ columnGap: '.5rem' }}
      justifyContent={'flex-start'}
      maxWidth={'30%'}
      width={'30%'}
      alignSelf={'center'}
    >
      {getValue(status)}
    </Box>
  );
};

export default StatisticItem;
