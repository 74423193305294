import React, { lazy } from 'react';
import AccessAuthorizationsContainer from '../pages/AccessAuthorizationsContainer/AccessAuthorizationsContainer';
import LocationsContainer from '../pages/Locations/LocationsContainer';
import SettingsContainer from '../pages/Settings/SettingsContainer';
import {
  businessStatusList,
  CampaignStatusList,
  employeeTypeList,
  industryStatusList,
  LocationStatusList,
  postSiteStatusList,
  ReviewSitesList,
  seoCategoryStatusList,
} from '../utils/api/filters';
import CampaignContainer from '../pages/Campaign/CampaignContainer';
import PostSitesList from '../pages/PostSites/PostSitesContainer';
import SeoTable from '../pages/SeoCategory/SeoCategoryContainer';
import IndustryTable from '../pages/Industry/IndustryContainer';
import WidgetsContainer from '../pages/WidgetsContainer/WidgetsContainer';
import SearchScrapeContainer from '../pages/SearchScrapeContainer/SearchScrapeContainer';
import WebchatContainer from '../pages/WebchatContainer/WebchatContainer';
import BusinessContainer from '../pages/Business/BusinessContainer';
import CustomerView from '../pages/Customers/CustomerView';
import ImportCustomers from '../pages/Customers/ImportCustomers';
import Goals from '../pages/Goals/Goals';
import Permissions from '../pages/Permisions/Permisions';
import Employee from '../pages/Account/Employee';
import { getYearDropDown } from '../utils/helper/helper';
import SurveyInformation from '../pages/Report/SurveyInformation';
import SurveyBuilder from '../pages/SurveyBuilder/SurveyBuilder';

const dashboard = lazy(() => import('../pages/Dashboard/Dashboard'));
const setup = lazy(() => import('../pages/Setup'));
const listing = lazy(() => import('../pages/Listing'));
const pageNotFound = lazy(() => import('../pages/PageNotFound'));
const Account = lazy(() => import('../pages/Account/Account'));
const locations = lazy(() => import('../pages/Locations/Locations'));
const ReviewsView = lazy(() => import('../pages/Reviews/ReviewsView'));
const EditUser = lazy(() => import('../pages/Account/EditUser'));
const businessForm = lazy(() => import('../pages/Business/BusinessForm'));
const AddClient = lazy(() => import('../pages/Customers/AddClient'));
const Customers = lazy(() => import('../pages/Customers'));
const Portal = lazy(() => import('../pages/Portal/ReviewPortalContainer'));
const PostSites = lazy(() => import('../pages/PostSites/PostSites'));
const conversations = lazy(() => import('../pages/Conversation/Conversation'));
const Reports = lazy(() => import('../pages/Report/Reports'));
const Survey = lazy(() => import('../pages/Survey/Survey'));
const createSurvey = lazy(() => import('../pages/Survey/createSurvey'));
const campaignType = lazy(() => import('../pages/Campaign/CampaignType'));
const templateForm = lazy(() => import('../pages/Templates/TemplateForm/index'));
const editSurvey = lazy(() => import('../pages/Survey/EditSurvey'));
const appRoutes = [
  {
    path: '/app/dashboard',
    exact: true,
    component: dashboard,
  },

  {
    path: '/app/feedback',
    exact: true,
    component: Customers,
  },
  {
    path: '/app/feedback/review-request',
    exact: true,
    component: Customers,
  },
  {
    path: '/app/feedback/survey',
    exact: true,
    component: Customers,
  },
  {
    path: '/app/feedback/notification',
    exact: true,
    component: Customers,
  },
  {
    path: '/app/feedback/message',
    exact: true,
    component: Customers,
  },
  {
    path: '/app/setup',
    exact: true,
    component: setup,
  },
  {
    path: '/app/setup/location',
    exact: true,
    component: setup,
  },
  {
    path: '/app/setup/business',
    exact: true,
    component: setup,
  },
  {
    path: '/app/setup/email-template',
    exact: true,
    component: setup,
  },
  {
    path: '/app/setup/survey-builder',
    exact: true,
    component: setup,
  },
  {
    path: '/app/setup/connections',
    exact: true,
    component: setup,
  },
  {
    path: '/app/setup/widgets',
    exact: true,
    component: setup,
  },
  {
    path: '/app/setup/webchat',
    exact: true,
    component: setup,
  },
  {
    path: '/app/setup/auto-posting',
    exact: true,
    component: setup,
  },
  // {
  //   path: '/app/setup/campaigns',
  //   exact: true,
  //   component: setup,
  // },
  {
    path: '/app/setup/post-sites',
    exact: true,
    component: setup,
  },
  {
    path: '/app/setup/seo-category',
    exact: true,
    component: setup,
  },
  {
    path: '/app/setup/industry',
    exact: true,
    component: setup,
  },
  {
    path: '/app/setup/templates-old',
    exact: true,
    component: setup,
  },
  {
    path: '/app/setup/templates',
    exact: true,
    component: setup,
  },
  {
    path: '/app/setup/search-scrape',
    exact: true,
    component: setup,
  },
  {
    path: '/app/setup/templates/:id',
    exact: true,
    component: templateForm,
  },
  {
    path: '/app/setup/location/:id',
    exact: true,
    component: locations,
  },
  {
    path: '/app/setup/business-form/:id',
    exact: true,
    component: businessForm,
  },
  {
    path: '/app/setup/campaign-type',
    exact: true,
    component: campaignType,
  },

  {
    path: '/app/list',
    exact: true,
    component: listing,
  },
  {
    path: '/app/account/contacts',
    exact: true,
    component: Account,
  },
  {
    path: '/app/setup/users',
    exact: true,
    component: setup,
  },
  {
    path: '/app/setup/users/:id',
    exact: true,
    component: EditUser,
  },
  {
    path: '/app/setup/goals',
    exact: true,
    component: setup,
  },
  {
    path: '/app/setup/permissions',
    exact: true,
    component: setup,
  },
  { path: '/app/account/contacts/:id', exact: true, component: AddClient },
  {
    path: '/app/setup/portal',
    exact: true,
    component: Portal,
  },
  {
    path: '/app/setup/post-sites/:id',
    exact: true,
    component: PostSites,
  },
  {
    path: '/app/conversations',
    exact: true,
    component: conversations,
  },
  {
    path: '/app/reports',
    exact: true,
    component: Reports,
  },
  {
    path: '/app/reports/survey-information/:id/:business',
    exact: true,
    component: SurveyInformation,
  },
  {
    path: '/app/reports/summary',
    exact: true,
    component: Reports,
  },
  {
    path: '/app/reports/rating',
    exact: true,
    component: Reports,
  },
  {
    path: '/app/reports/conversions',
    exact: true,
    component: Reports,
  },
  {
    path: '/app/reports/leaderboard',
    exact: true,
    component: Reports,
  },
  {
    path: '/app/reports/surveys',
    exact: true,
    component: Reports,
  },
  {
    path: '/app/reports/sentiment',
    exact: true,
    component: Reports,
  },
  {
    path: '/app/reports/goals',
    exact: true,
    component: Reports,
  },
  {
    path: '/app/reports/reputation',
    exact: true,
    component: Reports,
  },
  // {
  //   path: '/app/reports/nps',
  //   exact: true,
  //   component: Reports,
  // },
  {
    path: '/app/Reviews',
    exact: true,
    component: ReviewsView,
  },
  {
    path: '/app/survey',
    exact: true,
    component: Survey,
  },
  {
    path: '/app/survey/active-survey',
    exact: true,
    component: Survey,
  },
  {
    path: '/app/survey/completed-survey',
    exact: true,
    component: Survey,
  },
  {
    path: '/app/survey/paused-survey',
    exact: true,
    component: Survey,
  },
  { path: '/app/survey/edit-survey/:id/:type/:path', exact: true, component: editSurvey },

  { path: '/app/surveys/add-survey', exact: true, component: createSurvey },
  {
    path: '*',
    exact: false,
    component: pageNotFound,
  },
];

export default appRoutes;

export const appRoutesRedirection = [
  {
    exact: true,
    path: '/app',
    redirectTo: '/app/conversations',
  },
  {
    exact: true,
    path: '/app/account',
    redirectTo: '/app/account/contacts',
  },
  {
    exact: true,
    path: '/app/feedback',
    redirectTo: '/app/feedback/review-request',
  },
  // {
  //   exact: true,
  //   path: '/app/survey',
  //   redirectTo: '/app/survey/all-survey',
  // },
  {
    exact: true,
    path: '/app/survey',
    redirectTo: '/app/survey/active-survey',
  },
  {
    exact: true,
    path: '/app/reports',
    redirectTo: '/app/reports',
  },
  {
    exact: true,
    path: '/app/setup',
    redirectTo: '/app/setup/business',
  },
];

export const appSetupTabs = ({
  businessStatusFilter,
  handleBusinessStatusFilter,
  handleBusinessFormRedirection,
  locationStatusFilter,
  handleLocationStatusFilter,
  handleLocationRedirection,
  handleReviewSitesFilter,
  reviewSitesFilter,
  campaignStatusFilter,
  handleCapmpaignStatusFilter,
  handleCampaignTypeRedirection,
  postSiteStatusFilter,
  handlePostSiteStatusFilter,
  handlePostSiteRedirection,
  seoCategoryFilter,
  handleSeoCategoryStatusFilter,
  handleSeoPopupOpen,
  industryFilter,
  handleIndustryStatusFilter,
  handleIndustryPopupOpen,
  openCampaignPopup,
  handleCampaignPopupClose,
  handleOpenCampaignPopup,
  openSeoPopup,
  handleSeoPopupClose,
  openIndustryPopup,
  handleIndustryPopupClose,
  selectedEmployeeType,
  handleEmployeeList,
  handleEmployeeRedirection,
  showGoalSetting,
  handleHideGoalSettingPopup,
  handleShowGoalSettingPopup,
  selectedYear,
  handleYearSelection,
  handleSaveChanges,
  handleCampaignCreationPopup,
  handleSurveyCreationPopup,
  handleMessageCreationPopup,
  handleEmailTemplateCreationPopup,
  handleSocialMediaCreationPopup,
}) => [
  {
    tabName: 'Business',
    actionBtnTitle: 'Add Business',
    tabComponent: <BusinessContainer businessStatusFilter={businessStatusFilter} />,
    list: businessStatusList,
    value: businessStatusFilter,
    handleChange: handleBusinessStatusFilter,
    handleClick: handleBusinessFormRedirection,
    showActionBtn: true,
    showFilter: true,
    showSwitchBtn: false,
    tabValue: 0,
    tabPath: '/app/setup/business',
  },
  {
    tabName: 'Locations',
    actionBtnTitle: 'Add Location',
    tabComponent: <LocationsContainer locationStatusFilter={locationStatusFilter} />,
    list: LocationStatusList,
    value: locationStatusFilter,
    handleChange: handleLocationStatusFilter,
    handleClick: handleLocationRedirection,
    showActionBtn: true,
    showFilter: true,
    showSwitchBtn: false,
    tabValue: 1,
    tabPath: '/app/setup/location',
  },
  {
    tabName: 'Users',
    actionBtnTitle: 'Add User',
    tabComponent: <Employee selectedEmployeeType={selectedEmployeeType} />,
    list: employeeTypeList,
    value: selectedEmployeeType,
    handleChange: handleEmployeeList,
    handleClick: handleEmployeeRedirection,
    showActionBtn: true,
    showFilter: true,
    showSwitchBtn: false,
    tabValue: 2,
    tabPath: '/app/setup/users',
  },
  {
    tabName: 'Goals',
    actionBtnTitle: 'Goal Setting',
    tabComponent: (
      <Goals
        showGoalSetting={showGoalSetting}
        handleHideGoalSettingPopup={handleHideGoalSettingPopup}
        handleShowGoalSettingPopup={handleShowGoalSettingPopup}
        selectedYear={selectedYear}
      />
    ),
    list: getYearDropDown(),
    value: selectedYear,
    handleChange: handleYearSelection,
    handleClick: handleShowGoalSettingPopup,
    showActionBtn: true,
    showFilter: true,
    showSwitchBtn: false,
    tabValue: 3,
    tabPath: '/app/setup/goals',
  },

  {
    tabName: 'Connections',
    actionBtnTitle: '',
    tabComponent: <AccessAuthorizationsContainer reviewSitesFilter={reviewSitesFilter} />,
    list: ReviewSitesList,
    value: reviewSitesFilter,
    handleChange: handleReviewSitesFilter,
    handleClick: () => {},
    showActionBtn: false,
    showFilter: true,
    showSwitchBtn: false,
    tabValue: 4,
    tabPath: '/app/setup/connections',
  },
  {
    tabName: 'Templates',
    actionBtnTitle: 'New Template',
    tabComponent: (
      <CampaignContainer
        open={openCampaignPopup}
        handleClose={handleCampaignPopupClose}
        handleOpenPopup={handleOpenCampaignPopup}
        campaignStatusFilter={campaignStatusFilter}
      />
    ),
    list: CampaignStatusList,
    value: campaignStatusFilter,
    handleChange: handleCapmpaignStatusFilter,
    handleClick: handleCampaignTypeRedirection,
    showActionBtn: false,
    showActionMultipleBtn: true,
    optionsAtionMultipleBtn: [
      { title: 'Review Request', onClick: handleCampaignCreationPopup },
      { title: 'Survey', onClick: handleSurveyCreationPopup },
      { title: 'Message', onClick: handleMessageCreationPopup },
      { title: 'Email Template', onClick: handleEmailTemplateCreationPopup },
      { title: 'Social Media Post', onClick: handleSocialMediaCreationPopup },
    ],
    showFilter: true,
    showSwitchBtn: false,
    tabValue: 5,
    tabPath: '/app/setup/templates',
  },

  {
    tabName: 'Widgets',
    actionBtnTitle: '',
    tabComponent: <WidgetsContainer />,
    list: [],
    value: '',
    handleChange: () => {},
    handleClick: () => {},
    showActionBtn: false,
    showFilter: false,
    showSwitchBtn: false,
    tabValue: 6,
    tabPath: '/app/setup/widgets',
  },
  {
    tabName: 'Webchat',
    actionBtnTitle: '',
    tabComponent: <WebchatContainer />,
    list: [],
    value: '',
    handleChange: () => {},
    handleClick: () => {},
    showActionBtn: false,
    showFilter: false,
    showSwitchBtn: false,
    tabValue: 7,
    tabPath: '/app/setup/webchat',
  },
  {
    tabName: 'Auto Posting',
    actionBtnTitle: '',
    tabComponent: <SettingsContainer />,
    list: [],
    value: '',
    handleChange: () => {},
    handleClick: () => {},
    showActionBtn: false,
    showFilter: false,
    showSwitchBtn: false,
    tabValue: 8,
    tabPath: '/app/setup/auto-posting',
  },
  {
    tabName: 'Post Site',
    actionBtnTitle: 'Add Post Site',
    tabComponent: <PostSitesList postSiteStatusFilter={postSiteStatusFilter} />,
    list: postSiteStatusList,
    value: postSiteStatusFilter,
    handleChange: handlePostSiteStatusFilter,
    handleClick: handlePostSiteRedirection,
    showActionBtn: true,
    showFilter: true,
    showSwitchBtn: false,
    tabValue: 9,
    tabPath: '/app/setup/post-sites',
  },
  {
    tabName: 'SEO-Category',
    actionBtnTitle: 'Add Seocategory',
    tabComponent: (
      <SeoTable
        open={openSeoPopup}
        handleClose={handleSeoPopupClose}
        handleOpen={handleSeoPopupOpen}
        seoCategoryFilter={seoCategoryFilter}
      />
    ),
    list: seoCategoryStatusList,
    value: seoCategoryFilter,
    handleChange: handleSeoCategoryStatusFilter,
    handleClick: handleSeoPopupOpen,
    showActionBtn: true,
    showFilter: true,
    showSwitchBtn: false,
    tabValue: 10,
    tabPath: '/app/setup/seo-category',
  },
  {
    tabName: 'Industry',
    actionBtnTitle: 'Add Industry',
    tabComponent: (
      <IndustryTable
        open={openIndustryPopup}
        handleClose={handleIndustryPopupClose}
        handleOpen={handleIndustryPopupOpen}
        industryFilter={industryFilter}
      />
    ),
    list: industryStatusList,
    value: industryFilter,
    handleChange: handleIndustryStatusFilter,
    handleClick: handleIndustryPopupOpen,
    showActionBtn: true,
    showFilter: true,
    showSwitchBtn: false,
    tabValue: 11,
    tabPath: '/app/setup/industry',
  },

  {
    tabName: 'Permissions',
    actionBtnTitle: 'Save Changes',
    tabComponent: <Permissions handleSaveChanges={handleSaveChanges} />,
    list: [],
    value: '',
    handleChange: () => {},
    handleClick: handleSaveChanges,
    showActionBtn: true,
    showFilter: false,
    showSwitchBtn: false,
    tabValue: 12,
    tabPath: '/app/setup/permissions',
  },
  {
    tabName: 'Search-Scrape',
    actionBtnTitle: 'Search and Scrape',
    tabComponent: <SearchScrapeContainer />,
    list: [],
    value: '',
    handleChange: () => {},
    handleClick: () => {},
    showActionBtn: false,
    showFilter: false,
    showSwitchBtn: false,
    tabValue: 13,
    tabPath: '/app/setup/search-scrape',
  },
  {
    tabName: 'survey-builder',
    actionBtnTitle: '',
    tabComponent: <SurveyBuilder />,
    list: [],
    value: '',
    handleChange: () => {},
    handleClick: () => {},
    hiddenTitle: true,
    showActionBtn: false,
    showFilter: false,
    showSwitchBtn: false,
    tabValue: 14,
    tabPath: '/app/setup/survey-builder',
  },
];

export const appAccountTabs = ({
  options,
  selectedIndex,
  handleGetImportCustomersFile,
  handleContactBtnClick,
  disabledBtn,
  contactPopover,
  showLoader,
}) => [
  {
    tabName: 'Contacts',
    actionBtnTitle: '',
    switchBtnTitle: options[selectedIndex],
    tabComponent:
      options[selectedIndex] === 'Add' ? (
        <CustomerView />
      ) : (
        <ImportCustomers handleGetImportCustomersFile={handleGetImportCustomersFile} />
      ),
    list: [],
    value: '',
    handleChange: () => {},
    handleClick: handleContactBtnClick,
    showActionBtn: false,
    showFilter: false,
    showSwitchBtn: true,
    disabledBtn,
    handleSwitchBtnClick: contactPopover.handleClick,
    switchBtnId: contactPopover.id,
    showLoader,
    tabValue: 1,
    tabPath: '/app/account/contacts',
  },
];
