import React from 'react';
import { Checkbox, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.checkboxColor,
    '&$checked': {
      color: theme.checkboxColor,
    },
  },
  checked: {},
}));

const StyledCheckbox = (props) => {
  const classes = useStyles();
  return <Checkbox color="default" {...props} className={classes.root} />;
};

export default StyledCheckbox;
