import React from 'react';
import Proptypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { Card, CardContent, Typography } from '@material-ui/core';
import CustomButton from '../CustomButton/CustomButton';
import Logo from '../../assets/images/reveo_logo@1x.png';

const CardStyle = styled(Card)`
  text-align: center;
  min-width: 100%;
  height: 100%;
  position: relative;
  // @media only screen and (max-width: 600px) {
  //   box-shadow: none;
  // }
`;
const CardContentStyle = styled(CardContent)`
  text-align: center;
`;
const BottomContent = styled(CardContent)`
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0;
`;
const ImageConatiner = styled.img`
  width: 32%;
  margin-top: 1em;
  //   min-height: 150px;
  //   height: 150px;
  //   object-fit: contain;
`;

const QuestionTextStyle = styled(Typography)`
  color: #6c6c6c !important;
  padding: 0px 60px;
  font-family: Montserrat, bold !important;
  font-size: 16px !important;
  // @media only screen and (min-device-width: 481px) and (max-device-width: 1000px) {
  //   font-size: 1rem !important;
  // }
`;
// const PowerByText = styled(Typography)`
//   color: #7c7c7c !important;
//   font-family: Montserrat, bold !important;
//   font-size: 6px !important;
// `;

function SocialSites(props) {
  // console.log('socialSites[0]', props);
  const { positiveBtnAction, negativeBtnAction, socialSites, reviewResponseData } = props;

  const buttonTheme = {
    background:
      socialSites.backgroundColor === '#fff' || socialSites.backgroundColor === '#ffffff'
        ? '#333'
        : socialSites.backgroundColor,
    margin: '0px',
    color: socialSites.color,
    fontSize: '18px !important',
    fontWeight: 'bold',
    width: '70%',
  };
  const cancelButtonTheme = {
    background: '#fff',
    border: 'none',
    fontSize: '12px !important',
    fontWeight: '400',
    color:
      socialSites.backgroundColor === '#fff' || socialSites.backgroundColor === '#ffffff'
        ? '#333'
        : socialSites.backgroundColor,
    margin: '0px',
  };

  const getUpdatedItem = (item) => {
    let obj = {};
    if (reviewResponseData.postSites.length) {
      reviewResponseData.postSites.forEach((i1) => {
        if (i1.postSites.name.toLowerCase() === item.type.toLowerCase()) {
          obj = {
            name: i1.postSites.name,
            postReviewLink: i1.postReviewLink,
            listReviewLink: i1.listReviewsLink,
            isConnected: i1.isConnected || i1.isDeleted || i1.isSynced,
            screen: 'social-site',
          };
        }
      });
    }
    return obj;
  };

  const obj = getUpdatedItem(socialSites);

  return (
    <CardStyle>
      <CardContentStyle>
        <ImageConatiner
          src={`${process.env.REACT_APP_IMAGE_ENDPOINT}${socialSites.url}`}
          alt="logo"
        />
        <QuestionTextStyle>{socialSites.title}</QuestionTextStyle>
      </CardContentStyle>

      <CardContentStyle>
        <ThemeProvider theme={buttonTheme}>
          <CustomButton onClick={() => positiveBtnAction(obj)}>
            {socialSites.positiveButtonText}
          </CustomButton>
        </ThemeProvider>
        <ThemeProvider theme={cancelButtonTheme}>
          <CustomButton onClick={() => negativeBtnAction(obj)}>
            {socialSites.negativeButtonText}
          </CustomButton>
        </ThemeProvider>
      </CardContentStyle>

      <BottomContent>
        <div style={{ display: 'inline-grid' }}>
          <span style={{ fontSize: '6px', marginLeft: '14px' }}>Powered by</span>
          <img src={Logo} style={{ width: '2.3em' }} alt="logo" />
        </div>
      </BottomContent>
    </CardStyle>
  );
}

SocialSites.propTypes = {
  positiveBtnAction: Proptypes.func.isRequired,
  negativeBtnAction: Proptypes.func.isRequired,
  socialSites: Proptypes.string.isRequired,
};

SocialSites.defaultProps = {};

export default SocialSites;
