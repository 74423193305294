import React, { useContext, useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
} from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import BorderSearchBoxTheme from '../../../themes/SearchBox/BorderSearchBoxTheme';
import TransperentButtonTheme from '../../../themes/Button/TransperentButtonTheme';
import CustomButton from '../../CustomButton/CustomButton';
import CusotomSearchBox from '../../CustomSearchBox/CustomSearchBox';
import NoDataFound from '../../NoDataFound/NoDataFound';
import ContactInfoList from '../ContactInfoList/ContactInfoList';
import ButtonLoader from '../../ButtonLoader/ButtonLoader';
import StyledGroupAddIcon from '../../Icons/GroupAdd';
import StyledPersonAddIcon from '../../Icons/PersonAdd';
import { convertIntoContactList, getGroupMemberList } from '../../../utils/helper/helper';
import { GlobalContext } from '../../../Stores/GlobalStateStore';
import useFetch from '../../../hooks/useFetch';
import useDebounce from '../../../hooks/useDebounce';
import useIconTheme from '../../../hooks/useIconTheme';

const StyledDialogTitle = styled(DialogTitle)`
  letter-spacing: 1.08px;
  color: #6c6c6c;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 400 !important;
  & > h2 {
    letter-spacing: 1.08px;
    color: #6c6c6c;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 400 !important;
  }
`;

const StyledFieldGroupLabel = styled.div`
  margin: 0.5rem 1rem;
  color: #888888;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  font-size: 14px;
`;

const StyledContentDialog = styled(DialogContent)`
  padding: 0 0.5em !important;
`;

const StyledActions = styled(DialogActions)`
  padding: 0 !important;
`;

const iconTheme = {
  fontSize: '16x',
};

function NewConversationList(props) {
  const {
    selectedUser,
    handleUserSelection,
    handleEditModeToggle,
    handleSelectedUser,
    newConversationLoader,
    handleGroupViewToggle,
    selectedGroupIndex,
    handleGroupSelection,
    open,
  } = props;
  const [searchValue, setSearchValue] = useState(null);
  const { state, dispatch } = useContext(GlobalContext);
  const { fetchSearchedSendInviteList } = useFetch();
  const descriptionElementRef = React.useRef(null);
  const { sendInviteList, sendInviteLoader } = state;
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const { iconsTheme } = useIconTheme();

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchValue('');
  };

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    if (debouncedSearchTerm || debouncedSearchTerm === '') {
      if (Number.isInteger(parseInt(debouncedSearchTerm, 10)) && searchValue.trim().length === 10) {
        fetchSearchedSendInviteList(encodeURIComponent(`+1${searchValue}`));
      } else {
        fetchSearchedSendInviteList(encodeURIComponent(searchValue));
      }
    }
  }, [debouncedSearchTerm, fetchSearchedSendInviteList, searchValue]);

  const handleOnSelect = () => {
    if (selectedGroupIndex !== null && selectedGroupIndex >= 0) {
      dispatch({
        type: 'SET_SELECTED_GROUP_ID',
        payload: sendInviteList.groups[selectedGroupIndex].id,
      });
      dispatch({
        type: 'SET_SELECTED_GROUP_INFO',
        payload: sendInviteList.groups[selectedGroupIndex],
      });
    } else {
      handleSelectedUser();
    }
  };

  const groupListOrNoData = sendInviteList.groups.length ? (
    <>
      {getGroupMemberList(sendInviteList.groups).map((groupInfo, index) => (
        <ContactInfoList
          {...groupInfo}
          index={index}
          selectedUser={selectedGroupIndex}
          handleSelection={handleGroupSelection}
          MemberCountShowAsSecondary
        />
      ))}
    </>
  ) : (
    <NoDataFound>No Groups Found !</NoDataFound>
  );

  const userListOrNoData = sendInviteList.clients.length ? (
    <>
      {convertIntoContactList(sendInviteList.clients).map((user, index) => (
        <ContactInfoList
          {...user}
          index={index}
          selectedUser={selectedUser}
          handleSelection={handleUserSelection}
          locationShowAsSecondary={!!searchValue}
        />
      ))}
    </>
  ) : (
    <NoDataFound>No Users Found !</NoDataFound>
  );

  const userListContainer = sendInviteLoader ? (
    <Grid container alignItems="center" justify="center">
      <CircularProgress />
    </Grid>
  ) : (
    userListOrNoData
  );

  const groupListContainer = sendInviteLoader ? (
    <Grid container alignItems="center" justify="center">
      <CircularProgress />
    </Grid>
  ) : (
    groupListOrNoData
  );

  const showLoader = newConversationLoader ? <ButtonLoader /> : null;
  const btnDisabled = selectedUser === null && selectedGroupIndex === null;
  return (
    <>
      <StyledDialogTitle id="scroll-dialog-title">
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={9} md={9} sm={9} lg={9}>
            <ThemeProvider theme={BorderSearchBoxTheme}>
              <CusotomSearchBox
                placeholder="Search"
                handleChange={handleSearch}
                searchValue={searchValue}
                clearSearch={handleClearSearch}
              />
            </ThemeProvider>
          </Grid>
          <Grid item xs={3} md={3} sm={3} lg={3}>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={6} md={6} sm={6} lg={6}>
                <ThemeProvider theme={TransperentButtonTheme}>
                  <CustomButton onClick={() => handleEditModeToggle(null)} title="Add Contact">
                    <ThemeProvider theme={{ ...iconTheme, color: iconsTheme.personAddIcon }}>
                      <StyledPersonAddIcon />
                    </ThemeProvider>
                  </CustomButton>
                </ThemeProvider>
              </Grid>
              <Grid item xs={6} md={6} sm={6} lg={6}>
                <ThemeProvider theme={TransperentButtonTheme}>
                  <CustomButton onClick={handleGroupViewToggle} title="Add Group">
                    <ThemeProvider theme={{ ...iconTheme, color: iconsTheme.groupAddIcon }}>
                      <StyledGroupAddIcon />
                    </ThemeProvider>
                  </CustomButton>
                </ThemeProvider>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </StyledDialogTitle>
      <StyledContentDialog id="scroll-dialog-description" ref={descriptionElementRef}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <StyledFieldGroupLabel item>Recent conversations</StyledFieldGroupLabel>
            <Divider />
            <List>{userListContainer}</List>
            <StyledFieldGroupLabel item>Recent Groups</StyledFieldGroupLabel>
            <Divider />
            <List>{groupListContainer}</List>
          </Grid>
        </Grid>
      </StyledContentDialog>
      <StyledActions>
        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={3} lg={4}>
            <CustomButton
              type="submit"
              color="primary"
              onClick={handleOnSelect}
              disabled={btnDisabled}
            >
              Select
              {showLoader}
            </CustomButton>
          </Grid>
        </Grid>
      </StyledActions>
    </>
  );
}

NewConversationList.propTypes = {
  selectedUser: Proptypes.number.isRequired,
  handleUserSelection: Proptypes.func.isRequired,
  loader: Proptypes.bool.isRequired,
  handleEditModeToggle: Proptypes.func.isRequired,
  handleSelectedUser: Proptypes.func.isRequired,
  newConversationLoader: Proptypes.bool.isRequired,
  handleGroupViewToggle: Proptypes.func.isRequired,
  selectedGroupIndex: Proptypes.number.isRequired,
  handleGroupSelection: Proptypes.func.isRequired,
  open: Proptypes.bool.isRequired,
};

export default React.memo(NewConversationList);
