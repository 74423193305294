import React from 'react';
import styled from 'styled-components';
import AddTypeRequestModal from '../AddTypeRequestModal/AddTypeRequestModal';
import FlowController from '../FlowController/FlowController';
import QuestionCategory from '../QuestionCategory/QuestionCategory';
import SingleChoiceForm from '../SingleChoiceForm/SingleChoiceForm';
import SingleChoicePreview from '../SingleChoicePreview/SingleChoicePreview';

const StyledMainContent = styled.div`
  height: 512px;
  display: flex;
  flex-direction: row;
  overflow: auto;
`;
const StyledQuestionsContent = styled.div`
  width: 50%;
  margin: 20px;
`;

// eslint-disable-next-line max-len
const ContainerForm = ({
  surveyBody,
  setSurveyBody,
  updateQuestionIndex,
  setUpdateQuestion,
  questionAddType,
  setQuestionAddType,
  handleAddRequest,
  handleSaveQuestion,
  handleDeleteQuestion,
  handleEditQuestion,
  setQuestionCategory,
  setQuestionFlowControl
}) => {
  const { questions } = surveyBody;
  return (
    <StyledMainContent>
      <StyledQuestionsContent>
        {questions.length > 0 &&
          questions.map((item, index) => {
            if (item.type === 'singleChoice') {
              return (
                <SingleChoicePreview
                  data={item}
                  handleDeleteQuestion={() => handleDeleteQuestion(index)}
                  handleEditQuestion={() => handleEditQuestion(index, 'singleChoice')}
                />
              );
            }
            return <div>question</div>;
          })}

        {questionAddType === 'singleChoice' && (
          <SingleChoiceForm
            surveyBody={surveyBody}
            setSurveyBody={setSurveyBody}
            updateQuestionIndex={updateQuestionIndex}
            setUpdateQuestion={setUpdateQuestion}
            handleSaveQuestion={handleSaveQuestion}
            setQuestionAddType={setQuestionAddType}
            setQuestionFlowControl={setQuestionFlowControl}
          />
        )}

        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <AddTypeRequestModal handleAddRequest={handleAddRequest} />
        </div>
      </StyledQuestionsContent>
      <StyledQuestionsContent>
        <QuestionCategory
          questionAddType={questionAddType}
          updateQuestionIndex={updateQuestionIndex}
          surveyBody={surveyBody}
          setSurveyBody={setSurveyBody}
          setQuestionCategory={setQuestionCategory}

        />
        {questionAddType && updateQuestionIndex >= 0 &&
          <FlowController
            questionAddType={questionAddType}
            updateQuestionIndex={updateQuestionIndex}
            surveyBody={surveyBody}
            setSurveyBody={setSurveyBody}
            setQuestionFlowControl={setQuestionFlowControl}
          />
        }
      </StyledQuestionsContent>
    </StyledMainContent>
  );
};

export default ContainerForm;
