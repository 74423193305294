import React, { useContext } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Grid } from '@material-ui/core';
import Proptypes from 'prop-types';
import { GlobalContext } from '../../Stores/GlobalStateStore';

const StyledPageTitle = styled.div`
  font-size: ${(props) => (props.theme.fontSize ? props.theme.fontSize : '1.5rem')};
  padding: ${(props) => props.theme.padding};
  font-weight: ${(props) => (props.theme.fontWeight ? props.theme.fontWeight : 600)};
  min-width: 400px;
  // letter-spacing: 1.08px;
  text-transform: capitalize;
  //margin-top: -40px;
  // @media screen and (min-width: 1920px) {
  //   & {
  //     font-size: 54px;
  //   }
  // }
`;

function PageTitle(props) {
  const { title } = props;
  const { state } = useContext(GlobalContext);
  const { themeContainer } = state;
  return (
    <ThemeProvider theme={{ color: themeContainer.pageTitleColor }}>
      <StyledPageTitle>{title}</StyledPageTitle>
    </ThemeProvider>
  );
}

PageTitle.propTypes = {
  title: Proptypes.string,
};

PageTitle.defaultProps = {
  title: '',
};

export default PageTitle;
