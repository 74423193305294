import React from 'react';
import TabPanel from '../../components/TabPanel/TabPanel';
import { Grid, InputAdornment } from '@material-ui/core';
import StyledTextField from '../../components/GeneralInputField/GeneralInputField';
import { ThemeProvider } from 'styled-components';
import defaultDropDownTheme from '../../themes/Dropdown/DefaultDropdownTheme';
import StyledFormControl from '../../components/StyledFormControl';
import NumberField from '../../components/NumberField/NumberField';
import Dropdown from '../../components/Dropdown/Dropdown';
import Timezone from '../../utils/api/Timezone';

export const ContactTab = (props) => {
  const { tabValue, disabledMMSNo, formik, validateEmail } = props;

  return (
    <TabPanel value={tabValue} index={1}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5} md={5} lg={5}>
          <StyledTextField
            name="contact.addressLine1"
            label="Address 1 *"
            value={formik.values.contact.addressLine1}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.contact?.addressLine1}
            helperText={formik.errors.contact?.addressLine1 ?? formik.errors.contact?.addressLine1}
          />
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={5}>
          <StyledTextField
            name="contact.addressLine2"
            label="Address 2"
            value={formik.values.contact.addressLine2}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2}>
          <StyledTextField
            name="contact.suite"
            label="Suite"
            value={formik.values.contact.suite}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <StyledTextField
            name="contact.city"
            label="City *"
            value={formik.values.contact.city}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.contact?.city}
            helperText={formik.errors.contact?.city ?? formik.errors.contact?.city}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <StyledTextField
            name="contact.state"
            label="State *"
            value={formik.values.contact.state}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.contact?.state}
            helperText={formik.errors.contact?.state ?? formik.errors.contact?.state}
          />
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2}>
          <StyledTextField
            name="contact.zip"
            format="#####"
            type="number"
            label="zip"
            value={formik.values.contact.zip}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <ThemeProvider theme={defaultDropDownTheme}>
            <StyledFormControl>
              <Dropdown
                listOptions={Array.from(Timezone)}
                name="contact.timeZone"
                label="Time Zone"
                value={formik.values.contact.timeZone}
                onChange={formik.handleChange}
              />
            </StyledFormControl>
          </ThemeProvider>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4}>
          <StyledTextField
            name="contact.webSite"
            label="Website"
            value={formik.values.contact.webSite}
            onChange={formik.handleChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">https://</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <NumberField
            name="contact.phoneNo"
            format="+1 ### ### ####"
            label="Contact Mobile *"
            disabled={false}
            value={formik.values.contact.phoneNo}
            onChange={(event) => {
              formik.setFieldValue('contact.phoneNo', event.value);
            }}
            onBlur={formik.handleBlur}
            error={formik.errors.contact?.phoneNo}
            helperText={formik.errors.contact?.phoneNo ?? formik.errors.contact?.phoneNo}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <NumberField
            name="contact.mmsNumber"
            format="+1 ### ### ####"
            label="MMS Number *"
            disabled={disabledMMSNo}
            value={formik.values.contact.mmsNumber}
            onChange={(event) => {
              formik.setFieldValue('contact.mmsNumber', event.value);
            }}
            onBlur={formik.handleBlur}
            error={formik.errors.contact?.mmsNumber}
            helperText={formik.errors.contact?.mmsNumber ?? formik.errors.contact?.mmsNumber}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {/* <StyledTextField
            name="contact.emailAddress"
            label="Contact Email *"
            value={formik.values.contact.emailAddress}
            onChange={formik.handleChange}
            onBlur={(e) => {
              formik.handleBlur(e);
              validateEmail(e.target.value); // call validateEmail on blur
            }}
            error={
              formik.touched?.contact?.emailAddress ? formik.errors.contact?.emailAddress : null
            }
            helperText={
              formik.touched?.contact?.emailAddress ? formik.errors.contact?.emailAddress : null
            }
          /> */}
          <StyledTextField
            name="contact.emailAddress"
            label="Contact Email *"
            value={formik.values.contact.emailAddress}
            onChange={formik.handleChange}
            onBlur={(e) => {
              formik.handleBlur(e);
              validateEmail(e.target.value);
            }}
            error={
              formik.touched?.contact?.emailAddress ? formik.errors.contact?.emailAddress : null
            }
            helperText={
              formik.touched?.contact?.emailAddress ? formik.errors.contact?.emailAddress : null
            }
          />
        </Grid>
      </Grid>
    </TabPanel>
  );
};
